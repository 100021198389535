import { byId } from '../common/byId'
import { CollageGrid } from './grid'
import { getGutterOffsetRange } from './gutterOffsetRange'

export const evenlySpaceGutter = (
  grid: CollageGrid,
  gutterId: string,
): CollageGrid => {
  const [minOffset, maxOffset] = getGutterOffsetRange(
    byId(grid.gutters),
    gutterId,
  )

  const newOffset = minOffset + (maxOffset - minOffset) * 0.5

  return {
    ...grid,
    gutters: grid.gutters.map(other =>
      other.id === gutterId ? { ...other, offset: newOffset } : other,
    ),
  }
}
