import { Box, Grid, Text } from '@moonpig/launchpad-components'
import { colorValue } from '@moonpig/launchpad-theme'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC, useMemo } from 'react'
import { RadioList } from '../RadioList'
import { useLocaleText } from './TextColorPicker.locale'

export type TextColor = {
  id: string
  value: string
  name: string
}

export type TextColorPickerProps = {
  colours: TextColor[]
  selectedColourId: string
  onSelect: (colourId: string) => void
}

const ColorBlock = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 4px;
  width: 24px;
  height: 24px;
  box-shadow: ${({ color, theme }) =>
    color.toLowerCase() === '#ffffff'
      ? `0 0 0 1px ${colorValue('colorBorder03')({ theme })} inset`
      : 'none'};
`

export const TextColorPicker: FC<TextColorPickerProps> = ({
  colours,
  selectedColourId,
  onSelect,
}) => {
  const t = useLocaleText()
  const items = useMemo(
    () =>
      colours.map(({ id, name, value }) => {
        const checked = selectedColourId === id

        return {
          id,
          element: (
            <Grid>
              <Box px={5}>
                <ColorBlock color={value} />
              </Box>
              <Text
                typography={checked ? 'bodyBold' : 'body'}
                flex={1}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                width="100%"
                display="block"
              >
                {name || t('color_name_not_found_title', value)}
              </Text>
            </Grid>
          ),
          checked,
        }
      }),
    [colours, selectedColourId, t],
  )

  return (
    <RadioList
      items={items}
      name={'mp-ed-toolbar-color-radio'}
      onSelect={onSelect}
    />
  )
}
