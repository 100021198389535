import { Box, Grid } from '@moonpig/launchpad-components'
import React, { FC, ReactNode } from 'react'
import { RadioButton } from '../RadioButton'
import { RadioGroup } from '../RadioGroup'

const GRID_GAP = 4

type RadioGridProps = {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: { id: string; disabled?: boolean; value: any; element: ReactNode }[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect(id: any): void
  selectedId: string
}

export const RadioGrid: FC<RadioGridProps> = ({
  name,
  items,
  onSelect,
  selectedId,
}) => {
  return (
    <RadioGroup>
      <Grid gap={GRID_GAP}>
        {items.map(item => {
          const id = `mp-ed-toolbar-${name}-radio-${item.id}`
          return (
            <Box
              key={item.id}
              width={{ xs: 1 / 5, sm: 1 / 6, md: 1 / 8, lg: 1 / 5 }}
              display="flex"
              justifyContent="center"
            >
              <RadioButton
                id={id}
                name={name}
                onSelect={() => onSelect(item.value)}
                checked={selectedId === item.id}
                disabled={item.disabled}
                fullWidth
              >
                {item.element}
              </RadioButton>
            </Box>
          )
        })}
      </Grid>
    </RadioGroup>
  )
}
