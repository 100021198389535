import { useTheme } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'

const CHARACTER_COUNT_FONT_SIZE = 12
const CHARACTER_COUNT_WIDTH = 45
const CHARACTER_COUNT_HEIGHT = 16
const CHARACTER_COUNT_BACKGROUND_COLOR = 'rgba(255,255,255,0.8)'
const CHARACTER_COUNT_BORDER_RADIUS = 3

type CharacterCountProps = {
  scale: number
  width: number
  height: number
  charactersUsed: number
  characterLimit: number
}

export const CharacterCount: FC<CharacterCountProps> = ({
  scale,
  width,
  height,
  charactersUsed,
  characterLimit,
}) => {
  const theme = useTheme()
  const { colorTextBody } = theme.palette
  const scaledCharacterCountFontSize = CHARACTER_COUNT_FONT_SIZE * scale
  const scaledCharacterCountWidth = CHARACTER_COUNT_WIDTH * scale
  const scaledCharacterCountHeight = CHARACTER_COUNT_HEIGHT * scale
  const scaledCharacterCountBorderRadius = CHARACTER_COUNT_BORDER_RADIUS * scale
  const t = useEditorLocaleText()

  const usedLabel =
    charactersUsed === 1
      ? t('edit_used_one_character_label')
      : t('edit_used_multiple_characters_label', charactersUsed)

  const label = t('edit_total_used_character_label', usedLabel, characterLimit)

  return (
    <g transform={`translate(${width - scaledCharacterCountWidth}, ${height})`}>
      <rect
        width={scaledCharacterCountWidth}
        height={scaledCharacterCountHeight}
        fill={CHARACTER_COUNT_BACKGROUND_COLOR}
        rx={scaledCharacterCountBorderRadius}
      />
      <text
        aria-label={label}
        fontSize={scaledCharacterCountFontSize}
        fontWeight="bold"
        x={scaledCharacterCountWidth * 0.5}
        y={scaledCharacterCountHeight * 0.75}
        fill={colorTextBody}
        textAnchor="middle"
      >
        {`${charactersUsed} / ${characterLimit}`}
      </text>
    </g>
  )
}
