import { ProductFacet } from '@moonpig/web-personalise-editor-types'
import {
  birthdayStarterMessagesByDesign,
  genericBirthdayMessage,
  starterMessagesByOccasion,
} from './starterMessages'
import { StarterMessage } from './types'
import { GetEditorLocaleText } from '../../../text-localisation'

const defaultStarterMessage: StarterMessage = {
  id: 0,
  message: '',
}

const createBirthdayMessage = (
  designFacets: string[],
  t: GetEditorLocaleText,
): StarterMessage => {
  for (let i = 0; i < designFacets.length; i += 1) {
    const starterMessage = birthdayStarterMessagesByDesign(t)[designFacets[i]]

    if (starterMessage) {
      return starterMessage
    }
  }

  return genericBirthdayMessage(t)
}

const createOtherOccasionMessage = (
  occasionFacets: string[],
  t: GetEditorLocaleText,
): StarterMessage => {
  if (occasionFacets.length !== 2) return defaultStarterMessage

  return (
    starterMessagesByOccasion(t)[occasionFacets[1]] ?? defaultStarterMessage
  )
}

export const createStarterMessage = (
  facets: ProductFacet[],
  t: GetEditorLocaleText,
): StarterMessage => {
  const occasionFacets = facets
    .filter(({ name }) => name === 'occasion')
    .map(({ value }) => value)

  const designFacets = facets
    .filter(({ name }) => name === 'design')
    .map(({ value }) => value)

  const isBirthdayCard =
    occasionFacets.length > 0 &&
    occasionFacets.every(facet => facet === 'birthday')

  if (isBirthdayCard) {
    return createBirthdayMessage(designFacets, t)
  }

  return createOtherOccasionMessage(occasionFacets, t)
}
