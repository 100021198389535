/* eslint-disable jsx-a11y/media-has-caption */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { clamp } from '@moonpig/common-math'
import { AudioTimeline } from '../AudioTimeline'
import { useLocaleText } from './AudioPlayer.locale'

export type AudioPlayerState = 'paused' | 'playing' | 'ended' | 'error'

export type AudioPlayerContext = {
  play: () => void
  pause: () => void
}

export type AudioPlayerReadyEvent = {
  playerContext: AudioPlayerContext
}

type AudioPlayerProps = {
  audioUrl: string
  timePerSampleSeconds: number
  samples: number[]
  durationSeconds: number
  onReady?: (event: AudioPlayerReadyEvent) => void
  onPlaying: () => void
  onPaused: () => void
  onEnded: () => void
  onError: () => void
}

export const AudioPlayer: FC<AudioPlayerProps> = ({
  audioUrl,
  timePerSampleSeconds,
  durationSeconds,
  samples,
  onReady,
  onPlaying,
  onPaused,
  onEnded,
  onError,
}) => {
  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(0)
  const [playingStartTimeSeconds, setPlayingStartTimeSeconds] = useState<
    number | null
  >(null)
  const playingRef = useRef(false)
  const audioRef = useRef<HTMLAudioElement>(null)

  const t = useLocaleText()

  const handleSeek = useCallback(
    time => {
      const audioElement = audioRef.current

      // istanbul ignore next
      if (!audioElement) {
        return
      }

      audioElement.pause()
      const updatedTime = clamp(time, 0, durationSeconds)
      audioElement.currentTime = updatedTime
      setCurrentTimeSeconds(updatedTime)
    },
    [audioRef, durationSeconds],
  )

  useEffect(() => {
    const animate = () => {
      if (playingStartTimeSeconds !== null && playingRef.current) {
        const animationTimeSeconds = Date.now() / 1000
        const newCurrentTimeSeconds =
          animationTimeSeconds - playingStartTimeSeconds

        setCurrentTimeSeconds(newCurrentTimeSeconds)
      }

      if (playingRef.current) {
        requestAnimationFrame(animate)
      }
    }

    animate()
  }, [playingStartTimeSeconds, durationSeconds, onEnded])

  useEffect(() => {
    const audioElement = audioRef.current
    if (onReady && audioElement) {
      onReady({
        playerContext: {
          play: () => {
            if (playingStartTimeSeconds === null) {
              setCurrentTimeSeconds(0)
              audioElement.currentTime = 0
            }
            audioElement.play()
          },
          pause: () => {
            audioElement.pause()
          },
        },
      })
    }
  }, [playingStartTimeSeconds, onReady])

  const handlePlay = useCallback(() => {
    const timeSeconds = Date.now() / 1000
    setPlayingStartTimeSeconds(timeSeconds - currentTimeSeconds)
    playingRef.current = true
    onPlaying()
  }, [currentTimeSeconds, onPlaying])

  const handlePaused = useCallback(() => {
    playingRef.current = false
    onPaused()
  }, [onPaused])

  const handleEnded = useCallback(() => {
    setPlayingStartTimeSeconds(null)
    playingRef.current = false
    onEnded()
  }, [onEnded])

  return (
    <>
      <AudioTimeline
        durationSeconds={durationSeconds}
        currentTimeSeconds={currentTimeSeconds}
        samples={samples}
        timePerSampleSeconds={timePerSampleSeconds}
        onSeek={handleSeek}
      />
      <audio
        ref={audioRef}
        src={audioUrl}
        aria-label={t('audio_player_label')}
        onPlay={handlePlay}
        onPause={handlePaused}
        onEnded={handleEnded}
        onError={onError}
      />
    </>
  )
}
