import { byId } from '../utils/byId'
import { getLayoutPad } from './pad'
import { DrawnImage, drawShadow } from './draw'
import { Layout, LayoutImage, LayoutScene, LayoutSceneInput } from './types'
import { calculateBounds, calculateDimensions } from './utils'

export const buildLayoutFallback = (scenes: LayoutSceneInput[]): Layout => {
  const pad = getLayoutPad(scenes)

  let x = 0
  const layoutScenes = scenes.map<LayoutScene>(scene => {
    const layoutScene: LayoutScene = {
      id: scene.id,
      ...calculateDimensions(x, 0, scene.width, scene.height),
      connected: [],
    }
    x += scene.width + pad
    return layoutScene
  })

  layoutScenes.forEach((layoutScene, i) => {
    const prev = i > 0 ? layoutScenes[i - 1] : null
    const next = i < layoutScenes.length - 1 ? layoutScenes[i + 1] : null

    if (next) {
      layoutScene.connected.push(next)
    }

    if (prev) {
      layoutScene.connected.push(prev)
    }
  })

  const backgroundImages: LayoutImage[] = []
  const imageBySize: { [sizeId: string]: DrawnImage } = {}
  layoutScenes.forEach(layoutScene => {
    const sizeId = `${layoutScene.width}x${layoutScene.height}`

    if (!imageBySize[sizeId]) {
      imageBySize[sizeId] = drawShadow(layoutScene.width, layoutScene.height)
    }
    const image = imageBySize[sizeId]
    backgroundImages.push({
      id: layoutScene.id,
      url: image.url,
      x: layoutScene.x + image.x,
      y: layoutScene.y + image.y,
      width: image.width,
      height: image.height,
    })
  })

  return {
    bounds: calculateBounds(layoutScenes),
    sceneById: byId(layoutScenes),
    images: {
      background: backgroundImages,
      foreground: [],
    },
  }
}
