import { IconSystemImageUpload } from '@moonpig/launchpad-assets'
import { useTheme } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { BORDER_WIDTH, ICON_SIZE } from '../constants'

type IndicatorPhotoProps = {
  scale: number
  width: number
  height: number
  invalid?: boolean
}

export const IndicatorPhoto: FC<IndicatorPhotoProps> = ({
  scale,
  width,
  height,
  invalid,
}) => {
  const theme = useTheme()
  const {
    colorFeedbackInformation,
    colorBackground01,
    colorTextBody,
    colorFeedbackWarning,
  } = theme.palette
  const iconSize = ICON_SIZE * scale
  const halfIconSize = iconSize * 0.5
  const borderWidth = BORDER_WIDTH * scale
  const radius = ICON_SIZE * scale * (2 / 3)

  return (
    <g transform={`translate(${width * 0.5} ${height * 0.5})`}>
      <circle
        r={radius}
        stroke={invalid ? colorFeedbackWarning : colorFeedbackInformation}
        strokeWidth={borderWidth}
        fill={colorBackground01}
      />
      <IconSystemImageUpload
        x={-halfIconSize}
        y={-halfIconSize}
        width={iconSize}
        height={iconSize}
        color={colorTextBody}
      />
    </g>
  )
}
