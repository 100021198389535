/* eslint-disable no-restricted-syntax */
import { assert } from '../../../utils/assert'
import { performance } from '../../../utils/performance'
import { createEditorEventState } from '../../common/createEditorEventState'
import { ActionCreator } from '../../types'

export const createFinish: ActionCreator<'finish'> =
  ({ services, set, get }) =>
  async () => {
    performance.measureStart('editor-finish')

    set(({ view }) => {
      assert(view.type === 'main')

      return {
        view: {
          ...view,
          view: { type: 'loading-finish' },
        },
      }
    })

    const store = get()

    assert(store.view.type === 'main')

    const { design, designState, photos, selectedSceneId } = store.view

    const state = createEditorEventState({
      design,
      designState,
      photos,
      selectedSceneId,
    })

    const result = await services.finishEditing(design, designState)

    if (result.type === 'success') {
      services.trackEvent({
        type: 'FINISH_EDITING',
        state,
        addToBasket: designState.type === 'draft',
        additionalProps: result.additionalTrackingData,
      })
    }

    if (result.type === 'error') {
      services.trackEvent({ type: 'ERROR', kind: 'ADD_TO_BASKET' })

      set(({ view }) => {
        assert(view.type === 'main')

        return {
          view: {
            ...view,
            view: { type: 'error-finish' },
          },
        }
      })
    }

    performance.measureEnd('editor-finish')
  }
