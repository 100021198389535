import { DesignElement } from '@moonpig/web-personalise-editor-types'

export const clampBounds = ({
  element,
  sceneWidth,
  sceneHeight,
}: {
  element: DesignElement
  sceneWidth: number
  sceneHeight: number
}): { x: number; y: number; width: number; height: number } => {
  const x = Math.max(element.x, 0)
  const y = Math.max(element.y, 0)

  const overflowX = Math.max(0, x + element.width - sceneWidth)
  const overflowY = Math.max(0, y + element.height - sceneHeight)
  const width = element.width - overflowX
  const height = element.height - overflowY

  return { x, y, width, height }
}
