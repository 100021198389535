import { Flex, IconButton } from '@moonpig/launchpad-components'
import { IconRedo, IconUndo } from '@moonpig/web-personalise-components'
import React, { FC, useCallback } from 'react'
import { useAction, useEditorFeatures, useView } from '../../../../../store'
import { useEditorLocaleText } from '../../../../../text-localisation'

export const HistoryControls: FC = () => {
  const t = useEditorLocaleText()
  const features = useEditorFeatures()
  const navigateHistory = useAction('navigateHistory')

  const undoEnabled = useView(
    'main',
    view => view.designMeta.history.entryIndex > 0,
  )

  const redoEnabled = useView(
    'main',
    view =>
      view.designMeta.history.entryIndex <
      view.designMeta.history.entries.length - 1,
  )

  const handleUndo = useCallback(() => {
    navigateHistory(-1)
  }, [navigateHistory])

  const handleRedo = useCallback(() => {
    navigateHistory(1)
  }, [navigateHistory])

  if (!features.enableHistory) {
    return null
  }

  return (
    <Flex>
      <IconButton
        label={t('undo')}
        title={t('undo')}
        icon={IconUndo}
        disabled={!undoEnabled}
        onClick={handleUndo}
      />
      <IconButton
        label={t('redo')}
        title={t('redo')}
        icon={IconRedo}
        disabled={!redoEnabled}
        onClick={handleRedo}
      />
    </Flex>
  )
}
