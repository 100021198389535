import { Vec2, vec2Sub } from '@moonpig/common-math'

export type Frame = {
  position: Vec2
  size: Vec2
}

export const frameContainsPoint = (frame: Frame, point: Vec2): boolean => {
  return (
    point[0] >= frame.position[0] &&
    point[0] <= frame.position[0] + frame.size[0] &&
    point[1] >= frame.position[1] &&
    point[1] <= frame.position[1] + frame.size[1]
  )
}

export const frameIntersect = (f1: Frame, f2: Frame): Frame => {
  const topLeft: Vec2 = [
    Math.max(f1.position[0], f2.position[0]),
    Math.max(f1.position[1], f2.position[1]),
  ]

  const bottomRight: Vec2 = [
    Math.min(f1.position[0] + f1.size[0], f2.position[0] + f2.size[0]),
    Math.min(f1.position[1] + f1.size[1], f2.position[1] + f2.size[1]),
  ]

  return {
    position: topLeft,
    size: vec2Sub(bottomRight, topLeft),
  }
}
