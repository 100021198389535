import {
  Design,
  DesignElement,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import { assert } from '../../utils/assert'
import { Layout } from '../../layout'
import { useView } from '../../store'

export const useSceneIds = () => {
  return useView('main', view => view.design.sceneIds)
}

export const useScene = (sceneId: string) => {
  return useView('main', view => view.design.sceneById[sceneId])
}

export const useSelectedSceneId = () => {
  return useView('main', view => view.selectedSceneId)
}

export const useDesignStateType = () => {
  return useView('main', view => view.designState.type)
}

export const useDesignLayout = () => {
  return useView('main', view => view.design.layout)
}

export const useLayout = (): Layout => {
  return useView('main', view => view.layout)
}

export const getDesignElementByRef = <TElement extends DesignElement>(
  design: Design,
  elementRef: DesignElementRef<TElement>,
): TElement => {
  const element =
    design.sceneById[elementRef.sceneId].elementById[elementRef.id]

  assert(element.type === elementRef.type)

  return element as TElement
}

export const useElementRef = <TElement extends DesignElement>(
  elementRef: DesignElementRef<TElement>,
): TElement => {
  return useView('main', view => getDesignElementByRef(view.design, elementRef))
}

export const useElementDerived = <TElement extends DesignElement, TDerived>(
  elementRef: DesignElementRef<TElement>,
  derive: (element: TElement) => TDerived,
): TDerived => {
  return useView('main', view =>
    derive(getDesignElementByRef(view.design, elementRef)),
  )
}

export const useSelectedSceneLayout = () => {
  const selectedSceneId = useSelectedSceneId()
  const { sceneById } = useLayout()
  const scene = sceneById[selectedSceneId]
  return scene
}
