/* eslint-disable array-callback-return */
import { ContentPicker } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { DESKTOP_BP } from '../../../../../../../constants'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { PhotoImage } from './PhotoImage'
import { PhotoInput } from './PhotoInput'
import { PhotoPlaceholder } from './PhotoPlaceholder'

type Photo =
  | {
      type: 'loading'
      id: string
    }
  | {
      type: 'loaded'
      id: string
      selected: boolean
      imageUrl: string
    }

type PhotoLibraryProps = {
  labelledById: string
  photos: Photo[]
  selectLimit?: number
  multiSelect: boolean
  onAddPhotos: (files: Blob[]) => void
  onRemovePhoto: (id: string) => void
  onSelectPhoto: (id: string) => void
}

export const PhotoLibrary: FC<PhotoLibraryProps> = ({
  labelledById,
  photos,
  selectLimit = 1,
  multiSelect,
  onAddPhotos,
  onRemovePhoto,
  onSelectPhoto,
}) => {
  const t = useEditorLocaleText()

  return (
    <ContentPicker
      labelledById={labelledById}
      columnWidth={{ xs: 1 / 3, md: 1 / 5, [DESKTOP_BP]: 1 / 2 }}
      multiSelect={multiSelect}
      selectLimit={selectLimit}
      onSelectItem={onSelectPhoto}
      onDeleteItem={onRemovePhoto}
      addContentControl={<PhotoInput onChange={onAddPhotos} />}
      items={photos.map((photo, index) => {
        const name = `${index + 1}`

        switch (photo.type) {
          case 'loading': {
            return {
              type: 'loading' as const,
              id: photo.id,
              content: <PhotoPlaceholder name={name} />,
            }
          }
          case 'loaded': {
            return {
              type: 'loaded' as const,
              id: photo.id,
              selected: photo.selected,
              selectLabel:
                multiSelect && photo.selected
                  ? t('toolbar_remove_image_screen_reader_label', name)
                  : t('toolbar_apply_image_screen_reader_label', name),
              deleteLabel: t('toolbar_delete_image_screen_reader_label', name),
              content: <PhotoImage imageUrl={photo.imageUrl} />,
            }
          }
        }
      })}
    />
  )
}
