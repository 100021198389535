import { IllustrationCardPersonalisation } from '@moonpig/launchpad-assets'
import { LoadingScreen } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { useEditorLocaleText } from '../../../../../text-localisation'

export const LoadingPreview: FC = () => {
  const t = useEditorLocaleText()

  return (
    <LoadingScreen
      title={t('preview_loading')}
      description={t('preview_loading_description')}
      icon={<IllustrationCardPersonalisation />}
    />
  )
}
