import { IconSystemTextField } from '@moonpig/launchpad-assets'
import { useTheme } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { BORDER_WIDTH, ICON_SIZE } from '../constants'

type IndicatorTextProps = {
  scale: number
  invalid?: boolean
}

export const IndicatorText: FC<IndicatorTextProps> = ({ scale, invalid }) => {
  const theme = useTheme()
  const {
    colorFeedbackInformation,
    colorBackground01,
    colorTextBody,
    colorFeedbackWarning,
  } = theme.palette
  const iconSize = ICON_SIZE * scale
  const borderWidth = BORDER_WIDTH * scale
  const radius = ICON_SIZE * scale * (2 / 3)
  const offset = borderWidth * -0.5

  return (
    <g transform={`translate(${offset}, ${offset})`}>
      <circle
        r={radius}
        stroke={invalid ? colorFeedbackWarning : colorFeedbackInformation}
        strokeWidth={borderWidth}
        fill={colorBackground01}
      />
      <IconSystemTextField
        x={iconSize * -0.5}
        y={iconSize * -0.5}
        width={iconSize}
        height={iconSize}
        color={colorTextBody}
      />
    </g>
  )
}
