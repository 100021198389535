const PAD_DIVISOR = 32
const MIN_SIZE_DIVISOR = 4

export const getDefaultStickerSize = (
  sceneWidth: number,
  sceneHeight: number,
): [number, number] => {
  const minSceneSize = Math.min(sceneWidth, sceneHeight)
  const pad = minSceneSize / PAD_DIVISOR
  const usableSceneSize = minSceneSize - 2 * pad
  const defaultSize = usableSceneSize / MIN_SIZE_DIVISOR

  return [defaultSize, usableSceneSize]
}
