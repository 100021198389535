export const CHANGE_PAGE_THREHSOLD = 0.3
export const CREASE_COLOR = 'rgba(0,0,0,0.1)'
export const CREASE_SHADOW_COLOR = 'rgba(0,0,0,0.03)'
export const CREASE_SHADOW_SIZE = 80
export const MAX_OPEN_DEGREES = 110
export const PAGE_SHADOW_COLOR = 'rgba(0, 0, 0, 0.05)'
export const PAGE_SHADOW_SIZE = 24
export const PERSPECTIVE_MULTIPLIER = 5
export const SIDE_GAP = 1
export const TRANSITION_DURATION = 700
