import { ErrorScreen } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { useSession } from '../../store'
import { useEditorLocaleText } from '../../text-localisation'

export const ErrorFinish: FC = () => {
  const t = useEditorLocaleText()
  const session = useSession()

  return <ErrorScreen title={t('add_to_basket_error')} session={session} />
}
