import { useTheme } from '@moonpig/launchpad-utils'
import { RendererProviderNative } from '@moonpig/renderer-react-provider-native'
import { Editor } from '@moonpig/web-personalise-editor-app'
import { Session } from '@moonpig/web-personalise-editor-types'
import Head from 'next/head'
import React, { FC } from 'react'
import { useEditorConfig } from '../utils/useEditorConfig'
import { useEditorFeatures } from '../utils/useEditorFeatures'
import { useEditorServices } from '../utils/useEditorServices'

const session: Session = {
  sessionId: 'editor-demo',
  shortSessionId: 'editor-demo',
}

const Page: FC = () => {
  const { colors } = useTheme()
  const { colorBackground01 } = colors
  const config = useEditorConfig()
  const features = useEditorFeatures()
  const services = useEditorServices()

  return (
    <>
      <Head>
        <title>Editor Demo</title>
        <meta name="robots" content="noindex, follow" />
        <meta name="theme-color" content={colorBackground01} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <RendererProviderNative>
        <Editor
          config={config}
          features={features}
          session={session}
          services={services}
        />
      </RendererProviderNative>
    </>
  )
}

export default Page
