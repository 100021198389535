import { assert } from '../../../utils/assert'
import { ActionCreator, ViewEditModal } from '../../types'

export const createShowModal: ActionCreator<'showModal'> =
  ({ set }) =>
  async (modal: ViewEditModal) => {
    set(({ view }) => {
      assert(view.type === 'main')
      return {
        view: {
          ...view,
          view: {
            ...view.view,
            modal,
          },
        },
      }
    })
  }
