import { ActionCreator, UI } from '../../types'
import { storeUpdateMainView } from '../../updaters/main-view'
import { addNotification } from '../../common/addNotification'
import { assert } from '../../../utils/assert'

export const createStopAudioRecording: ActionCreator<'stopAudioRecording'> =
  ({ get, set, services }) =>
  async ({ discardRecording = false }) => {
    const { audioRecording, view } = get()

    assert(view.type === 'main')

    const maxSizeBytes = view.customisationConfig.audio.maxSizeBytes

    if (!audioRecording) {
      return
    }

    const response = await audioRecording.stop()

    if (!discardRecording) {
      switch (response.type) {
        case 'success': {
          const { data, url, duration, samples, timePerSampleSeconds } =
            response

          if (maxSizeBytes < data.size) {
            services.trackEvent({
              type: 'ERROR',
              kind: 'AUDIO_TOO_LARGE_ERROR',
            })

            set(() => {
              return {
                view: {
                  ...view,
                  notifications: addNotification(view.notifications, {
                    type: 'audio-file-too-large',
                  }),
                },
              }
            })
          } else {
            set(store => {
              return storeUpdateMainView(store, 'edit', () => {
                const ui: UI = {
                  type: 'default',
                  selectedMenuItem: 'audio-upload',
                  audio: {
                    file: data,
                    url,
                    duration,
                    samples,
                    timePerSampleSeconds,
                  },
                }

                return {
                  ui,
                }
              })
            })
          }
          break
        }
        case 'error': {
          set(() => {
            const ui: UI = {
              type: 'default',
              selectedMenuItem: 'audio-input',
            }

            return {
              view: {
                ...view,
                ui,
                notifications: addNotification(view.notifications, {
                  type: 'audio-recording-error',
                }),
              },
            }
          })
          break
        }
      }
    }

    set(() => {
      return {
        audioRecording: null,
      }
    })
  }
