import { assert } from '../../../utils/assert'
import { ActionCreator } from '../../types'

export const createUpdateSmartTextPrompt: ActionCreator<
  'updateSmartTextPrompt'
> = ({ set }) => {
  return prompt => {
    set(({ view }) => {
      assert(view.type === 'main')

      return {
        view: {
          ...view,
          smartTextPrompt: prompt,
        },
      }
    })
  }
}
