import {
  CollageGrid,
  CollageMode,
  ImageById,
  InteractiveCollage,
} from '@moonpig/web-personalise-collage'
import { DesignElementImageCollage } from '@moonpig/web-personalise-editor-types'
import React, { FC, MouseEvent, useCallback } from 'react'
import { useCamera } from '../../../../../../../camera'
import { isElementValid } from '../../../../../../../utils/isElementValid'
import {
  UI,
  getGestureChangeType,
  useAction,
  useMainView,
  useView,
} from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { useElementRef } from '../../../../../selectors'
import { useUI } from '../../../../selectors'
import { InteractiveElementProps } from '../../types'
import { InteractiveElementCommon } from '../InteractiveElementCommon'
import { clampBounds } from '../InteractiveElementCommon/clampBounds'
import { EmptyIndicator } from './components/EmptyIndicator'
import { getClipBounds } from './getClipBounds'

const useCollageImageById = (): ImageById => {
  return useView('main', ({ photos }) => {
    return photos.photoIds.reduce<ImageById>((acc, photoId) => {
      const photoState = photos.photoById[photoId]
      if (photoState.type === 'loaded') {
        const image = photoState.photo.mediumImage
        acc[photoId] = {
          size: [image.width, image.height],
          url: image.url,
        }
      }
      return acc
    }, {})
  })
}

type GetMode = (params: { ui: UI | null }) => CollageMode

const getMode: GetMode = ({ ui }) => {
  switch (ui?.type) {
    case 'image-collage': {
      if (ui.selectedMenuItem === 'border') {
        return 'static'
      }
      return 'manipulate'
    }
    case 'image-collage-cell': {
      return 'manipulate'
    }
    case 'image-collage-rearrange': {
      return 'rearrange'
    }
  }

  return 'static'
}

type ElementImageCollageInteractiveProps =
  InteractiveElementProps<DesignElementImageCollage>

export const ElementImageCollageInteractive: FC<
  ElementImageCollageInteractiveProps
> = ({ elementRef, title, sceneWidth, sceneHeight }) => {
  const t = useEditorLocaleText()
  const { inverseScale } = useCamera()
  const element = useElementRef(elementRef)
  const activeElementId = useMainView('edit', view => view.activeElementId)
  const ui = useUI()
  const isSelected = activeElementId === elementRef.id
  const updateElementRef = useAction('updateElementRef')
  const setUI = useAction('setUI')
  const highlightInvalidElements = useMainView(
    'edit',
    view => view.highlightInvalidElements,
  )
  const notify = useAction('notify')

  const { grid } = element.customisations
  const imageById = useCollageImageById()
  const isInvalid = highlightInvalidElements && !isElementValid(element).valid

  const selectedCellId =
    ui && ui.type === 'image-collage-cell' ? ui.cellId : null

  const handleSelectCell = useCallback(
    (cellId: string) => {
      if (selectedCellId === cellId) {
        setUI({
          type: 'image-collage',
          elementRef: element,
          selectedMenuItem: null,
        })
      } else {
        setUI({
          type: 'image-collage-cell',
          elementRef: element,
          cellId,
          selectedMenuItem: null,
        })
      }
    },
    [element, selectedCellId, setUI],
  )

  const handleChangeGrid = useCallback(
    (newGrid: CollageGrid, context: { last: boolean }) => {
      updateElementRef(
        elementRef,
        current => {
          return {
            ...current,
            customisations: {
              ...current.customisations,
              pendingEditedImage: null,
              grid: newGrid,
            },
          }
        },
        { changeType: getGestureChangeType(context) },
      )
    },
    [elementRef, updateElementRef],
  )

  const handleClick = useCallback((event: MouseEvent) => {
    event.stopPropagation()
  }, [])

  const createCellLabel = useCallback(
    (index: number): string => {
      return t('collage_image_label', String(index + 1))
    },
    [t],
  )

  const clipBounds = getClipBounds({ element, sceneWidth, sceneHeight })

  const handleSelect = useCallback(() => {
    if (!element.customisations.grid && element.customisations.editedImage) {
      notify({
        type: 'photo-missing',
        elementType: 'image-collage',
      })
    }
  }, [element.customisations.editedImage, element.customisations.grid, notify])

  const bounds = clampBounds({ element, sceneWidth, sceneHeight })

  return (
    <InteractiveElementCommon
      border={grid === null ? 'ALWAYS' : 'NONE'}
      title={title}
      sceneId={elementRef.sceneId}
      sceneWidth={sceneWidth}
      sceneHeight={sceneHeight}
      element={element}
      isInvalid={isInvalid}
      onSelect={handleSelect}
      interactiveContent={
        grid && (
          <g
            aria-hidden={!isSelected}
            style={{ pointerEvents: isSelected ? 'all' : 'none' }}
            onClick={handleClick}
            transform={`translate(-${clipBounds.position[0]} -${clipBounds.position[1]})`}
          >
            <InteractiveCollage
              createCellLabel={createCellLabel}
              label={t('collage_element_label')}
              mode={getMode({ ui })}
              grid={grid}
              width={element.width}
              height={element.height}
              scale={inverseScale}
              imageById={imageById}
              clipBounds={clipBounds}
              selectedCellId={selectedCellId}
              onSelectCell={handleSelectCell}
              onChangeGrid={handleChangeGrid}
            />
          </g>
        )
      }
    >
      {!grid && !element.customisations.editedImage && (
        <EmptyIndicator
          width={bounds.width}
          height={bounds.height}
          scale={inverseScale}
        />
      )}
    </InteractiveElementCommon>
  )
}
