import { Scene, SceneObject } from '@moonpig/renderer-scene-types'
import { CollageGrid, ImageById, layoutGrid, layoutImages } from '../model'

export const convertCollageGridToScene = ({
  grid,
  imageById,
  width,
  height,
  roundFrames,
  frameOverlap,
}: {
  grid: CollageGrid
  imageById: ImageById
  width: number
  height: number
  roundFrames?: boolean
  frameOverlap?: number
}): Scene => {
  const layout = layoutGrid({
    grid,
    size: [width, height],
    frameOverlap,
  })

  const imageLayoutById = layoutImages(imageById, layout)

  const round = roundFrames ? Math.round : (x: number) => x

  const imageObjects = layout.elements.map<SceneObject>(
    ({ id, frame, content }) => {
      const image = imageById[content.imageId]
      const { offset } = imageLayoutById[id]

      return {
        type: 'IMAGE',
        id,
        frame: {
          x: round(frame.position[0]),
          y: round(frame.position[1]),
          width: round(frame.size[0]),
          height: round(frame.size[1]),
          rotation: 0,
        },
        url: image.url,
        transform: {
          position: {
            x: round(offset[0]),
            y: round(offset[1]),
          },
          rotation: 0,
          scale: content.scale,
        },
      }
    },
  )

  const backgroundObject: SceneObject = {
    type: 'RECTANGLE',
    id: 'collage-background',
    fillColor: grid.background ? grid.background.color : '#ffffff',
    frame: {
      x: 0,
      y: 0,
      width,
      height,
      rotation: 0,
    },
    outline: null,
  }

  return {
    title: '',
    width,
    height,
    objects: [backgroundObject, ...imageObjects],
  }
}
