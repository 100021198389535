type FileSize = {
  type: 'mb' | 'kb'
  value: number
}

const ONE_KB_IN_BYTES = 1000
const ONE_MB_IN_BYTES = 1000000

export const formatFileSize = (sizeInBytes: number): FileSize => {
  const kb = Math.round(sizeInBytes / ONE_KB_IN_BYTES)
  const mb = Math.round(sizeInBytes / ONE_MB_IN_BYTES)

  if (kb >= 1000) {
    return { type: 'mb', value: mb }
  }

  return { type: 'kb', value: kb }
}
