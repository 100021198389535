import { binarySearch } from '../../../utils'

const MIN_FONT_SIZE = 2
const FONT_SIZE_STEP = 0.5

type FontSizeCollection = {
  count(): number
  get(index: number): number
}

const createFontSizeCollection = (
  min: number,
  max: number,
  step: number,
): FontSizeCollection => {
  const count = Math.floor((max - min) / step + 1)
  return {
    count: () => count,
    get: index => min + index * step,
  }
}

const findMaxFontSize = (
  fontSizeCollection: FontSizeCollection,
  predicate: (fontSize: number) => boolean,
): number | null => {
  const maxIndex = binarySearch(fontSizeCollection.count(), index =>
    predicate(fontSizeCollection.get(index)),
  )

  return /* istanbul ignore next */ maxIndex === null
    ? null
    : fontSizeCollection.get(maxIndex)
}

type FitTextArgs = {
  maxFontSize: number
  fitCheck(fontSize: number): boolean
}

type FitTextResult = {
  fontSize: number
}

export const fitText = ({
  maxFontSize,
  fitCheck,
}: FitTextArgs): FitTextResult => {
  const fontSizes = createFontSizeCollection(
    MIN_FONT_SIZE,
    maxFontSize,
    FONT_SIZE_STEP,
  )
  const scaledFontSize = findMaxFontSize(fontSizes, candidateFontSize => {
    return fitCheck(candidateFontSize)
  })

  const minFontSize = fontSizes.get(0)
  const fontSize = scaledFontSize || /* istanbul ignore next */ minFontSize

  return { fontSize }
}
