import {
  IconSystemEdit,
  IconSystemImage,
  IconSystemTextField,
} from '@moonpig/launchpad-assets'
import { AspectRatio, Flex, Text } from '@moonpig/launchpad-components'
import { useTheme } from '@moonpig/launchpad-utils'
import React, { FC, Fragment, useCallback } from 'react'
import { useLocaleText } from './LayoutPicker.locale'
import { LayoutElementContentType, LayoutIconProps } from './types'

const BORDER_WIDTH = 2
const INSET = 3
const ICON_SIZE = 24

const BlankLayoutIcon = ({
  width,
  height,
}: {
  width: number
  height: number
}) => {
  const t = useLocaleText()

  return (
    <AspectRatio ratio={width / height}>
      <Flex
        aria-hidden
        pt={6}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        color="colorInteractionIcon"
        gap="4px"
      >
        <IconSystemEdit width={ICON_SIZE} height={ICON_SIZE} />
        <Text typography="bodySmall">{t('blank_layout')}</Text>
      </Flex>
    </AspectRatio>
  )
}

export const LayoutGridIcon: FC<LayoutIconProps> = ({
  elements,
  width,
  height,
}) => {
  const { palette } = useTheme()

  const getContentTypeIcon = useCallback(
    (contentType: LayoutElementContentType) => {
      const icon = {
        image: <IconSystemImage width={ICON_SIZE} height={ICON_SIZE} />,
        text: <IconSystemTextField width={ICON_SIZE} height={ICON_SIZE} />,
      }
      return icon[contentType]
    },
    [],
  )

  if (!elements.length) return <BlankLayoutIcon width={width} height={height} />

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      style={{ width: '100%', height: '100%', display: 'block' }}
    >
      {elements.map(element => {
        const iconX = element.x + (element.width - ICON_SIZE) * 0.5
        const iconY = element.y + (element.height - ICON_SIZE) * 0.5

        return (
          <Fragment key={element.id}>
            <rect
              key={element.id}
              x={element.x + INSET}
              y={element.y + INSET}
              width={element.width - INSET * 2}
              height={element.height - INSET * 2}
              fill={'transparent'}
              stroke={palette.colorFeedbackInformation}
              strokeWidth={BORDER_WIDTH}
              strokeDasharray={`${BORDER_WIDTH * 2} ${BORDER_WIDTH * 2}`}
            />
            <g
              transform={`translate(${iconX}, ${iconY})`}
              style={{ color: palette.colorTextBody }}
            >
              {getContentTypeIcon(element.contentType)}
            </g>
          </Fragment>
        )
      })}
    </svg>
  )
}
