import React, { FC } from 'react'

const BACKGROUND_COLOR = '#F8F8F9'
const COLOR = '#087827'

export const IconDone: FC<{
  width?: number
  height?: number
}> = ({ width = 40, height = 40 }) => {
  return (
    <svg viewBox="0 0 40 40" width={width} height={height}>
      <circle
        cx="20"
        cy="20"
        r="19"
        fill={BACKGROUND_COLOR}
        stroke={COLOR}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.707 14.293a1 1 0 0 1 0 1.414l-10 10a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414L17 23.586l9.293-9.293a1 1 0 0 1 1.414 0Z"
        fill={COLOR}
      />
    </svg>
  )
}
