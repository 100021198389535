import 'canvas-toBlob'
import { wrapError } from '../utils/wrapError'

type BaseInput = {
  width: number
  height: number
}

export type SequentialCanvasRenderer<TInput extends BaseInput> = (
  input: TInput,
) => Promise<Blob>

export const createSequentialCanvasRenderer = <TInput extends BaseInput>(
  render: (ctx: CanvasRenderingContext2D, input: TInput) => Promise<void>,
): SequentialCanvasRenderer<TInput> => {
  let isRendering = false

  const renderQueue: {
    input: TInput
    resolve: (result: Promise<Blob>) => void
  }[] = []

  const canvas = document.createElement('canvas')

  const renderToCanvas: SequentialCanvasRenderer<TInput> = async input => {
    if (isRendering) {
      return new Promise<Blob>(resolve => {
        renderQueue.push({ input, resolve })
      })
    }
    isRendering = true

    canvas.width = input.width
    canvas.height = input.height

    const ctx = canvas.getContext('2d')

    if (!ctx) {
      throw new Error('Failed to create canvas context')
    }

    await render(ctx, input)

    const result = await new Promise<Blob>((resolve, reject) => {
      try {
        canvas.toBlob(blob => {
          if (blob) {
            resolve(blob)
          } else {
            reject(new Error('Failed to extract canvas data'))
          }
        }, 'image/jpeg')
      } catch (error) {
        throw wrapError('Failed to extract blob from canvas', error)
      }
    })

    isRendering = false

    const next = renderQueue.shift()
    if (next) {
      next.resolve(renderToCanvas(next.input))
    }

    return result
  }

  return renderToCanvas
}
