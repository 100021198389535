import {
  TextEditable,
  TextEditableDoneEvent,
  TextEditableFragmentsChangedEvent,
  TextEditableGetFragments,
} from '@moonpig/web-personalise-components'
import {
  DesignElement,
  DesignElementOverlayText,
  DesignElementRef,
  DesignElementTextPlain,
  DesignElementTextStyled,
  DesignFontMetrics,
  DesignHorizontalAlignment,
  DesignShadow,
  DesignTextEditable,
  DesignVerticalAlignment,
} from '@moonpig/web-personalise-editor-types'
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { animated } from 'react-spring'
import { useCamera } from '../../../../../camera'
import {
  FOCUS_ID_TEXT_INPUT,
  TEXT_ELEMENT_MAX_CHARACTER_LIMIT,
} from '../../../../../constants'
import { assert } from '../../../../../utils/assert'
import { findEmojis } from '../../../../../utils/findEmojis'
import { inputIsValid, sanitizeInput } from '../../../../../utils/inputIsValid'
import { useAction, useEditorFeatures, useView } from '../../../../../store'
import { useElementRef, useLayout } from '../../../selectors'
import {
  useActiveKeyboardElementRef,
  useSelectedElementRef,
} from '../../selectors'
import { LayerTextTooltip } from './LayerTextTooltip/LayerTextTooltip'

const MM_PER_POINT = 25.4 / 72

const convertPointsToMM = (points: number): number => points * MM_PER_POINT

const BASE_FONT_SIZE = convertPointsToMM(16)
const RESIZE_THRESHOLD = 1

type ElementTextProperties = {
  horizontalAlignment: DesignHorizontalAlignment
  verticalAlignment: DesignVerticalAlignment
  color: string
  fontFamily: string
  fontSize: number
  fontMetrics: DesignFontMetrics
}

const useElementTextProperties = (
  element:
    | DesignElementTextPlain
    | DesignElementTextStyled
    | DesignElementOverlayText,
): ElementTextProperties => {
  const fontById = useView('main', view => view.fontById)
  const {
    availableTextStyles: { availableColorById, availableFontFaceById },
  } = useView('main', view => view.design)

  switch (element.type) {
    case 'text-plain': {
      return {
        horizontalAlignment: element.horizontalAlignment,
        verticalAlignment: element.verticalAlignment,
        color: element.color.value,
        fontFamily: fontById[element.fontFace.id].fontFamily,
        fontSize: element.fontSize,
        fontMetrics: element.fontFace.metrics,
      }
    }
    case 'text-styled': {
      return {
        horizontalAlignment: element.customisations.horizontalAlignment,
        verticalAlignment: element.customisations.verticalAlignment,
        color: element.availableColorById[element.customisations.colorId].value,
        fontFamily: fontById[element.customisations.fontFaceId].fontFamily,
        fontSize: element.customisations.fontSize,
        fontMetrics: element.fontFace.metrics,
      }
    }
    case 'overlay-text': {
      return {
        horizontalAlignment: element.customisations.horizontalAlignment,
        verticalAlignment: element.customisations.verticalAlignment,
        color: availableColorById[element.customisations.colorId].value,
        fontFamily: fontById[element.customisations.fontFaceId].fontFamily,
        fontSize: element.customisations.fontSize,
        fontMetrics:
          availableFontFaceById[element.customisations.fontFaceId].metrics,
      }
    }
  }
}

const scaleShadow = (shadow: DesignShadow, scale: number): DesignShadow => {
  return {
    color: shadow.color,
    offsetX: shadow.offsetX * scale,
    offsetY: shadow.offsetY * scale,
  }
}

const updateElementText = (
  element:
    | DesignElementTextPlain
    | DesignElementTextStyled
    | DesignElementOverlayText,
  text: string,
): DesignElement => {
  switch (element.type) {
    case 'text-plain': {
      return {
        ...element,
        valid: true,
        customisations: { ...element.customisations, text },
      }
    }
    case 'text-styled': {
      return {
        ...element,
        valid: true,
        customisations: { ...element.customisations, text },
      }
    }
    case 'overlay-text': {
      return {
        ...element,
        customisations: {
          ...element.customisations,
          text,
        },
      }
    }
  }
}

const LayerTextContent: FC<{
  elementRef: DesignElementRef
  inactive?: boolean
}> = ({ elementRef, inactive }) => {
  const element = useElementRef(elementRef)
  const layout = useLayout()
  const { enableEmojis, enableTextFragments } = useEditorFeatures()
  const transformedElementRef = useRef<HTMLDivElement>(null)
  const hidden =
    inactive &&
    !(element.type === 'overlay-text' && element.fragmentsState?.resizingWidth)

  assert(
    element.type === 'text-plain' ||
      element.type === 'text-styled' ||
      element.type === 'overlay-text',
  )

  const editableText: undefined | DesignTextEditable =
    element.type !== 'overlay-text' ? element.editableText : undefined

  const [text, setText] = useState(element.customisations.text)
  useEffect(() => {
    setText(element.customisations.text)
  }, [element.customisations.text])
  const { scale, translate } = useCamera()

  const {
    horizontalAlignment,
    verticalAlignment,
    color,
    fontFamily,
    fontSize,
    fontMetrics,
  } = useElementTextProperties(element)

  const textScale = enableTextFragments
    ? BASE_FONT_SIZE / convertPointsToMM(fontSize)
    : 1

  const sceneLayout = layout.sceneById[elementRef.sceneId]

  const deselectElement = useAction('deselectElement')
  const updateElement = useAction('updateElement')
  const updateElementRef = useAction('updateElementRef')
  const trackEvent = useAction('trackEvent')
  const notify = useAction('notify')
  const updateOnboardingViewed = useAction('updateOnboardingViewed')
  const setUI = useAction('setUI')
  const emojiOnboardingViewed = useView(
    'main',
    view => view.onboardingViewed.emoji,
  )

  const handleTruncated = useCallback(() => {
    trackEvent({
      type: 'ERROR',
      kind: 'TEXT_TRUNCATED',
    })

    notify({
      type: 'text-truncated',
    })
  }, [notify, trackEvent])

  const transformText = useCallback(
    (newText: string): string => {
      const sanitizedText = enableEmojis ? newText : sanitizeInput(newText)

      if (sanitizedText !== newText) {
        const hasEmojis = findEmojis(newText).length > 0

        trackEvent({
          type: 'ERROR',
          kind: hasEmojis ? 'FILTERED_EMOJI' : 'FILTERED_CHARACTER',
        })

        notify({
          type: 'text-filtered',
          hasEmojis,
        })
      }

      return sanitizedText
    },
    [enableEmojis, notify, trackEvent],
  )

  const dismissEmojiOnboarding = useCallback(() => {
    updateOnboardingViewed({ emoji: true })
  }, [updateOnboardingViewed])

  const showEmojiOnboardingTooltip =
    ['overlay-text', 'text-styled'].includes(element.type) &&
    !emojiOnboardingViewed &&
    enableEmojis

  const handleChange = useCallback(
    (newText: string) => {
      setText(newText)

      updateElement(updateElementText(element, newText), {
        changeType: 'minor',
      })
    },
    [element, updateElement],
  )

  const updateElementFragments = useCallback(
    (input: { getFragments: TextEditableGetFragments }) => {
      if (!enableTextFragments || !hidden) {
        return
      }

      if (elementRef.type === 'overlay-text') {
        const transformedElement = transformedElementRef.current

        if (!transformedElement) {
          return
        }

        const transform = transformedElement.style.transform

        // Rotation of the text element inteferes with extracting the text fragments
        // Temporarily remove the transform and reset after fragments have been extracted
        transformedElement.style.transform = 'none'
        const fragments = input.getFragments()
        transformedElement.style.transform = transform

        updateElementRef<DesignElementOverlayText>(
          elementRef as DesignElementRef<DesignElementOverlayText>,
          current => {
            const textFragmentsUnsupported =
              !current.fragmentsState ||
              current.customisations.horizontalAlignment === 'JUSTIFY' ||
              !inputIsValid(current.customisations.text)

            if (textFragmentsUnsupported) {
              return {
                ...current,
                fragmentsState: null,
              }
            }

            return {
              ...current,
              fragmentsState: {
                fragments: fragments.map(fragment => {
                  return {
                    text: fragment.text,
                    x: fragment.x / scale,
                    y: fragment.y / scale,
                    fontSize: fragment.fontSize / (scale * textScale),
                  }
                }),
                version: 0,
                resizingWidth: false,
              },
            }
          },
          { changeType: 'default' },
        )
      }
    },
    [
      hidden,
      enableTextFragments,
      elementRef,
      updateElementRef,
      scale,
      textScale,
    ],
  )

  const handleDone = useCallback(
    (event: TextEditableDoneEvent) => {
      updateElementFragments(event)

      if (!emojiOnboardingViewed && showEmojiOnboardingTooltip) {
        dismissEmojiOnboarding()
      }

      switch (element.type) {
        case 'text-plain':
          deselectElement()
          break
        case 'overlay-text': {
          updateElement(element, { changeType: 'default' })
          setUI({
            type: 'overlay-text',
            elementRef: element,
            selectedMenuItem: null,
          })
          break
        }
        case 'text-styled': {
          setUI({
            type: 'text-styled',
            elementRef: element,
            selectedMenuItem: null,
          })
          break
        }
      }
    },
    [
      updateElementFragments,
      emojiOnboardingViewed,
      showEmojiOnboardingTooltip,
      element,
      dismissEmojiOnboarding,
      deselectElement,
      updateElement,
      setUI,
    ],
  )

  let lineSpacingRelative = element.lineSpacingRelative
  let baselinePercentOfHeight: number | undefined

  if (enableTextFragments) {
    const ascender = fontMetrics.hhea.ascender
    const descender = Math.abs(fontMetrics.hhea.descender)
    const lineGap = fontMetrics.hhea.lineGap

    baselinePercentOfHeight = ascender / (ascender + descender)

    if (!element.lineSpacingRelative) {
      lineSpacingRelative =
        (ascender + descender + lineGap) / fontMetrics.head.unitsPerEm
    }
  }

  const handleFragmentsChanged = useCallback(
    (event: TextEditableFragmentsChangedEvent) => {
      updateElementFragments(event)
    },
    [updateElementFragments],
  )

  const onResize = useMemo(() => {
    if (element.type !== 'overlay-text' || element.fixed) {
      return undefined
    }

    /* istanbul ignore next: covered in browser tests */
    return (event: { height: number }) => {
      const newHeight = element.height * event.height
      const newElement = {
        ...element,
        height: newHeight,
      }

      if (Math.abs(newHeight - element.height) > RESIZE_THRESHOLD) {
        updateElement(newElement, { changeType: 'minor' })
      }

      return true
    }
  }, [element, updateElement])

  return (
    <animated.div
      data-testid="mp-ed-layer-text"
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        transformOrigin: 'top left',
        pointerEvents: inactive ? 'none' : 'all',
        opacity: hidden ? 0 : 1,
      }}
      onClick={
        /* istanbul ignore next */ event => {
          event.stopPropagation()
        }
      }
    >
      <div
        style={{
          position: 'absolute',
          left: (sceneLayout.x + element.x) * scale + translate[0],
          top: (sceneLayout.y + element.y) * scale + translate[1],
          width: element.width * scale * textScale,
          height: element.height * scale * textScale,
          transformOrigin: 'top left',
          transform: `scale(${1 / textScale})`,
        }}
      >
        <div
          ref={transformedElementRef}
          style={{
            width: '100%',
            height: '100%',
            transformOrigin: 'center',
            transform: `rotate(${element.rotation}deg)`,
          }}
        >
          <TextEditable
            focusId={FOCUS_ID_TEXT_INPUT}
            hidden={inactive}
            text={text}
            color={color}
            shadow={element.shadow && scaleShadow(element.shadow, scale)}
            horizontalAlignment={horizontalAlignment}
            verticalAlignment={verticalAlignment}
            fontSize={
              enableTextFragments
                ? BASE_FONT_SIZE * scale
                : convertPointsToMM(fontSize * scale)
            }
            lineSpacingRelative={lineSpacingRelative}
            fontFamily={fontFamily}
            maxCharacterLimit={
              editableText?.maxCharacters ?? TEXT_ELEMENT_MAX_CHARACTER_LIMIT
            }
            textTransform={editableText?.textTransform ?? null}
            transformText={transformText}
            onChange={handleChange}
            onDone={handleDone}
            onTruncated={handleTruncated}
            onResize={onResize}
            onFragmentsChanged={handleFragmentsChanged}
            fragmentsVersion={
              element.type === 'overlay-text' && element.fragmentsState
                ? element.fragmentsState.version
                : 0
            }
            baselinePercentOfHeight={baselinePercentOfHeight}
          />
        </div>
        <div style={{ transform: `scale(${textScale})` }}>
          {showEmojiOnboardingTooltip && <LayerTextTooltip />}
        </div>
      </div>
    </animated.div>
  )
}

export const LayerText: FC = () => {
  const activeKeyboardElementRef = useActiveKeyboardElementRef()
  const selectedElementRef = useSelectedElementRef()

  if (selectedElementRef && selectedElementRef.type === 'overlay-text') {
    return (
      <LayerTextContent
        key={selectedElementRef.id}
        elementRef={selectedElementRef}
        inactive={activeKeyboardElementRef === null}
      />
    )
  }

  if (!activeKeyboardElementRef) {
    return null
  }

  return (
    <LayerTextContent
      key={activeKeyboardElementRef.id}
      elementRef={activeKeyboardElementRef}
    />
  )
}
