import React, { FC } from 'react'

export const IconSmartText: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="2 0 24 24" width={width} height={height}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6959 3.71086C20.8188 2.83369 19.3966 2.83369 18.5194 3.71086L6.16569 16.0646C5.74446 16.4858 5.50781 17.0571 5.50781 17.6528V20.5568C5.50781 21.1771 6.01063 21.6799 6.63088 21.6799H9.53489C10.1306 21.6799 10.7019 21.4433 11.1231 21.022L23.4769 8.66831C24.354 7.79114 24.354 6.36897 23.4769 5.4918L21.6959 3.71086ZM18.6896 6.71714L20.1077 5.29911L21.8886 7.08005L20.4706 8.49808L18.6896 6.71714ZM17.4984 7.90833L7.8702 17.5366L7.96033 17.807C8.1839 18.4777 8.7102 19.004 9.38091 19.2276L9.65106 19.3176L19.2794 9.68927L17.4984 7.90833Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0932 3.12717C8.071 3.02825 7.85444 3.02825 7.83224 3.12717C7.48482 4.67508 6.63409 5.87866 5.5701 6.30507C5.48705 6.33835 5.48705 6.49105 5.5701 6.52433C6.63408 6.95074 7.48481 8.15431 7.83222 9.70221C7.85443 9.80113 8.071 9.80113 8.0932 9.70221C8.44061 8.15431 9.29134 6.95073 10.3553 6.52431C10.4384 6.49103 10.4384 6.33835 10.3553 6.30507C9.29134 5.87865 8.44061 4.67507 8.0932 3.12717Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.7299 14.9453C21.7077 14.8464 21.4912 14.8464 21.469 14.9453C21.1215 16.4932 20.2708 17.6968 19.2068 18.1232C19.1238 18.1565 19.1238 18.3092 19.2068 18.3424C20.2708 18.7688 21.1215 19.9724 21.4689 21.5203C21.4911 21.6192 21.7077 21.6192 21.7299 21.5203C22.0773 19.9724 22.9281 18.7688 23.992 18.3424C24.0751 18.3091 24.0751 18.1565 23.992 18.1232C22.9281 17.6968 22.0773 16.4932 21.7299 14.9453Z"
        fill="currentColor"
      />
    </svg>
  )
}
