import { focusIndicatorStyles } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { colorValue } from '@moonpig/launchpad-theme'
import { breakpoint, styled } from '@moonpig/launchpad-utils'
import React, { FC, MouseEvent, ReactNode, useCallback, useMemo } from 'react'
import { useElementBounds } from '../../utils'
import { NavButton } from './NavButton'

type Bounds = {
  x: number
  y: number
  width: number
  height: number
}

const calculateBounds = (bounds: Bounds[]): Bounds => {
  let minX = Infinity
  let minY = Infinity
  let maxX = 0
  let maxY = 0
  for (let i = 0; i < bounds.length; i += 1) {
    const scene = bounds[i]
    minX = Math.min(scene.x, minX)
    minY = Math.min(scene.y, minY)
    maxX = Math.max(scene.x + scene.width, maxX)
    maxY = Math.max(scene.y + scene.height, maxY)
  }

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  }
}

const StyledMinimap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledButton = styled.button`
  ${focusIndicatorStyles}

  width: 100%;
  height: 100%;
  position: relative;

  &:focus-visible {
    z-index: 2;
  }
`

const StyledButtonOutline = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  outline: 1px solid ${colorValue('colorBorder02')};

  &.selected {
    outline: 2px solid ${colorValue('colorInteractionTextLink')};
    z-index: 1;
    outline-offset: -1px;
  }
`

const SceneButton: FC<{
  sceneId: string
  isSelected: boolean
  title: string
  onClick: () => void
}> = ({ sceneId, isSelected, title, onClick, children }) => {
  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      onClick()
    },
    [onClick],
  )

  return (
    <StyledButton
      type="button"
      data-testid="mp-editor-minimap-scene-button"
      aria-current={isSelected ? 'page' : undefined}
      aria-label={title}
      key={sceneId}
      onClick={handleClick}
    >
      {children}
      <StyledButtonOutline className={isSelected ? 'selected' : undefined} />
    </StyledButton>
  )
}

const StyledNavButtonLayout = styled.div`
  position: relative;
  height: 100%;

  > div {
    display: flex;
    align-items: center;
    position: relative;
    ${s({ px: { xs: 0, lg: 8 } })}
  }
`

const NavButtonLayout: FC<{
  sceneLayout: { y: number; height: number }
}> = ({ children, sceneLayout }) => {
  return (
    <StyledNavButtonLayout>
      <div
        style={{
          top: sceneLayout.y,
          height: sceneLayout.height,
        }}
      >
        {children}
      </div>
    </StyledNavButtonLayout>
  )
}

type BoundedMinimapProps = MinimapProps & {
  height: number
}

const BoundedMinimap: FC<BoundedMinimapProps> = ({
  height: overallHeight,
  onSelectScene,
  scenes,
  selectedSceneId,
}) => {
  const layoutBounds = useMemo(
    () => calculateBounds(scenes.map(({ bounds }) => bounds)),
    [scenes],
  )

  const hasMultipleScenes = scenes.length > 1
  const scale = overallHeight / layoutBounds.height
  const overallWidth = scale * layoutBounds.width

  const sceneLayouts = useMemo(() => {
    return scenes.map(scene => {
      const x = (scene.bounds.x - layoutBounds.x) * scale
      const y = (scene.bounds.y - layoutBounds.y) * scale
      const width = scene.bounds.width * scale
      const height = scene.bounds.height * scale

      return { x, y, width, height }
    })
  }, [scenes, layoutBounds.x, layoutBounds.y, scale])

  return (
    <StyledMinimap data-testid="mp-editor-minimap">
      {hasMultipleScenes ? (
        <NavButtonLayout sceneLayout={sceneLayouts[0]}>
          <NavButton
            disabled={selectedSceneId === scenes[0].sceneId}
            direction="previous"
            onClick={() => {
              const currentIndex = scenes.findIndex(
                scene => scene.sceneId === selectedSceneId,
              )

              onSelectScene(scenes[currentIndex - 1].sceneId, 'CHEVRON')
            }}
          />
        </NavButtonLayout>
      ) : null}

      <div
        style={{ position: 'relative', width: overallWidth, height: '100%' }}
      >
        {scenes.map(({ sceneId, title, content }, i) => {
          const sceneLayout = sceneLayouts[i]
          return (
            <div
              key={sceneId}
              style={{
                position: 'absolute',
                left: sceneLayout.x,
                top: sceneLayout.y,
                width: sceneLayout.width,
                height: sceneLayout.height,
              }}
            >
              <SceneButton
                key={sceneId}
                sceneId={sceneId}
                isSelected={sceneId === selectedSceneId}
                title={title}
                onClick={() => onSelectScene(sceneId, 'MINIMAP')}
              >
                {content}
              </SceneButton>
            </div>
          )
        })}
      </div>
      {hasMultipleScenes ? (
        <NavButtonLayout sceneLayout={sceneLayouts[0]}>
          <NavButton
            disabled={selectedSceneId === scenes[scenes.length - 1].sceneId}
            direction="next"
            onClick={() => {
              const currentIndex = scenes.findIndex(
                scene => scene.sceneId === selectedSceneId,
              )

              onSelectScene(scenes[currentIndex + 1].sceneId, 'CHEVRON')
            }}
          />
        </NavButtonLayout>
      ) : null}
    </StyledMinimap>
  )
}

const StyledContainer = styled.div`
  height: calc(100% - 16px);
  ${breakpoint('lg')} {
    height: calc(100% - 32px);
  }
`

type MinimapProps = {
  scenes: {
    sceneId: string
    title: string
    bounds: Bounds
    content: ReactNode
  }[]
  selectedSceneId: string
  onSelectScene: (id: string, method: 'MINIMAP' | 'CHEVRON') => void
}

export const Minimap: FC<MinimapProps> = ({
  onSelectScene,
  scenes,
  selectedSceneId,
}) => {
  const [bounds, ref] = useElementBounds()

  return (
    <StyledContainer ref={ref}>
      {bounds ? (
        <BoundedMinimap
          height={bounds.height}
          scenes={scenes}
          selectedSceneId={selectedSceneId}
          onSelectScene={onSelectScene}
        />
      ) : /* istanbul ignore next */ null}
    </StyledContainer>
  )
}
