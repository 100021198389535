import React, { FC, useMemo } from 'react'
import {
  Drawer,
  DrawerStopPosition,
  PAGE_LAYOUT_HEADER_HEIGHT_MOBILE,
} from '@moonpig/web-personalise-components'
import { Menu } from './Menu'
import { ToolbarMenu, ToolbarMenuDrawerHeight } from './types'
import { useWindowSize } from '../../../../../../../utils/useWindowSize'

const DRAWER_HEADER_GAP = 8

export const DRAWER_FIRST_STOP_POSITION = 1 / 3

type MenuProps = {
  id?: string
  menu?: ToolbarMenu
  drawerHeight?: ToolbarMenuDrawerHeight
  onClose: () => void
}

export const MenuLeft: FC<MenuProps> = ({ id, menu, onClose }) => {
  return menu ? (
    <Menu id={id} menu={menu} orientation="vertical" onClose={onClose} />
  ) : null
}

export const MenuBottom: FC<MenuProps> = ({
  id,
  menu,
  drawerHeight = 'default',
  onClose,
}) => {
  const content = useMemo(() => {
    return menu ? (
      <Menu id={id} menu={menu} orientation="horizontal" onClose={onClose} />
    ) : null
  }, [id, menu, onClose])

  const windowSize = useWindowSize()

  const stopPositions = useMemo<DrawerStopPosition[]>(() => {
    const expandedStop =
      windowSize.height - PAGE_LAYOUT_HEADER_HEIGHT_MOBILE - DRAWER_HEADER_GAP

    switch (drawerHeight) {
      case 'default': {
        return [windowSize.height * DRAWER_FIRST_STOP_POSITION, expandedStop]
      }
      case 'fit': {
        return ['auto']
      }
      case 'expand': {
        return [expandedStop]
      }
    }
  }, [drawerHeight, windowSize.height])

  return (
    <div
      onPointerMove={
        /* istanbul ignore next */ event => {
          event.stopPropagation()
        }
      }
    >
      <Drawer
        onClose={onClose}
        stopPositions={stopPositions}
        overlapHandle={menu?.drawerOverlapHandle}
      >
        {content}
      </Drawer>
    </div>
  )
}
