import { IllustrationCardHearts } from '@moonpig/launchpad-assets'
import { LoadingScreen } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { useMainView } from '../../store'
import { useEditorLocaleText } from '../../text-localisation'

export const LoadingExit: FC = () => {
  const t = useEditorLocaleText()
  const saving = useMainView('loading-exit', view => view.saving)

  return (
    <LoadingScreen
      title={t('exiting_title')}
      description={saving ? t('exiting_description') : ''}
      icon={<IllustrationCardHearts />}
    />
  )
}
