import { assert } from '../../utils/assert'
import { Store, Views } from '../types'

export const storeUpdateView = <TView extends keyof Views>(
  store: Store,
  viewType: TView,
  updater: (view: Views[TView]) => Partial<Views[TView]>,
): Store => {
  assert(store.view.type === viewType)

  return {
    ...store,
    view: {
      ...store.view,
      ...updater(store.view as Views[TView]),
    },
  }
}
