export const getInitialScale = (
  imageWidth: number,
  imageHeight: number,
  apertureWidth: number,
  apertureHeight: number,
): number => {
  const imageAspect = imageWidth / imageHeight
  const apertureAspect = apertureWidth / apertureHeight
  const fillWidth = apertureAspect > imageAspect

  return fillWidth ? apertureWidth / imageWidth : apertureHeight / imageHeight
}
