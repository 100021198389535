import { assert } from '../../../utils/assert'
import { ActionCreator } from '../../types'

export const createUpdateOnboardingViewed: ActionCreator<
  'updateOnboardingViewed'
> =
  ({ set, services }) =>
  onboardingViewed => {
    set(({ view }) => {
      assert(view.type === 'main')

      services.saveUserState({
        emojiOnboardingViewed: onboardingViewed.emoji,
        textOnboardingViewed: onboardingViewed.text,
        photosAndStickersOnboardingViewed: onboardingViewed.photosAndStickers,
        layoutsOnboardingViewed: onboardingViewed.layouts,
        videoOnboardingViewed: onboardingViewed.video,
        smartTextOnboardingViewed: onboardingViewed.smartText,
        audioOnboardingViewed: onboardingViewed.audio,
      })
      return {
        view: {
          ...view,
          onboardingViewed: {
            ...view.onboardingViewed,
            ...onboardingViewed,
          },
        },
      }
    })
  }
