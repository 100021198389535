/* eslint-disable class-methods-use-this */
import {
  NetworkAdapter,
  PeerId,
  RepoMessage,
  cbor,
} from '@automerge/automerge-repo'
import { CollaboratePeer } from '@moonpig/web-personalise-editor-types'
import { z } from 'zod'
import { throttle } from '@moonpig/launchpad-utils'

const webSocketUrl = 'wss://ws.editor-collab.dev.moonpig.net'
const joinIntervalInSeconds = 60
const maxSendCount = 2000
const maxSendRateMs = 100

const messageFromClientSchema = z.union([
  z.object({
    action: z.literal('join'),
    designId: z.string(),
    peerId: z.string(),
    name: z.string(),
  }),
  z.object({
    action: z.literal('message'),
    designId: z.string(),
    peerId: z.string(),
    message: z.string(),
  }),
])

type MessageFromClient = z.infer<typeof messageFromClientSchema>

const messageFromServerSchema = z.union([
  z.object({
    action: z.literal('join-result'),
    success: z.boolean(),
    remotePeerId: z.string(),
  }),
  z.object({
    action: z.literal('send-result'),
    success: z.boolean(),
  }),
  z.object({
    action: z.literal('message'),
    message: z.string(),
  }),
  z.object({
    action: z.literal('error'),
    message: z.string(),
  }),
  z.object({
    action: z.literal('peers-updated'),
    peers: z.array(z.object({ id: z.string(), name: z.string() })),
  }),
])

type MessageFromServer = z.infer<typeof messageFromServerSchema>

const attemptParseMessageFromServer = (
  data: unknown,
): MessageFromServer | null => {
  try {
    return messageFromServerSchema.parse(JSON.parse(String(data)))
  } catch {
    return null
  }
}

export class WebSocketNetworkAdapter extends NetworkAdapter {
  private socket: WebSocket | undefined

  private socketSendThrottled: (message: string) => void = () => {}

  private name = ''

  private designId = ''

  private selfPeerId = ''

  private remotePeerId = ''

  private onPeersUpdated: (peers: CollaboratePeer[]) => void

  private joinTimeout: NodeJS.Timeout | null = null

  private sentCount = 0

  constructor(input: {
    name: string
    designId: string
    onPeersUpdated: (peers: CollaboratePeer[]) => void
  }) {
    super()

    this.name = input.name
    this.designId = input.designId
    this.onPeersUpdated = input.onPeersUpdated
  }

  public getPeerId(): string {
    return this.selfPeerId
  }

  public updateName(name: string) {
    this.name = name
    this.joinRequest()
  }

  private sendMessageFromClient(messageFromClient: MessageFromClient) {
    if (!this.socket) {
      console.error('No socket to send message')
      return
    }

    if (this.sentCount >= maxSendCount) {
      console.error('Exceeded max send count')
      return
    }

    this.socketSendThrottled(JSON.stringify(messageFromClient))
    this.sentCount += 1
  }

  private joinRequest() {
    console.log('>>>>>>>> join request')

    if (this.joinTimeout !== null) {
      clearTimeout(this.joinTimeout)
    }

    this.sendMessageFromClient({
      action: 'join',
      designId: this.designId,
      peerId: this.selfPeerId,
      name: this.name,
    })

    this.joinTimeout = setTimeout(() => {
      this.joinRequest()
    }, joinIntervalInSeconds * 1000)
  }

  connect(peerId: PeerId) {
    console.log('>>>>>>>> connect', peerId)
    this.selfPeerId = peerId

    const socket = new WebSocket(webSocketUrl)
    this.socket = socket

    this.socketSendThrottled = throttle((message: string) => {
      socket.send(message)
    }, maxSendRateMs)

    socket.addEventListener('open', () => {
      console.log('>>>>>>>> ws open')

      this.joinRequest()
    })

    socket.addEventListener('close', () => {
      console.log('>>>>>>>> ws close')
    })

    socket.addEventListener('error', () => {
      console.log('>>>>>>>> ws error')
    })

    socket.addEventListener('message', event => {
      const message = attemptParseMessageFromServer(event.data)

      if (!message) {
        console.error('WebSocket message does not match schema:', event.data)
        return
      }

      switch (message.action) {
        case 'join-result': {
          if (!message.success) {
            console.error('Join was not successful')
            return
          }

          console.log('Joined successfully')
          this.remotePeerId = message.remotePeerId
          this.emit('ready', { network: this })
          this.emit('peer-candidate', { peerId: this.remotePeerId as PeerId })
          break
        }
        case 'send-result': {
          if (!message.success) {
            console.error('Send was not successful')
          }

          break
        }
        case 'message': {
          const data = new Uint8Array(
            message.message.split(',').map(byte => parseInt(byte, 10)),
          )
          const repoMessage = cbor.decode<RepoMessage>(data)
          console.log('>>>>>>>> receive', repoMessage)
          this.emit('message', repoMessage)
          break
        }
        case 'error': {
          console.error('Server error:', message.message)
          break
        }
        case 'peers-updated': {
          this.onPeersUpdated(message.peers)
          break
        }
      }
    })
  }

  disconnect(): void {
    console.log('>>>>>>>> disconnect')
  }

  private lastSentMessage = ''

  private duplicateMessageCount = 0

  send(repoMessage: RepoMessage): void {
    const message = cbor.encode(repoMessage).toString()
    const maxDuplicated = this.duplicateMessageCount > 10
    const isDuplicate = message === this.lastSentMessage

    if (isDuplicate) {
      console.log('Duplicate message')

      if (maxDuplicated) {
        console.log('>>>>>>>> Dropped duplicate message')
        return
      }

      this.duplicateMessageCount += 1
    } else {
      this.duplicateMessageCount = 0
    }

    console.log('>>>>>>>> send', repoMessage, this.sentCount)
    this.lastSentMessage = message

    this.sendMessageFromClient({
      action: 'message',
      designId: this.designId,
      peerId: this.remotePeerId,
      message,
    })
  }
}
