import {
  IconBorder,
  IconLayout,
  IconPhotos,
  IconRearrange,
} from '@moonpig/web-personalise-components'
import React, { FC, useCallback, useMemo } from 'react'
import { createElementDomId } from '../../../../../../../utils/createElementId'
import {
  UIImageCollage,
  UIImageCollageMenuItem,
  useAction,
  useView,
} from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { useElementRef } from '../../../../../selectors'
import { ToolbarItem, ToolbarMenu, ToolbarOrientation } from '../Toolbar'
import { ToolbarCommon } from '../ToolbarCommon'
import { createMenuTitleId } from '../utils/createMenuTitleId'
import { ImageCollageBorderPicker } from './ImageCollageBorderPicker'
import { ImageCollageLayoutPicker } from './ImageCollageLayoutPicker'
import { ImageCollagePhotoLibrary } from './ImageCollagePhotoLibrary'

type ToolbarImageCollageProps = {
  onDone: () => void
  orientation: ToolbarOrientation
  ui: UIImageCollage
}

export const ToolbarImageCollage: FC<ToolbarImageCollageProps> = ({
  onDone,
  orientation,
  ui,
}) => {
  const { elementRef } = ui

  const productKey = useView('main', view => view.design.productKey)
  const trackEvent = useAction('trackEvent')
  const setUI = useAction('setUI')
  const t = useEditorLocaleText()
  const element = useElementRef(elementRef)

  const hasSelectedAtLeastOnePhoto =
    (element.customisations.grid?.cells.length ?? 0) > 0
  const hasSelectedMultiplePhotos =
    (element.customisations.grid?.cells.length ?? 0) > 1

  const controlId = createElementDomId(elementRef.id)

  const { selectedMenuItem } = ui

  const items = useMemo<ToolbarItem<UIImageCollageMenuItem>[]>(() => {
    return [
      {
        type: 'menu',
        id: 'photo-library',
        label: t('toolbar_image_photos_label'),
        ariaLabel: t('toolbar_add_photo'),
        icon: <IconPhotos />,
        disabled: false,
      },
      {
        type: 'menu',
        id: 'layout-picker',
        label: t('toolbar_layout_label'),
        ariaLabel: t('toolbar_select_layout'),
        icon: <IconLayout />,
        disabled: !hasSelectedMultiplePhotos,
      },
      {
        type: 'button',
        id: 'rearrange',
        label: t('toolbar_collage_rearrange_label'),
        ariaLabel: t('toolbar_rearrange'),
        icon: <IconRearrange />,
        disabled: !hasSelectedMultiplePhotos,
      },
      {
        type: 'menu',
        id: 'border',
        label: t('border'),
        ariaLabel: t('border'),
        icon: <IconBorder />,
        disabled: !hasSelectedAtLeastOnePhoto,
      },
    ]
  }, [t, hasSelectedMultiplePhotos, hasSelectedAtLeastOnePhoto])

  const menu = useMemo<ToolbarMenu | undefined>(() => {
    switch (selectedMenuItem) {
      case 'photo-library': {
        const titleId = createMenuTitleId(elementRef.id, selectedMenuItem)

        return {
          title: t('toolbar_menu_choose_photo_label'),
          titleId,
          content: (
            <ImageCollagePhotoLibrary
              elementRef={elementRef}
              labelledById={titleId}
            />
          ),
          closeLabel: t('toolbar_menu_close_photo_library_label'),
        }
      }
      case 'layout-picker': {
        const titleId = createMenuTitleId(elementRef.id, selectedMenuItem)

        return {
          title: t('toolbar_menu_choose_layout_label'),
          titleId,
          content: (
            <ImageCollageLayoutPicker
              elementRef={elementRef}
              labelledById={titleId}
            />
          ),
          closeLabel: t('toolbar_menu_close_layout_label'),
        }
      }
      case 'border': {
        const titleId = createMenuTitleId(elementRef.id, selectedMenuItem)

        return {
          title: t('border'),
          titleId,
          content: <ImageCollageBorderPicker elementRef={elementRef} />,
          closeLabel: t('toolbar_menu_close_border_label'),
        }
      }
    }
  }, [elementRef, selectedMenuItem, t])

  const handleSelectItem = useCallback(
    (itemId: UIImageCollageMenuItem | null) => {
      if (itemId === 'rearrange') {
        setUI({
          type: 'image-collage-rearrange',
          elementRef: ui.elementRef,
          selectedMenuItem: null,
        })
      } else {
        setUI({ ...ui, selectedMenuItem: itemId })
      }
    },
    [setUI, ui],
  )

  const handleDone = useCallback(() => {
    trackEvent({
      type: 'SELECT_CONTENT',
      kind: 'COLLAGE_DONE',
      productKey,
    })

    onDone()
  }, [onDone, productKey, trackEvent])

  return (
    <ToolbarCommon
      controlId={controlId}
      label={t('toolbar_collage_toolbar_title')}
      orientation={orientation}
      items={items}
      selectedItemId={selectedMenuItem}
      onSelectItem={handleSelectItem}
      menu={menu}
      done={{
        label: t('toolbar_done_button'),
        ariaLabel: t('toolbar_collage_toolbar_close_label'),
        onDone: handleDone,
      }}
    />
  )
}
