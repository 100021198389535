import {
  Transform2d,
  Vec2,
  clamp,
  degToRad,
  radToDeg,
} from '@moonpig/common-math'
import {
  TransformContext,
  useContacts,
  useKeyboard,
  usePointer,
  useTransform,
  useWheel,
} from '@moonpig/use-transform'
import {
  InteractiveElement,
  InteractiveElementBorder,
  InteractiveElementIndicator,
  InteractiveElementInteraction,
  InteractiveElementState,
} from '@moonpig/web-personalise-components'
import { DesignElement } from '@moonpig/web-personalise-editor-types'
import React, { FC, ReactNode, useCallback, useEffect, useRef } from 'react'
import { useCamera } from '../../../../../../../camera'
import { FOCUS_ID_ELEMENT } from '../../../../../../../constants'
import { createElementDomId } from '../../../../../../../utils/createElementId'
import { isKeyboardTransformDisabled } from '../../../../../../../utils/isKeyboardTransformDisabled'
import { useDisablePageZoom } from '../../../../../../../utils/useDisablePageZoom'
import {
  getGestureChangeType,
  useAction,
  useMainView,
  useView,
} from '../../../../../../../store'
import { useDeleteElement } from '../../../../useDeleteElement'
import { DeleteHandle } from './components/DeleteHandle'
import { ResizeHandle } from './components/ResizeHandle'
import { RotateHandle } from './components/RotateHandle'

type InteractiveElementTransformableProps = {
  title: string
  sceneId: string
  sceneX: number
  sceneY: number
  sceneWidth: number
  sceneHeight: number
  element: DesignElement
  interactiveContent?: ReactNode
  defaultWidth: number
  defaultHeight: number
  minScale?: number | Vec2
  maxScale?: number | Vec2
  enableDeleteShortcut: boolean
  enableUniformScaling: boolean
  isEditing?: boolean
  isDimmed?: boolean
  border?: InteractiveElementBorder
  indicator?: InteractiveElementIndicator
  onSelect?: () => void
  handles?: ReactNode
  transformElement?: (
    currentElement: DesignElement,
    updatedElement: DesignElement,
  ) => DesignElement
}

export const InteractiveElementTransformable: FC<
  InteractiveElementTransformableProps
> = ({
  title,
  sceneId,
  sceneX,
  sceneY,
  element,
  defaultWidth,
  defaultHeight,
  minScale,
  maxScale,
  children,
  isEditing,
  isDimmed,
  enableDeleteShortcut,
  enableUniformScaling,
  border,
  indicator,
  onSelect,
  handles,
  transformElement = (_, y) => y,
}) => {
  const { inverseScale, toWorldPoint, domBounds, setFocusLocked } = useCamera()
  const selectScene = useAction('selectScene')
  const selectElement = useAction('selectElement')
  const deleteElement = useDeleteElement()

  const activeElementId = useMainView('edit', view => view.activeElementId)

  const handleDelete = useCallback(() => {
    deleteElement(element)
  }, [deleteElement, element])

  const selectedScene = useView(
    'main',
    view => view.design.sceneById[view.selectedSceneId],
  )
  const isSelected = activeElementId === element.id
  const isSceneActive = selectedScene.id === sceneId

  const containerRef = useRef<SVGGElement>(null)

  useDisablePageZoom(containerRef)

  const handleSelect = useCallback(() => {
    if (!isSceneActive) {
      selectScene(sceneId, 'FOCUS')
    } else if (!isSelected) {
      selectElement(sceneId, element.id)
    } else if (onSelect) {
      onSelect()
    }
  }, [
    isSceneActive,
    isSelected,
    onSelect,
    selectScene,
    sceneId,
    selectElement,
    element.id,
  ])

  const handleFocus = useCallback(() => {
    selectScene(sceneId, 'FOCUS')
  }, [selectScene, sceneId])

  const x = element.x + element.width * 0.5
  const y = element.y + element.height * 0.5
  const rotation = degToRad(element.rotation)
  const scale: Vec2 = [
    element.width / defaultWidth,
    element.height / defaultHeight,
  ]

  const updateElementRef = useAction('updateElementRef')

  const setValue = useCallback(
    (transform: Transform2d, { last }: TransformContext) => {
      setFocusLocked(!last)

      const width = transform.scale[0] * defaultWidth
      const height = transform.scale[1] * defaultHeight

      updateElementRef(
        element,
        current => {
          return transformElement(element, {
            ...current,
            x: clamp(
              transform.position[0] - width * 0.5,
              -width * 0.5,
              selectedScene.width - width * 0.5,
            ),
            y: clamp(
              transform.position[1] - height * 0.5,
              -height * 0.5,
              selectedScene.height - height * 0.5,
            ),
            width,
            height,
            rotation: radToDeg(transform.rotation),
          })
        },
        { changeType: getGestureChangeType({ last }) },
      )

      if (last && !isSelected) {
        handleSelect()
      }
    },
    [
      setFocusLocked,
      defaultWidth,
      defaultHeight,
      updateElementRef,
      isSelected,
      transformElement,
      element,
      selectedScene.width,
      selectedScene.height,
      handleSelect,
    ],
  )

  const transform = useTransform({
    value: {
      position: [x, y],
      rotation,
      scale,
    },
    setValue,
    options: {
      minScale,
      maxScale,
      uniformScale: enableUniformScaling,
    },
  })

  const transformPoint = useCallback(
    (screenPoint: Vec2): Vec2 => {
      const screenX = screenPoint[0] - domBounds.x
      const screenY = screenPoint[1] - domBounds.y

      const worldPoint = toWorldPoint([screenX, screenY])
      return [worldPoint[0] - sceneX, worldPoint[1] - sceneY]
    },
    [domBounds.x, domBounds.y, sceneX, sceneY, toWorldPoint],
  )

  const onContactsChange = useContacts({
    transform,
    options: { rotationSnapOut: 12, rotationSnapIn: 2 },
  })

  const disabled = Boolean(!isSelected || isEditing)
  const bindPointer = usePointer(onContactsChange, {
    id: element.type,
    transformPoint,
    captureEvents: true,
  })

  const bindWheel = useWheel({
    disabled,
    transform,
    options: { transformPoint },
  })

  useKeyboard({
    transform,
    disabled: () => disabled || isKeyboardTransformDisabled(),
    options: { increment: 1 },
  })

  const svgTransform = [
    `translate(${transform.value.position[0]}, ${transform.value.position[1]})`,
    `rotate(${radToDeg(transform.value.rotation)})`,
    `translate(${-element.width * 0.5}, ${-element.height * 0.5})`,
  ].join(' ')

  const resizeProps = {
    id: element.id,
    transform,
    disabled,
    elementWidth: defaultWidth,
    elementHeight: defaultHeight,
    transformPoint,
    screenScale: inverseScale,
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (['Delete', 'Backspace'].includes(event.key) && isSelected) {
        handleDelete()
      }
    }

    if (enableDeleteShortcut) {
      window.addEventListener('keydown', handleKeyDown)

      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [handleDelete, enableDeleteShortcut, isSelected])

  let state: InteractiveElementState
  if (isSelected) {
    state = 'SELECTED'
  } else if (isDimmed) {
    state = 'DIMMED'
  } else {
    state = 'DEFAULT'
  }

  let interaction: InteractiveElementInteraction
  if (activeElementId && !isSelected) {
    interaction = 'INERT'
  } else {
    interaction = 'DEFAULT'
  }

  return (
    <>
      <g transform={svgTransform} ref={containerRef}>
        <InteractiveElement
          id={createElementDomId(element.id)}
          label={title}
          scale={inverseScale}
          width={element.width}
          height={element.height}
          state={state}
          border={border}
          indicator={indicator}
          interaction={interaction}
          focusId={FOCUS_ID_ELEMENT(element.id)}
          onSelect={handleSelect}
          onFocus={handleFocus}
          moveableEvents={{ ...bindPointer(), ...bindWheel() }}
        >
          {children}
        </InteractiveElement>
      </g>
      <ResizeHandle {...resizeProps} corner="top-left" />
      <ResizeHandle {...resizeProps} corner="top-right" />
      <ResizeHandle {...resizeProps} corner="bottom-left" />
      <ResizeHandle {...resizeProps} corner="bottom-right" />
      <RotateHandle
        id={element.id}
        transform={transform}
        disabled={disabled}
        elementHeight={defaultHeight}
        transformPoint={transformPoint}
        screenScale={inverseScale}
      />
      {disabled ? null : (
        <DeleteHandle
          element={element}
          transform={transform}
          elementHeight={defaultHeight}
          elementWidth={defaultWidth}
          screenScale={inverseScale}
          onClick={handleDelete}
        />
      )}
      {handles}
    </>
  )
}
