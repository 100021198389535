import { CollageGrid } from './grid'

export const applyImageIdsToGrid = (
  grid: CollageGrid,
  imageIds: string[],
): CollageGrid => {
  return {
    ...grid,
    cells: grid.cells.map((cell, index) => {
      return {
        ...cell,
        content: {
          ...cell.content,
          imageId: imageIds[index],
        },
      }
    }),
  }
}

export const getAvailableGrids = (
  grids: CollageGrid[],
  imageIds: string[],
): CollageGrid[] => {
  return grids
    .filter(grid => grid.cells.length === imageIds.length)
    .map(grid => applyImageIdsToGrid(grid, imageIds))
}
