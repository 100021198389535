import {
  Design,
  DesignElementRef,
  DesignTextPartEditable,
} from '@moonpig/web-personalise-editor-types'
import { EditorTextKeys } from '../../../text-localisation'

const denorm = <T>(byId: { [id: string]: T }, ids: string[]): T[] => {
  return ids.reduce<T[]>((acc, curr) => {
    return [...acc, byId[curr]]
  }, [])
}

type ValidationWarning = {
  firstDefaultElement: DesignElementRef
  textKey: EditorTextKeys
}

const CARD_LAYOUTS = [
  'CARD_LANDSCAPE',
  'CARD_PORTRAIT',
  'CARD_SQUARE',
  'POSTCARD_LANDSCAPE',
  'POSTCARD_PORTRAIT',
]

export const validateDefaultText = (
  design: Design,
): ValidationWarning | null => {
  const scenes = denorm(design.sceneById, design.sceneIds)
  const sceneDefaultTextElements = scenes.map(scene => {
    const elements = denorm(scene.elementById, scene.elementIds)
    const defaultTextElements: DesignElementRef[] = elements.filter(element => {
      switch (element.type) {
        case 'text-plain':
        case 'text-styled': {
          return (
            element.editableText.defaultText &&
            element.editableText.defaultText === element.customisations.text
          )
        }
        case 'text-placeholder': {
          return (
            element.textParts.filter(
              t => t.type === 'editable',
            ) as DesignTextPartEditable[]
          ).some((textPart, i) => {
            const customisationText = element.customisations.textParts[i]
            return (
              textPart.defaultText && textPart.defaultText === customisationText
            )
          })
        }
        case 'image-dynamic': {
          return element.textParts.some((textPart, i) => {
            const customisationText = element.customisations.textParts[i]
            return (
              textPart.defaultText && textPart.defaultText === customisationText
            )
          })
        }
        default: {
          return false
        }
      }
    })

    return { sceneId: scene.id, defaultTextElements }
  })

  const isCardLayout = CARD_LAYOUTS.includes(design.layout)

  const defaultOnFront = sceneDefaultTextElements[0].defaultTextElements[0]
  const defaultOnBack =
    sceneDefaultTextElements[sceneDefaultTextElements.length - 1]
      .defaultTextElements[0]

  if (!isCardLayout && defaultOnFront) {
    return {
      firstDefaultElement: defaultOnFront,
      textKey: 'unchanged_default_text_message_generic',
    }
  }

  if (defaultOnFront && defaultOnBack) {
    return {
      firstDefaultElement: defaultOnFront,
      textKey: 'unchanged_default_text_message_front_and_back',
    }
  }

  if (defaultOnFront) {
    return {
      firstDefaultElement: defaultOnFront,
      textKey: 'unchanged_default_text_message_front',
    }
  }

  if (defaultOnBack) {
    return {
      firstDefaultElement: defaultOnBack,
      textKey: 'unchanged_default_text_message_back',
    }
  }

  return null
}
