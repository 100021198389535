import { TextAlignmentPicker } from '@moonpig/web-personalise-components'
import {
  DesignElementRef,
  DesignElementTextStyled,
  DesignHorizontalAlignment,
  DesignVerticalAlignment,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback } from 'react'
import { useAction } from '../../../../../../../../store'
import { useElementRef } from '../../../../../../selectors'

type ElementTextAlignmentPickerProps = {
  elementRef: DesignElementRef<DesignElementTextStyled>
}

export const ElementTextAlignmentPicker: FC<
  ElementTextAlignmentPickerProps
> = ({ elementRef }) => {
  const element = useElementRef(elementRef)
  const updateElement = useAction('updateElement')

  const onHorizontalAlignmentSelect = useCallback(
    (newHorizontalAlignment: DesignHorizontalAlignment) => {
      updateElement({
        ...element,
        customisations: {
          ...element.customisations,
          horizontalAlignment: newHorizontalAlignment,
        },
      })
    },
    [element, updateElement],
  )

  const onVerticalAlignmentSelect = useCallback(
    (newVerticalAlignment: DesignVerticalAlignment) => {
      updateElement({
        ...element,
        customisations: {
          ...element.customisations,
          verticalAlignment: newVerticalAlignment,
        },
      })
    },
    [element, updateElement],
  )

  return (
    <TextAlignmentPicker
      selectedVerticalAlignment={element.customisations.verticalAlignment}
      selectedHorizontalAlignment={element.customisations.horizontalAlignment}
      onVerticalChange={onVerticalAlignmentSelect}
      onHorizontalChange={onHorizontalAlignmentSelect}
    />
  )
}
