import React, { FC } from 'react'
import { useView } from '../../store'
import { ErrorFinish } from '../ErrorFinish'
import { ErrorPreview } from '../ErrorPreview'
import { ErrorUnsupportedFeatures } from '../ErrorUnsupportedFeatures'
import { LoadingExit } from '../LoadingExit'
import { LoadingFinish } from '../LoadingFinish'
import { Edit } from './Edit'
import { Preview } from './Preview'

export const Main: FC = () => {
  const mainViewType = useView('main', ({ view }) => view.type)

  switch (mainViewType) {
    case 'edit': {
      return <Edit />
    }
    case 'loading-preview':
    case 'preview': {
      return <Preview />
    }
    case 'error-preview': {
      return <ErrorPreview />
    }
    case 'loading-finish': {
      return <LoadingFinish />
    }
    case 'error-finish': {
      return <ErrorFinish />
    }
    case 'loading-exit': {
      return <LoadingExit />
    }
    case 'error-unsupported-features': {
      return <ErrorUnsupportedFeatures />
    }
  }
}
