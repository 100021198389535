import { IconSystemCross } from '@moonpig/launchpad-assets'
import { focusIndicatorStyles } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { useFocusRef } from '@moonpig/web-personalise-components'
import React, { FC, ReactNode } from 'react'
import { FOCUS_ID_MENU } from '../../../../../../../constants'

const StyledMenuHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const StyledClose = styled.button`
  ${focusIndicatorStyles}
  ${s({ borderRadius: 2 })}
  
  position: absolute;
  top: 8px;
  right: 8px;
  width: 40px;
  height: 40px;
  color: inherit;
`

const StyledMenuHeading = styled.h4`
  outline: none;
  margin-bottom: 0;
  text-align: center;
  ${s({ typography: 'display2', px: 6, pt: 6 })}
`

type MenuHeaderBannerProps = {
  banner: ReactNode
  title: string
  titleId?: string
  closeLabel: string
  onClose: () => void
}

export const MenuHeaderBanner: FC<MenuHeaderBannerProps> = ({
  banner,
  title,
  titleId,
  closeLabel,
  onClose,
}) => {
  const headingRef = useFocusRef<HTMLHeadingElement>(FOCUS_ID_MENU)

  return (
    <StyledMenuHeader>
      {banner}
      <StyledClose
        aria-label={closeLabel}
        onClick={event => {
          event.stopPropagation()
          onClose()
        }}
      >
        <IconSystemCross width={28} height={28} />
      </StyledClose>
      <StyledMenuHeading ref={headingRef} id={titleId} tabIndex={-1}>
        {title}
      </StyledMenuHeading>
    </StyledMenuHeader>
  )
}
