import { DesignElementTextStyled } from '@moonpig/web-personalise-editor-types'
import React, { FC } from 'react'
import { useElementRef } from '../../../../../selectors'
import { useActiveKeyboardElementRef } from '../../../../selectors'
import { InteractiveElementProps } from '../../types'
import { InteractiveElementCommon } from '../InteractiveElementCommon'

export const ElementTextStyledInteractive: FC<
  InteractiveElementProps<DesignElementTextStyled>
> = ({ elementRef, title, sceneWidth, sceneHeight }) => {
  const element = useElementRef(elementRef)
  const isEmpty = element.customisations.text.trim().length === 0
  const activeKeyboardElementRef = useActiveKeyboardElementRef()
  const isEditing = elementRef.id === activeKeyboardElementRef?.id

  return (
    <InteractiveElementCommon
      border="ALWAYS"
      indicator={isEmpty && !isEditing ? 'ADD_TEXT' : 'NONE'}
      title={title}
      sceneId={elementRef.sceneId}
      sceneWidth={sceneWidth}
      sceneHeight={sceneHeight}
      element={element}
      isInvalid={!element.valid}
    />
  )
}
