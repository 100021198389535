import { Vec2 } from '@moonpig/common-math'
import { useMemo, useState } from 'react'
import { useElementRef } from './useElementRef'
import { useElementSize } from './useElementSize'
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'

type Bounds = {
  x: number
  y: number
  width: number
  height: number
}

export const useElementBounds = (): [
  Bounds | null,
  (node: unknown) => void,
] => {
  const [ref, element] = useElementRef<HTMLDivElement>()

  const [position, setPosition] = useState<Vec2 | null>(null)
  const size = useElementSize(element)
  useIsomorphicLayoutEffect(() => {
    if (element) {
      const rect = element?.getBoundingClientRect()
      setPosition([rect.left, rect.top])
    }
  }, [element])

  const bounds = useMemo(() => {
    if (position && size) {
      return {
        x: position[0],
        y: position[1],
        ...size,
      }
    }
    return null
  }, [position, size])

  return [bounds, ref]
}
