import {
  Design,
  DesignElement,
  DesignElementOverlayTextAuthor,
  DesignTextOverlayCustomisations,
} from '@moonpig/web-personalise-editor-types'

const updateOverlayTextAuthor = (
  customisations: DesignTextOverlayCustomisations,
  previousCustomisations: DesignTextOverlayCustomisations,
): DesignElementOverlayTextAuthor | null => {
  const textUpdated = customisations.text !== previousCustomisations?.text

  switch (previousCustomisations.author) {
    case 'SMART_TEXT': {
      return textUpdated ? 'SMART_TEXT_MODIFIED' : 'SMART_TEXT'
    }
    case 'SMART_TEXT_MODIFIED': {
      return 'SMART_TEXT_MODIFIED'
    }
    case 'STARTER': {
      return textUpdated ? 'STARTER_MODIFIED' : 'STARTER'
    }
    case 'STARTER_MODIFIED': {
      return 'STARTER_MODIFIED'
    }
    case 'CUSTOMER': {
      return 'CUSTOMER'
    }
    default:
      return textUpdated
        ? customisations.author || 'CUSTOMER'
        : customisations.author
  }
}

export const updateDesignElement = (
  design: Design,
  element: DesignElement,
): Design => {
  let updatedElement: DesignElement = element
  const previousElement =
    design.sceneById[element.sceneId].elementById[element.id]

  if (
    element.type === 'overlay-text' &&
    previousElement.type === 'overlay-text'
  ) {
    updatedElement = {
      ...element,
      customisations: {
        ...element.customisations,
        author: updateOverlayTextAuthor(
          element.customisations,
          previousElement.customisations,
        ),
      },
    }
  }

  return {
    ...design,
    sceneById: {
      ...design.sceneById,
      [updatedElement.sceneId]: {
        ...design.sceneById[updatedElement.sceneId],
        elementById: {
          ...design.sceneById[updatedElement.sceneId].elementById,
          [updatedElement.id]: updatedElement,
        },
      },
    },
  }
}
