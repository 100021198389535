import { Box, Flex } from '@moonpig/launchpad-components'
import { Dial } from '@moonpig/web-personalise-components'
import {
  DesignElementOverlayImage,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback } from 'react'
import {
  OVERLAY_IMAGE_MAX_SCALE,
  OVERLAY_IMAGE_MIN_SCALE,
} from '../../../../../../../../constants'
import { getDefaultOverlayImageSize } from '../../../../../../../../utils/getDefaultOverlayImageSize'
import { getGestureChangeType, useAction } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { useElementRef, useScene } from '../../../../../../selectors'

const RotationDial: FC<{
  rotationDeg: number
  onChange: (rotationDeg: number, context: { last: boolean }) => void
}> = ({ rotationDeg, onChange }) => {
  const t = useEditorLocaleText()
  return (
    <Dial
      id="rotation-dial"
      label={t('rotation_label')}
      getValueText={v => `${Math.round(v)}°`}
      minValue={-180}
      maxValue={180}
      unitsPerMark={1}
      pixelsPerMark={10}
      minorPerMajorMark={10}
      value={rotationDeg}
      onChange={onChange}
    />
  )
}

const ZoomDial: FC<{
  initialScale: number
  scale: number
  onChange: (scale: number, context: { last: boolean }) => void
}> = ({ initialScale, scale, onChange }) => {
  const t = useEditorLocaleText()
  const zoom = scale / initialScale

  const handleChange = useCallback(
    (value: number, context: { last: boolean }) => {
      onChange(value * initialScale, context)
    },
    [initialScale, onChange],
  )

  return (
    <Dial
      id="zoom-dial"
      label={t('zoom_label')}
      getValueText={v => `${v.toFixed(1)}x`}
      minValue={OVERLAY_IMAGE_MIN_SCALE}
      maxValue={OVERLAY_IMAGE_MAX_SCALE}
      unitsPerMark={0.1}
      pixelsPerMark={10}
      minorPerMajorMark={10}
      value={zoom}
      onChange={handleChange}
    />
  )
}

type AdjustProps = {
  elementRef: DesignElementRef<DesignElementOverlayImage>
}

export const Adjust: FC<AdjustProps> = ({ elementRef }) => {
  const element = useElementRef(elementRef)
  const scene = useScene(element.sceneId)
  const updateElementRef = useAction('updateElementRef')

  const defaultSize = getDefaultOverlayImageSize(scene, element)

  const handleRotationChange = useCallback(
    (newRotationDeg: number, context: { last: boolean }) => {
      updateElementRef(
        elementRef,
        current => {
          return {
            ...current,
            rotation: newRotationDeg,
          }
        },
        { changeType: getGestureChangeType(context) },
      )
    },
    [elementRef, updateElementRef],
  )

  const handleScaleChange = useCallback(
    (newScale: number, context: { last: boolean }) => {
      updateElementRef(
        elementRef,
        current => {
          const newWidth = defaultSize.width * newScale
          const newHeight = defaultSize.height * newScale
          const dx = (newWidth - current.width) * -0.5
          const dy = (newHeight - current.height) * -0.5

          return {
            ...current,
            x: current.x + dx,
            y: current.y + dy,
            width: newWidth,
            height: newHeight,
          }
        },
        { changeType: getGestureChangeType(context) },
      )
    },
    [defaultSize.height, defaultSize.width, elementRef, updateElementRef],
  )

  const scale = element.width / defaultSize.width

  return (
    <Flex flexDirection="column" width="100%">
      <Box mt={4}>
        <RotationDial
          rotationDeg={element.rotation}
          onChange={handleRotationChange}
        />
      </Box>
      <Box mt={5}>
        <ZoomDial initialScale={1} scale={scale} onChange={handleScaleChange} />
      </Box>
    </Flex>
  )
}
