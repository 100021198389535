import React, { FC } from 'react'

export const IconStop: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 24 24" width={width} height={height}>
      <rect rx={2} x={4} y={4} width={16} height={16} fill="currentColor" />
    </svg>
  )
}
