import { IconSystemImageUpload } from '@moonpig/launchpad-assets'
import { useTheme } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { COLLAGE_PHOTO_LIMIT } from '../../../../../../../../constants'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'

const INDICATOR_ICON_SIZE = 60
const INDICATOR_FONT_SIZE = 20
const INDICATOR_ICON_GAP = 8
const BACKGROUND_CIRCLE_RADIUS = 120

export const EmptyIndicator: FC<{
  scale: number
  width: number
  height: number
}> = ({ scale, width, height }) => {
  const t = useEditorLocaleText()
  const { colors } = useTheme()
  const { colorTextBody, colorBackground03 } = colors

  const circleRadius = BACKGROUND_CIRCLE_RADIUS * scale
  const iconSize = INDICATOR_ICON_SIZE * scale
  const fontSize = INDICATOR_FONT_SIZE * scale
  const iconTextGap = INDICATOR_ICON_GAP * scale

  const lines = t('collage_add_photos', COLLAGE_PHOTO_LIMIT).split('\n')
  const lineSpacing = fontSize * 1.5

  const separation = iconSize + iconTextGap
  const iconOffsetY = separation * -0.5
  const textOffsetY = separation * 0.5

  return (
    <>
      <circle
        cx={width * 0.5}
        cy={height * 0.5}
        r={circleRadius}
        fill={colorBackground03}
      />
      <IconSystemImageUpload
        x={(width - iconSize) * 0.5}
        y={(height - iconSize) * 0.5 + iconOffsetY}
        width={iconSize}
        height={iconSize}
        color={colorTextBody}
      />
      {lines.map((line, lineIndex) => {
        return (
          <text
            // eslint-disable-next-line react/no-array-index-key
            key={lineIndex}
            aria-hidden
            fontSize={fontSize}
            color={colorTextBody}
            x={width * 0.5}
            y={height * 0.5 + lineSpacing * lineIndex + textOffsetY}
            textAnchor="middle"
          >
            {line}
          </text>
        )
      })}
    </>
  )
}
