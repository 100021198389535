import { assert } from '../../../utils/assert'
import { addNotification } from '../../common/addNotification'
import { ActionCreator } from '../../types'

type onSample = (event: {
  amplitude: number
  currentTimeInSeconds: number
}) => void

export const createStartAudioRecording: ActionCreator<'startAudioRecording'> =
  ({ get, set, services }) =>
  async () => {
    const store = get()

    assert(store.view.type === 'main')

    const supportedMimeTypes =
      store.view.customisationConfig.audio.supportedMimeTypes

    const handleOnSamples: onSample = event => {
      const { amplitude, currentTimeInSeconds } = event

      set(({ audioRecording }) => {
        /* istanbul ignore next */
        if (!audioRecording) {
          return { audioRecording }
        }

        return {
          audioRecording: {
            ...audioRecording,
            currentTimeSeconds: currentTimeInSeconds,
            samples: [...audioRecording.samples, amplitude],
          },
        }
      })
    }

    const response = await services.recordAudio({
      supportedMimeTypes,
      onSample: handleOnSamples,
    })

    if (response.type === 'success') {
      set(() => {
        return {
          audioRecording: {
            samples: [],
            currentTimeSeconds: 0,
            timePerSampleSeconds: response.timePerSampleSeconds,
            stop: response.stop,
          },
        }
      })
    } else {
      set(({ view }) => {
        assert(view.type === 'main')

        return {
          view: {
            ...view,
            notifications: addNotification(view.notifications, {
              type: 'microphone-permissions-error',
            }),
          },
        }
      })
    }
  }
