import { DesignElementSticker } from '@moonpig/web-personalise-editor-types'
import React, { memo, useMemo } from 'react'
import {
  STICKER_MAX_SCALE,
  STICKER_MIN_SCALE,
} from '../../../../../../../constants'
import { getDefaultStickerSize } from '../../../../../../../utils/getDefaultStickerSize'
import { useView } from '../../../../../../../store'
import { useElementRef } from '../../../../../selectors'
import { InteractiveElementProps } from '../../types'
import { InteractiveElementTransformable } from '../InteractiveElementTransformable'
import { LoadingIndicator } from '../LoadingIndicator'

export const ElementStickerInteractive = memo<
  InteractiveElementProps<DesignElementSticker>
>(({ elementRef, title, sceneX, sceneY, sceneWidth, sceneHeight }) => {
  const loadedStickerIds = useView('main', view => view.loadedStickerIds)
  const element = useElementRef(elementRef)

  const [defaultSize] = useMemo(
    () => getDefaultStickerSize(sceneWidth, sceneHeight),
    [sceneWidth, sceneHeight],
  )

  const isLoading = !loadedStickerIds.has(element.sticker.id)

  return (
    <>
      <InteractiveElementTransformable
        title={title}
        sceneId={elementRef.sceneId}
        sceneX={sceneX}
        enableDeleteShortcut
        sceneY={sceneY}
        sceneWidth={sceneWidth}
        sceneHeight={sceneHeight}
        element={element}
        defaultWidth={defaultSize}
        defaultHeight={defaultSize}
        minScale={STICKER_MIN_SCALE}
        maxScale={STICKER_MAX_SCALE}
        enableUniformScaling
      />
      {isLoading && <LoadingIndicator element={element} />}
    </>
  )
})
