import React, { FC } from 'react'

export const IconUndo: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M7.7498 19C7.53314 19 7.35397 18.9292 7.2123 18.7875C7.07064 18.6458 6.9998 18.4667 6.9998 18.25C6.9998 18.0333 7.07064 17.8542 7.2123 17.7125C7.35397 17.5708 7.53314 17.5 7.7498 17.5H14.2248C15.3915 17.5 16.3956 17.1125 17.2373 16.3375C18.079 15.5625 18.4998 14.6 18.4998 13.45C18.4998 12.3 18.079 11.3375 17.2373 10.5625C16.3956 9.7875 15.3915 9.4 14.2248 9.4H6.8498L9.1748 11.725C9.3248 11.875 9.3998 12.05 9.3998 12.25C9.3998 12.45 9.3248 12.625 9.1748 12.775C9.0248 12.925 8.8498 13 8.6498 13C8.4498 13 8.2748 12.925 8.1248 12.775L4.5248 9.175C4.44147 9.09167 4.38314 9.00833 4.3498 8.925C4.31647 8.84167 4.2998 8.75 4.2998 8.65C4.2998 8.55 4.31647 8.45833 4.3498 8.375C4.38314 8.29167 4.44147 8.20833 4.5248 8.125L8.1248 4.525C8.2748 4.375 8.4498 4.3 8.6498 4.3C8.8498 4.3 9.0248 4.375 9.1748 4.525C9.3248 4.675 9.3998 4.85 9.3998 5.05C9.3998 5.25 9.3248 5.425 9.1748 5.575L6.8498 7.9H14.1998C15.7831 7.9 17.1456 8.43333 18.2873 9.5C19.429 10.5667 19.9998 11.8833 19.9998 13.45C19.9998 15.0167 19.429 16.3333 18.2873 17.4C17.1456 18.4667 15.7831 19 14.1998 19H7.7498Z"
        fill="currentColor"
      />
    </svg>
  )
}
