import { LayoutPicker } from '@moonpig/web-personalise-components'
import React, { FC, useCallback, useMemo } from 'react'
import { useAction, useView } from '../../../../../../../../store'
import { useScene } from '../../../../../../selectors'
import { getAvailableLayouts } from '../../utils/getAvailableLayouts'

export const LayoutsLibrary: FC<{
  labelledById: string
  sceneId: string
}> = ({ labelledById, sceneId }) => {
  const showModal = useAction('showModal')
  const scene = useScene(sceneId)
  const overlayTextElements = scene.elementIds.filter(
    elementId => scene.elementById[elementId].type === 'overlay-text',
  )
  const selectLayout = useAction('selectLayout')

  const selectedLayoutId = scene.layout ? scene.layout.grid.id : null

  const grids = useView(
    'main',
    view => view.customisationConfig.availableSceneLayouts,
  )

  const layouts = useMemo(() => {
    return getAvailableLayouts({
      grids,
      width: scene.width,
      height: scene.height,
      selectedGridId: selectedLayoutId,
    })
  }, [scene, selectedLayoutId, grids])

  const handleLayoutSelection = useCallback(
    (id: string) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const newSelectedLayout = grids.find(grid => grid.id === id)!
      if (overlayTextElements.length) {
        return showModal({
          type: 'confirm-layout-change',
          selectedLayout: newSelectedLayout,
        })
      }
      selectLayout(newSelectedLayout, sceneId)
    },
    [grids, sceneId, selectLayout, showModal, overlayTextElements],
  )
  return (
    <LayoutPicker
      labelledById={labelledById}
      onSelect={id => handleLayoutSelection(id)}
      layouts={layouts}
    />
  )
}
