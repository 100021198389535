import { DesignElementImageDynamic } from '@moonpig/web-personalise-editor-types'
import React, { FC } from 'react'
import { useElementRef } from '../../../../../selectors'
import { InteractiveElementProps } from '../../types'
import { InteractiveElementCommon } from '../InteractiveElementCommon'

export const ElementImageDynamicInteractive: FC<
  InteractiveElementProps<DesignElementImageDynamic>
> = ({ elementRef, title, sceneWidth, sceneHeight }) => {
  const element = useElementRef(elementRef)

  return (
    <InteractiveElementCommon
      indicator="TEXT"
      border="ALWAYS"
      title={title}
      sceneId={elementRef.sceneId}
      sceneWidth={sceneWidth}
      sceneHeight={sceneHeight}
      element={element}
    />
  )
}
