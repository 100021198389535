import { CollageGrid } from './grid'

const range = (length: number): number[] => {
  return Array(length)
    .fill(0)
    .map((_, i) => i)
}

const randomInt = (max: number): number => Math.floor(Math.random() * max)

// Shuffle integers [0, n) in place and ensure each item moves from its initial position
const randomPermutation = (n: number): number[] => {
  const values = range(n)

  for (let i = n - 1; i > 0; i -= 1) {
    const swapWith = randomInt(i)
    const temp = values[i]
    values[i] = values[swapWith]
    values[swapWith] = temp
  }

  return values
}

export const shuffleGrid = (grid: CollageGrid): CollageGrid => {
  const order = randomPermutation(grid.cells.length)

  return {
    ...grid,
    cells: grid.cells.map((cell, index) => {
      const swap = grid.cells[order[index]]

      return {
        ...cell,
        anchors: swap.anchors,
      }
    }),
  }
}
