import { BrandLogo, Illustration404 } from '@moonpig/launchpad-assets'
import {
  Box,
  Flex,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC, ReactNode } from 'react'
import { PageHeading } from '../PageHeading'
import { useLocaleText } from './ErrorScreen.locale'

type ButtonType = 'primary' | 'secondary' | 'tertiary'

export type ErrorAction = {
  title: string
  onClick: () => void
  type: ButtonType
}

type ErrorScreenProps = {
  icon?: ReactNode
  title: string
  description?: string
  session: {
    sessionId: string
    shortSessionId: string
  }
  actions?: [ErrorAction] | [ErrorAction, ErrorAction]
}

const StyledPageHeading = styled(PageHeading)`
  ${s({
    mb: 2,
    typography: 'bodyBold',
  })}
`

const LOGO_WIDTH_PX = 96

const StyledBrandLogo = styled.div`
  position: absolute;
  bottom: 64px;
  width: ${LOGO_WIDTH_PX}px;
  ${s({ color: 'colorPrimary01' })}
  svg {
    display: block;
  }
`

const StyledAbbr = styled.abbr`
  text-decoration: none;
`

const getButtonComponent = (type: ButtonType) => {
  switch (type) {
    case 'primary':
      return PrimaryButton
    case 'secondary':
      return SecondaryButton
    case 'tertiary':
      return TertiaryButton
  }
}

export const ErrorScreen: FC<ErrorScreenProps> = ({
  icon = <Illustration404 width={256} />,
  title,
  description,
  session,
  actions,
}) => {
  const t = useLocaleText()

  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding={8}
    >
      <Box mb={6}>{icon}</Box>
      <Flex mb={2}>
        <StyledPageHeading>{title}</StyledPageHeading>
      </Flex>
      <Text typography="bodySmall" textAlign="center">
        {description}
      </Text>
      <Text typography="bodySmall" as="div" mt={10} mb={0}>
        <StyledAbbr title={t('error_reference_session_id', session.sessionId)}>
          <i>{t('error_reference_short_session_id', session.shortSessionId)}</i>
        </StyledAbbr>
      </Text>
      {actions && (
        <Flex gap={'16px'} mt={5}>
          {actions.map(action => {
            const Component = getButtonComponent(action.type)

            return (
              <Component key={action.title} onClick={action.onClick}>
                {action.title}
              </Component>
            )
          })}
        </Flex>
      )}
      <StyledBrandLogo>
        <BrandLogo />
      </StyledBrandLogo>
    </Flex>
  )
}
