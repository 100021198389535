import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { CREASE_COLOR, SIDE_GAP } from './constants'

type SideProps = {
  flipped?: boolean
  rotated?: boolean
  raised?: boolean
}

const StyledSide = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`

export const Side: FC<SideProps> = ({ flipped, rotated, raised, children }) => {
  const rotateY = flipped ? 180 : 0
  const rotateZ = rotated ? 180 : 0
  const translateZ = raised ? SIDE_GAP : 0

  return (
    <StyledSide
      style={{
        transformOrigin: 'center center',
        transform: `rotateZ(${rotateZ}deg) rotateY(${rotateY}deg) translateZ(${translateZ}px)`,
        outline: `1px solid ${CREASE_COLOR}`,
        outlineOffset: '-1px',
      }}
    >
      {children}
    </StyledSide>
  )
}
