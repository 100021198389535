import { RegisterResources } from '@moonpig/renderer-react'
import { Services } from '@moonpig/web-personalise-editor-types'
import { FC, useEffect, useMemo } from 'react'
import { useEditorLocaleText } from '../../text-localisation'
import { useStoreApi } from '../context'
import { ActionContext } from '../types'
import { storeUpdateView } from '../updaters/view'
import { updateDynamicImages } from './updateDynamicImages'

type StoreUpdater = {
  updated: () => void
}

const createStoreUpdater = (context: ActionContext): StoreUpdater => {
  const { services, registerResources, get, set } = context

  return {
    updated: async () => {
      const { view, features } = get()

      if (view.type !== 'main') {
        return
      }

      if (!view.designMeta.changed) {
        return
      }

      if (view.collaborateSession) {
        view.collaborateSession.updateDesign(view.design)
      }

      set(store => {
        return storeUpdateView(store, 'main', mainView => {
          return {
            designMeta: {
              ...mainView.designMeta,
              changed: false,
            },
          }
        })
      })

      if (features.enableAutosave) {
        await services.saveDesign(view.design, { local: true })
      }

      await updateDynamicImages({
        services,
        registerResources,
        get,
        set,
      })
    },
  }
}

type StoreSubscriberProps = {
  services: Services
  registerResources: RegisterResources
}

export const StoreSubscriber: FC<StoreSubscriberProps> = ({
  services,
  registerResources,
}) => {
  const { getState: get, setState: set, subscribe } = useStoreApi()
  const t = useEditorLocaleText()

  const storeUpdater = useMemo(
    () => createStoreUpdater({ services, registerResources, get, set, t }),
    [get, registerResources, services, set, t],
  )

  useEffect(() => {
    subscribe(storeUpdater.updated)
  }, [storeUpdater, subscribe])

  return null
}
