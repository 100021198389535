import { Layout, Timing } from './types'

export const SCENE_ASPECT_RATIO = 800 / 594

export const CARD_ASPECT_RATIO: { [layout in Layout]: number } = {
  CARD_PORTRAIT: 133 / 187,
  CARD_SQUARE: 133 / 133,
  CARD_LANDSCAPE: 187 / 133,
}

// width of card at end of animation relative to scene width
export const CARD_WIDTH_PERCENT: { [layout in Layout]: number } = {
  CARD_PORTRAIT: 0.7,
  CARD_SQUARE: 0.7,
  CARD_LANDSCAPE: 0.7,
}

export const CARD_INITIAL_SCALE: { [layout in Layout]: number } = {
  CARD_PORTRAIT: 0.5,
  CARD_SQUARE: 0.7,
  CARD_LANDSCAPE: 0.9,
}

// y offset from top side relative to scene height at start of animation
export const CARD_INITIAL_POSITION_Y_PERCENT: { [layout in Layout]: number } = {
  CARD_PORTRAIT: 0.28,
  CARD_SQUARE: 0.27,
  CARD_LANDSCAPE: 0.31,
}

// y offset from top side relative to scene height at end of animation
export const CARD_FINAL_POSITION_Y_PERCENT: { [layout in Layout]: number } = {
  CARD_PORTRAIT: 0.01,
  CARD_SQUARE: 0.01,
  CARD_LANDSCAPE: 0.15,
}

export const PHONE_ASPECT_RATIO = 768 / 816

// width of phone relative to scene width
export const PHONE_WIDTH_PERCENT = 0.8

// x offset from left side relative to scene width
export const PHONE_POSITION_X_PERCENT = 0.16

// y offset from top side relative to scene height
export const PHONE_POSITION_Y_PERCENT = 0.05

export const VIDEO_ASPECT_RATIO = 720 / 1280

// width of phone relative to scene width
export const VIDEO_WIDTH_PERCENT = 0.37

// x offset from left side relative to scene width
export const VIDEO_POSITION_X_PERCENT = 0.323

// y offset from top side relative to scene height
export const VIDEO_POSITION_Y_PERCENT = 0.132

// size of the video loading spinner relative to scene width
export const SPINNER_SIZE = 0.1

export const TIMING_BACKGROUND: Timing = { start: 1000, end: 2500 }

export const TIMING_CARD: Timing = { start: 1000, end: 2500 }

export const TIMING_PHONE: Timing = { start: 3000, end: 3500 }

export const TIMING_MEDIA_SPINNER: Timing = { start: 4000, end: 4200 }

export const TIMING_MEDIA_VIDEO: Timing = { start: 5000, end: 5000 }

export const DURATION = 9000
