import {
  DesignElementRef,
  DesignElementTextPlaceholder,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback } from 'react'
import { useAction } from '../../../../../../../../store'
import { useElementRef } from '../../../../../../selectors'
import { TextPartsForm } from '../../TextPartsForm'

type TextPlaceholderFormProps = {
  elementRef: DesignElementRef<DesignElementTextPlaceholder>
}

export const TextPlaceholderForm: FC<TextPlaceholderFormProps> = ({
  elementRef,
}) => {
  const element = useElementRef(elementRef)
  const updateElement = useAction('updateElement')
  const notify = useAction('notify')
  const trackEvent = useAction('trackEvent')

  const handleFiltered = useCallback(
    ({ hasEmojis }: { hasEmojis: boolean }) => {
      trackEvent({
        type: 'ERROR',
        kind: hasEmojis ? 'FILTERED_EMOJI' : 'FILTERED_CHARACTER',
      })

      notify({
        type: 'text-filtered',
        hasEmojis,
      })
    },
    [notify, trackEvent],
  )

  const handleChange = useCallback(
    (textParts: string[]) => {
      updateElement({ ...element, customisations: { textParts } })
    },
    [element, updateElement],
  )

  return (
    <TextPartsForm
      showTextPreview
      textParts={element.textParts}
      customisations={element.customisations.textParts}
      onChange={handleChange}
      onFiltered={handleFiltered}
    />
  )
}
