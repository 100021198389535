import { AspectRatio, Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'

const StyledImage = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
`

export const StickerImage: FC<{ imageUrl: string }> = ({ imageUrl }) => {
  return (
    <AspectRatio ratio={1}>
      <Box p={3}>
        <StyledImage
          style={{
            backgroundImage: `url('${imageUrl}')`,
          }}
        />
      </Box>
    </AspectRatio>
  )
}
