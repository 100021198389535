import React, { FC } from 'react'

export const IconScale: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 24 24" width={width} height={height}>
      <path
        d="M21.25 20.75C21.25 21.0333 21.1542 21.2708 20.9625 21.4625C20.7708 21.6542 20.5333 21.75 20.25 21.75H14.25C13.9667 21.75 13.7292 21.6542 13.5375 21.4625C13.3458 21.2708 13.25 21.0333 13.25 20.75C13.25 20.4667 13.3458 20.2292 13.5375 20.0375C13.7292 19.8458 13.9667 19.75 14.25 19.75H17.85L4.75 6.65V10.25C4.75 10.5333 4.65417 10.7708 4.4625 10.9625C4.27083 11.1542 4.03333 11.25 3.75 11.25C3.46667 11.25 3.22917 11.1542 3.0375 10.9625C2.84583 10.7708 2.75 10.5333 2.75 10.25V4.25C2.75 3.96667 2.84583 3.72917 3.0375 3.5375C3.22917 3.34583 3.46667 3.25 3.75 3.25H9.75C10.0333 3.25 10.2708 3.34583 10.4625 3.5375C10.6542 3.72917 10.75 3.96667 10.75 4.25C10.75 4.53333 10.6542 4.77083 10.4625 4.9625C10.2708 5.15417 10.0333 5.25 9.75 5.25H6.15L19.25 18.35V14.75C19.25 14.4667 19.3458 14.2292 19.5375 14.0375C19.7292 13.8458 19.9667 13.75 20.25 13.75C20.5333 13.75 20.7708 13.8458 20.9625 14.0375C21.1542 14.2292 21.25 14.4667 21.25 14.75V20.75Z"
        fill="currentColor"
      />
    </svg>
  )
}
