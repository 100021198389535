import {
  EditorFeatures,
  Services,
  UserState,
} from '@moonpig/web-personalise-editor-types'
import { OnboardingEntry } from '../../types'

export const buildOnboardingEntries = (
  userState: UserState,
  features: EditorFeatures,
  services: Services,
): OnboardingEntry[] => {
  const entries: OnboardingEntry[] = []

  if (features.enableEmojis && !userState.emojiOnboardingViewed) {
    entries.push({ type: 'emoji' })
    services.saveUserState({ emojiOnboardingViewed: true })
  }

  if (features.enableFlexibleText && !userState.textOnboardingViewed) {
    entries.push({ type: 'text' })
    services.saveUserState({ textOnboardingViewed: true })
  }

  if (features.enableLayouts && !userState.layoutsOnboardingViewed) {
    entries.push({ type: 'layouts' })
    services.saveUserState({ layoutsOnboardingViewed: true })
  }

  if (!userState.photosAndStickersOnboardingViewed) {
    if (features.enableFlexiblePhotos) entries.push({ type: 'photos' })
    if (features.enableStickers) entries.push({ type: 'stickers' })
    services.saveUserState({ photosAndStickersOnboardingViewed: true })
  }

  if (features.enableVideo && !userState.videoOnboardingViewed) {
    entries.push({ type: 'video' })
    services.saveUserState({ videoOnboardingViewed: true })
  }

  if (features.enableSmartText && !userState.smartTextOnboardingViewed) {
    entries.push({ type: 'smart-text' })
    services.saveUserState({ smartTextOnboardingViewed: true })
  }

  if (features.enableAudio && !userState.audioOnboardingViewed) {
    entries.push({ type: 'audio' })
    services.saveUserState({ audioOnboardingViewed: true })
  }

  return entries
}
