import { colorValue } from '@moonpig/launchpad-theme'
import { createGlobalStyle } from '@moonpig/launchpad-utils'
import { useRegisterResources } from '@moonpig/renderer-react'
import {
  FocusManagerProvider,
  useIsomorphicLayoutEffect,
  useReducedMotion,
} from '@moonpig/web-personalise-components'
import {
  EditorConfig,
  EditorFeatures,
  Services,
  Session,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useEffect } from 'react'
import { Globals } from 'react-spring'
import { DeviceViewport } from './components/DeviceViewport'
import { ErrorBoundary } from './components/ErrorBoundary'
import { ErrorDesignNotEditable } from './components/ErrorDesignNotEditable'
import { ErrorDesignNotFound } from './components/ErrorDesignNotFound'
import { ErrorProductNotFound } from './components/ErrorProductNotFound'
import { ErrorUnexpected } from './components/ErrorUnexpected'
import { Loading } from './components/Loading'
import { LoadingLogin } from './components/LoadingLogin'
import { Main } from './components/Main'
import { StoreProvider, useAction, useStore } from './store'
import { useEditorLocaleText } from './text-localisation'
import { ShareJoin } from './components/ShareJoin'

type EditorProps = {
  config: EditorConfig
  features: EditorFeatures
  session: Session
  services: Services
}

const GlobalStyles = createGlobalStyle`
  * {
    -webkit-tap-highlight-color: transparent;
    overscroll-behavior: none;
  }

  body {
    background: ${colorValue('colorBackground01')};
    overflow: hidden;
  }
`

const useConfigureReactSpring = () => {
  const prefersReducedMotion = useReducedMotion()
  useEffect(() => {
    Globals.assign({
      skipAnimation: prefersReducedMotion || process.env.NODE_ENV === 'test',
    })
  }, [prefersReducedMotion])
}

const useResetOnPageShow = () => {
  const edit = useAction('edit')

  const isInFinalState = useStore(({ view }) => {
    if (view.type !== 'main') {
      return false
    }

    return (
      view.view.type === 'loading-exit' || view.view.type === 'loading-finish'
    )
  })

  useIsomorphicLayoutEffect(() => {
    const reset = () => {
      if (isInFinalState) {
        edit()
      }
    }

    window.addEventListener('pageshow', reset)

    return () => {
      window.removeEventListener('pageshow', reset)
    }
  }, [edit, isInFinalState])
}

const EditorContent: FC = () => {
  const load = useAction('load')
  const viewType = useStore(({ view }) => view.type)

  useConfigureReactSpring()
  useResetOnPageShow()

  useEffect(() => {
    load()
  }, [load])

  switch (viewType) {
    case 'share-join': {
      return <ShareJoin />
    }
    case 'loading': {
      return <Loading />
    }
    case 'loading-login': {
      return <LoadingLogin />
    }
    case 'main': {
      return <Main />
    }
    case 'error-product-not-found': {
      return <ErrorProductNotFound />
    }
    case 'error-design-not-found': {
      return <ErrorDesignNotFound />
    }
    case 'error-design-not-editable': {
      return <ErrorDesignNotEditable />
    }
    case 'error-unexpected': {
      return <ErrorUnexpected />
    }
    default: {
      return null
    }
  }
}

const EditorStore: FC<EditorProps> = ({
  config,
  features,
  session,
  services,
}) => {
  const t = useEditorLocaleText()
  const registerResources = useRegisterResources()

  return (
    <StoreProvider
      config={config}
      features={features}
      session={session}
      services={services}
      t={t}
      registerResources={registerResources}
    >
      <DeviceViewport>
        <ErrorBoundary>
          <EditorContent />
        </ErrorBoundary>
      </DeviceViewport>
    </StoreProvider>
  )
}

export const Editor: FC<EditorProps> = props => {
  return (
    <>
      <GlobalStyles />
      <FocusManagerProvider>
        <EditorStore {...props} />
      </FocusManagerProvider>
    </>
  )
}
