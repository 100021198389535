/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Vec2, vec2Scale, vec2Sub } from '@moonpig/common-math'
import {
  IconCursor,
  ScreenReaderOnly,
  useFocusRef,
} from '@moonpig/web-personalise-components'
import {
  DesignElement,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, memo, useCallback, useEffect, useMemo } from 'react'
import { animated, to, useSpring } from 'react-spring'
import shallow from 'zustand/shallow'
import { useCamera } from '../../../../../camera'
import { FOCUS_ID_SCENE } from '../../../../../constants'
import { LayoutScene } from '../../../../../layout'
import { useAction, useView } from '../../../../../store'
import { useIsDesktop } from '../../../../../utils/useIsDesktop'
import { useElementDerived, useLayout, useSceneIds } from '../../../selectors'
import {
  useInteractiveElementTitle,
  useInteractiveSceneTitle,
  useSelectedElementRef,
} from '../../selectors'
import { ElementImageCollageInteractive } from './components/ElementImageCollageInteractive'
import { ElementImageDynamicInteractive } from './components/ElementImageDynamicInteractive'
import { ElementImageUploadInteractive } from './components/ElementImageUploadInteractive'
import { ElementMediaInteractive } from './components/ElementMediaInteractive'
import { ElementOverlayImageInteractive } from './components/ElementOverlayImageInteractive'
import { ElementOverlayTextInteractive } from './components/ElementOverlayTextInteractive'
import { ElementStickerInteractive } from './components/ElementStickerInteractive'
import { ElementTextPlaceholderInteractive } from './components/ElementTextPlaceholderInteractive'
import { ElementTextPlainInteractive } from './components/ElementTextPlainInteractive'
import { ElementTextStyledInteractive } from './components/ElementTextStyledInteractive'
import { ImageManipulation } from './components/ImageManipulation'
import { SceneToolbarSkipLink } from './components/SceneToolbarSkipLink'

const renderElementInteractiveContent = ({
  elementRef,
  ...rest
}: {
  elementRef: DesignElementRef
  title: string
  sceneX: number
  sceneY: number
  sceneWidth: number
  sceneHeight: number
}) => {
  const { type, id, sceneId } = elementRef

  switch (type) {
    case 'image-upload': {
      return (
        <ElementImageUploadInteractive
          elementRef={{ type, id, sceneId }}
          {...rest}
        />
      )
    }
    case 'image-dynamic': {
      return (
        <ElementImageDynamicInteractive
          elementRef={{ type, id, sceneId }}
          {...rest}
        />
      )
    }
    case 'image-collage': {
      return (
        <ElementImageCollageInteractive
          elementRef={{ type, id, sceneId }}
          {...rest}
        />
      )
    }
    case 'text-plain': {
      return (
        <ElementTextPlainInteractive
          elementRef={{ type, id, sceneId }}
          {...rest}
        />
      )
    }
    case 'text-styled': {
      return (
        <ElementTextStyledInteractive
          elementRef={{ type, id, sceneId }}
          {...rest}
        />
      )
    }
    case 'text-placeholder': {
      return (
        <ElementTextPlaceholderInteractive
          elementRef={{ type, id, sceneId }}
          {...rest}
        />
      )
    }
    case 'sticker': {
      return (
        <ElementStickerInteractive
          elementRef={{ type, id, sceneId }}
          {...rest}
        />
      )
    }
    case 'overlay-image': {
      return (
        <ElementOverlayImageInteractive
          elementRef={{ type, id, sceneId }}
          {...rest}
        />
      )
    }
    case 'overlay-text': {
      return (
        <ElementOverlayTextInteractive
          elementRef={{ type, id, sceneId }}
          {...rest}
        />
      )
    }
    case 'media': {
      return (
        <ElementMediaInteractive elementRef={{ type, id, sceneId }} {...rest} />
      )
    }
  }

  return null
}

const ElementInteractive: FC<{
  sceneId: string
  elementId: string
  title: string
  sceneX: number
  sceneY: number
  sceneWidth: number
  sceneHeight: number
}> = ({
  sceneId,
  elementId,
  title,
  sceneX,
  sceneY,
  sceneWidth,
  sceneHeight,
}) => {
  const elementRef: DesignElementRef = useView(
    'main',
    ({ design }) => {
      const element = design.sceneById[sceneId].elementById[elementId]

      return {
        type: element.type,
        sceneId: element.sceneId,
        id: element.id,
      }
    },
    shallow,
  )

  const elementX = useElementDerived(elementRef, ({ x }) => x)
  const elementY = useElementDerived(elementRef, ({ y }) => y)

  const content = renderElementInteractiveContent({
    elementRef,
    title,
    sceneX,
    sceneY,
    sceneWidth,
    sceneHeight,
  })

  if (!content) {
    return null
  }

  return (
    <g x={elementX} y={elementY}>
      {content}
    </g>
  )
}

const Element = ({
  layout,
  sceneId,
  elementId,
}: {
  layout: LayoutScene
  sceneId: string
  elementId: string
}) => {
  const title = useInteractiveElementTitle(sceneId, elementId)
  return (
    <ElementInteractive
      key={elementId}
      sceneId={sceneId}
      elementId={elementId}
      title={title}
      sceneX={layout.x}
      sceneY={layout.y}
      sceneWidth={layout.width}
      sceneHeight={layout.height}
    />
  )
}

const transformableElementTypes = new Set<DesignElement['type']>([
  'sticker',
  'overlay-image',
  'overlay-text',
])

const useSortedElementIds = (sceneId: string) => {
  const elementIds = useSceneElementIds(sceneId)
  const selectedElementRef = useSelectedElementRef()

  return useMemo(() => {
    if (sceneId !== selectedElementRef?.sceneId) {
      return elementIds
    }

    if (
      !selectedElementRef.id ||
      !selectedElementRef.type ||
      !transformableElementTypes.has(selectedElementRef.type)
    ) {
      return elementIds
    }

    const sortedIds = [...elementIds]
    sortedIds.splice(elementIds.indexOf(selectedElementRef.id), 1)
    sortedIds.push(selectedElementRef.id)
    return sortedIds
  }, [sceneId, selectedElementRef, elementIds])
}

const useSceneElementIds = (sceneId: string): string[] => {
  return useView('main', view => view.design.sceneById[sceneId].elementIds)
}

const SceneInteractiveContent: FC<{
  sceneId: string
  layout: LayoutScene
}> = memo(({ sceneId, layout }) => {
  const sceneTitle = useInteractiveSceneTitle(sceneId)
  const elementIds = useSortedElementIds(sceneId)
  const headingRef = useFocusRef<HTMLHeadingElement>(FOCUS_ID_SCENE(sceneId))

  return (
    <>
      <foreignObject width="1" height="1">
        <ScreenReaderOnly>
          <h2 ref={headingRef} tabIndex={-1}>
            {sceneTitle}
          </h2>
        </ScreenReaderOnly>
      </foreignObject>
      <SceneToolbarSkipLink sceneId={sceneId} />
      {elementIds.map(elementId => {
        return (
          <Element
            key={elementId}
            sceneId={sceneId}
            elementId={elementId}
            layout={layout}
          />
        )
      })}
    </>
  )
})

const SceneInteractive: FC<{ sceneId: string; layout: LayoutScene }> = ({
  sceneId,
  layout,
}) => {
  const isSelected = useView(
    'main',
    ({ selectedSceneId }) => selectedSceneId === sceneId,
  )

  return (
    <g
      transform={`translate(${layout.x} ${layout.y})`}
      aria-hidden={!isSelected}
    >
      <SceneInteractiveContent sceneId={sceneId} layout={layout} />
    </g>
  )
}

const CollabCursor: FC<{ x: number; y: number; name: string }> = ({
  x,
  y,
  name,
}) => {
  const { inverseScale } = useCamera()

  const [p] = useSpring(() => {
    return { x, y }
  }, [x, y])

  const maxNameLength = 12
  const displayedName =
    name.length > maxNameLength ? `${name.substring(0, maxNameLength)}…` : name

  return (
    <animated.g
      aria-hidden
      style={{ pointerEvents: 'none' }}
      transform={to([p.x, p.y], (animatedX, animatedY) => {
        return `translate(${animatedX} ${animatedY})`
      })}
    >
      <g transform={`scale(${inverseScale})`}>
        <IconCursor />
        <g transform="translate(20, 32)">
          <text fill="#313131" fontSize={16} fontWeight="bold">
            {displayedName}
          </text>
        </g>
      </g>
    </animated.g>
  )
}

const CollabCursors: FC = () => {
  const { inverseScale } = useCamera()
  const cursors = useView(
    'main',
    view => view.collaborateSession?.cursors ?? {},
  )
  const peers = useView('main', view => view.collaborateSession?.peers ?? [])
  const iconOffset: Vec2 = [4, 4]
  const offset: Vec2 = vec2Scale(iconOffset, inverseScale)

  return (
    <>
      {Object.entries(cursors).map(([peerId, position]) => {
        const peer = peers.find(other => other.id === peerId)

        if (!peer) {
          return null
        }

        const cursorPosition = vec2Sub(position!, offset)

        return (
          <CollabCursor
            key={peerId}
            x={cursorPosition[0]}
            y={cursorPosition[1]}
            name={peer.name}
          />
        )
      })}
    </>
  )
}

const LayerInteractiveContent: FC = memo(() => {
  const sceneIds = useSceneIds()
  const layout = useLayout()

  return (
    <>
      {sceneIds.map(sceneId => {
        const sceneLayout = layout.sceneById[sceneId]
        return (
          <SceneInteractive
            key={sceneId}
            sceneId={sceneId}
            layout={sceneLayout}
          />
        )
      })}
      <CollabCursors />
    </>
  )
})

const LayerInteractiveSurface: FC = () => {
  const { animation } = useCamera()

  return (
    <animated.g style={animation} transform-origin={'0 0'}>
      <LayerInteractiveContent />
    </animated.g>
  )
}

const useTrackCursor = () => {
  const { toWorldPoint } = useCamera()
  const updateCursor = useAction('updateCursor')
  const isDesktop = useIsDesktop()

  const handleUpdate = useCallback(
    (screenPosition: Vec2) => {
      const headerHeight = isDesktop ? 64 : 56
      const interactiveLayerPosition = vec2Sub(screenPosition, [
        0,
        headerHeight,
      ])
      updateCursor(toWorldPoint(interactiveLayerPosition))
    },
    [isDesktop, toWorldPoint, updateCursor],
  )

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      handleUpdate([event.clientX, event.clientY])
    }
    const handleTouchMove = (event: TouchEvent) => {
      const touch = event.touches[0]
      if (touch) {
        handleUpdate([touch.clientX, touch.clientY])
      }
    }

    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('touchmove', handleTouchMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('touchmove', handleTouchMove)
    }
  })
}

export const LayerInteractive: FC = () => {
  useTrackCursor()

  return (
    <>
      <ImageManipulation />
      <svg
        style={{
          position: 'absolute',
          zIndex: 0,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
        }}
      >
        <LayerInteractiveSurface />
      </svg>
    </>
  )
}
