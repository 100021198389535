import { RegisterResources } from '@moonpig/renderer-react'
import { Design, Services } from '@moonpig/web-personalise-editor-types'
import { RemoteImageStore } from '../types'

export const loadMediaImages = async ({
  services,
  registerResources,
  design,
  initialMediaImageById,
}: {
  services: Services
  registerResources: RegisterResources
  design: Design
  initialMediaImageById: RemoteImageStore['mediaImageById']
}): Promise<RemoteImageStore['mediaImageById']> => {
  const mediaToRender: {
    mediaId: string
    elementWidth: number
    elementHeight: number
  }[] = []

  design.sceneIds.forEach(sceneId => {
    design.sceneById[sceneId].elementIds.forEach(elementId => {
      const element = design.sceneById[sceneId].elementById[elementId]

      if (element.type !== 'media' || element.state.type !== 'uploaded') {
        return
      }

      const existingImage = initialMediaImageById[element.state.mediaId]

      if (existingImage) {
        return
      }

      mediaToRender.push({
        mediaId: element.state.mediaId,
        elementWidth: element.width,
        elementHeight: element.height,
      })
    })
  })

  const mediaImageByIdEntries = await Promise.all(
    mediaToRender.map(
      async ({
        mediaId,
        elementWidth,
        elementHeight,
      }): Promise<[string, string]> => {
        const result = await services.renderMediaImage({
          id: mediaId,
          elementWidth,
          elementHeight,
        })

        if (result.type !== 'success') {
          throw new Error('Failed to render media image')
        }

        const imageUrl = result.url
        const imageElement = await services.loadImage(imageUrl)

        registerResources.registerImage({ imageUrl, imageElement })

        return [mediaId, imageUrl]
      },
    ),
  )

  const mediaImageById = {
    ...initialMediaImageById,
    ...Object.fromEntries(mediaImageByIdEntries),
  }

  const mediaImageUrls = Object.values(mediaImageById).filter(
    (url): url is string => !!url,
  )

  await Promise.all(
    mediaImageUrls.map(async imageUrl => {
      const imageElement = await services.loadImage(imageUrl)
      registerResources.registerImage({ imageUrl, imageElement })
    }),
  )

  return mediaImageById
}
