import { Link, Text } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { colorValue } from '@moonpig/launchpad-theme'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { getTooltipAndArrowOffsets } from './utils'
import { TooltipProps } from './types'

const UI_TOOLTIP_WIDTH = 274
const UI_TOOLTIP_POINTER_SIZE = 30

type TooltipContainerProps = {
  pointerOffsetX: number
  pointerOffsetY: number
  top: number
  left: number
  bottom: number
}

const StyledTooltipContainer = styled.div<Partial<TooltipContainerProps>>`
  ${s({ px: 5, py: 4, borderRadius: 2 })}
  position: absolute;
  background: ${colorValue('colorBackgroundInformation')};
  filter: drop-shadow(0px 0px 4px rgb(0 32 77 / 15%));
  pointer-events: all;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  bottom: ${({ bottom }) => bottom}px;
  z-index: 1;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: ${UI_TOOLTIP_POINTER_SIZE * 0.5}px solid;
  }
  &.bottom {
    min-width: ${UI_TOOLTIP_WIDTH}px;
    &::after {
      border-color: transparent transparent
        ${colorValue('colorBackgroundInformation')} transparent;
      top: ${-UI_TOOLTIP_POINTER_SIZE}px;
      left: ${({ pointerOffsetX }) => pointerOffsetX}px;
    }
  }
  &.right {
    width: ${UI_TOOLTIP_WIDTH}px;
    &::after {
      top: ${({ pointerOffsetY }) => pointerOffsetY}px;
      left: ${-UI_TOOLTIP_POINTER_SIZE}px;
      border-color: transparent ${colorValue('colorBackgroundInformation')}
        transparent transparent;
    }
  }
  &.left {
    width: ${UI_TOOLTIP_WIDTH}px;
    &::after {
      top: ${({ pointerOffsetY }) => pointerOffsetY}px;
      left: ${({ pointerOffsetX }) => pointerOffsetX}px;
      border-color: transparent transparent transparent
        ${colorValue('colorBackgroundInformation')};
    }
  }
  &.top {
    min-width: ${UI_TOOLTIP_WIDTH}px;
    &::after {
      top: ${({ pointerOffsetY }) => pointerOffsetY}px;
      left: ${({ pointerOffsetX }) => pointerOffsetX}px;
      border-color: ${colorValue('colorBackgroundInformation')} transparent
        transparent transparent;
    }
  }
`

export const Tooltip: FC<TooltipProps> = ({
  target,
  placement,
  onDismiss,
  done,
  next,
  children,
}) => {
  const tooltipRef = useRef<HTMLDivElement>(null)
  const [tooltipPosition, setTooltipPosition] = useState({})

  const dismissIfClickedOutsideTooltip = useCallback(
    (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        tooltipRef.current !== event.target &&
        onDismiss
      ) {
        onDismiss()
      }
    },
    [onDismiss, tooltipRef],
  )

  useEffect(() => {
    if (target && tooltipRef.current) {
      setTooltipPosition(
        getTooltipAndArrowOffsets({
          target,
          tooltip: tooltipRef.current,
          pointerSize: UI_TOOLTIP_POINTER_SIZE,
          placement,
        }),
      )
    }

    window.addEventListener('click', dismissIfClickedOutsideTooltip)

    return () => {
      window.removeEventListener('click', dismissIfClickedOutsideTooltip)
    }
  }, [target, placement, dismissIfClickedOutsideTooltip, tooltipRef])

  return (
    <StyledTooltipContainer
      data-testid="mp-tooltip"
      onClick={event => {
        event.stopPropagation()
      }}
      ref={tooltipRef}
      {...tooltipPosition}
      className={placement}
    >
      {children}
      <Text as="div" fontWeight="bold" typography="bodySmall" textAlign="right">
        {done && (
          <>
            <Link aria-label={done.ariaLabel} onClick={done.onDone}>
              {done.label}
            </Link>{' '}
          </>
        )}
        {next && (
          <Link aria-label={next.ariaLabel} onClick={next.onNext}>
            {next.label}
          </Link>
        )}
      </Text>
    </StyledTooltipContainer>
  )
}
