import {
  Vec2,
  vec2AngleSigned,
  vec2Dot,
  vec2Mag,
  vec2Norm,
  vec2Scale,
} from '@moonpig/common-math'
import { BindPointerHandlers, usePointer } from '@moonpig/use-transform'
import { useCallback, useRef } from 'react'
import { SpringRef } from 'react-spring'
import { AnimationConfig, CameraNode } from '../types'
import { createTransform } from '../utils'

const NODE_CHANGE_THRESHOLD = 48

const snapPan = (node: CameraNode, v: Vec2): [Vec2, string | null] => {
  const panRaw = v

  let minDiff = Infinity
  let match: {
    lock: Vec2
    id: string
  } | null = null
  for (let i = 0; i < node.connected.length; i += 1) {
    const child = node.connected[i]
    const lock: Vec2 = [
      node.center.x - child.center.x,
      node.center.y - child.center.y,
    ]

    const diff = vec2AngleSigned(lock, panRaw)
    const absDiff = Math.abs(diff)

    if (absDiff < Math.PI * 0.5 && absDiff < minDiff) {
      minDiff = absDiff
      match = {
        id: child.id,
        lock,
      }
    }
  }

  if (!match) {
    return [[0, 0], null]
  }

  const u = vec2Norm(match.lock)
  const dv = vec2Scale(u, vec2Dot(v, u))

  return [dv, match.id]
}

type UsePanResult = BindPointerHandlers

export const usePan = (props: {
  node?: CameraNode
  translate: Vec2
  scale: number
  spring: SpringRef<{
    transform: string
    raw: [number, number, number]
  }>
  animationConfig: AnimationConfig
  onNodeChange?: (nodeId: string, prevNodeId: string) => void
  disable?: boolean
}): UsePanResult => {
  const {
    node,
    translate,
    scale,
    spring,
    animationConfig,
    onNodeChange,
    disable,
  } = props

  const currRef = useRef<Vec2 | null>(null)
  const startRef = useRef<Vec2 | null>(null)

  const onContactsChange = useCallback(
    (contactsCurr: Vec2[]) => {
      if (disable) {
        return
      }

      if (!node || !onNodeChange) return

      const start = startRef.current
      const curr = contactsCurr.length ? contactsCurr[0] : null
      if (curr && !start) {
        // Start
        startRef.current = curr as Vec2
        const value = [0, 0] as Vec2
        currRef.current = value

        spring.start(() => {
          const [x, y] = translate
          return {
            transform: createTransform(x, y, scale),
            raw: [x, y, scale],
            immediate: true,
            config: animationConfig,
          }
        })
        return
      }

      if (curr && start) {
        // Move
        const value = [curr[0] - start[0], curr[1] - start[1]] as Vec2
        currRef.current = value

        const [d] = snapPan(node, value)

        spring.start(() => {
          const [x, y] = translate
          const [dx, dy] = d
          const tx = x + dx
          const ty = y + dy
          return {
            transform: createTransform(tx, ty, scale),
            raw: [tx, ty, scale],
            immediate: true,
            config: animationConfig,
          }
        })
        return
      }

      /* istanbul ignore else */
      if (!curr && start) {
        // End
        const value = currRef.current || /* istanbul ignore next */ [0, 0]

        const [d, id] = snapPan(node, value)

        const toNodeId = vec2Mag(d) >= NODE_CHANGE_THRESHOLD ? id : null

        startRef.current = null
        currRef.current = null

        spring.start(() => {
          const [x, y] = translate
          return {
            transform: createTransform(x, y, scale),
            raw: [x, y, scale],
            immediate: false,
            config: animationConfig,
          }
        })

        if (toNodeId) {
          onNodeChange(toNodeId, node.id)
        }
      }
    },
    [disable, node, onNodeChange, spring, translate, scale, animationConfig],
  )

  const bind = usePointer(onContactsChange, { id: 'camera' })

  return bind
}
