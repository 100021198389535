import React, { FC } from 'react'
import { animated, SpringRef, useSpring } from 'react-spring'
import {
  VIDEO_ASPECT_RATIO,
  VIDEO_POSITION_X_PERCENT,
  VIDEO_POSITION_Y_PERCENT,
  VIDEO_WIDTH_PERCENT,
} from './constants'
import { Size } from './types'

type LayerMediaCommonProps = {
  springRef: SpringRef
  sceneSize: Size
  duration: number
}

export const LayerMediaCommon: FC<LayerMediaCommonProps> = ({
  springRef,
  sceneSize,
  duration,
  children,
}) => {
  const spring = useSpring({
    ref: springRef,
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
    config: { duration },
  })

  const width = sceneSize.width * VIDEO_WIDTH_PERCENT
  const height = width / VIDEO_ASPECT_RATIO
  const x = sceneSize.width * VIDEO_POSITION_X_PERCENT
  const y = sceneSize.height * VIDEO_POSITION_Y_PERCENT

  return (
    <>
      <animated.div
        style={{
          ...spring,
          position: 'absolute',
          top: `${y}px`,
          left: `${x}px`,
          width: `${width}px`,
          height: `${height}px`,
        }}
      >
        {children}
      </animated.div>
    </>
  )
}
