import { byId } from '../utils/byId'
import { getLayoutPad } from './pad'
import { drawShadow, drawCrease } from './draw'
import { Layout, LayoutImage, LayoutScene, LayoutSceneInput } from './types'
import { calculateDimensions, calculateBounds } from './utils'

export const buildLayoutCardLandscape = (
  scenes: LayoutSceneInput[],
): Layout => {
  const pad = getLayoutPad(scenes)
  const sceneWidth = 187
  const sceneHeight = 133

  const frontCover: LayoutScene = {
    id: scenes[0].id,
    ...calculateDimensions(0, sceneHeight, sceneWidth, sceneHeight),
    connected: [],
  }

  const insideTop: LayoutScene = {
    id: scenes[1].id,
    ...calculateDimensions(sceneWidth + pad, 0, sceneWidth, sceneHeight),
    connected: [],
  }

  const insideBottom: LayoutScene = {
    id: scenes[2].id,
    ...calculateDimensions(
      sceneWidth + pad,
      sceneHeight,
      sceneWidth,
      sceneHeight,
    ),
    connected: [],
  }

  const backCover: LayoutScene = {
    id: scenes[3].id,
    ...calculateDimensions(
      sceneWidth + pad + sceneWidth + pad,
      sceneHeight,
      sceneWidth,
      sceneHeight,
    ),
    connected: [],
  }

  frontCover.connected.push(insideBottom)
  insideTop.connected.push(insideBottom, frontCover, backCover)
  insideBottom.connected.push(backCover, insideTop, frontCover)
  backCover.connected.push(insideBottom)

  const layoutScenes: LayoutScene[] = [
    frontCover,
    insideTop,
    insideBottom,
    backCover,
  ]

  const imageShadowFrontBack = drawShadow(sceneWidth, sceneHeight)
  const imageShadowInside = drawShadow(sceneWidth, sceneHeight * 2)
  const imageCreaseInside = drawCrease(sceneWidth, 'top')

  const imageFrontCover: LayoutImage = {
    id: 'front-cover',
    url: imageShadowFrontBack.url,
    x: frontCover.x + imageShadowFrontBack.x,
    y: frontCover.y + imageShadowFrontBack.y,
    width: imageShadowFrontBack.width,
    height: imageShadowFrontBack.height,
  }

  const imageInside: LayoutImage = {
    id: 'inside',
    url: imageShadowInside.url,
    x: insideTop.x + imageShadowInside.x,
    y: insideTop.y + imageShadowInside.y,
    width: imageShadowInside.width,
    height: imageShadowInside.height,
  }

  const imageBackCover: LayoutImage = {
    id: 'back-cover',
    url: imageShadowFrontBack.url,
    x: backCover.x + imageShadowFrontBack.x,
    y: backCover.y + imageShadowFrontBack.y,
    width: imageShadowFrontBack.width,
    height: imageShadowFrontBack.height,
  }

  const imageInsideCrease: LayoutImage = {
    id: 'inside-crease',
    url: imageCreaseInside.url,
    x: insideBottom.x + imageCreaseInside.x,
    y: insideBottom.y + imageCreaseInside.y,
    width: imageCreaseInside.width,
    height: imageCreaseInside.height,
  }

  return {
    bounds: calculateBounds(layoutScenes),
    sceneById: byId(layoutScenes),
    images: {
      background: [imageFrontCover, imageInside, imageBackCover],
      foreground: [imageInsideCrease],
    },
  }
}
