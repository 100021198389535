import { FontSizePicker } from '@moonpig/web-personalise-components'
import {
  DesignElementOverlayText,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback } from 'react'
import { useAction, useView } from '../../../../../../../../store'
import { getTextFragmentsChangeType } from '../../../../../../../../store/utils/getTextFragmentsChangeType'
import { useElementRef } from '../../../../../../selectors'

type ElementFontSizePickerProps = {
  elementRef: DesignElementRef<DesignElementOverlayText>
  availableFontSizes: number[]
}

const getClosestAvailableFontSize = (
  availableFontSizes: number[],
  updatedFontSize: number,
): number =>
  availableFontSizes.reduce((prev, curr) => {
    return curr <= updatedFontSize ? curr : prev
  })

export const ElementFontSizePicker: FC<ElementFontSizePickerProps> = ({
  elementRef,
  availableFontSizes,
}) => {
  const element = useElementRef(elementRef)
  const updateElement = useAction('updateElement')
  const { availableTextStyles } = useView('main', view => view.design)

  const onSelect = useCallback(
    (fontSize: number) => {
      updateElement(
        {
          ...element,
          customisations: {
            ...element.customisations,
            fontSize,
          },
        },
        { changeType: getTextFragmentsChangeType(element) },
      )
    },
    [element, updateElement],
  )

  return (
    <FontSizePicker
      onSelect={onSelect}
      selectedFontSize={getClosestAvailableFontSize(
        availableTextStyles.availableFontSizes,
        element.customisations.fontSize,
      )}
      sizes={availableFontSizes.map(value => ({ value }))}
    />
  )
}
