import { styled } from '@moonpig/launchpad-utils'
import {
  CREASE_COLOR,
  CREASE_SHADOW_COLOR,
  CREASE_SHADOW_SIZE,
} from './constants'

export const CreaseShadowPortrait = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: ${CREASE_SHADOW_SIZE}px;
  height: 100%;
  background: linear-gradient(to left, ${CREASE_SHADOW_COLOR}, transparent);
  border-right: 1px solid ${CREASE_COLOR};
`

export const CreaseShadowLandscape = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: ${CREASE_SHADOW_SIZE}px;
  background: linear-gradient(to top, ${CREASE_SHADOW_COLOR}, transparent);
  border-bottom: 1px solid ${CREASE_COLOR};
`
