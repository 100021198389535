import { RegisterResources } from '@moonpig/renderer-react'
import {
  EditorConfig,
  EditorFeatures,
  Services,
  Session,
} from '@moonpig/web-personalise-editor-types'
import React, { FC } from 'react'
import create, { SetState } from 'zustand'
import { unstable_batchedUpdates } from 'react-dom'
import { GetEditorLocaleText } from '../text-localisation'
import { createAddElement } from './actions/createAddElement'
import { createAddPhotos } from './actions/createAddPhotos'
import { createDeleteElement } from './actions/createDeleteElement'
import { createDeselectElement } from './actions/createDeselectElement'
import { createDismissModal } from './actions/createDismissModal'
import { createDismissNotification } from './actions/createDismissNotification'
import { createEdit } from './actions/createEdit'
import { createExit } from './actions/createExit'
import { createFinish } from './actions/createFinish'
import { createGenerateSmartText } from './actions/createGenerateSmartText'
import { createLoad } from './actions/createLoad'
import { createLoadSticker } from './actions/createLoadSticker'
import { createNavigateHistory } from './actions/createNavigateHistory'
import { createNotify } from './actions/createNotify'
import { createPreview } from './actions/createPreview'
import { createPreviewLoaded } from './actions/createPreviewLoaded'
import { createRemovePhoto } from './actions/createRemovePhoto'
import { createReorderElement } from './actions/createReorderElement'
import { createResetDesign } from './actions/createResetDesign'
import { createResetSmartTextState } from './actions/createResetSmartTextState'
import { createSelectElement } from './actions/createSelectElement'
import { createSelectLayout } from './actions/createSelectLayout'
import { createSelectScene } from './actions/createSelectScene'
import { createSetUI } from './actions/createSetUI'
import { createShowModal } from './actions/createShowModal/createShowModal'
import { createStartAudioRecording } from './actions/createStartAudioRecording'
import { createStopAudioRecording } from './actions/createStopAudioRecording'
import { createTrackEvent } from './actions/createTrackEvent'
import {
  createUpdateElement,
  createUpdateElementRef,
} from './actions/createUpdateElement'
import { createUpdateOnboardingViewed } from './actions/createUpdateOnboardingViewed'
import { createUpdateSmartTextPrompt } from './actions/createUpdateSmartTextPrompt'
import { createUpdateStyledElementStyles } from './actions/createUpdateStyledElementStyles'
import { createUploadMedia } from './actions/createUploadMedia'
import { Provider } from './context'
import { StoreSubscriber } from './subscriber'
import { ActionContext, Store } from './types'
import { createShare } from './actions/createShare'
import { createUpdateCursor } from './actions/createUpdateCursor'

type StoreProviderProps = {
  config: EditorConfig
  features: EditorFeatures
  session: Session
  services: Services
  t: GetEditorLocaleText
  registerResources: RegisterResources
}

export const StoreProvider: FC<StoreProviderProps> = ({
  config,
  features,
  children,
  session,
  services,
  t,
  registerResources,
}) => {
  return (
    <Provider
      createStore={() =>
        create<Store>((set, get) => {
          const customSet: SetState<Store> = (partial, replace) => {
            unstable_batchedUpdates(() => {
              set(partial, replace)
            })
          }

          const context: ActionContext = {
            services,
            set: customSet,
            get,
            t,
            registerResources,
          }

          return {
            config,
            features,
            session,
            view: { type: 'initial' },
            audioRecording: null,
            remoteImageStore: {
              editedImageById: {},
              dynamicImageById: {},
              mediaImageById: {},
            },
            load: createLoad(context),
            preview: createPreview(context),
            previewLoaded: createPreviewLoaded(context),
            edit: createEdit(context),
            addElement: createAddElement(context),
            deleteElement: createDeleteElement(context),
            reorderElement: createReorderElement(context),
            finish: createFinish(context),
            exit: createExit(context),
            addPhotos: createAddPhotos(context),
            removePhoto: createRemovePhoto(context),
            dismissNotification: createDismissNotification(context),
            selectElement: createSelectElement(context),
            deselectElement: createDeselectElement(context),
            selectScene: createSelectScene(context),
            selectLayout: createSelectLayout(context),
            setUI: createSetUI(context),
            updateElement: createUpdateElement(context),
            updateElementRef: createUpdateElementRef(context),
            updateStyledElementStyles: createUpdateStyledElementStyles(context),
            notify: createNotify(context),
            resetDesign: createResetDesign(context),
            trackEvent: createTrackEvent(context),
            loadSticker: createLoadSticker(context),
            generateSmartText: createGenerateSmartText(context),
            resetSmartTextState: createResetSmartTextState(context),
            updateSmartTextPrompt: createUpdateSmartTextPrompt(context),
            uploadMedia: createUploadMedia(context),
            showModal: createShowModal(context),
            dismissModal: createDismissModal(context),
            updateOnboardingViewed: createUpdateOnboardingViewed(context),
            navigateHistory: createNavigateHistory(context),
            startAudioRecording: createStartAudioRecording(context),
            stopAudioRecording: createStopAudioRecording(context),
            share: createShare(context),
            updateCursor: createUpdateCursor(context),
          }
        })
      }
    >
      <StoreSubscriber
        services={services}
        registerResources={registerResources}
      />
      {children}
    </Provider>
  )
}
