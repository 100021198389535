import { IconSystemTickFilled } from '@moonpig/launchpad-assets'
import { focusIndicatorStyles } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { colorValue } from '@moonpig/launchpad-theme'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { ScreenReaderOnly } from '../ScreenReaderOnly'
import { IconDelete } from '../../assets'

const StyledIconSmall = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  ${s({ bgcolor: 'colorBackground02' })}
`

const StyledThumbnail = styled.div`
  position: relative;
`

const StyledImageButton = styled.button`
  ${focusIndicatorStyles}
  ${s({ borderRadius: 2 })}

  overflow: hidden;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

const StyledBorder = styled.div`
  border-radius: inherit;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &:not(.disabled) {
    &:hover {
      box-shadow: 0 0 0 2px ${colorValue('colorInteractionButtonHover')} inset;
    }

    &.selected {
      box-shadow: 0 0 0 2px ${colorValue('colorInteractionSelectedState')} inset;
    }
  }
`

const StyledDeleteButton = styled.button`
  ${focusIndicatorStyles}
  ${s({ borderRadius: 7 })}

  position: absolute;
  left: calc(50% - 16px);
  bottom: -16px;
  color: ${colorValue('colorInteractionIcon')};

  &:hover {
    color: ${colorValue('colorInteractionIconHover')};
  }
`

const StyledSelected = styled.div`
  position: absolute;
  right: 6px;
  bottom: 6px;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  pointer-events: none;
  ${s({ color: 'colorInteractionSelectedState', bgcolor: 'white' })}
`

type ContentButtonProps = {
  selected: boolean
  disabled?: boolean
  multiSelect?: boolean
  selectLabel?: string
  deleteLabel?: string
  onSelect: () => void
  onDelete?: () => void
}

export const ContentButton: FC<ContentButtonProps> = ({
  selected,
  disabled,
  multiSelect,
  selectLabel,
  deleteLabel,
  onSelect,
  onDelete,
  children,
}) => {
  return (
    <StyledThumbnail>
      <StyledImageButton
        onClick={onSelect}
        disabled={disabled}
        role={multiSelect ? 'checkbox' : 'radio'}
        aria-checked={selected}
      >
        <ScreenReaderOnly>{selectLabel}</ScreenReaderOnly>
        {children}
        <StyledBorder
          className={`${selected ? 'selected' : ''} ${
            disabled ? 'disabled' : ''
          }`}
        />
      </StyledImageButton>
      {selected ? (
        <StyledSelected>
          <IconSystemTickFilled width={28} height={28} />
        </StyledSelected>
      ) : null}
      {onDelete && (
        <StyledDeleteButton onClick={onDelete}>
          <StyledIconSmall>
            <IconDelete width={20} height={20} />
          </StyledIconSmall>
          <ScreenReaderOnly>{deleteLabel}</ScreenReaderOnly>
        </StyledDeleteButton>
      )}
    </StyledThumbnail>
  )
}
