import { TextAlignmentPicker } from '@moonpig/web-personalise-components'
import {
  DesignElementOverlayText,
  DesignElementRef,
  DesignHorizontalAlignment,
  DesignVerticalAlignment,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback } from 'react'
import { useAction, useEditorFeatures } from '../../../../../../../../store'
import { getTextFragmentsChangeType } from '../../../../../../../../store/utils/getTextFragmentsChangeType'
import { useElementRef } from '../../../../../../selectors'

type ElementTextAlignmentPickerProps = {
  elementRef: DesignElementRef<DesignElementOverlayText>
}

export const ElementTextAlignmentPicker: FC<
  ElementTextAlignmentPickerProps
> = ({ elementRef }) => {
  const element = useElementRef(elementRef)
  const updateElement = useAction('updateElement')
  const { enableTextFragments } = useEditorFeatures()

  const onHorizontalAlignmentSelect = useCallback(
    (newHorizontalAlignment: DesignHorizontalAlignment) => {
      updateElement(
        {
          ...element,
          customisations: {
            ...element.customisations,
            horizontalAlignment: newHorizontalAlignment,
          },
        },
        { changeType: getTextFragmentsChangeType(element) },
      )
    },
    [element, updateElement],
  )

  const onVerticalAlignmentSelect = useCallback(
    (newVerticalAlignment: DesignVerticalAlignment) => {
      updateElement(
        {
          ...element,
          customisations: {
            ...element.customisations,
            verticalAlignment: newVerticalAlignment,
          },
        },
        { changeType: getTextFragmentsChangeType(element) },
      )
    },
    [element, updateElement],
  )

  const enableVerticalAlignment = element.fixed || !enableTextFragments

  return (
    <TextAlignmentPicker
      selectedHorizontalAlignment={element.customisations.horizontalAlignment}
      onHorizontalChange={onHorizontalAlignmentSelect}
      selectedVerticalAlignment={
        enableVerticalAlignment
          ? element.customisations.verticalAlignment
          : null
      }
      onVerticalChange={
        enableVerticalAlignment ? onVerticalAlignmentSelect : null
      }
    />
  )
}
