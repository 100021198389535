import { Transform2d, vec2Add, vec2Scale } from '@moonpig/common-math'
import { logger } from '@moonpig/web-core-monitoring'
import { loadImage } from '@moonpig/web-core-utils'
import {
  AddPhoto,
  LoadPhotos,
  Photo,
  PhotoImageData,
  RemovePhoto,
} from '@moonpig/web-personalise-editor-types'
import { wrapAsyncFunctionError, wrapFunctionError } from '../utils/wrapError'
import { blobStore } from './blobStore'
import { TransformImage, createTransformImage } from './createTransformImage'

const SMALL_IMAGE_SIZE = 256
const MEDIUM_IMAGE_SIZE = 1024
const EDITED_IMAGE_SCALE = 10

const createImageKey = (id: string): string => `ed-image-${id}`

const createMediumImageKey = (id: string): string => `ed-scaled-${id}`

const createSmallImageKey = (id: string): string => `ed-thumbnail-${id}`

const parseKey = (key: string): string | null => {
  const matches = /^ed-image-(.+)$/.exec(key)

  return matches ? matches[1] : null
}

const removeNullItems = <T>(arr: (T | null)[]): T[] =>
  arr.reduce<T[]>((acc, item) => {
    if (item !== null) {
      acc.push(item)
    }
    return acc
  }, [])

const resizeImage = (
  originalWidth: number,
  originalHeight: number,
  maxSize: number,
) => {
  if (originalWidth <= maxSize && originalHeight <= maxSize) {
    return { width: originalWidth, height: originalHeight }
  }

  const aspectRatio = originalWidth / originalHeight
  const fillWidth = originalWidth < originalHeight
  const width = fillWidth ? maxSize : maxSize * aspectRatio
  const height = fillWidth ? maxSize / aspectRatio : maxSize

  return { width, height }
}

const renderScaledImage = async ({
  transformImage,
  image,
  scaledSize,
}: {
  transformImage: TransformImage
  image: { url: string; width: number; height: number }
  scaledSize: number
}): Promise<{ imageData: Blob; width: number; height: number }> => {
  const { width, height } = resizeImage(image.width, image.height, scaledSize)

  const scaledImageData = await transformImage({
    url: image.url,
    width,
    height,
    transform: {
      position: [width / 2, height / 2],
      scale: [width / image.width, height / image.height],
      rotation: 0,
    },
  })

  return {
    imageData: scaledImageData,
    width,
    height,
  }
}

const createPhotoImageData = async ({
  transformImage,
  originalImage,
  key,
  addedTime,
  scaledSize,
}: {
  transformImage: TransformImage
  originalImage: PhotoImageData
  key: string
  addedTime: number
  scaledSize: number
}): Promise<PhotoImageData> => {
  const { imageData, width, height } = await renderScaledImage({
    transformImage,
    image: originalImage,
    scaledSize,
  })

  await blobStore.store({
    addedTime,
    key,
    width,
    height,
    blob: imageData,
  })

  return {
    url: URL.createObjectURL(imageData),
    width,
    height,
  }
}

const loadPhotoImageData = async (
  key: string,
): Promise<{ addedTime: number; photoImageData: PhotoImageData } | null> => {
  const imageData = await blobStore.load(key)

  if (!imageData) {
    return null
  }

  const url = URL.createObjectURL(imageData.blob)
  const imageElement = await loadImage(url)

  return {
    addedTime: imageData.addedTime,
    photoImageData: {
      url,
      width: imageElement.naturalWidth,
      height: imageElement.naturalHeight,
    },
  }
}

const loadPhoto = async (id: string): Promise<Photo | null> => {
  const [originalImage, mediumImage, smallImage] = await Promise.all([
    loadPhotoImageData(createImageKey(id)),
    loadPhotoImageData(createMediumImageKey(id)),
    loadPhotoImageData(createSmallImageKey(id)),
  ])

  if (!originalImage || !mediumImage || !smallImage) {
    return null
  }

  return {
    id,
    addedTime: originalImage.addedTime,
    originalImage: originalImage.photoImageData,
    mediumImage: mediumImage.photoImageData,
    smallImage: smallImage.photoImageData,
  }
}

const loadImageWrapped = wrapAsyncFunctionError(
  url => `Failed to load image element (${url})`,
  loadImage,
)

const createObjectUrlWrapped = wrapFunctionError(
  'Failed to create object URL',
  (blob: Blob) => URL.createObjectURL(blob),
)

const createPhoto = async ({
  transformImage,
  originalImageData,
}: {
  transformImage: TransformImage
  originalImageData: { id: string; addedTime: number; blob: Blob }
}): Promise<Photo> => {
  const transformImageWrapped = wrapAsyncFunctionError(
    'Failed to transform image',
    transformImage,
  )
  const originalImageUrl = createObjectUrlWrapped(originalImageData.blob)
  const originalImageElement = await loadImageWrapped(originalImageUrl)

  const originalImage: PhotoImageData = {
    url: originalImageUrl,
    width: originalImageElement.naturalWidth,
    height: originalImageElement.naturalHeight,
  }

  const [mediumImage, smallImage] = await Promise.all([
    createPhotoImageData({
      transformImage: transformImageWrapped,
      originalImage,
      scaledSize: MEDIUM_IMAGE_SIZE,
      key: createMediumImageKey(originalImageData.id),
      addedTime: originalImageData.addedTime,
    }),
    createPhotoImageData({
      transformImage: transformImageWrapped,
      originalImage,
      scaledSize: SMALL_IMAGE_SIZE,
      key: createSmallImageKey(originalImageData.id),
      addedTime: originalImageData.addedTime,
    }),
    blobStore.store({
      addedTime: originalImageData.addedTime,
      key: createImageKey(originalImageData.id),
      width: originalImage.width,
      height: originalImage.height,
      blob: originalImageData.blob,
    }),
  ])

  return {
    addedTime: originalImageData.addedTime,
    id: originalImageData.id,
    originalImage,
    mediumImage,
    smallImage,
  }
}

export type PhotoStore = {
  loadPhotos: LoadPhotos
  addPhoto: AddPhoto
  removePhoto: RemovePhoto
  createEditedImage: (
    photo: Photo,
    size: { width: number; height: number },
    transform: Transform2d,
  ) => Promise<Blob>
}

export const createPhotoStore = (): PhotoStore => {
  let cachedTransformImage: TransformImage | null = null
  let lastAddedTime = 0

  const getTransformImage = (): TransformImage => {
    cachedTransformImage = cachedTransformImage ?? createTransformImage()
    return cachedTransformImage
  }

  return {
    loadPhotos: async () => {
      try {
        const keys = await blobStore.list()
        const ids = removeNullItems(keys.map(parseKey))
        const photos = await Promise.all(ids.map(loadPhoto))

        return removeNullItems(photos).sort((a, b) => b.addedTime - a.addedTime)
      } catch (error) {
        logger.fixToday('Failed to load photos', {}, error)

        return []
      }
    },
    addPhoto: async (id, imageData) => {
      try {
        // Preserve ordering when multiple images are added at the same time
        const currentTime = Date.now()
        const addedTime = Math.max(currentTime, lastAddedTime + 1)
        lastAddedTime = addedTime

        const photo = await createPhoto({
          transformImage: getTransformImage(),
          originalImageData: {
            id,
            addedTime,
            blob: imageData,
          },
        })

        return { type: 'success', photo }
      } catch (error) {
        logger.fixToday('Failed to add photo', {}, error)

        return { type: 'error' }
      }
    },
    removePhoto: async id => {
      try {
        await Promise.all([
          blobStore.delete(createImageKey(id)),
          blobStore.delete(createMediumImageKey(id)),
          blobStore.delete(createSmallImageKey(id)),
        ])
      } catch (error) {
        logger.fixToday('Failed to remove photo', {}, error)
      }
    },
    createEditedImage: async (photo, size, transform) => {
      const transformImage = getTransformImage()

      const imageData = await transformImage({
        width: size.width * EDITED_IMAGE_SCALE,
        height: size.height * EDITED_IMAGE_SCALE,
        url: photo.originalImage.url,
        transform: {
          ...transform,
          scale: vec2Scale(transform.scale, EDITED_IMAGE_SCALE),
          position: vec2Scale(
            vec2Add(transform.position, [size.width * 0.5, size.height * 0.5]),
            EDITED_IMAGE_SCALE,
          ),
        },
      })

      return imageData
    },
  }
}
