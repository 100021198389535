import React, { FC } from 'react'

export const IconPhotos: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 24 24" width={width} height={height}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2ZM0 4a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Zm4 16 4-6 3 4 4-5 5 7H4Z"
        fill="currentColor"
      />
    </svg>
  )
}
