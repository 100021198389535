export const binarySearch = (
  count: number,
  predicate: (index: number) => boolean,
): number | null => {
  const search = (startIndex: number, endIndex: number): number | null => {
    if (startIndex > endIndex) {
      return null
    }

    const middleIndex = Math.ceil((startIndex + endIndex) / 2)

    if (predicate(middleIndex)) {
      if (startIndex === endIndex) {
        return middleIndex
      }
      return search(middleIndex, endIndex)
    }

    return search(startIndex, middleIndex - 1)
  }

  return search(0, count - 1)
}
