import {
  Flex,
  PrimaryButton,
  SecondaryButton,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC, useCallback } from 'react'
import { useAction, useView } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { VideoInput } from '../VideoInput'

const VIDEO_INPUT_ID = 'ed-video-onboarding-input'

const StyledStepList = styled.ol`
  ${s({ pl: 6 })}
  list-style: decimal outside;
`

const HTMLListItem: FC<{ html: string }> = ({ html }) => {
  /* eslint-disable react/no-danger */
  return <li dangerouslySetInnerHTML={{ __html: html }} />
}

export const VideoOnboarding: FC = () => {
  const t = useEditorLocaleText()
  const setUI = useAction('setUI')
  const trackEvent = useAction('trackEvent')
  const productKey = useView('main', view => view.design.productKey)

  const handleSelectVideo = useCallback(
    (file: Blob) => {
      setUI({
        type: 'default',
        selectedMenuItem: 'video-upload',
        video: { file, url: URL.createObjectURL(file) },
      })

      trackEvent({
        type: 'SELECT_CONTENT',
        kind: 'VIDEO_ONBOARDING_ADD',
        label: 'modal',
        productKey,
      })
    },
    [productKey, setUI, trackEvent],
  )

  const handleMaybeLater = useCallback(() => {
    setUI({ type: 'default', selectedMenuItem: null })

    trackEvent({
      type: 'SELECT_CONTENT',
      kind: 'VIDEO_ONBOARDING_DISMISS',
      productKey,
      label: 'modal',
    })
  }, [productKey, setUI, trackEvent])

  return (
    <Flex flexDirection="column" flex={1}>
      <StyledStepList>
        <HTMLListItem html={t('toolbar_menu_video_step_1')} />
        <HTMLListItem html={t('toolbar_menu_step_2')} />
        <HTMLListItem html={t('toolbar_menu_step_3')} />
      </StyledStepList>
      <Flex flexDirection="column" pt={6}>
        <VideoInput
          id={VIDEO_INPUT_ID}
          onChange={handleSelectVideo}
          label={
            <PrimaryButton
              width="100%"
              {...{ as: 'label', htmlFor: VIDEO_INPUT_ID }}
            >
              {t('button_add_video')}
            </PrimaryButton>
          }
        />
        <SecondaryButton width="100%" mt={6} onClick={handleMaybeLater}>
          {t('button_maybe_later')}
        </SecondaryButton>
      </Flex>
    </Flex>
  )
}
