import { applyLayout } from '../../common/layouts'
import { ActionCreator } from '../../types'
import { storeUpdateDesign } from '../../updaters/design'

export const createSelectLayout: ActionCreator<'selectLayout'> =
  ({ set, t }) =>
  (grid, sceneId) => {
    set(store => {
      return storeUpdateDesign(
        store,
        { changeReason: 'elements-changed', t },
        design => {
          const selectedScene = design.sceneById[sceneId]

          return {
            ...design,
            sceneById: {
              ...design.sceneById,
              [sceneId]: applyLayout(design, selectedScene, grid),
            },
          }
        },
      )
    })
  }
