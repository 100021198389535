import { Design } from '@moonpig/web-personalise-editor-types'

type FeatureSupportResult = 'supported' | 'resettable' | 'unsupported'

const supportedFeatures = [
  'EditorFeatureAllCharacters',
  'EditorFeatureAudio',
  'EditorFeatureLayout',
  'EditorFeatureOverlayImage',
  'EditorFeatureOverlayInlay',
  'EditorFeatureOverlaySticker',
  'EditorFeatureOverlayText',
  'EditorFeatureTemplateBaseline',
  'EditorFeatureTemplateImageCollage',
  'EditorFeatureTemplateImageDynamic',
  'EditorFeatureVideo',
]

export const checkFeatureSupport = (design: Design): FeatureSupportResult => {
  const unsupportedFeatures = design.usedFeatures.filter(
    feature => !supportedFeatures.includes(feature.type),
  )

  if (unsupportedFeatures.length === 0) {
    return 'supported'
  }

  if (unsupportedFeatures.every(feature => !feature.required)) {
    return 'resettable'
  }

  return 'unsupported'
}
