import { assert } from '../../../utils/assert'
import { ActionCreator } from '../../types'

export const createDismissModal: ActionCreator<'dismissModal'> =
  ({ set }) =>
  async () => {
    set(({ view }) => {
      assert(view.type === 'main' && view.view.type === 'edit')

      return {
        view: {
          ...view,
          view: {
            ...view.view,
            modal: null,
          },
        },
      }
    })
  }
