import React, { FC } from 'react'

export const IconDelete: FC<{
  width?: number
  height?: number
  background?: string
}> = ({ width = 24, height = 24, background = 'transparent' }) => {
  return (
    <svg viewBox="0 0 20 20" width={width} height={height}>
      <path
        d="M13.6134 3.03579V2.23571C13.6134 1.35379 12.875 0.636475 11.9675 0.636475H7.03252C6.12406 0.636475 5.38753 1.35287 5.38753 2.23663V3.03579H2.09665C1.1882 3.03579 0.45166 3.75126 0.45166 4.63502V5.4351C0.45166 6.31794 1.1882 6.56825 2.09665 6.56825V15.8306C2.09665 17.5963 3.56972 19.3635 5.38753 19.3635H13.6134C15.4312 19.3635 16.9034 17.5972 16.9034 15.8306V6.56825C17.8118 6.56825 18.5483 6.31702 18.5483 5.43418V4.6341C18.5483 3.75126 17.8118 3.03579 16.9034 3.03579H13.6134Z"
        fill={background}
      />
      <path
        d="M7.273 9.565c0-.48-.407-.87-.91-.87-.501 0-.908.39-.908.87v6.087c0 .48.407.87.909.87s.909-.39.909-.87V9.565ZM10.91 9.565c0-.48-.408-.87-.91-.87s-.91.39-.91.87v6.087c0 .48.408.87.91.87s.91-.39.91-.87V9.565ZM13.636 8.696c.502 0 .91.389.91.87v6.086c0 .48-.408.87-.91.87s-.909-.39-.909-.87V9.565c0-.48.407-.87.91-.87Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.546 2.609v-.87C14.546.78 13.73 0 12.727 0H7.273C6.269 0 5.455.779 5.455 1.74v.869H1.818C.814 2.609 0 3.387 0 4.348v.87c0 .96.814 1.739 1.818 1.739v9.565c0 1.92 1.628 3.478 3.637 3.478h9.09c2.009 0 3.637-1.557 3.637-3.478V6.957c1.004 0 1.818-.78 1.818-1.74v-.87c0-.96-.814-1.738-1.818-1.738h-3.636Zm-7.273-.87v.87h5.454v-.87H7.273Zm10.909 3.478H1.818v-.87h16.364v.87ZM3.636 6.957h12.728v9.565c0 .96-.814 1.739-1.819 1.739h-9.09c-1.005 0-1.819-.779-1.819-1.74V6.958Z"
        fill="currentColor"
      />
    </svg>
  )
}
