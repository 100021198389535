import React, { FC } from 'react'

export const IconReplace: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 24 24" width={width} height={height}>
      <path
        d="m19 4 5 5-5 5v-4H9V8h10V4ZM5 10l-5 5 5 5v-4h10v-2H5v-4Z"
        fill="currentColor"
      />
    </svg>
  )
}
