import { Vec2, vec2MagSq, vec2Scale, vec2Sub } from '@moonpig/common-math'
import { styled } from '@moonpig/launchpad-utils'
import React, {
  FC,
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useRef,
  useState,
} from 'react'
import { useGesture } from 'react-use-gesture'
import { Frame } from '../../common/frame'

const FOCUSSED_FILL_COLOR = 'rgba(255, 255, 255, 0.3)'
const DIMMED_FILL_COLOR = 'rgba(255, 255, 255, 0.7)'
const TARGETED_FILL_COLOR = 'rgba(255, 255, 255, 0.3)'
const PRESS_DISTANCE_THRESHOLD_SQUARED = 16

export type DragEvent = {
  pointerOffset: Vec2
  position: Vec2
  last: boolean
}

type InteractiveCellButtonProps = {
  label: string
  bounds: Frame
  scale: number
  cell: { offset: Vec2; scale: number }
  active: boolean
  dimmed: boolean
  targeted: boolean
  disableSelectCell: MutableRefObject<boolean>
  onClick: () => void
  onKeyDown: (event: KeyboardEvent) => void
  onDrag: (event: DragEvent) => void
}

const StyledGrabRect = styled.a`
  outline: none;
  touch-action: none;

  &:focus-visible > rect {
    fill: ${FOCUSSED_FILL_COLOR};
  }
`

export const InteractiveCellButton: FC<InteractiveCellButtonProps> = ({
  label,
  bounds,
  scale,
  cell,
  active,
  dimmed,
  targeted,
  disableSelectCell,
  onClick,
  onKeyDown,
  onDrag,
  children,
}) => {
  const pointerOffset = useRef<Vec2>([0, 0])
  const [isDragging, setIsDragging] = useState(false)

  const bind = useGesture({
    onPointerDown: () => {
      // eslint-disable-next-line no-param-reassign
      disableSelectCell.current = false
    },
    onDrag: state => {
      // eslint-disable-next-line no-param-reassign
      setIsDragging(!state.last)

      const movedDistance = vec2MagSq(state.movement)
      if (movedDistance > PRESS_DISTANCE_THRESHOLD_SQUARED) {
        // eslint-disable-next-line no-param-reassign
        disableSelectCell.current = true
      }

      if (state.first) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const elementBounds = state.event!.currentTarget.getBoundingClientRect()
        const elementPosition: Vec2 = [elementBounds.left, elementBounds.top]

        pointerOffset.current = vec2Scale(
          vec2Sub(state.initial, elementPosition),
          scale,
        )
      }

      onDrag({
        pointerOffset: pointerOffset.current,
        position: vec2Scale(state.movement, scale),
        last: state.last,
      })
    },
    onPinch: /* istanbul ignore next */ () => {
      // eslint-disable-next-line no-param-reassign
      disableSelectCell.current = true
    },
  })

  const handleClick = useCallback(() => {
    if (!disableSelectCell.current) {
      onClick()
    }
  }, [disableSelectCell, onClick])

  let cursor = 'pointer'
  if (!dimmed && isDragging) {
    cursor = 'grabbing'
  } else if (!dimmed) {
    cursor = 'grab'
  }

  let interactiveButtonFill = 'transparent'
  if (dimmed) {
    interactiveButtonFill = DIMMED_FILL_COLOR
  } else if (targeted) {
    interactiveButtonFill = TARGETED_FILL_COLOR
  }

  return (
    <StyledGrabRect
      {...bind()}
      onKeyDown={onKeyDown}
      onClick={handleClick}
      role="button"
      tabIndex={active ? 0 : -1}
      aria-hidden={!active}
      aria-label={label}
      data-test-offset={`${cell.offset[0]} ${cell.offset[1]}`}
      data-test-scale={`${cell.scale}`}
      style={{
        cursor,
        pointerEvents: active ? 'all' : 'none',
      }}
    >
      <rect
        x={bounds.position[0]}
        y={bounds.position[1]}
        width={bounds.size[0]}
        height={bounds.size[1]}
        fill={interactiveButtonFill}
      />
      {children}
    </StyledGrabRect>
  )
}
