import { Design, DesignElements } from '@moonpig/web-personalise-editor-types'
import { sortElementsByLayer } from './elementLayers'

export const updateDesignSceneElements = (
  design: Design,
  sceneId: string,
  updater: (design: Design) => DesignElements,
): Design => {
  const scene = design.sceneById[sceneId]
  const { elementIds, elementById } = sortElementsByLayer(updater(design))

  return {
    ...design,
    sceneById: {
      ...design.sceneById,
      [scene.id]: {
        ...scene,
        elementIds,
        elementById,
      },
    },
  }
}
