import { useMatchMedia } from './useMatchMedia'

const breakpointMap = {
  xs: 0,
  sm: 400,
  md: 640,
  lg: 900,
  xxl: 1640,
}

export const useIsBreakpoint = (
  breakpoint: keyof typeof breakpointMap,
): boolean => {
  const breakpointWidth = breakpointMap[breakpoint]

  return useMatchMedia(`(min-width: ${breakpointWidth}px)`, false)
}
