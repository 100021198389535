import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { animated, SpringRef, useSpring } from 'react-spring'
import backgroundImageUrl from './assets/background.png'
import { TIMING_BACKGROUND } from './constants'

const StyledBackgroundImage = styled(animated.img)`
  transform-origin: center 90%;
  position: absolute;
  top: 0;
  left: 0;
`

type LayerBackgroundProps = {
  springRef: SpringRef
}

export const LayerBackground: FC<LayerBackgroundProps> = ({ springRef }) => {
  const spring = useSpring({
    ref: springRef,
    from: {
      filter: 'blur(0px)',
      transform: 'scale(1.2)',
    },
    to: {
      filter: 'blur(4px)',
      transform: 'scale(1.5)',
    },
    config: { duration: TIMING_BACKGROUND.end - TIMING_BACKGROUND.start },
  })

  return (
    <StyledBackgroundImage
      role="presentation"
      src={backgroundImageUrl}
      width="100%"
      height="100%"
      style={spring}
    />
  )
}
