import {
  DesignAvailableTextStyles,
  DesignDefaultTextStyle,
  DesignElementOverlayText,
} from '@moonpig/web-personalise-editor-types'
import { useCallback } from 'react'
import { v4 as uuid } from 'uuid'
import shallow from 'zustand/shallow'
import { useAction, useView } from '../../../../../../../store'

const getYPosition = (
  existingOverlayTextElements: number,
  sceneHeight: number,
): number => {
  if (existingOverlayTextElements === 0) return 20
  if (existingOverlayTextElements === 2) return sceneHeight * 0.7
  return 0.4 * sceneHeight
}

const createOverlayTextElement = ({
  sceneId,
  sceneWidth,
  sceneHeight,
  existingOverlayTextElements,
  defaultTextStyle,
  availableTextStyles,
}: {
  sceneId: string
  sceneWidth: number
  sceneHeight: number
  existingOverlayTextElements: number
  defaultTextStyle: DesignDefaultTextStyle
  availableTextStyles: DesignAvailableTextStyles
}): DesignElementOverlayText => {
  const { availableColorById, availableFontFaceById } = availableTextStyles

  return {
    id: uuid(),
    type: 'overlay-text',
    width: sceneWidth * 0.9,
    height: defaultTextStyle.fontSize,
    x: (sceneWidth * 0.1) / 2,
    y: getYPosition(existingOverlayTextElements, sceneHeight),
    sceneId,
    rotation: 0,
    horizontalAlignment: 'CENTER',
    verticalAlignment: 'MIDDLE',
    fontFace: availableFontFaceById[defaultTextStyle.fontFaceId],
    fontSize: defaultTextStyle.fontSize,
    lineSpacing: null,
    lineSpacingRelative: 0,
    color: availableColorById[defaultTextStyle.colorId],
    shadow: null,
    customisations: {
      text: '',
      fontFaceId: defaultTextStyle.fontFaceId,
      fontSize: defaultTextStyle.fontSize,
      colorId: defaultTextStyle.colorId,
      horizontalAlignment: 'CENTER',
      verticalAlignment: 'MIDDLE',
      author: null,
    },
    fragmentsState: {
      fragments: [],
      resizingWidth: false,
      version: 0,
    },
  }
}

export const useAddTextElement = () => {
  const selectElement = useAction('selectElement')
  const addElement = useAction('addElement')
  const { sceneId, sceneWidth, sceneHeight, existingOverlayTextElements } =
    useView(
      'main',
      view => {
        const scene = view.design.sceneById[view.selectedSceneId]

        return {
          sceneId: view.selectedSceneId,
          sceneWidth: scene.width,
          sceneHeight: scene.height,
          existingOverlayTextElements: scene.elementIds.filter(
            id => scene.elementById[id].type === 'overlay-text',
          ).length,
        }
      },
      shallow,
    )

  const defaultTextStyle = useView('main', view => view.design.defaultTextStyle)
  const availableTextStyles = useView(
    'main',
    view => view.design.availableTextStyles,
  )

  const addText = useCallback(() => {
    if (!defaultTextStyle) {
      return
    }

    const newElement = createOverlayTextElement({
      sceneId,
      sceneWidth,
      sceneHeight,
      existingOverlayTextElements,
      defaultTextStyle,
      availableTextStyles,
    })

    addElement(newElement)

    selectElement(sceneId, newElement.id)
  }, [
    defaultTextStyle,
    sceneId,
    sceneWidth,
    sceneHeight,
    existingOverlayTextElements,
    availableTextStyles,
    addElement,
    selectElement,
  ])

  return addText
}
