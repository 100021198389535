import {
  InteractiveElement,
  InteractiveElementBorder,
  InteractiveElementIndicator,
  InteractiveElementInteraction,
  InteractiveElementState,
} from '@moonpig/web-personalise-components'
import { DesignElement } from '@moonpig/web-personalise-editor-types'
import React, { FC, ReactNode, useCallback } from 'react'
import { useCamera } from '../../../../../../../camera'
import { FOCUS_ID_ELEMENT } from '../../../../../../../constants'
import { createElementDomId } from '../../../../../../../utils/createElementId'
import { useAction, useMainView, useView } from '../../../../../../../store'
import { clampBounds } from './clampBounds'

type InteractiveElementCommonProps = {
  isDimmed?: boolean
  indicator?: InteractiveElementIndicator
  border?: InteractiveElementBorder
  interactionNone?: boolean
  title: string
  sceneId: string
  sceneWidth: number
  sceneHeight: number
  element: DesignElement
  isInvalid?: boolean
  interactiveContent?: ReactNode
  onSelect?: () => void
}

export const InteractiveElementCommon: FC<InteractiveElementCommonProps> = ({
  isDimmed,
  indicator,
  border,
  interactionNone,
  title,
  sceneId,
  sceneWidth,
  sceneHeight,
  element,
  isInvalid,
  interactiveContent,
  onSelect,
  children,
}) => {
  const { inverseScale } = useCamera()
  const selectScene = useAction('selectScene')
  const selectElement = useAction('selectElement')
  const selectedSceneId = useView('main', view => view.selectedSceneId)
  const activeElementId = useMainView('edit', view => view.activeElementId)
  const isSelected = activeElementId === element.id
  const isSceneActive = selectedSceneId === sceneId

  const handleSelect = useCallback(() => {
    if (!isSceneActive) {
      selectScene(sceneId, 'FOCUS')
      return
    }

    selectElement(sceneId, element.id)
    if (onSelect) {
      onSelect()
    }
  }, [selectElement, sceneId, isSceneActive, element.id, onSelect, selectScene])

  const handleFocus = useCallback(() => {
    selectScene(sceneId, 'FOCUS')
  }, [selectScene, sceneId])

  const bounds =
    element.rotation === 0
      ? clampBounds({ element, sceneWidth, sceneHeight })
      : element

  const halfWidth = element.width * 0.5
  const halfHeight = element.height * 0.5

  const transform = [
    `translate(${bounds.x + halfWidth} ${bounds.y + halfHeight})`,
    `rotate(${element.rotation})`,
    `translate(${-halfWidth} ${-halfHeight})`,
  ].join(' ')

  let state: InteractiveElementState
  if (isSelected) {
    state = 'SELECTED'
  } else if (isDimmed) {
    state = 'DIMMED'
  } else {
    state = 'DEFAULT'
  }

  let interaction: InteractiveElementInteraction
  if (interactionNone) {
    interaction = 'NONE'
  } else if (activeElementId && !isSelected) {
    interaction = 'INERT'
  } else {
    interaction = 'DEFAULT'
  }

  return (
    <g transform={transform}>
      <InteractiveElement
        id={createElementDomId(element.id)}
        label={title}
        scale={inverseScale}
        width={bounds.width}
        height={bounds.height}
        state={state}
        indicator={indicator}
        border={border}
        interaction={interaction}
        invalid={isInvalid}
        focusId={FOCUS_ID_ELEMENT(element.id)}
        onSelect={handleSelect}
        onFocus={handleFocus}
      >
        {children}
      </InteractiveElement>
      {interactiveContent}
    </g>
  )
}
