import { FontMetrics } from '@moonpig/renderer-react'
import {
  Design,
  DesignFontMetrics,
  Services,
} from '@moonpig/web-personalise-editor-types'
import { FontById } from '../../types'

type FontUrlMap = {
  [id: string]: {
    fontUrl: string
    fontMetrics: FontMetrics
    designFontMetrics: DesignFontMetrics
  }
}

const convertFontMetrics = (metrics: DesignFontMetrics): FontMetrics => {
  return {
    unitsPerEm: metrics.head.unitsPerEm,
    usWinAscent: metrics.os2.usWinAscent,
    usWinDescent: metrics.os2.usWinDescent,
  }
}

export const extractFonts = (
  design: Design,
): {
  fontUrlMap: FontUrlMap
  initialFontIds: Set<string>
} => {
  const fontUrlMap: FontUrlMap = {}
  const initialFontIds = new Set<string>()

  design.availableTextStyles.availableFontFaceIds.forEach(fontFaceId => {
    const fontFace =
      design.availableTextStyles.availableFontFaceById[fontFaceId]

    fontUrlMap[fontFace.id] = {
      fontUrl: fontFace.urls.woff2,
      fontMetrics: convertFontMetrics(fontFace.metrics),
      designFontMetrics: fontFace.metrics,
    }
  })

  if (design.defaultTextStyle) {
    initialFontIds.add(design.defaultTextStyle.fontFaceId)
  }

  design.sceneIds.forEach(sceneId => {
    const scene = design.sceneById[sceneId]
    scene.elementIds.forEach(elementId => {
      const element = scene.elementById[elementId]

      switch (element.type) {
        case 'text-static':
        case 'text-plain':
        case 'text-placeholder': {
          const { fontFace } = element

          initialFontIds.add(fontFace.id)
          fontUrlMap[fontFace.id] = {
            fontUrl: fontFace.urls.woff2,
            fontMetrics: convertFontMetrics(fontFace.metrics),
            designFontMetrics: fontFace.metrics,
          }
          break
        }
        case 'text-styled': {
          initialFontIds.add(element.customisations.fontFaceId)

          element.availableFontFaceIds.forEach(availableFontFaceId => {
            const fontFace = element.availableFontFaceById[availableFontFaceId]

            fontUrlMap[fontFace.id] = {
              fontUrl: fontFace.urls.woff2,
              fontMetrics: convertFontMetrics(fontFace.metrics),
              designFontMetrics: fontFace.metrics,
            }
          })
          break
        }
        case 'overlay-text': {
          initialFontIds.add(element.customisations.fontFaceId)

          break
        }
      }
    })
  })

  return { fontUrlMap, initialFontIds }
}

type LoadedFont = {
  fontUrl: string
  fontFamily: string
  fontMetrics: FontMetrics
}

export const loadInitialFonts = async ({
  services,
  fontUrlMap,
  initialFontIds,
}: {
  services: Services
  fontUrlMap: FontUrlMap
  initialFontIds: Set<string>
}): Promise<{ fontById: FontById; loadedFonts: LoadedFont[] }> => {
  const fontById: FontById = {}
  const loadedFonts: LoadedFont[] = []

  Array.from(Object.keys(fontUrlMap)).forEach(id => {
    fontById[id] = {
      type: 'loading',
      fontFamily: `mp-ed-${id}`,
    }
  })

  await Promise.all(
    Array.from(initialFontIds.values()).map(async id => {
      const { fontFamily } = fontById[id]
      const { fontUrl, fontMetrics, designFontMetrics } = fontUrlMap[id]
      await services.loadFont(fontFamily, fontUrl, designFontMetrics)

      fontById[id] = {
        fontFamily,
        type: 'loaded',
      }

      loadedFonts.push({
        fontUrl,
        fontFamily,
        fontMetrics,
      })
    }),
  )

  return { fontById, loadedFonts }
}

export const loadRemainingFonts = async ({
  services,
  fontUrlMap,
  fontById,
  onLoad,
  onError,
}: {
  services: Services
  fontUrlMap: FontUrlMap
  fontById: FontById
  onLoad: (event: {
    id: string
    fontUrl: string
    fontFamily: string
    fontMetrics: FontMetrics
  }) => void
  onError: (event: { id: string }) => void
}): Promise<void> => {
  await Promise.all(
    Array.from(Object.entries(fontById)).map(async ([id, currentState]) => {
      if (currentState.type !== 'loading') {
        return
      }

      const { fontUrl, fontMetrics, designFontMetrics } = fontUrlMap[id]
      const { fontFamily } = currentState

      try {
        await services.loadFont(fontFamily, fontUrl, designFontMetrics)
        onLoad({ id, fontUrl, fontMetrics, fontFamily })
      } catch {
        onError({ id })
      }
    }),
  )
}
