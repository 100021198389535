import {
  Box,
  Flex,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { TextInput } from '@moonpig/launchpad-forms'
import { Modal } from '@moonpig/web-personalise-components'
import React, { FC, useCallback, useRef, useState } from 'react'
import { useAction, useView } from '../../../../../../store'
import { useEditorLocaleText } from '../../../../../../text-localisation'

const ShareScreen: FC = () => {
  const initialName = useView('main', view => view.shareName)
  const [name, setName] = useState(initialName)
  const edit = useAction('edit')
  const share = useAction('share')

  const handleShare = useCallback(() => {
    share(name)
  }, [name, share])

  return (
    <>
      <Text>
        Share a link with others to let them edit the design with you. Enter
        your name to let them see who is editing.
      </Text>
      <Flex mt={4} flexDirection="column" width="100%">
        <TextInput
          label="Your Name (optional)"
          name=""
          value={name}
          placeholder="Anonymous"
          onChange={event => {
            setName(event.currentTarget.value)
          }}
        />
        <PrimaryButton mt={6} onClick={handleShare}>
          Share
        </PrimaryButton>
        <SecondaryButton mt={6} onClick={edit}>
          Maybe Later
        </SecondaryButton>
      </Flex>
    </>
  )
}

const LinkScreen: FC<{ link: string }> = ({ link }) => {
  const edit = useAction('edit')
  const [copied, setCopied] = useState(false)
  const handleCopyLink = useCallback(async () => {
    await navigator.clipboard.writeText(link)
    setCopied(true)
  }, [link])
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <>
      <Text>
        Share this link with others to let them edit the design with you.
      </Text>
      <Flex mt={4} flexDirection="column" width="100%">
        <TextInput
          ref={inputRef}
          label="Link"
          name=""
          value={link}
          onChange={() => {}}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onFocus={() => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const input = inputRef.current!
            input.setSelectionRange(0, input.value.length)
          }}
        />
        <PrimaryButton mt={6} onClick={handleCopyLink}>
          {copied ? 'Copied to clipboard!' : 'Copy Link'}
        </PrimaryButton>
        <SecondaryButton mt={6} onClick={edit}>
          Done
        </SecondaryButton>
      </Flex>
    </>
  )
}

export const InviteModal: FC = () => {
  const t = useEditorLocaleText()
  const sharing = useView('main', view => view.sharing)
  const edit = useAction('edit')

  return (
    <Modal
      heading="Invite others to edit?"
      closeLabel={t('modal_close_button')}
      onClose={edit}
    >
      <Box mx="auto" maxWidth={420}>
        {sharing ? <LinkScreen link={sharing.link} /> : <ShareScreen />}
      </Box>
    </Modal>
  )
}
