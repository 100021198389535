import { Flex, TertiaryButton } from '@moonpig/launchpad-components'
import { ProgressBar } from '@moonpig/web-personalise-components'
import {
  DesignElementMedia,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, ReactNode, useCallback } from 'react'
import { useAction, useView } from '../../../../../store'
import { useEditorLocaleText } from '../../../../../text-localisation'

type MediaProgressBarProps = {
  elementRef: DesignElementRef<DesignElementMedia>
  progressPercent: number
  kind: DesignElementMedia['kind']
}

const MediaProgressBar: FC<MediaProgressBarProps> = ({
  elementRef,
  progressPercent,
  kind,
}) => {
  const t = useEditorLocaleText()
  const deleteElement = useAction('deleteElement')

  const mediaProgressBarText = {
    audio: {
      uploading: t('audio_uploading'),
      cancel_upload: t('cancel_audio_upload'),
    },
    video: {
      uploading: t('video_uploading'),
      cancel_upload: t('cancel_video_upload'),
    },
  }

  const handleCancel = useCallback(() => {
    deleteElement(elementRef)
  }, [deleteElement, elementRef])

  return (
    <Flex width="100%" alignItems="center">
      <ProgressBar
        id={`mp-ed-media-interactive-${elementRef.id}`}
        initialLabel={mediaProgressBarText[kind].uploading}
        progressPercent={progressPercent}
      />
      <TertiaryButton
        ml={6}
        onClick={handleCancel}
        aria-label={mediaProgressBarText[kind].cancel_upload}
      >
        {t('cancel')}
      </TertiaryButton>
    </Flex>
  )
}

export const useMediaProgressBar = (): ReactNode => {
  const mediaElement = useView('main', view => {
    // eslint-disable-next-line no-restricted-syntax
    for (const sceneId of view.design.sceneIds) {
      const scene = view.design.sceneById[sceneId]
      // eslint-disable-next-line no-restricted-syntax
      for (const elementId of scene.elementIds) {
        const element = scene.elementById[elementId]
        if (element.type === 'media') {
          return element
        }
      }
    }
    return null
  })

  if (!mediaElement) {
    return null
  }

  let progressPercent: number
  switch (mediaElement.state.type) {
    case 'loading': {
      progressPercent = 0
      break
    }
    case 'uploading': {
      progressPercent = mediaElement.state.progressPercent
      break
    }
    default: {
      return null
    }
  }

  return (
    <MediaProgressBar
      elementRef={mediaElement}
      progressPercent={progressPercent}
      kind={mediaElement.kind}
    />
  )
}
