import React, { FC } from 'react'
import { SpringRef } from 'react-spring'

import { TIMING_MEDIA_VIDEO } from './constants'
import { LayerMediaCommon } from './LayerMediaCommon'
import { Size } from './types'

type LayerMediaVideoProps = {
  springRef: SpringRef
  sceneSize: Size
  demoVideoUrl: string
}

export const LayerMediaVideo: FC<LayerMediaVideoProps> = ({
  springRef,
  sceneSize,
  demoVideoUrl,
}) => {
  return (
    <LayerMediaCommon
      springRef={springRef}
      sceneSize={sceneSize}
      duration={TIMING_MEDIA_VIDEO.end - TIMING_MEDIA_VIDEO.start}
    >
      <video
        muted
        loop
        playsInline
        autoPlay
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <source src={demoVideoUrl} type="video/mp4" />
      </video>
    </LayerMediaCommon>
  )
}
