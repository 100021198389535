import { Services } from '@moonpig/web-personalise-editor-types'

type LoadedImage = {
  imageUrl: string
  imageElement: HTMLImageElement
}

export const loadStaticImages = async ({
  services,
  imageUrls,
}: {
  services: Services
  imageUrls: string[]
}): Promise<LoadedImage[]> => {
  return Promise.all(
    Array.from(imageUrls.values()).map(async imageUrl => {
      const imageElement = await services.loadImage(imageUrl)
      return { imageUrl, imageElement }
    }),
  )
}
