import { Bounds } from '@moonpig/common-math'
import { CameraUI } from './types'
import { expandBounds, transposeBounds } from './utils'

const squashToFit = (
  availableAspect: number,
  availableHeight: number,
  focus: Bounds,
  pad: number,
  screenHeight: number,
  uiSize: number,
): Bounds => {
  const actualFocusHeight = focus.width / availableAspect

  const scale = actualFocusHeight / availableHeight
  const uiWorldSize = uiSize * scale
  const worldHeight = scale * screenHeight

  const worldX = focus.x - uiWorldSize
  const worldY = focus.y - (worldHeight - focus.height) * 0.5

  const worldBounds = {
    x: worldX,
    y: worldY,
    width: focus.width + uiWorldSize,
    height: actualFocusHeight,
  }

  return expandBounds(pad * scale, worldBounds)
}

const panToFit = (
  availableAspect: number,
  availableWidth: number,
  focus: Bounds,
  pad: number,
  screenWidth: number,
  uiSize: number,
): Bounds => {
  const actualFocusWidth = focus.height * availableAspect

  const scale = actualFocusWidth / availableWidth
  const uiWorldSize = uiSize * scale
  const worldWidth = scale * screenWidth

  const worldCenterX = worldWidth * 0.5
  const leftCenterX = uiWorldSize + focus.width * 0.5
  const offsetX = -Math.max(0, worldCenterX - leftCenterX)
  const worldX = offsetX + focus.x - uiWorldSize

  const worldY = focus.y
  const worldBounds = {
    x: worldX,
    y: worldY,
    width: actualFocusWidth + uiWorldSize,
    height: focus.height,
  }

  return expandBounds(pad * scale, worldBounds)
}

const layout = (
  focus: Bounds,
  screen: Bounds,
  uiSize: number,
  pad: number,
): Bounds => {
  const screenWidth = screen.width - 2 * pad
  const screenHeight = screen.height - 2 * pad

  const availableWidth = screenWidth - uiSize
  const availableHeight = screenHeight

  const focusAspect = focus.width / focus.height
  const availableAspect = availableWidth / availableHeight

  if (focusAspect > availableAspect) {
    return squashToFit(
      availableAspect,
      availableHeight,
      focus,
      pad,
      screenHeight,
      uiSize,
    )
  }

  return panToFit(
    availableAspect,
    availableWidth,
    focus,
    pad,
    screenWidth,
    uiSize,
  )
}

const layoutTop = (
  focus: Bounds,
  screen: Bounds,
  uiSize: number,
  pad: number,
): Bounds => {
  const focusT = transposeBounds(focus)
  const screenT = transposeBounds(screen)
  const worldT = layout(focusT, screenT, uiSize, pad)
  return transposeBounds(worldT)
}

export const calculateWorldBounds = (
  focus: Bounds,
  screen: Bounds,
  ui: CameraUI | null,
  pad: number,
) => {
  const uiDirection = ui ? ui.direction : null
  const uiSize = ui ? ui.size : 0
  const worldBounds =
    !ui || uiDirection === 'top'
      ? layoutTop(focus, screen, uiSize, pad)
      : layout(focus, screen, uiSize, pad)
  return worldBounds
}
