import {
  DesignElementImageUpload,
  DesignElementRef,
  Photo,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useMemo } from 'react'
import shallow from 'zustand/shallow'
import { assert } from '../../../../../../../../utils/assert'
import { getInitialScale } from '../../../../../../../../utils/getInitialScale'
import { useAction, useView } from '../../../../../../../../store'
import { getDesignElementByRef } from '../../../../../../selectors'
import { PhotoLibrary } from '../../PhotoLibrary'

type ImageUploadPhotoLibraryProps = {
  elementRef: DesignElementRef<DesignElementImageUpload>
  labelledById: string
}

const applyPhoto = (
  element: DesignElementImageUpload,
  photo: Photo,
): DesignElementImageUpload => {
  const scale = getInitialScale(
    photo.originalImage.width,
    photo.originalImage.height,
    element.width,
    element.height,
  )

  const transform = {
    position: { x: 0, y: 0 },
    rotation: 0,
    scale,
  }

  return {
    ...element,
    customisations: {
      ...element.customisations,
      pendingEditedImage: null,
      editedImage: null,
      sourceImage: {
        id: photo.id,
        transform,
      },
    },
  }
}

export const ImageUploadPhotoLibrary: FC<ImageUploadPhotoLibraryProps> = ({
  elementRef,
  labelledById,
}) => {
  const addPhotos = useAction('addPhotos')
  const updateElementRef = useAction('updateElementRef')
  const removePhoto = useAction('removePhoto')
  const photos = useView('main', view => view.photos)

  const otherEmptyImageUploadElementRefs = useView(
    'main',
    view => {
      return Object.entries(
        view.design.sceneById[view.selectedSceneId].elementById,
      )
        .map(([, element]) => element)
        .filter(
          (element): element is DesignElementImageUpload =>
            element.type === 'image-upload' &&
            element.customisations.sourceImage === null &&
            element.customisations.pendingEditedImage === null &&
            element.customisations.editedImage === null &&
            element.id !== elementRef.id,
        )
        .map(
          (element): DesignElementRef<DesignElementImageUpload> => ({
            type: element.type,
            sceneId: element.sceneId,
            id: element.id,
          }),
        )
    },
    shallow,
  )

  const handleAddPhotos = useCallback(
    (files: Blob[]) => {
      const imageUploadElementRefs = [
        elementRef,
        ...otherEmptyImageUploadElementRefs,
      ]

      addPhotos(files, addedPhotos => {
        addedPhotos.forEach((photo, index) => {
          const imageUploadElementRef = imageUploadElementRefs[index]

          if (!imageUploadElementRef) {
            return
          }

          updateElementRef(imageUploadElementRef, current => {
            return applyPhoto(current, photo)
          })
        })
      })
    },
    [addPhotos, elementRef, otherEmptyImageUploadElementRefs, updateElementRef],
  )

  const handleSelectPhoto = useCallback(
    (photoId: string) => {
      updateElementRef(elementRef, current => {
        const photoState = photos.photoById[photoId]

        assert(photoState.type === 'loaded')

        const { photo } = photoState

        return applyPhoto(current, photo)
      })
    },
    [elementRef, photos.photoById, updateElementRef],
  )

  const selectedPhotoId = useView('main', view => {
    const element = getDesignElementByRef(view.design, elementRef)

    return element.customisations.sourceImage?.id
  })

  const photosView = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return photos.photoIds.map(id => {
      const photoState = photos.photoById[id]

      switch (photoState.type) {
        case 'loading': {
          return {
            type: 'loading' as const,
            id,
          }
        }
        case 'loaded': {
          return {
            type: 'loaded' as const,
            id,
            selected: id === selectedPhotoId,
            imageUrl: photoState.photo.smallImage.url,
          }
        }
      }
    })
  }, [photos, selectedPhotoId])

  return (
    <PhotoLibrary
      labelledById={labelledById}
      photos={photosView}
      multiSelect={false}
      onAddPhotos={handleAddPhotos}
      onSelectPhoto={handleSelectPhoto}
      onRemovePhoto={removePhoto}
    />
  )
}
