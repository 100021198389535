/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
/* eslint-disable no-multi-assign */
import { DocHandle, Repo } from '@automerge/automerge-repo'
import { IndexedDBStorageAdapter } from '@automerge/automerge-repo-storage-indexeddb'
import { urlPrefix } from '@automerge/automerge-repo/dist/DocUrl'
import {
  Collaborate,
  CollaboratePeer,
  Design,
  DesignElementOverlayText,
  DesignElementSticker,
  DesignSticker,
} from '@moonpig/web-personalise-editor-types'
import { Vec2 } from '@moonpig/common-math'
import { throttle, debounce } from '@moonpig/launchpad-utils'
import { z } from 'zod'
import { WebSocketNetworkAdapter } from './webSocketNetworkAdapter'

const cursorUpdateSchema = z.object({
  peerId: z.string(),
  cursorPosition: z.tuple([z.number(), z.number()]),
})

type DocTypeElement =
  | {
      type: 'sticker'
      order: number
      x: number
      y: number
      size: number
      rotation: number
      stickerId: string
    }
  | {
      type: 'text'
      order: number
      x: number
      y: number
      width: number
      height: number
      rotation: number
      text: string
      fontFaceId: string
      fontSize: number
      colorId: string
      horizontalAlignment: string
      verticalAlignment: string
    }

type DocTypeElements = Record<string, DocTypeElement>

type DocType = {
  elements?: DocTypeElements
}

const convertDesignToDocument = (design: Design): DocType => {
  const elements: DocTypeElements = {}

  design.sceneIds.forEach(sceneId => {
    const scene = design.sceneById[sceneId]
    scene.elementIds.forEach((elementId, index) => {
      const element = scene.elementById[elementId]

      switch (element.type) {
        case 'sticker': {
          elements[element.id] = {
            type: 'sticker',
            order: index,
            x: element.x,
            y: element.y,
            size: element.width,
            rotation: element.rotation,
            stickerId: element.sticker.id,
          }
          break
        }
        case 'overlay-text': {
          elements[element.id] = {
            type: 'text',
            order: index,
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
            colorId: element.customisations.colorId,
            fontFaceId: element.customisations.fontFaceId,
            fontSize: element.customisations.fontSize,
            text: element.customisations.text,
            horizontalAlignment: element.customisations.horizontalAlignment,
            verticalAlignment: element.customisations.verticalAlignment,
          }
          break
        }
      }
    })
  })

  return {
    elements,
  }
}

const convertDocumentToDesign = ({
  stickers,
  currentDesign,
  document,
}: {
  stickers: DesignSticker[]
  currentDesign: Design
  document: DocType
}): Design => {
  const sceneId = currentDesign.sceneIds[0]
  const elements = document.elements ?? {}

  return {
    ...currentDesign,
    sceneById: {
      ...currentDesign.sceneById,
      [currentDesign.sceneIds[0]]: {
        ...currentDesign.sceneById[sceneId],
        elementIds: Object.keys(elements).sort(
          (a, b) => elements[a].order - elements[b].order,
        ),
        elementById: Object.fromEntries(
          Object.entries(elements).map(([id, e]) => {
            switch (e.type) {
              case 'sticker': {
                const sticker = stickers.find(s => s.id === e.stickerId)!

                const element: DesignElementSticker = {
                  type: 'sticker',
                  id,
                  sceneId,
                  x: e.x,
                  y: e.y,
                  width: e.size,
                  height: e.size,
                  rotation: e.rotation,
                  sticker,
                }

                return [id, element]
              }
              case 'text': {
                const fontFace =
                  currentDesign.availableTextStyles.availableFontFaceById[
                    e.fontFaceId
                  ]
                const color =
                  currentDesign.availableTextStyles.availableColorById[
                    e.colorId
                  ]

                const element: DesignElementOverlayText = {
                  type: 'overlay-text',
                  id,
                  sceneId,
                  x: e.x,
                  y: e.y,
                  width: e.width,
                  height: e.height,
                  rotation: e.rotation,
                  shadow: null,
                  horizontalAlignment: e.horizontalAlignment as any,
                  verticalAlignment: e.verticalAlignment as any,
                  lineSpacing: null,
                  lineSpacingRelative: 1,
                  fontSize: e.fontSize,
                  fragmentsState: null,
                  fontFace,
                  color,
                  customisations: {
                    horizontalAlignment: e.horizontalAlignment as any,
                    verticalAlignment: e.verticalAlignment as any,
                    fontSize: e.fontSize,
                    text: e.text,
                    author: null,
                    fontFaceId: e.fontFaceId,
                    colorId: e.colorId,
                  },
                }

                return [id, element]
              }
            }
          }),
        ),
      },
    },
  }
}

type DocumentChange =
  | {
      type: 'insert-element'
      id: string
      element: Record<string, unknown>
    }
  | {
      type: 'remove-element'
      id: string
    }
  | {
      type: 'update-element'
      id: string
      properties: Record<string, unknown>
    }

const diffDocuments = (curr: DocType, next: DocType): DocumentChange[] => {
  const changes: DocumentChange[] = []
  const currElements = curr.elements ?? {}
  const nextElements = next.elements ?? {}

  Object.keys(currElements).forEach(currElementId => {
    const nextElement = nextElements[currElementId]

    if (!nextElement) {
      changes.push({
        type: 'remove-element',
        id: currElementId,
      })
    }
  })

  Object.keys(nextElements).forEach(nextElementId => {
    const nextElement = nextElements[nextElementId] as Record<string, unknown>
    const currElement = currElements[nextElementId] as Record<string, unknown>

    if (currElement) {
      const properties: Record<string, unknown> = {}

      Object.entries(nextElement).forEach(([key, nextValue]) => {
        const currValue = currElement[key]

        if (nextValue !== currValue) {
          properties[key] = nextValue
        }
      })

      if (Object.keys(properties).length > 0) {
        changes.push({
          type: 'update-element',
          id: nextElementId,
          properties,
        })
      }
    } else {
      changes.push({
        type: 'insert-element',
        id: nextElementId,
        element: nextElement,
      })
    }
  })

  return changes
}

export const createCollaborate = (): Collaborate => {
  return async ({
    designId,
    initialName,
    initialDesign,
    stickers,
    onDesignUpdated,
    onCursorUpdated,
    onPeersUpdated,
  }) => {
    let peers: CollaboratePeer[] = []

    const webSocketNetworkAdapter = new WebSocketNetworkAdapter({
      name: initialName,
      designId,
      onPeersUpdated: updatedPeers => {
        peers = updatedPeers
        onPeersUpdated(updatedPeers)
      },
    })

    const repo = new Repo({
      network: [webSocketNetworkAdapter],
      storage: new IndexedDBStorageAdapter(),
    })

    const url = `${urlPrefix}${designId}`
    const handle: DocHandle<DocType> = repo.find(url as any)

    let currentDocument: DocType = await handle.doc()
    let currentDesign = convertDocumentToDesign({
      stickers,
      currentDesign: initialDesign,
      document: currentDocument,
    })

    handle.on('change', event => {
      currentDocument = event.doc
      currentDesign = convertDocumentToDesign({
        stickers,
        currentDesign,
        document: currentDocument,
      })
      onDesignUpdated(currentDesign)
    })

    handle.on('ephemeral-message', payload => {
      const parseResult = cursorUpdateSchema.safeParse(payload.message)
      if (parseResult.success) {
        const { peerId, cursorPosition } = parseResult.data
        onCursorUpdated(peerId, cursorPosition)
      }
    })

    const applyChanges = (changes: DocumentChange[]) => {
      handle.change((d: any) => {
        if (!d.elements) {
          d.elements = {}
        }
        changes.forEach(change => {
          switch (change.type) {
            case 'insert-element': {
              d.elements[change.id] = change.element
              break
            }
            case 'remove-element': {
              delete d.elements[change.id]
              break
            }
            case 'update-element': {
              const target = d.elements[change.id]
              if (target) {
                Object.entries(change.properties).forEach(([key, value]) => {
                  target[key] = value
                })
              }
            }
          }
        })
      })
    }

    const broadcastCursorUpdate = (position: Vec2) => {
      handle.broadcast({
        peerId: webSocketNetworkAdapter.getPeerId(),
        cursorPosition: position,
      })
    }

    const throttledBroadcastCursorUpdate = throttle(broadcastCursorUpdate, 200)

    const applyCurrentDesign = (design: Design) => {
      currentDesign = design
      const newDocument = convertDesignToDocument(currentDesign)
      const changes = diffDocuments(currentDocument, newDocument)
      if (changes.length > 0) {
        console.log('Syncing', changes.length, 'changes')
      }
      currentDocument = newDocument
      applyChanges(changes)
    }

    const throttledApplyCurrentDesign = debounce(applyCurrentDesign, 500)

    return {
      initialDesign: currentDesign,
      updateName: newName => {
        webSocketNetworkAdapter.updateName(newName)
      },
      updateDesign: design => {
        throttledApplyCurrentDesign(design)
      },
      updateCursor: position => {
        throttledBroadcastCursorUpdate(position)
      },
      peers,
    }
  }
}

export const createDesignId = (): string => {
  const repo = new Repo({
    network: [],
    storage: new IndexedDBStorageAdapter(),
  })

  const handle = repo.create<DocType>()
  handle.change((d: DocType) => {
    d.elements = {}
  })

  const designId = handle.url.replace(urlPrefix, '')
  return designId
}
