import { Box, VerticalStack } from '@moonpig/launchpad-components'
import { Selector } from '@moonpig/launchpad-forms'
import React, { FC, ReactNode } from 'react'
import { RadioGroup } from '../RadioGroup'

export type RadioListItem = {
  id: string
  element: ReactNode
  checked: boolean
  disabled?: boolean
}

type RadioListProps = {
  name: string
  onSelect: (id: string) => void
  items: RadioListItem[]
}

export const RadioList: FC<RadioListProps> = ({ items, onSelect, name }) => {
  return (
    <RadioGroup>
      <VerticalStack spacing={4}>
        {items.map(({ id, checked, disabled, element }) => {
          return (
            <Box width="100%" key={id}>
              <Selector
                type="radio"
                name={name}
                id={id}
                checked={checked}
                onChange={() => onSelect(id)}
                disabled={disabled || false}
              >
                {element}
              </Selector>
            </Box>
          )
        })}
      </VerticalStack>
    </RadioGroup>
  )
}
