import { BrandLogo } from '@moonpig/launchpad-assets'
import { Box, Flex, Text } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { keyframes, styled } from '@moonpig/launchpad-utils'
import React, { FC, ReactNode } from 'react'
import { PageHeading } from '../PageHeading'

type LoadingScreenProps = {
  title: string
  description?: string
  icon: ReactNode
}

const StyledPageHeading = styled(PageHeading)`
  ${s({
    mb: 2,
    typography: 'bodyBold',
  })}
`

const LOGO_WIDTH_PX = 96

const StyledBrandLogo = styled.div`
  position: absolute;
  bottom: 64px;
  left: 50%;
  width: ${LOGO_WIDTH_PX}px;
  margin-left: -${LOGO_WIDTH_PX / 2}px;
  ${s({ color: 'colorPrimary01' })}

  svg {
    display: block;
  }
`

const animateDot = keyframes`
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-3px);
  }

  50% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
`

const ANIMATION_DURATION_MS = 1300

const StyledDot = styled.span<{ animationDelay: string }>`
  display: block;
  background-color: currentColor;
  width: 3px;
  height: 3px;
  margin-right: 3px;
  border-radius: 50%;
  margin-top: 5px;
  animation: ${animateDot} ${ANIMATION_DURATION_MS}ms linear infinite
    ${({ animationDelay }) => animationDelay};
`

export const LoadingScreen: FC<LoadingScreenProps> = ({
  title,
  description,
  icon,
}) => {
  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding={8}
    >
      <Box mb={6} width="140px" height="140px">
        {icon}
      </Box>
      <Flex mb={2}>
        <StyledPageHeading>{title}</StyledPageHeading>{' '}
        <Flex alignItems="center" ml={3}>
          <StyledDot animationDelay="0ms" />
          <StyledDot animationDelay={`${ANIMATION_DURATION_MS / 6}ms`} />
          <StyledDot animationDelay={`${ANIMATION_DURATION_MS / 3}ms`} />
        </Flex>
      </Flex>
      <Text typography="bodySmall" textAlign="center">
        {description}
      </Text>
      <StyledBrandLogo>
        <BrandLogo />
      </StyledBrandLogo>
    </Flex>
  )
}
