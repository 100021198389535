import { Photos } from '../types'

export const removePhotos = (photos: Photos, idsToRemove: string[]): Photos => {
  return photos.photoIds.reduce<Photos>(
    (acc, id) => {
      if (!idsToRemove.includes(id)) {
        acc.photoIds.push(id)
        acc.photoById[id] = photos.photoById[id]
      }

      return acc
    },
    { photoIds: [], photoById: {} },
  )
}
