import { ErrorScreen } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { useSession } from '../../store'
import { useEditorLocaleText } from '../../text-localisation'

export const ErrorUnexpected: FC = () => {
  const t = useEditorLocaleText()
  const session = useSession()

  return (
    <ErrorScreen
      title={t('loading_error_title')}
      description={t('loading_error_description')}
      session={session}
    />
  )
}
