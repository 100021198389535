import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { animated, SpringRef, useSpring } from 'react-spring'
import { Card3D } from '../Card3D'
import {
  CARD_ASPECT_RATIO,
  CARD_FINAL_POSITION_Y_PERCENT,
  CARD_INITIAL_POSITION_Y_PERCENT,
  CARD_INITIAL_SCALE,
  CARD_WIDTH_PERCENT,
  TIMING_CARD,
} from './constants'
import { Layout, Size } from './types'

type LayerCardProps = {
  layout: Layout
  renderScene: (input: { sceneIndex: number }) => ReactNode
  springRef: SpringRef
  sceneSize: Size
}

export const LayerCard: FC<LayerCardProps> = ({
  layout,
  renderScene,
  springRef,
  sceneSize,
}) => {
  const [cardOpen, setCardOpen] = useState(false)
  const translateYFrom =
    sceneSize.height * CARD_INITIAL_POSITION_Y_PERCENT[layout]
  const translateYTo = sceneSize.height * CARD_FINAL_POSITION_Y_PERCENT[layout]
  const duration = TIMING_CARD.end - TIMING_CARD.start

  const isMountedRef = useRef(false)

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  })

  const spring = useSpring({
    ref: springRef,
    from: {
      transform: `translateY(${translateYFrom}px) scale(${CARD_INITIAL_SCALE[layout]})`,
      transformOrigin: '50% 20%',
    },
    to: {
      transform: `translateY(${translateYTo}px) scale(1)`,
      transformOrigin: '50% 20%',
    },
    config: { duration },
    onStart: /* istanbul ignore next: not testable */ () => {
      if (isMountedRef.current) {
        setCardOpen(true)
      }
    },
  })

  const width = sceneSize.width * CARD_WIDTH_PERCENT[layout]
  const height = width / CARD_ASPECT_RATIO[layout]
  const x = (sceneSize.width - width) / 2
  const y = sceneSize.height * 0.07

  return (
    <animated.div style={spring}>
      <Card3D
        activeSceneIndex={
          /* istanbul ignore next: not testable */ cardOpen ? 1 : 0
        }
        onChangeActiveSceneIndex={
          /* istanbul ignore next: not interactive */ () => {}
        }
        orientation={layout === 'CARD_LANDSCAPE' ? 'LANDSCAPE' : 'PORTRAIT'}
        position={{ x, y }}
        size={{ width, height }}
        transitionDuration={duration}
        renderScene={renderScene}
      />
    </animated.div>
  )
}
