import { Alert, Text } from '@moonpig/launchpad-components'
import { Checkbox, FormControlLabel } from '@moonpig/launchpad-forms'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { useStore } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'

export type TermsState = 'checked' | 'not-checked' | 'not-checked-error'

type TermsCheckboxProps = {
  state: TermsState
  onChange: (state: TermsState) => void
}

const StyledContainer = styled.div`
  border-top: solid 1px;
  border-bottom: solid 1px;

  ${s({
    borderColor: 'colorBorder04',
    py: 6,
  })}
`

const StyledFormControlLabel = styled(FormControlLabel)`
  height: auto;
`

export const TermsCheckbox: FC<TermsCheckboxProps> = ({ state, onChange }) => {
  const t = useEditorLocaleText()

  const termsAndConditionsUrl = useStore(
    ({ config }) => config.termsAndConditionsUrl,
  )

  return (
    <StyledContainer>
      <StyledFormControlLabel
        label={
          <Text
            typography="bodySmall"
            color="colorBlack40"
            dangerouslySetInnerHTML={{
              __html: t('video_terms_and_conditions', termsAndConditionsUrl),
            }}
          />
        }
      >
        <Checkbox
          value="accept-terms-and-conditions"
          checked={state === 'checked'}
          onChange={event =>
            onChange(event.currentTarget.checked ? 'checked' : 'not-checked')
          }
        />
      </StyledFormControlLabel>
      {state === 'not-checked-error' && (
        <Alert mt={4} variant="error">
          <Text typography="bodyBold">
            {t('video_terms_and_conditions_error')}
          </Text>
        </Alert>
      )}
    </StyledContainer>
  )
}
