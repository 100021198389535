import {
  Flex,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { Modal } from '@moonpig/web-personalise-components'
import { DesignElementRef } from '@moonpig/web-personalise-editor-types'
import React, { useCallback } from 'react'
import { useAction } from '../../../../../../store'
import { useEditorLocaleText } from '../../../../../../text-localisation'

export const ConfirmDeleteModal = ({
  elementRef,
}: {
  elementRef: DesignElementRef
}) => {
  const t = useEditorLocaleText()
  const dismissModal = useAction('dismissModal')
  const deleteElement = useAction('deleteElement')
  const handleDelete = useCallback(() => {
    deleteElement(elementRef)
    dismissModal()
  }, [deleteElement, dismissModal, elementRef])

  return (
    <Modal
      heading={t('are_you_sure_about_deletion')}
      closeLabel={t('modal_close_button')}
      onClose={dismissModal}
    >
      <Flex flexDirection="column" width="100%">
        <Text paddingBottom={'15px'} textAlign="center">
          {t('deleted_items_will_be_gone')}
        </Text>
        <PrimaryButton mb={6} onClick={handleDelete}>
          {t('delete_element_label')}
        </PrimaryButton>
        <SecondaryButton onClick={dismissModal}>
          {t('keep_element')}
        </SecondaryButton>
      </Flex>
    </Modal>
  )
}
