import { AspectRatio, Box } from '@moonpig/launchpad-components'
import { MediaPlayer } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { useEditorLocaleText } from '../../../../../../../text-localisation'

const VIDEO_PREVIEW_ASPECT_RATIO = 1.35

type VideoPreviewProps = {
  selectedVideoUrl: string
  onDurationChange?: (event: { durationInSeconds: number }) => void
  onError?: () => void
}

export const VideoPreview: FC<VideoPreviewProps> = ({
  selectedVideoUrl,
  onDurationChange,
  onError,
}) => {
  const t = useEditorLocaleText()

  return (
    <Box mt={-6} mx={-6}>
      <AspectRatio ratio={VIDEO_PREVIEW_ASPECT_RATIO}>
        <MediaPlayer
          id="mp-ed-video-preview"
          ariaLabel={t('video_selected_label')}
          mediaUrl={selectedVideoUrl}
          mediaKind="Video"
          onDurationChange={onDurationChange}
          onError={onError}
        />
      </AspectRatio>
    </Box>
  )
}
