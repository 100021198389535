import {
  Design,
  DesignElementOverlayImage,
  DesignElementRef,
  DesignElements,
} from '@moonpig/web-personalise-editor-types'

export const moveElementToBack = (
  elementRef: DesignElementRef | DesignElementRef<DesignElementOverlayImage>,
  design: Design,
): DesignElements => {
  const scene = design.sceneById[elementRef.sceneId]
  const elementIndex = scene.elementIds.indexOf(elementRef.id)

  const updatedElements = [...scene.elementIds]
  updatedElements.splice(elementIndex, 1)
  updatedElements.unshift(elementRef.id)

  return {
    elementById: scene.elementById,
    elementIds: updatedElements,
  }
}
