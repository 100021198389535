import { Box, Flex } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import React, {
  FC,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { PlayButton } from './PlayButton'
import { MediaError } from './MediaError'
import { MediaKind, VideoPlayerState } from './types'

const StyledVideoContainer = styled.div`
  background: black;
  width: 100%;
  height: 100%;
`

const StyledVideo = styled.video.attrs(({ isAudio }: { isAudio: boolean }) => ({
  className: isAudio && 'is-audio',
}))<{ isAudio: boolean }>`
  width: 100%;
  height: 100%;
  &.is-audio {
    object-fit: cover;
  }
`

type MediaPlayerProps = {
  id: string
  mediaUrl: string
  mediaKind: MediaKind
  ariaLabel?: string
  ariaLabelledBy?: string
  poster?: string
  onReady?: () => void
  onPlaying?: (event: { durationInSeconds: number }) => void
  onEnded?: (event: { durationInSeconds: number }) => void
  onRetry?: () => void
  onError?: () => void
  onDurationChange?: (event: { durationInSeconds: number }) => void
  onPause?: (event: {
    currentTimeInSeconds: number
    durationInSeconds: number
  }) => void
  onTimeUpdate?: (event: {
    currentTimeInSeconds: number
    durationInSeconds: number
  }) => void
}

export const MediaPlayer: FC<MediaPlayerProps> = ({
  id,
  ariaLabel,
  ariaLabelledBy,
  mediaUrl,
  poster,
  mediaKind,
  onReady,
  onPlaying,
  onPause,
  onEnded,
  onRetry,
  onError,
  onDurationChange,
  onTimeUpdate,
}) => {
  const [state, setState] = useState<VideoPlayerState>('stopped')
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    setState('stopped')
  }, [mediaUrl])

  const handleClick = useCallback(() => {
    videoRef.current?.play()
  }, [])

  const handleDurationChange = useCallback(
    (event: SyntheticEvent<HTMLVideoElement, Event>) => {
      onDurationChange?.({ durationInSeconds: event.currentTarget.duration })
    },
    [onDurationChange],
  )

  const handleTimeUpdate = useCallback(
    (event: SyntheticEvent<HTMLVideoElement, Event>) => {
      onTimeUpdate?.({
        currentTimeInSeconds: event.currentTarget.currentTime,
        durationInSeconds: event.currentTarget.duration,
      })
    },
    [onTimeUpdate],
  )

  const handlePaused = useCallback(
    (event: SyntheticEvent<HTMLVideoElement, Event>) => {
      onPause?.({
        currentTimeInSeconds: event.currentTarget.currentTime,
        durationInSeconds: event.currentTarget.duration,
      })
    },
    [onPause],
  )

  const handleLoadedMetadata = useCallback(() => {
    onReady?.()
  }, [onReady])

  const handlePlaying = useCallback(
    (event: SyntheticEvent<HTMLVideoElement, Event>) => {
      setState('playing')
      onPlaying?.({ durationInSeconds: event.currentTarget.duration })
    },
    [onPlaying],
  )

  const handleEnded = useCallback(
    (event: SyntheticEvent<HTMLVideoElement, Event>) => {
      setState('ended')
      onEnded?.({ durationInSeconds: event.currentTarget.duration })
    },
    [onEnded],
  )

  const handleError = useCallback(() => {
    setState('error')
    onError?.()
  }, [onError])

  if (state === 'error') {
    return (
      <StyledVideoContainer>
        <Flex
          position="relative"
          width="100%"
          maxWidth="640px"
          marginX="auto"
          height="100%"
          px={6}
          alignContent={'center'}
          justifyContent={'center'}
          flexDirection={'column'}
        >
          <MediaError onRetry={onRetry} mediaKind={mediaKind} />
        </Flex>
      </StyledVideoContainer>
    )
  }

  return (
    <Box position="relative" width="100%" height="100%">
      <StyledVideoContainer>
        <StyledVideo
          id={id}
          isAudio={mediaKind === 'Audio'}
          ref={videoRef}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
          src={mediaUrl}
          controls={state === 'playing'}
          preload="metadata"
          onDurationChange={handleDurationChange}
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          onPlaying={handlePlaying}
          onPause={handlePaused}
          onError={handleError}
          onEnded={handleEnded}
          poster={poster}
        />
        <PlayButton
          ariaControls={id}
          state={state}
          onClick={handleClick}
          mediaKind={mediaKind}
        />
      </StyledVideoContainer>
    </Box>
  )
}
