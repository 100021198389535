import { win1252Filter } from 'win1252-filter'

export const inputIsValid = (text: string) => {
  const filtered = win1252Filter(text)
  return filtered === text
}

export const sanitizeInput = (text: string) => {
  return win1252Filter(text)
}
