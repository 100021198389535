import { styled, useTheme } from '@moonpig/launchpad-utils'
import React, {
  CSSProperties,
  FC,
  FocusEvent,
  MouseEvent,
  PointerEvent,
  RefObject,
  WheelEvent,
  useCallback,
} from 'react'
import { useThresholdClick } from '../../utils'
import { isFocusVisible } from '../../utils/isFocusVisible'
import { useFocusRef } from '../FocusManager'
import { Indicator } from './components/Indicator'
import { BORDER_WIDTH } from './constants'
import {
  InteractiveElementBorder,
  InteractiveElementIndicator,
  InteractiveElementInteraction,
  InteractiveElementState,
} from './types'

const StyledLink = styled.a<{
  borderColor: string
  opacity: number
  cursor: CSSProperties['cursor']
}>`
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  outline: none;
  pointer-events: all;
  cursor: ${({ cursor }) => cursor};
  opacity: ${({ opacity }) => opacity};

  &:hover,
  &:focus-visible {
    text-decoration: none;
    opacity: 1;

    > rect.foreground {
      stroke: transparent;
    }

    > rect.background {
      stroke: ${({ borderColor }) => borderColor};
      stroke-dasharray: none;
      stroke-opacity: 1;
    }
  }
`

type InteractiveElementProps = {
  id?: string
  label?: string
  describedById?: string
  scale: number
  width: number
  height: number
  ref?: RefObject<HTMLAnchorElement>
  focusId?: string
  state?: InteractiveElementState
  border?: InteractiveElementBorder
  invalid?: boolean
  indicator?: InteractiveElementIndicator
  interaction?: InteractiveElementInteraction
  onSelect?: () => void
  onFocus?: () => void
  moveableEvents?: {
    onPointerDown?: (event: PointerEvent) => void
    onPointerMove?: (event: PointerEvent) => void
    onPointerUp?: (event: PointerEvent) => void
    onPointerCancel?: (event: PointerEvent) => void
    onPointerLeave?: (event: PointerEvent) => void
    onWheel?: (event: WheelEvent) => void
  }
}

export const InteractiveElement: FC<InteractiveElementProps> = ({
  id,
  label,
  describedById,
  scale,
  width,
  height,
  border = 'DEFAULT',
  focusId = '',
  state = 'DEFAULT',
  invalid,
  indicator = 'NONE',
  interaction = 'DEFAULT',
  onSelect = () => {},
  onFocus = () => {},
  moveableEvents,
  children,
}) => {
  const { palette } = useTheme()
  const { colorFeedbackInformation, colorFeedbackWarning } = palette
  const borderWidth = scale * BORDER_WIDTH
  const borderColor = invalid ? colorFeedbackWarning : colorFeedbackInformation
  const ref = useFocusRef<HTMLAnchorElement>(focusId)

  const showBorder =
    (state === 'SELECTED' && border !== 'NONE') || border === 'ALWAYS'

  const strokeDasharray =
    border === 'ALWAYS' && state !== 'SELECTED'
      ? `${borderWidth * 2} ${borderWidth * 2}`
      : undefined

  const opacity = state === 'DIMMED' ? 0.5 : 1

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      onSelect()
    },
    [onSelect],
  )

  const clickEvents = useThresholdClick(handleClick)

  const handleFocus = useCallback(
    (event: FocusEvent) => {
      if (isFocusVisible(event.currentTarget)) {
        onFocus()
      }
    },
    [onFocus],
  )

  return (
    <StyledLink
      ref={ref}
      id={id}
      tabIndex={interaction === 'INERT' ? -1 : 0}
      href="#"
      role="button"
      aria-hidden={interaction === 'INERT'}
      aria-label={label}
      aria-describedby={describedById}
      aria-expanded={state === 'SELECTED'}
      onFocus={handleFocus}
      borderColor={borderColor}
      opacity={opacity}
      style={{ pointerEvents: interaction === 'NONE' ? 'none' : 'all' }}
      cursor={moveableEvents ? 'move' : 'pointer'}
      onContextMenu={event => {
        event.preventDefault()
      }}
      {...moveableEvents}
      {...clickEvents}
    >
      <rect
        className="background"
        x={0}
        y={0}
        width={width}
        height={height}
        fill="transparent"
        stroke={showBorder && strokeDasharray ? 'white' : 'transparent'}
        strokeWidth={borderWidth}
      />
      <rect
        className="foreground"
        x={0}
        y={0}
        width={width}
        height={height}
        fill="transparent"
        stroke={showBorder ? borderColor : 'transparent'}
        strokeDasharray={strokeDasharray}
        strokeWidth={borderWidth}
      />
      <Indicator
        indicator={indicator}
        scale={scale}
        width={width}
        height={height}
        invalid={invalid}
      />
      {children}
    </StyledLink>
  )
}
