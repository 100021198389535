import { Flex, SecondaryButton } from '@moonpig/launchpad-components'
import React, { FC, useCallback } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { useAction } from '../../../../../../../../store'
import { HandwritingInput } from './HandwritingInput'

const StyledStepList = styled.ol`
  ${s({ pl: 6, mb: 6 })}
  list-style: decimal outside;
`
const HTMLListItem: FC<{ html: string }> = ({ html }) => {
  /* eslint-disable react/no-danger */
  return <li dangerouslySetInnerHTML={{ __html: html }} />
}

export const HandwritingOnboarding: FC = () => {
  const t = useEditorLocaleText()
  const setUI = useAction('setUI')

  const handleMaybeLater = useCallback(() => {
    setUI({ type: 'default', selectedMenuItem: null })
  }, [setUI])

  return (
    <Flex flexDirection="column" flex={1}>
      <StyledStepList>
        <HTMLListItem html={t('toolbar_menu_handwriting_step_1')} />
        <HTMLListItem html={t('toolbar_menu_handwriting_step_2')} />
        <HTMLListItem html={t('toolbar_menu_handwriting_step_3')} />
      </StyledStepList>
      <HandwritingInput />
      <SecondaryButton width="100%" mt={6} onClick={handleMaybeLater}>
        {t('button_maybe_later')}
      </SecondaryButton>
    </Flex>
  )
}
