import { OnboardingEntry } from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import pinkBackground from './assets/pink_background.jpg'
import blueBackground from './assets/blue_background.jpg'

type OnboardingContent = {
  title: string
  description: string
  image: {
    url: string
    alt: string
  }
}

export const buildOnboardingContent = (
  entries: OnboardingEntry[],
  t: ReturnType<typeof useEditorLocaleText>,
): OnboardingContent[] => {
  const allFeatures: Record<OnboardingEntry['type'], OnboardingContent> = {
    stickers: {
      title: t('onboarding_content_stickers_title'),
      description: t('onboarding_content_stickers_description'),
      image: {
        url: blueBackground,
        alt: '',
      },
    },
    photos: {
      title: t('onboarding_content_photos_title'),
      description: t('onboarding_content_photos_description'),
      image: {
        url: pinkBackground,
        alt: '',
      },
    },
    text: {
      title: t('onboarding_content_text_title'),
      description: t('onboarding_content_text_description'),
      image: {
        url: blueBackground,
        alt: '',
      },
    },
    emoji: {
      title: t('onboarding_content_emoji_title'),
      description: t('onboarding_content_emoji_description'),
      image: {
        url: pinkBackground,
        alt: '',
      },
    },
    audio: {
      title: t('onboarding_content_audio_title'),
      description: t('onboarding_content_audio_description'),
      image: {
        url: blueBackground,
        alt: '',
      },
    },
    video: {
      title: t('onboarding_content_video_title'),
      description: t('onboarding_content_video_description'),
      image: {
        url: pinkBackground,
        alt: '',
      },
    },
    layouts: {
      title: t('onboarding_content_layouts_title'),
      description: t('onboarding_content_layouts_description'),
      image: {
        url: blueBackground,
        alt: '',
      },
    },
    'smart-text': {
      title: t('onboarding_content_smart_text_title'),
      description: t('onboarding_content_smart_text_description'),
      image: {
        url: pinkBackground,
        alt: '',
      },
    },
  }

  return entries.map(entry => allFeatures[entry.type])
}
