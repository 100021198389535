import { v4 as uuid } from 'uuid'
import { CollageGrid, layoutGrid } from '@moonpig/web-personalise-collage'
import {
  Design,
  DesignAvailableTextStyles,
  DesignDefaultTextStyle,
  DesignElement,
  DesignElementOverlayText,
  DesignScene,
} from '@moonpig/web-personalise-editor-types'
import { assert } from '../../utils/assert'

type ApplyLayout = (
  design: Design,
  scene: DesignScene,
  grid: CollageGrid,
) => DesignScene

const OVERLAY_TYPES_TO_REMOVE = new Set<DesignElement['type']>(['overlay-text'])

type ApplyDefaultLayouts = (design: Design) => Design

const createOverlayTextElement = ({
  defaultTextStyle,
  availableTextStyles,
  x,
  y,
  width,
  height,
  sceneId,
  content,
}: {
  defaultTextStyle: DesignDefaultTextStyle
  availableTextStyles: DesignAvailableTextStyles
  x: number
  y: number
  width: number
  height: number
  sceneId: string
  content: string
}): DesignElementOverlayText => {
  const { availableColorById, availableFontFaceById } = availableTextStyles

  return {
    id: uuid(),
    type: 'overlay-text',
    width,
    height,
    fixed: true,
    x,
    y,
    sceneId,
    rotation: 0,
    horizontalAlignment: 'CENTER',
    verticalAlignment: 'MIDDLE',
    fontFace: availableFontFaceById[defaultTextStyle.fontFaceId],
    fontSize: defaultTextStyle.fontSize,
    lineSpacing: null,
    lineSpacingRelative: 0,
    color: availableColorById[defaultTextStyle.colorId],
    shadow: null,
    customisations: {
      text: content,
      fontFaceId: defaultTextStyle.fontFaceId,
      fontSize: defaultTextStyle.fontSize,
      colorId: defaultTextStyle.colorId,
      horizontalAlignment: 'CENTER',
      verticalAlignment: 'MIDDLE',
      author: null,
    },
    fragmentsState: {
      fragments: [],
      resizingWidth: false,
      version: 0,
    },
  }
}

export const applyLayout: ApplyLayout = (design, scene, grid) => {
  const { availableTextStyles, defaultTextStyle } = design

  assert(defaultTextStyle !== undefined)

  const newElementByIdEntries = Object.entries(scene.elementById).filter(
    ([, element]) => !OVERLAY_TYPES_TO_REMOVE.has(element.type),
  )

  const { elements: cells } = layoutGrid({
    grid,
    size: [scene.width, scene.height],
  })

  const updatedGrid = grid

  cells.forEach(cell => {
    const newOverlayTextElement = createOverlayTextElement({
      x: cell.frame.position[0],
      y: cell.frame.position[1],
      width: cell.frame.size[0],
      height: cell.frame.size[1],
      availableTextStyles,
      content: '',
      defaultTextStyle,
      sceneId: scene.id,
    })

    newElementByIdEntries.push([
      newOverlayTextElement.id,
      newOverlayTextElement,
    ])
  })

  const elementById = Object.fromEntries(newElementByIdEntries)
  const elementIds = Object.keys(elementById)

  return {
    ...scene,
    elementById,
    elementIds,
    layout: {
      grid: updatedGrid,
    },
  }
}

export const applyDefaultLayouts: ApplyDefaultLayouts = design => {
  if (design.modified) {
    return design
  }

  return {
    ...design,
    sceneById: Object.fromEntries(
      Object.entries(design.sceneById).map(([id, scene]) => {
        if (scene.layoutOriginal) {
          return [id, applyLayout(design, scene, scene.layoutOriginal.grid)]
        }

        return [id, scene]
      }),
    ),
  }
}
