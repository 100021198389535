import {
  IconSystemAlignCenter,
  IconSystemAlignJustify,
  IconSystemAlignLeft,
  IconSystemAlignRight,
} from '@moonpig/launchpad-assets'
import { IconKeyboard } from '@moonpig/web-personalise-components'
import { DesignHorizontalAlignment } from '@moonpig/web-personalise-editor-types'
import React, { FC, ReactNode, useCallback, useMemo } from 'react'
import { createElementDomId } from '../../../../../../../utils/createElementId'
import {
  UITextStyled,
  UITextStyledMenuItem,
  useAction,
  useView,
} from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { useElementRef } from '../../../../../selectors'
import { ToolbarItem, ToolbarMenu, ToolbarOrientation } from '../Toolbar'
import { ToolbarCommon } from '../ToolbarCommon'
import { ElementFontFacePicker } from './ElementFontFacePicker'
import { ElementFontSizePicker } from './ElementFontSizePicker'
import { ElementTextAlignmentPicker } from './ElementTextAlignmentPicker'
import { ElementTextColorPicker } from './ElementTextColorPicker'

type ToolbarTextStyledProps = {
  onDone: () => void
  orientation: ToolbarOrientation
  ui: UITextStyled
}

const iconByHorizontalAlignment: {
  [align in DesignHorizontalAlignment]: ReactNode
} = {
  LEFT: <IconSystemAlignLeft width="28" height="28" />,
  CENTER: <IconSystemAlignCenter width="28" height="28" />,
  RIGHT: <IconSystemAlignRight width="28" height="28" />,
  JUSTIFY: <IconSystemAlignJustify width="28" height="28" />,
}

export const ToolbarTextStyled: FC<ToolbarTextStyledProps> = ({
  onDone,
  orientation,
  ui,
}) => {
  const { elementRef, selectedMenuItem } = ui

  const element = useElementRef(elementRef)
  const setUI = useAction('setUI')
  const t = useEditorLocaleText()

  const controlId = createElementDomId(element.id)

  const currentFont =
    element.availableFontFaceById[element.customisations.fontFaceId]
  const currentSize = element.customisations.fontSize
  const currentColor =
    element.availableColorById[element.customisations.colorId]
  const currentHorizontalAlignment = element.customisations.horizontalAlignment
  const currentVerticalAlignment = element.customisations.verticalAlignment

  const fontIconFontFamily = useView('main', ({ fontById }) => {
    const state = fontById[currentFont.id]
    return state.type === 'loaded'
      ? state.fontFamily
      : /* istanbul ignore next: guaranteed to be loaded */ undefined
  })

  const items = useMemo<ToolbarItem<UITextStyledMenuItem>[]>(() => {
    return [
      {
        type: 'button',
        id: 'keyboard',
        label: t('toolbar_keyboard_label'),
        ariaLabel: t('toolbar_open_keyboard'),
        icon: <IconKeyboard />,
        disabled: false,
        focusVisible: orientation === 'vertical',
      },
      {
        type: 'menu',
        id: 'size',
        label: t('toolbar_text_size_label'),
        ariaLabel: t('toolbar_current_text_size', currentSize),
        icon: (
          <div aria-hidden style={{ fontWeight: 'bold', fontSize: 16 }}>
            {currentSize}
          </div>
        ),
        disabled: false,
      },
      {
        type: 'menu',
        id: 'font',
        label: t('toolbar_font_label'),
        ariaLabel: t('toolbar_current_font', currentFont.name),
        icon: (
          <div
            aria-hidden
            style={{
              fontWeight: 'bold',
              fontSize: 16,
              fontFamily: fontIconFontFamily,
            }}
          >
            Aa
          </div>
        ),
        disabled: false,
      },

      {
        type: 'menu',
        id: 'color',
        label: t('toolbar_text_color_label'),
        ariaLabel: t('toolbar_current_text_color', currentColor.name),
        icon: (
          <div
            aria-hidden
            style={{
              borderRadius: 16,
              backgroundColor: currentColor.value,
              width: 28,
              height: 28,
            }}
          />
        ),
        disabled: false,
      },
      {
        type: 'menu',
        id: 'alignment',
        label: t('toolbar_text_alignment_label'),
        ariaLabel: t(
          'toolbar_current_text_alignment',
          t('vertical_alignment', currentVerticalAlignment),
          t('horizontal_alignment', currentHorizontalAlignment),
        ),
        icon: iconByHorizontalAlignment[currentHorizontalAlignment],
        disabled: false,
      },
    ]
  }, [
    t,
    orientation,
    currentFont.name,
    fontIconFontFamily,
    currentSize,
    currentColor.name,
    currentColor.value,
    currentVerticalAlignment,
    currentHorizontalAlignment,
  ])

  const menu = useMemo<ToolbarMenu | undefined>(() => {
    switch (selectedMenuItem) {
      case 'font':
        return {
          title: t('toolbar_font_menu_heading'),
          content: <ElementFontFacePicker elementRef={elementRef} />,
          closeLabel: t('toolbar_close_font_menu'),
        }
      case 'size':
        return {
          title: t('toolbar_text_size_menu_heading'),
          content: <ElementFontSizePicker elementRef={elementRef} />,
          closeLabel: t('toolbar_close_text_size_menu'),
        }
      case 'color':
        return {
          title: t('toolbar_text_color_menu_heading'),
          content: <ElementTextColorPicker elementRef={elementRef} />,
          closeLabel: t('toolbar_close_text_color_menu'),
        }
      case 'alignment':
        return {
          title: t('toolbar_text_alignment_menu_heading'),
          content: <ElementTextAlignmentPicker elementRef={elementRef} />,
          closeLabel: t('toolbar_close_text_alignment_menu'),
        }
    }
  }, [selectedMenuItem, t, elementRef])

  const handleSelectItem = useCallback(
    (itemId: UITextStyledMenuItem | null) => {
      setUI({ ...ui, selectedMenuItem: itemId })
    },
    [setUI, ui],
  )

  return (
    <ToolbarCommon
      controlId={controlId}
      label={t('toolbar_text_toolbar_title')}
      orientation={orientation}
      items={items}
      selectedItemId={selectedMenuItem}
      onSelectItem={handleSelectItem}
      menu={menu}
      done={{
        label: t('toolbar_done_button'),
        ariaLabel: t('toolbar_text_toolbar_close_label'),
        onDone,
      }}
    />
  )
}
