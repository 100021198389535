import { logger } from '@moonpig/web-core-monitoring'
import { ErrorScreen } from '@moonpig/web-personalise-components'
import React, { Component, FC, ReactNode, useEffect } from 'react'
import { useSession } from '../../store'
import { useEditorLocaleText } from '../../text-localisation'

type Props = {
  children: ReactNode
}

type State = {
  error: Error | null
}

const ErrorBoundaryScreen: FC<{ error: Error }> = ({ error }) => {
  const t = useEditorLocaleText()
  const session = useSession()
  useEffect(() => {
    logger.fixImmediately('Unexpected editor error', {}, error)
  }, [error])

  return (
    <ErrorScreen
      title={t('unexpected_error_title')}
      description={error.message}
      session={session}
    />
  )
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error): State {
    return { error }
  }

  componentDidCatch(error: Error) {
    this.setState({ error })
  }

  render(): React.ReactNode {
    const { children } = this.props
    const { error } = this.state

    if (error) {
      return <ErrorBoundaryScreen error={error} />
    }

    return <>{children}</>
  }
}
