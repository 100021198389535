import { Vec2, vec2Sub } from '@moonpig/common-math'
import { Frame } from '@moonpig/web-personalise-collage'

export const getClipBounds = ({
  element,
  sceneWidth,
  sceneHeight,
}: {
  element: { x: number; y: number; width: number; height: number }
  sceneWidth: number
  sceneHeight: number
}): Frame => {
  const topLeft: Vec2 = [Math.max(0, -element.x), Math.max(0, -element.y)]

  const bottomRight: Vec2 = [
    Math.min(element.width, sceneWidth - element.x),
    Math.min(element.height, sceneHeight - element.y),
  ]

  return {
    position: topLeft,
    size: vec2Sub(bottomRight, topLeft),
  }
}
