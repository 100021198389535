import { byId } from '../utils/byId'
import { getLayoutPad } from './pad'
import { drawCrease, drawShadow } from './draw'
import { Layout, LayoutImage, LayoutScene, LayoutSceneInput } from './types'
import { calculateBounds, calculateDimensions } from './utils'

type Shape = 'RECTANGULAR' | 'SQUARE'

const sceneSizeByShape: {
  [shape in Shape]: { sceneWidth: number; sceneHeight: number }
} = {
  RECTANGULAR: {
    sceneWidth: 133,
    sceneHeight: 187,
  },
  SQUARE: {
    sceneWidth: 133,
    sceneHeight: 133,
  },
}

export const buildLayoutCardPortrait = (
  scenes: LayoutSceneInput[],
  shape: Shape,
): Layout => {
  const pad = getLayoutPad(scenes)
  const { sceneWidth, sceneHeight } = sceneSizeByShape[shape]

  const frontCover: LayoutScene = {
    id: scenes[0].id,
    ...calculateDimensions(0, 0, sceneWidth, sceneHeight),
    connected: [],
  }

  const insideLeft: LayoutScene = {
    id: scenes[1].id,
    ...calculateDimensions(sceneWidth + pad, 0, sceneWidth, sceneHeight),
    connected: [],
  }

  const insideRight: LayoutScene = {
    id: scenes[2].id,
    ...calculateDimensions(
      sceneWidth + pad + sceneWidth,
      0,
      sceneWidth,
      sceneHeight,
    ),
    connected: [],
  }

  const backCover: LayoutScene = {
    id: scenes[3].id,
    ...calculateDimensions(
      sceneWidth + pad + sceneWidth + sceneWidth + pad,
      0,
      sceneWidth,
      sceneHeight,
    ),
    connected: [],
  }

  frontCover.connected.push(insideLeft)
  insideLeft.connected.push(insideRight, frontCover)
  insideRight.connected.push(backCover, insideLeft)
  backCover.connected.push(insideRight)

  const layoutScenes: LayoutScene[] = [
    frontCover,
    insideLeft,
    insideRight,
    backCover,
  ]

  const imageShadowFrontBack = drawShadow(sceneWidth, sceneHeight)
  const imageShadowInside = drawShadow(sceneWidth * 2, sceneHeight)
  const imageCreaseInside = drawCrease(sceneHeight, 'left')

  const imageFrontCover: LayoutImage = {
    id: 'front-cover',
    url: imageShadowFrontBack.url,
    x: frontCover.x + imageShadowFrontBack.x,
    y: frontCover.y + imageShadowFrontBack.y,
    width: imageShadowFrontBack.width,
    height: imageShadowFrontBack.height,
  }

  const imageInside: LayoutImage = {
    id: 'inside',
    url: imageShadowInside.url,
    x: insideLeft.x + imageShadowInside.x,
    y: insideLeft.y + imageShadowInside.y,
    width: imageShadowInside.width,
    height: imageShadowInside.height,
  }

  const imageBackCover: LayoutImage = {
    id: 'back-cover',
    url: imageShadowFrontBack.url,
    x: backCover.x + imageShadowFrontBack.x,
    y: backCover.y + imageShadowFrontBack.y,
    width: imageShadowFrontBack.width,
    height: imageShadowFrontBack.height,
  }

  const imageInsideCrease: LayoutImage = {
    id: 'inside-crease',
    url: imageCreaseInside.url,
    x: insideRight.x + imageCreaseInside.x,
    y: insideRight.y + imageCreaseInside.y,
    width: imageCreaseInside.width,
    height: imageCreaseInside.height,
  }

  return {
    bounds: calculateBounds(layoutScenes),
    sceneById: byId(layoutScenes),
    images: {
      background: [imageFrontCover, imageInside, imageBackCover],
      foreground: [imageInsideCrease],
    },
  }
}
