import { Design, DesignElementRef } from '@moonpig/web-personalise-editor-types'
import { groupElementsByLayer } from '../../../../../../../utils/elementLayers'

type LayerControlsState = {
  forwardButtonDisabled: boolean
  backwardButtonDisabled: boolean
}

export const getLayerControlsState = (
  elementRef: DesignElementRef | null,
  design: Design,
): LayerControlsState => {
  if (!elementRef) {
    return {
      forwardButtonDisabled: true,
      backwardButtonDisabled: true,
    }
  }

  const scene = design.sceneById[elementRef.sceneId]
  const layers = groupElementsByLayer(scene)
  const overlayMainIndex = layers['overlay-main'].indexOf(elementRef.id)
  const lastOverlayMainIndex = layers['overlay-main'].length - 1

  return {
    forwardButtonDisabled: overlayMainIndex === lastOverlayMainIndex,
    backwardButtonDisabled: overlayMainIndex === 0,
  }
}
