import {
  Flex,
  LoadingIndicator,
  PrimaryButton,
} from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import {
  DesignElementImageDynamic,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { getDynamicImageId } from '../../../../../../../../utils/getDynamicImageId'
import { useAction, useStore } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { useElementRef } from '../../../../../../selectors'
import { TextPartsForm } from '../../TextPartsForm'

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: 100%;
  height: 100%;
  color: currentColor;
`

type ImageDynamicFormProps = {
  elementRef: DesignElementRef<DesignElementImageDynamic>
}

export const ImageDynamicForm: FC<ImageDynamicFormProps> = ({ elementRef }) => {
  const t = useEditorLocaleText()
  const element = useElementRef(elementRef)
  const updateElement = useAction('updateElement')
  const notify = useAction('notify')
  const trackEvent = useAction('trackEvent')

  const [customisations, setCustomisations] = useState(
    element.customisations.textParts,
  )

  const handleFiltered = useCallback(
    ({ hasEmojis }: { hasEmojis: boolean }) => {
      trackEvent({
        type: 'ERROR',
        kind: hasEmojis ? 'FILTERED_EMOJI' : 'FILTERED_CHARACTER',
      })

      notify({
        type: 'text-filtered',
        hasEmojis,
      })
    },
    [notify, trackEvent],
  )

  const handleUpdate = useCallback(() => {
    updateElement({
      ...element,
      customisations: {
        previousTextParts: element.customisations.textParts,
        textParts: customisations,
      },
    })
  }, [customisations, element, updateElement])

  const isUnchanged = useMemo(() => {
    return (
      customisations.length === element.customisations.textParts.length &&
      customisations.every(
        (customisation, index) =>
          customisation === element.customisations.textParts[index],
      )
    )
  }, [customisations, element.customisations.textParts])

  const { dynamicImageId } = getDynamicImageId(element)

  const dynamicImage = useStore(
    ({ remoteImageStore }) => remoteImageStore.dynamicImageById[dynamicImageId],
  )

  const updating = dynamicImage === undefined || dynamicImage.type === 'loading'

  return (
    <>
      <TextPartsForm
        textParts={element.textParts}
        customisations={customisations}
        onChange={setCustomisations}
        onFiltered={handleFiltered}
        onSubmit={handleUpdate}
      >
        <Flex justifyContent="flex-end">
          <PrimaryButton
            type="submit"
            disabled={isUnchanged || updating}
            onClick={handleUpdate}
            trailingIcon={updating ? <StyledLoadingIndicator /> : undefined}
          >
            {updating ? (
              <Flex alignItems="center">{t('updating_label')}</Flex>
            ) : (
              t('update_label')
            )}
          </PrimaryButton>
        </Flex>
      </TextPartsForm>
    </>
  )
}
