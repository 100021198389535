import { AspectRatio, Box } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC, ReactNode } from 'react'
import { useElementBounds } from '../../utils'
import { SCENE_ASPECT_RATIO } from './constants'
import { Layers } from './Layers'
import { useLocaleText } from './MediaCardDemo.locale'
import { Layout, MediaKind } from './types'

const StyledSceneContainer = styled.div`
  & * {
    user-select: none;
    pointer-events: none;
  }
`

type SceneProps = {
  layout: Layout
  renderScene: (input: { sceneIndex: number }) => ReactNode
  demoVideoUrl: string
  mediaKind: MediaKind
}

export const Scene: FC<SceneProps> = ({
  layout,
  renderScene,
  demoVideoUrl,
  mediaKind,
}) => {
  const [bounds, ref] = useElementBounds()
  const t = useLocaleText()

  const animationLabel = {
    audio: t('audio_animation_label'),
    video: t('video_animation_label'),
  }

  return (
    <StyledSceneContainer role="img" aria-label={animationLabel[mediaKind]}>
      <AspectRatio ratio={SCENE_ASPECT_RATIO}>
        <Box ref={ref} width="100%" height="100%" overflow="hidden">
          {bounds && (
            <Layers
              layout={layout}
              renderScene={renderScene}
              sceneSize={bounds}
              demoVideoUrl={demoVideoUrl}
            />
          )}
        </Box>
      </AspectRatio>
    </StyledSceneContainer>
  )
}
