import { ActionCreator } from '../../types'
import { storeUpdateView } from '../../updaters/view'

export const createShare: ActionCreator<'share'> =
  ({ set }) =>
  async shareNameInput => {
    set(store => {
      const shareName = shareNameInput || 'Anonymous'

      localStorage.setItem('mp-ed-collab-name', shareName)
      localStorage.setItem('mp-ed-collab-sharing', 'true')

      return storeUpdateView(store, 'main', view => {
        const designId = view.design.id

        if (view.collaborateSession) {
          view.collaborateSession.updateName(shareName)
        }

        return {
          shareName,
          sharing: {
            link: `${window.location.origin}?share=${designId}`,
          },
        }
      })
    })
  }
