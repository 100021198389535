import { Locale, Store } from '@moonpig/web-core-types'
import { FooterConfig, InterestFacetMapping } from '../types'
import { createWebLoyaltyConfig } from '../shared'

const STORE_ID_UK = 'uk'
const STORE_ID_US = 'us'
const STORE_ID_AU = 'au'
const STORE_ID_IE = 'ie'

const LOCALE_EN_GB: Locale = {
  language: 'en-GB',
  countryCode: 'gb',
  currencyCode: 'gbp',
  timeZone: 'Europe/London',
}

const LOCALE_EN_US: Locale = {
  language: 'en-US',
  countryCode: 'us',
  currencyCode: 'usd',
  timeZone: 'America/New_York',
}

const LOCALE_EN_AU: Locale = {
  language: 'en-AU',
  countryCode: 'au',
  currencyCode: 'aud',
  timeZone: 'Australia/Sydney',
}

const LOCALE_EN_IE: Locale = {
  language: 'en-IE',
  countryCode: 'ie',
  currencyCode: 'eur',
  timeZone: 'Europe/Dublin',
}

const GTM_CLIENT = {
  containerId: 'GTM-M8RZDQ9',
  verificationCode: '6ynlpPuN0ok0-Oy8NELN7XktiFH-5iVAaoB7uIAdTUA',
  host: 'www.googletagmanager.com',
}

export const STORES: Store[] = [
  {
    id: STORE_ID_UK,
    legacyId: 'mnpg-uk',
    shortName: 'UK',
    name: 'United Kingdom',
    locale: LOCALE_EN_GB,
    analytics: {
      gtmClient: GTM_CLIENT,
    },
    webLoyalty: createWebLoyaltyConfig(STORE_ID_UK),
  },
  {
    id: STORE_ID_US,
    legacyId: 'mnpg-us',
    shortName: 'US & CA',
    name: 'United States & Canada',
    locale: LOCALE_EN_US,
    analytics: {
      gtmClient: GTM_CLIENT,
    },
    webLoyalty: createWebLoyaltyConfig(STORE_ID_US),
  },
  {
    id: STORE_ID_AU,
    legacyId: 'mnpg-au',
    shortName: 'AU & NZ',
    name: 'Australia & New Zealand',
    locale: LOCALE_EN_AU,
    analytics: {
      gtmClient: GTM_CLIENT,
    },
    webLoyalty: createWebLoyaltyConfig(STORE_ID_AU),
  },
  {
    id: STORE_ID_IE,
    legacyId: 'mnpg-ie',
    shortName: 'Ireland',
    name: 'Ireland',
    locale: LOCALE_EN_IE,
    analytics: {
      gtmClient: GTM_CLIENT,
    },
    webLoyalty: createWebLoyaltyConfig(STORE_ID_IE),
  },
]

const API_ENV = process.env.MNPG_ENVIRONMENT_NAME as string
const API_REGIONS = ['eu-west-1', 'us-east-1', 'ap-southeast-2']

const INTERACTIONS_API_HOST_NAMES = [
  // old bucket
  `mnpg-interactions-api-${API_ENV}-video-card-assets.s3.eu-west-1.amazonaws.com`,
  // new buckets
  ...API_REGIONS.map(
    region =>
      `mnpg-interactions-api-${API_ENV}-${region}-media-card-assets.s3.${region}.amazonaws.com`,
  ),
]

const MEDIA_API_HOST_NAMES = [
  // default region (without amazonaws.com subdomain)
  `mnpg-media-assets-us-east-1-${API_ENV}-uploaded.s3.amazonaws.com`,
  `mnpg-media-assets-us-east-1-${API_ENV}-converted.s3.amazonaws.com`,
  // other regions
  ...API_REGIONS.map(
    region =>
      `mnpg-media-assets-${region}-${API_ENV}-uploaded.s3.${region}.amazonaws.com`,
  ),
  ...API_REGIONS.map(
    region =>
      `mnpg-media-assets-${region}-${API_ENV}-converted.s3.${region}.amazonaws.com`,
  ),
]

const DESIGN_API_HOST_NAMES = [
  // default region (without amazonaws.com subdomain)
  `mnpg-design-api-us-east-1-${API_ENV}-image-upload.s3.amazonaws.com`,
  // other regions
  ...API_REGIONS.map(
    region =>
      `mnpg-design-api-${region}-${API_ENV}-image-upload.s3.${region}.amazonaws.com`,
  ),
]

export const contentSecurityPolicy: { [key: string]: string[] } = {
  'font-src': ['*.moonpig.com', '*.moonpig.net'],
  'connect-src': [
    ...DESIGN_API_HOST_NAMES,
    ...INTERACTIONS_API_HOST_NAMES,
    ...MEDIA_API_HOST_NAMES,
    '*.lunio.ai',
    '*.moonpig.com',
    '*.moonpig.net',
    '*.sail-horizon.com',
    '*.sail-personalize.com',
    '*.sail-track.com',
  ],
  'frame-src': [],
  'style-src': ['*.moonpig.com', '*.moonpig.net'],
  'script-src': [
    '*.moonpig.com',
    '*.moonpig.net',
    '*.sail-horizon.com',
    '*.sail-personalize.com',
    '*.sail-track.com',
  ],
  'img-src': [],
  'media-src': [
    ...INTERACTIONS_API_HOST_NAMES,
    ...MEDIA_API_HOST_NAMES,
    '*.moonpig.net',
    'videos.ctfassets.net',
  ],
}

export const ONE_TRUST_DOMAIN_SCRIPT_ID = 'e46f8143-8f58-4c40-a105-e42e91ce027b'

export const BRAND_LABEL = 'Moonpig'

export const DEPARTMENT_PATHS = {
  gifts: 'gifts',
  cards: 'cards',
  'flowers-and-plants': 'flowers-and-plants',
}

export const FOOTER: FooterConfig = {
  socialItems: {
    uk: [
      {
        icon: 'facebook',
        linkHref: 'https://www.facebook.com/Moonpig/',
        label: 'Facebook',
      },
      {
        icon: 'twitter',
        linkHref: 'https://twitter.com/moonpiguk',
        label: 'Twitter',
      },
      {
        icon: 'instagram',
        linkHref: 'https://www.instagram.com/moonpig/',
        label: 'Instagram',
      },
      {
        icon: 'pinterest',
        linkHref: 'https://www.pinterest.co.uk/moonpiguk/',
        label: 'Pinterest',
      },
      {
        icon: 'youtube',
        linkHref: 'https://www.youtube.com/user/moonpigcards',
        label: 'YouTube',
      },
    ],
    us: [
      {
        icon: 'facebook',
        linkHref: 'https://www.facebook.com/moonpigusa/',
        label: 'Facebook',
      },
    ],
    au: [
      {
        icon: 'facebook',
        linkHref: 'https://www.facebook.com/Moonpig/',
        label: 'Facebook',
      },
      {
        icon: 'twitter',
        linkHref: 'https://twitter.com/moonpiguk',
        label: 'Twitter',
      },
      {
        icon: 'instagram',
        linkHref: 'https://www.instagram.com/moonpig/',
        label: 'Instagram',
      },
      {
        icon: 'pinterest',
        linkHref: 'https://www.pinterest.co.uk/moonpiguk/',
        label: 'Pinterest',
      },
      {
        icon: 'youtube',
        linkHref: 'https://www.youtube.com/user/moonpigcards',
        label: 'YouTube',
      },
    ],
    ie: [
      {
        icon: 'facebook',
        linkHref: 'https://www.facebook.com/moonpigie',
        label: 'Facebook',
      },
      {
        icon: 'instagram',
        linkHref: 'https://www.instagram.com/moonpigie/',
        label: 'Instagram',
      },
    ],
  },
  appItems: [
    {
      id: 'apple',
      icon: 'apple-app-store',
      to: 'https://itunes.apple.com/app/id393730279',
      width: '120px',
      height: '40px',
    },
    {
      id: 'google',
      icon: 'google-app-store',
      to: 'https://play.google.com/store/apps/details?id=com.commonagency.moonpig.uk',
      width: '135px',
      height: '40px',
    },
  ],
  regionItems: [
    {
      region: 'uk',
      linkHref: '/uk/',
      shortLabel: 'UK',
      label: 'United Kingdom',
      icon: 'uk-flag',
    },
    {
      region: 'us',
      linkHref: '/us/',
      shortLabel: 'US',
      label: 'United States of America',
      icon: 'us-flag',
    },
    {
      region: 'au',
      linkHref: '/au/',
      shortLabel: 'AUS',
      label: 'Australia',
      icon: 'aus-flag',
    },
    {
      region: 'ie',
      linkHref: '/ie/',
      shortLabel: 'IE',
      label: 'Ireland',
      icon: 'ie-flag',
    },
  ],
  paymentProviders: ['Visa', 'Mastercard', 'Maestro', 'Apple Pay', 'PayPal'],
}

export const interestFacetsMappings: InterestFacetMapping[] = [
  {
    interestId: 'beer_fan',
    interestName: 'Beer Fan',
    facetKeys: ['beer-cider'],
    includeRecipient: false,
  },
  {
    interestId: 'wine_lover',
    interestName: 'Wine Lover',
    facetKeys: ['champagne-prosecco-wine'],
    includeRecipient: true,
  },
  {
    interestId: 'gin_and_spirits_fan',
    interestName: 'Gin and Spirits Fan',
    facetKeys: ['gin', 'vodka', 'other-spirits', 'whiskey'],
    includeRecipient: true,
  },
  {
    interestId: 'foodie',
    interestName: 'Foodie',
    facetKeys: [
      'snacks-treats-savoury',
      'gift-experiences-food-drink',
      'biscuits',
    ],
    priceKeys: [
      'price-£10orless',
      'price-£10-£20',
      'price-£20-£30',
      'price-£30-£40',
    ],
    includeRecipient: true,
  },
  {
    interestId: 'sweet_tooth',
    interestName: 'Sweet Tooth',
    facetKeys: ['chocolate', 'biscuits', 'sweets'],
    includeRecipient: true,
  },
  {
    interestId: 'wellness_enthusiast',
    interestName: 'Wellness Enthusiast',
    facetKeys: ['spa-breaks', 'beauty-face-body-gift-sets-letterbox'],
    includeRecipient: true,
  },
  {
    interestId: 'game_and_puzzle_solver',
    interestName: 'Game and Puzzle Solver',
    facetKeys: [
      'games-gadgets-novelty-games-board-games-puzzles',
      'gadgets',
      'games-gadgets-novelty-lego-sets',
      'games-gadgets-novelty-novelty',
    ],
    includeRecipient: true,
  },
  {
    interestId: 'jewellery_lover',
    interestName: 'Jewellery Lover',
    facetKeys: ['jewellery'],
    includeRecipient: false,
  },
  {
    interestId: 'home_stylist',
    interestName: 'Home Stylist',
    facetKeys: [
      'towels',
      'posters-prints',
      'photo-frames',
      'home-accessories',
      'glassware',
      'aprons',
    ],
    includeRecipient: true,
  },
  {
    interestId: 'gardener',
    interestName: 'Gardener',
    facetKeys: ['garden', 'grow-kits'],
    includeRecipient: true,
  },
  {
    interestId: 'adventurous',
    interestName: 'Adventurous',
    facetKeys: ['action-and-adventure', 'day-trips'],
    includeRecipient: true,
  },
  {
    interestId: 'holidayer',
    interestName: 'Holidayer',
    facetKeys: ['short-breaks', 'multi-choice'],
    includeRecipient: false,
  },
  {
    interestId: 'experience_fan',
    interestName: 'Experience Fan',
    facetKeys: [
      'product-type-gift-experiences',
      'product-type-digital-gift-experience',
    ],
    includeRecipient: true,
  },
  {
    interestId: 'flower_enthusiast',
    interestName: 'Flower Enthusiast',
    facetKeys: [
      'alstroemeria',
      'bouquets',
      'carnations',
      'dried-flowers',
      'germini',
      'lilies',
      'lisianthus',
      'roses',
      'stocks',
      'sunflowers',
      'tulips',
    ],
    includeRecipient: false,
  },
  {
    interestId: 'plant_lover',
    interestName: 'Plant Lover',
    facetKeys: ['indoor-plants', 'outdoor-plants'],
    includeRecipient: true,
  },
]

export const GIFT_UPSELL_PRODUCT_IDS: {
  originalId: string
  upsellId: string
}[] = [
  { originalId: 'choc5503', upsellId: 'choc537' },
  { originalId: 'choc1267', upsellId: 'choc963' },
  { originalId: 'nwchlb4-ar', upsellId: 'alc301' },
]
