import { ErrorScreen } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { useEditorLocaleText } from '../../text-localisation'
import { useAction, useMainView, useSession } from '../../store'
import { useDesignStateType } from '../Main/selectors'

export const ErrorPreview: FC = () => {
  const t = useEditorLocaleText()
  const canContinue = useMainView('error-preview', view => view.canContinue)
  const designStateType = useDesignStateType()
  const session = useSession()
  const edit = useAction('edit')
  const finish = useAction('finish')

  if (!canContinue) {
    return (
      <ErrorScreen
        title={t('preview_error_alert_title')}
        description={t('preview_error_alert_description')}
        session={session}
      />
    )
  }

  let finishButtonText: string
  switch (designStateType) {
    case 'draft': {
      finishButtonText = t('add_to_basket_button')
      break
    }
    case 'in-basket': {
      finishButtonText = t('finished_editing_button')
      break
    }
  }

  return (
    <ErrorScreen
      title={t('preview_error_notification_title')}
      description={t('preview_error_notification_text')}
      session={session}
      actions={[
        { type: 'secondary', onClick: edit, title: t('edit_button') },
        { type: 'primary', onClick: finish, title: finishButtonText },
      ]}
    />
  )
}
