import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  getting_there_label: {
    'en-GB': 'Getting there…',
    'nl-NL': 'Nog heel even…',
  },
  almost_done_label: {
    'en-GB': 'Almost done…',
    'nl-NL': 'Bijna klaar…',
  },
}

export const useLocaleText = createLocaleTextHook({ dictionary })
