import {
  CollageGrid,
  getAvailableGrids,
  getImageIds,
} from '@moonpig/web-personalise-collage'
import {
  Design,
  DesignElement,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import { deleteElement } from '../../utils/deleteElement'
import { Photos } from '../types'
import { updateDesignElement } from './updateDesignElement'

export const removeMissingSourceImages = ({
  design,
  photos,
  collageGrids,
  partiallyRemove,
}: {
  design: Design
  photos: Photos
  collageGrids: CollageGrid[]
  partiallyRemove?: boolean
}): Design => {
  const updatedElements: DesignElement[] = []
  const deletedElements: DesignElementRef[] = []

  design.sceneIds.forEach(sceneId => {
    const { elementIds, elementById } = design.sceneById[sceneId]

    elementIds.forEach(elementId => {
      const element = elementById[elementId]

      switch (element.type) {
        case 'image-upload': {
          if (
            element.customisations.sourceImage &&
            !photos.photoById[element.customisations.sourceImage.id]
          ) {
            updatedElements.push({
              ...element,
              customisations: {
                ...element.customisations,
                sourceImage: null,
              },
            })
          }

          break
        }
        case 'image-collage': {
          if (element.customisations.grid) {
            const imageIds = getImageIds(element.customisations.grid)
            const newImageIds = imageIds.filter(
              imageId => imageId in photos.photoById,
            )

            if (newImageIds.length !== imageIds.length) {
              if (partiallyRemove) {
                const grids = getAvailableGrids(collageGrids, newImageIds)
                updatedElements.push({
                  ...element,
                  customisations: {
                    ...element.customisations,
                    grid: grids.length > 0 ? grids[0] : null,
                  },
                })
              } else {
                updatedElements.push({
                  ...element,
                  customisations: {
                    ...element.customisations,
                    grid: null,
                  },
                })
              }
            }
          }

          break
        }
        case 'overlay-image': {
          if (
            element.customisations.sourceImage &&
            !photos.photoById[element.customisations.sourceImage.id]
          ) {
            deletedElements.push(element)
          }

          break
        }
      }
    })
  })

  const designWithUpdatedElements = updatedElements.reduce(
    updateDesignElement,
    design,
  )

  const designWithRemovedElements = deletedElements.reduce(
    (acc, elementRef) => {
      return {
        ...acc,
        sceneById: {
          ...acc.sceneById,
          [elementRef.sceneId]: {
            ...acc.sceneById[elementRef.sceneId],
            ...deleteElement(elementRef, acc),
          },
        },
      }
    },
    designWithUpdatedElements,
  )

  return designWithRemovedElements
}
