import React, { FC } from 'react'
import { animated, SpringRef, useSpring } from 'react-spring'
import phoneImageUrl from './assets/phone.png'
import {
  PHONE_ASPECT_RATIO,
  PHONE_POSITION_X_PERCENT,
  PHONE_POSITION_Y_PERCENT,
  PHONE_WIDTH_PERCENT,
  TIMING_PHONE,
} from './constants'
import { Size } from './types'

type LayerPhoneProps = {
  springRef: SpringRef
  sceneSize: Size
}

export const LayerPhone: FC<LayerPhoneProps> = ({ springRef, sceneSize }) => {
  const spring = useSpring({
    ref: springRef,
    from: {
      transform: 'translateY(100%)',
    },
    to: {
      transform: 'translateY(0%)',
    },
    config: { duration: TIMING_PHONE.end - TIMING_PHONE.start },
  })

  const width = sceneSize.width * PHONE_WIDTH_PERCENT
  const height = width / PHONE_ASPECT_RATIO
  const x = sceneSize.width * PHONE_POSITION_X_PERCENT
  const y = sceneSize.height * PHONE_POSITION_Y_PERCENT

  return (
    <animated.img
      role="presentation"
      src={phoneImageUrl}
      width="100%"
      height="100%"
      style={{
        ...spring,
        position: 'absolute',
        top: `${y}px`,
        left: `${x}px`,
        width: `${width}px`,
        height: `${height}px`,
      }}
    />
  )
}
