import { Size } from '@moonpig/renderer-react'
import {
  Minimap as MinimapBase,
  useFocusOn,
} from '@moonpig/web-personalise-components'
import React, { FC, useCallback, useMemo } from 'react'
import shallow from 'zustand/shallow'
import { FOCUS_ID_SCENE } from '../../../../constants'
import { usePixelRatio } from '../../../../utils/usePixelRatio'
import { SelectSceneMethod, useAction, useView } from '../../../../store'
import {
  useLayout,
  useScene,
  useSceneIds,
  useSelectedSceneId,
} from '../../selectors'
import { DefaultSceneSurface } from '../DefaultSceneSurface'

type MinimapProps = {
  sceneOrder?: number[]
}

type DesignSceneSurfaceProps = {
  sceneId: string
  size: Size
}

const DesignSceneSurface: FC<DesignSceneSurfaceProps> = ({ sceneId, size }) => {
  const scene = useScene(sceneId)

  return <DefaultSceneSurface scene={scene} size={size} />
}

export const Minimap: FC<MinimapProps> = ({
  sceneOrder: sceneOrderOverride,
}) => {
  const sceneIds = useSceneIds()
  const sceneTitles = useView(
    'main',
    ({ design }) =>
      design.sceneIds.map(sceneId => design.sceneById[sceneId].title),
    shallow,
  )
  const sceneOrder = sceneOrderOverride ?? sceneIds.map((_, index) => index)
  const focusOn = useFocusOn()
  const layout = useLayout()
  const pixelRatio = usePixelRatio({ max: 2 })
  const selectedSceneId = useSelectedSceneId()
  const selectScene = useAction('selectScene')

  const handleSelectScene = useCallback(
    (sceneId: string, method: SelectSceneMethod) => {
      selectScene(sceneId, method)
      focusOn(FOCUS_ID_SCENE(sceneId))
    },
    [focusOn, selectScene],
  )

  const scenes = useMemo(
    () =>
      sceneOrder.map(sceneIndex => {
        const sceneId = sceneIds[sceneIndex]
        const title = sceneTitles[sceneIndex]
        const bounds = layout.sceneById[sceneId]

        return {
          sceneId,
          title,
          bounds,
          content: (
            <DesignSceneSurface
              sceneId={sceneId}
              size={{
                width: bounds.width * pixelRatio,
                height: bounds.height * pixelRatio,
              }}
            />
          ),
        }
      }),
    [layout.sceneById, pixelRatio, sceneIds, sceneOrder, sceneTitles],
  )

  return (
    <MinimapBase
      scenes={scenes}
      selectedSceneId={selectedSceneId}
      onSelectScene={handleSelectScene}
    />
  )
}
