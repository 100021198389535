import { Vec2, radToDeg } from '@moonpig/common-math'
import { styled, useTheme } from '@moonpig/launchpad-utils'
import { Transform, useHandle } from '@moonpig/use-transform'
import React from 'react'
import {
  BORDER_WIDTH,
  HANDLE_LENGTH,
  MIN_TOUCH_SIZE,
  ROTATE_HANDLE_SIZE,
} from '../constants'

const StyledLink = styled.a<{ borderColor: string }>`
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  outline: none;
  pointer-events: all;
  cursor: move;

  .bg {
    fill: white;
  }
  .fg {
    fill: ${({ borderColor }) => borderColor};
  }

  &:hover {
    .bg {
      fill: ${({ borderColor }) => borderColor};
    }
    .fg {
      fill: white;
    }
  }
`

export const RotateHandle = ({
  id,
  transform,
  disabled,
  elementHeight,
  transformPoint,
  screenScale,
}: {
  id: string
  transform: Transform
  disabled: boolean
  elementHeight: number
  transformPoint?: (point: Vec2) => Vec2
  screenScale: number
}) => {
  const controlOffsetY = screenScale * HANDLE_LENGTH
  const control: Vec2 = [0, -(elementHeight * 0.5 + controlOffsetY)]
  const anchor: Vec2 = [0, 0]

  const [, bind] = useHandle({
    options: {
      enableScale: false,
      enableRotation: true,
      transformPoint,
    },
    transform,
    control,
    anchor,
  })

  const touchSize = MIN_TOUCH_SIZE * screenScale
  const halfTouchSize = touchSize * 0.5
  const visibleSize = ROTATE_HANDLE_SIZE * screenScale
  const halfVisibleSize = visibleSize * 0.5
  const borderSize = BORDER_WIDTH * screenScale

  const { palette } = useTheme()
  const { colorFeedbackInformation } = palette
  const borderColor = colorFeedbackInformation

  const svgTransform = [
    `translate(${transform.value.position[0]}, ${transform.value.position[1]})`,
    `rotate(${radToDeg(transform.value.rotation)})`,
    `translate(${0} ${-(
      elementHeight * 0.5 * transform.value.scale[1] +
      controlOffsetY
    )})`,
  ].join(' ')

  return !disabled ? (
    <StyledLink
      {...bind()}
      onClick={
        /* istanbul ignore next */ e => {
          e.stopPropagation()
          e.preventDefault()
        }
      }
      aria-hidden
      tabIndex={-1}
      href="#"
      borderColor={borderColor}
      data-testid={`mp-ed-handle-rotate-${id}`}
    >
      <g transform={svgTransform}>
        <rect
          x={-halfTouchSize}
          y={-halfTouchSize}
          width={touchSize}
          height={touchSize}
          fill="transparent"
        />
        <circle cx={0} cy={0} r={halfVisibleSize} fill={borderColor} />
        <circle className="bg" cx={0} cy={0} r={halfVisibleSize - borderSize} />
        <g
          transform={`scale(${screenScale} ${screenScale}) translate(-12 -12)`}
        >
          <path
            className="fg"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.43 5.207a6.985 6.985 0 0 0-4.6 3.95A1 1 0 0 1 4 8.353a9.001 9.001 0 0 1 16.661.432l.295-.672a.75.75 0 1 1 1.374.602l-1.034 2.36a1.25 1.25 0 0 1-1.886.505l-2.075-1.526a.75.75 0 1 1 .888-1.209l.434.32a7.002 7.002 0 0 0-8.227-3.958ZM14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm-.184 6.793a6.985 6.985 0 0 0 4.6-3.95 1 1 0 1 1 1.83.804 9.001 9.001 0 0 1-16.661-.432l-.294.672a.75.75 0 1 1-1.374-.602l1.034-2.36a1.25 1.25 0 0 1 1.885-.505l2.076 1.527a.75.75 0 0 1-.889 1.208l-.434-.32a7.002 7.002 0 0 0 8.227 3.958Z"
          />
        </g>
      </g>
    </StyledLink>
  ) : null
}
