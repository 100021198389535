import { EditorFeatures } from '@moonpig/web-personalise-editor-types'

export const features: EditorFeatures = {
  enableAutosave: true,
  enableHistory: false,
  enableStickers: true,
  enableFlexibleText: true,
  enableFlexibleFront: true,
  enableFlexiblePhotos: true,
  enableEmojis: true,
  enableVideo: true,
  enableLayouts: true,
  enablePreviewComposition: true,
  enableSmartText: true,
  enableAudio: true,
  enableTextFragments: false,
  enableHandwriting: true,
  enableOnboarding: true,
}

export const useEditorFeatures = (): EditorFeatures => {
  return features
}
