import {
  Design,
  DesignElementMedia,
  DesignElementMediaUploadCompleteResult,
  DesignPlacement,
} from '@moonpig/web-personalise-editor-types'
import uuid from 'uuid'
import { assert } from './assert'
import { updateDesignSceneElements } from './updateDesignSceneElements'

const createMediaElement = ({
  sceneId,
  placement,
  uploadComplete,
  state,
  mediaAspectRatio,
  kind,
}: {
  sceneId: string
  placement: DesignPlacement
  uploadComplete: Promise<DesignElementMediaUploadCompleteResult>
  state: DesignElementMedia['state']
  mediaAspectRatio: number
  kind: DesignElementMedia['kind']
}): DesignElementMedia => {
  return {
    type: 'media',
    kind,
    id: uuid(),
    sceneId,
    x: placement.x,
    y: placement.y,
    width: placement.width,
    height: placement.width / mediaAspectRatio,
    rotation: 0,
    uploadComplete,
    state,
  }
}

export const addMediaToDesign = ({
  design,
  uploadComplete,
  state,
  mediaAspectRatio,
  kind,
}: {
  design: Design
  uploadComplete: Promise<DesignElementMediaUploadCompleteResult>
  state: DesignElementMedia['state']
  mediaAspectRatio: number
  kind: DesignElementMedia['kind']
}): {
  updatedDesign: Design
  mediaElement: DesignElementMedia
  targetSceneId: string
} => {
  const targetSceneId = design.sceneIds.find(otherSceneId =>
    design.sceneById[otherSceneId].availableFeatures.includes(kind),
  )

  assert(targetSceneId !== undefined)

  const targetScene = design.sceneById[targetSceneId]

  const mediaElement = createMediaElement({
    sceneId: targetScene.id,
    placement: targetScene.placements.media,
    uploadComplete,
    state,
    mediaAspectRatio,
    kind,
  })

  const updatedDesign = updateDesignSceneElements(design, targetSceneId, () => {
    return {
      elementIds: [...targetScene.elementIds, mediaElement.id],
      elementById: {
        ...targetScene.elementById,
        [mediaElement.id]: mediaElement,
      },
    }
  })

  return {
    updatedDesign,
    mediaElement,
    targetSceneId,
  }
}
