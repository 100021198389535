import { updateDesignSceneElements } from '../../../utils/updateDesignSceneElements'
import { ActionCreator } from '../../types'
import { storeUpdateDesign } from '../../updaters/design'

export const createAddElement: ActionCreator<'addElement'> =
  ({ set, t }) =>
  element => {
    set(storeInitial => {
      let store = storeInitial

      store = storeUpdateDesign(
        store,
        {
          t,
          changeReason:
            element.type === 'overlay-text'
              ? 'elements-changed-minor'
              : 'elements-changed',
        },
        design => {
          return updateDesignSceneElements(
            design,
            element.sceneId,
            currentDesign => ({
              elementIds: [
                ...currentDesign.sceneById[element.sceneId].elementIds,
                ...[element.id],
              ],
              elementById: {
                ...currentDesign.sceneById[element.sceneId].elementById,
                [element.id]: element,
              },
            }),
          )
        },
      )

      return store
    })
  }
