import { Vec2 } from '@moonpig/common-math'
import React, { FC } from 'react'

type CollageContainerProps = {
  label: string
  gridSize: Vec2
}

export const CollageContainer: FC<CollageContainerProps> = ({
  label,
  gridSize,
  children,
}) => {
  return (
    <svg
      width={`${gridSize[0]}px`}
      height={`${gridSize[1]}px`}
      role="article"
      aria-label={label}
      overflow={'visible'}
    >
      {children}
    </svg>
  )
}
