import { Vec2 } from '@moonpig/common-math'
import React, { FC, useCallback, useMemo, useRef } from 'react'
import { Frame } from '../../common/frame'
import { CollageMode } from '../../common/types'
import { CollageGrid, ImageById } from '../../model'
import { CollageContainer } from '../CollageContainer'
import { InteractiveCollageCells } from '../InteractiveCollageCells'
import { InteractiveCollageGutters } from '../InteractiveCollageGutters'

type InteractiveCollageProps = {
  createCellLabel: (index: number) => string
  label: string
  mode: CollageMode
  grid: CollageGrid
  width: number
  height: number
  scale: number
  imageById: ImageById
  clipBounds: Frame
  selectedCellId: string | null
  onSelectCell: (cellId: string) => void
  onChangeGrid: (newGrid: CollageGrid, context: { last: boolean }) => void
}

export const InteractiveCollage: FC<InteractiveCollageProps> = ({
  createCellLabel,
  label,
  mode,
  grid,
  width,
  height,
  scale,
  imageById,
  clipBounds,
  selectedCellId,
  onSelectCell,
  onChangeGrid,
}) => {
  const gridSize = useMemo<Vec2>(() => [width, height], [width, height])
  const disableSelectCell = useRef(false)

  const handleDraggingGutter = useCallback((isDragging: boolean) => {
    if (isDragging) {
      disableSelectCell.current = true
    }
  }, [])

  return (
    <CollageContainer label={label} gridSize={gridSize}>
      <InteractiveCollageCells
        createCellLabel={createCellLabel}
        mode={mode}
        grid={grid}
        gridSize={gridSize}
        clipBounds={clipBounds}
        scale={scale}
        imageById={imageById}
        selectedCellId={selectedCellId}
        onSelectCell={onSelectCell}
        onChangeGrid={onChangeGrid}
        disableSelectCell={disableSelectCell}
      />
      {mode === 'manipulate' && selectedCellId === null && (
        <InteractiveCollageGutters
          grid={grid}
          gridSize={gridSize}
          clipBounds={clipBounds}
          scale={scale}
          onChangeGrid={onChangeGrid}
          onDragging={handleDraggingGutter}
        />
      )}
    </CollageContainer>
  )
}
