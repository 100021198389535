import { IconSystemTextField } from '@moonpig/launchpad-assets'
import { useTheme } from '@moonpig/launchpad-utils'
import React, { FC, useMemo, useRef } from 'react'
import { useElementSize } from '../../../utils'
import { useLocaleText } from '../InteractiveElement.locale'
import { FONT_SIZE, ICON_GAP, ICON_SIZE } from '../constants'

type IndicatorAddTextProps = {
  scale: number
  width: number
  height: number
}

export const IndicatorAddText: FC<IndicatorAddTextProps> = ({
  scale,
  width,
  height,
}) => {
  const t = useLocaleText()
  const { colors } = useTheme()
  const { colorTextBody } = colors

  const iconSize = ICON_SIZE * scale
  const fontSize = FONT_SIZE * scale
  const iconTextGap = ICON_GAP * scale

  const text = t('add_text')

  const elementRef = useRef<SVGGElement>(null)
  const elementSize = useElementSize(elementRef)

  const elementPosition: [number, number] = useMemo(() => {
    /* istanbul ignore next */
    if (elementSize) {
      return [
        width * 0.5 - elementSize.width * 0.5,
        height * 0.5 - elementSize.height * 0.5,
      ]
    }

    return [width * 0.5, height * 0.5]
  }, [elementSize, width, height])

  return (
    <g transform={`translate(${elementPosition.join(' ')})`} ref={elementRef}>
      <IconSystemTextField
        width={iconSize}
        height={iconSize}
        color={colorTextBody}
      />
      <text
        aria-hidden
        fontSize={fontSize}
        color={colorTextBody}
        x={iconSize + iconTextGap}
        y={iconSize * 0.75}
      >
        {text}
      </text>
    </g>
  )
}
