import { RefObject, useEffect } from 'react'

type EventTargetElement = HTMLDivElement | SVGGElement

export const useDisablePageZoom = (ref: RefObject<EventTargetElement>) => {
  useEffect(() => {
    const element = ref.current

    if (!element) {
      return
    }

    const handleWheelEvent = (wheelEvent: WheelEvent) => {
      if (wheelEvent.ctrlKey) {
        wheelEvent.preventDefault()
      }
    }

    element.addEventListener('wheel', handleWheelEvent as EventListener)

    return () => {
      element.removeEventListener('wheel', handleWheelEvent as EventListener)
    }
  }, [ref])
}
