import { RegisterResources } from '@moonpig/renderer-react'
import {
  Design,
  DesignElementImageDynamic,
  Services,
} from '@moonpig/web-personalise-editor-types'
import { getDynamicImageId } from '../../utils/getDynamicImageId'
import { DynamicImageState, RemoteImageStore } from '../types'

export const getDynamicImageElementsToUpdate = ({
  design,
  dynamicImageById,
}: {
  design: Design
  dynamicImageById: RemoteImageStore['dynamicImageById']
}): DesignElementImageDynamic[] => {
  const dynamicImageElementsToUpdate: DesignElementImageDynamic[] = []

  design.sceneIds.forEach(sceneId => {
    design.sceneById[sceneId].elementIds.forEach(elementId => {
      const element = design.sceneById[sceneId].elementById[elementId]

      if (element.type !== 'image-dynamic') {
        return
      }

      const { dynamicImageId } = getDynamicImageId(element)
      const existingImage = dynamicImageById[dynamicImageId]

      if (existingImage) {
        return
      }

      dynamicImageElementsToUpdate.push(element)
    })
  })

  return dynamicImageElementsToUpdate
}

export const renderDynamicImages = async ({
  services,
  registerResources,
  dynamicImageElementsToUpdate,
}: {
  services: Services
  registerResources: RegisterResources
  dynamicImageElementsToUpdate: DesignElementImageDynamic[]
}): Promise<RemoteImageStore['dynamicImageById']> => {
  const updatedDynamicImageUrlEntries = await Promise.all(
    dynamicImageElementsToUpdate.map(
      async (element): Promise<[string, DynamicImageState]> => {
        const { dynamicImageId } = getDynamicImageId(element)

        const result = await services.renderDynamicImage({
          id: element.dynamic.id,
          format: element.dynamic.format,
          textParts: element.customisations.textParts,
          elementWidth: element.width,
          elementHeight: element.height,
        })

        if (result.type !== 'success') {
          throw new Error('Failed to render dynamic image')
        }

        const imageUrl = result.url
        const imageElement = await services.loadImage(imageUrl)

        registerResources.registerImage({ imageUrl, imageElement })

        return [dynamicImageId, { type: 'loaded', imageUrl }]
      },
    ),
  )

  return Object.fromEntries(updatedDynamicImageUrlEntries)
}
