import { Flex, Text } from '@moonpig/launchpad-components'
import { colorValue } from '@moonpig/launchpad-theme'
import { styled } from '@moonpig/launchpad-utils'
import { CollaboratePeer } from '@moonpig/web-personalise-editor-types'
import React, { FC } from 'react'
import { useView } from '../../../../../store'

const SIZE = 36

const StyledPeer = styled.button`
  margin: ${(48 - SIZE) / 2}px 0;
  width: ${SIZE}px;
  height: ${SIZE}px;
  border-radius: 50%;
  border: 3px solid ${colorValue('colorPrimary02')};
  background: ${colorValue('colorBackground03')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #313131;
`

const getPeerInitial = (peer: CollaboratePeer): string => {
  return (peer.name[0] ?? '?').toUpperCase()
}

export const Peers: FC = () => {
  const peers = useView('main', view => view.collaborateSession?.peers)
  const sharing = useView('main', view => view.sharing)

  if (!peers || !sharing) {
    return null
  }

  return (
    <Flex pr={4} gap="4px">
      {peers.map(peer => (
        <StyledPeer key={peer.id} title={peer.name}>
          <Text typography="typeDisplay05">{getPeerInitial(peer)}</Text>
        </StyledPeer>
      ))}
    </Flex>
  )
}
