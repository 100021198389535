import { retry } from '@lifeomic/attempt'
import { logger } from '@moonpig/web-core-monitoring'
import { createProxyApiUrl } from '@moonpig/web-core-utils'
import { LoadFont } from '@moonpig/web-personalise-editor-types'
import { AppContext } from '../types'

const toPercentString = (p: number): string => `${p * 100}%`

export const createLoadFont = ({ features }: AppContext): LoadFont => {
  return async (fontFamily, fontUrl, fontMetrics) => {
    try {
      return await retry(
        async () => {
          const url = createProxyApiUrl(fontUrl, { forwardAbsolute: true })

          const ascender = fontMetrics.hhea.ascender
          const descender = Math.abs(fontMetrics.hhea.descender)
          const lineGap = fontMetrics.hhea.lineGap
          const unitsPerEm = fontMetrics.head.unitsPerEm

          const fontFaceOptions = features.enableTextFragments
            ? {
                ascentOverride: toPercentString(ascender / unitsPerEm),
                descentOverride: toPercentString(descender / unitsPerEm),
                lineGapOverride: toPercentString(lineGap / unitsPerEm),
              }
            : undefined

          const src = `url('${url}') format('woff2')`
          const fontFace = new FontFace(fontFamily, src, fontFaceOptions)

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          document.fonts.add(fontFace as any)

          await fontFace.load()
        },
        {
          maxAttempts: 3,
          delay: 500,
          factor: 2,
          handleError(err, { attemptNum }) {
            logger.info(
              'Retrying load font',
              { attemptNum, fontFamily, fontUrl },
              err,
            )
          },
        },
      )
    } catch (error) {
      logger.fixToday('Failed to load font', { fontFamily, fontUrl }, error)

      throw new Error(`Failed to load font ${fontUrl}: ${error.message}`)
    }
  }
}
