import { IllustrationCardHearts } from '@moonpig/launchpad-assets'
import { LoadingScreen } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { useView } from '../../store'
import { useEditorLocaleText } from '../../text-localisation'

export const LoadingFinish: FC = () => {
  const t = useEditorLocaleText()
  const designStateType = useView('main', view => view.designState.type)

  switch (designStateType) {
    case 'draft': {
      return (
        <LoadingScreen
          title={t('add_to_basket_loading_title')}
          description={t('add_to_basket_loading_description')}
          icon={<IllustrationCardHearts />}
        />
      )
    }
    case 'in-basket': {
      return (
        <LoadingScreen
          title={t('update_basket_loading_title')}
          description={t('update_basket_loading_description')}
          icon={<IllustrationCardHearts />}
        />
      )
    }
  }
}
