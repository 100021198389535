import { TextInput } from '@moonpig/launchpad-forms'
import { screenReaderOnly, styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { CharacterCount } from './CharacterCount'

type TextPartInputProps = {
  id: string
  controlsId: string
  label: string
  helperText?: string
  text: string
  maxCharacters: number | null
  onChange(text: string): void
  textTransform?: string
}

const StyledTextInput = styled(TextInput)<{ textTransform?: string }>`
  label {
    ${screenReaderOnly}
  }

  input {
    text-transform: ${({ textTransform }) => textTransform};
    user-select: auto;
  }
`

export const TextPartInput: FC<TextPartInputProps> = ({
  id,
  controlsId,
  label,
  helperText,
  text,
  maxCharacters,
  onChange,
  textTransform,
}) => {
  return (
    <StyledTextInput
      id={id}
      aria-controls={controlsId}
      mb={6}
      name=""
      label={label}
      helperText={helperText}
      value={text}
      maxLength={maxCharacters ?? undefined}
      textTransform={textTransform}
      onChange={event => {
        onChange(event.currentTarget.value.normalize())
      }}
      componentEnd={
        <CharacterCount text={text} maxCharacters={maxCharacters} />
      }
    />
  )
}
