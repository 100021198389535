const INITIAL_PHOTO_SIZE_RELATIVE_TO_SCENE = 0.5

type Size = {
  width: number
  height: number
}

export const getDefaultOverlayImageSize = (
  sceneSize: Size,
  elementSize: Size,
): Size => {
  const elementAspectRatio = elementSize.width / elementSize.height
  const minSceneDimension = Math.min(sceneSize.width, sceneSize.height)

  if (elementAspectRatio < 1) {
    const height = minSceneDimension * INITIAL_PHOTO_SIZE_RELATIVE_TO_SCENE
    const width = height * elementAspectRatio
    return { width, height }
  }

  const width = minSceneDimension * INITIAL_PHOTO_SIZE_RELATIVE_TO_SCENE
  const height = width / elementAspectRatio
  return { width, height }
}
