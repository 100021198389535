import localforage from 'localforage'
// eslint-disable-next-line import/no-namespace
import * as z from 'zod'

const rawBlobStoreItemSchema = z
  .object({
    blob: z.unknown(),
    width: z.number(),
    height: z.number(),
    addedTime: z.number(),
  })
  .nonstrict()

export type BlobStoreItem = {
  key: string
  blob: Blob
  width: number
  height: number
  addedTime: number
}

type BlobStore = {
  list: () => Promise<string[]>
  load: (key: string) => Promise<BlobStoreItem | null>
  store: (item: BlobStoreItem) => Promise<void>
  delete: (key: string) => Promise<void>
}

const parseBlobStoreItem = (
  id: string,
  data: unknown,
): BlobStoreItem | null => {
  try {
    const { blob, width, height, addedTime } =
      rawBlobStoreItemSchema.parse(data)

    if (!(blob instanceof Blob)) {
      return null
    }

    return { key: id, blob, width, height, addedTime }
  } catch (error) {
    return null
  }
}

export const blobStore: BlobStore = {
  list: async () => {
    return localforage.keys()
  },
  load: async key => {
    const data = await localforage.getItem(key)
    return parseBlobStoreItem(key, data)
  },
  store: async item => {
    const { key, ...data } = item

    try {
      await localforage.setItem(key, data)
    } catch {
      // Ignore errors. Safari will throw an error in private browsing mode
    }
  },
  delete: async key => {
    await localforage.removeItem(key)
  },
}
