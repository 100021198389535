import { IconInformation } from '@moonpig/launchpad-assets'
import { Alert, Text } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { ScreenReaderOnly } from '@moonpig/web-personalise-components'
import {
  DesignTextPart,
  DesignTextPartEditable,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, FormEvent, useCallback } from 'react'
import {
  BRAND_TWO_TRANSLUCENT,
  TEXT_DEFAULT_MAX_CHARACTERS,
} from '../../../../../../../constants'
import { findEmojis } from '../../../../../../../utils/findEmojis'
import { sanitizeInput } from '../../../../../../../utils/inputIsValid'
import { useEditorFeatures } from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { TextPartInput } from './TextPartInput'

const TEXT_DESCRIPTION_ID = 'mp-ed-placeholder-text-description'
const TEXT_PREVIEW_ID = 'mp-ed-placeholder-text-preview'

type TextPartsFormProps = {
  showTextPreview?: boolean
  textParts: DesignTextPart[]
  customisations: string[]
  onChange: (customisations: string[]) => void
  onFiltered: (event: { hasEmojis: boolean }) => void
  onSubmit?: () => void
}

const StyledTextPreview = styled.p`
  ${s({ mb: 6 })}

  word-break: break-word;
`

const StyledFieldset = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`

const applyTransforms = (
  text: string,
  textPart: DesignTextPartEditable,
): string => {
  if (textPart.textTransform === 'UPPERCASE') {
    return text.toUpperCase()
  }

  return text
}

export const TextPartsForm: FC<TextPartsFormProps> = ({
  showTextPreview,
  textParts,
  customisations,
  onChange,
  onFiltered,
  onSubmit,
  children,
}) => {
  const t = useEditorLocaleText()

  const { enableEmojis } = useEditorFeatures()

  const textPreview = textParts.map(textPart => {
    if (textPart.type === 'static') {
      return textPart.text
    }

    const text = customisations[textPart.editableIndex]

    return (
      <mark
        key={textPart.editableIndex}
        style={{
          display: 'inline',
          backgroundColor: BRAND_TWO_TRANSLUCENT,
          textDecoration: 'underline',
          fontWeight: 'bold',
          padding: '2px 4px',
        }}
      >
        {applyTransforms(text, textPart) || '\u00A0'}
      </mark>
    )
  })

  const handleSubmit = useCallback(
    (event: FormEvent) => {
      event.preventDefault()
      onSubmit?.()
    },
    [onSubmit],
  )

  return (
    <form method="post" onSubmit={handleSubmit}>
      {showTextPreview && (
        <>
          <Alert variant="info" icon={IconInformation} py={4} mb={6}>
            <Text mb={0} {...{ id: TEXT_DESCRIPTION_ID }}>
              {t('placeholder_text_form_editable_alert_text')}
            </Text>
          </Alert>
          <StyledTextPreview
            id={TEXT_PREVIEW_ID}
            aria-live="polite"
            aria-describedby={TEXT_DESCRIPTION_ID}
          >
            {textPreview}
          </StyledTextPreview>
        </>
      )}
      <StyledFieldset>
        <ScreenReaderOnly as="div">
          <legend>{t('placeholder_text_form_screen_reader_legend')}</legend>
        </ScreenReaderOnly>
        {textParts.map(textPart => {
          if (textPart.type !== 'editable') {
            return null
          }

          const label = t(
            'editable_text_part_label',
            textPart.editableIndex + 1,
          )

          const id = `mp-ed-placholder-input-${textPart.editableIndex}`

          const text = customisations[textPart.editableIndex]

          const maxCharacters =
            textPart.maxCharacters ?? TEXT_DEFAULT_MAX_CHARACTERS

          const helperText =
            text.length >= maxCharacters
              ? t('toolbar_character_limit_reached_error_helper_text')
              : undefined

          return (
            <TextPartInput
              key={label}
              id={id}
              controlsId={TEXT_PREVIEW_ID}
              label={label}
              helperText={helperText}
              text={text}
              maxCharacters={textPart.maxCharacters}
              textTransform={textPart.textTransform || undefined}
              onChange={newText => {
                const sanitizedText = enableEmojis
                  ? newText
                  : sanitizeInput(newText)

                if (sanitizedText !== newText) {
                  const hasEmojis = findEmojis(newText).length > 0
                  onFiltered({ hasEmojis })
                }

                const truncatedText = sanitizedText.slice(0, maxCharacters)

                const newCustomisations = [...customisations]

                newCustomisations.splice(
                  textPart.editableIndex,
                  1,
                  truncatedText,
                )

                onChange(newCustomisations)
              }}
            />
          )
        })}
      </StyledFieldset>
      {children}
    </form>
  )
}
