import { Vec2, vec2Add, vec2Scale, vec2Sub } from '@moonpig/common-math'
import { colorValue } from '@moonpig/launchpad-theme'
import { styled, useTheme } from '@moonpig/launchpad-utils'
import React, { FC, KeyboardEvent, useCallback, useMemo, useRef } from 'react'
import { useGesture } from 'react-use-gesture'
import { Frame, frameIntersect } from '../../common/frame'
import { CollageGridGutterOrientation } from '../../model'

const KEYBOARD_OFFSET_DELTA_COARSE = 0.05
const KEYBOARD_OFFSET_DELTA_FINE = 0.01
const GUTTER_GRAB_WIDTH = 32
const GUTTER_LINE_WIDTH = 4
const GUTTER_HANDLE_WIDTH = 12
const GUTTER_HANDLE_BORDER_WIDTH = 2
const GUTTER_HANDLE_LENGTH_PERCENT = 0.5
const DOUBLE_CLICK_THRESHOLD_MS = 300

type GutterAnchors = {
  start: number
  end: number
}

type GutterHandleProps = {
  scale: number
  gridSize: Vec2
  clipBounds: Frame
  orientation: CollageGridGutterOrientation
  offset: number
  anchors: GutterAnchors
  onMove: (newOffset: number, context: { last: boolean }) => void
  onReset: () => void
  onDragging: (isDragging: boolean) => void
}

type Rect = {
  position: Vec2
  size: Vec2
}

// fix me: check with Launchpad team what color to use for focus style
const StyledGrabRect = styled.rect`
  outline: none;

  &:focus-visible + rect {
    fill: ${colorValue('colorFeedbackWarning')};
  }
`

type GutterConfig = {
  cursor: string
  unitFrame: Frame
  normal: Vec2
}

const getGutterConfig = (
  orientation: CollageGridGutterOrientation,
  gridSize: Vec2,
  anchors: GutterAnchors,
  offset: number,
): GutterConfig => {
  switch (orientation) {
    case 'horizontal': {
      const y = offset * gridSize[1]
      const start = anchors.start * gridSize[0]
      const end = anchors.end * gridSize[0]

      return {
        cursor: 'ns-resize',
        unitFrame: {
          position: [start, y],
          size: [end - start, 0],
        },
        normal: [0, 1],
      }
    }
    case 'vertical': {
      const x = offset * gridSize[0]
      const start = anchors.start * gridSize[1]
      const end = anchors.end * gridSize[1]

      return {
        cursor: 'ew-resize',
        unitFrame: {
          position: [x, start],
          size: [0, end - start],
        },
        normal: [1, 0],
      }
    }
  }
}

export const GutterHandle: FC<GutterHandleProps> = ({
  scale,
  orientation,
  clipBounds,
  gridSize,
  offset,
  anchors,
  onMove,
  onReset,
  onDragging,
}) => {
  const { colors } = useTheme()
  const { colorBackground01, colorFeedbackInformation } = colors

  const initialGutterOffset = useRef(0)

  const lastClickTime = useRef(0)

  const bind = useGesture({
    onDrag: ({ first, last, movement: [mx, my] }) => {
      onDragging(!last)

      if (first) {
        initialGutterOffset.current = offset
      }

      let offsetDelta
      switch (orientation) {
        case 'horizontal': {
          offsetDelta = (my * scale) / clipBounds.size[1]
          break
        }
        case 'vertical': {
          offsetDelta = (mx * scale) / clipBounds.size[0]
          break
        }
      }

      onMove(initialGutterOffset.current + offsetDelta, { last })
    },
    onClick: () => {
      const time = Date.now()
      if (time - lastClickTime.current < DOUBLE_CLICK_THRESHOLD_MS) {
        onReset()
      }
      lastClickTime.current = time
    },
  })

  const { cursor, unitFrame, normal } = useMemo(() => {
    const config = getGutterConfig(orientation, gridSize, anchors, offset)

    return {
      ...config,
      unitFrame: frameIntersect(clipBounds, config.unitFrame),
    }
  }, [orientation, clipBounds, gridSize, anchors, offset])

  const grabRect: Rect = {
    position: vec2Sub(
      unitFrame.position,
      vec2Scale(normal, GUTTER_GRAB_WIDTH * scale * 0.5),
    ),
    size: vec2Add(unitFrame.size, vec2Scale(normal, GUTTER_GRAB_WIDTH * scale)),
  }

  const handleInset = vec2Scale(
    vec2Sub(
      unitFrame.size,
      vec2Scale(unitFrame.size, GUTTER_HANDLE_LENGTH_PERCENT),
    ),
    0.5,
  )

  const lineRect: Rect = {
    position: vec2Sub(
      unitFrame.position,
      vec2Scale(normal, GUTTER_LINE_WIDTH * scale * 0.5),
    ),
    size: vec2Add(unitFrame.size, vec2Scale(normal, GUTTER_LINE_WIDTH * scale)),
  }

  const handleRect: Rect = {
    position: vec2Add(
      vec2Sub(
        unitFrame.position,
        vec2Scale(normal, GUTTER_HANDLE_WIDTH * scale * 0.5),
      ),
      handleInset,
    ),
    size: vec2Sub(
      vec2Add(unitFrame.size, vec2Scale(normal, GUTTER_HANDLE_WIDTH * scale)),
      vec2Scale(handleInset, 2),
    ),
  }

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<SVGElement>) => {
      const delta = event.shiftKey
        ? KEYBOARD_OFFSET_DELTA_FINE
        : KEYBOARD_OFFSET_DELTA_COARSE

      switch (event.key) {
        case 'ArrowUp':
        case 'ArrowLeft': {
          onMove(offset - delta, { last: true })
          break
        }
        case 'ArrowDown':
        case 'ArrowRight': {
          onMove(offset + delta, { last: true })
          break
        }
      }
    },
    [offset, onMove],
  )

  return (
    <>
      {/* fix me, upgrade ESLint rules to remove this disable. Needed because aria-valuenow was not always recognised as valid (https://github.com/A11yance/aria-query/commit/cd57f86c963a893fa8d72f5bb37adf740a8507d0) */}
      {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
      <StyledGrabRect
        {...bind()}
        onKeyDown={handleKeyDown}
        role="separator"
        aria-orientation={orientation}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={Math.round(offset * 100)}
        tabIndex={0}
        style={{ cursor, touchAction: 'none' }}
        fill="transparent"
        x={grabRect.position[0]}
        y={grabRect.position[1]}
        width={grabRect.size[0]}
        height={grabRect.size[1]}
      />
      <rect
        role="presentation"
        style={{ pointerEvents: 'none' }}
        fill={colorFeedbackInformation}
        x={lineRect.position[0]}
        y={lineRect.position[1]}
        width={lineRect.size[0]}
        height={lineRect.size[1]}
      />
      <rect
        role="presentation"
        style={{ pointerEvents: 'none' }}
        stroke={colorBackground01}
        strokeWidth={GUTTER_HANDLE_BORDER_WIDTH * scale}
        fill={colorFeedbackInformation}
        rx={GUTTER_HANDLE_WIDTH * scale * 0.5}
        x={handleRect.position[0]}
        y={handleRect.position[1]}
        width={handleRect.size[0]}
        height={handleRect.size[1]}
      />
    </>
  )
}
