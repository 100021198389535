import { Text } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { RadioList, RadioListItem } from '../RadioList'
import { useLocaleText } from './FontFacePicker.locale'

export type FontFace = {
  id: string
  name: string
  value: string
  state: 'loading' | 'loaded' | 'error'
}

export type FontFacePickerProps = {
  fonts: FontFace[]
  selectedFontId: string
  onSelect: (fontId: string) => void
}

export const FontFacePicker: FC<FontFacePickerProps> = ({
  fonts,
  selectedFontId,
  onSelect,
}) => {
  const t = useLocaleText()
  const items: RadioListItem[] = []

  fonts.forEach(({ id, name, value, state }) => {
    const loaded = state === 'loaded'

    if (state === 'error') {
      return
    }

    items.push({
      id,
      element: (
        <Text
          typography="body"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          width="100%"
          display="block"
          fontFamily={loaded ? value : undefined}
        >
          {loaded ? name : t('loading_font_list_text', name)}
        </Text>
      ),
      checked: id === selectedFontId,
      disabled: !loaded,
    })
  })

  return (
    <RadioList
      name={'mp-ed-toolbar-font-style-radio'}
      onSelect={onSelect}
      items={items}
    />
  )
}
