import { updateGridCell } from '@moonpig/web-personalise-collage'
import {
  DesignElementImageCollage,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useMemo } from 'react'
import { assert } from '../../../../../../../../utils/assert'
import { useAction, useView } from '../../../../../../../../store'
import { useElementRef } from '../../../../../../selectors'
import { PhotoLibrary } from '../../PhotoLibrary'

type ImageCollageCellPhotoLibraryProps = {
  elementRef: DesignElementRef<DesignElementImageCollage>
  labelledById: string
  cellId: string
}

export const ImageCollageCellPhotoLibrary: FC<
  ImageCollageCellPhotoLibraryProps
> = ({ elementRef, labelledById, cellId }) => {
  const addPhotos = useAction('addPhotos')
  const removePhoto = useAction('removePhoto')
  const updateElementRef = useAction('updateElementRef')
  const setUI = useAction('setUI')
  const photos = useView('main', view => view.photos)
  const element = useElementRef(elementRef)

  const { grid } = element.customisations

  assert(grid !== null)

  const cell = grid.cells.find(c => c.id === cellId)

  assert(cell !== undefined)
  assert(cell.content.type === 'image')

  const selectedPhotoId = cell.content.imageId

  const photosView = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return photos.photoIds.map(id => {
      const photoState = photos.photoById[id]

      switch (photoState.type) {
        case 'loading': {
          return {
            type: 'loading' as const,
            id,
          }
        }
        case 'loaded': {
          return {
            type: 'loaded' as const,
            id,
            selected: id === selectedPhotoId,
            imageUrl: photoState.photo.smallImage.url,
          }
        }
      }
    })
  }, [photos, selectedPhotoId])

  const handleAddPhotos = useCallback(
    (files: Blob[]) => {
      addPhotos(files, addedPhotos => {
        updateElementRef(elementRef, current => {
          return {
            ...current,
            customisations: {
              ...current.customisations,
              pendingEditedImage: null,
              grid: updateGridCell(grid, cellId, {
                content: {
                  type: 'image',
                  imageId: addedPhotos[0].id,
                  scale: 1,
                  offset: [0, 0],
                },
              }),
            },
          }
        })
      })
    },
    [addPhotos, cellId, elementRef, grid, updateElementRef],
  )

  const handleSelectPhoto = useCallback(
    (imageId: string) => {
      updateElementRef(elementRef, current => {
        return {
          ...current,
          customisations: {
            ...current.customisations,
            pendingEditedImage: null,
            grid: updateGridCell(grid, cellId, {
              content: {
                type: 'image',
                imageId,
                scale: 1,
                offset: [0, 0],
              },
            }),
          },
        }
      })
    },
    [cellId, elementRef, grid, updateElementRef],
  )

  const handleRemovePhoto = useCallback(
    (imageId: string) => {
      setUI({
        type: 'image-collage',
        elementRef: element,
        selectedMenuItem: null,
      })
      removePhoto(imageId)
    },
    [element, removePhoto, setUI],
  )

  return (
    <PhotoLibrary
      labelledById={labelledById}
      multiSelect={false}
      photos={photosView}
      onAddPhotos={handleAddPhotos}
      onSelectPhoto={handleSelectPhoto}
      onRemovePhoto={handleRemovePhoto}
    />
  )
}
