import { IconAdjust, IconReplace } from '@moonpig/web-personalise-components'
import React, { useCallback, useMemo } from 'react'
import { createElementDomId } from '../../../../../../../utils/createElementId'
import {
  UIImageCollageCell,
  UIImageCollageCellMenuItem,
  useAction,
} from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { ToolbarItem, ToolbarMenu, ToolbarOrientation } from '../Toolbar'
import { ToolbarCommon } from '../ToolbarCommon'
import { createMenuTitleId } from '../utils/createMenuTitleId'
import { ImageCollageCellAdjust } from './ImageCollageCellAdjust'
import { ImageCollageCellPhotoLibrary } from './ImageCollageCellPhotoLibrary'

type ToolbarImageCollageCellProps = {
  onDone: () => void
  orientation: ToolbarOrientation
  ui: UIImageCollageCell
}

export const ToolbarImageCollageCell = ({
  onDone,
  orientation,
  ui,
}: ToolbarImageCollageCellProps) => {
  const setUI = useAction('setUI')
  const t = useEditorLocaleText()
  const { elementRef } = ui
  const controlId = createElementDomId(elementRef.id)
  const { selectedMenuItem } = ui

  const items = useMemo<ToolbarItem<UIImageCollageCellMenuItem>[]>(() => {
    return [
      {
        type: 'menu',
        id: 'replace',
        label: t('toolbar_image_replace_label'),
        ariaLabel: t('toolbar_replace_photo'),
        icon: <IconReplace />,
        disabled: false,
      },
      {
        type: 'menu',
        id: 'adjust',
        label: t('toolbar_image_adjust_label'),
        ariaLabel: t('toolbar_adjust'),
        icon: <IconAdjust />,
        disabled: false,
      },
    ]
  }, [t])

  const menu = useMemo<ToolbarMenu | undefined>(() => {
    switch (selectedMenuItem) {
      case 'replace': {
        const titleId = createMenuTitleId(elementRef.id, selectedMenuItem)

        return {
          title: t('toolbar_menu_choose_photo_label'),
          titleId,
          content: (
            <ImageCollageCellPhotoLibrary
              elementRef={elementRef}
              labelledById={titleId}
              cellId={ui.cellId}
            />
          ),
          closeLabel: t('toolbar_menu_close_photo_library_label'),
        }
      }
      case 'adjust': {
        const titleId = createMenuTitleId(elementRef.id, selectedMenuItem)

        return {
          title: t('toolbar_menu_adjust_photo_label'),
          titleId,
          content: (
            <ImageCollageCellAdjust
              elementRef={elementRef}
              cellId={ui.cellId}
            />
          ),
          closeLabel: t('toolbar_menu_close_adjust_label'),
          drawerHeight: 'fit',
        }
      }
    }
  }, [elementRef, selectedMenuItem, t, ui.cellId])

  const handleSelectItem = useCallback(
    (itemId: UIImageCollageCellMenuItem | null) => {
      setUI({ ...ui, selectedMenuItem: itemId })
    },
    [setUI, ui],
  )

  return (
    <ToolbarCommon
      controlId={controlId}
      label={t('toolbar_collage_cell_toolbar_title')}
      orientation={orientation}
      items={items}
      selectedItemId={selectedMenuItem}
      onSelectItem={handleSelectItem}
      menu={menu}
      done={{
        label: t('toolbar_done_button'),
        ariaLabel: t('toolbar_collage_cell_toolbar_close_label'),
        onDone,
      }}
    />
  )
}
