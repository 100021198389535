import { assert } from '../../../utils/assert'
import { createEditorEventState } from '../../common/createEditorEventState'
import { ActionCreator } from '../../types'

export const createSelectScene: ActionCreator<'selectScene'> =
  ({ services, set }) =>
  async (id, method) => {
    set(({ view }) => {
      assert(view.type === 'main')

      if (view.selectedSceneId === id) {
        return { view }
      }

      const { design, designState, photos } = view

      services.trackEvent({
        type: 'CHANGE_SCENE',
        mode: view.view.type === 'edit' ? 'EDIT' : 'PREVIEW',
        kind: method,
        state: createEditorEventState({
          design,
          designState,
          photos,
          selectedSceneId: id,
        }),
      })

      let subview = view.view
      if (view.view.type === 'edit') {
        subview = {
          ...view.view,
          activeElementId: null,
          ui: null,
        }
      }

      return {
        view: {
          ...view,
          selectedSceneId: id,
          view: subview,
        },
      }
    })
  }
