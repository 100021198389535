import { Box, Flex } from '@moonpig/launchpad-components'
import { Dial } from '@moonpig/web-personalise-components'
import {
  DesignElementRef,
  DesignElementSticker,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback } from 'react'
import shallow from 'zustand/shallow'
import {
  STICKER_MAX_SCALE,
  STICKER_MIN_SCALE,
} from '../../../../../../../../constants'
import { getDefaultStickerSize } from '../../../../../../../../utils/getDefaultStickerSize'
import {
  getGestureChangeType,
  useAction,
  useView,
} from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { useElementDerived } from '../../../../../../selectors'

const RotationDial: FC<{
  elementRef: DesignElementRef<DesignElementSticker>
}> = ({ elementRef }) => {
  const t = useEditorLocaleText()
  const updateElementRef = useAction('updateElementRef')

  const elementRotation = useElementDerived(
    elementRef,
    ({ rotation }) => rotation,
  )

  const handleRotationChange = useCallback(
    (newRotationDeg: number, context: { last: boolean }) => {
      updateElementRef(
        elementRef,
        current => ({
          ...current,
          rotation: newRotationDeg,
        }),
        { changeType: getGestureChangeType(context) },
      )
    },
    [elementRef, updateElementRef],
  )

  return (
    <Dial
      id="rotation-dial"
      label={t('rotation_label')}
      getValueText={v => `${Math.round(v)}°`}
      minValue={-180}
      maxValue={180}
      unitsPerMark={1}
      pixelsPerMark={10}
      minorPerMajorMark={10}
      value={elementRotation}
      onChange={handleRotationChange}
    />
  )
}

const ZoomDial: FC<{
  elementRef: DesignElementRef<DesignElementSticker>
}> = ({ elementRef }) => {
  const t = useEditorLocaleText()
  const updateElementRef = useAction('updateElementRef')
  const elementWidth = useElementDerived(elementRef, ({ width }) => width)

  const { sceneWidth, sceneHeight } = useView(
    'main',
    view => {
      const scene = view.design.sceneById[elementRef.sceneId]

      return {
        sceneWidth: scene.width,
        sceneHeight: scene.height,
      }
    },
    shallow,
  )

  const [defaultSize] = getDefaultStickerSize(sceneWidth, sceneHeight)

  const scale = elementWidth / defaultSize

  const handleScaleChange = useCallback(
    (newScale: number, context: { last: boolean }) => {
      updateElementRef(
        elementRef,
        current => {
          const newWidth = defaultSize * newScale
          const newHeight = defaultSize * newScale
          const dx = (newWidth - current.width) * -0.5
          const dy = (newHeight - current.height) * -0.5

          return {
            ...current,
            x: current.x + dx,
            y: current.y + dy,
            width: newWidth,
            height: newHeight,
          }
        },
        { changeType: getGestureChangeType(context) },
      )
    },
    [defaultSize, elementRef, updateElementRef],
  )

  return (
    <Dial
      id="zoom-dial"
      label={t('zoom_label')}
      getValueText={v => `${v.toFixed(1)}x`}
      minValue={STICKER_MIN_SCALE}
      maxValue={STICKER_MAX_SCALE}
      unitsPerMark={0.1}
      pixelsPerMark={10}
      minorPerMajorMark={10}
      value={scale}
      onChange={handleScaleChange}
    />
  )
}

type AdjustProps = {
  elementRef: DesignElementRef<DesignElementSticker>
}

export const Adjust: FC<AdjustProps> = ({ elementRef }) => {
  return (
    <Flex flexDirection="column" width="100%">
      <Box mt={4}>
        <RotationDial elementRef={elementRef} />
      </Box>
      <Box mt={5}>
        <ZoomDial elementRef={elementRef} />
      </Box>
    </Flex>
  )
}
