import { Design, Services } from '@moonpig/web-personalise-editor-types'

const extractImages = (design: Design): Set<string> => {
  const imageUrls = new Set<string>()

  design.sceneIds.forEach(sceneId => {
    const scene = design.sceneById[sceneId]
    scene.elementIds.forEach(elementId => {
      const element = scene.elementById[elementId]

      switch (element.type) {
        case 'image-static': {
          imageUrls.add(element.url)
          if (element.maskUrl) {
            imageUrls.add(element.maskUrl)
          }
          break
        }
        case 'image-upload': {
          imageUrls.add(element.url)
          if (element.maskUrl) {
            imageUrls.add(element.maskUrl)
          }
          break
        }
        case 'image-collage': {
          imageUrls.add(element.placeholderImageUrl)
          break
        }
        case 'overlay-inlay': {
          imageUrls.add(element.imageUrl)
          break
        }
        case 'sticker': {
          imageUrls.add(element.sticker.url)
          break
        }
      }
    })
  })

  return imageUrls
}

type LoadedImage = {
  imageUrl: string
  imageElement: HTMLImageElement
}

export const loadDesignImages = async ({
  services,
  design,
  editedImageById,
}: {
  services: Services
  design: Design
  editedImageById: { [id in string]?: string }
}): Promise<LoadedImage[]> => {
  const editedImageUrls = Object.values(editedImageById).filter(
    (url): url is string => !!url,
  )

  const imageUrls = [...extractImages(design), ...editedImageUrls]

  return Promise.all(
    Array.from(imageUrls.values()).map(async imageUrl => {
      const imageElement = await services.loadImage(imageUrl)
      return { imageUrl, imageElement }
    }),
  )
}
