import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import {
  PAGE_SHADOW_COLOR,
  PAGE_SHADOW_SIZE,
  TRANSITION_DURATION,
} from './constants'

type PageProps = {
  transformOrigin: string
  translateX: number
  translateY: number
  rotateX: number
  rotateY: number
  animated: boolean
  transitionDuration?: number
}

const StyledPage = styled.div`
  position: absolute;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
`

export const Page: FC<PageProps> = ({
  transformOrigin,
  translateX,
  translateY,
  rotateX,
  rotateY,
  animated,
  transitionDuration = TRANSITION_DURATION,
  children,
}) => {
  return (
    <StyledPage
      style={{
        transformOrigin,
        transform: `translateX(${translateX}px) translateY(${translateY}px)`,
        transition: animated ? `transform ${transitionDuration}ms` : undefined,
      }}
    >
      <StyledPage
        style={{
          boxShadow: `0 0 ${PAGE_SHADOW_SIZE}px 0 ${PAGE_SHADOW_COLOR}`,
          transformOrigin,
          transform: `rotateY(${rotateY}deg) rotateX(${rotateX}deg)`,
          transition: animated
            ? `transform ${transitionDuration}ms`
            : undefined,
        }}
      >
        {children}
      </StyledPage>
    </StyledPage>
  )
}
