/* eslint-disable no-param-reassign */
import { fitText } from './fitText'
import { VerticalAlignment } from '../types'

type AutoSizeTextInput = {
  containerElement: HTMLElement
  textElement: HTMLElement
  maxFontSize: number
  verticalAlignment: VerticalAlignment
  onResize?: (event: { height: number }) => boolean
}

const reduceFontSizeToFit = ({
  containerElement,
  textElement,
  maxFontSize,
  verticalAlignment,
}: AutoSizeTextInput) => {
  const containerHeight = containerElement.clientHeight

  textElement.style.paddingTop = '0'
  textElement.style.height = 'auto'

  const fitTextResult = fitText({
    maxFontSize,
    fitCheck: fontSize => {
      textElement.style.fontSize = `${fontSize}px`
      const textHeight = textElement.clientHeight
      return textHeight <= containerHeight
    },
  })

  textElement.style.fontSize = `${fitTextResult.fontSize}px`

  const textHeight = textElement.clientHeight

  let offsetY: number
  switch (verticalAlignment) {
    case 'TOP': {
      offsetY = 0
      break
    }
    case 'MIDDLE': {
      offsetY = (containerHeight - textHeight) / 2
      break
    }
    case 'BOTTOM': {
      offsetY = containerHeight - textHeight
      break
    }
  }

  textElement.style.paddingTop = `${offsetY}px`
  textElement.style.height = '100%'
}

const resizeBoundsToFit = ({
  containerElement,
  textElement,
  maxFontSize,
  verticalAlignment,
  onResize,
}: AutoSizeTextInput) => {
  const containerHeight = containerElement.clientHeight

  textElement.style.fontSize = `${maxFontSize}px`
  textElement.style.paddingTop = '0'
  textElement.style.height = 'auto'

  const textHeight = textElement.clientHeight
  if (onResize && containerHeight > 0 && textHeight > 0) {
    const didResize = onResize({ height: textHeight / containerHeight })

    if (!didResize) {
      reduceFontSizeToFit({
        containerElement,
        textElement,
        maxFontSize,
        verticalAlignment,
      })
    }
  }
}

export const autoSizeText = ({
  containerElement,
  textElement,
  maxFontSize,
  verticalAlignment,
  onResize,
}: AutoSizeTextInput) => {
  if (onResize) {
    resizeBoundsToFit({
      containerElement,
      textElement,
      maxFontSize,
      verticalAlignment,
      onResize,
    })
  } else {
    reduceFontSizeToFit({
      containerElement,
      textElement,
      maxFontSize,
      verticalAlignment,
    })
  }
}
