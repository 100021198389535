import { Text } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { colorValue } from '@moonpig/launchpad-theme'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { useEditorLocaleText } from '../../../../../../text-localisation'

const UI_TOOLTIP_POINTER_SIZE = 30

const LINE_HEIGHT = 1.2

const StyledTextTooltipContainer = styled.div`
  ${s({ px: 5, py: 4, borderRadius: 2, lineHeight: LINE_HEIGHT })}
  position: absolute;
  display: flex;
  justify-content: center;
  background: ${colorValue('colorBackground06')};
  filter: drop-shadow(0px 0px 4px rgb(0 32 77 / 15%));
  pointer-events: all;
  text-align: center;
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: ${UI_TOOLTIP_POINTER_SIZE * 0.5}px solid;
    border-color: transparent transparent ${colorValue('colorBackground06')}
      transparent;
    top: ${-UI_TOOLTIP_POINTER_SIZE}px;
  }
`

export const LayerTextTooltip: FC = () => {
  const t = useEditorLocaleText()
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <StyledTextTooltipContainer>
        <Text
          lineHeight={LINE_HEIGHT}
          typography="bodySmall"
          alignContent="center"
        >
          {t('textbox_tooltip_message')}
        </Text>
      </StyledTextTooltipContainer>
    </div>
  )
}
