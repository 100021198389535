import { EqualityChecker } from 'zustand'
import { assert } from '../utils/assert'
import { useStore } from './context'
import { Actions, View, ViewMainViews, Views } from './types'

export const useAction = <TAction extends keyof Actions>(
  action: TAction,
): Actions[TAction] => {
  return useStore(store => {
    return store[action]
  })
}

export const useSession = () => {
  return useStore(({ session }) => {
    return session
  })
}

export const useEditorFeatures = () => {
  return useStore(({ features }) => {
    return features
  })
}

export const useView = <TType extends View['type'], TData>(
  viewType: TType,
  selector: (view: Views[TType]) => TData,
  equalityFn?: EqualityChecker<TData> | undefined,
): TData => {
  return useStore(({ view }) => {
    assert(
      view.type === viewType,
      `Expected view type '${viewType}' but got '${view.type}'`,
    )

    return selector(view as Views[TType])
  }, equalityFn)
}

export const useMainView = <TType extends keyof ViewMainViews, TData>(
  viewType: TType,
  selector: (view: ViewMainViews[TType], mainView: Views['main']) => TData,
  equalityFn?: EqualityChecker<TData> | undefined,
): TData => {
  return useView(
    'main',
    mainView => {
      const { view } = mainView
      assert(
        view.type === viewType,
        `Expected view type '${viewType}' but got '${view.type}'`,
      )

      return selector(view as ViewMainViews[TType], mainView)
    },
    equalityFn,
  )
}
