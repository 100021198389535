import { IconSystemPlusOutline } from '@moonpig/launchpad-assets'
import {
  AspectRatio,
  Box,
  SecondaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { screenReaderOnly, styled } from '@moonpig/launchpad-utils'
import React, { ChangeEvent, FC, useCallback, useRef } from 'react'
import { useEditorLocaleText } from '../../../../../../../text-localisation'

type PhotoInputProps = {
  onChange(files: Blob[]): void
}

const FILE_INPUT_ID = 'ed-image-options-file-input'

const StyledFileInput = styled.input`
  ${screenReaderOnly}
`

const StyledLabelText = styled(Text)`
  width: 100%;
  user-select: none;
`

const StyledFocus = styled.div`
  &:has(:focus-visible) {
    label,
    label:hover {
      ${s({ boxShadow: 5 })}
    }
  }
`

export const PhotoInput: FC<PhotoInputProps> = ({ onChange }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.currentTarget
      if (files && files.length > 0) {
        onChange(Array.from(files))
      }
      // Reset file input to default state
      // eslint-disable-next-line no-param-reassign
      event.currentTarget.value = ''
    },
    [onChange],
  )

  const t = useEditorLocaleText()

  return (
    <AspectRatio ratio={1}>
      <StyledFocus>
        <SecondaryButton
          {...{
            as: 'label',
            htmlFor: FILE_INPUT_ID,
          }}
          aria-label={t('toolbar_add_photos_label')}
          width="100%"
          height="100%"
          p={0}
        >
          <Box
            aria-hidden
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            width="100%"
          >
            <IconSystemPlusOutline width={36} height={36} />
            <StyledLabelText typography="ctaButton" lineHeight="1.25rem" mt={3}>
              {t('toolbar_add_photos_text')
                .split('\n')
                .map((line, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index}>{line}</div>
                ))}
            </StyledLabelText>
          </Box>
        </SecondaryButton>
        <StyledFileInput
          ref={inputRef}
          id={FILE_INPUT_ID}
          type="file"
          accept="image/png,image/jpg,image/jpeg"
          multiple
          data-testid="ed-image-upload-button"
          onChange={handleInputChange}
        />
      </StyledFocus>
    </AspectRatio>
  )
}
