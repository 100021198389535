import React, { FC } from 'react'
import { useMainView } from '../../../../../store'
import { ConfirmDeleteModal } from './components/ConfirmDeleteModal'
import { ConfirmExitModal } from './components/ConfirmExitModal'
import { ConfirmLayoutChangeModal } from './components/ConfirmLayoutChangeModal'
import { OnboardingModal } from './components/OnboardingModal'
import { InviteModal } from './components/InviteModal'

export const EditModal: FC = () => {
  const modal = useMainView('edit', view => view.modal)

  if (!modal) {
    return null
  }

  switch (modal.type) {
    case 'confirm-exit': {
      return <ConfirmExitModal />
    }
    case 'confirm-layout-change': {
      return <ConfirmLayoutChangeModal selectedLayout={modal.selectedLayout} />
    }
    case 'confirm-delete': {
      return <ConfirmDeleteModal elementRef={modal.elementRef} />
    }
    case 'onboarding': {
      return <OnboardingModal entries={modal.entries} />
    }
    case 'invite': {
      return <InviteModal />
    }
  }
}
