import { Vec2 } from '@moonpig/common-math'
import { Frame } from '../common/frame'
import { CollageGrid, CollageGridCellContent } from './grid'

export type CollageLayoutElement = {
  id: string
  content: CollageGridCellContent
  frame: Frame
}

export type CollageLayout = {
  size: Vec2
  elements: CollageLayoutElement[]
}

export const layoutGrid = ({
  grid,
  size,
  frameOverlap = 0,
}: {
  grid: CollageGrid
  size: Vec2
  frameOverlap?: number
}): CollageLayout => {
  const [width, height] = size
  const minSize = Math.min(width, height)
  const border = grid.border ?? {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    inner: 0,
  }
  const innerOffset = border.inner * minSize * 0.5

  const horizontalGutterPositions = grid.gutters.reduce<{
    [id: string]: number
  }>((acc, gutter) => {
    acc[gutter.id] = gutter.offset * height
    return acc
  }, {})

  const verticalGutterPositions = grid.gutters.reduce<{
    [id: string]: number
  }>((acc, gutter) => {
    acc[gutter.id] = gutter.offset * width
    return acc
  }, {})

  const elements = grid.cells.map<CollageLayoutElement>(
    ({ id, content, anchors }) => {
      const top = anchors.top
        ? horizontalGutterPositions[anchors.top] + innerOffset
        : border.top * minSize

      const bottom = anchors.bottom
        ? horizontalGutterPositions[anchors.bottom] - innerOffset
        : height - border.bottom * minSize

      const left = anchors.left
        ? verticalGutterPositions[anchors.left] + innerOffset
        : border.left * minSize

      const right = anchors.right
        ? verticalGutterPositions[anchors.right] - innerOffset
        : width - border.right * minSize

      const frame: Frame = {
        position: [left, top],
        size: [right - left + frameOverlap, bottom - top + frameOverlap],
      }

      return { id, content, frame }
    },
  )

  return { elements, size }
}
