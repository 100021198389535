import {
  Design,
  DesignElement,
  DesignElementTextPlaceholder,
  DesignElementTextPlain,
  DesignElementTextStyled,
  DesignLayout,
  Services,
} from '@moonpig/web-personalise-editor-types'
import { GetState, SetState } from 'zustand'
import { assert } from '../../../utils/assert'
import { performance } from '../../../utils/performance'
import { addNotification } from '../../common/addNotification'
import { createEditorEventState } from '../../common/createEditorEventState'
import { updateDesignElement } from '../../common/updateDesignElement'
import { uploadDesignImages } from '../../common/uploadDesignImages'
import { validateMissingRequired } from '../../common/validateMissingRequired'
import { ActionCreator, Actions, Store } from '../../types'
import { validateDefaultText } from './validateDefaultText'

const COMPOSITION_DISABLED_LAYOUTS = new Set<DesignLayout>([
  'CARD_LANDSCAPE',
  'CARD_PORTRAIT',
  'CARD_SQUARE',
  'MUG',
])

type ValidatedDesignElements =
  | DesignElementTextStyled
  | DesignElementTextPlain
  | DesignElementTextPlaceholder

const elementShouldBeValidated = (
  element: DesignElement,
): element is ValidatedDesignElements => {
  const validationElementTypes: DesignElement['type'][] = [
    'text-placeholder',
    'text-plain',
    'text-styled',
  ]

  return validationElementTypes.includes(element.type)
}

const updateValidElements = (
  design: Design,
  elements: { sceneId: string; inputElementId: string }[],
): Design => {
  let updatedDesign = { ...design }

  elements.forEach(({ sceneId, inputElementId }) => {
    const invalidElement =
      updatedDesign.sceneById[sceneId].elementById[inputElementId]

    /* istanbul ignore else */
    if (elementShouldBeValidated(invalidElement)) {
      updatedDesign = updateDesignElement(updatedDesign, {
        ...invalidElement,
        valid: false,
      })
    }
  })

  return updatedDesign
}

const loadPreview = async ({
  services,
  get,
  set,
}: {
  services: Services
  get: GetState<Store>
  set: SetState<Store>
}) => {
  const store = get()

  assert(store.view.type === 'main')

  const {
    design,
    designState,
    photos,
    selectedSceneId,
    canShowDefaultTextNotification,
  } = store.view

  services.trackEvent({
    type: 'PREVIEW',
    state: createEditorEventState({
      design,
      designState,
      photos,
      selectedSceneId,
    }),
  })

  const result = await services.saveDesignAndPreview(store.view.design, {
    composition: !COMPOSITION_DISABLED_LAYOUTS.has(design.layout),
  })

  const defaultTextWarning = validateDefaultText(design)
  const notificationsOnLoad =
    defaultTextWarning && canShowDefaultTextNotification
      ? addNotification([], {
          type: 'default-text-found',
          textKey: defaultTextWarning.textKey,
          firstDefaultElement: defaultTextWarning.firstDefaultElement,
        })
      : []

  switch (result.type) {
    case 'success': {
      set(({ view }) => {
        assert(view.type === 'main')

        return {
          view: {
            ...view,
            notifications: notificationsOnLoad,
            canShowDefaultTextNotification: false,
            view: {
              type: 'preview',
              readyToShow: false,
              previewImages: result.images,
            },
          },
        }
      })
      break
    }
    case 'error': {
      set(({ view }) => {
        assert(view.type === 'main')

        return {
          view: {
            ...view,
            view: {
              type: 'error-preview',
              canContinue: false,
            },
          },
        }
      })
      break
    }
    case 'profanity-error': {
      set(({ view }) => {
        assert(view.type === 'main')

        const firstInputError = result.elements[0]

        return {
          view: {
            ...view,
            design: updateValidElements(design, result.elements),
            notifications: addNotification(view.notifications, {
              type: 'profanity-found',
            }),
            selectedSceneId: firstInputError.sceneId,
            view: {
              type: 'edit',
              activeElementId: firstInputError.inputElementId,
              ui: null,
              modal: null,
              highlightInvalidElements: true,
            },
          },
        }
      })
      break
    }
    case 'preview-error': {
      services.trackEvent({ type: 'ERROR', kind: 'PREVIEW_LOAD' })

      set(({ view }) => {
        assert(view.type === 'main')

        return {
          view: {
            ...view,
            view: {
              type: 'error-preview',
              canContinue: true,
            },
          },
        }
      })
      break
    }
  }
}

export const createPreview: ActionCreator<'preview'> =
  ({ services, set, get }): Actions['preview'] =>
  async () => {
    const store = get()

    assert(store.view.type === 'main')

    const { firstInvalidElement } = validateMissingRequired(store.view.design)

    if (firstInvalidElement) {
      set(({ view }) => {
        assert(view.type === 'main')

        return {
          view: {
            ...view,
            notifications: addNotification(view.notifications, {
              type: 'missing-required',
              kind: firstInvalidElement.kind,
            }),
            selectedSceneId: firstInvalidElement.sceneId,
            view: {
              type: 'edit',
              activeElementId: null,
              ui: null,
              modal: null,
              highlightInvalidElements: true,
            },
          },
        }
      })

      return
    }

    performance.measureStart('editor-preview')

    set(({ view }) => {
      assert(view.type === 'main')

      const { design, photos } = view

      return {
        view: {
          ...view,
          design: uploadDesignImages({ services, design, photos }),
          notifications: [],
          selectedSceneId: design.sceneIds[0],
          view: { type: 'loading-preview' },
        },
      }
    })

    await loadPreview({ services, get, set })
  }
