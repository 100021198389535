import {
  DesignElementOverlayText,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import { assert } from '../../../utils/assert'
import { sanitizeInput } from '../../../utils/inputIsValid'
import { performance } from '../../../utils/performance'
import { addNotification } from '../../common/addNotification'
import { updateDesignElement } from '../../common/updateDesignElement'
import { ActionCreator, UpdateElementChangeType } from '../../types'
import { UpdateDesignContext, storeUpdateDesign } from '../../updaters/design'
import { storeUpdateMainView } from '../../updaters/main-view'
import { storeUpdateView } from '../../updaters/view'
import { getTextFragmentsChangeType } from '../../utils/getTextFragmentsChangeType'

const updateContextByUpdateElementChangeType: {
  [type in UpdateElementChangeType]: UpdateDesignContext
} = {
  default: { changeReason: 'element-changed' },
  minor: { changeReason: 'element-changed-minor' },
}

export const createGenerateSmartText: ActionCreator<'generateSmartText'> =
  ({ services, set, get }) =>
  async ({
    prompt,
    elementRef,
  }: {
    prompt: string
    elementRef: DesignElementRef
  }) => {
    performance.measureStart('editor-smart-text')

    const { view, features } = get()
    assert(view.type === 'main')

    set(() => {
      return {
        view: {
          ...view,
          smartTextByElementId: {
            [elementRef.id]: {
              type: 'loading',
            },
          },
        },
      }
    })

    const smartTextResult = await services.loadSmartText(prompt)
    const element =
      view.design.sceneById[elementRef.sceneId].elementById[elementRef.id]

    assert(element.type === 'overlay-text')

    switch (smartTextResult.type) {
      case 'limit-reached-error': {
        let { notifications } = view
        notifications = addNotification(notifications, {
          type: 'smart-text-limit-exceeded',
        })

        set(() => {
          return {
            view: {
              ...view,
              notifications,
              smartTextByElementId: {
                ...view.smartTextByElementId,
                [elementRef.id]: smartTextResult,
              },
              smartTextConfig: {
                ...view.smartTextConfig,
                remainingRequests: 0,
              },
            },
          }
        })
        break
      }
      case 'success': {
        set(initialStore => {
          let store = initialStore
          assert(
            store.view.type === 'main' &&
              store.view.view.type === 'edit' &&
              elementRef.type === 'overlay-text',
          )
          const smartText = features.enableEmojis
            ? smartTextResult.text
            : sanitizeInput(smartTextResult.text)

          store = storeUpdateDesign(
            store,
            updateContextByUpdateElementChangeType[
              getTextFragmentsChangeType(element)
            ],
            current =>
              updateDesignElement(current, {
                ...element,
                customisations: {
                  ...element.customisations,
                  text: smartText,
                  author: 'SMART_TEXT',
                },
              }),
          )

          store = storeUpdateView(store, 'main', () => {
            return {
              smartTextByElementId: {
                ...view.smartTextByElementId,
                [elementRef.id]: smartTextResult,
              },
            }
          })

          store = storeUpdateMainView(store, 'edit', () => {
            return {
              ui: {
                type: 'overlay-text' as const,
                elementRef:
                  elementRef as DesignElementRef<DesignElementOverlayText>,
                selectedMenuItem: null,
              },
            }
          })

          return store
        })
        break
      }
      default: {
        set(() => {
          return {
            view: {
              ...view,
              smartTextByElementId: {
                ...view.smartTextByElementId,
                [elementRef.id]: smartTextResult,
              },
            },
          }
        })
        break
      }
    }

    performance.measureEnd('editor-smart-text')
  }
