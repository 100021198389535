import React, { FC } from 'react'
import { PrimaryButton } from '@moonpig/launchpad-components'
import { screenReaderOnly, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'

const HANDWRITING_INPUT_ID = 'mp-ed-handwriting-input'

const StyledFileInput = styled.input`
  ${screenReaderOnly}
`

const StyledFocus = styled.div`
  &:has(:focus-visible) {
    label,
    label:hover {
      ${s({ boxShadow: 5 })}
    }
  }
`

export const HandwritingInput: FC = () => {
  const t = useEditorLocaleText()
  return (
    <StyledFocus>
      <PrimaryButton
        {...{ as: 'label', htmlFor: HANDWRITING_INPUT_ID }}
        width="100%"
        p={0}
      >
        {t('button_add_handwriting')}
      </PrimaryButton>
      <StyledFileInput
        id={HANDWRITING_INPUT_ID}
        type="file"
        accept="image/png,image/jpg,image/jpeg"
      />
    </StyledFocus>
  )
}
