import {
  IconAdjust,
  IconChevronDown,
  IconChevronUp,
  IconDelete,
  IconStickers,
} from '@moonpig/web-personalise-components'
import React, { FC, useCallback, useMemo } from 'react'
import shallow from 'zustand/shallow'
import { assert } from '../../../../../../../utils/assert'
import {
  UISticker,
  UIStickerMenuItem,
  useAction,
  useView,
} from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { useDeleteElement } from '../../../../useDeleteElement'
import { ToolbarItem, ToolbarMenu, ToolbarOrientation } from '../Toolbar/types'
import { ToolbarCommon } from '../ToolbarCommon'
import { StickerLibrary } from '../ToolbarDefault/StickerLibrary'
import { getLayerControlsState } from '../utils/elementLayerState'
import { Adjust } from './Adjust'

type ToolbarStickerProps = {
  onDone: () => void
  orientation: ToolbarOrientation
  ui: UISticker
}

export const ToolbarSticker: FC<ToolbarStickerProps> = ({
  onDone,
  orientation,
  ui,
}) => {
  const { selectedMenuItem } = ui

  const t = useEditorLocaleText()
  const setUI = useAction('setUI')
  const deleteElement = useDeleteElement()
  const reorderElement = useAction('reorderElement')

  const { forwardButtonDisabled, backwardButtonDisabled } = useView(
    'main',
    ({ design }) => getLayerControlsState(ui.elementRef, design),
    shallow,
  )

  const items = useMemo<ToolbarItem<UIStickerMenuItem>[]>(() => {
    return [
      {
        type: 'menu',
        id: 'stickers',
        label: t('toolbar_stickers_label'),
        ariaLabel: t('toolbar_stickers_label'),
        icon: <IconStickers />,
        disabled: false,
      },
      {
        type: 'menu',
        id: 'adjust',
        label: t('toolbar_sticker_adjust_label'),
        ariaLabel: t('toolbar_sticker_adjust_aria_label'),
        icon: <IconAdjust />,
        disabled: !ui.elementRef,
      },
      {
        type: 'button',
        id: 'forward',
        label: t('toolbar_move_to_front_label'),
        ariaLabel: t('toolbar_sticker_move_to_front_aria_label'),
        icon: <IconChevronUp />,
        disabled: forwardButtonDisabled,
      },
      {
        type: 'button',
        id: 'backward',
        label: t('toolbar_move_to_back_label'),
        ariaLabel: t('toolbar_sticker_move_to_back_aria_label'),
        icon: <IconChevronDown />,
        disabled: backwardButtonDisabled,
      },

      {
        type: 'button',
        id: 'delete',
        label: t('toolbar_sticker_delete_label'),
        ariaLabel: t('toolbar_sticker_delete_aria_label'),
        icon: <IconDelete />,
        disabled: !ui.elementRef,
      },
    ]
  }, [t, ui.elementRef, forwardButtonDisabled, backwardButtonDisabled])

  const handleSelectItem = useCallback(
    (itemId: UIStickerMenuItem | null) => {
      setUI(current => {
        assert(current !== null && current.type === 'sticker')
        switch (itemId) {
          case 'delete': {
            assert(current.elementRef !== null)
            deleteElement(current.elementRef)
            return null
          }
          case 'forward': {
            const { elementRef } = current

            assert(elementRef !== null)

            reorderElement({ elementRef, direction: 'to-front' })

            return {
              ...current,
              selectedMenuItem: null,
            }
          }
          case 'backward': {
            const { elementRef } = current

            assert(elementRef !== null)

            reorderElement({ elementRef, direction: 'to-back' })

            return {
              ...current,
              selectedMenuItem: null,
            }
          }
          default: {
            return {
              type: 'sticker',
              sceneId: current.sceneId,
              elementRef: current.elementRef,
              selectedMenuItem: itemId,
            }
          }
        }
      })
    },
    [setUI, deleteElement, reorderElement],
  )

  const stickersLibraryMenu = useMemo<ToolbarMenu>(
    () => ({
      title: t('toolbar_stickers_label'),
      content: <StickerLibrary />,
      closeLabel: t('toolbar_close_stickers_menu'),
    }),
    [t],
  )

  const menu = useMemo<ToolbarMenu | undefined>(() => {
    switch (selectedMenuItem) {
      case 'adjust': {
        assert(ui.elementRef !== null)
        return {
          title: t('toolbar_menu_adjust_sticker_label'),
          content: <Adjust elementRef={ui.elementRef} />,
          closeLabel: t('toolbar_menu_close_adjust_label'),
          drawerHeight: 'fit',
        }
      }
      case 'stickers': {
        return stickersLibraryMenu
      }
    }
  }, [ui.elementRef, stickersLibraryMenu, selectedMenuItem, t])

  return (
    <ToolbarCommon
      label={t('toolbar_sticker_toolbar_title')}
      orientation={orientation}
      items={items}
      selectedItemId={selectedMenuItem}
      onSelectItem={handleSelectItem}
      menu={menu}
      done={{
        label: t('toolbar_done_button'),
        ariaLabel: t('toolbar_sticker_toolbar_close_label'),
        onDone,
      }}
    />
  )
}
