import { DesignElementRef } from '@moonpig/web-personalise-editor-types'
import { assert } from '../../../utils/assert'
import { ActionCreator } from '../../types'

export const createResetSmartTextState: ActionCreator<'resetSmartTextState'> =
  ({ set, get }) =>
  async (elementRef: DesignElementRef) => {
    const { view } = get()
    assert(view.type === 'main')
    const smartTextByElementId = view.smartTextByElementId
    set(() => {
      return {
        view: {
          ...view,
          smartTextByElementId: {
            ...smartTextByElementId,
            [elementRef.id]: null,
          },
        },
      }
    })
  }
