import {
  IconSystemAlignBottom,
  IconSystemAlignCenter,
  IconSystemAlignJustify,
  IconSystemAlignLeft,
  IconSystemAlignMiddle,
  IconSystemAlignRight,
  IconSystemAlignTop,
} from '@moonpig/launchpad-assets'
import { Box, Flex, Grid, Text } from '@moonpig/launchpad-components'
import React, { FC, ReactNode } from 'react'
import { RadioButton } from '../RadioButton'
import { useLocaleText } from './TextAlignmentPicker.locale'
import { ScreenReaderOnly } from '../ScreenReaderOnly'
import { RadioGroup } from '../RadioGroup'

export type VerticalAlignmentID = 'BOTTOM' | 'MIDDLE' | 'TOP'
export type HorizontalAlignmentID = 'CENTER' | 'JUSTIFY' | 'LEFT' | 'RIGHT'

type AlignmentID = VerticalAlignmentID | HorizontalAlignmentID

type OnChangeHandler<A extends AlignmentID> = (alignment: A) => void

type AlignmentItem = {
  id: string
  label: string
  icon: ReactNode
}

type AlignmentOptionsProps<A extends AlignmentID = AlignmentID> = {
  name: string
  label: string
  alignments: AlignmentItem[]
  onAlignmentSelect(alignment: string): void
  selectedAlignment: A
}

const AlignmentOptions: FC<AlignmentOptionsProps> = ({
  name,
  label,
  alignments,
  onAlignmentSelect,
  selectedAlignment,
}) => {
  const labelId = `mp-ed-toolbar-${label.toLowerCase()}-alignment-label`

  return (
    <Grid
      role="group"
      aria-labelledby={labelId}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Flex justifyContent="flex-end" alignItems="center">
        <Text {...{ id: labelId }} typography="body" as="h5" mb={4}>
          {label}
        </Text>
      </Flex>
      <Box width={1} display="flex">
        <RadioGroup>
          <Grid gap={4}>
            {alignments.map(alignment => {
              const id = `${name}-${alignment.id}`
              return (
                <Box key={id}>
                  <RadioButton
                    id={id}
                    name={name}
                    onSelect={() => onAlignmentSelect(alignment.id)}
                    checked={selectedAlignment === alignment.id}
                  >
                    <ScreenReaderOnly>{alignment.label}</ScreenReaderOnly>
                    {alignment.icon}
                  </RadioButton>
                </Box>
              )
            })}
          </Grid>
        </RadioGroup>
      </Box>
    </Grid>
  )
}

type TextAlignmentPickerProps = {
  selectedHorizontalAlignment: HorizontalAlignmentID
  onHorizontalChange: OnChangeHandler<HorizontalAlignmentID>
  selectedVerticalAlignment?: VerticalAlignmentID | null
  onVerticalChange?: OnChangeHandler<VerticalAlignmentID> | null
}

export const TextAlignmentPicker: FC<TextAlignmentPickerProps> = ({
  selectedVerticalAlignment,
  onHorizontalChange,
  selectedHorizontalAlignment,
  onVerticalChange,
}) => {
  const t = useLocaleText()

  const horizontalAlignments: AlignmentItem[] = [
    {
      id: 'LEFT',
      label: t('horizontal_alignment_left_label'),
      icon: <IconSystemAlignLeft width="28px" height="28px" />,
    },
    {
      id: 'CENTER',
      label: t('horizontal_alignment_center_label'),
      icon: <IconSystemAlignCenter width="28px" height="28px" />,
    },
    {
      id: 'RIGHT',
      label: t('horizontal_alignment_right_label'),
      icon: <IconSystemAlignRight width="28px" height="28px" />,
    },
    {
      id: 'JUSTIFY',
      label: t('horizontal_alignment_justify_label'),
      icon: <IconSystemAlignJustify width="28px" height="28px" />,
    },
  ]

  const verticalAlignments: AlignmentItem[] = [
    {
      id: 'TOP',
      label: t('vertical_alignment_top_label'),
      icon: <IconSystemAlignTop width="28px" height="28px" />,
    },
    {
      id: 'MIDDLE',
      label: t('vertical_alignment_middle_label'),
      icon: <IconSystemAlignMiddle width="28px" height="28px" />,
    },
    {
      id: 'BOTTOM',
      label: t('vertical_alignment_bottom_label'),
      icon: <IconSystemAlignBottom width="28px" height="28px" />,
    },
  ]

  return (
    <>
      <Box>
        <AlignmentOptions
          name="mp-ed-toolbar-horizontal-alignment-radio"
          label={t('alignment_option_horizontal')}
          alignments={horizontalAlignments}
          onAlignmentSelect={onHorizontalChange}
          selectedAlignment={selectedHorizontalAlignment}
        />
      </Box>
      {selectedVerticalAlignment && onVerticalChange && (
        <Box mt={6}>
          <AlignmentOptions
            name="mp-ed-toolbar-vertical-alignment-radio"
            label={t('alignment_option_vertical')}
            alignments={verticalAlignments}
            onAlignmentSelect={onVerticalChange}
            selectedAlignment={selectedVerticalAlignment}
          />
        </Box>
      )}
    </>
  )
}
