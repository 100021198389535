import { TextColor, TextColorPicker } from '@moonpig/web-personalise-components'
import {
  DesignElementRef,
  DesignElementTextStyled,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useMemo } from 'react'
import { useAction } from '../../../../../../../../store'
import { useElementRef } from '../../../../../../selectors'

type ElementTextColorPickerProps = {
  elementRef: DesignElementRef<DesignElementTextStyled>
}

export const ElementTextColorPicker: FC<ElementTextColorPickerProps> = ({
  elementRef,
}) => {
  const element = useElementRef(elementRef)
  const updateElement = useAction('updateElement')

  const handleSelect = useCallback(
    (colorId: string) => {
      updateElement({
        ...element,
        customisations: {
          ...element.customisations,
          colorId,
        },
      })
    },
    [element, updateElement],
  )

  const colours: TextColor[] = useMemo(
    () =>
      element.availableColorIds.map(colorId => {
        const { name, value } = element.availableColorById[colorId]

        return {
          id: colorId,
          name,
          value,
        }
      }),
    [element.availableColorIds, element.availableColorById],
  )

  return (
    <TextColorPicker
      colours={colours}
      onSelect={handleSelect}
      selectedColourId={element.customisations.colorId}
    />
  )
}
