import {
  Transform2d,
  Vec2,
  clamp,
  degToRad,
  radToDeg,
} from '@moonpig/common-math'
import { TransformContext, useTransform } from '@moonpig/use-transform'
import { InteractiveElementIndicator } from '@moonpig/web-personalise-components'
import {
  DesignElement,
  DesignElementOverlayText,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback } from 'react'
import { useCamera } from '../../../../../../../camera'
import { assert } from '../../../../../../../utils/assert'
import {
  getGestureChangeType,
  useAction,
  useMainView,
  useView,
} from '../../../../../../../store'
import { useElementRef } from '../../../../../selectors'
import { useActiveKeyboardElementRef } from '../../../../selectors'
import { InteractiveElementProps } from '../../types'
import { InteractiveElementCommon } from '../InteractiveElementCommon'
import { InteractiveElementTransformable } from '../InteractiveElementTransformable'
import { ResizeSideHandle } from '../InteractiveElementTransformable/components/ResizeSideHandle'

export const ElementOverlayTextInteractive: FC<
  InteractiveElementProps<DesignElementOverlayText>
> = ({ elementRef, title, sceneX, sceneY, sceneWidth, sceneHeight }) => {
  const setUI = useAction('setUI')
  const element = useElementRef(elementRef)
  const defaultTextStyle = useView('main', view => view.design.defaultTextStyle)
  const updateElementRef = useAction('updateElementRef')
  const { inverseScale, toWorldPoint, domBounds } = useCamera()
  const activeElementId = useMainView('edit', view => view.activeElementId)

  assert(element.type === 'overlay-text' && defaultTextStyle !== undefined)

  const activeKeyboardElementRef = useActiveKeyboardElementRef()
  const isEditing = elementRef.id === activeKeyboardElementRef?.id
  const isEmpty = element.customisations.text.trim().length === 0
  const isSelected = activeElementId === element.id

  const defaultWidth = sceneWidth * 0.9
  const defaultHeight = defaultTextStyle.fontSize

  const minWidth = sceneWidth * 0.2
  const minHeight = sceneHeight * 0.05

  const minScale: Vec2 = [minWidth / defaultWidth, minHeight / defaultHeight]
  const maxScale: Vec2 = [sceneWidth / defaultWidth, Infinity]

  const handleSelect = useCallback(() => {
    setUI({ type: 'overlay-text', elementRef, selectedMenuItem: 'keyboard' })
  }, [elementRef, setUI])

  const selectedScene = useView(
    'main',
    view => view.design.sceneById[view.selectedSceneId],
  )

  const hasFragments = element.fragmentsState !== null

  const setValue = useCallback(
    (transform: Transform2d, context: TransformContext) => {
      const width = transform.scale[0] * defaultWidth
      const height = transform.scale[1] * defaultHeight

      updateElementRef(
        elementRef,
        current => {
          return {
            ...current,
            x: clamp(
              transform.position[0] - width * 0.5,
              -width * 0.5,
              selectedScene.width - width * 0.5,
            ),
            y: clamp(
              transform.position[1] - height * 0.5,
              -height * 0.5,
              selectedScene.height - height * 0.5,
            ),
            width,
            height,
            rotation: radToDeg(transform.rotation),
            fragmentsState: current.fragmentsState && {
              ...current.fragmentsState,
              version: current.fragmentsState.version + (context.last ? 1 : 0),
              resizingWidth: !context.last,
            },
          }
        },
        { changeType: hasFragments ? 'minor' : getGestureChangeType(context) },
      )
    },
    [
      defaultHeight,
      defaultWidth,
      elementRef,
      hasFragments,
      selectedScene.height,
      selectedScene.width,
      updateElementRef,
    ],
  )

  const rotation = degToRad(element.rotation)
  const x = element.x + element.width * 0.5
  const y = element.y + element.height * 0.5
  const scale: Vec2 = [
    element.width / defaultWidth,
    element.height / defaultHeight,
  ]

  const transform = useTransform({
    value: {
      position: [x, y],
      rotation,
      scale,
    },
    setValue,
    options: {
      minScale,
      maxScale,
      uniformScale: true,
    },
  })

  const transformPoint = useCallback(
    (screenPoint: Vec2): Vec2 => {
      const screenX = screenPoint[0] - domBounds.x
      const screenY = screenPoint[1] - domBounds.y

      const worldPoint = toWorldPoint([screenX, screenY])
      return [worldPoint[0] - sceneX, worldPoint[1] - sceneY]
    },
    [domBounds.x, domBounds.y, sceneX, sceneY, toWorldPoint],
  )

  const resizeProps = {
    id: element.id,
    transform,
    disabled: Boolean(!isSelected || isEditing),
    elementWidth: defaultWidth,
    elementHeight: defaultHeight,
    transformPoint,
    screenScale: inverseScale,
  }

  const transformElement = useCallback(
    (
      currentElement: DesignElement,
      updatedElement: DesignElement,
    ): DesignElement => {
      assert(updatedElement.type === 'overlay-text')

      const percentChange = updatedElement.width / currentElement.width

      return {
        ...updatedElement,
        customisations: {
          ...updatedElement.customisations,
          fontSize: updatedElement.customisations.fontSize * percentChange,
        },
      }
    },
    [],
  )

  const indicator: InteractiveElementIndicator =
    isEmpty && !isEditing ? 'ADD_TEXT' : 'NONE'

  const isDimmed = !isSelected

  if (element.fixed) {
    return (
      <InteractiveElementCommon
        border="ALWAYS"
        isDimmed={isDimmed}
        indicator={indicator}
        title={title}
        sceneId={elementRef.sceneId}
        sceneWidth={sceneWidth}
        sceneHeight={sceneHeight}
        element={element}
        onSelect={handleSelect}
      />
    )
  }

  return (
    <InteractiveElementTransformable
      title={title}
      sceneId={elementRef.sceneId}
      sceneX={sceneX}
      sceneY={sceneY}
      sceneWidth={sceneWidth}
      sceneHeight={sceneHeight}
      element={element}
      defaultWidth={defaultWidth}
      defaultHeight={defaultHeight}
      minScale={minScale}
      maxScale={maxScale}
      isEditing={isEditing}
      isDimmed={isDimmed}
      border="ALWAYS"
      indicator={indicator}
      enableDeleteShortcut={false}
      enableUniformScaling
      onSelect={handleSelect}
      transformElement={transformElement}
      handles={
        <>
          <ResizeSideHandle {...resizeProps} side="left" />
          <ResizeSideHandle {...resizeProps} side="right" />
        </>
      }
    />
  )
}
