import React, {
  createContext,
  FC,
  RefObject,
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
} from 'react'

type FocusOn = (id: string) => void

type FocusManagerContext = {
  focusRefs: { [id in string]?: RefObject<HTMLElement> }
}

const focusManagerContext = createContext<FocusManagerContext>({
  focusRefs: {},
})

export const FocusManagerProvider: FC = ({ children }) => {
  const value = useRef<FocusManagerContext>({ focusRefs: {} })

  return (
    <focusManagerContext.Provider value={value.current}>
      {children}
    </focusManagerContext.Provider>
  )
}

export const useFocusRef = <TElement extends HTMLElement>(
  id: string,
): RefObject<TElement> => {
  const ref = useRef<TElement>(null)
  const { focusRefs } = useContext(focusManagerContext)

  useLayoutEffect(() => {
    focusRefs[id] = ref

    return () => {
      delete focusRefs[id]
    }
  }, [focusRefs, id])

  return ref
}

export const useFocusOn = (): FocusOn => {
  const { focusRefs } = useContext(focusManagerContext)

  const focusOn = useCallback<FocusOn>(
    id => {
      const ref = focusRefs[id]

      if (ref?.current) {
        ref.current.focus({ preventScroll: true })
      }
    },
    [focusRefs],
  )

  return focusOn
}
