import {
  DesignElement,
  DesignElementRefInner,
} from '@moonpig/web-personalise-editor-types'
import { assert } from '../../../utils/assert'
import { updateDesignElement } from '../../common/updateDesignElement'
import {
  ActionContext,
  ActionCreator,
  UpdateElementChangeType,
} from '../../types'
import { storeUpdateDesign } from '../../updaters/design'

const resetPendingEditedImage = (element: DesignElement): DesignElement => {
  switch (element.type) {
    case 'overlay-image': {
      return {
        ...element,
        customisations: { ...element.customisations, pendingEditedImage: null },
      }
    }
    default: {
      return element
    }
  }
}

const updateElementCommon = ({
  context,
  element,
  options: { changeType = 'default' } = {},
}: {
  context: ActionContext
  element: DesignElement
  options?: { changeType?: UpdateElementChangeType }
}) => {
  const { set } = context
  const resetElement = resetPendingEditedImage(element)

  set(storeInitial => {
    let store = storeInitial

    store = storeUpdateDesign(
      store,
      {
        changeReason:
          changeType === 'minor' ? 'element-changed-minor' : 'element-changed',
      },
      design => {
        let updatedDesign = updateDesignElement(design, resetElement)

        if (element.type === 'overlay-text') {
          updatedDesign = {
            ...updatedDesign,
            defaultTextStyle: updatedDesign.defaultTextStyle && {
              ...updatedDesign.defaultTextStyle,
              colorId: element.customisations.colorId,
              fontFaceId: element.customisations.fontFaceId,
            },
          }
        }

        return updatedDesign
      },
    )

    return store
  })
}

export const createUpdateElement: ActionCreator<'updateElement'> =
  context => (element, options) => {
    updateElementCommon({ context, element, options })
  }

export const createUpdateElementRef: ActionCreator<'updateElementRef'> =
  context => (elementRef, updater, options) => {
    const { get } = context
    const store = get()

    assert(store.view.type === 'main')

    const elementCurrent =
      store.view.design.sceneById[elementRef.sceneId].elementById[elementRef.id]

    const element = updater(
      elementCurrent as DesignElementRefInner<typeof elementRef>,
    )

    updateElementCommon({ context, element, options })
  }
