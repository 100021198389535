import { getClippedBorder } from '@moonpig/web-personalise-collage'
import { TextColorPicker } from '@moonpig/web-personalise-components'
import {
  DesignElementImageCollage,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useMemo } from 'react'
import { assert } from '../../../../../../../../utils/assert'
import { getClipBounds } from '../../../../../../../../utils/getClipBounds'
import { useAction, useView } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { useElementRef, useScene } from '../../../../../../selectors'

const BORDER_SIZE_PERCENT = 0.02

type BorderColor = { id: string; name: string; value: string }

const useBorderColors = (): BorderColor[] => {
  const t = useEditorLocaleText()

  const borderColors = useMemo<BorderColor[]>(
    () => [
      { id: 'none', name: t('none'), value: '' },
      { id: 'white', name: t('color_white'), value: '#ffffff' },
      { id: 'black', name: t('color_black'), value: '#000000' },
      { id: 'pink', name: t('color_pink'), value: '#ffd3db' },
      { id: 'blue', name: t('color_light_blue'), value: '#ccddf4' },
      { id: 'green', name: t('color_light_green'), value: '#e6f1e9' },
      { id: 'yellow', name: t('color_light_yellow'), value: '#ffefb7' },
    ],
    [t],
  )

  return borderColors
}

type ImageCollageBorderPickerProps = {
  elementRef: DesignElementRef<DesignElementImageCollage>
}

export const ImageCollageBorderPicker: FC<ImageCollageBorderPickerProps> = ({
  elementRef,
}) => {
  const borderColors = useBorderColors()
  const updateElement = useAction('updateElement')
  const trackEvent = useAction('trackEvent')
  const element = useElementRef(elementRef)
  const productKey = useView('main', view => view.design.productKey)

  const customisationsGrid = element.customisations.grid
  assert(customisationsGrid !== null)

  const scene = useScene(elementRef.sceneId)

  const clipBounds = getClipBounds({
    element,
    sceneWidth: scene.width,
    sceneHeight: scene.height,
  })

  const border = getClippedBorder({
    clipBounds,
    size: [element.width, element.height],
    baseSize: BORDER_SIZE_PERCENT,
  })

  const handleSelectColor = useCallback(
    (selectedColorId: string) => {
      const color = borderColors.find(other => other.id === selectedColorId)
      assert(element.customisations.grid !== null)
      assert(color !== undefined)
      updateElement({
        ...element,
        customisations: {
          ...element.customisations,
          pendingEditedImage: null,
          grid: {
            ...element.customisations.grid,
            background: color.id === 'none' ? null : { color: color.value },
            border: color.id === 'none' ? null : border,
          },
        },
      })

      trackEvent({
        type: 'SELECT_CONTENT',
        kind: 'COLLAGE_BORDER_COLOR',
        productKey,
      })
    },
    [border, borderColors, element, productKey, trackEvent, updateElement],
  )

  const selectedColourId = borderColors.find(
    color => color.value === customisationsGrid.background?.color,
  )

  return (
    <TextColorPicker
      onSelect={handleSelectColor}
      colours={borderColors}
      selectedColourId={selectedColourId ? selectedColourId.id : 'none'}
    />
  )
}
