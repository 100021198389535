import { Design, DesignElement, DesignScene } from '../design'
import { StoredDesign, StoredDesignElement, StoredDesignScene } from './types'
import { mapRecord } from './utils'

const convertDesignElementToStoredDesignElement = (
  element: DesignElement,
): StoredDesignElement | null => {
  switch (element.type) {
    case 'image-dynamic': {
      return {
        ...element,
        customisations: {
          textParts: element.customisations.textParts,
        },
      }
    }
    case 'image-upload': {
      return {
        ...element,
        customisations: {
          editedImage: element.customisations.editedImage,
          sourceImage: element.customisations.sourceImage,
        },
      }
    }
    case 'image-collage': {
      return {
        ...element,
        customisations: {
          editedImage: element.customisations.editedImage,
          grid: element.customisations.grid,
        },
      }
    }
    case 'overlay-image': {
      return {
        ...element,
        customisations: {
          imageKind: 'photo',
          fixed: false,
          editedImage: element.customisations.editedImage,
          sourceImage: element.customisations.sourceImage,
        },
      }
    }
    case 'overlay-text': {
      const { fragmentsState, ...elementRest } = element

      return {
        ...elementRest,
        fragments: fragmentsState && fragmentsState.fragments,
      }
    }
    case 'media': {
      if (element.state.type !== 'uploaded') {
        return null
      }

      return {
        type: 'media',
        kind: element.kind,
        sceneId: element.sceneId,
        id: element.id,
        x: element.x,
        y: element.y,
        width: element.width,
        height: element.height,
        rotation: element.rotation,
        mediaId: element.state.mediaId,
        mediaToken: element.state.mediaToken,
      }
    }
    default: {
      return element
    }
  }
}

const convertDesignSceneToStoredDesignScene = (
  scene: DesignScene,
): StoredDesignScene => {
  const { elementIds, elementById } = scene.elementIds.reduce<{
    elementIds: StoredDesignScene['elementIds']
    elementById: StoredDesignScene['elementById']
  }>(
    (acc, elementId) => {
      const designElement = scene.elementById[elementId]
      const storedDesignElement =
        convertDesignElementToStoredDesignElement(designElement)

      if (storedDesignElement) {
        acc.elementIds.push(storedDesignElement.id)
        acc.elementById[storedDesignElement.id] = storedDesignElement
      }

      return acc
    },
    { elementIds: [], elementById: {} },
  )

  return {
    id: scene.id,
    title: scene.title,
    width: scene.width,
    height: scene.height,
    renderMethod: scene.renderMethod,
    availableFeatures: scene.availableFeatures,
    elementIds,
    elementById,
    layout: scene.layout,
    layoutOriginal: scene.layoutOriginal,
    placements: scene.placements,
  }
}

export const convertDesignToStoredDesign = (design: Design): StoredDesign => {
  return {
    id: design.id,
    token: design.token,
    version: design.version,
    productKey: design.productKey,
    layout: design.layout,
    sceneIds: design.sceneIds,
    sceneById: mapRecord(
      design.sceneById,
      convertDesignSceneToStoredDesignScene,
    ),
    usedFeatures: design.usedFeatures,
    availableStickerSets: design.availableStickerSets,
    defaultTextStyle: design.defaultTextStyle,
    availableTextStyles: design.availableTextStyles,
    limits: design.limits,
  }
}
