import {
  IconSystemChevronLeft,
  IconSystemChevronRight,
} from '@moonpig/launchpad-assets'
import { IconButton, focusIndicatorStyles } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC, MouseEvent, useCallback } from 'react'
import { useLocaleText } from './Minimap.locale'

type NavButtonProps = {
  onClick: () => void
  direction: string
  disabled: boolean
}

const StyledIconButton = styled(IconButton)`
  ${focusIndicatorStyles}
  ${s({ borderRadius: 2 })}
`

export const NavButton: FC<NavButtonProps> = ({
  onClick,
  direction,
  disabled,
}) => {
  const t = useLocaleText()

  const label = t(
    direction === 'previous' ? 'nav_button_previous' : 'nav_button_next',
  )
  const icon =
    direction === 'previous' ? IconSystemChevronLeft : IconSystemChevronRight
  const rel = direction === 'previous' ? 'prev' : 'next'

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      onClick()
    },
    [onClick],
  )

  return (
    <StyledIconButton
      onClick={handleClick}
      disabled={disabled}
      label={label}
      icon={icon}
      {...{
        rel,
      }}
    />
  )
}
