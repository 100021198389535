import { RegisterResources } from '@moonpig/renderer-react'
import {
  AddPhotoResult,
  Design,
  Photo,
  Services,
} from '@moonpig/web-personalise-editor-types'
import uuid from 'uuid/v4'
import { assert } from '../../../utils/assert'
import { performance } from '../../../utils/performance'
import { loadPhotoImages } from '../../common/loadPhotoImages'
import { removePhotos } from '../../common/removePhotos'
import { ActionCreator, PhotoState, Photos } from '../../types'

export const MAX_ADDED_PHOTOS = 50

const addPhoto = async ({
  services,
  registerResources,
  id,
  file,
}: {
  services: Services
  registerResources: RegisterResources
  id: string
  file: Blob
}): Promise<AddPhotoResult> => {
  const result = await services.addPhoto(id, file)

  if (result.type === 'success') {
    const loadedImages = await loadPhotoImages({
      services,
      photos: [result.photo],
    })

    loadedImages.forEach(({ imageUrl, imageElement }) => {
      registerResources.registerImage({ imageUrl, imageElement })
    })
  }

  return result
}

const addPhotos = (photos: Photos, photosToAdd: PhotoState[]): Photos => {
  return photosToAdd.reduceRight<Photos>((acc, photo) => {
    return {
      photoIds: [photo.id, ...acc.photoIds],
      photoById: { ...acc.photoById, [photo.id]: photo },
    }
  }, photos)
}

const isPhotoInUse = (design: Design, photoId: string): boolean => {
  // eslint-disable-next-line no-restricted-syntax
  for (const sceneId of design.sceneIds) {
    const scene = design.sceneById[sceneId]
    // eslint-disable-next-line no-restricted-syntax
    for (const elementId of scene.elementIds) {
      const element = scene.elementById[elementId]

      switch (element.type) {
        case 'image-upload':
        case 'overlay-image': {
          const inUse =
            element.customisations.sourceImage !== null &&
            element.customisations.sourceImage.id === photoId

          if (inUse) {
            return true
          }
        }
      }
    }
  }

  return false
}

export const createAddPhotos: ActionCreator<'addPhotos'> =
  ({ services, set, get, registerResources }) =>
  async (files, onPhotosAdded) => {
    performance.measureStart('editor-add-photos')

    const filesWithIds = files
      .slice(0, MAX_ADDED_PHOTOS)
      .map(file => ({ id: uuid(), file }))

    const newPhotos = filesWithIds
      .map<PhotoState>(({ id }) => ({ type: 'loading', id }))
      .reverse()

    const store = get()

    assert(store.view.type === 'main')

    const { design, photos } = store.view

    const photoCount = photos.photoIds.length

    const photosOverLimit = photoCount + filesWithIds.length - MAX_ADDED_PHOTOS

    const photoIdsToDelete =
      photosOverLimit > 0
        ? photos.photoIds
            .filter(photoId => !isPhotoInUse(design, photoId))
            .slice(-photosOverLimit)
        : []

    set(({ view }) => {
      assert(view.type === 'main')

      return {
        view: {
          ...view,
          photos: addPhotos(
            removePhotos(view.photos, photoIdsToDelete),
            newPhotos,
          ),
        },
      }
    })

    const resultsPromise = Promise.all(
      filesWithIds.map(async ({ id, file }) => ({
        id,
        result: await addPhoto({ services, registerResources, id, file }),
      })),
    )

    const deletePromise = Promise.all(
      photoIdsToDelete.map(id => services.removePhoto(id)),
    )

    const [results] = await Promise.all([resultsPromise, deletePromise])

    results.forEach(({ result }) => {
      services.trackEvent({
        type: 'PHOTO_UPLOADED',
        kind: result.type === 'success' ? 'SUCCESS' : 'ERROR',
        productKey: design.productKey,
      })
    })

    const resultsById = results.reduce<{
      [id: string]: AddPhotoResult
    }>((acc, { id, result }) => {
      acc[id] = result
      return acc
    }, {})

    set(({ view }) => {
      assert(view.type === 'main' && view.view.type === 'edit')

      const loadedPhotos = view.photos.photoIds.reduce<Photos>(
        (acc, id) => {
          const result = resultsById[id]

          if (!result) {
            acc.photoIds.push(id)
            acc.photoById[id] = view.photos.photoById[id]
          } else if (result && result.type === 'success') {
            acc.photoIds.push(id)
            acc.photoById[id] = {
              type: 'loaded',
              id: result.photo.id,
              photo: result.photo,
            }
          }

          return acc
        },
        { photoIds: [], photoById: {} },
      )

      return {
        view: {
          ...view,
          photos: loadedPhotos,
        },
      }
    })

    const { view } = get()

    assert(view.type === 'main')

    const addedPhotos = view.photos.photoIds.reduce<Photo[]>((acc, id) => {
      const result = resultsById[id]

      if (result && result.type === 'success') {
        acc.push(result.photo)
      }

      return acc
    }, [])

    if (addedPhotos.length > 0) {
      onPhotosAdded(addedPhotos)
    }

    performance.measureEnd('editor-add-photos')
  }
