import { Box, Flex } from '@moonpig/launchpad-components'
import { updateGridCell } from '@moonpig/web-personalise-collage'
import { Dial } from '@moonpig/web-personalise-components'
import {
  DesignElementImageCollage,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback } from 'react'
import { MAX_SCALE_PHOTO } from '../../../../../../../../constants'
import { assert } from '../../../../../../../../utils/assert'
import { getGestureChangeType, useAction } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { useElementRef } from '../../../../../../selectors'

const ZoomDial: FC<{
  initialScale: number
  scale: number
  onChange: (scale: number, context: { last: boolean }) => void
}> = ({ initialScale, scale, onChange }) => {
  const t = useEditorLocaleText()
  const zoom = scale / initialScale

  const handleChange = useCallback(
    (value: number, context: { last: boolean }) => {
      onChange(value * initialScale, context)
    },
    [initialScale, onChange],
  )

  return (
    <Dial
      id="zoom-dial"
      label={t('zoom_label')}
      getValueText={v => `${v.toFixed(1)}x`}
      minValue={1}
      maxValue={MAX_SCALE_PHOTO}
      unitsPerMark={0.1}
      pixelsPerMark={10}
      minorPerMajorMark={10}
      value={zoom}
      onChange={handleChange}
    />
  )
}

type ImageCollageCellAdjustProps = {
  elementRef: DesignElementRef<DesignElementImageCollage>
  cellId: string
}

export const ImageCollageCellAdjust: FC<ImageCollageCellAdjustProps> = ({
  elementRef,
  cellId,
}) => {
  const element = useElementRef(elementRef)
  const updateElementRef = useAction('updateElementRef')

  const { grid } = element.customisations

  assert(grid !== null)

  const cell = grid.cells.find(c => c.id === cellId)

  assert(cell !== undefined)
  assert(cell.content.type === 'image')

  const handleScaleChange = useCallback(
    (newScale: number, context: { last: boolean }) => {
      updateElementRef(
        elementRef,
        current => {
          return {
            ...current,
            customisations: {
              ...current.customisations,
              pendingEditedImage: null,
              grid: updateGridCell(grid, cellId, {
                content: { ...cell.content, scale: newScale },
              }),
            },
          }
        },
        { changeType: getGestureChangeType(context) },
      )
    },
    [cell.content, cellId, elementRef, grid, updateElementRef],
  )

  return (
    <Flex flexDirection="column" width="100%">
      <Box mt={4}>
        <ZoomDial
          initialScale={1}
          scale={cell.content.scale}
          onChange={handleScaleChange}
        />
      </Box>
    </Flex>
  )
}
