import {
  Transform2d,
  Vec2,
  transform2dRotateAndScaleAroundPoint,
} from '@moonpig/common-math'
import { Box, Flex } from '@moonpig/launchpad-components'
import { Dial } from '@moonpig/web-personalise-components'
import {
  DesignElementImageUpload,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useMemo } from 'react'
import { MAX_SCALE_PHOTO } from '../../../../../../../../constants'
import { assert } from '../../../../../../../../utils/assert'
import { getInitialScale } from '../../../../../../../../utils/getInitialScale'
import {
  getGestureChangeType,
  useAction,
  useView,
} from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { useElementRef } from '../../../../../../selectors'

const CENTER_OF_APERTURE: Vec2 = [0, 0]

const RotationDial: FC<{
  rotation: number
  onChange: (rotation: number, context: { last: boolean }) => void
}> = ({ rotation, onChange }) => {
  const t = useEditorLocaleText()
  const rotationDegrees = (rotation / Math.PI) * 180

  const handleChange = useCallback(
    (value: number, context: { last: boolean }) => {
      onChange((value * Math.PI) / 180, context)
    },
    [onChange],
  )

  return (
    <Dial
      id="rotation-dial"
      label={t('rotation_label')}
      getValueText={v => `${Math.round(v)}°`}
      minValue={-180}
      maxValue={180}
      unitsPerMark={1}
      pixelsPerMark={10}
      minorPerMajorMark={10}
      value={rotationDegrees}
      onChange={handleChange}
    />
  )
}

const ZoomDial: FC<{
  initialScale: number
  scale: number
  onChange: (scale: number, context: { last: boolean }) => void
}> = ({ initialScale, scale, onChange }) => {
  const t = useEditorLocaleText()
  const zoom = scale / initialScale

  const handleChange = useCallback(
    (value: number, context: { last: boolean }) => {
      onChange(value * initialScale, context)
    },
    [initialScale, onChange],
  )

  return (
    <Dial
      id="zoom-dial"
      label={t('zoom_label')}
      getValueText={v => `${v.toFixed(1)}x`}
      minValue={1}
      maxValue={MAX_SCALE_PHOTO}
      unitsPerMark={0.1}
      pixelsPerMark={10}
      minorPerMajorMark={10}
      value={zoom}
      onChange={handleChange}
    />
  )
}

const ImageUploadAdjustContent: FC<{ element: DesignElementImageUpload }> = ({
  element,
}) => {
  const updateElementRef = useAction('updateElementRef')

  const { sourceImage } = element.customisations

  assert(sourceImage !== null)

  const updateElementTransform = useCallback(
    (newTransform: Transform2d, context: { last: boolean }) => {
      updateElementRef<DesignElementImageUpload>(
        element,
        current => {
          return {
            ...current,
            customisations: {
              ...current.customisations,
              pendingEditedImage: null,
              editedImage: null,
              sourceImage: {
                ...sourceImage,
                transform: {
                  position: {
                    x: newTransform.position[0],
                    y: newTransform.position[1],
                  },
                  rotation: newTransform.rotation,
                  scale: newTransform.scale[0],
                },
              },
            },
          }
        },
        { changeType: getGestureChangeType(context) },
      )
    },
    [element, sourceImage, updateElementRef],
  )

  const photo = useView('main', ({ photos }) => {
    const photoState = photos.photoById[sourceImage.id]

    assert(photoState.type === 'loaded')

    return photoState.photo
  })

  const initialScale = getInitialScale(
    photo.originalImage.width,
    photo.originalImage.height,
    element.width,
    element.height,
  )

  const { position, rotation, scale } = sourceImage.transform

  const transform = useMemo<Transform2d>(() => {
    return {
      position: [position.x, position.y],
      rotation,
      scale: [scale, scale],
    }
  }, [position.x, position.y, rotation, scale])

  const handleRotationChange = useCallback(
    (newRotation: number, context: { last: boolean }) => {
      updateElementTransform(
        transform2dRotateAndScaleAroundPoint(
          CENTER_OF_APERTURE,
          transform,
          newRotation,
          [scale, scale],
        ),
        context,
      )
    },
    [scale, transform, updateElementTransform],
  )

  const handleScaleChange = useCallback(
    (newScale: number, context: { last: boolean }) => {
      updateElementTransform(
        transform2dRotateAndScaleAroundPoint(
          CENTER_OF_APERTURE,
          transform,
          rotation,
          [newScale, newScale],
        ),
        context,
      )
    },
    [rotation, transform, updateElementTransform],
  )

  return (
    <Flex flexDirection="column" width="100%">
      <Box mt={4}>
        <RotationDial rotation={rotation} onChange={handleRotationChange} />
      </Box>
      <Box mt={5}>
        <ZoomDial
          initialScale={initialScale}
          scale={scale}
          onChange={handleScaleChange}
        />
      </Box>
    </Flex>
  )
}

type ImageUploadAdjustProps = {
  elementRef: DesignElementRef<DesignElementImageUpload>
}

export const ImageUploadAdjust: FC<ImageUploadAdjustProps> = ({
  elementRef,
}) => {
  const element = useElementRef(elementRef)

  const { sourceImage } = element.customisations

  /* istanbul ignore next */
  if (!sourceImage) {
    return null
  }

  return <ImageUploadAdjustContent element={element} />
}
