import { CollageGrid, CollageGridCell } from './grid'

export const updateGridCell = (
  grid: CollageGrid,
  cellId: string,
  newCell: Partial<CollageGridCell>,
): CollageGrid => {
  return {
    ...grid,
    cells: grid.cells.map(cell =>
      cell.id === cellId ? { ...cell, ...newCell } : cell,
    ),
  }
}
