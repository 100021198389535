import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  nav_button_previous: {
    'en-GB': `Previous page`,
    'nl-NL': `Vorige pagina`,
  },
  nav_button_next: {
    'en-GB': `Next page`,
    'nl-NL': `Volgende pagina`,
  },
}

export const useLocaleText = createLocaleTextHook({ dictionary })
