import { modNeg } from '@moonpig/common-math'
import { Box, Flex, IconButton } from '@moonpig/launchpad-components'
import {
  Dial,
  IconRotateAntiClockwise,
  IconRotateClockwise,
} from '@moonpig/web-personalise-components'
import {
  DesignElementOverlayText,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback } from 'react'
import { getGestureChangeType, useAction } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { useElementRef } from '../../../../../../selectors'

const MAX_ANTI_CLOCKWISE_ROTATION = -180
const MAX_CLOCKWISE_ROTATION = 180

const RotationDial: FC<{
  rotation: number
  onChange: (newRotation: number, context: { last: boolean }) => void
}> = ({ rotation, onChange }) => {
  const t = useEditorLocaleText()

  const handleChange = useCallback(
    (value: number, context: { last: boolean }) => {
      onChange(value, context)
    },
    [onChange],
  )

  return (
    <Dial
      id="rotation-dial"
      label={t('rotation_label')}
      getValueText={v => `${Math.round(v)}°`}
      minValue={MAX_ANTI_CLOCKWISE_ROTATION}
      maxValue={MAX_CLOCKWISE_ROTATION}
      unitsPerMark={1}
      pixelsPerMark={10}
      minorPerMajorMark={10}
      value={rotation}
      onChange={handleChange}
    />
  )
}

type RotateProps = {
  elementRef: DesignElementRef<DesignElementOverlayText>
}

const clampRotation = (rotation: number): number =>
  modNeg(rotation + 180, 360) - 180

export const ElementRotationPicker: FC<RotateProps> = ({ elementRef }) => {
  const t = useEditorLocaleText()

  const element = useElementRef(elementRef)
  const updateElementRef = useAction('updateElementRef')

  const handleRotationChange = useCallback(
    (newRotation: number, context: { last: boolean }) => {
      updateElementRef(
        elementRef,
        current => {
          return {
            ...current,
            rotation: newRotation,
          }
        },
        { changeType: getGestureChangeType(context) },
      )
    },
    [elementRef, updateElementRef],
  )

  return (
    <Flex flexDirection="column" width="100%">
      <Box mt={4}>
        <Flex flexDirection="row" width="100%" justifyContent="center">
          <Box mr={10}>
            <IconButton
              icon={IconRotateAntiClockwise}
              onClick={() =>
                handleRotationChange(clampRotation(element.rotation - 45), {
                  last: true,
                })
              }
              label={t('rotate_anticlockwise_label')}
              title={t('rotate_anticlockwise_label')}
            />
          </Box>
          <Box ml={10}>
            <IconButton
              icon={IconRotateClockwise}
              onClick={() =>
                handleRotationChange(clampRotation(element.rotation + 45), {
                  last: true,
                })
              }
              label={t('rotate_clockwise_label')}
              title={t('rotate_clockwise_label')}
            />
          </Box>
        </Flex>
      </Box>
      <Box mt={4}>
        <RotationDial
          rotation={element.rotation}
          onChange={handleRotationChange}
        />
      </Box>
    </Flex>
  )
}
