import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  loading_font_list_text: {
    'en-GB': (fontName: string) => `Loading ${fontName}…`,
    'nl-NL': (fontName: string) => `${fontName} aan het laden…`,
  },
}

export const useLocaleText = createLocaleTextHook({ dictionary })
