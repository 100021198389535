import { IconInformation } from '@moonpig/launchpad-assets'
import {
  Alert,
  Box,
  Flex,
  IconButton,
  PrimaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { HelperText, Label, TextArea } from '@moonpig/launchpad-forms'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { Tooltip } from '@moonpig/web-personalise-components'

import {
  DesignElementOverlayText,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import { useAction, useStore, useView } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'

const LINE_HEIGHT_INFO_TOOLTIP_CONTENT = 1.4

type SmartTextGenerationFormProps = {
  elementRef: DesignElementRef<DesignElementOverlayText>
}

type SmartTextError =
  | 'moderation-error'
  | 'limit-reached-error'
  | 'unavailable-error'
  | 'error'

const StyledTextArea = styled(TextArea)`
  label {
    display: none;
  }
  textarea {
    resize: vertical;
    min-height: 128px;
  }
`

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  label {
    padding-right: 4px;
  }
`

const List = styled.ul`
  list-style: disc;
  ${s({
    pl: 7,
    mt: 2,
    typography: 'bodySmall',
    lineHeight: LINE_HEIGHT_INFO_TOOLTIP_CONTENT,
  })}
`

const SmartTextError: FC<{ error: SmartTextError }> = ({ error }) => {
  const t = useEditorLocaleText()
  switch (error) {
    case 'limit-reached-error':
      return null
    case 'moderation-error':
      return (
        <HelperText id={'mp-ed-smart-text-moderation-error'} error>
          {t('smart_text_moderation_error')}
        </HelperText>
      )
    case 'unavailable-error':
      return (
        <Alert mt={4} variant="error">
          <Text as="div" typography={'bodyBold'}>
            {t('smart_text_feature_unavailable_title')}
          </Text>
          {t('smart_text_unavailable_error')}
        </Alert>
      )
    default:
      return (
        <Alert mt={4} variant="error">
          <Text as="div" typography={'bodyBold'}>
            {t('smart_text_generic_error_title')}
          </Text>
          {t('smart_text_generic_error')}
        </Alert>
      )
  }
}

export const SmartTextForm: FC<SmartTextGenerationFormProps> = ({
  elementRef,
}) => {
  const {
    privacyNoticeUrl,
    termsAndConditionsUrl,
    userGeneratedTermsAndConditionsUrl,
  } = useStore(({ config }) => config)
  const t = useEditorLocaleText()
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
  const iconRef = useRef<HTMLButtonElement | null>(null)
  const updateSmartTextPrompt = useAction('updateSmartTextPrompt')
  const trackEvent = useAction('trackEvent')
  const generateSmartText = useAction('generateSmartText')
  const resetSmartTextState = useAction('resetSmartTextState')
  const productKey = useView('main', view => view.design.productKey)
  const smartTextByElementId = useView(
    'main',
    view => view.smartTextByElementId,
  )
  const smartTextPrompt = useView('main', view => view.smartTextPrompt)
  const smartTextConfig = useView('main', view => view.smartTextConfig)
  const smartTextState = smartTextByElementId?.[elementRef.id]
  const generateSmartMessage = useCallback(async () => {
    trackEvent({
      type: 'SELECT_CONTENT',
      kind: 'SMART_TEXT_GENERATE',
      productKey,
    })
    generateSmartText({ prompt: smartTextPrompt, elementRef })
  }, [elementRef, smartTextPrompt, generateSmartText, trackEvent, productKey])

  const error = useMemo<SmartTextError | null>(() => {
    if (
      smartTextState &&
      smartTextState.type !== 'loading' &&
      smartTextState.type !== 'success'
    ) {
      return smartTextState.type
    }
    return null
  }, [smartTextState])

  const disableRetries =
    smartTextConfig.remainingRequests === 0 ||
    (smartTextState &&
      ['limit-reached-error', 'unavailable-error'].includes(
        smartTextState.type,
      )) ||
    false

  return (
    <Flex flexDirection="column">
      <Text typography="bodySmall">{t('smart_text_intro_text')}</Text>
      <StyledBox>
        <Label htmlFor="prompt-message">{t('smart_text_label')}</Label>
        <IconButton
          label={t('smart_text_info_label')}
          ref={iconRef}
          onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
          icon={IconInformation}
        />
        {tooltipIsOpen && (
          <Tooltip
            placement="bottom"
            target={iconRef.current}
            done={{
              onDone: () => setTooltipIsOpen(false),
              ariaLabel: t('smart_text_dismiss_more_info_aria_label'),
              label: t('toolbar_done_button'),
            }}
          >
            <>
              <Text
                lineHeight={LINE_HEIGHT_INFO_TOOLTIP_CONTENT}
                typography="bodySmall"
                as="div"
              >
                {t('smart_text_info_text')}
              </Text>
              <List>
                <li>{t('smart_text_recipient_name')}</li>
                <li>{t('smart_text_recipient_occasion')}</li>
                <li>{t('smart_text_tone_of_message')}</li>
                <li>{t('smart_text_fact_on_message')}</li>
              </List>
            </>
          </Tooltip>
        )}
      </StyledBox>
      <StyledTextArea
        name="message"
        id="prompt-message"
        placeholder={t('smart_text_placeholder')}
        error={error === 'moderation-error'}
        label={t('smart_text_label')}
        disabled={disableRetries}
        maxLength={smartTextConfig.maxPromptLength}
        onChange={e => {
          const newSmartTextPrompt = e.target.value.substring(
            0,
            smartTextConfig.maxPromptLength,
          )
          updateSmartTextPrompt(newSmartTextPrompt)
          resetSmartTextState(elementRef)
        }}
        value={smartTextPrompt}
        rows={4}
      />
      {error && <SmartTextError error={error} />}
      <PrimaryButton
        onClick={generateSmartMessage}
        disabled={
          smartTextPrompt.length === 0 ||
          disableRetries ||
          smartTextState?.type === 'moderation-error'
        }
        width="100%"
        mt={5}
        loading={smartTextState?.type === 'loading'}
      >
        {t('smart_text_action_button_label')}
      </PrimaryButton>
      <Text
        typography="bodySmall"
        as="p"
        mt={5}
        mb={0}
        color="colorInteractionDeactivated"
        dangerouslySetInnerHTML={{
          __html: t('smart_text_disclaimer_text', {
            privacyNoticeUrl,
            termsAndConditionsUrl,
            userGeneratedTermsAndConditionsUrl,
          }),
        }}
      />
    </Flex>
  )
}
