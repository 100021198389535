import { IconSystemBin, IconSystemReplace } from '@moonpig/launchpad-assets'
import {
  Flex,
  PrimaryButton,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import {
  DesignElementMedia,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useState } from 'react'
import { useAction, useView } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { VideoPreview } from '../../VideoPreview/VideoPreview'
import { VideoInput } from '../VideoInput'
import { TermsCheckbox, TermsState } from './TermsCheckbox'
import { VideoDetails } from './VideoDetails'

const VIDEO_INPUT_ID = 'ed-video-upload-replace-input'

type VideoUploadProps = {
  video: {
    file: Blob
    url: string
  }
  existingMediaElement?: DesignElementRef<DesignElementMedia> | null
}

export const VideoUpload: FC<VideoUploadProps> = ({
  video,
  existingMediaElement,
}) => {
  const t = useEditorLocaleText()
  const setUI = useAction('setUI')
  const trackEvent = useAction('trackEvent')
  const uploadMedia = useAction('uploadMedia')
  const [termsState, setTermsState] = useState<TermsState>('not-checked')
  const [durationInSeconds, setDurationInSeconds] = useState(0)
  const productKey = useView('main', view => view.design.productKey)
  const [playbackError, setPlaybackError] = useState(false)
  const deleteElement = useAction('deleteElement')
  const videoAspectRatio = useView(
    'main',
    ({ customisationConfig }) => customisationConfig.video.sceneAspectRatio,
  )

  const handleDurationChange = useCallback(
    (event: { durationInSeconds: number }) => {
      setDurationInSeconds(event.durationInSeconds)
    },
    [],
  )

  const handlePlaybackError = useCallback(() => {
    setPlaybackError(true)

    trackEvent({
      type: 'ERROR',
      kind: 'VIDEO_PLAYBACK_ERROR',
    })
  }, [trackEvent])

  const handleReplace = useCallback(
    (file: Blob) => {
      setPlaybackError(false)

      setUI({
        type: 'default',
        selectedMenuItem: 'video-upload',
        video: { file, url: URL.createObjectURL(file) },
      })

      trackEvent({
        type: 'SELECT_CONTENT',
        kind: 'VIDEO_REPLACE',
        productKey,
        label: 'modal',
      })
    },
    [productKey, setUI, trackEvent],
  )

  const handleDelete = useCallback(() => {
    setUI({ type: 'default', selectedMenuItem: null })

    trackEvent({
      type: 'SELECT_CONTENT',
      kind: 'VIDEO_DELETE',
      productKey,
      label: 'modal',
    })
  }, [productKey, setUI, trackEvent])

  const handleUpload = useCallback(() => {
    const termsAndConditionsAccepted = termsState === 'checked'

    if (termsAndConditionsAccepted) {
      setUI({ type: 'default', selectedMenuItem: null })

      if (existingMediaElement) deleteElement(existingMediaElement)

      uploadMedia({
        file: video.file,
        termsAndConditionsAccepted,
        mediaAspectRatio: videoAspectRatio,
        kind: 'video',
      })

      trackEvent({
        type: 'MEDIA_UPLOAD',
        productKey,
        mediaSizeBytes: video.file.size,
        mediaDurationSeconds: durationInSeconds,
        mediaKind: 'video',
      })
    } else {
      setTermsState('not-checked-error')

      trackEvent({
        type: 'ERROR',
        kind: 'VIDEO_TERMS_ERROR',
      })
    }
  }, [
    termsState,
    setUI,
    existingMediaElement,
    deleteElement,
    uploadMedia,
    video.file,
    videoAspectRatio,
    trackEvent,
    productKey,
    durationInSeconds,
  ])

  return (
    <Flex flexDirection="column" flex={1}>
      <VideoPreview
        selectedVideoUrl={video.url}
        onDurationChange={handleDurationChange}
        onError={handlePlaybackError}
      />
      {!playbackError && (
        <VideoDetails
          sizeInBytes={video.file.size}
          durationInSeconds={durationInSeconds}
        />
      )}
      <Flex justifyContent="space-around" pb={4} pt={6}>
        <VideoInput
          id={VIDEO_INPUT_ID}
          onChange={handleReplace}
          label={
            <TertiaryButton
              {...{ as: 'label', htmlFor: VIDEO_INPUT_ID }}
              leadingIcon={<IconSystemReplace />}
            >
              {t('toolbar_image_replace_label')}
            </TertiaryButton>
          }
        />
        <TertiaryButton leadingIcon={<IconSystemBin />} onClick={handleDelete}>
          {t('delete_element_label')}
        </TertiaryButton>
      </Flex>
      <Flex flex={1} />
      {!playbackError && (
        <TermsCheckbox state={termsState} onChange={setTermsState} />
      )}
      <PrimaryButton
        key={String(playbackError)}
        disabled={playbackError}
        width="100%"
        mt={6}
        onClick={handleUpload}
      >
        {t('button_upload_video')}
      </PrimaryButton>
    </Flex>
  )
}
