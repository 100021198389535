import {
  SceneSurface,
  Size,
  useSceneSurfaceData,
} from '@moonpig/renderer-react'
import { SceneObject } from '@moonpig/renderer-scene-types'
import {
  DesignElement,
  DesignScene,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useMemo } from 'react'
import { useEditorFeatures, useStore, useView } from '../../../../store'
import { convertDesignToScene } from './convertDesignToScene'

type DefaultSceneSurfaceProps = {
  scene: DesignScene
  size: Size
  hideObject?: (input: { object: SceneObject }) => boolean
  dimElement?: (element: DesignElement) => boolean
}

export const DefaultSceneSurface: FC<DefaultSceneSurfaceProps> = ({
  scene: { width, height, renderMethod, elementIds, elementById },
  size,
  hideObject,
  dimElement,
}) => {
  const photos = useView('main', view => view.photos)
  const loadedStickerIds = useView('main', view => view.loadedStickerIds)
  const availableTextStyles = useView(
    'main',
    view => view.design.availableTextStyles,
  )
  const remoteImageStore = useStore(store => store.remoteImageStore)
  const { enableTextFragments } = useEditorFeatures()

  const scene = useMemo(() => {
    return convertDesignToScene({
      elementById,
      scene: { width, height, renderMethod, elementIds },
      photos,
      dimElement,
      loadedStickerIds,
      availableTextStyles,
      remoteImageStore,
      enableTextFragments,
    })
  }, [
    availableTextStyles,
    dimElement,
    elementById,
    elementIds,
    enableTextFragments,
    height,
    loadedStickerIds,
    photos,
    remoteImageStore,
    renderMethod,
    width,
  ])

  const state = useSceneSurfaceData(scene)

  switch (state.type) {
    case 'LOADING': {
      return null
    }
    case 'ERROR': {
      throw new Error(state.message)
    }
    case 'LOADED': {
      return (
        <SceneSurface data={state.data} size={size} hideObject={hideObject} />
      )
    }
  }
}
