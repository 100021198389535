import React, { FC } from 'react'
import { animated, useTransition } from 'react-spring'
import { SHARED_ANIMATION_CONFIG } from '../../../../../../../constants'

export const ToolbarBottom: FC = ({ children }) => {
  const transitions = useTransition(true, {
    from: { offset: 'translateY(-110%)' },
    enter: { offset: 'translateY(0%)' },
    config: SHARED_ANIMATION_CONFIG,
  })

  return transitions(springs => {
    return (
      <animated.div style={{ transform: springs.offset, width: '100%' }}>
        {children}
      </animated.div>
    )
  })
}

export const ToolbarLeft: FC = ({ children }) => {
  return <>{children}</>
}
