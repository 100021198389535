import { DesignElementRef } from '@moonpig/web-personalise-editor-types'
import { useCallback } from 'react'
import { useAction, useEditorFeatures } from '../../../store'

type DeleteElement = (elementRef: DesignElementRef) => void

export const useDeleteElement = (): DeleteElement => {
  const features = useEditorFeatures()
  const showModal = useAction('showModal')
  const deleteElement = useAction('deleteElement')

  return useCallback(
    elementRef => {
      if (features.enableHistory) {
        deleteElement(elementRef)
        return
      }

      showModal({ type: 'confirm-delete', elementRef })
    },
    [deleteElement, features.enableHistory, showModal],
  )
}
