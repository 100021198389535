import { Vec2 } from '@moonpig/common-math'
import { Frame } from '../common/frame'
import { CollageBorder } from './grid'

export const getClippedBorder = ({
  clipBounds,
  size,
  baseSize,
}: {
  clipBounds: Frame
  size: Vec2
  baseSize: number
}): CollageBorder => {
  const minSize = Math.min(size[0], size[1])

  return {
    top: clipBounds.position[1] / minSize + baseSize,
    right:
      (size[0] - clipBounds.size[0] - clipBounds.position[0]) / minSize +
      baseSize,
    bottom:
      (size[1] - clipBounds.size[1] - clipBounds.position[1]) / minSize +
      baseSize,
    left: clipBounds.position[0] / minSize + baseSize,
    inner: baseSize,
  }
}
