import React, { FC } from 'react'

export const IconChevronDown: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 28 28" width={width} height={height}>
      <path
        clipRule="evenodd"
        d="M22.707 9.293a1 1 0 0 0-1.414 0L14 16.586 6.707 9.293a1 1 0 0 0-1.414 1.414l8 8a1 1 0 0 0 1.414 0l8-8a1 1 0 0 0 0-1.414z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
