import { DesignLimits } from '@moonpig/web-personalise-editor-types'
import shallow from 'zustand/shallow'
import { useView } from '../store'

type UseCheckIfMaxElementLimitReachedResult = {
  maxElementAdded: boolean
  designOverlayElementCount: number
}

export const useCheckIfMaxElementLimitReached = (
  elementType: string,
  limitFor: keyof DesignLimits,
): UseCheckIfMaxElementLimitReachedResult => {
  return useView(
    'main',
    ({ design }) => {
      const designOverlayElementCount = design.sceneIds.reduce(
        (acc, sceneId) => {
          const scene = design.sceneById[sceneId]

          const sceneElementCount = scene.elementIds.filter(
            elementId => scene.elementById[elementId].type === elementType,
          ).length

          return acc + sceneElementCount
        },
        0,
      )

      return {
        maxElementAdded: designOverlayElementCount >= design.limits[limitFor],
        designOverlayElementCount,
      }
    },
    shallow,
  )
}
