import {
  IconAdjust,
  IconPhotos,
  IconReplace,
} from '@moonpig/web-personalise-components'
import {
  DesignElementImageUpload,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { useCallback, useMemo } from 'react'
import shallow from 'zustand/shallow'
import { createElementDomId } from '../../../../../../../utils/createElementId'
import {
  UIImageUpload,
  UIImageUploadMenuItem,
  useAction,
  useMainView,
} from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { getDesignElementByRef } from '../../../../../selectors'
import { ToolbarItem, ToolbarMenu, ToolbarOrientation } from '../Toolbar'
import { ToolbarCommon } from '../ToolbarCommon'
import { createMenuTitleId } from '../utils/createMenuTitleId'
import { ImageUploadAdjust } from './ImageUploadAdjust'
import { ImageUploadPhotoLibrary } from './ImageUploadPhotoLibrary'

type ToolbarImageUploadProps = {
  onDone: () => void
  orientation: ToolbarOrientation
  ui: UIImageUpload
}

const useElement = (
  elementRef: DesignElementRef<DesignElementImageUpload>,
): { id: string; hasSourceImage: boolean } => {
  return useMainView(
    'edit',
    (_, { design }) => {
      const element = getDesignElementByRef(design, elementRef)

      return {
        id: element.id,
        hasSourceImage: element.customisations.sourceImage !== null,
      }
    },
    shallow,
  )
}

export const ToolbarImageUpload = ({
  onDone,
  orientation,
  ui,
}: ToolbarImageUploadProps) => {
  const { elementRef, selectedMenuItem } = ui

  const element = useElement(elementRef)
  const setUI = useAction('setUI')
  const t = useEditorLocaleText()

  const controlId = createElementDomId(element.id)

  const items = useMemo<ToolbarItem<UIImageUploadMenuItem>[]>(() => {
    const photoLibrary: ToolbarItem<UIImageUploadMenuItem> =
      element.hasSourceImage
        ? {
            type: 'menu',
            id: 'photo-library',
            label: t('toolbar_image_replace_label'),
            ariaLabel: t('toolbar_replace_photo'),
            icon: <IconReplace />,
            disabled: false,
          }
        : {
            type: 'menu',
            id: 'photo-library',
            label: t('toolbar_image_photos_label'),
            ariaLabel: t('toolbar_add_photo'),
            icon: <IconPhotos />,
            disabled: false,
          }
    return [
      photoLibrary,
      {
        type: 'menu',
        id: 'adjust',
        label: t('toolbar_image_adjust_label'),
        ariaLabel: t('toolbar_adjust'),
        icon: <IconAdjust />,
        disabled: !element.hasSourceImage,
      },
    ]
  }, [element.hasSourceImage, t])

  const menu = useMemo<ToolbarMenu | undefined>(() => {
    switch (selectedMenuItem) {
      case 'photo-library': {
        const titleId = createMenuTitleId(elementRef.id, selectedMenuItem)

        return {
          title: t('toolbar_menu_choose_photo_label'),
          titleId,
          content: (
            <ImageUploadPhotoLibrary
              elementRef={elementRef}
              labelledById={titleId}
            />
          ),
          closeLabel: t('toolbar_menu_close_photo_library_label'),
        }
      }
      case 'adjust': {
        return {
          title: t('toolbar_menu_adjust_photo_label'),
          content: <ImageUploadAdjust elementRef={elementRef} />,
          closeLabel: t('toolbar_menu_close_adjust_label'),
          drawerHeight: 'fit',
        }
      }
    }
  }, [elementRef, selectedMenuItem, t])

  const handleSelectItem = useCallback(
    (itemId: UIImageUploadMenuItem | null) => {
      setUI({ ...ui, selectedMenuItem: itemId })
    },
    [setUI, ui],
  )

  return (
    <ToolbarCommon
      controlId={controlId}
      label={t('toolbar_image_toolbar_title')}
      orientation={orientation}
      items={items}
      selectedItemId={selectedMenuItem}
      onSelectItem={handleSelectItem}
      menu={menu}
      done={{
        label: t('toolbar_done_button'),
        ariaLabel: t('toolbar_image_toolbar_close_label'),
        onDone,
      }}
    />
  )
}
