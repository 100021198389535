import { config } from 'react-spring'

export const DESKTOP_BP = 'lg'

export const SHARED_ANIMATION_CONFIG = config.default

export const UI_SPACER = 8

export const UI_CAMERA_PAD_MOBILE = 8
export const UI_CAMERA_PAD_DESKTOP = 16

const UI_TOOLBAR_PORTRAIT_WIDTH = 88
const UI_TOOLBAR_LANDSCAPE_HEIGHT = 72
export const UI_TOOLBAR_PORTRAIT_MENU_WIDTH = 300
export const UI_HEIGHT_TOP = UI_TOOLBAR_LANDSCAPE_HEIGHT + UI_SPACER

export const UI_WIDTH_CLOSED_LAYOUT_LEFT = UI_TOOLBAR_PORTRAIT_WIDTH + UI_SPACER
export const UI_WIDTH_OPEN_LAYOUT_LEFT =
  UI_WIDTH_CLOSED_LAYOUT_LEFT + UI_TOOLBAR_PORTRAIT_MENU_WIDTH + UI_SPACER

export const STICKER_MIN_SCALE = 0.5
export const STICKER_MAX_SCALE = 5

export const OVERLAY_IMAGE_MIN_SCALE = 0.5
export const OVERLAY_IMAGE_MAX_SCALE = 3

export const HEADER_HEIGHT_DESKTOP = 64

export const TEXT_ELEMENT_MAX_CHARACTER_LIMIT = 2000

export const BRAND_TWO_TRANSLUCENT = 'rgba(0, 84, 200, 0.1)' // Move to Launchpad

export const MAX_SCALE_PHOTO = 4

export const COLLAGE_PHOTO_LIMIT = 10

export const TOOLBAR_ID = 'mp-ed-toolbar'

export const DIAGONAL_OFFSET_DISTANCE = 10

export const DIAGONAL_OFFSET_REPEAT = 3

// Hard limit of 2048 characters imposed in MCD (9 characters used to wrap in <ct></ct> tag). Design API imposes 2000 characters on all text inputs.
export const TEXT_DEFAULT_MAX_CHARACTERS = 2000

export const FOCUS_ID_SCENE = (sceneId: string) => `scene-${sceneId}`
export const FOCUS_ID_ELEMENT = (elementId: string) => `element-${elementId}`
export const FOCUS_ID_TOOLBAR = 'toolbar'
export const FOCUS_ID_MENU = 'menu'
export const FOCUS_ID_TEXT_INPUT = 'text-input'
