/* eslint-disable react/jsx-key */
import { Modal, FeatureCard } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import {
  Box,
  Flex,
  Grid,
  PrimaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { OnboardingEntry, useAction } from '../../../../../../../store'
import { buildOnboardingContent } from './buildOnboardingContent'

const DESKTOP_BP = 'md'

const StyledOnboardingCards = styled(Flex).attrs<{
  singleOnboardingContent: boolean
}>(({ singleOnboardingContent }) => ({
  className: singleOnboardingContent === true ? 'singleOnboardingContent' : '',
}))<{ singleOnboardingContent: boolean }>`
  flex-direction: column;
  flex: 1;

  ${s({
    maxWidth: '420px',
    maxHeight: '450px',
  })}

  &.singleOnboardingContent {
    ${s({
      maxWidth: 'none',
      maxHeight: 'none',
    })}
  }

  ${breakpoint(DESKTOP_BP)} {
    &.singleOnboardingContent {
      ${s({
        maxHeight: '750px',
      })}
    }
  }
`
export const OnboardingModal: FC<{ entries: OnboardingEntry[] }> = ({
  entries,
}) => {
  const t = useEditorLocaleText()
  const edit = useAction('edit')

  const onboardingContent = buildOnboardingContent(entries, t)
  const singleOnboardingContent = onboardingContent.length === 1

  return (
    <Modal
      heading={t('onboarding_title')}
      closeLabel={t('modal_close_button')}
      onClose={edit}
    >
      <StyledOnboardingCards
        singleOnboardingContent={singleOnboardingContent}
        flexDirection={'column'}
      >
        <Text pb={6}>{t('onboarding_body')}</Text>
        {singleOnboardingContent ? (
          <FeatureCard
            title={onboardingContent[0].title}
            description={onboardingContent[0].description}
            image={onboardingContent[0].image}
          />
        ) : (
          <Grid gap={10} style={{ overflowY: 'scroll' }}>
            {onboardingContent.map((content, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box width={0.5} key={index}>
                <FeatureCard
                  title={content.title}
                  description={content.description}
                  image={content.image}
                />
              </Box>
            ))}
          </Grid>
        )}
        <PrimaryButton width={'100%'} mt={6} onClick={edit}>
          {t('onboarding_dismiss_button')}
        </PrimaryButton>
      </StyledOnboardingCards>
    </Modal>
  )
}
