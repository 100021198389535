import { retry } from '@lifeomic/attempt'
import { logger } from '@moonpig/web-core-monitoring'
import { loadImage } from '@moonpig/web-core-utils'
import { LoadImage } from '@moonpig/web-personalise-editor-types'

export const createLoadImage = (): LoadImage => {
  return async imageUrl => {
    try {
      return await retry(async () => loadImage(imageUrl), {
        maxAttempts: 3,
        delay: 500,
        factor: 2,
        handleError(err, { attemptNum }) {
          logger.info('Retrying load image', { attemptNum, imageUrl }, err)
        },
      })
    } catch (error) {
      logger.fixToday('Failed to load image', { imageUrl }, error)

      throw new Error(`Failed to load image ${imageUrl}: ${error.message}`)
    }
  }
}
