import { Vec2, vec2Add } from '@moonpig/common-math'
import React, {
  FC,
  MutableRefObject,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { assert } from '../../common/assert'
import { Frame, frameContainsPoint } from '../../common/frame'
import { CollageMode } from '../../common/types'
import { FRAME_OVERLAP } from '../../constants'
import {
  CollageGrid,
  CollageLayoutElement,
  ImageById,
  layoutGrid,
  layoutImages,
  swapGridCells,
  updateGridCell,
} from '../../model'
import { InteractiveCollageCellImage } from '../InteractiveCollageCellImage'
import { InteractiveImageManipulation } from '../InteractiveImageManipulation'

const DEFAULT_BACKGROUND_COLOR = '#ffffff'

type InteractiveCollageCellsProps = {
  createCellLabel: (index: number) => string
  mode: CollageMode
  grid: CollageGrid
  gridSize: Vec2
  clipBounds: Frame
  scale: number
  imageById: ImageById
  selectedCellId: string | null
  onSelectCell: (cellId: string) => void
  onChangeGrid: (newGrid: CollageGrid, context: { last: boolean }) => void
  disableSelectCell: MutableRefObject<boolean>
}

type SwapState = {
  sourceCellId: string
  targetCellId: string | null
  offset: Vec2
}

type ChangePositionHandler = (event: {
  pointerOffset: Vec2
  cellId: string
  position: Vec2
  last: boolean
}) => void

const keepAnimatingCellsOnTop = (
  grid: CollageGrid,
  sourceCellId: string,
  targetCellId = '',
): CollageGrid => {
  const weights: { [id in string]?: number } = {
    [sourceCellId]: 2,
    [targetCellId]: 1,
  }

  return {
    ...grid,
    cells: grid.cells.sort((a, b) => {
      const weightA = weights[a.id] ?? 0
      const weightB = weights[b.id] ?? 0
      return weightA - weightB
    }),
  }
}

export const InteractiveCollageCells: FC<InteractiveCollageCellsProps> = ({
  createCellLabel,
  mode,
  grid,
  gridSize,
  clipBounds,
  scale,
  imageById,
  selectedCellId,
  onSelectCell,
  onChangeGrid,
  disableSelectCell,
}) => {
  const [swapState, setSwapState] = useState<SwapState | null>(null)
  const layout = useMemo(
    () => layoutGrid({ grid, size: gridSize, frameOverlap: FRAME_OVERLAP }),
    [grid, gridSize],
  )

  const imageLayoutById = useMemo(
    () => layoutImages(imageById, layout),
    [imageById, layout],
  )

  const findElementIdAtPosition = useCallback(
    (position: Vec2): null | string => {
      const elementAtTarget = layout.elements.find(element => {
        return frameContainsPoint(element.frame, position)
      })

      return elementAtTarget?.id ?? null
    },
    [layout.elements],
  )

  const handleChangePosition = useCallback<ChangePositionHandler>(
    ({ pointerOffset, cellId: sourceCellId, position, last }) => {
      let targetCellId: string | null = null

      const sourceCell = layout.elements.find(
        element => element.id === sourceCellId,
      )

      assert(sourceCell !== undefined)

      targetCellId = findElementIdAtPosition(
        vec2Add(vec2Add(position, sourceCell.frame.position), pointerOffset),
      )

      if (last) {
        if (sourceCellId && targetCellId) {
          const swappedGrid = swapGridCells(grid, sourceCellId, targetCellId)

          onChangeGrid(
            keepAnimatingCellsOnTop(swappedGrid, sourceCellId, targetCellId),
            { last },
          )
        } else {
          onChangeGrid(keepAnimatingCellsOnTop(grid, sourceCellId), { last })
        }

        setSwapState(null)
        return
      }

      setSwapState({
        sourceCellId,
        targetCellId,
        offset: position,
      })
    },
    [grid, layout.elements, onChangeGrid, findElementIdAtPosition],
  )

  const elements: CollageLayoutElement[] = useMemo(() => {
    if (swapState) {
      const newElements = [...layout.elements]
      const swapElementIndex = newElements.findIndex(
        other => other.id === swapState.sourceCellId,
      )
      const swapElement = newElements.splice(swapElementIndex, 1)
      return [...newElements, ...swapElement]
    }

    return layout.elements
  }, [layout.elements, swapState])

  const selectedElement = useMemo(
    () => elements.find(element => element.id === selectedCellId),
    [elements, selectedCellId],
  )

  const renderContent = mode === 'rearrange'

  const cells = (
    <>
      {renderContent && (
        <rect
          fill={
            grid.background ? grid.background.color : DEFAULT_BACKGROUND_COLOR
          }
          x={clipBounds.position[0]}
          y={clipBounds.position[1]}
          width={clipBounds.size[0]}
          height={clipBounds.size[1]}
        />
      )}
      {elements.map(({ id, content, frame }, index) => {
        const positionOffset: Vec2 =
          swapState && swapState.sourceCellId === id ? swapState.offset : [0, 0]

        return (
          <InteractiveCollageCellImage
            key={id}
            label={createCellLabel(index)}
            mode={mode}
            id={id}
            cell={{ offset: content.offset, scale: content.scale }}
            scale={scale}
            clipBounds={clipBounds}
            frame={frame}
            positionOffset={positionOffset}
            layout={imageLayoutById[id]}
            image={imageById[content.imageId]}
            dimmed={selectedCellId !== null && selectedCellId !== id}
            selected={selectedCellId === id}
            targeted={
              swapState !== null &&
              id !== swapState.sourceCellId &&
              id === swapState.targetCellId
            }
            renderContent={renderContent}
            onChangeTransform={({
              offset: newOffset,
              scale: newScale,
              last,
            }) => {
              onChangeGrid(
                updateGridCell(grid, id, {
                  content: {
                    ...content,
                    offset: newOffset,
                    scale: newScale,
                  },
                }),
                { last },
              )
            }}
            onChangePosition={({ pointerOffset, position, last }) => {
              handleChangePosition({
                pointerOffset,
                cellId: id,
                position,
                last,
              })
            }}
            onClick={() => {
              onSelectCell(id)
            }}
            disableSelectCell={disableSelectCell}
          />
        )
      })}
    </>
  )

  if (!selectedElement) {
    return cells
  }

  return (
    <InteractiveImageManipulation
      bounds={clipBounds}
      cell={{
        offset: selectedElement.content.offset,
        scale: selectedElement.content.scale,
      }}
      frame={selectedElement.frame}
      layout={imageLayoutById[selectedElement.id]}
      image={imageById[selectedElement.content.imageId]}
      onChangeTransform={event => {
        onChangeGrid(
          updateGridCell(grid, selectedElement.id, {
            content: {
              type: 'image',
              offset: event.offset,
              scale: event.scale,
              imageId: selectedElement.content.imageId,
            },
          }),
          { last: event.last },
        )
      }}
    >
      {cells}
    </InteractiveImageManipulation>
  )
}
