import { MediaCardDemo } from '@moonpig/web-personalise-components'
import { Design } from '@moonpig/web-personalise-editor-types'
import React, { FC, useMemo } from 'react'
import { addMediaToDesign } from '../../../../../../../../utils/addMediaElementToDesign'
import { assert } from '../../../../../../../../utils/assert'
import { usePixelRatio } from '../../../../../../../../utils/usePixelRatio'
import { useStore, useView } from '../../../../../../../../store'
import { DefaultSceneSurface } from '../../../../../../components/DefaultSceneSurface'
import { useLayout } from '../../../../../../selectors'

const SCALE = 3

const useMediaCardLayout = () => {
  const layout = useView('main', ({ design }) => design.layout)

  assert(
    layout === 'CARD_PORTRAIT' ||
      layout === 'CARD_LANDSCAPE' ||
      layout === 'CARD_SQUARE',
    'Unsupported media card layout',
  )

  return layout
}

export const AudioOnboardingBanner: FC = () => {
  const mediaCardLayout = useMediaCardLayout()
  const design = useView('main', view => view.design)
  const pixelRatio = usePixelRatio({ max: 2 })
  const layout = useLayout()

  const audioAspectRatio = useView(
    'main',
    ({ customisationConfig }) => customisationConfig.audio.sceneAspectRatio,
  )

  const onboardingDemoVideoUrl = useStore(
    ({ config }) => config.audioMediaDemo.videoUrl,
  )

  const designWithAudio = useMemo<Design>(() => {
    const { updatedDesign } = addMediaToDesign({
      design,
      uploadComplete: Promise.resolve({ type: 'error' }),
      state: {
        type: 'uploaded',
        mediaId: 'audio-media-demo',
        mediaToken: 'audio-media-demo',
      },
      mediaAspectRatio: audioAspectRatio,
      kind: 'audio',
    })

    return updatedDesign
  }, [design, audioAspectRatio])

  return (
    <MediaCardDemo
      layout={mediaCardLayout}
      demoVideoUrl={onboardingDemoVideoUrl}
      mediaKind="audio"
      renderScene={({ sceneIndex }) => {
        const sceneId = designWithAudio.sceneIds[sceneIndex]
        const scene = designWithAudio.sceneById[sceneId]
        const bounds = layout.sceneById[sceneId]

        return (
          <DefaultSceneSurface
            scene={scene}
            size={{
              width: bounds.width * pixelRatio * SCALE,
              height: bounds.height * pixelRatio * SCALE,
            }}
          />
        )
      }}
    />
  )
}
