import { CollageGrid, CollageGridCell } from './grid'

export const swapGridCells = (
  grid: CollageGrid,
  sourceId: string,
  targetId: string,
): CollageGrid => {
  if (sourceId === targetId) {
    return grid
  }

  let sourceCell: CollageGridCell
  let targetCell: CollageGridCell

  grid.cells.forEach(cell => {
    if (cell.id === sourceId) {
      sourceCell = cell
    } else if (cell.id === targetId) {
      targetCell = cell
    }
  })

  return {
    ...grid,
    cells: grid.cells.map(cell => {
      let { anchors } = cell
      if (cell.id === sourceId) {
        anchors = targetCell.anchors
      } else if (cell.id === targetId) {
        anchors = sourceCell.anchors
      }

      return {
        ...cell,
        anchors,
      }
    }),
  }
}
