import { FontSizePicker } from '@moonpig/web-personalise-components'
import {
  DesignElementRef,
  DesignElementTextStyled,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback } from 'react'
import { useAction } from '../../../../../../../../store'
import { useElementRef } from '../../../../../../selectors'

type ElementFontSizePickerProps = {
  elementRef: DesignElementRef<DesignElementTextStyled>
}

export const ElementFontSizePicker: FC<ElementFontSizePickerProps> = ({
  elementRef,
}) => {
  const element = useElementRef(elementRef)
  const updateElement = useAction('updateElement')

  const onSelect = useCallback(
    (fontSize: number) => {
      updateElement({
        ...element,
        customisations: {
          ...element.customisations,
          fontSize,
        },
      })
    },
    [element, updateElement],
  )

  return (
    <FontSizePicker
      onSelect={onSelect}
      selectedFontSize={element.customisations.fontSize}
      sizes={element.availableFontSizes.map(value => ({ value }))}
    />
  )
}
