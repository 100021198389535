import React, { FC } from 'react'

export const IconLayout: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 24 24" width={width} height={height}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 2h-9v9h11V4a2 2 0 0 0-2-2Zm4 9v9a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v7Zm-2 2H11v9h9a2 2 0 0 0 2-2v-7ZM4 22h5V2H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2Z"
        fill="currentColor"
      />
    </svg>
  )
}
