/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const getTextContent = /* istanbul ignore next */ (
  element: HTMLElement,
): string => {
  const newLineElements = ['DIV', 'BR', 'P']

  const isNewParagraph = (nodeName: string) => {
    return newLineElements.includes(nodeName)
  }

  const result = Array.from(element!.childNodes).reduce<string[]>(
    (lines, child) => {
      const text = child.textContent!

      if (lines.length === 0 || isNewParagraph(child.nodeName)) {
        lines.push(text)
      } else {
        // eslint-disable-next-line no-param-reassign
        lines[lines.length - 1] += text
      }

      return lines
    },
    [],
  )

  return result
    .map(text => text.replace(/^( |\t)+|( |\t)+$/gm, ''))
    .join('\n')
    .normalize()
}
