import { assert } from '../../../utils/assert'
import { ActionCreator } from '../../types'

export const createEdit: ActionCreator<'edit'> =
  ({ set }) =>
  () => {
    set(({ view }) => {
      assert(view.type === 'main')

      return {
        view: {
          ...view,
          view: {
            type: 'edit',
            activeElementId: null,
            ui: null,
            modal: null,
            highlightInvalidElements: false,
          },
        },
      }
    })
  }
