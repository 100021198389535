import uuid from 'uuid/v4'
import { Notification, NotificationContent } from '../types'

export const addNotification = (
  notifications: Notification[],
  content: NotificationContent,
): Notification[] => {
  if (notifications.some(other => other.content.type === content.type)) {
    return notifications
  }

  return [
    ...notifications,
    {
      id: uuid(),
      content,
    },
  ]
}
