import { assert } from '../../utils/assert'
import { Store, ViewMain, ViewMainViews } from '../types'
import { storeUpdateView } from './view'

export const storeUpdateMainView = <TView extends keyof ViewMainViews>(
  store: Store,
  viewType: TView,
  updater: (
    mainView: ViewMainViews[TView],
    view: ViewMain,
  ) => Partial<ViewMainViews[TView]>,
): Store => {
  return storeUpdateView(store, 'main', view => {
    assert(view.view.type === viewType)

    return {
      view: {
        ...view.view,
        ...updater(view.view as ViewMainViews[TView], view),
      },
    }
  })
}
