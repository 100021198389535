import { Bounds } from '@moonpig/common-math'

export const expandBounds = (v: number, bounds: Bounds) => {
  return {
    x: bounds.x - v,
    y: bounds.y - v,
    width: bounds.width + 2 * v,
    height: bounds.height + 2 * v,
  }
}

export const transposeBounds = (bounds: Bounds): Bounds => {
  return {
    x: bounds.y,
    y: bounds.x,
    width: bounds.height,
    height: bounds.width,
  }
}

export const createTransform = (x: number, y: number, scale: number) => {
  return `translate(${x}px, ${y}px) scale(${scale})`
}
