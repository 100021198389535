import { RegisterResources } from '@moonpig/renderer-react'
import { Services } from '@moonpig/web-personalise-editor-types'
import { GetState, SetState } from 'zustand'
import { assert } from '../../utils/assert'
import { getDynamicImageId } from '../../utils/getDynamicImageId'
import {
  getDynamicImageElementsToUpdate,
  renderDynamicImages,
} from '../common/renderDynamicImages'
import { DynamicImageState, Store } from '../types'

export const updateDynamicImages = async ({
  services,
  registerResources,
  get,
  set,
}: {
  services: Services
  registerResources: RegisterResources
  get: GetState<Store>
  set: SetState<Store>
}): Promise<void> => {
  const { view, remoteImageStore } = get()

  assert(view.type === 'main')

  const { design } = view

  const dynamicImageElementsToUpdate = getDynamicImageElementsToUpdate({
    design,
    dynamicImageById: remoteImageStore.dynamicImageById,
  })

  if (dynamicImageElementsToUpdate.length === 0) {
    return
  }

  set(store => {
    const updatedDynamicImages = Object.fromEntries(
      dynamicImageElementsToUpdate.map(
        (element): [string, DynamicImageState] => {
          const { dynamicImageId } = getDynamicImageId(element)

          return [dynamicImageId, { type: 'loading' }]
        },
      ),
    )

    return {
      remoteImageStore: {
        ...store.remoteImageStore,
        dynamicImageById: {
          ...store.remoteImageStore.dynamicImageById,
          ...updatedDynamicImages,
        },
      },
    }
  })

  try {
    const updatedDynamicImages = await renderDynamicImages({
      services,
      registerResources,
      dynamicImageElementsToUpdate,
    })

    set(store => {
      return {
        remoteImageStore: {
          ...store.remoteImageStore,
          dynamicImageById: {
            ...store.remoteImageStore.dynamicImageById,
            ...updatedDynamicImages,
          },
        },
      }
    })
  } catch {
    set(store => {
      const updatedDynamicImages = Object.fromEntries(
        dynamicImageElementsToUpdate.map(
          (element): [string, DynamicImageState] => {
            const { dynamicImageId } = getDynamicImageId(element)

            return [dynamicImageId, { type: 'error' }]
          },
        ),
      )

      return {
        remoteImageStore: {
          ...store.remoteImageStore,
          dynamicImageById: {
            ...store.remoteImageStore.dynamicImageById,
            ...updatedDynamicImages,
          },
        },
      }
    })
  }
}
