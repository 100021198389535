import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  apply_layout: {
    'en-GB': (name: string) => `Apply Layout ${name}`,
    'nl-NL': (name: string) => `Lay-out ${name} toepassen`,
  },
  blank_layout: {
    'en-GB': `Blank`,
    'nl-NL': `Blanco`,
  },
}

export const useLocaleText = createLocaleTextHook({ dictionary })
