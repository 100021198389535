import {
  DesignElement,
  DesignElements,
} from '@moonpig/web-personalise-editor-types'

type Layer = 'overlay-top' | 'overlay-main' | 'background'

type Layers = { [layer in Layer]: string[] }

const getElementLayerGroup = (element: DesignElement): Layer => {
  switch (element.type) {
    case 'overlay-inlay':
    case 'media': {
      return 'overlay-top'
    }
    case 'overlay-text': {
      return element.fixed ? 'background' : 'overlay-main'
    }
    case 'overlay-image':
    case 'sticker': {
      return 'overlay-main'
    }
    default: {
      return 'background'
    }
  }
}

export const groupElementsByLayer = (elements: DesignElements): Layers => {
  const layers: Layers = {
    'overlay-top': [],
    'overlay-main': [],
    background: [],
  }

  elements.elementIds.forEach(elementId => {
    layers[getElementLayerGroup(elements.elementById[elementId])].push(
      elementId,
    )
  })

  return layers
}

export const sortElementsByLayer = (
  elements: DesignElements,
): DesignElements => {
  const layers = groupElementsByLayer(elements)

  return {
    elementById: elements.elementById,
    elementIds: [
      ...layers.background,
      ...layers['overlay-main'],
      ...layers['overlay-top'],
    ],
  }
}
