import React, { FC } from 'react'

export const IconChevronUp: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 28 28" width={width} height={height}>
      <path
        clipRule="evenodd"
        d="M5.293 18.707a1 1 0 0 0 1.414 0L14 11.414l7.293 7.293a1 1 0 0 0 1.414-1.414l-8-8a1 1 0 0 0-1.414 0l-8 8a1 1 0 0 0 0 1.414z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
