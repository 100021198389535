import { useCallback, MouseEvent, PointerEvent, TouchEvent } from 'react'

export const useConsumeTouch = () => {
  const bindConsumeTouch = useCallback(() => {
    return {
      onPointerDown: (event: PointerEvent) => {
        event.stopPropagation()
      },
      onTouchStart: (event: TouchEvent) => {
        event.stopPropagation()
      },
      onMouseDown: (event: MouseEvent) => {
        event.stopPropagation()
      },
      onClick: (event: MouseEvent) => {
        event.stopPropagation()
      },
    }
  }, [])

  return bindConsumeTouch
}
