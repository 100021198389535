import { IllustrationPencil } from '@moonpig/launchpad-assets'
import { LoadingScreen } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { useEditorLocaleText } from '../../text-localisation'

export const Loading: FC = () => {
  const t = useEditorLocaleText()

  return (
    <LoadingScreen
      title={t('loading')}
      description={t('loading_description')}
      icon={<IllustrationPencil />}
    />
  )
}
