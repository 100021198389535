import {
  IconAudio,
  IconHandwriting,
  IconLayout,
  IconPhotos,
  IconStickers,
  IconText,
  IconVideo,
} from '@moonpig/web-personalise-components'
import {
  DesignElementMedia,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import shallow from 'zustand/shallow'
import { useCheckIfMaxElementLimitReached } from '../../../../../../../utils/useCheckIfMaxElementLimitReached'
import {
  OnboardingViewed,
  UIDefault,
  UIDefaultMenuItem,
  useAction,
  useEditorFeatures,
  useView,
} from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import {
  useCheckIfFeatureIsAvailable,
  useCheckIfFeatureIsAvailableOnAnyScene,
} from '../../../../selectors'
import {
  ToolbarItem,
  ToolbarMenu,
  ToolbarOrientation,
  ToolbarTooltip,
} from '../Toolbar'
import { ToolbarCommon } from '../ToolbarCommon'
import { AudioInput } from './AudioInput'
import { AudioOnboarding } from './AudioOnboading/AudioOnboarding'
import { AudioOnboardingBanner } from './AudioOnboading/AudioOnboardingBanner'
import { AudioUpload } from './AudioUpload'
import { LayoutsLibrary } from './LayoutsLibrary'
import { VideoOnboarding, VideoOnboardingBanner } from './VideoOnboarding'
import { VideoUpload } from './VideoUpload'
import { useAddTextElement } from './useAddTextElement'
import { HandwritingOnboarding } from './HandwritingOnboarding'
import { HandwritingOnboardingBanner } from './HandwritingOnboarding/HandwritingOnboardingBanner'

type ToolbarDefaultProps = {
  orientation: ToolbarOrientation
  ui: UIDefault
}

export const ToolbarDefault: FC<ToolbarDefaultProps> = ({
  orientation,
  ui,
}) => {
  const t = useEditorLocaleText()
  const overlayStickersFeatureAvailable =
    useCheckIfFeatureIsAvailable('stickers')
  const handWritingFeatureAvailable =
    useCheckIfFeatureIsAvailable('handwriting')
  const overlayImageFeatureAvailable =
    useCheckIfFeatureIsAvailable('overlay-image')
  const overlayTextFeatureAvailable =
    useCheckIfFeatureIsAvailable('overlay-text')
  const videoFeatureAvailable = useCheckIfFeatureIsAvailableOnAnyScene('video')
  const audioFeatureAvailable = useCheckIfFeatureIsAvailableOnAnyScene('audio')
  const layoutsFeatureAvailable = useCheckIfFeatureIsAvailable('layouts')
  const { enableOnboarding } = useEditorFeatures()

  const setUI = useAction('setUI')
  const notify = useAction('notify')
  const selectElement = useAction('selectElement')
  const trackEvent = useAction('trackEvent')
  const updateOnboardingViewed = useAction('updateOnboardingViewed')
  const { selectedMenuItem } = ui
  const onboardingViewed = useView('main', view => view.onboardingViewed)
  const productKey = useView('main', view => view.design.productKey)
  const addTextElement = useAddTextElement()

  const dismissFeaturesOnboarding = useCallback(
    (feature: Partial<OnboardingViewed>) => {
      updateOnboardingViewed(feature)
    },
    [updateOnboardingViewed],
  )

  const dismissAllOnboardingTooltips = useCallback(() => {
    updateOnboardingViewed({
      text: true,
      layouts: true,
      photosAndStickers: true,
      video: true,
    })
  }, [updateOnboardingViewed])

  useEffect(() => {
    if (
      !onboardingViewed.text ||
      !onboardingViewed.photosAndStickers ||
      !onboardingViewed.layouts
    ) {
      window.addEventListener('keydown', dismissAllOnboardingTooltips)

      return () => {
        window.removeEventListener('keydown', dismissAllOnboardingTooltips)
      }
    }
  }, [onboardingViewed, dismissAllOnboardingTooltips])

  useEffect(() => {
    if (!onboardingViewed.audio && audioFeatureAvailable && !enableOnboarding) {
      setUI({ type: 'default', selectedMenuItem: 'audio-onboarding' })
      updateOnboardingViewed({ audio: true })
      trackEvent({
        type: 'IMPRESSION',
        kind: 'AUDIO_ONBOARDING',
        productKey,
      })
    }
  }, [
    onboardingViewed.audio,
    productKey,
    setUI,
    trackEvent,
    updateOnboardingViewed,
    audioFeatureAvailable,
    enableOnboarding,
    onboardingViewed,
  ])

  const stickersAvailable = useView(
    'main',
    ({ stickers }) => stickers.length > 0,
  )

  const { maxElementAdded: maximumOverlayTextAdded } =
    useCheckIfMaxElementLimitReached('overlay-text', 'maxTextCount')

  const designAudioMediaElementRef = useView(
    'main',
    (view): DesignElementRef<DesignElementMedia> | null => {
      // eslint-disable-next-line no-restricted-syntax
      for (const sceneId of view.design.sceneIds) {
        const scene = view.design.sceneById[sceneId]
        // eslint-disable-next-line no-restricted-syntax
        for (const elementId of scene.elementIds) {
          const element = scene.elementById[elementId]
          if (element.type === 'media' && element.kind === 'audio') {
            return {
              type: 'media',
              id: element.id,
              sceneId: element.sceneId,
            }
          }
        }
      }

      return null
    },
    shallow,
  )

  const designVideoMediaElementRef = useView(
    'main',
    (view): DesignElementRef<DesignElementMedia> | null => {
      // eslint-disable-next-line no-restricted-syntax
      for (const sceneId of view.design.sceneIds) {
        const scene = view.design.sceneById[sceneId]
        // eslint-disable-next-line no-restricted-syntax
        for (const elementId of scene.elementIds) {
          const element = scene.elementById[elementId]
          if (element.type === 'media' && element.kind === 'video') {
            return {
              type: 'media',
              id: element.id,
              sceneId: element.sceneId,
            }
          }
        }
      }

      return null
    },
    shallow,
  )

  let currentVideoMenuItem: UIDefaultMenuItem
  if (ui.selectedMenuItem === 'video-upload') {
    currentVideoMenuItem = 'video-upload'
  } else {
    currentVideoMenuItem = 'video-onboarding'
  }

  let currentAudioMenuItem: UIDefaultMenuItem
  if (ui.selectedMenuItem === 'audio-input') {
    currentAudioMenuItem = 'audio-input'
  } else {
    currentAudioMenuItem = 'audio-onboarding'
  }

  const items = useMemo<
    ToolbarItem<UIDefaultMenuItem>[]
  >((): ToolbarItem<UIDefaultMenuItem>[] => {
    const arr: ToolbarItem<UIDefaultMenuItem>[] = []

    if (layoutsFeatureAvailable) {
      arr.push({
        type: 'menu',
        id: 'layout-picker',
        label: t('toolbar_layout_label'),
        ariaLabel: t('toolbar_select_layout'),
        icon: <IconLayout />,
        disabled: false,
      })
    }

    if (overlayTextFeatureAvailable) {
      arr.push({
        type: 'button',
        id: 'overlay-text',
        label: t('toolbar_text_label'),
        ariaLabel: t('toolbar_text_label'),
        icon: <IconText />,
        disabled: false,
      })
    }

    if (overlayImageFeatureAvailable) {
      arr.push({
        type: 'button',
        id: 'overlay-image',
        label: t('toolbar_image_photos_label'),
        ariaLabel: t('toolbar_add_photo'),
        icon: <IconPhotos />,
        disabled: false,
      })
    }

    if (overlayStickersFeatureAvailable) {
      arr.push({
        type: 'menu',
        id: 'stickers',
        label: t('toolbar_stickers_label'),
        ariaLabel: t('toolbar_stickers_label'),
        icon: <IconStickers />,
        disabled: false,
      })
    }

    if (videoFeatureAvailable) {
      arr.push({
        type: 'menu',
        id: currentVideoMenuItem,
        label: t('toolbar_video_label'),
        ariaLabel: t('toolbar_video_label'),
        icon: <IconVideo />,
        disabled: false,
      })
    }

    if (audioFeatureAvailable) {
      arr.push({
        type: 'menu',
        id: currentAudioMenuItem,
        label: t('toolbar_audio_label'),
        ariaLabel: t('toolbar_audio_label'),
        icon: <IconAudio />,
        disabled: false,
      })
    }

    if (handWritingFeatureAvailable) {
      arr.push({
        type: 'menu',
        id: 'handwriting',
        label: t('toolbar_handwriting_label'),
        ariaLabel: t('toolbar_handwriting_label'),
        icon: <IconHandwriting />,
        disabled: false,
      })
    }

    return arr
  }, [
    layoutsFeatureAvailable,
    overlayTextFeatureAvailable,
    overlayImageFeatureAvailable,
    overlayStickersFeatureAvailable,
    handWritingFeatureAvailable,
    videoFeatureAvailable,
    audioFeatureAvailable,
    t,
    currentVideoMenuItem,
    currentAudioMenuItem,
  ])

  const selectedSceneId = useView('main', view => view.selectedSceneId)
  const menu = useMemo<ToolbarMenu | undefined>(() => {
    switch (ui.selectedMenuItem) {
      case 'video-onboarding': {
        const titleId = 'mp-menu-heading-video'

        return {
          title: t('toolbar_menu_add_video_label'),
          titleId,
          banner: <VideoOnboardingBanner />,
          content: <VideoOnboarding />,
          closeLabel: t('toolbar_menu_close_video_label'),
          drawerHeight: 'expand',
          drawerOverlapHandle: true,
        }
      }
      case 'audio-onboarding': {
        const titleId = 'mp-menu-heading-audio'

        return {
          title: t('toolbar_menu_add_audio_label'),
          titleId,
          banner: <AudioOnboardingBanner />,
          content: <AudioOnboarding />,
          closeLabel: t('toolbar_menu_close_audio_label'),
          drawerHeight: 'expand',
          drawerOverlapHandle: true,
        }
      }
      case 'video-upload': {
        const titleId = 'mp-menu-heading-video'

        return {
          title: t('toolbar_menu_preview_video_label'),
          titleId,
          content: (
            <VideoUpload
              video={ui.video}
              existingMediaElement={designAudioMediaElementRef}
            />
          ),
          closeLabel: t('toolbar_menu_close_video_label'),
          drawerHeight: 'expand',
        }
      }
      case 'audio-input': {
        const titleId = 'mp-menu-heading-audio'

        return {
          title: t('toolbar_menu_add_audio_message_label'),
          titleId,
          content: <AudioInput />,
          closeLabel: t('toolbar_menu_close_audio_label'),
          drawerHeight: 'fit',
        }
      }

      case 'audio-upload': {
        const titleId = 'mp-menu-heading-audio'

        return {
          title: t('toolbar_menu_add_audio_message_label'),
          titleId,
          content: (
            <AudioUpload
              audio={ui.audio}
              existingMediaElement={designVideoMediaElementRef}
            />
          ),
          closeLabel: t('toolbar_menu_close_audio_label'),
          drawerHeight: 'fit',
        }
      }

      case 'layout-picker': {
        const titleId = 'mp-menu-heading-layout-picker'

        return {
          title: t('toolbar_layout_label'),
          content: (
            <LayoutsLibrary labelledById={titleId} sceneId={selectedSceneId} />
          ),
          closeLabel: t('toolbar_close_layouts_menu'),
        }
      }
      case 'handwriting': {
        const titleId = 'mp-menu-heading-handwriting'

        return {
          title: t('toolbar_menu_add_handwriting_label'),
          titleId,
          drawerHeight: 'expand',
          drawerOverlapHandle: true,
          banner: <HandwritingOnboardingBanner />,
          content: <HandwritingOnboarding />,
          closeLabel: t('toolbar_menu_close_handwriting_label'),
        }
      }
    }
  }, [
    ui,
    t,
    selectedSceneId,
    designAudioMediaElementRef,
    designVideoMediaElementRef,
  ])

  const handleSelectItem = useCallback(
    (itemId: UIDefaultMenuItem | null) => {
      dismissAllOnboardingTooltips()
      switch (itemId) {
        case 'stickers': {
          if (!stickersAvailable) {
            notify({ type: 'stickers-unavailable' })
            return
          }

          trackEvent({
            type: 'SELECT_CONTENT',
            kind: 'ADD_STICKER',
            productKey,
          })

          setUI({
            ...ui,
            type: 'sticker',
            elementRef: null,
            selectedMenuItem: itemId,
            sceneId: selectedSceneId,
          })
          break
        }
        case 'layout-picker': {
          trackEvent({
            type: 'SELECT_CONTENT',
            kind: 'ADD_LAYOUT',
            productKey,
          })

          setUI({
            type: 'default',
            selectedMenuItem: 'layout-picker',
            sceneId: selectedSceneId,
          })
          break
        }
        case 'handwriting': {
          setUI({
            type: 'default',
            selectedMenuItem: 'handwriting',
          })
          break
        }
        case 'overlay-image': {
          trackEvent({
            type: 'SELECT_CONTENT',
            kind: 'ADD_PHOTO',
            productKey,
          })

          setUI({
            type: 'overlay-image',
            selectedMenuItem: 'photo-library',
            elementRef: null,
            sceneId: selectedSceneId,
          })
          break
        }
        case 'overlay-text': {
          trackEvent({
            type: 'SELECT_CONTENT',
            kind: 'ADD_TEXT',
            productKey,
          })

          if (maximumOverlayTextAdded) {
            notify({ type: 'maximum_overlay_text_added' })
            return
          }

          addTextElement()

          break
        }
        case 'video-onboarding': {
          trackEvent({
            type: 'SELECT_CONTENT',
            kind: 'VIDEO_CLICK',
            productKey,
            label: 'toolbar',
          })

          if (designVideoMediaElementRef) {
            selectElement(
              designVideoMediaElementRef.sceneId,
              designVideoMediaElementRef.id,
            )
          } else if (designAudioMediaElementRef) {
            notify({ type: 'audio-already-exists' })
          } else {
            setUI({
              type: 'default',
              selectedMenuItem: 'video-onboarding',
            })
          }

          break
        }
        case 'audio-onboarding': {
          trackEvent({
            type: 'SELECT_CONTENT',
            kind: 'AUDIO_CLICK',
            productKey,
            label: 'toolbar',
          })
          if (designAudioMediaElementRef) {
            selectElement(
              designAudioMediaElementRef.sceneId,
              designAudioMediaElementRef.id,
            )
          } else if (designVideoMediaElementRef) {
            notify({ type: 'video-already-exists' })
          } else {
            setUI({
              type: 'default',
              selectedMenuItem: 'audio-onboarding',
            })
          }

          break
        }
        default: {
          setUI({
            type: 'default',
            selectedMenuItem: null,
          })
        }
      }
    },
    [
      dismissAllOnboardingTooltips,
      stickersAvailable,
      trackEvent,
      productKey,
      setUI,
      ui,
      selectedSceneId,
      notify,
      maximumOverlayTextAdded,
      addTextElement,
      designVideoMediaElementRef,
      selectElement,
      designAudioMediaElementRef,
    ],
  )

  const onboardingTooltips = useMemo(() => {
    if (enableOnboarding) {
      return []
    }
    const tooltips: ToolbarTooltip[] = []

    const layoutMenuItem = items.find(
      menuItem => menuItem.id === 'layout-picker',
    )

    const videoMenuItem = items.find(
      menuItem => menuItem.id === 'video-onboarding',
    )
    const textMenuItem = items.find(menuItem => menuItem.id === 'overlay-text')
    const photosOrStickers = items.find(menuItem =>
      ['stickers', 'overlay-image'].includes(menuItem.id),
    )

    if (!onboardingViewed.layouts && layoutMenuItem) {
      tooltips.push({
        content: t('tooltip_choose_layout'),
        itemIndex: items.indexOf(layoutMenuItem),
        onDismiss: dismissAllOnboardingTooltips,
        done: {
          label: t('tooltip_onboarding_tooltip_skip_label'),
          onDone: dismissAllOnboardingTooltips,
          ariaLabel: t('tooltip_onboarding_skip_aria_label'),
        },
        next: {
          label: t('tooltip_onboarding_tooltip_next_label'),
          ariaLabel: t('tooltip_onboarding_next_aria_label'),
          onNext: () => dismissFeaturesOnboarding({ layouts: true }),
        },
      })
    }

    if (!onboardingViewed.text && textMenuItem) {
      tooltips.push({
        content: t('tooltip_add_text_in_card'),
        itemIndex: items.indexOf(textMenuItem),
        onDismiss: dismissAllOnboardingTooltips,
        done: {
          label: t('tooltip_onboarding_tooltip_skip_label'),
          onDone: dismissAllOnboardingTooltips,
          ariaLabel: t('tooltip_onboarding_skip_aria_label'),
        },
        next: {
          ariaLabel: t('tooltip_onboarding_next_aria_label'),
          label: t('tooltip_onboarding_tooltip_next_label'),
          onNext: () => dismissFeaturesOnboarding({ text: true }),
        },
      })
    }

    if (!onboardingViewed.photosAndStickers && photosOrStickers) {
      tooltips.push({
        content: t('tooltip_add_photos_inside_card'),
        itemIndex: items.indexOf(photosOrStickers),
        onDismiss: dismissAllOnboardingTooltips,
        done: {
          label: t('tooltip_onboarding_tooltip_skip_label'),
          onDone: dismissAllOnboardingTooltips,
          ariaLabel: t('tooltip_onboarding_skip_aria_label'),
        },
        next: {
          label: t('tooltip_onboarding_tooltip_next_label'),
          onNext: () => dismissFeaturesOnboarding({ photosAndStickers: true }),
          ariaLabel: t('tooltip_onboarding_skip_aria_label'),
        },
      })
    }

    if (!onboardingViewed.video && videoMenuItem) {
      tooltips.push({
        content: t('video_onboarding_tooltip_text'),
        itemIndex: items.indexOf(videoMenuItem),
        onDismiss: dismissAllOnboardingTooltips,
        done: {
          label: t('tooltip_onboarding_tooltip_done_label'),
          onDone: dismissAllOnboardingTooltips,
          ariaLabel: t('tooltip_onboarding_dismiss_aria_label'),
        },
      })
    }

    const lastTooltip = tooltips[tooltips.length - 1]
    if (lastTooltip) {
      delete lastTooltip.next
    }
    return tooltips
  }, [
    enableOnboarding,
    items,
    onboardingViewed,
    t,
    dismissAllOnboardingTooltips,
    dismissFeaturesOnboarding,
  ])

  return (
    <ToolbarCommon
      label="Toolbar"
      orientation={orientation}
      items={items}
      selectedItemId={selectedMenuItem}
      onSelectItem={handleSelectItem}
      menu={menu}
      tooltip={onboardingTooltips[0]}
    />
  )
}
