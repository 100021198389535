const PAD_PERCENT = 0.18

export const getLayoutPad = (
  scenes: { width: number; height: number }[],
): number => {
  const largestSceneSideSize = scenes.reduce<number>((acc, scene) => {
    return Math.max(acc, scene.width, scene.height)
  }, 0)

  const pad = largestSceneSideSize * PAD_PERCENT

  return pad
}
