import React, { FC } from 'react'

export const IconStickers: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 24 24" width={width} height={height}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5V2h10a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10h3a5 5 0 0 0 5-5ZM1 8l7-7 1-1h11a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V9l1-1Zm4 0H3.828L8 3.828V5a3 3 0 0 1-3 3Zm14 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM5.977 14c-.477 0-.81.472-.595.899C6.609 17.333 9.112 19 12 19c2.913 0 5.435-1.696 6.65-4.164.195-.398-.113-.836-.557-.836a.687.687 0 0 0-.603.382c-1.031 2.001-3.103 3.368-5.49 3.368-2.376 0-4.348-1.353-5.372-3.338a.74.74 0 0 0-.65-.412Z"
        fill="currentColor"
      />
    </svg>
  )
}
