import { DesignSticker } from '@moonpig/web-personalise-editor-types'

export const stickers: DesignSticker[] = [
  {
    id: 'v2-default-550f383f410df796',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/041ed5aecc306a8a',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/454e02ac05072717',
  },
  {
    id: 'v2-default-dd98c56d87c352ef',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/c4db29dd82cd0662',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/5929d23c7375370f',
  },
  {
    id: 'v2-default-30b0a0b644b452ef',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/88ad014f683d6015',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/9d1b3e8f88f68daa',
  },
  {
    id: 'v2-default-54d782da77fd0175',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/c5c056f126f95ed2',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/ed0ee2885f78f38c',
  },
  {
    id: 'v2-default-b8157214dae0433c',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/9109eec7c914f65c',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/b838812dd0e6f890',
  },
  {
    id: 'v2-default-643d085b54014ab3',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/378d71c9b8326cb8',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/fbeb223eeb9f363d',
  },
  {
    id: 'v2-default-4a8ef6ccf64fd731',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/a94e2f06cee6bd17',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/712470f59fa45154',
  },
  {
    id: 'v2-default-196f2e55a5290d78',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/38840a1c68eceb70',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/3e541dc9812e320c',
  },
  {
    id: 'v2-default-f0526c71ac3b4fa6',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/e9d15a59e4397e1f',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/90ff732ee86334ae',
  },
  {
    id: 'v2-default-a5d5ce715336ba90',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/c311f7c9cd5d1c9d',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/e30fba5bfa1153e8',
  },
  {
    id: 'v2-default-61d466fbae8aec27',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/448c286917efb9db',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/1e2aa966df318ae5',
  },
  {
    id: 'v2-default-8a2c8bd7e0cc0d77',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/1bbb5fcb3a978b9b',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/6e0ce5ccbdf7be2f',
  },
  {
    id: 'v2-default-6a976061202e80aa',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/294d60db2a252bb3',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/41843f6d5adf8156',
  },
  {
    id: 'v2-default-bb85de50842bd94d',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/3deb5d92c8405203',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/21ea82c2775a924f',
  },
  {
    id: 'v2-default-514bde3a3231f662',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/4ba8d1c191ee4729',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/cb5b5b4d62bb68b8',
  },
  {
    id: 'v2-default-9677e215b0ed88c2',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/c9f340520ee1195f',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/c9ab4a17765bcc08',
  },
  {
    id: 'v2-default-b13e923b97b0c45c',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/81ce3b85789d9f77',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/031d97ebb126baf6',
  },
  {
    id: 'v2-default-da6c39f73c773a26',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/d50b600d3e04abc1',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/f429b30155c00c00',
  },
  {
    id: 'v2-default-fb1335a0270d1274',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/16dc2ae831af11c2',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/bb647e8ec0676e29',
  },
  {
    id: 'v2-default-56dfb438f36c3f72',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/c712d8f44c7b2e7d',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/92d8ecc6b50cd071',
  },
  {
    id: 'v2-default-9be43f561c4d632c',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/e8363b730d37e9e6',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/750c13d84b09c3e5',
  },
  {
    id: 'v2-default-ffdd715cc688312e',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/2cc9c316a2c29ebb',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/15024df4782ab012',
  },
  {
    id: 'v2-default-071ba13531aaffd1',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/de695943d23dd0c1',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/961a3e1aa601b799',
  },
  {
    id: 'v2-default-4281d555f09add78',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/532c271a23100137',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/020592227c9e2260',
  },
  {
    id: 'v2-default-1f4395b7ad031fd2',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/caf46057bd29aa83',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/6c8e556ff094ce6b',
  },
  {
    id: 'v2-default-9a96278e694b9043',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/9846e32ed2f824f3',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/372b11db27d24baa',
  },
  {
    id: 'v2-default-56b963d3593a9811',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/0fc7ff16536caa00',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/de9344e65bc6fdbe',
  },
  {
    id: 'v2-default-65e1744f51000926',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/18103c678a57d7da',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/7bc506c0f584ddf7',
  },
  {
    id: 'v2-default-a81ad8819e5452f7',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/ab7996285b0a12ca',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/0ea81d8929cc6fbc',
  },
  {
    id: 'v2-default-9e51d216692b2155',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/068b932a6d7551bd',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/e769d9ec5a75ac99',
  },
  {
    id: 'v2-default-f40df00df9f54c4b',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/f0d8b9e84434a2ab',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/cadcfc040fc01a7f',
  },
  {
    id: 'v2-default-9c238114fa90a43a',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/6f5f685a8004e3cc',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/9edb21ae6059b6c8',
  },
  {
    id: 'v2-default-9a3d24b766f8aca1',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/50b4e76546e032f0',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/6a5f64f822c505c3',
  },
  {
    id: 'v2-default-3a1a348910ef4dd4',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/b7945a343347bc29',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/593a0824f11134bc',
  },
  {
    id: 'v2-default-e48dc91f6dbb8249',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/31b573c95c61ca10',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/e05360d3d420e0a2',
  },
  {
    id: 'v2-default-ec05ad94f478a76e',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/7232144f88b0aacc',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/d74735489cce3d10',
  },
  {
    id: 'v2-default-bef83637cc0c0d0d',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/9d3b2737cc6b5974',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/d6a3cb0ec2afb7ca',
  },
  {
    id: 'v2-default-02e20e62bf5d1d40',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/32328d2405391446',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/d98cebdc611f7245',
  },
  {
    id: 'v2-default-4b7ba28cfc7731d9',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/0cf70904bc5360a1',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/993032c88185e194',
  },
  {
    id: 'v2-default-547e7f3097e0c1fb',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/dc8c901fefed4ed8',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/e25828dbc24cb9d4',
  },
  {
    id: 'v2-default-77035acc10318030',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/99c3a1733713b6a2',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/89eb05d30a660a2d',
  },
  {
    id: 'v2-default-11db0f4fd7e9e04d',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/9be3da5738a05ff6',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/7d8b23943f6ecadd',
  },
  {
    id: 'v2-default-999156ac3e59b049',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/749e407e725354c7',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/be5e7258561f4266',
  },
  {
    id: 'v2-default-4486b0bb81fc5fad',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/a03efbe0778e2c8c',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/d19a6b899047ff3e',
  },
  {
    id: 'v2-default-6340f10e63e8c4b5',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/93018d555c8aa2d7',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/ed58e3ec826f468b',
  },
  {
    id: 'v2-default-82b6f3b07c2f84ad',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/15417f7a229684f2',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/47732886e1d83595',
  },
  {
    id: 'v2-default-ab829ebbb1a666af',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/d58a3e3d2d71ae9e',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/e5b44a4714fe4eaf',
  },
  {
    id: 'v2-default-47d5428c478b4e37',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/38c120637e51756e',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/eb54d571526f5d8c',
  },
  {
    id: 'v2-default-a541aa4537901c1d',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/ab82283459bfa0f8',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/421f39eb24013f3d',
  },
  {
    id: 'v2-default-ab7798973bfc2cdc',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/c9e06b9d8bacb063',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/e123e8f7a8a6a9d3',
  },
  {
    id: 'v2-default-15a659d49d18f0a3',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/31a8d11681a65ddf',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/24501b8aba3a19d6',
  },
  {
    id: 'v2-default-1f47cbb74f075de6',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/f0d84911b8b59dc1',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/3d7075255ae0d199',
  },
  {
    id: 'v2-default-41345dcca27789c0',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/68b826a3ace9d0c3',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/bcdff71ba4424327',
  },
  {
    id: 'v2-default-ccf5dcc4f4ba4dc3',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/cebc2c373b206dab',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/e44101690bb70d49',
  },
  {
    id: 'v2-default-fe9890e9c0d47d6f',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/b7f4ce20e8ab73d6',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/b6b333a4befe9a27',
  },
  {
    id: 'v2-default-7e3b924170fd3bf4',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/cc39a42ab8a600c6',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/ead7d5051fa3a8b0',
  },
  {
    id: 'v2-default-9c5288f8288427bb',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/d2d4e5f9aa396a4b',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/c737768953fa07fe',
  },
  {
    id: 'v2-default-fc9bfc989f81f1d7',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/757db0c89f40d42a',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/9c9f3fe0b7de6dbd',
  },
  {
    id: 'v2-default-63d696b3f2a79508',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/6e7c341d188c368d',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/c19fb1c317ba1808',
  },
  {
    id: 'v2-default-577ff68cc6e006e2',
    url: 'https://eu-west-1.template.prod.moonpig.net/image/800db1587f06b119',
    urlThumbnail:
      'https://eu-west-1.template.prod.moonpig.net/image/8bb55e20a31512aa',
  },
]
