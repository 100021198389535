import { clamp, modNeg } from '@moonpig/common-math'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC, ReactNode, useState } from 'react'
import { useDrag } from 'react-use-gesture'
import { useReducedMotion } from '../../utils'
import { Card3DPosedLandscape, Card3DPosedPortrait } from './Card3DPosed'
import { CHANGE_PAGE_THREHSOLD, PERSPECTIVE_MULTIPLIER } from './constants'
import { getPose } from './pose'
import { Orientation } from './types'

type Card3DProps = {
  orientation: Orientation
  size: { width: number; height: number }
  position: { x: number; y: number }
  renderScene: (input: { sceneIndex: number }) => ReactNode
  activeSceneIndex: number
  onChangeActiveSceneIndex: (facingPageIndex: number) => void
  transitionDuration?: number
}

const StyledOuterContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  /* Ensure card renders behind header and nav in Safari */
  transform: translateZ(0px);
`

type StyledInnerContainerProps = {
  size: { width: number; height: number }
  position: { x: number; y: number }
}

const StyledInnerContainer = styled.div<StyledInnerContainerProps>`
  touch-action: none;
  transform-style: preserve-3d;
  perspective: ${({ size }) =>
    Math.max(size.width, size.height) * PERSPECTIVE_MULTIPLIER}px;
  pointer-events: none;
  position: absolute;
  left: ${({ position }) => position.x}px;
  top: ${({ position }) => position.y}px;
  width: ${({ size }) => size.width}px;
  height: ${({ size }) => size.height}px;
`

const activeSceneIndexToFacing: { [index: number]: number } = {
  0: 0,
  1: 2,
  2: 1,
  3: 3,
}

export const Card3D: FC<Card3DProps> = ({
  orientation,
  size,
  position,
  renderScene,
  activeSceneIndex,
  onChangeActiveSceneIndex,
  transitionDuration,
}) => {
  const reducedMotion = useReducedMotion()
  const [dragPercent, setDragPercent] = useState(0)
  const facing = activeSceneIndexToFacing[activeSceneIndex]
  const isPortrait = orientation === 'PORTRAIT'
  const axisSize = isPortrait ? size.width : size.height

  const bind = useDrag(
    ({ movement: [offsetX, offsetY], last, swipe: [swipeX, swipeY], tap }) => {
      const creaseOffset = isPortrait ? offsetX : offsetY
      const axisSwipe = isPortrait ? swipeX : swipeY

      if (last) {
        setDragPercent(0)
        if (dragPercent > CHANGE_PAGE_THREHSOLD || axisSwipe < 0 || tap) {
          onChangeActiveSceneIndex(
            activeSceneIndexToFacing[modNeg(facing + 1, 4)],
          )
        } else if (dragPercent < -CHANGE_PAGE_THREHSOLD || axisSwipe > 0) {
          onChangeActiveSceneIndex(
            activeSceneIndexToFacing[modNeg(facing - 1, 4)],
          )
        }
      } else {
        setDragPercent(clamp(-creaseOffset / axisSize, -1, 1))
      }
    },
  )

  const animated = dragPercent === 0 && !reducedMotion
  const posePoint = facing + dragPercent
  const pose = getPose(orientation, size, posePoint)

  return (
    <StyledOuterContainer {...bind()} data-testid="mp-editor-card-3d">
      <StyledInnerContainer position={position} size={size}>
        {isPortrait ? (
          <Card3DPosedPortrait
            renderScene={renderScene}
            animated={animated}
            pose={pose}
            transitionDuration={transitionDuration}
          />
        ) : (
          <Card3DPosedLandscape
            renderScene={renderScene}
            animated={animated}
            pose={pose}
            transitionDuration={transitionDuration}
          />
        )}
      </StyledInnerContainer>
    </StyledOuterContainer>
  )
}
