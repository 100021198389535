import {
  Design,
  DesignElementOverlayText,
  DesignScene,
  DesignSceneById,
  ProductFacet,
} from '@moonpig/web-personalise-editor-types'
import { createStarterMessage } from './createStarterMessage'
import { GetEditorLocaleText } from '../../../text-localisation'

const addStarterMessageToScene = ({
  scene,
  starterMessage,
}: {
  scene: DesignScene
  starterMessage: string
}): DesignScene => {
  const textElements = scene.elementIds.flatMap(elementId => {
    const element = scene.elementById[elementId]
    if (element.type === 'overlay-text') {
      return [element]
    }
    return []
  })

  if (textElements.length !== 3) {
    return scene
  }

  const starterMessageElement = textElements[1]

  if (starterMessageElement.type === 'overlay-text') {
    const updatedElement: DesignElementOverlayText = {
      ...starterMessageElement,
      customisations: {
        ...starterMessageElement.customisations,
        text: starterMessage,
        author: 'STARTER',
      },
      fragmentsState: null,
    }

    return {
      ...scene,
      elementById: {
        ...scene.elementById,
        [updatedElement.id]: updatedElement,
      },
      elementIds: [...scene.elementIds],
    }
  }

  // istanbul ignore next Currently unreachable: layouts only create overlay-text elements
  return scene
}

export const addStarterMessageToLayout = (
  design: Design,
  facets: ProductFacet[],
  t: GetEditorLocaleText,
): Design => {
  if (design.modified) {
    return design
  }

  const starterMessage = createStarterMessage(facets, t)

  if (!starterMessage.message) {
    return design
  }

  return {
    ...design,
    sceneById: design.sceneIds.reduce<DesignSceneById>((acc, sceneId) => {
      acc[sceneId] = addStarterMessageToScene({
        scene: design.sceneById[sceneId],
        starterMessage: starterMessage.message,
      })

      return acc
    }, {}),
  }
}
