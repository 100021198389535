import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  play_video: {
    'en-GB': 'Play video',
    'nl-NL': 'Video afspelen',
  },
  play_audio: {
    'en-GB': 'Play audio',
    'nl-NL': 'Audio afspelen',
  },
  replay_video: {
    'en-GB': 'Replay video',
    'nl-NL': 'Video opnieuw afspelen',
  },
  replay_audio: {
    'en-GB': 'Replay audio',
    'nl-NL': 'Audio opnieuw afspelen',
  },
  video_error_title: {
    'en-GB': 'Unable to play video',
    'nl-NL': 'De video kan niet afspelen',
  },
  audio_error_title: {
    'en-GB': 'Unable to play audio',
    'nl-NL': 'Afspelen audio niet mogelijk',
  },
  media_error_description: {
    'en-GB':
      'There might be a problem with the file format or internet connection.',
    'nl-NL':
      'Misschien is er een probleem met het bestand of de internetverbinding.',
  },
  media_error_retry: {
    'en-GB': 'Retry',
    'nl-NL': 'Opnieuw proberen',
  },
}

export const useLocaleText = createLocaleTextHook({ dictionary })
