import { system as s } from '@moonpig/launchpad-system'
import { screenReaderOnly, styled } from '@moonpig/launchpad-utils'
import React, { ChangeEvent, FC, ReactNode, useCallback, useRef } from 'react'
import { useAction, useView } from '../../../../../../../../store'

type VideoInputProps = {
  id: string
  onChange: (file: Blob) => void
  label: ReactNode
}

const StyledFileInput = styled.input`
  ${screenReaderOnly}
`

const StyledFocus = styled.div`
  &:has(:focus-visible) {
    label,
    label:hover {
      ${s({ boxShadow: 5 })}
    }
  }
`

export const VideoInput: FC<VideoInputProps> = ({ id, onChange, label }) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const notify = useAction('notify')
  const trackEvent = useAction('trackEvent')

  const { maxSizeBytes, supportedMimeTypes } = useView(
    'main',
    ({ customisationConfig }) => customisationConfig.video,
  )

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.currentTarget
      if (!files || files.length === 0) {
        return
      }

      const file = files[0]

      if (file.size > maxSizeBytes) {
        notify({
          type: 'video-file-too-large',
          maxSizeBytes,
        })
        trackEvent({
          type: 'ERROR',
          kind: 'VIDEO_TOO_LARGE_ERROR',
        })
      } else {
        onChange(file)
      }

      // Reset file input to default state
      // eslint-disable-next-line no-param-reassign
      event.currentTarget.value = ''
    },
    [maxSizeBytes, notify, onChange, trackEvent],
  )

  return (
    <StyledFocus>
      {label}
      <StyledFileInput
        ref={inputRef}
        id={id}
        type="file"
        accept={supportedMimeTypes.join(',')}
        onChange={handleInputChange}
      />
    </StyledFocus>
  )
}
