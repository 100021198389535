import React, { FC, ReactNode, useEffect, useState } from 'react'
import { DURATION } from './constants'
import { Scene } from './Scene'
import { Layout, MediaKind } from './types'

type MediaCardDemoProps = {
  layout: Layout
  renderScene: (input: { sceneIndex: number }) => ReactNode
  demoVideoUrl: string
  mediaKind: MediaKind
}

export const MediaCardDemo: FC<MediaCardDemoProps> = ({
  layout,
  renderScene,
  demoVideoUrl,
  mediaKind,
}) => {
  const [iteration, setIteration] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIteration(curr => curr + 1)
    }, DURATION)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <Scene
      key={iteration}
      layout={layout}
      renderScene={renderScene}
      demoVideoUrl={demoVideoUrl}
      mediaKind={mediaKind}
    />
  )
}
