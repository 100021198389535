import { toError } from '@moonpig/web-core-utils'

export const wrapError = (context: string, error: unknown): Error => {
  return new Error(`${context}: ${toError(error).message}`)
}

export const wrapFunctionError = <TArgs extends unknown[], TResult>(
  context: string,
  fn: (...args: TArgs) => TResult,
): ((...args: TArgs) => TResult) => {
  return (...args) => {
    try {
      return fn(...args)
    } catch (error) {
      throw wrapError(context, error)
    }
  }
}

export const wrapAsyncFunctionError = <TArgs extends unknown[], TResult>(
  context: string | ((...args: TArgs) => string),
  fn: (...args: TArgs) => Promise<TResult>,
): ((...args: TArgs) => Promise<TResult>) => {
  return async (...args) => {
    try {
      return await fn(...args)
    } catch (error) {
      const contextString =
        typeof context === 'string' ? context : context(...args)

      throw wrapError(contextString, error)
    }
  }
}
