import {
  getAvailableGrids,
  getImageIds,
} from '@moonpig/web-personalise-collage'
import { LayoutPicker } from '@moonpig/web-personalise-components'
import {
  DesignElementImageCollage,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useMemo } from 'react'
import { assert } from '../../../../../../../../utils/assert'
import { useAction, useView } from '../../../../../../../../store'
import { useElementRef } from '../../../../../../selectors'
import { getAvailableLayouts } from '../../utils/getAvailableLayouts'

type ImageCollageLayoutPickerProps = {
  elementRef: DesignElementRef<DesignElementImageCollage>
  labelledById: string
}

export const ImageCollageLayoutPicker: FC<ImageCollageLayoutPickerProps> = ({
  elementRef,
  labelledById,
}) => {
  const updateElement = useAction('updateElement')
  const element = useElementRef(elementRef)

  const customisationsGrid = element.customisations.grid
  assert(customisationsGrid !== null)

  const availableCollageGrids = useView(
    'main',
    view => view.customisationConfig.availableCollageGrids,
  )

  const grids = useMemo(
    () =>
      getAvailableGrids(availableCollageGrids, getImageIds(customisationsGrid)),
    [availableCollageGrids, customisationsGrid],
  )

  const handleSelectGrid = useCallback(
    (selectedGridId: string) => {
      const grid = grids.find(foundGrid => foundGrid.id === selectedGridId)
      assert(grid !== undefined)
      updateElement({
        ...element,
        customisations: {
          ...element.customisations,
          pendingEditedImage: null,
          grid: {
            ...grid,
            background: customisationsGrid.background,
            border: customisationsGrid.border,
          },
        },
      })
    },
    [
      grids,
      updateElement,
      element,
      customisationsGrid.background,
      customisationsGrid.border,
    ],
  )

  const layouts = useMemo(
    () =>
      getAvailableLayouts({
        grids,
        width: element.width,
        height: element.height,
        selectedGridId: customisationsGrid.id,
      }),
    [grids, element, customisationsGrid],
  )

  return (
    <LayoutPicker
      onSelect={id => handleSelectGrid(id)}
      layouts={layouts}
      labelledById={labelledById}
    />
  )
}
