import { Services } from '@moonpig/web-personalise-editor-types'
import { GetState, SetState } from 'zustand'
import { assert } from '../../../utils/assert'
import { performance } from '../../../utils/performance'
import { addNotification } from '../../common/addNotification'
import { createEditorEventState } from '../../common/createEditorEventState'
import { uploadDesignImages } from '../../common/uploadDesignImages'
import { validateMissingRequired } from '../../common/validateMissingRequired'
import { ActionCreator, Store } from '../../types'

const saveAndExit = async ({
  services,
  set,
  get,
}: {
  services: Services
  set: SetState<Store>
  get: GetState<Store>
}) => {
  performance.measureStart('editor-exit-save')

  set(({ view }) => {
    assert(view.type === 'main')

    const { design, photos } = view

    return {
      view: {
        ...view,
        design: uploadDesignImages({ services, design, photos }),
        view: { type: 'loading-exit', saving: true },
      },
    }
  })

  const store = get()

  assert(store.view.type === 'main')

  await services.saveDesign(store.view.design)

  performance.measureEnd('editor-exit-save')

  await services.exit()
}

const deleteAndExit = async ({
  services,
  set,
  get,
}: {
  services: Services
  set: SetState<Store>
  get: GetState<Store>
}) => {
  performance.measureStart('editor-exit-delete')

  set(({ view }) => {
    assert(view.type === 'main')

    return {
      view: {
        ...view,
        view: { type: 'loading-exit', saving: false },
      },
    }
  })

  const store = get()

  assert(store.view.type === 'main')

  await services.deleteDesign({
    id: store.view.design.id,
    productKey: store.view.design.productKey,
  })

  performance.measureEnd('editor-exit-delete')

  await services.exit()
}

const exitWithoutSaving = async ({
  services,
  set,
}: {
  services: Services
  set: SetState<Store>
}) => {
  set(({ view }) => {
    assert(view.type === 'main')

    return {
      view: {
        ...view,
        view: { type: 'loading-exit', saving: false },
      },
    }
  })

  await services.exit()
}

export const createExit: ActionCreator<'exit'> =
  ({ services, set, get }) =>
  async mode => {
    const store = get()

    assert(store.view.type === 'main')

    const { design, designState, photos, selectedSceneId } = store.view

    services.trackEvent({
      type: 'EXIT',
      save: mode === 'SAVE_AND_EXIT',
      state: createEditorEventState({
        design,
        designState,
        photos,
        selectedSceneId,
      }),
    })

    switch (mode) {
      case 'SAVE_AND_EXIT': {
        const { firstInvalidElement } = validateMissingRequired(
          store.view.design,
        )

        if (firstInvalidElement && designState.type === 'in-basket') {
          set(({ view }) => {
            assert(view.type === 'main')

            return {
              view: {
                ...view,
                notifications: addNotification(view.notifications, {
                  type: 'missing-required',
                  kind: firstInvalidElement.kind,
                }),
                selectedSceneId: firstInvalidElement.sceneId,
                view: {
                  type: 'edit',
                  activeElementId: null,
                  ui: null,
                  modal: null,
                  highlightInvalidElements: true,
                },
              },
            }
          })
        } else {
          await saveAndExit({ services, set, get })
        }

        break
      }
      case 'DELETE_AND_EXIT': {
        await deleteAndExit({ services, set, get })

        break
      }
      case 'EXIT': {
        await exitWithoutSaving({ services, set })

        break
      }
    }
  }
