import { radToDeg } from '@moonpig/common-math'
import { styled, useTheme } from '@moonpig/launchpad-utils'
import { Transform } from '@moonpig/use-transform'
import { IconDelete } from '@moonpig/web-personalise-components'
import { DesignElement } from '@moonpig/web-personalise-editor-types'
import React, { MouseEvent, useCallback } from 'react'
import {
  DELETE_HANDLE_LENGTH,
  DELETE_HANDLE_SIZE,
  MIN_TOUCH_SIZE,
} from '../constants'

const StyledLink = styled.a<{ borderColor: string }>`
  outline: none;
  pointer-events: all;
  cursor: pointer;
  color: ${({ borderColor }) => borderColor};
`

export const DeleteHandle = ({
  transform,
  elementHeight,
  elementWidth,
  screenScale,
  element,
  onClick,
}: {
  transform: Transform
  elementHeight: number
  elementWidth: number
  screenScale: number
  element: DesignElement
  onClick: () => void
}) => {
  const { palette } = useTheme()
  const { colorFeedbackInformation } = palette
  const controlOffsetY = screenScale * DELETE_HANDLE_LENGTH
  const touchSize = MIN_TOUCH_SIZE * screenScale
  const visibleSize = DELETE_HANDLE_SIZE * screenScale
  const touchAreaOffset = (visibleSize - touchSize) * 0.5
  const svgTransform = [
    `translate(${transform.value.position[0]}, ${transform.value.position[1]})`,
    `rotate(${radToDeg(transform.value.rotation)})`,
    `translate(${elementWidth * 0.5 * transform.value.scale[0] + 5} ${-(
      elementHeight * 0.5 * transform.value.scale[1] +
      controlOffsetY
    )})`,
  ].join(' ')

  const handleDelete = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation()
      event.preventDefault()
      onClick()
    },
    [onClick],
  )

  return (
    <StyledLink
      onClick={handleDelete}
      aria-hidden
      tabIndex={-1}
      href="#"
      borderColor={colorFeedbackInformation}
      data-testid={`mp-ed-handle-delete-${element.id}`}
    >
      <g transform={svgTransform}>
        <rect
          x={touchAreaOffset}
          y={touchAreaOffset}
          width={touchSize}
          height={touchSize}
          fill="transparent"
        />
        <IconDelete
          width={DELETE_HANDLE_SIZE * screenScale}
          height={DELETE_HANDLE_SIZE * screenScale}
          background="white"
        />
      </g>
    </StyledLink>
  )
}
