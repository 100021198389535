import {
  IconInformation,
  IconSystemPlayOutline,
} from '@moonpig/launchpad-assets'
import { Flex } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC, ReactNode } from 'react'
import { ScreenReaderOnly } from '@moonpig/web-personalise-components'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'

const ICON_SIZE = 24

type DetailProps = {
  term: string
  definition: string
  definitionLabel: string
  icon: ReactNode
}

const StyledDescriptionTerm = styled.dt`
  display: flex;
  margin: 0;
`

const StyledDescriptionDetails = styled.dd`
  margin: 0;
`

const Detail: FC<DetailProps> = ({
  term,
  definition,
  definitionLabel,
  icon,
}) => {
  return (
    <Flex alignItems="center" gap="4px">
      <StyledDescriptionTerm>
        <ScreenReaderOnly>{term}</ScreenReaderOnly>
        {icon}
      </StyledDescriptionTerm>
      <StyledDescriptionDetails aria-label={definitionLabel}>
        {definition}
      </StyledDescriptionDetails>
    </Flex>
  )
}

type VideoDetailsProps = {
  durationInSeconds: number
  sizeInBytes: number
}

export const VideoDetails: FC<VideoDetailsProps> = ({
  durationInSeconds,
  sizeInBytes,
}) => {
  const t = useEditorLocaleText()

  return (
    <Flex justifyContent="center" gap="32px" pt={6} m={0} as="dl">
      <Detail
        term={t('video_size_label')}
        definition={t('file_size', sizeInBytes)}
        definitionLabel={t('aria_label_video_size', sizeInBytes)}
        icon={
          <IconInformation
            role="presentation"
            width={ICON_SIZE}
            height={ICON_SIZE}
          />
        }
      />
      <Detail
        term={t('video_duration_label')}
        definition={t('label_video_duration', durationInSeconds)}
        definitionLabel={t('aria_label_video_duration', durationInSeconds)}
        icon={<IconSystemPlayOutline width={ICON_SIZE} height={ICON_SIZE} />}
      />
    </Flex>
  )
}
