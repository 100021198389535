import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  color_name_not_found_title: {
    'en-GB': (value: string) => `Hex ${value}`,
    'nl-NL': (value: string) => `Hex ${value}`,
  },
}

export const useLocaleText = createLocaleTextHook({ dictionary })
