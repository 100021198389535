export const byId = <T extends { id: string }>(
  v: T[],
): {
  [id: string]: T
} =>
  v.reduce<{
    [id: string]: T
  }>((acc, curr) => {
    acc[curr.id] = curr
    return acc
  }, {})
