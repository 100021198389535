import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  video_animation_label: {
    'en-GB':
      'Phone scanning a QR code from a card on a mantelpiece to reveal a video message.',
    'nl-NL':
      'Telefoon die een QR-code scant van een kaart op een schoorsteenmantel om een videoboodschap te laten zien.',
  },
  audio_animation_label: {
    'en-GB':
      'Phone scanning a QR code from a card on a mantelpiece to reveal an audio message.',
    'nl-NL':
      'Telefoon die een QR-code scant van een kaart op een schoorsteenmantel om te ontdekken wat het audiobericht is.',
  },
}

export const useLocaleText = createLocaleTextHook({ dictionary })
