import {
  IconSystemBin,
  IconSystemPauseOutline,
  IconSystemPlayOutline,
} from '@moonpig/launchpad-assets'
import {
  Alert,
  Box,
  Flex,
  Heading,
  PrimaryButton,
  TertiaryButton,
  Text,
} from '@moonpig/launchpad-components'
import React, { FC, useState, useCallback, useRef } from 'react'
import {
  AudioPlayerReadyEvent,
  AudioPlayer,
  AudioPlayerContext,
  AudioPlayerState,
} from '@moonpig/web-personalise-components'
import {
  DesignElementMedia,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { useAction, useView } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'

const StyledButtonContainer = styled.div`
  ${s({ pt: 6, pb: 4 })}
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
`

type AudioUploadProps = {
  audio: {
    file: Blob
    url: string
    duration: number
    samples: number[]
    timePerSampleSeconds: number
  }
  existingMediaElement?: DesignElementRef<DesignElementMedia> | null
}

export const AudioUpload: FC<AudioUploadProps> = ({
  audio: { file, url, duration, samples, timePerSampleSeconds },
  existingMediaElement,
}) => {
  const setUI = useAction('setUI')
  const uploadMedia = useAction('uploadMedia')
  const [playerState, setPlayerState] = useState<AudioPlayerState>('paused')
  const t = useEditorLocaleText()
  const deleteElement = useAction('deleteElement')
  const trackEvent = useAction('trackEvent')
  const productKey = useView('main', view => view.design.productKey)
  const audioPlayerContextRef = useRef<AudioPlayerContext | null>(null)

  const audioAspectRatio = useView(
    'main',
    ({ customisationConfig }) => customisationConfig.audio.sceneAspectRatio,
  )

  const handleDelete = useCallback(() => {
    setUI({ type: 'default', selectedMenuItem: 'audio-input' })

    trackEvent({
      type: 'SELECT_CONTENT',
      kind: 'AUDIO_DELETE',
      productKey,
      label: 'modal',
    })
  }, [productKey, setUI, trackEvent])

  const handleReady = useCallback((event: AudioPlayerReadyEvent) => {
    audioPlayerContextRef.current = event.playerContext
  }, [])

  const play = useCallback(() => {
    audioPlayerContextRef.current?.play()
  }, [])

  const handlePlaying = useCallback(() => {
    setPlayerState('playing')
  }, [])

  const handleEnded = useCallback(() => {
    setPlayerState('ended')
  }, [])

  const pause = useCallback(() => {
    audioPlayerContextRef.current?.pause()
  }, [])

  const handlePaused = useCallback(() => {
    setPlayerState('paused')
  }, [])

  const handlePlaybackError = useCallback(() => {
    setPlayerState('error')

    trackEvent({
      type: 'ERROR',
      kind: 'AUDIO_PLAYBACK_ERROR',
    })
  }, [trackEvent])

  const handleUpload = useCallback(() => {
    setUI({ type: 'default', selectedMenuItem: null })

    if (existingMediaElement) deleteElement(existingMediaElement)

    uploadMedia({
      file,
      termsAndConditionsAccepted: true,
      mediaAspectRatio: audioAspectRatio,
      kind: 'audio',
    })

    trackEvent({
      type: 'MEDIA_UPLOAD',
      productKey,
      mediaSizeBytes: file.size,
      mediaDurationSeconds: duration,
      mediaKind: 'audio',
    })
  }, [
    audioAspectRatio,
    deleteElement,
    duration,
    existingMediaElement,
    file,
    productKey,
    setUI,
    trackEvent,
    uploadMedia,
  ])

  if (playerState === 'error') {
    return (
      <Flex
        position="relative"
        width="100%"
        maxWidth="640px"
        marginX="auto"
        height="100%"
        px={6}
        alignContent={'center'}
        justifyContent={'center'}
        flexDirection={'column'}
      >
        <Alert variant={'error'}>
          <Heading level="h4" mb={0}>
            {t('audio_error_title')}
          </Heading>
          <Text typography={'body'}>{t('audio_error_description')}</Text>
        </Alert>

        <TertiaryButton leadingIcon={<IconSystemBin />} onClick={handleDelete}>
          {t('delete_element_label')}
        </TertiaryButton>

        <PrimaryButton disabled width="100%" mt={6} onClick={handleUpload}>
          {t('button_upload_audio')}
        </PrimaryButton>
      </Flex>
    )
  }

  return (
    <Flex flexDirection="column" flex={1}>
      <Text pb={6}>{t('toolbar_menu_audio_playback')}</Text>
      <Box>
        <AudioPlayer
          audioUrl={url}
          samples={samples}
          onReady={handleReady}
          onEnded={handleEnded}
          onError={handlePlaybackError}
          onPaused={handlePaused}
          onPlaying={handlePlaying}
          timePerSampleSeconds={timePerSampleSeconds}
          durationSeconds={duration}
        />
        <StyledButtonContainer>
          {playerState === 'playing' ? (
            <TertiaryButton
              leadingIcon={<IconSystemPauseOutline />}
              onClick={pause}
            >
              {t('button_pause_audio')}
            </TertiaryButton>
          ) : (
            <TertiaryButton
              leadingIcon={<IconSystemPlayOutline />}
              onClick={play}
            >
              {t('button_play_audio')}
            </TertiaryButton>
          )}

          <TertiaryButton
            leadingIcon={<IconSystemBin />}
            onClick={handleDelete}
          >
            {t('delete_element_label')}
          </TertiaryButton>
        </StyledButtonContainer>
      </Box>
      <PrimaryButton width="100%" mt={6} onClick={handleUpload}>
        {t('button_upload_audio')}
      </PrimaryButton>
    </Flex>
  )
}
