import { EditorConfig } from '@moonpig/web-personalise-editor-types'
import { useMemo } from 'react'
import {
  audioMediaDemoImageUrlByLanguage,
  audioMediaDemoVideoUrl,
  handwritingOnboardingImageUrlByLanguage,
  videoMediaDemoImageUrlByLanguage,
  videoMediaDemoVideoUrl,
} from '@moonpig/web-personalise-editor-assets'

export const useEditorConfig = (): EditorConfig => {
  const features = useMemo<EditorConfig>(
    () => ({
      termsAndConditionsUrl: '/404',
      privacyNoticeUrl: '/404',
      userGeneratedTermsAndConditionsUrl: '/404',
      videoMediaDemo: {
        imageUrl: videoMediaDemoImageUrlByLanguage['en-GB'],
        videoUrl: videoMediaDemoVideoUrl,
      },
      handwritingOnboardingImageUrl:
        handwritingOnboardingImageUrlByLanguage['en-GB'],
      audioMediaDemo: {
        imageUrl: audioMediaDemoImageUrlByLanguage['en-GB'],
        videoUrl: audioMediaDemoVideoUrl,
      },
    }),
    [],
  )

  return features
}
