import { Box, TertiaryButton } from '@moonpig/launchpad-components'
import { FontFace, FontFacePicker } from '@moonpig/web-personalise-components'
import {
  ById,
  DesignElementOverlayText,
  DesignElementRef,
  DesignFontFace,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useMemo } from 'react'
import { FontById, useAction, useView } from '../../../../../../../../store'
import { getTextFragmentsChangeType } from '../../../../../../../../store/utils/getTextFragmentsChangeType'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { useElementRef } from '../../../../../../selectors'

const useFontById = (): FontById => {
  return useView('main', ({ fontById }) => {
    return fontById
  })
}

type ElementFontFacePickerProps = {
  elementRef: DesignElementRef<DesignElementOverlayText>
  availableFontFaceIds: string[]
  availableFontFaceById: ById<DesignFontFace>
}

export const ElementFontFacePicker: FC<ElementFontFacePickerProps> = ({
  elementRef,
  availableFontFaceIds,
  availableFontFaceById,
}) => {
  const updateStyledElementStyles = useAction('updateStyledElementStyles')
  const element = useElementRef(elementRef)
  const fontById = useFontById()
  const updateElement = useAction('updateElement')
  const t = useEditorLocaleText()

  const handleSelect = useCallback(
    (fontFaceId: string) => {
      updateElement(
        {
          ...element,
          customisations: {
            ...element.customisations,
            fontFaceId,
          },
        },
        { changeType: getTextFragmentsChangeType(element) },
      )
    },
    [element, updateElement],
  )

  const fonts = useMemo<FontFace[]>(
    () =>
      availableFontFaceIds.map(id => {
        const { name } = availableFontFaceById[id]
        const fontState = fontById[id]

        return {
          id,
          name,
          state: fontState.type,
          value: fontState.fontFamily,
        }
      }),
    [availableFontFaceIds, availableFontFaceById, fontById],
  )
  const handleUpdateAllStyles = useCallback(() => {
    updateStyledElementStyles({
      fontFaceId: element.customisations.fontFaceId,
    })
  }, [element, updateStyledElementStyles])

  return (
    <>
      <Box p={4}>
        <TertiaryButton onClick={handleUpdateAllStyles} inline>
          {t('toolbar_styled_text_apply_font_all')}
        </TertiaryButton>
      </Box>
      <FontFacePicker
        fonts={fonts}
        onSelect={handleSelect}
        selectedFontId={element.customisations.fontFaceId}
      />
    </>
  )
}
