import { performance } from '../../../utils/performance'
import { ActionCreator, Actions } from '../../types'
import { storeUpdateMainView } from '../../updaters/main-view'
import { storeUpdateView } from '../../updaters/view'

export const createPreviewLoaded: ActionCreator<'previewLoaded'> =
  ({ set }): Actions['previewLoaded'] =>
  async type => {
    switch (type) {
      case 'success': {
        set(store => {
          return storeUpdateMainView(store, 'preview', () => {
            return {
              readyToShow: true,
            }
          })
        })

        break
      }
      case 'error': {
        set(store => {
          return storeUpdateView(store, 'main', () => {
            return {
              view: {
                type: 'error-preview' as const,
                canContinue: true,
              },
            }
          })
        })
      }
    }

    performance.measureEnd('editor-preview')
  }
