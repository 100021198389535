import React, { FC } from 'react'

export const IconRearrange: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 24 24" width={width} height={height} fill="none">
      <rect
        x="1"
        y="1"
        width="16"
        height="16"
        rx="3"
        stroke="currentColor"
        strokeWidth="2"
      />
      <mask
        id="mp-icon-rearrange-mask"
        maskUnits="userSpaceOnUse"
        x="6"
        y="6"
        width="18"
        height="18"
      >
        <rect
          x="7"
          y="7"
          width="16"
          height="16"
          rx="3"
          stroke="white"
          strokeWidth="2"
        />
      </mask>
      <g mask="url(#mp-icon-rearrange-mask)">
        <path d="M6 24V20H20V6H24V24H6Z" fill="currentColor" />
      </g>
    </svg>
  )
}
