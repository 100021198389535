import { ErrorScreen } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { useSession, useView } from '../../store'
import { useEditorLocaleText } from '../../text-localisation'

export const ErrorProductNotFound: FC = () => {
  const t = useEditorLocaleText()
  const session = useSession()
  const productKey = useView('error-product-not-found', view => view.productKey)

  return (
    <ErrorScreen
      title={t('loading_product_not_found_title')}
      description={t('loading_product_not_found_description', productKey)}
      session={session}
    />
  )
}
