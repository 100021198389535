import React, { FC } from 'react'

export const IconVideo: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 24 24" width={width} height={height}>
      <path
        d="M9.5 8.64L14.77 12L9.5 15.36V8.64ZM7.5 5V19L18.5 12L7.5 5Z"
        fill="currentColor"
      />
      <rect
        x="1"
        y="1"
        width="22"
        height="22"
        rx="3"
        stroke="currentColor"
        strokeWidth="2"
        fill="transparent"
      />
    </svg>
  )
}
