import { Flex } from '@moonpig/launchpad-components'
import { useIsomorphicLayoutEffect } from '@moonpig/web-personalise-components'
import React, { FC, useState } from 'react'

// Android will resize the window when the virtual keyboard opens which causes
// the editor to be scaled down to a very small size. This hack preserves the
// initial height of the window so that the page scrolls but remains the same
// size when the virtual keyboard opens (similar to the behaviour on iOS).
const shouldPreserveWindowHeight = () => /Android/i.test(navigator.userAgent)

export const DeviceViewport: FC = ({ children }) => {
  const [initialWindowHeight, setInitialWindowHeight] = useState<string>('100%')

  useIsomorphicLayoutEffect(() => {
    /* istanbul ignore next */
    if (shouldPreserveWindowHeight()) {
      setInitialWindowHeight(`${window.innerHeight}px`)
    }
  }, [])

  return (
    <Flex
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      flexDirection="column"
      overflow="auto"
    >
      <Flex
        flexShrink={0}
        flexDirection="column"
        width="100%"
        height={initialWindowHeight}
      >
        {children}
      </Flex>
    </Flex>
  )
}
