import { DesignElementOverlayImage } from '@moonpig/web-personalise-editor-types'
import React, { FC, useMemo } from 'react'
import {
  OVERLAY_IMAGE_MAX_SCALE,
  OVERLAY_IMAGE_MIN_SCALE,
} from '../../../../../../../constants'
import { getDefaultOverlayImageSize } from '../../../../../../../utils/getDefaultOverlayImageSize'
import { useElementRef } from '../../../../../selectors'
import { InteractiveElementProps } from '../../types'
import { InteractiveElementTransformable } from '../InteractiveElementTransformable'

export const ElementOverlayImageInteractive: FC<
  InteractiveElementProps<DesignElementOverlayImage>
> = ({ elementRef, title, sceneX, sceneY, sceneWidth, sceneHeight }) => {
  const element = useElementRef(elementRef)

  const defaultSize = useMemo(
    () =>
      getDefaultOverlayImageSize(
        { width: sceneWidth, height: sceneHeight },
        { width: element.width, height: element.height },
      ),
    [sceneWidth, sceneHeight, element.width, element.height],
  )

  return (
    <InteractiveElementTransformable
      title={title}
      sceneId={elementRef.sceneId}
      sceneX={sceneX}
      enableDeleteShortcut
      sceneY={sceneY}
      sceneWidth={sceneWidth}
      sceneHeight={sceneHeight}
      element={element}
      defaultWidth={defaultSize.width}
      defaultHeight={defaultSize.height}
      minScale={OVERLAY_IMAGE_MIN_SCALE}
      maxScale={OVERLAY_IMAGE_MAX_SCALE}
      enableUniformScaling
    />
  )
}
