import { Bounds } from '@moonpig/common-math'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { animated, useSpring } from 'react-spring'
import {
  SHARED_ANIMATION_CONFIG,
  UI_CAMERA_PAD_DESKTOP,
  UI_HEIGHT_TOP,
  UI_SPACER,
} from '../../../../../../constants'

type ToolbarLayoutProps = {
  screenFocus: Bounds
  screenBounds: Bounds
}

const StyledToolbarLayoutCommon = styled.div`
  display: flex;
  pointer-events: none;

  > div {
    display: flex;
  }
`

const StyledToolbarLayoutBottom = styled(StyledToolbarLayoutCommon)`
  justify-content: center;
  max-width: 100%;
  ${s({ mx: 6 })}

  > div {
    max-width: 100%;
  }
`

const StyledToolbarLayoutLeft = styled(StyledToolbarLayoutCommon)`
  justify-content: flex-end;
  transition: height 500ms;
  max-height: 100%;

  > div {
    max-height: 100%;
  }
`

export const ToolbarLayoutTop: FC<ToolbarLayoutProps> = ({ children }) => {
  const top = UI_SPACER
  const height = UI_HEIGHT_TOP
  const animatedValues = {
    left: 0,
    width: '100%',
    height: height - UI_SPACER,
    top,
    justifyContent: 'flex-end',
  }

  const animatedStyles = useSpring({
    ...animatedValues,
    config: SHARED_ANIMATION_CONFIG,
    immediate: false,
  })

  return (
    <animated.div
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        pointerEvents: 'none',
        maxWidth: '100%',
        ...animatedStyles,
      }}
    >
      <StyledToolbarLayoutBottom>{children}</StyledToolbarLayoutBottom>
    </animated.div>
  )
}

export const ToolbarLayoutLeft: FC<ToolbarLayoutProps> = ({
  children,
  screenFocus,
  screenBounds,
}) => {
  const animatedValues = {
    left: 0,
    width: screenFocus.x - UI_SPACER,
    height: screenBounds.height - 2 * UI_CAMERA_PAD_DESKTOP,
    top: UI_CAMERA_PAD_DESKTOP,
  }

  const animatedStyles = useSpring({
    ...animatedValues,
    config: SHARED_ANIMATION_CONFIG,
    immediate: false,
  })

  return (
    <animated.div
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        pointerEvents: 'none',
        maxWidth: '100%',
        ...animatedStyles,
      }}
    >
      <StyledToolbarLayoutLeft>{children}</StyledToolbarLayoutLeft>
    </animated.div>
  )
}
