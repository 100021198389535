import { Flex, Text } from '@moonpig/launchpad-components'
import { useTheme } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { useLocaleText } from './ProgressBar.locale'

const GETTING_THERE_THRESHOLD = 0.55
const ALMOST_DONE_THRESHOLD = 0.85
const TRACK_HEIGHT = 4

type ProgressBarProps = {
  id: string
  initialLabel: string
  progressPercent: number
}

const useLabel = (initialLabel: string, progressPercent: number): string => {
  const t = useLocaleText()

  if (progressPercent >= ALMOST_DONE_THRESHOLD) {
    return t('almost_done_label')
  }

  if (progressPercent >= GETTING_THERE_THRESHOLD) {
    return t('getting_there_label')
  }

  return initialLabel
}

export const ProgressBar: FC<ProgressBarProps> = ({
  id,
  initialLabel,
  progressPercent,
}) => {
  const { colors } = useTheme()
  const { colorFeedbackInformation, colorBlack10 } = colors
  const label = useLabel(initialLabel, progressPercent)
  const labelId = `${id}-label`

  return (
    <Flex width="100%" flexDirection="column">
      <Text id={labelId} mb={3} display="block">
        {label}
      </Text>
      <div
        id={id}
        role="progressbar"
        aria-labelledby={labelId}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={Math.round(progressPercent * 100)}
        style={{
          position: 'relative',
          width: '100%',
          height: `${TRACK_HEIGHT}px`,
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: `${TRACK_HEIGHT * 0.5}px`,
            background: colorBlack10,
          }}
        />
        <div
          style={{
            position: 'absolute',
            width: `${progressPercent * 100}%`,
            minWidth: `${TRACK_HEIGHT}px`,
            maxWidth: '100%',
            height: '100%',
            borderRadius: `${TRACK_HEIGHT * 0.5}px`,
            background: colorFeedbackInformation,
            transition: 'width 400ms',
          }}
        />
      </div>
    </Flex>
  )
}
