import { FontFace, FontFacePicker } from '@moonpig/web-personalise-components'
import {
  DesignElementRef,
  DesignElementTextStyled,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useMemo } from 'react'
import { FontById, useAction, useView } from '../../../../../../../../store'
import { useElementRef } from '../../../../../../selectors'

const useFontById = (): FontById => {
  return useView('main', ({ fontById }) => {
    return fontById
  })
}

type ElementFontFacePickerProps = {
  elementRef: DesignElementRef<DesignElementTextStyled>
}

export const ElementFontFacePicker: FC<ElementFontFacePickerProps> = ({
  elementRef,
}) => {
  const element = useElementRef(elementRef)
  const fontById = useFontById()
  const updateElement = useAction('updateElement')

  const handleSelect = useCallback(
    (fontFaceId: string) => {
      updateElement({
        ...element,
        customisations: {
          ...element.customisations,
          fontFaceId,
        },
      })
    },
    [element, updateElement],
  )

  const fonts = useMemo<FontFace[]>(
    () =>
      element.availableFontFaceIds.map(fontFaceId => {
        const { name } = element.availableFontFaceById[fontFaceId]
        const fontState = fontById[fontFaceId]

        return {
          id: fontFaceId,
          name,
          state: fontState.type,
          value: fontState.fontFamily,
        }
      }),
    [element.availableFontFaceIds, element.availableFontFaceById, fontById],
  )

  return (
    <FontFacePicker
      fonts={fonts}
      onSelect={handleSelect}
      selectedFontId={element.customisations.fontFaceId}
    />
  )
}
