import {
  DesignAvailableFeature,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import shallow from 'zustand/shallow'
import { UI, useEditorFeatures, useMainView, useView } from '../../../store'

const DEFAULT_UI: UI = { type: 'default', selectedMenuItem: null }

const useCheckIfFeatureIsEnabled = (feature: DesignAvailableFeature) => {
  const {
    enableFlexibleText,
    enableFlexibleFront,
    enableStickers,
    enableFlexiblePhotos,
    enableVideo,
    enableLayouts,
    enableAudio,
    enableHandwriting,
  } = useEditorFeatures()

  switch (feature) {
    case 'stickers':
      return enableStickers
    case 'overlay-image':
      return enableFlexiblePhotos
    case 'overlay-text':
      return enableFlexibleText || enableFlexibleFront
    case 'video':
      return enableVideo
    case 'layouts':
      return enableLayouts
    case 'handwriting':
      return enableHandwriting
    case 'audio':
      return enableAudio
  }
}

export const useCheckIfFeatureIsAvailable = (
  feature: DesignAvailableFeature,
) => {
  const sceneHasFeature = useView('main', ({ design, selectedSceneId }) =>
    design.sceneById[selectedSceneId].availableFeatures.includes(feature),
  )

  const featureIsEnabled = useCheckIfFeatureIsEnabled(feature)

  const maxVideoSizeBytes = useView(
    'main',
    ({ customisationConfig }) => customisationConfig.video.maxSizeBytes,
  )

  const maxAudioSizeBytes = useView(
    'main',
    ({ customisationConfig }) => customisationConfig.audio.maxSizeBytes,
  )

  if (feature === 'video' && maxVideoSizeBytes === 0) {
    return false
  }

  if (feature === 'audio' && maxAudioSizeBytes === 0) {
    return false
  }

  return sceneHasFeature && featureIsEnabled
}

export const useCheckIfFeatureIsAvailableOnAnyScene = (
  feature: DesignAvailableFeature,
) => {
  const anySceneHasFeature = useView('main', ({ design }) =>
    design.sceneIds.some(sceneId =>
      design.sceneById[sceneId].availableFeatures.includes(feature),
    ),
  )

  const featureIsEnabled = useCheckIfFeatureIsEnabled(feature)

  return anySceneHasFeature && featureIsEnabled
}

const useDefaultUIForScene = (): UI | null => {
  const stickersEnabled = useCheckIfFeatureIsAvailable('stickers')
  const overlayImageEnabled = useCheckIfFeatureIsAvailable('overlay-image')
  const overlayTextEnabled = useCheckIfFeatureIsAvailable('overlay-text')
  const videoEnabled = useCheckIfFeatureIsAvailable('video')
  const audioEnabled = useCheckIfFeatureIsAvailable('audio')
  const handwritingEnabled = useCheckIfFeatureIsAvailable('handwriting')
  const layoutsEnabled = useCheckIfFeatureIsAvailable('layouts')

  if (
    stickersEnabled ||
    overlayImageEnabled ||
    overlayTextEnabled ||
    videoEnabled ||
    layoutsEnabled ||
    audioEnabled ||
    handwritingEnabled
  ) {
    return DEFAULT_UI
  }

  return null
}

export const useUIForScene = (sceneId: string): UI | null => {
  const defaultUI = useDefaultUIForScene()

  const explicitUI = useMainView('edit', ({ ui }, { selectedSceneId }) =>
    sceneId === selectedSceneId ? ui : null,
  )

  if (explicitUI) {
    return explicitUI
  }

  if (defaultUI) {
    return defaultUI
  }

  return null
}

export const useUI = () => {
  const selectedSceneId = useView('main', view => view.selectedSceneId)

  return useUIForScene(selectedSceneId)
}

export const useActiveKeyboardElementRef = (): DesignElementRef | null => {
  const ui = useUI()

  return useMainView(
    'edit',
    ({ activeElementId }, { design, selectedSceneId }) => {
      if (!activeElementId) {
        return null
      }

      const element =
        design.sceneById[selectedSceneId].elementById[activeElementId]

      const isKeyboardUISelected =
        ui &&
        (ui.type === 'text-styled' || ui.type === 'overlay-text') &&
        ui.selectedMenuItem === 'keyboard'

      if (element.type === 'text-plain' || isKeyboardUISelected) {
        return {
          type: element.type,
          sceneId: element.sceneId,
          id: element.id,
        }
      }

      return null
    },
    shallow,
  )
}

export const useInteractiveSceneTitle = (sceneId: string) => {
  const sceneTitle = useView(
    'main',
    view => view.sceneTextById[sceneId].sceneTitle,
  )

  return sceneTitle
}

export const useInteractiveElementTitle = (
  sceneId: string,
  elementId: string,
): string => {
  const { editElementLabelById } = useView(
    'main',
    view => view.sceneTextById[sceneId],
  )
  return editElementLabelById[elementId]
}

export const useSelectedElementRef = (): DesignElementRef | null => {
  return useMainView(
    'edit',
    (view, { design, selectedSceneId }) => {
      if (!view.activeElementId) {
        return null
      }

      const selectedElement =
        design.sceneById[selectedSceneId].elementById[view.activeElementId]

      return {
        type: selectedElement.type,
        sceneId: selectedElement.sceneId,
        id: selectedElement.id,
      }
    },
    shallow,
  )
}
