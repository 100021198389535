import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  audio_player_label: {
    'en-GB': `Audio Player`,
    'nl-NL': `Geluidsspeler`,
  },
}

export const useLocaleText = createLocaleTextHook({ dictionary })
