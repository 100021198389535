import {
  Flex,
  SecondaryButton,
  PrimaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { CollageGrid } from '@moonpig/web-personalise-collage'
import React, { FC, useCallback } from 'react'
import { Modal } from '@moonpig/web-personalise-components'
import { useAction, useView } from '../../../../../../store'
import { useEditorLocaleText } from '../../../../../../text-localisation'

export const ConfirmLayoutChangeModal: FC<{ selectedLayout: CollageGrid }> = ({
  selectedLayout,
}) => {
  const t = useEditorLocaleText()
  const dismissModal = useAction('dismissModal')
  const selectLayout = useAction('selectLayout')
  const sceneId = useView('main', view => view.selectedSceneId)

  const confirmSelectLayout = useCallback(() => {
    selectLayout(selectedLayout, sceneId)
    dismissModal()
  }, [selectLayout, dismissModal, sceneId, selectedLayout])

  return (
    <Modal
      heading={t('are_you_sure_about_change_layouts')}
      closeLabel={t('modal_close_button')}
      onClose={dismissModal}
    >
      <Flex flexDirection="column" width="100%">
        <Text paddingBottom={'15px'} textAlign="center">
          {t('existing_customisations_will_be_gone')}
        </Text>
        <PrimaryButton mb={6} onClick={confirmSelectLayout}>
          {t('change_layout')}
        </PrimaryButton>
        <SecondaryButton onClick={dismissModal}>{t('cancel')}</SecondaryButton>
      </Flex>
    </Modal>
  )
}
