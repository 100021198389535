import { Locale } from '@moonpig/web-core-types'
import handwritingOnboardingImage from './assets/handwriting-onboarding-image.jpg'
import videoMediaDemoImageEn from './assets/video-media-demo-image-en.jpg'
import videoMediaDemoImageNl from './assets/video-media-demo-image-nl.jpg'
import audioMediaDemoImageEn from './assets/audio-media-demo-image-en.jpg'
import audioMediaDemoImageNl from './assets/audio-media-demo-image-nl.jpg'
import videoMediaDemoVideo from './assets/video-media-demo-video.mp4'
import audioMediaDemoVideo from './assets/audio-media-demo-video.mp4'

export const handwritingOnboardingImageUrlByLanguage: {
  [language in Locale['language']]: string
} = {
  'en-AU': handwritingOnboardingImage,
  'en-GB': handwritingOnboardingImage,
  'en-IE': handwritingOnboardingImage,
  'en-US': handwritingOnboardingImage,
  'nl-NL': handwritingOnboardingImage,
}

export const videoMediaDemoImageUrlByLanguage: {
  [language in Locale['language']]: string
} = {
  'en-AU': videoMediaDemoImageEn,
  'en-GB': videoMediaDemoImageEn,
  'en-IE': videoMediaDemoImageEn,
  'en-US': videoMediaDemoImageEn,
  'nl-NL': videoMediaDemoImageNl,
}

export const audioMediaDemoImageUrlByLanguage: {
  [language in Locale['language']]: string
} = {
  'en-AU': audioMediaDemoImageEn,
  'en-GB': audioMediaDemoImageEn,
  'en-IE': audioMediaDemoImageEn,
  'en-US': audioMediaDemoImageEn,
  'nl-NL': audioMediaDemoImageNl,
}

export const videoMediaDemoVideoUrl = videoMediaDemoVideo
export const audioMediaDemoVideoUrl = audioMediaDemoVideo
