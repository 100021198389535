import { Box, Flex } from '@moonpig/launchpad-components'
import { Dial } from '@moonpig/web-personalise-components'
import {
  DesignElementOverlayText,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback } from 'react'
import { getGestureChangeType, useAction } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { useElementRef, useScene } from '../../../../../../selectors'

const WidthDial: FC<{
  width: number
  sceneWidth: number
  onChange: (newWidth: number, context: { last: boolean }) => void
}> = ({ width, sceneWidth, onChange }) => {
  const t = useEditorLocaleText()
  const currentValue = width / sceneWidth

  const handleChange = useCallback(
    (value: number, context: { last: boolean }) => {
      const convertedValue = (value / 100) * sceneWidth
      onChange(convertedValue, context)
    },
    [onChange, sceneWidth],
  )

  return (
    <Dial
      id="width-dial"
      label={t('adjust_width_label')}
      getValueText={v => `${v.toFixed()}%`}
      minValue={5}
      maxValue={100}
      unitsPerMark={1}
      pixelsPerMark={10}
      minorPerMajorMark={10}
      value={currentValue * 100}
      onChange={handleChange}
    />
  )
}

type ScaleProps = {
  elementRef: DesignElementRef<DesignElementOverlayText>
}

export const ElementScalePicker: FC<ScaleProps> = ({ elementRef }) => {
  const element = useElementRef(elementRef)
  const scene = useScene(elementRef.sceneId)

  const updateElementRef = useAction('updateElementRef')

  const handleWidthChange = useCallback(
    (newWidth: number, context: { last: boolean }) => {
      updateElementRef(
        elementRef,
        current => {
          const midpoint = current.x + current.width / 2
          const dx = midpoint - newWidth / 2

          return {
            ...current,
            x: dx,
            width: newWidth,
            fragmentsState: current.fragmentsState && {
              ...current.fragmentsState,
              version: current.fragmentsState.version + (context.last ? 1 : 0),
              resizingWidth: !context.last,
            },
          }
        },
        { changeType: getGestureChangeType(context) },
      )
    },
    [elementRef, updateElementRef],
  )

  return (
    <Flex flexDirection="column" width="100%">
      <Box mt={4}>
        <WidthDial
          width={element.width}
          sceneWidth={scene.width}
          onChange={handleWidthChange}
        />
      </Box>
    </Flex>
  )
}
