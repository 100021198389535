import { shuffleGrid } from '@moonpig/web-personalise-collage'
import { IconShuffle } from '@moonpig/web-personalise-components'
import React, { FC, useCallback, useMemo } from 'react'
import { assert } from '../../../../../../../utils/assert'
import { createElementDomId } from '../../../../../../../utils/createElementId'
import {
  UIImageCollageRearrange,
  UIImageCollageRearrangeMenuItem,
  useAction,
  useView,
} from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { useElementRef } from '../../../../../selectors'
import { ToolbarItem, ToolbarOrientation } from '../Toolbar/types'
import { ToolbarCommon } from '../ToolbarCommon'

type ToolbarImageCollageRearrangeProps = {
  onDone: () => void
  orientation: ToolbarOrientation
  ui: UIImageCollageRearrange
}

export const ToolbarImageCollageRearrange: FC<
  ToolbarImageCollageRearrangeProps
> = ({ onDone, orientation, ui }) => {
  const productKey = useView('main', view => view.design.productKey)
  const element = useElementRef(ui.elementRef)
  const updateElement = useAction('updateElement')
  const t = useEditorLocaleText()
  const controlId = createElementDomId(element.id)
  const trackEvent = useAction('trackEvent')

  const { grid } = element.customisations

  assert(grid !== null)

  const items = useMemo<ToolbarItem<UIImageCollageRearrangeMenuItem>[]>(() => {
    return [
      {
        type: 'button',
        id: 'shuffle',
        label: t('toolbar_image_shuffle_label'),
        ariaLabel: t('toolbar_shuffle_photo'),
        icon: <IconShuffle />,
        disabled: false,
      },
    ]
  }, [t])

  const handleSelectItem = useCallback(
    (itemId: UIImageCollageRearrangeMenuItem | null) => {
      switch (itemId) {
        case 'shuffle': {
          trackEvent({
            type: 'SELECT_CONTENT',
            kind: 'COLLAGE_SHUFFLE',
            productKey,
          })

          updateElement({
            ...element,
            customisations: {
              ...element.customisations,
              pendingEditedImage: null,
              grid: shuffleGrid(grid),
            },
          })
        }
      }
    },
    [trackEvent, productKey, updateElement, element, grid],
  )

  return (
    <ToolbarCommon
      controlId={controlId}
      label={t('toolbar_collage_rearrange_toolbar_title')}
      orientation={orientation}
      items={items}
      selectedItemId={null}
      onSelectItem={handleSelectItem}
      done={{
        label: t('toolbar_done_button'),
        ariaLabel: t('toolbar_collage_rearrange_toolbar_close_label'),
        onDone,
      }}
    />
  )
}
