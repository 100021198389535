import { ActionCreator } from '../../types'
import { storeUpdateView } from '../../updaters/view'

export const createUpdateCursor: ActionCreator<'updateCursor'> =
  ({ set }) =>
  async position => {
    set(store => {
      return storeUpdateView(store, 'main', view => {
        if (!view.collaborateSession) {
          return {}
        }

        if (view.collaborateSession.peers.length > 1) {
          view.collaborateSession.updateCursor(position)
        }

        return {}
      })
    })
  }
