import { Box, Text } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'

type FeatureCardProps = {
  title: string
  description: string
  image: {
    url: string
    alt: string
  }
}

const StyledFeatureCardImage = styled.img`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`

export const FeatureCard: FC<FeatureCardProps> = ({
  title,
  description,
  image,
}) => {
  return (
    <Box>
      <StyledFeatureCardImage src={image.url} alt={image.alt} />
      <Text typography={'typeBodyBold'} as={'h4'} mb={0}>
        {title}
      </Text>
      <Text typography={'typeBodyCaption'} as={'p'}>
        {description}
      </Text>
    </Box>
  )
}
