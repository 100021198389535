import { GetEditorLocaleText } from '../../../text-localisation'
import { StarterMessage } from './types'

export const genericBirthdayMessage = (
  t: GetEditorLocaleText,
): StarterMessage => ({
  id: 1,
  message: t('starter_message_birthday_generic'),
})

export const birthdayStarterMessagesByDesign = (
  t: GetEditorLocaleText,
): { [design in string]?: StarterMessage } => ({
  funny: {
    id: 2,
    message: t('starter_message_birthday_funny'),
  },
  cute: {
    id: 3,
    message: t('starter_message_birthday_cute'),
  },
  trendy: {
    id: 4,
    message: t('starter_message_birthday_trendy'),
  },
  traditional: {
    id: 5,
    message: t('starter_message_birthday_traditional'),
  },
})

export const starterMessagesByOccasion = (
  t: GetEditorLocaleText,
): {
  [occasion in string]?: StarterMessage
} => ({
  anniversary: {
    id: 6,
    message: t('starter_message_occasion_anniversary'),
  },
  christmas: {
    id: 7,
    message: t('starter_message_occasion_christmas'),
  },
  congratulations: {
    id: 8,
    message: t('starter_message_occasion_congratulations'),
  },
  'get well': {
    id: 9,
    message: t('starter_message_occasion_get_well'),
  },
  'good luck': {
    id: 10,
    message: t('starter_message_occasion_good_luck'),
  },
  leaving: {
    id: 11,
    message: t('starter_message_occasion_leaving'),
  },
  "mother's day": {
    id: 12,
    message: t('starter_message_occasion_mothers_day'),
  },
  'new baby': {
    id: 13,
    message: t('starter_message_occasion_new_baby'),
  },
  'new home': {
    id: 14,
    message: t('starter_message_occasion_new_home'),
  },
  'new job': {
    id: 15,
    message: t('starter_message_occasion_new_job'),
  },
  sympathy: {
    id: 16,
    message: t('starter_message_occasion_sympathy'),
  },
  'thank you': {
    id: 17,
    message: t('starter_message_occasion_thank_you'),
  },
  'thinking of you': {
    id: 18,
    message: t('starter_message_occasion_thinking_of_you'),
  },
  wedding: {
    id: 19,
    message: t('starter_message_occasion_wedding'),
  },
})
