import { getImageIds } from '@moonpig/web-personalise-collage'
import {
  Design,
  DesignElementImageCollage,
  DesignElementImageUpload,
  DesignElementOverlayImage,
  DesignScene,
  Photo,
  Services,
} from '@moonpig/web-personalise-editor-types'
import { assert } from '../../utils/assert'
import { Photos } from '../types'

type Size = {
  width: number
  height: number
}

const uploadDesignElementImageUpload = ({
  services,
  designId,
  element,
  photos,
  calculateScale,
}: {
  services: Services
  designId: string
  element: DesignElementImageUpload | DesignElementOverlayImage
  photos: Photos
  calculateScale: (transformScale: number, photoSize: Size) => number
}): DesignElementOverlayImage | DesignElementImageUpload => {
  const { pendingEditedImage, sourceImage } = element.customisations

  if (pendingEditedImage || !sourceImage) {
    return element
  }

  const transform = sourceImage.transform
  const photoState = photos.photoById[sourceImage.id]

  assert(photoState.type === 'loaded')

  const scale = calculateScale(transform.scale, photoState.photo.originalImage)

  const newPendingEditedImage = services
    .uploadPhotoToDesign({
      designId,
      size: {
        width: element.width,
        height: element.height,
      },
      transform: {
        position: [transform.position.x, transform.position.y],
        rotation: transform.rotation,
        scale: [scale, scale],
      },
      photo: photoState.photo,
    })
    .then(result => {
      if (result.type === 'error') {
        throw new Error('Failed to upload photo to design')
      }

      return { id: result.imageId }
    })

  if (element.type === 'overlay-image') {
    return {
      ...element,
      customisations: {
        ...element.customisations,
        imageKind: 'photo',
        fixed: false,
        pendingEditedImage: newPendingEditedImage,
      },
    }
  }

  return {
    ...element,
    customisations: {
      ...element.customisations,
      pendingEditedImage: newPendingEditedImage,
    },
  }
}

const uploadDesignElementImageCollage = ({
  services,
  designId,
  element,
  photos,
}: {
  services: Services
  designId: string
  element: DesignElementImageCollage
  photos: Photos
}): DesignElementImageCollage => {
  const { pendingEditedImage, grid } = element.customisations

  if (pendingEditedImage || !grid) {
    return element
  }

  const imageIds = getImageIds(grid)

  const newPendingEditedImage = services
    .uploadCollageToDesign({
      designId,
      size: {
        width: element.width,
        height: element.height,
      },
      photos: photos.photoIds.reduce<Photo[]>((acc, photoId) => {
        const photoState = photos.photoById[photoId]
        if (photoState.type === 'loaded' && imageIds.includes(photoId)) {
          acc.push(photoState.photo)
        }
        return acc
      }, []),
      grid,
    })
    .then(result => {
      if (result.type === 'error') {
        throw new Error('Failed to upload collage to design')
      }

      return { id: result.imageId }
    })

  return {
    ...element,
    customisations: {
      ...element.customisations,
      pendingEditedImage: newPendingEditedImage,
    },
  }
}

const uploadDesignSceneImages = ({
  services,
  designId,
  scene,
  photos,
}: {
  services: Services
  designId: string
  scene: DesignScene
  photos: Photos
}): DesignScene => {
  const newElementByIdEntries = Object.entries(scene.elementById).map(
    ([id, element]) => {
      switch (element.type) {
        case 'overlay-image': {
          const newElement = uploadDesignElementImageUpload({
            services,
            designId,
            element,
            photos,
            calculateScale: (transformScale, photoSize) =>
              transformScale * (element.width / photoSize.width),
          })

          return [id, newElement]
        }
        case 'image-upload': {
          const newElement = uploadDesignElementImageUpload({
            services,
            designId,
            element,
            photos,
            calculateScale: transformScale => transformScale,
          })

          return [id, newElement]
        }
        case 'image-collage': {
          const newElement = uploadDesignElementImageCollage({
            services,
            designId,
            element,
            photos,
          })

          return [id, newElement]
        }
      }

      return [id, element]
    },
  )

  return {
    ...scene,
    elementById: Object.fromEntries(newElementByIdEntries),
  }
}

export const uploadDesignImages = ({
  services,
  design,
  photos,
}: {
  services: Services
  design: Design
  photos: Photos
}): Design => {
  const newSceneByIdEntries = Object.entries(design.sceneById).map(
    ([id, scene]) => {
      return [
        id,
        uploadDesignSceneImages({
          services,
          designId: design.id,
          scene,
          photos,
        }),
      ]
    },
  )

  return {
    ...design,
    sceneById: Object.fromEntries(newSceneByIdEntries),
  }
}
