import { Bounds } from '@moonpig/common-math'
import { Layout, LayoutScene } from './types'

const scaleBounds = (bounds: Bounds, scale: number): Bounds => {
  return {
    x: bounds.x * scale,
    y: bounds.y * scale,
    width: bounds.width * scale,
    height: bounds.height * scale,
  }
}

export const scaleLayout = (layout: Layout, scale: number): Layout => {
  const sceneById = Object.keys(layout.sceneById).reduce<Layout['sceneById']>(
    (acc, curr) => {
      const scene = layout.sceneById[curr]
      const scaled: LayoutScene = {
        ...scene,
        ...scaleBounds(scene, scale),
        center: {
          x: scene.center.x * scale,
          y: scene.center.y * scale,
        },
      }

      return {
        ...acc,
        [curr]: scaled,
      }
    },
    {},
  )

  const scaled: Layout = {
    bounds: scaleBounds(layout.bounds, scale),
    sceneById,
    images: {
      background: layout.images.background.map(image => {
        return {
          ...image,
          ...scaleBounds(image, scale),
        }
      }),
      foreground: layout.images.foreground.map(image => {
        return {
          ...image,
          ...scaleBounds(image, scale),
        }
      }),
    },
  }
  return scaled
}
