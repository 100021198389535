import { IconSystemLoader } from '@moonpig/launchpad-assets'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { SpringRef } from 'react-spring'
import { SPINNER_SIZE, TIMING_MEDIA_SPINNER } from './constants'
import { LayerMediaCommon } from './LayerMediaCommon'
import { Size } from './types'

const StyledLoadingIndicator = styled(IconSystemLoader)<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  animation: loader-rotation infinite 2000ms linear;

  @keyframes loader-rotation {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`

type LayerMediaSpinnerProps = {
  springRef: SpringRef
  sceneSize: Size
}

export const LayerMediaSpinner: FC<LayerMediaSpinnerProps> = ({
  springRef,
  sceneSize,
}) => {
  return (
    <LayerMediaCommon
      springRef={springRef}
      sceneSize={sceneSize}
      duration={TIMING_MEDIA_SPINNER.end - TIMING_MEDIA_SPINNER.start}
    >
      <div
        style={{
          background: 'white',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <StyledLoadingIndicator size={sceneSize.width * SPINNER_SIZE} />
      </div>
    </LayerMediaCommon>
  )
}
