import { Bounds, Vec2, projectBounds, projectPoint } from '@moonpig/common-math'
import { useElementBounds } from '@moonpig/web-personalise-components'
import React, {
  FC,
  UIEvent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { SpringValue, useSpring } from 'react-spring'
import { calculateWorldBounds } from './layout'
import { AnimationConfig, CameraNode, CameraUI } from './types'
import { usePan } from './usePan'
import { createTransform } from './utils'

export type CameraProps = {
  /**
   * Camera background color
   */
  backgroundColor?: string

  /**
   * Focus of the screen in world units
   */
  focus: Bounds

  /**
   * Container padding in pixels
   */
  pad: number

  /**
   * UI (e.g. toolbar) orientation & size
   */
  ui?: CameraUI

  /**
   * Spring config
   */
  animationConfig: AnimationConfig

  /**
   * Current node (used for pan navigation)
   */

  node?: CameraNode

  /**
   * On change node triggered through camera swipe / pan
   */
  onNodeChange?: (nodeId: string, prevNodeId: string) => void

  /**
   * Disable changing nodes using swipe / pan gestures
   */
  disablePan?: boolean
}

export type BoundedCameraProps = CameraProps & { domBounds: Bounds }

export type UseCamera = {
  focus: Bounds
  pad: number
  ui: CameraUI | null
  worldBounds: Bounds
  domBounds: Bounds
  screenBounds: Bounds
  screenFocus: Bounds
  translate: Vec2
  scale: number
  inverseScale: number
  toScreenPoint: (pointInWorld: Vec2) => Vec2
  toScreenBounds: (boundsInView: Bounds) => Bounds
  toWorldPoint: (pointInScreen: Vec2) => Vec2
  toWorldBounds: (boundsInScreen: Bounds) => Bounds
  animation: {
    transform: SpringValue<string>
    raw: SpringValue<[number, number, number]>
  }
  setFocusLocked: (locked: boolean) => void
}

const CameraContext = React.createContext<UseCamera | null>(null)

const BoundedCamera: FC<BoundedCameraProps> = ({
  children,
  domBounds,
  focus: focusInput,
  pad,
  ui = null,
  animationConfig,
  node,
  onNodeChange,
  disablePan,
}) => {
  const [focusLocked, setFocusLocked] = useState(false)
  const [focus, setFocus] = useState(focusInput)

  useLayoutEffect(() => {
    if (!focusLocked) {
      setFocus(focusInput)
    }
  }, [focusLocked, focusInput])

  const bounds: Bounds = useMemo(
    () => ({
      x: 0,
      y: 0,
      width: domBounds.width,
      height: domBounds.height,
    }),
    [domBounds],
  )

  const derived = useMemo(() => {
    const worldBounds = calculateWorldBounds(focus, bounds, ui, pad)

    const screenFocus = projectBounds(focus, worldBounds, bounds)

    const translate = projectPoint([0, 0], worldBounds, bounds)
    const scale = bounds.width / worldBounds.width
    const inverseScale = 1 / scale

    const toScreenPoint = (pointInWorld: Vec2) =>
      projectPoint(pointInWorld, worldBounds, bounds)
    const toScreenBounds = (pointInBounds: Bounds) =>
      projectBounds(pointInBounds, worldBounds, bounds)
    const toWorldPoint = (pointInScreen: Vec2) =>
      projectPoint(pointInScreen, bounds, worldBounds)
    const toWorldBounds = (boundsInScreen: Bounds) =>
      projectBounds(boundsInScreen, bounds, worldBounds)

    return {
      worldBounds,
      screenFocus,
      translate,
      scale,
      inverseScale,
      toScreenPoint,
      toScreenBounds,
      toWorldPoint,
      toWorldBounds,
    }
  }, [focus, bounds, ui, pad])

  const { translate, scale } = derived

  const [animation, api] = useSpring(() => {
    return {
      transform: createTransform(translate[0], translate[1], scale),
      raw: [translate[0], translate[1], scale] as [number, number, number],
    }
  })

  const bind = usePan({
    translate,
    scale,
    spring: api,
    animationConfig,
    node,
    onNodeChange,
    disable: disablePan,
  })

  useEffect(() => {
    // Workaround for Safari issue where selecting a text box on another
    // scene causes the camera animation to stop
    requestAnimationFrame(() => {
      api.start(() => {
        const [x, y] = translate
        return {
          transform: createTransform(x, y, scale),
          raw: [translate[0], translate[1], scale],
          immediate: false,
          config: animationConfig,
        }
      })
    })
  }, [animationConfig, api, scale, translate])

  // Workaround for Safari issue where focus on scene buttons by
  // tabbing causes camera container to scroll
  const handleScroll = useCallback(
    /* istanbul ignore next: only fires in Safari */ (event: UIEvent) => {
      event.currentTarget.scrollTo(0, 0)
    },
    [],
  )

  const value = useMemo(() => {
    return {
      domBounds,
      screenBounds: bounds,
      focus,
      pad,
      ui,
      animation,
      setFocusLocked,
      ...derived,
    }
  }, [domBounds, bounds, focus, pad, ui, animation, derived])

  return (
    <CameraContext.Provider value={value}>
      <div
        data-testid="mp-editor-camera"
        {...bind()}
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          overflow: 'hidden',
        }}
        onScroll={handleScroll}
      >
        {children}
      </div>
    </CameraContext.Provider>
  )
}

export const Camera: FC<CameraProps> = props => {
  const { children, backgroundColor } = props

  const [bounds, ref] = useElementBounds()

  return (
    <div
      ref={ref}
      style={{
        width: '100%',
        backgroundColor,
      }}
    >
      {bounds ? (
        <BoundedCamera {...props} domBounds={bounds}>
          {children}
        </BoundedCamera>
      ) : null}
    </div>
  )
}

export const useCamera = (): UseCamera => {
  const context = useContext(CameraContext)

  /* istanbul ignore if */
  if (context === null) {
    throw new Error('useCamera must be used within a Camera')
  }

  return context
}
