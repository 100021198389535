import React, { FC } from 'react'

export const IconHandwriting: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg fill={'none'} width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M1.5 20.4998L7.04927 18.3655C7.40421 18.229 7.58168 18.1607 7.74772 18.0716C7.8952 17.9924 8.0358 17.901 8.16804 17.7984C8.31692 17.6829 8.45137 17.5484 8.72028 17.2795L20 5.99982C21.1046 4.89525 21.1046 3.10438 20 1.99981C18.8955 0.895245 17.1046 0.895244 16 1.99981L4.72028 13.2795C4.45138 13.5484 4.31692 13.6829 4.20139 13.8318C4.09877 13.964 4.0074 14.1046 3.92823 14.2521C3.83911 14.4181 3.77085 14.5956 3.63433 14.9506L1.5 20.4998ZM1.5 20.4998L3.55812 15.1488C3.7054 14.7659 3.77903 14.5744 3.90534 14.4867C4.01572 14.4101 4.1523 14.3811 4.2843 14.4063C4.43533 14.4351 4.58038 14.5802 4.87048 14.8703L7.12957 17.1294C7.41967 17.4195 7.56472 17.5645 7.59356 17.7155C7.61877 17.8475 7.58979 17.9841 7.51314 18.0945C7.42545 18.2208 7.23399 18.2944 6.85107 18.4417L1.5 20.4998ZM20.5 18.0945L19.4999 19.1885C18.9695 19.7686 18.2502 20.0945 17.5002 20.0945C16.7501 20.0945 16.0308 19.7686 15.5004 19.1885C14.9692 18.6096 14.25 18.2846 13.5002 18.2846C12.7504 18.2846 12.0312 18.6096 11.5 19.1885"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
