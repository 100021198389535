type Performance<TMarkName, TMeasureName> = {
  mark: (name: TMarkName) => void
  measureStart: (name: TMeasureName) => void
  measureEnd: (name: TMeasureName) => void
}

const createPerformance = <
  TMarkName extends string,
  TMeasureName extends string,
>(): Performance<TMarkName, TMeasureName> => {
  return {
    mark: name => {
      try {
        window.performance.mark(name)
      } catch /* istanbul ignore next: unreachable */ {
        // ignore errors
      }
    },
    measureStart: name => {
      try {
        window.performance.mark(`${name}-start`)
      } catch /* istanbul ignore next: unreachable */ {
        // ignore errors
      }
    },
    measureEnd: name => {
      try {
        window.performance.mark(`${name}-end`)

        window.performance.measure(name, `${name}-start`, `${name}-end`)
      } catch /* istanbul ignore next: unreachable */ {
        // ignore errors
      }
    },
  }
}

type PerformanceMarkName = 'editor-mounted' | 'editor-loaded'

type PerformanceMeasureName =
  | 'editor-loading'
  | 'editor-preview'
  | 'editor-finish'
  | 'editor-exit-save'
  | 'editor-exit-delete'
  | 'editor-add-photos'
  | 'editor-smart-text'

export const performance = createPerformance<
  PerformanceMarkName,
  PerformanceMeasureName
>()
