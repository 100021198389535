import {
  IconSystemPlayOutline,
  IconSystemResetFilled,
} from '@moonpig/launchpad-assets'
import { Box, focusIndicatorStyles } from '@moonpig/launchpad-components'
import { colorValue } from '@moonpig/launchpad-theme'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { MediaKind, VideoPlayerState } from './types'
import { useLocaleText } from './MediaPlayer.locale'

const ICON_SIZE = 64

const StyledButton = styled.button`
  ${focusIndicatorStyles}

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    background: ${colorValue('colorPrimary03')};
    color: ${colorValue('colorInteractionButtonHover')};
  }

  &:hover > div {
    background: ${colorValue('colorInteractionButtonHover')};
    color: ${colorValue('colorPrimary03')};
  }
`

const PlayIndicator: FC = () => {
  return (
    <Box borderRadius="50%">
      <IconSystemPlayOutline
        display="block"
        width={ICON_SIZE}
        height={ICON_SIZE}
      />
    </Box>
  )
}

const ReplayIndicator: FC = () => {
  return (
    <Box borderRadius="50%">
      <IconSystemResetFilled
        display="block"
        width={ICON_SIZE}
        height={ICON_SIZE}
      />
    </Box>
  )
}

type PlayButtonProps = {
  ariaControls: string
  mediaKind: MediaKind
  state: VideoPlayerState
  onClick: () => void
}

export const PlayButton: FC<PlayButtonProps> = ({
  ariaControls,
  mediaKind,
  state,
  onClick,
}) => {
  const t = useLocaleText()

  const controls_text: {
    [kind in MediaKind]: { [control in string]: string }
  } = {
    Audio: {
      play: t('play_audio'),
      replay: t('replay_audio'),
    },
    Video: {
      play: t('play_video'),
      replay: t('replay_video'),
    },
  }

  switch (state) {
    case 'stopped': {
      return (
        <StyledButton
          aria-label={controls_text[mediaKind].play}
          aria-controls={ariaControls}
          onClick={onClick}
        >
          <PlayIndicator />
        </StyledButton>
      )
    }
    case 'ended': {
      return (
        <StyledButton
          aria-label={controls_text[mediaKind].replay}
          aria-controls={ariaControls}
          onClick={onClick}
        >
          <ReplayIndicator />
        </StyledButton>
      )
    }
    default: {
      return null
    }
  }
}
