import { CollageGrid, layoutGrid } from '@moonpig/web-personalise-collage'

export const getAvailableLayouts = ({
  grids,
  width,
  height,
  selectedGridId,
}: {
  grids: CollageGrid[]
  width: number
  height: number
  selectedGridId: string | null
}) => {
  return grids.map(grid => {
    const { elements: gridElements } = layoutGrid({
      grid,
      size: [width, height],
    })
    return {
      id: grid.id,
      selected: grid.id === selectedGridId,
      width,
      height,
      elements: gridElements.map(gridElement => ({
        id: gridElement.id,
        x: gridElement.frame.position[0],
        y: gridElement.frame.position[1],
        width: gridElement.frame.size[0],
        height: gridElement.frame.size[1],
        contentType: gridElement.content.type,
      })),
    }
  })
}
