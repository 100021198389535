import { useFocusOn } from '@moonpig/web-personalise-components'
import React, { FC, useCallback } from 'react'
import { FOCUS_ID_TOOLBAR } from '../../../../../../../constants'
import { useAction } from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { useUIForScene } from '../../../../selectors'
import { SkipLink } from './components/SkipLink'

export const SceneToolbarSkipLink: FC<{ sceneId: string }> = ({ sceneId }) => {
  const t = useEditorLocaleText()
  const selectScene = useAction('selectScene')
  const ui = useUIForScene(sceneId)
  const focusOn = useFocusOn()

  const handleSkipLinkFocus = useCallback(() => {
    selectScene(sceneId, 'FOCUS')
  }, [sceneId, selectScene])

  const handleSkipLinkClick = useCallback(() => {
    focusOn(FOCUS_ID_TOOLBAR)
  }, [focusOn])

  if (!ui) {
    return null
  }

  return (
    <SkipLink
      label={t('skip_to_toolbar')}
      onFocus={handleSkipLinkFocus}
      onClick={handleSkipLinkClick}
    />
  )
}
