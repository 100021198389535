import { Vec2, vec2Add, vec2Scale, vec2Sub } from '@moonpig/common-math'
import React, {
  FC,
  KeyboardEvent,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import { animated, to, useSpring } from 'react-spring'
import { Frame, frameIntersect } from '../../common/frame'
import { CollageMode } from '../../common/types'
import {
  Image,
  ImageLayout,
  convertLayoutOffsetToCellOffset,
} from '../../model'
import { DragEvent, InteractiveCellButton } from '../InteractiveCellButton'
import { InteractiveImageManipulation } from '../InteractiveImageManipulation'
import { RearrangeIndicator } from '../RearrangeIndicator'

const KEYBOARD_OFFSET_DELTA_COARSE = 0.1
const KEYBOARD_OFFSET_DELTA_FINE = 0.02

type InteractiveCollageCellImageProps = {
  label: string
  mode: CollageMode
  id: string
  cell: {
    offset: Vec2
    scale: number
  }
  scale: number
  frame: Frame
  positionOffset: Vec2
  clipBounds: Frame
  layout: ImageLayout
  image: Image
  dimmed: boolean
  selected: boolean
  targeted: boolean
  renderContent: boolean
  onChangeTransform: (event: {
    offset: Vec2
    scale: number
    last: boolean
  }) => void
  onChangePosition: (event: {
    pointerOffset: Vec2
    position: Vec2
    last: boolean
  }) => void
  onClick: () => void
  disableSelectCell: MutableRefObject<boolean>
}

const createTransform = ({
  frame,
  offset,
  scale,
  imageSize,
}: {
  frame: Frame
  offset: Vec2
  scale: number
  imageSize: Vec2
}) => {
  const frameCenter = vec2Scale(frame.size, 0.5)
  const imageCenter = vec2Scale(imageSize, 0.5 * scale)
  const scaledOffset = vec2Add(vec2Sub(frameCenter, imageCenter), offset)
  const translate = vec2Add(frame.position, scaledOffset)

  return [`translate(${translate.join(' ')})`, `scale(${scale})`].join(' ')
}

export const InteractiveCollageCellImage: FC<
  InteractiveCollageCellImageProps
> = ({
  label,
  mode,
  id,
  cell,
  scale,
  frame,
  positionOffset,
  clipBounds,
  layout,
  image,
  dimmed,
  selected,
  targeted,
  renderContent,
  onChangeTransform,
  onChangePosition,
  onClick,
  disableSelectCell,
}) => {
  const clipPathId = `mp-ed-collage-image-${id}`
  const isDragging = useRef(false)

  const clippedFrame = useMemo(
    () => frameIntersect(clipBounds, frame),
    [clipBounds, frame],
  )

  const [p] = useSpring(() => {
    return {
      x: frame.position[0] + positionOffset[0],
      y: frame.position[1] + positionOffset[1],
      w: frame.size[0],
      h: frame.size[1],
      xClip: clippedFrame.position[0] + positionOffset[0],
      yClip: clippedFrame.position[1] + positionOffset[1],
      wClip: clippedFrame.size[0],
      hClip: clippedFrame.size[1],
      scale: layout.scale,
      offsetX: layout.offset[0],
      offsetY: layout.offset[1],
      immediate: () => isDragging.current || mode !== 'rearrange',
    }
  }, [frame, cell, positionOffset, clipBounds, mode, layout])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (mode !== 'manipulate') {
        return
      }

      const deltaPercent = event.shiftKey
        ? KEYBOARD_OFFSET_DELTA_FINE
        : KEYBOARD_OFFSET_DELTA_COARSE

      let delta: Vec2 = [0, 0]

      switch (event.key) {
        case 'ArrowUp': {
          delta = [0, -deltaPercent * layout.size[1]]
          break
        }
        case 'ArrowRight': {
          delta = [deltaPercent * layout.size[0], 0]
          break
        }
        case 'ArrowDown': {
          delta = [0, deltaPercent * layout.size[1]]
          break
        }
        case 'ArrowLeft': {
          delta = [-deltaPercent * layout.size[0], 0]
          break
        }
      }

      const newOffset = convertLayoutOffsetToCellOffset({
        imageSize: image.size,
        frameSize: frame.size,
        scale: cell.scale,
        offset: vec2Add(layout.offset, delta),
      })

      onChangeTransform({
        offset: newOffset,
        scale: cell.scale,
        last: true,
      })
    },
    [
      cell.scale,
      frame.size,
      image.size,
      layout.offset,
      layout.size,
      mode,
      onChangeTransform,
    ],
  )

  const handleDrag = useCallback(
    (event: DragEvent) => {
      isDragging.current = !event.last

      if (mode === 'rearrange') {
        onChangePosition(event)
      }
    },
    [mode, onChangePosition],
  )

  return (
    <>
      {renderContent && (
        <>
          <defs>
            <clipPath id={clipPathId}>
              <animated.rect
                role="presentation"
                x={p.xClip}
                y={p.yClip}
                width={p.wClip}
                height={p.hClip}
              />
            </clipPath>
          </defs>
          <g clipPath={`url(#${clipPathId})`}>
            <animated.g
              transform={to(
                [p.x, p.y, p.w, p.h, p.scale, p.offsetX, p.offsetY],
                (x, y, w, h, s, offsetX, offsetY) =>
                  createTransform({
                    frame: {
                      position: [x, y],
                      size: [w, h],
                    },
                    offset: [offsetX, offsetY],
                    scale: s,
                    imageSize: image.size,
                  }),
              )}
            >
              <image
                role="presentation"
                data-testid="mp-ed-collage-image"
                data-test-cell-id={id}
                data-test-frame-offset={`${frame.position[0]} ${frame.position[1]}`}
                href={image.url}
              />
            </animated.g>
            <RearrangeIndicator
              animatedX={p.xClip}
              animatedY={p.yClip}
              animatedW={p.wClip}
              animatedH={p.hClip}
              scale={scale}
            />
          </g>
        </>
      )}
      <InteractiveCellButton
        label={label}
        bounds={{
          position: vec2Add(clippedFrame.position, positionOffset),
          size: clippedFrame.size,
        }}
        cell={cell}
        scale={scale}
        active={mode !== 'static'}
        dimmed={dimmed}
        targeted={targeted}
        disableSelectCell={disableSelectCell}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        onDrag={handleDrag}
      >
        {mode === 'manipulate' && !dimmed && !selected && (
          <InteractiveImageManipulation
            bounds={clippedFrame}
            cell={cell}
            frame={frame}
            layout={layout}
            image={image}
            onChangeTransform={onChangeTransform}
          />
        )}
      </InteractiveCellButton>
    </>
  )
}
