import { MouseEvent, useCallback, useEffect, useMemo, useRef } from 'react'

const MOVE_THRESHOLD_PX = 10
const MOVE_THRESHOLD_PX_SQUARED = MOVE_THRESHOLD_PX * MOVE_THRESHOLD_PX

const isTextInputFocussed = (): boolean => {
  if (
    document.activeElement instanceof HTMLDivElement &&
    document.activeElement.hasAttribute('contenteditable')
  ) {
    return true
  }

  return false
}

export const useThresholdClick = (
  handleClick: (event: MouseEvent) => void,
): {
  onClick: (event: MouseEvent) => void
  onMouseDown: (event: MouseEvent) => void
} => {
  const downPosition = useRef({ x: 0, y: 0, focussedText: false })

  const onMouseDown = useCallback((event: MouseEvent) => {
    downPosition.current = {
      x: event.screenX,
      y: event.screenY,
      focussedText: isTextInputFocussed(),
    }
  }, [])

  const onClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()

      const position = { x: event.screenX, y: event.screenY }
      const dx = position.x - downPosition.current.x
      const dy = position.y - downPosition.current.y
      const distanceSquared = dx * dx + dy * dy

      if (
        distanceSquared <= MOVE_THRESHOLD_PX_SQUARED &&
        !downPosition.current.focussedText
      ) {
        handleClick(event)
      }
    },
    [handleClick],
  )

  const events = useMemo(
    () => ({ onMouseDown, onClick }),
    [onClick, onMouseDown],
  )

  return events
}

export const useThresholdClickOn = (
  element: Element,
  handleClick: (event: MouseEvent) => void,
): void => {
  const events = useThresholdClick(handleClick)

  useEffect(() => {
    element.addEventListener(
      'mousedown',
      events.onMouseDown as unknown as EventListener,
    )
    element.addEventListener(
      'click',
      events.onClick as unknown as EventListener,
    )

    return () => {
      element.removeEventListener(
        'mousedown',
        events.onMouseDown as unknown as EventListener,
      )
      element.removeEventListener(
        'click',
        events.onClick as unknown as EventListener,
      )
    }
  }, [element, events.onClick, events.onMouseDown])
}
