import { Box, Flex } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC, ReactNode } from 'react'

export const PAGE_LAYOUT_DESKTOP_BP = 'lg'
export const PAGE_LAYOUT_HEADER_HEIGHT_MOBILE = 56
export const PAGE_LAYOUT_HEADER_HEIGHT_DESKTOP = 64
export const PAGE_LAYOUT_NAV_HEIGHT_MOBILE = 80
export const PAGE_LAYOUT_NAV_HEIGHT_DESKTOP = 120

const StyledLayout = styled.div<{ enableNativePan?: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
  touch-action: ${({ enableNativePan }) =>
    enableNativePan ? 'pan-x pan-y' : 'none'};
`

type PageLayoutProps = {
  header: ReactNode
  nav: ReactNode
  main: ReactNode
  notifications?: ReactNode
  modal?: ReactNode
  enableNativePan?: boolean
  navHeight?: number
}

export const PageLayout: FC<PageLayoutProps> = ({
  header,
  nav,
  main,
  notifications,
  modal,
  enableNativePan,
  navHeight,
}) => {
  const navHeightMobile = navHeight ?? PAGE_LAYOUT_NAV_HEIGHT_MOBILE
  const navHeightDesktop = navHeight ?? PAGE_LAYOUT_NAV_HEIGHT_DESKTOP

  return (
    <StyledLayout
      data-testid="mp-ed-page-layout"
      enableNativePan={enableNativePan}
    >
      <Flex
        as="header"
        bgcolor="colorBackground01"
        width="100%"
        height={{
          xs: `${PAGE_LAYOUT_HEADER_HEIGHT_MOBILE}px`,
          [PAGE_LAYOUT_DESKTOP_BP]: `${PAGE_LAYOUT_HEADER_HEIGHT_DESKTOP}px`,
        }}
        position="relative"
        boxShadow={1}
        zIndex={1}
        flexShrink={0}
      >
        <Box
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          px={6}
          py={{ xs: 3, [PAGE_LAYOUT_DESKTOP_BP]: 4 }}
        >
          {header}
        </Box>
      </Flex>
      <Flex
        as="nav"
        bgcolor="colorBackground01"
        width="100%"
        pb="env(safe-area-inset-bottom)"
        height={{
          xs: `calc(${navHeightMobile}px + env(safe-area-inset-bottom))`,
          [PAGE_LAYOUT_DESKTOP_BP]: `calc(${navHeightDesktop}px + env(safe-area-inset-bottom))`,
        }}
        order={2}
        position="relative"
        boxShadow={1}
        zIndex={1}
        flexShrink={0}
      >
        {nav}
      </Flex>
      <Flex
        as="main"
        bgcolor="colorBackgroundSite"
        width="100%"
        flex={1}
        position="relative"
        minHeight="0"
      >
        <Flex zIndex={3}>{notifications}</Flex>
        {main}
      </Flex>
      {modal}
    </StyledLayout>
  )
}
