import { logger } from '@moonpig/web-core-monitoring'
import {
  RecordAudio,
  RecordAudioStopResult,
} from '@moonpig/web-personalise-editor-types'

const TIME_PER_SAMPLE_SECONDS = 0.2
const FFT_WINDOW_SIZE_SAMPLES = 32

const EXPECTED_ERRORS = new Set([
  'AbortError',
  'NotAllowedError',
  'NotFoundError',
  'NotReadableError',
  'OverconstrainedError',
  'SecurityError',
])

const chooseMimeType = (supportedMimeTypes: string[]): string => {
  const supportedMimeType = supportedMimeTypes.find(mimeType =>
    MediaRecorder.isTypeSupported(mimeType),
  )

  if (!supportedMimeType) {
    throw new Error('No supported audio mime type found')
  }

  return supportedMimeType
}

export const createRecordAudio = (): RecordAudio => {
  return async ({ supportedMimeTypes, onSample }) => {
    try {
      const mimeType = chooseMimeType(supportedMimeTypes)
      const audioChunks: Blob[] = []
      const allSamples: number[] = []

      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      })

      const context = new AudioContext()
      const analyser = context.createAnalyser()

      analyser.fftSize = FFT_WINDOW_SIZE_SAMPLES
      analyser.smoothingTimeConstant = 0
      analyser.maxDecibels = -60

      const source = context.createMediaStreamSource(stream)
      source.connect(analyser)

      let resolveStop: (result: RecordAudioStopResult) => void
      let currentTimeInSeconds = 0

      const stopPromise = new Promise<RecordAudioStopResult>(resolve => {
        resolveStop = resolve
      })

      const mediaRecorder = new MediaRecorder(stream, { mimeType })
      mediaRecorder.ondataavailable = (event: BlobEvent) => {
        audioChunks.push(event.data)

        if (mediaRecorder.state === 'inactive') {
          const data = new Blob(audioChunks, { type: mimeType })
          const url = window.URL.createObjectURL(data)

          resolveStop({
            type: 'success',
            data,
            url,
            duration: currentTimeInSeconds,
            samples: allSamples,
            timePerSampleSeconds: TIME_PER_SAMPLE_SECONDS,
          })
        } else {
          const dataArray = new Uint8Array(analyser.frequencyBinCount)
          analyser.getByteFrequencyData(dataArray)

          const frequencyAverage =
            dataArray.reduce((sum, freq) => sum + freq, 0) /
            dataArray.byteLength

          const amplitude = frequencyAverage / 255

          currentTimeInSeconds += TIME_PER_SAMPLE_SECONDS

          allSamples.push(amplitude)

          onSample({ amplitude, currentTimeInSeconds })
        }
      }

      mediaRecorder.onerror = () => {
        resolveStop({ type: 'error' })
      }

      const timesliceMilliseconds = TIME_PER_SAMPLE_SECONDS * 1000
      mediaRecorder.start(timesliceMilliseconds)

      return {
        type: 'success',
        timePerSampleSeconds: TIME_PER_SAMPLE_SECONDS,
        stop: async () => {
          mediaRecorder.stop()

          if (context.state !== 'closed') {
            await context.close()
          }

          stream.getTracks().forEach(track => track.stop())

          return stopPromise
        },
      }
    } catch (error) {
      if (EXPECTED_ERRORS.has(error.name)) {
        return { type: 'error' }
      }

      logger.fixImmediately('Failed to start audio recording', {}, error)

      return { type: 'error' }
    }
  }
}
