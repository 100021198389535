import {
  Transform2d,
  Vec2,
  clamp,
  degToRad,
  radToDeg,
} from '@moonpig/common-math'
import {
  TransformContext,
  useContacts,
  useKeyboard,
  usePointer,
  useTransform,
} from '@moonpig/use-transform'
import { InteractiveElement } from '@moonpig/web-personalise-components'
import { DesignElementMedia } from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useRef } from 'react'
import { useCamera } from '../../../../../../../camera'
import { FOCUS_ID_ELEMENT } from '../../../../../../../constants'
import { createElementDomId } from '../../../../../../../utils/createElementId'
import { isKeyboardTransformDisabled } from '../../../../../../../utils/isKeyboardTransformDisabled'
import { useDisablePageZoom } from '../../../../../../../utils/useDisablePageZoom'
import {
  getGestureChangeType,
  useAction,
  useMainView,
  useView,
} from '../../../../../../../store'
import { useElementRef } from '../../../../../selectors'
import { InteractiveElementProps } from '../../types'
import { LoadingIndicator } from '../LoadingIndicator'

export const ElementMediaInteractive: FC<
  InteractiveElementProps<DesignElementMedia>
> = ({ elementRef, title, sceneX, sceneY, sceneHeight, sceneWidth }) => {
  const element = useElementRef(elementRef)
  const { inverseScale, toWorldPoint, domBounds, setFocusLocked } = useCamera()
  const selectScene = useAction('selectScene')
  const selectElement = useAction('selectElement')
  const updateElementRef = useAction('updateElementRef')
  const activeElementId = useMainView('edit', view => view.activeElementId)
  const selectedSceneId = useView('main', view => view.selectedSceneId)
  const isSelected = activeElementId === elementRef.id
  const isSceneActive = selectedSceneId === elementRef.sceneId
  const containerRef = useRef<SVGGElement>(null)
  const describedById = `mp-ed-media-interactive-${element.id}`

  useDisablePageZoom(containerRef)

  const handleSelect = useCallback(() => {
    if (!isSceneActive) {
      selectScene(elementRef.sceneId, 'FOCUS')
    } else if (!isSelected) {
      selectElement(elementRef.sceneId, element.id)
    }
  }, [
    isSceneActive,
    isSelected,
    selectScene,
    elementRef.sceneId,
    selectElement,
    element.id,
  ])

  const handleFocus = useCallback(() => {
    selectScene(elementRef.sceneId, 'FOCUS')
  }, [selectScene, elementRef.sceneId])

  const x = element.x + element.width
  const y = element.y + element.height
  const rotation = degToRad(element.rotation)

  const setValue = useCallback(
    (transform: Transform2d, { last }: TransformContext) => {
      setFocusLocked(!last)

      updateElementRef(
        elementRef,
        current => {
          return {
            ...current,
            x: clamp(
              transform.position[0] - current.width,
              0,
              sceneWidth - current.width,
            ),
            y: clamp(
              transform.position[1] - current.height,
              0,
              sceneHeight - current.height,
            ),
          }
        },
        { changeType: getGestureChangeType({ last }) },
      )

      if (last && !isSelected) {
        handleSelect()
      }
    },
    [
      setFocusLocked,
      updateElementRef,
      elementRef,
      isSelected,
      sceneWidth,
      sceneHeight,
      handleSelect,
    ],
  )

  const transform = useTransform({
    value: {
      position: [x, y],
      rotation,
      scale: [1, 1],
    },
    setValue,
    options: {},
  })

  const transformPoint = useCallback(
    (screenPoint: Vec2): Vec2 => {
      const screenX = screenPoint[0] - domBounds.x
      const screenY = screenPoint[1] - domBounds.y

      const worldPoint = toWorldPoint([screenX, screenY])
      return [worldPoint[0] - sceneX, worldPoint[1] - sceneY]
    },
    [domBounds.x, domBounds.y, sceneX, sceneY, toWorldPoint],
  )

  const onContactsChange = useContacts({
    transform,
    options: { disableRotation: true },
  })

  const disabled = Boolean(!isSelected)

  const bindPointer = usePointer(onContactsChange, {
    id: element.type,
    transformPoint,
    captureEvents: true,
  })

  useKeyboard({
    transform,
    disabled: () => disabled || isKeyboardTransformDisabled(),
    options: { increment: 1 },
  })

  const svgTransform = [
    `translate(${transform.value.position[0]}, ${transform.value.position[1]})`,
    `rotate(${radToDeg(transform.value.rotation)})`,
    `translate(${-element.width}, ${-element.height})`,
  ].join(' ')

  return (
    <>
      <g transform={svgTransform} ref={containerRef}>
        <InteractiveElement
          id={createElementDomId(element.id)}
          describedById={describedById}
          label={title}
          scale={inverseScale}
          width={element.width}
          height={element.height}
          state={isSelected ? 'SELECTED' : 'DEFAULT'}
          focusId={FOCUS_ID_ELEMENT(element.id)}
          onSelect={handleSelect}
          onFocus={handleFocus}
          moveableEvents={{ ...bindPointer() }}
        />
      </g>
      {element.state.type === 'loading' && (
        <LoadingIndicator id={describedById} element={element} />
      )}
    </>
  )
}
