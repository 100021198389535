import React, { useCallback } from 'react'
import { useAction, useView } from '../../../../../../../store'
import {
  Toolbar,
  ToolbarItem,
  ToolbarMenu,
  ToolbarOrientation,
  ToolbarTooltip,
} from '../Toolbar'

export type ToolbarCommonProps<TItemId> = {
  controlId?: string
  label: string
  orientation: ToolbarOrientation
  items: ToolbarItem<TItemId>[]
  selectedItemId: string | null
  onSelectItem: (itemId: TItemId | null) => void
  menu?: ToolbarMenu
  done?: {
    label: string
    ariaLabel: string
    onDone: () => void
  }
  tooltip?: ToolbarTooltip
}

export const ToolbarCommon = <TItemId extends string>(
  props: ToolbarCommonProps<TItemId>,
) => {
  const carouselViewed = useView('main', view => view.onboardingViewed.carousel)
  const updateOnboardingViewed = useAction('updateOnboardingViewed')

  /* istanbul ignore next */
  const handleCarouselViewed = useCallback(() => {
    updateOnboardingViewed({ carousel: true })
  }, [updateOnboardingViewed])

  return (
    <Toolbar
      {...props}
      carouselViewed={carouselViewed}
      handleCarouselViewed={handleCarouselViewed}
    />
  )
}
