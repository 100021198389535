import { modNeg } from '@moonpig/common-math'
import {
  Box,
  CarouselControls,
  CarouselNextButton,
  CarouselPreviousButton,
  CarouselRoot,
  CarouselState,
  UnstyledCarouselScrollArea,
  Text,
  focusIndicatorStyles,
} from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { IconDone } from '../../assets'
import { useConsumeTouch } from '../../utils'
import { useFocusRef } from '../FocusManager'
import { ScreenReaderOnly } from '../ScreenReaderOnly'
import { Tooltip } from '../Tooltip/Tooltip'
import { ToolbarItem, ToolbarOrientation } from './types'

const UI_TOOLBAR_VERTICAL_WIDTH = 88
const UI_TOOLBAR_HORIZONTAL_HEIGHT = 72
const DISABLED_BUTTON_COLOR = 'rgba(108, 117, 132, 0.5)'
const CAROUSEL_MARGIN_X = -16
const TOOLTIP_CONTENT_LINE_HEIGHT = 1.2

const combineClassNames = (classNames: string[]) =>
  classNames.filter(Boolean).join(' ')

const StyledToolbar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: ${UI_TOOLBAR_HORIZONTAL_HEIGHT}px;
  pointer-events: all;

  ${s({ bgcolor: 'colorBackground01', borderRadius: 2, boxShadow: 1 })}

  &.vertical {
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;
    width: ${UI_TOOLBAR_VERTICAL_WIDTH}px;
    height: max-content;
    max-height: 100%;
    padding-left: 0;
  }
`

const StyledItems = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  min-width: 0;

  &.vertical {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    height: auto;
    width: 100%;

    ${s({
      pr: 0,
      pb: 5,
    })}
  }

  > button {
    ${s({
      ml: 5,
      mt: 0,
    })}
  }

  &.vertical > button {
    ${s({
      ml: 0,
      mt: 5,
    })}
  }
`

const StyledButton = styled.button`
  ${focusIndicatorStyles}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: inherit;
  min-width: 64px;
  touch-action: pan-x;

  ${s({ color: 'colorTextBody', borderRadius: 2 })}

  &.focus-visible {
    position: fixed;
    top: -999999px;

    &:focus {
      position: static;
    }
  }
  &.active,
  &:hover {
    ${s({ color: 'colorInteractionButton' })}
  }

  &.selected {
    ${s({ color: 'colorInteractionButton' })}
  }

  &:disabled {
    color: ${DISABLED_BUTTON_COLOR};
  }

  &.vertical {
    touch-action: pan-y;
  }
`

const StyledDone = styled.div`
  position: relative;
  width: auto;
  box-shadow: -8px 0 8px 0 white;
  ${s({ pl: 5 })}

  &.vertical {
    box-shadow: 0 -8px 8px 0 white;
    ${s({
      px: 0,
      py: 5,
    })}
  }
`

const StyledIcon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  ${s({ bgcolor: 'colorBackground02' })}
`

const StyledLabel = styled.div`
  color: inherit;
  ${s({ px: 4, mt: 0, typography: 'bodySmall' })}

  .horizontal & {
    white-space: nowrap;
  }
`

const ToolbarContent: FC<{
  orientation: ToolbarOrientation
  carouselViewed: boolean
  handleCarouselViewed?: () => void
}> = ({ orientation, children, carouselViewed, handleCarouselViewed }) => {
  if (orientation === 'horizontal' && handleCarouselViewed) {
    return (
      <Box
        maxWidth={`calc(100% - ${CAROUSEL_MARGIN_X * 2}px)`}
        minWidth={0}
        mx={`${CAROUSEL_MARGIN_X}px`}
      >
        <CarouselState>
          <CarouselRoot>
            <CarouselControls>
              <CarouselNextButton />
              <CarouselPreviousButton />
            </CarouselControls>
            <UnstyledCarouselScrollArea
              animateInView={!carouselViewed}
              onAnimationEnd={handleCarouselViewed}
            >
              {React.Children.map(children, child => (
                <div>{child}</div>
              ))}
            </UnstyledCarouselScrollArea>
          </CarouselRoot>
        </CarouselState>
      </Box>
    )
  }

  return <>{children}</>
}

export type ToolbarProps<TItemId> = {
  id: string
  focusId: string
  controlId?: string
  label: string
  orientation: ToolbarOrientation
  items: ToolbarItem<TItemId>[]
  selectedItemId: string | null
  onSelectItem: (itemId: TItemId | null) => void
  done?: {
    label: string
    ariaLabel: string
    onDone: () => void
  }
  tooltip?: {
    content: string
    itemIndex: number
    onDismiss?: () => void
    done?: {
      onDone: () => void
      ariaLabel: string
      label: string
    }
    next?: {
      onNext: () => void
      ariaLabel: string
      label: string
    }
  }
  carouselViewed: boolean
  handleCarouselViewed: () => void
}

export const Toolbar = <TItemId extends string>({
  id,
  focusId,
  controlId,
  label,
  orientation,
  items,
  selectedItemId,
  onSelectItem,
  done,
  tooltip,
  carouselViewed,
  handleCarouselViewed,
}: ToolbarProps<TItemId>) => {
  const [navIndex, setNavIndex] = useState<number>(0)

  const buttonsRef = useRef<(HTMLButtonElement | null)[]>([])

  const headingRef = useFocusRef<HTMLHeadingElement>(focusId)

  useEffect(() => {
    buttonsRef.current = buttonsRef.current.slice(0, items.length)
  }, [items])

  const setNavIndexAndFocus = useCallback((index: number) => {
    setNavIndex(index)

    const button = buttonsRef.current[index]
    /* istanbul ignore else */
    if (button) button.focus()
  }, [])

  const handleClickItem = useCallback(
    (index: number) => {
      const item = items[index]
      setNavIndexAndFocus(index)
      onSelectItem(item.id === selectedItemId ? null : item.id)
    },
    [items, setNavIndexAndFocus, onSelectItem, selectedItemId],
  )

  const handleKeyDown = useCallback(
    (index: number, event: React.KeyboardEvent<HTMLButtonElement>) => {
      const { key } = event
      switch (key) {
        case 'ArrowLeft':
          if (orientation === 'horizontal') {
            event.preventDefault()
            setNavIndexAndFocus(modNeg(index - 1, items.length))
          }
          break
        case 'ArrowRight':
          if (orientation === 'horizontal') {
            event.preventDefault()
            setNavIndexAndFocus(modNeg(index + 1, items.length))
          }
          break
        case 'ArrowUp':
          if (orientation === 'vertical') {
            event.preventDefault()
            setNavIndexAndFocus(modNeg(index - 1, items.length))
          }
          break
        case 'ArrowDown':
          if (orientation === 'vertical') {
            event.preventDefault()
            setNavIndexAndFocus(modNeg(index + 1, items.length))
          }
          break
        case 'Home':
          event.preventDefault()
          setNavIndexAndFocus(0)
          break
        case 'End':
          event.preventDefault()
          setNavIndexAndFocus(items.length - 1)
          break
      }
    },
    [items.length, orientation, setNavIndexAndFocus],
  )

  const bindConsumeTouch = useConsumeTouch()

  return (
    <Box position="relative">
      <StyledToolbar
        id={id}
        className={orientation}
        role="toolbar"
        aria-label={label}
        aria-controls={controlId}
        aria-orientation={orientation}
        {...bindConsumeTouch()}
      >
        <ScreenReaderOnly>
          <h3 ref={headingRef} tabIndex={-1}>
            {label}
          </h3>
        </ScreenReaderOnly>
        <StyledItems className={orientation}>
          <ToolbarContent
            orientation={orientation}
            carouselViewed={carouselViewed}
            handleCarouselViewed={handleCarouselViewed}
          >
            {items.map((item, index) => {
              return (
                <StyledButton
                  ref={button => {
                    buttonsRef.current[index] = button
                  }}
                  data-disable-keyboard-transform="true"
                  disabled={item.disabled}
                  className={combineClassNames([
                    item.id === selectedItemId ? 'selected' : '',
                    item.disabled ? 'disabled' : '',
                    item.focusVisible ? 'focus-visible' : '',
                    orientation,
                  ])}
                  key={item.id}
                  type="button"
                  aria-label={item.ariaLabel || item.label}
                  {...(item.disabled ? { 'aria-disabled': item.disabled } : {})}
                  {...(item.type === 'menu'
                    ? {
                        'aria-haspopup': true,
                        'aria-expanded': item.id === selectedItemId,
                        'aria-controls':
                          item.id === selectedItemId ? item.id : undefined,
                      }
                    : {})}
                  tabIndex={navIndex === index ? 0 : -1}
                  onClick={() => handleClickItem(index)}
                  onKeyDown={event => handleKeyDown(index, event)}
                >
                  <StyledIcon>{item.icon}</StyledIcon>
                  <StyledLabel aria-hidden>{item.label}</StyledLabel>
                </StyledButton>
              )
            })}
          </ToolbarContent>
        </StyledItems>
        {done && (
          <StyledDone className={orientation}>
            <StyledButton
              type="button"
              aria-label={done.ariaLabel}
              onClick={event => {
                event.stopPropagation()
                done.onDone()
              }}
            >
              <Box mt={3}>
                <IconDone />
              </Box>
              <StyledLabel aria-hidden>{done.label}</StyledLabel>
            </StyledButton>
          </StyledDone>
        )}
        {tooltip && (
          <Tooltip
            target={buttonsRef.current[tooltip.itemIndex]}
            placement={orientation === 'horizontal' ? 'bottom' : 'right'}
            {...tooltip}
          >
            <Text
              as="div"
              lineHeight={TOOLTIP_CONTENT_LINE_HEIGHT}
              typography="bodySmall"
            >
              {tooltip.content}
            </Text>
          </Tooltip>
        )}
      </StyledToolbar>
    </Box>
  )
}
