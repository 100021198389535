import { useEffect, useState } from 'react'
import { toError } from '../toError'

export type LoadImageOptions = {
  crossOrigin?: 'anonymous' | 'use-credentials'
}

export const loadImage = (
  url: string,
  options?: LoadImageOptions,
): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => {
      resolve(image)
    })
    image.addEventListener('error', e => {
      reject(e)
    })
    if (options?.crossOrigin) {
      image.crossOrigin = options.crossOrigin
    }
    image.src = url
  })

export const loadImages = (
  urls: string[],
  options?: LoadImageOptions,
): Promise<HTMLImageElement[]> =>
  Promise.all(urls.map(url => loadImage(url, options)))

export const useLoadImages = (
  urls: string[],
  options?: LoadImageOptions,
): [boolean, HTMLImageElement[] | null, Error | null] => {
  const [images, setImages] = useState<HTMLImageElement[] | null>(null)
  const [error, setError] = useState<Error | null>(null)
  useEffect(() => {
    const load = async () => {
      try {
        const all = await loadImages(urls, options)
        setImages(all)
      } catch (e) {
        setError(toError(e))
      }
    }
    setImages(null)
    load()
  }, [options, urls])
  const loading = !images && !error
  return [loading, images, error]
}
