import {
  IconSystemAlignCenter,
  IconSystemAlignJustify,
  IconSystemAlignLeft,
  IconSystemAlignRight,
} from '@moonpig/launchpad-assets'
import {
  IconChevronDown,
  IconChevronUp,
  IconDelete,
  IconKeyboard,
  IconRotate,
  IconScale,
  IconSmartText,
} from '@moonpig/web-personalise-components'
import { DesignHorizontalAlignment } from '@moonpig/web-personalise-editor-types'
import React, { ReactNode, useCallback, useMemo } from 'react'
import shallow from 'zustand/shallow'
import { createElementDomId } from '../../../../../../../utils/createElementId'
import {
  UIOverlayText,
  UIOverlayTextMenuItem,
  useAction,
  useEditorFeatures,
  useView,
} from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { useElementRef } from '../../../../../selectors'
import { useDeleteElement } from '../../../../useDeleteElement'
import { ToolbarMenu } from '../Toolbar'
import {
  ToolbarItem,
  ToolbarOrientation,
  ToolbarTooltip,
} from '../Toolbar/types'
import { ToolbarCommon } from '../ToolbarCommon'
import { getLayerControlsState } from '../utils/elementLayerState'
import { ElementFontFacePicker } from './ElementFontFacePicker'
import { ElementFontSizePicker } from './ElementFontSizePicker'
import { ElementRotationPicker } from './ElementRotationPicker'
import { ElementScalePicker } from './ElementScalePicker'
import { ElementTextAlignmentPicker } from './ElementTextAlignmentPicker'
import { ElementTextColorPicker } from './ElementTextColorPicker'
import { SmartTextForm } from './SmartTextForm'

type ToolbarOverlayTextProps = {
  onDone: () => void
  orientation: ToolbarOrientation
  ui: UIOverlayText
}

const iconByHorizontalAlignment: {
  [align in DesignHorizontalAlignment]: ReactNode
} = {
  LEFT: <IconSystemAlignLeft width="28" height="28" />,
  CENTER: <IconSystemAlignCenter width="28" height="28" />,
  RIGHT: <IconSystemAlignRight width="28" height="28" />,
  JUSTIFY: <IconSystemAlignJustify width="28" height="28" />,
}

const getClosestAvailableFontSize = (
  availableFontSizes: number[],
  updatedFontSize: number,
): number =>
  availableFontSizes.reduce((prev, curr) => {
    return curr <= updatedFontSize ? curr : prev
  })

export const ToolbarOverlayText = ({
  ui,
  onDone,
  orientation,
}: ToolbarOverlayTextProps) => {
  const { elementRef, selectedMenuItem } = ui
  const element = useElementRef(elementRef)
  const t = useEditorLocaleText()
  const setUI = useAction('setUI')
  const trackEvent = useAction('trackEvent')
  const deleteElement = useDeleteElement()
  const reorderElement = useAction('reorderElement')
  const features = useEditorFeatures()
  const smartTextConfig = useView(
    'main',
    view => view.customisationConfig.smartTextConfig,
  )
  const smartTextOnboardingViewed = useView(
    'main',
    view => view.onboardingViewed.smartText,
  )
  const updateOnboardingViewed = useAction('updateOnboardingViewed')
  const productKey = useView('main', view => view.design.productKey)
  const { enableSmartText } = features
  const controlId = createElementDomId(element.id)
  const {
    availableTextStyles: {
      availableFontSizes,
      availableFontFaceIds,
      availableFontFaceById,
      availableColorIds,
      availableColorById,
    },
  } = useView('main', view => view.design)

  const currentFont = availableFontFaceById[element.customisations.fontFaceId]
  const currentColor = availableColorById[element.customisations.colorId]
  const currentHorizontalAlignment = element.customisations.horizontalAlignment
  const currentVerticalAlignment = element.customisations.verticalAlignment

  const fontIconFontFamily = useView('main', ({ fontById }) => {
    const state = fontById[currentFont.id]
    return state.type === 'loaded'
      ? state.fontFamily
      : /* istanbul ignore next: guaranteed to be loaded */ undefined
  })

  const { forwardButtonDisabled, backwardButtonDisabled } = useView(
    'main',
    ({ design }) => getLayerControlsState(elementRef, design),
    shallow,
  )

  const dismissSmartTextOnboarding = useCallback(() => {
    updateOnboardingViewed({ smartText: true })
  }, [updateOnboardingViewed])

  const selectedFontSize = useMemo(() => {
    return getClosestAvailableFontSize(
      availableFontSizes,
      element.customisations.fontSize,
    )
  }, [availableFontSizes, element.customisations])

  const smartMessageToolbarItem = useMemo<
    ToolbarItem<UIOverlayTextMenuItem>[]
  >(() => {
    const { smartTextFeatureEnabled, remainingRequests } = smartTextConfig
    return enableSmartText && smartTextFeatureEnabled
      ? [
          {
            type: 'button',
            id: 'smart-text',
            label: t('toolbar_smart_text'),
            ariaLabel: t('toolbar_smart_text_aria_label'),
            icon: <IconSmartText />,
            disabled: remainingRequests === 0,
          },
        ]
      : []
  }, [enableSmartText, smartTextConfig, t])

  const transformableItems = useMemo<
    ToolbarItem<UIOverlayTextMenuItem>[]
  >(() => {
    if (element.fixed) {
      return []
    }

    return [
      {
        type: 'menu',
        id: 'scale',
        label: t('toolbar_scale_label'),
        ariaLabel: t('toolbar_text_scale_aria_label'),
        icon: <IconScale />,
        disabled: false,
      },
      {
        type: 'menu',
        id: 'rotate',
        label: t('toolbar_rotate_label'),
        ariaLabel: t('toolbar_text_rotate_aria_label'),
        icon: <IconRotate />,
        disabled: false,
      },
      {
        type: 'button',
        id: 'forward',
        label: t('toolbar_move_to_front_label'),
        ariaLabel: t('toolbar_text_move_to_front_aria_label'),
        icon: <IconChevronUp />,
        disabled: forwardButtonDisabled,
      },
      {
        type: 'button',
        id: 'backward',
        label: t('toolbar_move_to_back_label'),
        ariaLabel: t('toolbar_text_move_to_back_aria_label'),
        icon: <IconChevronDown />,
        disabled: backwardButtonDisabled,
      },
    ]
  }, [backwardButtonDisabled, element.fixed, forwardButtonDisabled, t])

  const items = useMemo<ToolbarItem<UIOverlayTextMenuItem>[]>(() => {
    return [
      {
        type: 'button',
        id: 'keyboard',
        label: t('toolbar_keyboard_label'),
        ariaLabel: t('toolbar_open_keyboard'),
        icon: <IconKeyboard />,
        disabled: false,
        focusVisible: orientation === 'vertical',
      },
      ...smartMessageToolbarItem,
      {
        type: 'menu',
        id: 'size',
        label: t('toolbar_text_size_label'),
        ariaLabel: t('toolbar_current_text_size', selectedFontSize),
        icon: (
          <div aria-hidden style={{ fontWeight: 'bold', fontSize: 16 }}>
            {selectedFontSize}
          </div>
        ),
        disabled: false,
      },
      {
        type: 'menu',
        id: 'font',
        label: t('toolbar_font_label'),
        ariaLabel: t('toolbar_current_font', currentFont.name),
        icon: (
          <div
            aria-hidden
            style={{
              fontWeight: 'bold',
              fontSize: 16,
              fontFamily: `${fontIconFontFamily}, sans-serif`,
            }}
          >
            Aa
          </div>
        ),
        disabled: false,
      },
      {
        type: 'menu',
        id: 'color',
        label: t('toolbar_text_color_label'),
        ariaLabel: t('toolbar_current_text_color', currentColor.name),
        icon: (
          <div
            aria-hidden
            style={{
              borderRadius: 16,
              backgroundColor: currentColor.value,
              width: 28,
              height: 28,
            }}
          />
        ),
        disabled: false,
      },
      {
        type: 'menu',
        id: 'alignment',
        label: t('toolbar_text_alignment_label'),
        ariaLabel: t(
          'toolbar_current_text_alignment',
          t('vertical_alignment', currentVerticalAlignment),
          t('horizontal_alignment', currentHorizontalAlignment),
        ),
        icon: iconByHorizontalAlignment[currentHorizontalAlignment],
        disabled: false,
      },
      ...transformableItems,
      {
        type: 'menu',
        id: 'delete',
        label: t('toolbar_text_delete_label'),
        ariaLabel: t('toolbar_text_delete_aria_label'),
        icon: <IconDelete />,
        disabled: false,
      },
    ]
  }, [
    t,
    orientation,
    smartMessageToolbarItem,
    selectedFontSize,
    currentFont.name,
    fontIconFontFamily,
    currentColor.name,
    currentColor.value,
    currentVerticalAlignment,
    currentHorizontalAlignment,
    transformableItems,
  ])

  const menu = useMemo<ToolbarMenu | undefined>(() => {
    switch (selectedMenuItem) {
      case 'smart-text':
        return {
          title: t('toolbar_smart_text'),
          content: <SmartTextForm elementRef={elementRef} />,
          closeLabel: t('toolbar_close_smart_text_menu'),
          drawerHeight: 'expand',
        }
      case 'size':
        return {
          title: t('toolbar_text_size_menu_heading'),
          content: (
            <ElementFontSizePicker
              elementRef={elementRef}
              availableFontSizes={availableFontSizes}
            />
          ),
          closeLabel: t('toolbar_close_text_size_menu'),
          drawerHeight: 'fit',
        }
      case 'font':
        return {
          title: t('toolbar_font_menu_heading'),
          content: (
            <ElementFontFacePicker
              elementRef={elementRef}
              availableFontFaceIds={availableFontFaceIds}
              availableFontFaceById={availableFontFaceById}
            />
          ),
          closeLabel: t('toolbar_close_font_menu'),
        }
      case 'color':
        return {
          title: t('toolbar_text_color_menu_heading'),
          content: (
            <ElementTextColorPicker
              elementRef={elementRef}
              availableColorIds={availableColorIds}
              availableColorById={availableColorById}
            />
          ),
          closeLabel: t('toolbar_close_text_color_menu'),
        }
      case 'alignment':
        return {
          title: t('toolbar_text_alignment_menu_heading'),
          content: <ElementTextAlignmentPicker elementRef={elementRef} />,
          closeLabel: t('toolbar_close_text_alignment_menu'),
          drawerHeight: 'fit',
        }
      case 'scale':
        return {
          title: t('toolbar_text_scale_menu_heading'),
          content: <ElementScalePicker elementRef={elementRef} />,
          closeLabel: t('toolbar_close_text_scale_menu'),
          drawerHeight: 'fit',
        }
      case 'rotate':
        return {
          title: t('toolbar_text_rotate_menu_heading'),
          content: <ElementRotationPicker elementRef={elementRef} />,
          closeLabel: t('toolbar_close_text_rotate_menu'),
          drawerHeight: 'fit',
        }
    }
  }, [
    selectedMenuItem,
    t,
    elementRef,
    availableFontSizes,
    availableFontFaceIds,
    availableFontFaceById,
    availableColorIds,
    availableColorById,
  ])

  const handleSelectItem = useCallback(
    (itemId: UIOverlayTextMenuItem | null) => {
      dismissSmartTextOnboarding()
      switch (itemId) {
        case 'delete': {
          deleteElement(ui.elementRef)
          break
        }
        case 'forward': {
          reorderElement({ elementRef, direction: 'to-front' })

          break
        }
        case 'backward': {
          reorderElement({ elementRef, direction: 'to-back' })

          break
        }
        case 'smart-text': {
          trackEvent({
            type: 'SELECT_CONTENT',
            kind: 'ADD_SMART_TEXT',
            productKey,
          })
          setUI({
            type: 'overlay-text',
            selectedMenuItem: 'smart-text',
            elementRef,
          })

          break
        }
        default: {
          setUI({
            ...ui,
            selectedMenuItem: itemId,
          })

          break
        }
      }
    },
    [
      deleteElement,
      ui,
      reorderElement,
      dismissSmartTextOnboarding,
      elementRef,
      productKey,
      setUI,
      trackEvent,
    ],
  )

  const onboardingTooltips = useMemo(() => {
    const tooltips: ToolbarTooltip[] = []

    const smartTextMenuItem = items.find(
      menuItem => menuItem.id === 'smart-text',
    )

    if (!smartTextOnboardingViewed && smartTextMenuItem) {
      tooltips.push({
        content: t('smart_text_onboarding_tooltip_text'),
        itemIndex: items.indexOf(smartTextMenuItem),
        onDismiss: dismissSmartTextOnboarding,
        done: {
          label: t('tooltip_onboarding_tooltip_done_label'),
          ariaLabel: t('tooltip_onboarding_dismiss_aria_label'),
          onDone: dismissSmartTextOnboarding,
        },
      })
    }

    return tooltips
  }, [items, dismissSmartTextOnboarding, smartTextOnboardingViewed, t])

  return (
    <ToolbarCommon
      controlId={controlId}
      label={t('toolbar_text_toolbar_title')}
      orientation={orientation}
      items={items}
      selectedItemId={selectedMenuItem}
      onSelectItem={handleSelectItem}
      menu={menu}
      done={{
        label: t('toolbar_done_button'),
        ariaLabel: t('toolbar_text_toolbar_close_label'),
        onDone,
      }}
      tooltip={onboardingTooltips[0]}
    />
  )
}
