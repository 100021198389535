import {
  useFocusOn,
  useThresholdClickOn,
} from '@moonpig/web-personalise-components'
import React, { FC, ReactNode, useCallback } from 'react'
import { useCamera } from '../../../../../camera'
import { FOCUS_ID_ELEMENT } from '../../../../../constants'
import { useAction, useMainView } from '../../../../../store'
import { useUI } from '../../selectors'
import { ToolbarOrientation } from './components/Toolbar'
import { ToolbarDefault } from './components/ToolbarDefault'
import { ToolbarImageCollage } from './components/ToolbarImageCollage'
import { ToolbarImageCollageCell } from './components/ToolbarImageCollageCell'
import { ToolbarImageCollageRearrange } from './components/ToolbarImageCollageRearrange'
import { ToolbarImageDynamic } from './components/ToolbarImageDynamic'
import { ToolbarImageUpload } from './components/ToolbarImageUpload'
import { ToolbarOverlayImage } from './components/ToolbarOverlayImage'
import { ToolbarOverlayText } from './components/ToolbarOverlayText'
import { ToolbarSticker } from './components/ToolbarSticker'
import { ToolbarTextPlaceholder } from './components/ToolbarTextPlaceholder'
import { ToolbarTextStyled } from './components/ToolbarTextStyled'
import { ToolbarVideo } from './components/ToolbarVideo'
import { ToolbarLayoutLeft, ToolbarLayoutTop } from './layout'
import { ToolbarAudio } from './components/ToolbarAudio'

const useLayerToolbarContent = (orientation: ToolbarOrientation): ReactNode => {
  const activeElementId = useMainView('edit', view => view.activeElementId)
  const ui = useUI()
  const deselectElement = useAction('deselectElement')
  const focusOn = useFocusOn()

  const handleDone = useCallback(() => {
    deselectElement()

    /* istanbul ignore next: unreachable */
    if (!activeElementId) {
      return
    }

    focusOn(FOCUS_ID_ELEMENT(activeElementId))
  }, [activeElementId, deselectElement, focusOn])

  useThresholdClickOn(document.body, handleDone)

  if (!ui) {
    return null
  }

  switch (ui.type) {
    case 'default': {
      return <ToolbarDefault orientation={orientation} ui={ui} />
    }
    case 'image-upload': {
      return (
        <ToolbarImageUpload
          onDone={handleDone}
          orientation={orientation}
          ui={ui}
        />
      )
    }
    case 'image-collage': {
      return (
        <ToolbarImageCollage
          onDone={handleDone}
          orientation={orientation}
          ui={ui}
        />
      )
    }
    case 'image-collage-cell': {
      return (
        <ToolbarImageCollageCell
          onDone={handleDone}
          orientation={orientation}
          ui={ui}
        />
      )
    }
    case 'image-collage-rearrange': {
      return (
        <ToolbarImageCollageRearrange
          onDone={handleDone}
          orientation={orientation}
          ui={ui}
        />
      )
    }
    case 'image-dynamic': {
      return (
        <ToolbarImageDynamic
          onDone={handleDone}
          orientation={orientation}
          ui={ui}
        />
      )
    }
    case 'text-styled': {
      return (
        <ToolbarTextStyled
          onDone={handleDone}
          orientation={orientation}
          ui={ui}
        />
      )
    }
    case 'text-placeholder': {
      return (
        <ToolbarTextPlaceholder
          onDone={handleDone}
          orientation={orientation}
          ui={ui}
        />
      )
    }
    case 'sticker': {
      return (
        <ToolbarSticker onDone={handleDone} orientation={orientation} ui={ui} />
      )
    }
    case 'overlay-image': {
      return (
        <ToolbarOverlayImage
          onDone={handleDone}
          orientation={orientation}
          ui={ui}
        />
      )
    }
    case 'overlay-text': {
      return (
        <ToolbarOverlayText
          onDone={handleDone}
          orientation={orientation}
          ui={ui}
        />
      )
    }
    case 'video': {
      return (
        <ToolbarVideo onDone={handleDone} orientation={orientation} ui={ui} />
      )
    }
    case 'audio': {
      return (
        <ToolbarAudio onDone={handleDone} orientation={orientation} ui={ui} />
      )
    }
  }
}

export const LayerToolbar: FC = () => {
  const { screenBounds, screenFocus, ui } = useCamera()
  const direction = !ui ? 'top' : ui.direction
  const orientation = direction === 'top' ? 'horizontal' : 'vertical'

  const content = useLayerToolbarContent(orientation)

  if (!content) {
    return null
  }

  const ToolbarContainer =
    direction === 'top' ? ToolbarLayoutTop : ToolbarLayoutLeft

  return (
    <ToolbarContainer screenBounds={screenBounds} screenFocus={screenFocus}>
      {content}
    </ToolbarContainer>
  )
}
