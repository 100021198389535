import { Design } from '@moonpig/web-personalise-editor-types'
import { InvalidElementKind, isElementValid } from '../../utils/isElementValid'

type MissingRequiredResult = {
  firstInvalidElement: {
    kind: InvalidElementKind
    sceneId: string
  } | null
}

export const validateMissingRequired = (
  design: Design,
): MissingRequiredResult => {
  // eslint-disable-next-line no-restricted-syntax
  for (const sceneId of design.sceneIds) {
    const scene = design.sceneById[sceneId]
    // eslint-disable-next-line no-restricted-syntax
    for (const elementId of scene.elementIds) {
      const element = scene.elementById[elementId]
      const result = isElementValid(element)

      if (!result.valid) {
        return {
          firstInvalidElement: {
            kind: result.kind,
            sceneId,
          },
        }
      }
    }
  }

  return { firstInvalidElement: null }
}
