import { DesignElement } from '@moonpig/web-personalise-editor-types'

export type InvalidElementKind = 'image' | 'collage' | 'text'

type ElementValidResult =
  | {
      valid: true
    }
  | {
      valid: false
      kind: InvalidElementKind
    }

export const isElementValid = (element: DesignElement): ElementValidResult => {
  switch (element.type) {
    case 'image-upload': {
      if (
        element.required &&
        !element.customisations.sourceImage &&
        !element.customisations.editedImage
      ) {
        return { valid: false, kind: 'image' }
      }
      break
    }
    case 'image-collage': {
      if (!element.customisations.grid && !element.customisations.editedImage) {
        return { valid: false, kind: 'collage' }
      }
      break
    }
    case 'text-plain': {
      if (
        (!element.editableText.allowBlankText &&
          !element.customisations.text) ||
        !element.valid
      ) {
        return {
          valid: false,
          kind: 'text',
        }
      }
      break
    }
    case 'text-placeholder':
    case 'text-styled': {
      return { valid: element.valid, kind: 'text' }
    }
  }

  return { valid: true }
}
