import { updateDesignSceneElements } from '../../../utils/updateDesignSceneElements'
import { ActionCreator } from '../../types'
import { storeUpdateDesign } from '../../updaters/design'
import { moveElementToBack } from './moveElementToBack'
import { moveElementToFront } from './moveElementToFront'

export const createReorderElement: ActionCreator<'reorderElement'> =
  ({ set, t }) =>
  ({ elementRef, direction }) => {
    set(storeInitial => {
      let store = storeInitial

      store = storeUpdateDesign(
        store,
        { changeReason: 'elements-changed', t },
        currentDesign => {
          return updateDesignSceneElements(
            currentDesign,
            elementRef.sceneId,
            design => {
              switch (direction) {
                case 'to-back': {
                  return moveElementToBack(elementRef, design)
                }
                case 'to-front': {
                  return moveElementToFront(elementRef, design)
                }
              }
            },
          )
        },
      )

      return store
    })
  }
