import { DesignElement } from '@moonpig/web-personalise-editor-types'
import { assert } from '../../../utils/assert'
import { ActionCreator, UI } from '../../types'

const buildUI = (currentUI: UI | null, element: DesignElement): UI | null => {
  switch (element.type) {
    case 'image-upload':
      return {
        type: 'image-upload',
        elementRef: element,
        selectedMenuItem: element.customisations.sourceImage
          ? null
          : 'photo-library',
      }
    case 'image-dynamic':
      return {
        type: 'image-dynamic',
        elementRef: element,
        selectedMenuItem: 'text',
      }
    case 'image-collage':
      return {
        type: 'image-collage',
        elementRef: element,
        selectedMenuItem: element.customisations.grid ? null : 'photo-library',
      }
    case 'text-styled':
      return {
        type: 'text-styled',
        elementRef: element,
        selectedMenuItem: 'keyboard',
      }
    case 'text-placeholder':
      return {
        type: 'text-placeholder',
        elementRef: element,
        selectedMenuItem: 'text',
      }
    case 'sticker':
      return {
        type: 'sticker',
        elementRef: element,
        selectedMenuItem:
          currentUI && currentUI.type === 'sticker'
            ? currentUI.selectedMenuItem
            : null,
        sceneId: element.sceneId,
      }
    case 'overlay-image': {
      return {
        type: 'overlay-image',
        sceneId: element.sceneId,
        elementRef: {
          type: 'overlay-image',
          sceneId: element.sceneId,
          id: element.id,
        },
        selectedMenuItem:
          currentUI && currentUI.type === 'overlay-image'
            ? currentUI.selectedMenuItem
            : null,
      }
    }
    case 'overlay-text':
      return {
        type: 'overlay-text',
        elementRef: element,
        selectedMenuItem:
          element.customisations.text.length === 0 ? 'keyboard' : null,
      }
    case 'media':
      switch (element.kind) {
        case 'video':
          return {
            type: 'video',
            elementRef: element,
            selectedMenuItem: null,
          }
        case 'audio':
          return {
            type: 'audio',
            elementRef: element,
            selectedMenuItem: null,
          }
      }
  }

  /* istanbul ignore next: other element types not currently selectable */
  return null
}

export const createSelectElement: ActionCreator<'selectElement'> =
  ({ set }) =>
  async (sceneId, elementId) => {
    set(({ view }) => {
      assert(view.type === 'main' && view.view.type === 'edit')

      const element = view.design.sceneById[sceneId].elementById[elementId]
      const ui = buildUI(view.view.ui, element)

      return {
        view: {
          ...view,
          selectedSceneId: sceneId,
          view: { ...view.view, activeElementId: elementId, ui },
        },
      }
    })
  }
