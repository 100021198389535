import { useState } from 'react'
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'

export const useMatchMedia = (mq: string, initialMatch = false) => {
  const [match, setMatch] = useState(initialMatch)

  useIsomorphicLayoutEffect(() => {
    if (!('matchMedia' in window)) return

    const mql = window.matchMedia(mq)

    const update = () => {
      setMatch(mql.matches)
    }
    update()

    if ('addEventListener' in mql) {
      mql.addEventListener('change', update)
    }

    return () => {
      if ('removeEventListener' in mql) {
        mql.removeEventListener('change', update)
      }
    }
  }, [mq])

  return match
}
