import { Box, TertiaryButton } from '@moonpig/launchpad-components'
import { TextColor, TextColorPicker } from '@moonpig/web-personalise-components'
import {
  ById,
  DesignElementOverlayText,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useMemo } from 'react'
import { useAction } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { useElementRef } from '../../../../../../selectors'

type ElementTextColorPickerProps = {
  elementRef: DesignElementRef<DesignElementOverlayText>
  availableColorIds: string[]
  availableColorById: ById<TextColor>
}

export const ElementTextColorPicker: FC<ElementTextColorPickerProps> = ({
  elementRef,
  availableColorIds,
  availableColorById,
}) => {
  const updateStyledElementStyles = useAction('updateStyledElementStyles')
  const element = useElementRef(elementRef)
  const updateElement = useAction('updateElement')
  const t = useEditorLocaleText()

  const handleSelect = useCallback(
    (colorId: string) => {
      updateElement({
        ...element,
        customisations: { ...element.customisations, colorId },
      })
    },
    [element, updateElement],
  )

  const colours: TextColor[] = useMemo(
    () =>
      availableColorIds.map(id => {
        const { name, value } = availableColorById[id]

        return {
          id,
          name,
          value,
        }
      }),
    [availableColorIds, availableColorById],
  )

  const handleUpdateAllStyles = useCallback(() => {
    updateStyledElementStyles({
      colorId: element.customisations.colorId,
    })
  }, [element, updateStyledElementStyles])

  return (
    <>
      <Box p={4}>
        <TertiaryButton onClick={handleUpdateAllStyles} inline>
          {t('toolbar_styled_text_apply_colour_all')}
        </TertiaryButton>
      </Box>
      <TextColorPicker
        colours={colours}
        onSelect={handleSelect}
        selectedColourId={element.customisations.colorId}
      />
    </>
  )
}
