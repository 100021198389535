import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  add_text: {
    'en-GB': 'Add Text',
    'nl-NL': 'Tekst toevoegen',
  },
}

export const useLocaleText = createLocaleTextHook({ dictionary })
