import { Design, Services } from '@moonpig/web-personalise-editor-types'

const extractStickers = (
  design: Design,
): { imageUrls: Set<string>; stickerIds: Set<string> } => {
  const imageUrls = new Set<string>()
  const stickerIds = new Set<string>()

  design.sceneIds.forEach(sceneId => {
    const scene = design.sceneById[sceneId]
    scene.elementIds.forEach(elementId => {
      const element = scene.elementById[elementId]

      switch (element.type) {
        case 'sticker': {
          stickerIds.add(element.sticker.id)
          imageUrls.add(element.sticker.url)
          break
        }
      }
    })
  })

  return { imageUrls, stickerIds }
}

type LoadedImage = {
  imageUrl: string
  imageElement: HTMLImageElement
}

export const loadDesignStickers = async ({
  services,
  design,
}: {
  services: Services
  design: Design
}): Promise<{ loadedImages: LoadedImage[]; stickerIds: Set<string> }> => {
  const { imageUrls, stickerIds } = extractStickers(design)

  const loadedImages = await Promise.all(
    Array.from(imageUrls.values()).map(async imageUrl => {
      const imageElement = await services.loadImage(imageUrl)
      return { imageUrl, imageElement }
    }),
  )

  return { loadedImages, stickerIds }
}
