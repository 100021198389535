import { assert } from '../../../utils/assert'
import { ActionCreator } from '../../types'

export const createDismissNotification: ActionCreator<'dismissNotification'> =
  ({ set }) =>
  async id => {
    set(({ view }) => {
      assert(view.type === 'main')

      return {
        view: {
          ...view,
          notifications: view.notifications.filter(
            notification => notification.id !== id,
          ),
        },
      }
    })
  }
