import { AspectRatio } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'

const StyledImage = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
`

export const PhotoImage: FC<{ imageUrl: string }> = ({ imageUrl }) => {
  return (
    <AspectRatio ratio={1}>
      <StyledImage
        data-testid="mp-ed-photo-button-image"
        style={{
          backgroundImage: `url('${imageUrl}')`,
        }}
      />
    </AspectRatio>
  )
}
