import {
  IconInformation,
  IconInformationError,
  IconInformationWarning,
} from '@moonpig/launchpad-assets'
import { Alert, Box, Flex, Heading, Text } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { useConsumeTouch } from '@moonpig/web-personalise-components'
import { DesignElementRef } from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback } from 'react'
import { DESKTOP_BP, HEADER_HEIGHT_DESKTOP } from '../../../../constants'
import {
  Notification as NotificationType,
  useAction,
  useView,
} from '../../../../store'
import { useEditorLocaleText } from '../../../../text-localisation'

type AlertVariant = 'success' | 'error' | 'warning' | 'info'

const StyledContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  ${s({
    top: { xs: 0, [DESKTOP_BP]: -HEADER_HEIGHT_DESKTOP },
  })}
  right: 0;
  left: 0;
  z-index: 1;
`

const StyledAlert = styled(Alert)`
  pointer-events: all;
`

type Action = {
  title: string
  onClick: () => void
}

const Notification: FC<{ notification: NotificationType }> = ({
  notification,
}) => {
  const bindConsumeTouch = useConsumeTouch()
  const t = useEditorLocaleText()
  const dismissNotification = useAction('dismissNotification')
  const resetDesign = useAction('resetDesign')
  const setUI = useAction('setUI')
  const edit = useAction('edit')
  const selectElement = useAction('selectElement')
  const { id, content } = notification
  const trackEvent = useAction('trackEvent')
  const productKey = useView('main', view => view.design.productKey)

  const handleDismiss = useCallback(() => {
    dismissNotification(id)
  }, [dismissNotification, id])

  const handleResetDesign = useCallback(() => {
    resetDesign()
    handleDismiss()
  }, [resetDesign, handleDismiss])

  const handleShowDefaultTextElement = useCallback(
    (elementRef: DesignElementRef) => {
      handleDismiss()
      edit()
      selectElement(elementRef.sceneId, elementRef.id)
    },
    [handleDismiss, edit, selectElement],
  )

  const handleReplaceAudioWithVideo = useCallback(() => {
    setUI({
      type: 'default',
      selectedMenuItem: 'video-onboarding',
    })

    trackEvent({
      type: 'SELECT_CONTENT',
      kind: 'VIDEO_REPLACE_AUDIO',
      productKey,
      label: 'notification',
    })

    handleDismiss()
  }, [setUI, trackEvent, productKey, handleDismiss])

  const handleReplaceVideoWithAudio = useCallback(() => {
    setUI({
      type: 'default',
      selectedMenuItem: 'audio-onboarding',
    })

    trackEvent({
      type: 'SELECT_CONTENT',
      kind: 'AUDIO_REPLACE_VIDEO',
      productKey,
      label: 'notification',
    })

    handleDismiss()
  }, [setUI, trackEvent, productKey, handleDismiss])

  let variant: AlertVariant
  let title: string
  let text: string
  let actions: undefined | [Action] | [Action, Action]
  let icon = IconInformation

  switch (content.type) {
    case 'continue-draft': {
      variant = 'info'
      title = t('continue_draft_title')
      text = t('continue_draft_text')
      actions = [
        {
          title: t('continue_draft_discard_button'),
          onClick: handleResetDesign,
        },
        {
          title: t('continue_draft_continue_button'),
          onClick: handleDismiss,
        },
      ]
      break
    }
    case 'text-filtered': {
      variant = 'warning'
      icon = IconInformationWarning
      if (content.hasEmojis) {
        title = t('filtered_notification_warning_emojis_title')
        text = t('filtered_notification_warning_emojis_text')
      } else {
        title = t('filtered_notification_warning_characters_title')
        text = t('filtered_notification_warning_characters_text')
      }
      break
    }
    case 'text-truncated': {
      variant = 'warning'
      icon = IconInformationWarning
      title = t('notification_truncated_text_title')
      text = t('notification_truncated_text_message')
      break
    }
    case 'default-text-found': {
      variant = 'warning'
      icon = IconInformationWarning
      title = t('unchanged_default_text_title')
      text = t(content.textKey)
      actions = [
        {
          title: t('unchanged_default_text_show_me_button'),
          onClick: () => {
            handleShowDefaultTextElement(content.firstDefaultElement)
          },
        },
        {
          title: t('unchanged_default_text_continue_button'),
          onClick: handleDismiss,
        },
      ]
      break
    }
    case 'photo-missing': {
      variant = 'warning'
      icon = IconInformationWarning
      switch (content.elementType) {
        case 'image-upload': {
          title = t('photo_missing_title')
          text = t('photo_missing_text')
          break
        }
        case 'image-collage': {
          title = t('collage_photo_missing_title')
          text = t('collage_photo_missing_text')
          break
        }
      }
      break
    }
    case 'missing-required': {
      variant = 'warning'
      icon = IconInformationWarning
      switch (content.kind) {
        case 'image': {
          title = t('missing_required_image_title')
          text = t('missing_required_image_text')
          break
        }
        case 'collage': {
          title = t('missing_required_collage_title')
          text = t('missing_required_collage_text')
          break
        }
        case 'text': {
          title = t('missing_required_text_input_title')
          text = t('missing_required_text_input_text')
          break
        }
      }
      break
    }
    case 'stickers-unavailable': {
      variant = 'warning'
      title = t('stickers_unavailable_title')
      text = t('stickers_unavailable_text')
      icon = IconInformationWarning
      break
    }
    case 'card-multibuy': {
      variant = 'info'
      title = t('notification_card_multibuy_title')
      text = t('notification_card_multibuy_text', content.quantity)
      break
    }
    case 'video-file-too-large': {
      variant = 'error'
      title = t('notification_file_too_large_title')
      text = t(
        'notification_video_file_too_large_text',
        t('file_size', content.maxSizeBytes),
      )
      break
    }
    case 'audio-file-too-large': {
      variant = 'error'
      title = t('notification_file_too_large_title')
      text = t('notification_audio_file_too_large_text')
      break
    }
    case 'microphone-permissions-error': {
      variant = 'warning'
      title = t('notification_microphone_permissions_error_title')
      text = t('notification_microphone_permissions_error_text')
      icon = IconInformationWarning
      break
    }
    case 'video-upload-error': {
      variant = 'error'
      title = t('notification_video_upload_error_title')
      text = t('notification_video_upload_error_text')
      icon = IconInformationError
      break
    }
    case 'audio-upload-error': {
      variant = 'error'
      title = t('notification_audio_upload_error_title')
      text = t('notification_audio_upload_error_text')
      icon = IconInformationError
      break
    }
    case 'audio-recording-error': {
      variant = 'error'
      title = t('notification_audio_recording_error_title')
      text = t('notification_audio_recording_error_text')
      icon = IconInformationError
      break
    }
    case 'maximum_overlay_text_added': {
      variant = 'warning'
      text = t('notification_maximum_overlay_text_added_text')
      title = t('notification_maximum_overlay_text_added_title')
      actions = [
        {
          title: t('unchanged_default_text_continue_button'),
          onClick: handleDismiss,
        },
      ]

      break
    }
    case 'maximum_overlay_image_added': {
      variant = 'warning'
      text = t('notification_maximum_photos_added_text')
      title = t('notification_maximum_photos_added_title')
      actions = [
        {
          title: t('unchanged_default_text_continue_button'),
          onClick: handleDismiss,
        },
      ]

      break
    }
    case 'maximum_stickers_added': {
      variant = 'warning'
      text = t('notification_maximum_stickers_added_text')
      title = t('notification_maximum_stickers_added_title')
      actions = [
        {
          title: t('unchanged_default_text_continue_button'),
          onClick: handleDismiss,
        },
      ]

      break
    }
    case 'experience-gift-first': {
      variant = 'info'
      text = t('notification_voucher_card_text')
      title = t('notification_voucher_card_title')

      break
    }
    case 'profanity-found': {
      variant = 'warning'
      title = t('notification_profanity_blocked_title')
      text = t('notification_profanity_blocked_text')

      break
    }
    case 'smart-text-limit-exceeded': {
      variant = 'warning'
      title = t('smart_text_limit_reached_title')
      text = t('smart_text_limit_error')
      icon = IconInformationWarning

      break
    }
    case 'audio-already-exists': {
      variant = 'warning'
      title = t('media_already_exists_warning_title')
      text = t('audio_already_exists_warning_description')

      actions = [
        {
          title: t('audio_already_exists_warning_continue_button'),
          onClick: handleReplaceAudioWithVideo,
        },
        {
          title: t('cancel'),
          onClick: handleDismiss,
        },
      ]
      break
    }
    case 'video-already-exists': {
      variant = 'warning'
      title = t('media_already_exists_warning_title')
      text = t('video_already_exists_warning_description')

      actions = [
        {
          title: t('video_already_exists_warning_continue_button'),
          onClick: handleReplaceVideoWithAudio,
        },
        {
          title: t('cancel'),
          onClick: handleDismiss,
        },
      ]
      break
    }
  }

  return (
    <Box px={{ xs: 4, [DESKTOP_BP]: 6 }} pt={{ xs: 4, [DESKTOP_BP]: 6 }}>
      <div {...bindConsumeTouch()}>
        <StyledAlert
          icon={icon}
          variant={variant}
          onClickClose={actions ? undefined : handleDismiss}
          actions={actions}
        >
          <Flex flexDirection="column">
            <Heading level="h4" mb={0}>
              {title}
            </Heading>
            <Text>{text}</Text>
          </Flex>
        </StyledAlert>
      </div>
    </Box>
  )
}

export const Notifications: FC = () => {
  const notifications = useView('main', view => view.notifications)

  return notifications.length > 0 ? (
    <StyledContainer>
      <Box maxWidth="640px" width="100%" alignSelf="center">
        {notifications.map(notification => (
          <Notification key={notification.id} notification={notification} />
        ))}
      </Box>
    </StyledContainer>
  ) : null
}
