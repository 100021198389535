import { RegisterResources } from '@moonpig/renderer-react'
import { logger } from '@moonpig/web-core-monitoring'
import {
  Design,
  DesignSticker,
  EditorConfig,
  EditorFeatures,
  LoadedDesignState,
  Photo,
  Services,
  convertDesignToStoredDesign,
} from '@moonpig/web-personalise-editor-types'
import { buildLayout } from '../../../layout'
import { assert } from '../../../utils/assert'
import { performance } from '../../../utils/performance'
import { addNotification } from '../../common/addNotification'
import { createEditorEventState } from '../../common/createEditorEventState'
import { applyDefaultLayouts } from '../../common/layouts'
import { loadMediaImages } from '../../common/loadMediaImages'
import { loadPhotoImages } from '../../common/loadPhotoImages'
import { loadAllStickers } from '../../common/loadSticker'
import { removeMissingSourceImages } from '../../common/removeMissingSourceImages'
import {
  getDynamicImageElementsToUpdate,
  renderDynamicImages,
} from '../../common/renderDynamicImages'
import { addStarterMessageToLayout } from '../../common/starterMessage'
import {
  ActionContext,
  ActionCreator,
  Notification,
  Photos,
  RemoteImageStore,
  ViewMainView,
} from '../../types'
import { buildOnboardingEntries } from './buildOnboardingEntries'
import { checkFeatureSupport } from './checkFeatureSupport'
import { createSceneTextById } from './createSceneTextById'
import { loadDesignImages } from './loadDesignImages'
import { loadDesignStickers } from './loadDesignStickers'
import { extractFonts, loadInitialFonts, loadRemainingFonts } from './loadFonts'
import { loadStaticImages } from './loadStaticImages'

const buildNotifications = (
  design: Design,
  designState: LoadedDesignState,
): Notification[] => {
  let notifications: Notification[] = []
  const { quantity, continueDraft, giftFirstCard } = designState

  if (continueDraft) {
    notifications = addNotification(notifications, {
      type: 'continue-draft',
      layout: design.layout,
    })
  }

  const isMultiBuy = quantity && quantity > 1

  if (isMultiBuy) {
    notifications = addNotification(notifications, {
      type: 'card-multibuy',
      quantity,
    })
  }

  if (giftFirstCard) {
    notifications = addNotification(notifications, {
      type: 'experience-gift-first',
    })
  }

  return notifications
}

const loadDynamicImages = ({
  services,
  registerResources,
  design,
}: {
  services: Services
  registerResources: RegisterResources
  design: Design
}): Promise<RemoteImageStore['dynamicImageById']> => {
  const dynamicImageElementsToUpdate = getDynamicImageElementsToUpdate({
    design,
    dynamicImageById: {},
  })

  return renderDynamicImages({
    services,
    registerResources,
    dynamicImageElementsToUpdate,
  })
}

const loadStickers = async ({
  services,
  design,
}: {
  services: Services
  design: Design
}): Promise<DesignSticker[]> => {
  const stickers: DesignSticker[] = []
  const stickerSetIds = design.availableStickerSets.map(({ id }) => id)

  const firstPage = await services.loadStickers({
    stickerSetIds,
    afterCursor: null,
  })

  stickers.push(...firstPage.stickers)

  if (firstPage.nextCursor) {
    const secondPage = await services.loadStickers({
      stickerSetIds,
      afterCursor: firstPage.nextCursor,
    })

    stickers.push(...secondPage.stickers)
  }

  return stickers
}

const loadMainView = async ({
  context,
  config,
  design,
  designState,
  editedImageById,
  initialMediaImageById,
  loadedPhotos,
  features,
  shareParam,
  shareName,
}: {
  context: ActionContext
  config: EditorConfig
  design: Design
  designState: LoadedDesignState
  editedImageById: { [id in string]?: string }
  initialMediaImageById: { [id in string]?: string }
  loadedPhotos: Photo[]
  features: EditorFeatures
  shareParam: string | null
  shareName: string
}) => {
  const { services, registerResources, set, t } = context

  const stickers = await loadStickers({ services, design })

  const designId = shareParam ?? design.id

  const collaborateSession = await services.collaborate({
    initialName: shareName,
    designId,
    initialDesign: design,
    stickers,
    onDesignUpdated: updatedDesign => {
      set(store => {
        if (store.view.type !== 'main' || store.view.view.type !== 'edit') {
          return store
        }

        loadAllStickers(context, updatedDesign)

        let editView = store.view.view

        if (editView.activeElementId) {
          const selectedScene =
            updatedDesign.sceneById[store.view.selectedSceneId]
          const selectedElement =
            selectedScene.elementById[editView.activeElementId]
          if (!selectedElement) {
            editView = { ...editView, activeElementId: null, ui: null }
          }
        }

        return {
          view: {
            ...store.view,
            design: updatedDesign,
            view: editView,
          },
        }
      })
    },
    onCursorUpdated: (peerId, position) => {
      set(store => {
        if (store.view.type !== 'main' || !store.view.collaborateSession) {
          return store
        }

        return {
          view: {
            ...store.view,
            collaborateSession: {
              ...store.view.collaborateSession,
              cursors: {
                ...store.view.collaborateSession.cursors,
                [peerId]: position,
              },
            },
          },
        }
      })
    },
    onPeersUpdated: peers => {
      set(store => {
        if (store.view.type !== 'main' || !store.view.collaborateSession) {
          return store
        }

        return {
          view: {
            ...store.view,
            collaborateSession: { ...store.view.collaborateSession, peers },
          },
        }
      })
    },
  })

  // eslint-disable-next-line no-param-reassign
  design = collaborateSession.initialDesign

  const { fontUrlMap, initialFontIds } = extractFonts(design)
  const userState = services.loadUserState()

  const [
    { fontById, loadedFonts },
    loadedDesignImages,
    loadedDesignStickers,
    loadedPhotoImages,
    loadedStaticImages,
    customisationConfig,
    dynamicImageById,
    mediaImageById,
  ] = await Promise.all([
    loadInitialFonts({
      services,
      fontUrlMap,
      initialFontIds,
    }),
    loadDesignImages({ services, design, editedImageById }),
    loadDesignStickers({ services, design }),
    loadPhotoImages({ services, photos: loadedPhotos }),
    loadStaticImages({
      services,
      imageUrls: [
        config.videoMediaDemo.imageUrl,
        config.audioMediaDemo.imageUrl,
        config.handwritingOnboardingImageUrl,
      ],
    }),
    services.loadCustomisationConfig(),
    loadDynamicImages({ services, registerResources, design }),
    loadMediaImages({
      services,
      registerResources,
      design,
      initialMediaImageById,
    }),
  ])

  loadedFonts.forEach(({ fontUrl, fontFamily, fontMetrics }) => {
    registerResources.registerFont({ fontUrl, fontFamily, fontMetrics })
  })

  const loadedImages = [
    ...loadedDesignImages,
    ...loadedDesignStickers.loadedImages,
    ...loadedPhotoImages,
    ...loadedStaticImages,
  ]

  loadedImages.forEach(({ imageUrl, imageElement }) => {
    registerResources.registerImage({ imageUrl, imageElement })
  })

  const selectedSceneId = design.sceneIds[0]

  const photos = loadedPhotos.reduce<Photos>(
    (acc, photo) => {
      acc.photoIds.push(photo.id)
      acc.photoById[photo.id] = {
        type: 'loaded',
        id: photo.id,
        photo,
      }
      return acc
    },
    { photoIds: [], photoById: {} },
  )

  const featureSupport = checkFeatureSupport(design)

  const normalisedDesign = removeMissingSourceImages({
    design,
    photos,
    collageGrids: customisationConfig.availableCollageGrids,
  })

  let mainView: ViewMainView

  const onboardingEntries = features.enableOnboarding
    ? buildOnboardingEntries(userState, features, services)
    : []

  if (featureSupport !== 'supported') {
    mainView = {
      type: 'error-unsupported-features',
      resettable: featureSupport === 'resettable',
    }
  } else {
    mainView = {
      type: 'edit',
      activeElementId: null,
      ui: null,
      modal:
        onboardingEntries.length > 0
          ? {
              type: 'onboarding',
              entries: onboardingEntries,
            }
          : null,
      highlightInvalidElements: false,
    }
  }

  set(() => ({
    remoteImageStore: {
      editedImageById,
      dynamicImageById,
      mediaImageById,
    },
    view: {
      type: 'main',
      layout: buildLayout(
        design.layout,
        design.sceneIds.map(sceneId => design.sceneById[sceneId]),
      ),
      sceneTextById: createSceneTextById(design, t),
      smartTextByElementId: {},
      smartTextPrompt: '',
      smartTextConfig: customisationConfig.smartTextConfig,
      design: normalisedDesign,
      designMeta: {
        changed: false,
        history: {
          entryIndex: 0,
          entries: [
            {
              selectedSceneId,
              design: convertDesignToStoredDesign(normalisedDesign),
            },
          ],
        },
      },
      designState,
      fontById,
      selectedSceneId,
      selectedLayoutId: null,
      photos,
      notifications: buildNotifications(design, designState),
      canShowDefaultTextNotification: true,
      stickers,
      onboardingViewed: {
        emoji: userState.emojiOnboardingViewed,
        text: userState.textOnboardingViewed,
        layouts: userState.layoutsOnboardingViewed,
        photosAndStickers: userState.photosAndStickersOnboardingViewed,
        video: userState.videoOnboardingViewed,
        smartText: userState.smartTextOnboardingViewed,
        audio: userState.audioOnboardingViewed,
        carousel: false,
      },
      loadedStickerIds: loadedDesignStickers.stickerIds,
      loadingStickerIds: new Set(),
      customisationConfig,
      shareName,
      sharing:
        shareParam || localStorage.getItem('mp-ed-collab-sharing') === 'true'
          ? { link: `${window.location.origin}?share=${designId}` }
          : null,
      collaborateSession: {
        updateName: collaborateSession.updateName,
        updateDesign: collaborateSession.updateDesign,
        updateCursor: collaborateSession.updateCursor,
        peers: collaborateSession.peers,
        cursors: {},
      },
      view: mainView,
    },
  }))

  performance.mark('editor-loaded')
  performance.measureEnd('editor-loading')

  services.trackEvent({
    type: 'LOADED',
    state: createEditorEventState({
      design,
      designState,
      photos,
      selectedSceneId,
    }),
  })

  await loadRemainingFonts({
    services,
    fontUrlMap,
    fontById,
    onLoad: ({ id, fontUrl, fontMetrics, fontFamily }) => {
      set(store => {
        assert(store.view.type === 'main')

        return {
          view: {
            ...store.view,
            fontById: {
              ...store.view.fontById,
              [id]: { type: 'loaded', fontFamily },
            },
          },
        }
      })

      registerResources.registerFont({ fontUrl, fontFamily, fontMetrics })
    },
    onError: ({ id }) => {
      set(store => {
        assert(store.view.type === 'main')

        return {
          view: {
            ...store.view,
            fontById: {
              ...store.view.fontById,
              [id]: { ...store.view.fontById[id], type: 'error' },
            },
          },
        }
      })
    },
  })
}

export const createLoad: ActionCreator<'load'> = context => async () => {
  performance.measureStart('editor-loading')

  const { services, get, set, t } = context
  const { config, features } = get()

  const shareParam = new URL(window.location.href).searchParams.get('share')
  const shareName = localStorage.getItem('mp-ed-collab-name') ?? ''

  if (shareParam && !shareName) {
    set(() => ({
      view: {
        type: 'share-join',
      },
    }))

    return
  }

  set(() => ({
    view: {
      type: 'loading',
    },
  }))

  const [result, loadedPhotos] = await Promise.all([
    services.loadDesign(),
    services.loadPhotos(),
  ])

  switch (result.type) {
    case 'success': {
      try {
        await loadMainView({
          context,
          config,
          design: features.enableLayouts
            ? addStarterMessageToLayout(
                applyDefaultLayouts(result.design),
                result.facets,
                t,
              )
            : result.design,
          designState: result.state,
          editedImageById: result.editedImageById,
          initialMediaImageById: {
            ...result.mediaImageById,
            'video-media-demo': config.videoMediaDemo.imageUrl,
            'audio-media-demo': config.audioMediaDemo.imageUrl,
          },
          loadedPhotos,
          features,
          shareParam,
          shareName,
        })
      } catch (error) {
        logger.fixToday('Failed to load main view', {}, error)

        set(() => ({
          view: {
            type: 'error-unexpected',
          },
        }))

        services.trackEvent({ type: 'ERROR', kind: 'EDITOR_LOAD' })
      }
      break
    }
    case 'product-not-found': {
      set(() => ({
        view: {
          type: 'error-product-not-found',
          productKey: result.productKey,
        },
      }))

      services.trackEvent({ type: 'ERROR', kind: 'EDITOR_LOAD' })

      break
    }
    case 'design-not-found': {
      set(() => ({
        view: {
          type: 'error-design-not-found',
          designId: result.designId,
        },
      }))

      services.trackEvent({ type: 'ERROR', kind: 'EDITOR_LOAD' })

      break
    }
    case 'design-requires-login': {
      set(() => ({
        view: {
          type: 'loading-login',
        },
      }))

      break
    }
    case 'design-not-editable': {
      set(() => ({
        view: {
          type: 'error-design-not-editable',
          designId: result.designId,
        },
      }))

      services.trackEvent({ type: 'ERROR', kind: 'EDITOR_LOAD' })

      break
    }
    default: {
      set(() => ({
        view: {
          type: 'error-unexpected',
        },
      }))

      services.trackEvent({ type: 'ERROR', kind: 'EDITOR_LOAD' })

      break
    }
  }
}
