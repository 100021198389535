import React, { FC, useCallback, useEffect } from 'react'
import { Box, Flex, SecondaryButton, Text } from '@moonpig/launchpad-components'
import {
  AudioTimeline,
  ActionButton,
  IconMicrophone,
  IconStop,
} from '@moonpig/web-personalise-components'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { useAction, useStore, useView } from '../../../../../../../../store'

const CONTENT_HEIGHT_PX = 244

const useAudioRecording = () => {
  return useStore(({ audioRecording }) => {
    return audioRecording
  })
}

export const AudioInput: FC = () => {
  const t = useEditorLocaleText()
  const audioRecording = useAudioRecording()
  const startAudioRecording = useAction('startAudioRecording')
  const stopAudioRecording = useAction('stopAudioRecording')

  const maxAudioDuration = useView(
    'main',
    ({ customisationConfig }) =>
      /* istanbul ignore next: unreachable as maxDurationSeconds is required from audio config */
      customisationConfig.audio.maxDurationSeconds || 0,
  )

  useEffect(() => {
    if (
      audioRecording &&
      audioRecording.currentTimeSeconds >= maxAudioDuration
    ) {
      stopAudioRecording({})
    }
  }, [audioRecording, maxAudioDuration, stopAudioRecording])

  useEffect(() => {
    return () => {
      stopAudioRecording({ discardRecording: true })
    }
  }, [stopAudioRecording])

  const onStopRecordingClick = useCallback(() => {
    stopAudioRecording({})
  }, [stopAudioRecording])

  if (!audioRecording) {
    return (
      <Box height={`${CONTENT_HEIGHT_PX}px`}>
        <Text as="p" mb={8}>
          {t('toolbar_menu_audio_start_recording_label')}
        </Text>
        <Flex justifyContent="center">
          <ActionButton
            onClick={startAudioRecording}
            icon={<IconMicrophone width={42} height={42} />}
            text={t('toolbar_menu_audio_record_label')}
          />
        </Flex>
      </Box>
    )
  }

  return (
    <Box height={`${CONTENT_HEIGHT_PX}px`}>
      <Text as="p" mb={8}>
        {t('toolbar_menu_audio_recording_label')}
      </Text>
      <AudioTimeline
        currentTimeSeconds={audioRecording.currentTimeSeconds}
        samples={audioRecording.samples}
        timePerSampleSeconds={audioRecording.timePerSampleSeconds}
      />
      <SecondaryButton
        mt={10}
        width={'100%'}
        onClick={onStopRecordingClick}
        leadingIcon={<IconStop />}
      >
        {t('toolbar_menu_stop_recording_label')}
      </SecondaryButton>
    </Box>
  )
}
