import { Photo } from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { assert } from '../../../../../../../../utils/assert'
import { useCheckIfMaxElementLimitReached } from '../../../../../../../../utils/useCheckIfMaxElementLimitReached'
import { useAction, useView } from '../../../../../../../../store'
import { PhotoLibrary } from '../../PhotoLibrary'
import { createOverlayImageElement } from './createOverlayImageElement'

type OverlayImagePhotoLibraryProps = {
  labelledById: string
  sceneId: string
}

export const OverlayImagePhotoLibrary: FC<OverlayImagePhotoLibraryProps> = ({
  labelledById,
  sceneId,
}) => {
  const [selectedPhotos, setSelectedPhotos] = useState<
    { photoId: string; elementId: string }[]
  >([])

  const selectElement = useAction('selectElement')
  const setUI = useAction('setUI')
  const addPhotos = useAction('addPhotos')
  const removePhoto = useAction('removePhoto')
  const deleteElement = useAction('deleteElement')
  const trackEvent = useAction('trackEvent')

  const addElement = useAction('addElement')
  const design = useView('main', view => view.design)
  const {
    limits: { maxImageCount },
  } = design
  const photos = useView('main', view => view.photos)
  const currentScene = design.sceneById[sceneId]
  const notify = useAction('notify')
  const { maxElementAdded, designOverlayElementCount } =
    useCheckIfMaxElementLimitReached('overlay-image', 'maxImageCount')
  useEffect(() => {
    if (maxElementAdded) {
      notify({ type: 'maximum_overlay_image_added' })
    }
  }, [maxElementAdded, notify])

  const usedPhotoIds = useMemo(() => {
    return new Set<string>(
      selectedPhotos.map(selectedPhoto => selectedPhoto.photoId),
    )
  }, [selectedPhotos])

  const photosView = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return photos.photoIds.map(id => {
      const photoState = photos.photoById[id]

      switch (photoState.type) {
        case 'loading': {
          return {
            type: 'loading' as const,
            id,
          }
        }
        case 'loaded': {
          return {
            type: 'loaded' as const,
            id,
            selected: usedPhotoIds.has(photoState.photo.id),
            imageUrl: photoState.photo.smallImage.url,
          }
        }
      }
    })
  }, [photos.photoById, photos.photoIds, usedPhotoIds])

  const selectPhoto = useCallback(
    (photo: Photo, indexOffset: number) => {
      const existingSelectedPhoto = selectedPhotos.find(
        selectedPhoto => selectedPhoto.photoId === photo.id,
      )

      if (existingSelectedPhoto) {
        deleteElement({
          type: 'overlay-image',
          sceneId,
          id: existingSelectedPhoto.elementId,
        })

        setUI({
          type: 'overlay-image',
          sceneId,
          elementRef: null,
          selectedMenuItem: 'photo-library',
        })

        setSelectedPhotos(
          selectedPhotos.filter(
            selectedPhoto => selectedPhoto !== existingSelectedPhoto,
          ),
        )

        return
      }

      const newElement = createOverlayImageElement({
        scene: currentScene,
        photo,
        numberOfSelectedPhotos: selectedPhotos.length + indexOffset,
      })

      trackEvent({
        type: 'PHOTO_ADDED',
        sceneTitle: currentScene.title,
        productKey: design.productKey,
      })

      setSelectedPhotos(currentSelectedPhotos => [
        ...currentSelectedPhotos,
        { photoId: photo.id, elementId: newElement.id },
      ])

      addElement(newElement)

      selectElement(newElement.sceneId, newElement.id)
    },
    [
      selectedPhotos,
      currentScene,
      trackEvent,
      design.productKey,
      addElement,
      selectElement,
      setUI,
      sceneId,
      deleteElement,
    ],
  )

  const handleAddPhotos = useCallback(
    (files: Blob[]) => {
      addPhotos(files, addedPhotos => {
        const maxPhotosToAdd = maxImageCount - designOverlayElementCount

        addedPhotos.slice(0, maxPhotosToAdd).forEach((addedPhoto, index) => {
          selectPhoto(addedPhoto, index)
        })
      })
    },
    [addPhotos, designOverlayElementCount, maxImageCount, selectPhoto],
  )

  const handleSelectPhoto = useCallback(
    (photoId: string) => {
      const photoState = photos.photoById[photoId]

      assert(photoState.type === 'loaded')

      selectPhoto(photoState.photo, 0)
    },
    [photos.photoById, selectPhoto],
  )

  return (
    <PhotoLibrary
      selectLimit={
        maxImageCount - designOverlayElementCount + selectedPhotos.length
      }
      multiSelect
      labelledById={labelledById}
      photos={photosView}
      onAddPhotos={handleAddPhotos}
      onSelectPhoto={handleSelectPhoto}
      onRemovePhoto={removePhoto}
    />
  )
}
