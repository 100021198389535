import { removeMissingSourceImages } from '../../common/removeMissingSourceImages'
import { removePhotos } from '../../common/removePhotos'
import { ActionCreator } from '../../types'
import { storeUpdateDesign } from '../../updaters/design'
import { storeUpdateMainView } from '../../updaters/main-view'
import { storeUpdateView } from '../../updaters/view'

export const createRemovePhoto: ActionCreator<'removePhoto'> =
  ({ services, set, t }) =>
  async id => {
    set(initialStore => {
      let store = initialStore

      store = storeUpdateView(store, 'main', ({ photos }) => {
        return { photos: removePhotos(photos, [id]) }
      })

      store = storeUpdateDesign(
        store,
        { changeReason: 'elements-changed', t },
        (design, { photos, customisationConfig }) => {
          return removeMissingSourceImages({
            design,
            photos,
            collageGrids: customisationConfig.availableCollageGrids,
            partiallyRemove: true,
          })
        },
      )

      store = storeUpdateMainView(store, 'edit', (mainView, { design }) => {
        const activeElementId = mainView.activeElementId
        let activeElementStillExists = false

        design.sceneIds.forEach(sceneId => {
          const scene = design.sceneById[sceneId]
          scene.elementIds.forEach(elementId => {
            if (elementId === activeElementId) {
              activeElementStillExists = true
            }
          })
        })

        return {
          activeElementId: activeElementStillExists ? activeElementId : null,
        }
      })

      return store
    })

    await services.removePhoto(id)
  }
