import {
  Flex,
  PrimaryButton,
  SecondaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { Modal } from '@moonpig/web-personalise-components'
import React, { FC, useCallback } from 'react'
import { useAction, useEditorFeatures } from '../../../../../../store'
import { useEditorLocaleText } from '../../../../../../text-localisation'

export const ConfirmExitModal: FC = () => {
  const t = useEditorLocaleText()
  const features = useEditorFeatures()
  const edit = useAction('edit')
  const exit = useAction('exit')

  const handleSaveAndExit = useCallback(() => {
    exit('SAVE_AND_EXIT')
  }, [exit])

  const handleDeleteAndExit = useCallback(() => {
    exit('DELETE_AND_EXIT')
  }, [exit])

  const handleExitWithoutSaving = useCallback(() => {
    exit('EXIT')
  }, [exit])

  if (features.enableAutosave) {
    return (
      <Modal
        heading={t('keep_draft_title')}
        closeLabel={t('modal_close_button')}
        onClose={edit}
      >
        <Flex flexDirection="column" width="100%">
          <Text pb={6}>{t('keep_draft_body')}</Text>
          <PrimaryButton mb={6} onClick={handleSaveAndExit}>
            {t('keep_draft_button')}
          </PrimaryButton>
          <SecondaryButton onClick={handleDeleteAndExit}>
            {t('delete_draft_button')}
          </SecondaryButton>
        </Flex>
      </Modal>
    )
  }

  return (
    <Modal
      heading={t('confirm_exit_exit_title')}
      closeLabel={t('modal_close_button')}
      onClose={edit}
    >
      <Flex flexDirection="column" width="100%">
        <PrimaryButton mb={6} onClick={handleSaveAndExit}>
          {t('confirm_exit_save_and_exit_button')}
        </PrimaryButton>
        <SecondaryButton onClick={handleExitWithoutSaving}>
          {t('confirm_exit_exit_without_saving_button')}
        </SecondaryButton>
      </Flex>
    </Modal>
  )
}
