import { Bounds } from '@moonpig/common-math'

export const calculateDimensions = (
  x: number,
  y: number,
  width: number,
  height: number,
) => {
  return {
    x,
    y,
    width,
    height,
    center: { x: x + width * 0.5, y: y + height * 0.5 },
  }
}

export const calculateBounds = (bounds: Bounds[]): Bounds => {
  let minX = Infinity
  let minY = Infinity
  let maxX = 0
  let maxY = 0
  for (let i = 0; i < bounds.length; i += 1) {
    const scene = bounds[i]
    minX = Math.min(scene.x, minX)
    minY = Math.min(scene.y, minY)
    maxX = Math.max(scene.x + scene.width, maxX)
    maxY = Math.max(scene.y + scene.height, maxY)
  }

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  }
}
