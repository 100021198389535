import React, { FC } from 'react'
import { InteractiveElementIndicator } from '../types'
import { IndicatorAddText } from './IndicatorAddText'
import { IndicatorPhoto } from './IndicatorPhoto'
import { IndicatorText } from './IndicatorText'

type IndicatorProps = {
  indicator: InteractiveElementIndicator
  scale: number
  width: number
  height: number
  invalid?: boolean
}

export const Indicator: FC<IndicatorProps> = ({
  indicator,
  scale,
  width,
  height,
  invalid,
}) => {
  switch (indicator) {
    case 'NONE': {
      return null
    }
    case 'PHOTO': {
      return (
        <IndicatorPhoto
          scale={scale}
          width={width}
          height={height}
          invalid={invalid}
        />
      )
    }
    case 'TEXT': {
      return <IndicatorText scale={scale} invalid={invalid} />
    }
    case 'ADD_TEXT': {
      return <IndicatorAddText scale={scale} width={width} height={height} />
    }
  }
}
