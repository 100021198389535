import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { useEditorLocaleText } from '../../../../../../../text-localisation'

type CharacterCountProps = {
  text: string
  maxCharacters: number | null
}

const StyledCharacterCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  user-select: none;
  font-size: 12px;
  font-weight: bold;
`

export const CharacterCount: FC<CharacterCountProps> = ({
  text,
  maxCharacters,
}) => {
  const t = useEditorLocaleText()

  if (maxCharacters === null) {
    return null
  }

  const charactersUsed = text.length
  const usedLabel =
    charactersUsed === 1
      ? t('edit_used_one_character_label')
      : t('edit_used_multiple_characters_label', charactersUsed)

  const label = t('edit_total_used_character_label', usedLabel, maxCharacters)
  const message = `${charactersUsed} / ${maxCharacters}`

  return (
    <StyledCharacterCount aria-label={label}>{message}</StyledCharacterCount>
  )
}
