import {
  Design,
  DesignElement,
  DesignScene,
} from '@moonpig/web-personalise-editor-types'
import { ActionCreator, ViewMainView } from '../../types'
import { storeUpdateDesign } from '../../updaters/design'
import { storeUpdateView } from '../../updaters/view'
import { applyLayout } from '../../common/layouts'

const resetDesignElement = (element: DesignElement): DesignElement => {
  switch (element.type) {
    case 'image-upload': {
      return {
        ...element,
        customisations: {
          pendingEditedImage: null,
          editedImage: null,
          sourceImage: null,
        },
      }
    }
    case 'image-dynamic': {
      return {
        ...element,
        customisations: {
          previousTextParts: element.customisations.textParts,
          textParts: element.textParts.map(textPart => textPart.defaultText),
        },
      }
    }
    case 'image-collage': {
      return {
        ...element,
        customisations: {
          pendingEditedImage: null,
          editedImage: null,
          grid: null,
        },
      }
    }
    case 'text-static': {
      return element
    }
    case 'text-plain': {
      return {
        ...element,
        customisations: {
          text: element.editableText.defaultText,
        },
      }
    }
    case 'text-styled': {
      return {
        ...element,
        customisations: {
          text: element.editableText.defaultText,
          colorId: element.color.id,
          fontFaceId: element.fontFace.id,
          fontSize: element.fontSize,
          horizontalAlignment: element.horizontalAlignment,
          verticalAlignment: element.verticalAlignment,
        },
      }
    }
    case 'text-placeholder': {
      return {
        ...element,
        customisations: {
          textParts: element.textParts.reduce<string[]>((acc, textPart) => {
            if (textPart.type === 'editable') {
              acc.push(textPart.defaultText)
            }
            return acc
          }, []),
        },
      }
    }
    case 'image-static':
    case 'shape-rectangle':
    case 'overlay-inlay':
    case 'overlay-image':
    case 'overlay-text':
    case 'sticker':
    case 'media': {
      return element
    }
  }
}

const resetDesignScene = (design: Design, scene: DesignScene): DesignScene => {
  const resetScene = scene.elementIds.reduce<DesignScene>(
    (acc, elementId) => {
      const element = scene.elementById[elementId]

      if (
        element.type === 'sticker' ||
        element.type === 'overlay-image' ||
        element.type === 'overlay-text' ||
        element.type === 'media'
      ) {
        return acc
      }

      return {
        ...acc,
        elementIds: [...acc.elementIds, elementId],
        elementById: {
          ...acc.elementById,
          [elementId]: resetDesignElement(element),
        },
      }
    },
    {
      ...scene,
      elementIds: [],
      elementById: {},
    },
  )

  if (resetScene.layoutOriginal) {
    return applyLayout(design, resetScene, resetScene.layoutOriginal.grid)
  }

  return resetScene
}

const resetDesign = (design: Design): Design => {
  return design.sceneIds.reduce<Design>(
    (acc, sceneId) => ({
      ...acc,
      sceneById: {
        ...acc.sceneById,
        [sceneId]: resetDesignScene(design, acc.sceneById[sceneId]),
      },
    }),
    design,
  )
}

const resetMainView = (view: ViewMainView): ViewMainView => {
  switch (view.type) {
    case 'edit': {
      return {
        ...view,
        activeElementId: null,
        ui: null,
      }
    }
  }

  return view
}

export const createResetDesign: ActionCreator<'resetDesign'> =
  context => async () => {
    const { set, t } = context

    set(initialStore => {
      let store = initialStore

      store = storeUpdateView(store, 'main', view => ({
        view: resetMainView(view.view),
      }))

      store = storeUpdateDesign(
        store,
        { changeReason: 'elements-changed', t },
        resetDesign,
      )

      return store
    })
  }
