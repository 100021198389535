import { Box, focusIndicatorStyles } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled, useTheme } from '@moonpig/launchpad-utils'
import React, {
  FC,
  TouchEvent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

const StyledContainer = styled.div`
  user-select: none;
`

const StyledTrack = styled.svg`
  display: block;
  touch-action: none;
`

const StyledHeader = styled.div`
  position: relative;
  left: 1em;
  display: block;
  text-align: center;
  ${s({ typography: 'bodySmall' })}
`

const StyledLabel = styled.span`
  display: inline-block;
`

const StyledValue = styled.span`
  display: inline-block;
  margin-left: 8px;
  min-width: 3em;
  text-align: left;
  font-weight: bold;
`

const StyledSlider = styled.div`
  ${focusIndicatorStyles}
  ${s({ borderRadius: 2 })}

  outline: none;
  cursor: ew-resize;
`

const TRACK_HEIGHT = 40
const TRACK_MARKS_HEIGHT = 24
const FADE_WIDTH = 120

const getPoint = (
  target: HTMLDivElement,
  event: React.PointerEvent,
): [number, number] => {
  const bounds = target.getBoundingClientRect()
  return [event.clientX - bounds.left, event.clientY - bounds.top]
}

const fill = (limit: number): number[] => {
  const a: number[] = []
  for (let i = 0; i < limit; i += 1) {
    a.push(i)
  }
  return a
}

const Marks: FC<{
  trackHeight: number
  numOfMarks: number
  minorPerMajorMark: number
  pixelsPerMark: number
  theme: ReturnType<typeof useTheme>
}> = React.memo(
  ({ trackHeight, numOfMarks, minorPerMajorMark, pixelsPerMark, theme }) => {
    return (
      <>
        {fill(numOfMarks).map(i => {
          const markValue = i
          const isMajorMark = i % minorPerMajorMark === 0

          const color = isMajorMark
            ? theme.palette.colorBorder01
            : theme.palette.colorBorder03
          const x = markValue * pixelsPerMark
          return (
            <rect
              key={i}
              x={x - 1}
              y={(trackHeight - TRACK_MARKS_HEIGHT) * 0.5}
              width="2"
              height={TRACK_MARKS_HEIGHT}
              fill={color}
            />
          )
        })}
      </>
    )
  },
)

Marks.displayName = 'Marks'

type DialProps = {
  id: string
  label: string
  value: number
  getValueText: (value: number) => string
  minValue: number
  maxValue: number
  unitsPerMark: number
  pixelsPerMark: number
  minorPerMajorMark: number
  onChange: (value: number, context: { last: boolean }) => void
}

export const Dial: FC<DialProps> = ({
  id,
  label,
  value,
  getValueText,
  minValue,
  maxValue,
  unitsPerMark,
  pixelsPerMark,
  minorPerMajorMark,
  onChange,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null)

  const range = maxValue - minValue
  const numOfMarks = range / unitsPerMark + 1
  const widthPixels = (numOfMarks - 1) * pixelsPerMark
  const pixelsPerUnit = widthPixels / range
  const unitsPerPixel = 1 / pixelsPerUnit
  const valuePixels = value * pixelsPerUnit
  const minValuePixels = minValue * pixelsPerUnit

  const theme = useTheme()

  const start = useRef<null | {
    value: number
    pointerId: number
    point: [number, number]
  }>(null)

  const clampValue = useCallback(
    (newValue: number) => {
      return Math.max(minValue, Math.min(maxValue, newValue))
    },
    [maxValue, minValue],
  )

  const handlePointerDown = useCallback(
    (event: React.PointerEvent) => {
      if (!event.isPrimary || !sliderRef.current) return

      if (event.pointerType === 'mouse')
        sliderRef.current.setPointerCapture(event.pointerId)

      start.current = {
        value,
        pointerId: event.pointerId,
        point: getPoint(sliderRef.current, event),
      }
    },
    [value],
  )

  const handlePointerMove = useCallback(
    (event: React.PointerEvent) => {
      if (!event.isPrimary || !start.current || !sliderRef.current) return

      const currentPoint = getPoint(sliderRef.current, event)
      const startPoint = start.current.point
      const diffPoint = [
        currentPoint[0] - startPoint[0],
        currentPoint[1] - startPoint[1],
      ]
      const diffX = diffPoint[0] * 1
      const newValue = start.current.value - diffX * unitsPerPixel
      onChange(clampValue(newValue), { last: false })
    },
    [clampValue, onChange, unitsPerPixel],
  )

  const handlePointerUpOrCancel = useCallback(
    (event: React.PointerEvent) => {
      if (!event.isPrimary || !start.current || !sliderRef.current) return

      if (sliderRef.current.releasePointerCapture)
        sliderRef.current.releasePointerCapture(start.current.pointerId)

      start.current = null
      onChange(value, { last: true })
    },
    [onChange, value],
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowDown':
        case 'ArrowLeft':
          event.preventDefault()
          event.stopPropagation()
          onChange(clampValue(value - unitsPerMark), { last: true })
          break
        case 'ArrowUp':
        case 'ArrowRight':
          event.preventDefault()
          event.stopPropagation()
          onChange(clampValue(value + unitsPerMark), { last: true })
          break
        /* istanbul ignore next */
        default:
          break
      }
    },
    [clampValue, onChange, unitsPerMark, value],
  )

  const [svgWidth, setSvgWidth] = useState(0)

  useLayoutEffect(() => {
    const update = () => {
      /* istanbul ignore if */
      if (!sliderRef.current) return
      setSvgWidth(sliderRef.current.clientWidth)
    }

    update()
    window.addEventListener('resize', update)
    return () => {
      window.removeEventListener('resize', update)
    }
  })

  const handleTouchMove = useCallback((event: TouchEvent) => {
    event.stopPropagation()
  }, [])

  return (
    <StyledContainer as={Box} width="100%" position="relative">
      <StyledHeader>
        <StyledLabel id={id}>{label}</StyledLabel>
        <StyledValue aria-hidden="true">{getValueText(value)}</StyledValue>
      </StyledHeader>
      <StyledSlider
        ref={sliderRef}
        aria-labelledby={id}
        role="slider"
        aria-valuemin={minValue}
        aria-valuemax={maxValue}
        aria-valuenow={value}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onPointerDown={handlePointerDown}
        onPointerMove={handlePointerMove}
        onPointerUp={handlePointerUpOrCancel}
        onPointerCancel={handlePointerUpOrCancel}
        onTouchMove={handleTouchMove}
      >
        <StyledTrack
          aria-hidden="true"
          width="100%"
          height={TRACK_HEIGHT}
          shapeRendering="crispEdges"
          cursor="ew-resize"
        >
          {svgWidth !== 0 && (
            <>
              <defs>
                <linearGradient id="fade-left">
                  <stop offset="0%" stopColor="rgba(255, 255, 255, 1)" />
                  <stop offset="100%" stopColor="rgba(255, 255, 255, 0)" />
                </linearGradient>
                <linearGradient id="fade-right">
                  <stop offset="0%" stopColor="rgba(255, 255, 255, 0)" />
                  <stop offset="100%" stopColor="rgba(255, 255, 255, 1)" />
                </linearGradient>
              </defs>
              <g
                data-testid="ed-dial-group"
                transform={`translate(${
                  svgWidth * 0.5 - (valuePixels - minValuePixels)
                } 0)`}
              >
                <Marks
                  numOfMarks={numOfMarks}
                  minorPerMajorMark={minorPerMajorMark}
                  trackHeight={TRACK_HEIGHT}
                  pixelsPerMark={pixelsPerMark}
                  theme={theme}
                />
              </g>
              <rect
                x="0"
                y="0"
                width={FADE_WIDTH}
                height={TRACK_HEIGHT}
                fill="url(#fade-left)"
              />
              <rect
                x={svgWidth - FADE_WIDTH}
                y="0"
                width={FADE_WIDTH}
                height={TRACK_HEIGHT}
                fill="url(#fade-right)"
              />
              <rect
                x={svgWidth * 0.5 - 2}
                y="0"
                height={TRACK_HEIGHT}
                width="4"
                fill={theme.palette.colorInteractionButton}
              />
            </>
          )}
        </StyledTrack>
      </StyledSlider>
    </StyledContainer>
  )
}
