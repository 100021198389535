import { clamp } from '@moonpig/common-math'
import { byId } from '../common/byId'
import { CollageGrid, CollageGridGutter } from './grid'
import { GutterById, getGutterOffsetRange } from './gutterOffsetRange'

const MIN_OFFSET_BETWEEN_GUTTERS = 0.1
const SNAP_THRESHOLD = 0.02

const areGuttersOpposing = (
  g1: CollageGridGutter,
  g2: CollageGridGutter,
): boolean => {
  if (g1.anchors.end !== null && g1.anchors.end === g2.anchors.start) {
    return true
  }
  if (g1.anchors.start !== null && g1.anchors.start === g2.anchors.end) {
    return true
  }
  return false
}

const snapGutterOffset = (
  gutterById: GutterById,
  gutterId: string,
  newOffset: number,
) => {
  const gutter = gutterById[gutterId]

  let snappedOffset = newOffset

  Object.values(gutterById).forEach(other => {
    if (
      other.id === gutter.id ||
      other.orientation !== gutter.orientation ||
      !areGuttersOpposing(gutter, other)
    ) {
      return
    }

    if (Math.abs(other.offset - newOffset) > SNAP_THRESHOLD) {
      return
    }

    snappedOffset = other.offset
  })

  return snappedOffset
}

export const updateGridGutterOffset = (
  grid: CollageGrid,
  gutterId: string,
  newOffset: number,
): CollageGrid => {
  const gutterById = byId(grid.gutters)

  const [minOffset, maxOffset] = getGutterOffsetRange(gutterById, gutterId)

  const clampedOffset = clamp(
    newOffset,
    minOffset + MIN_OFFSET_BETWEEN_GUTTERS,
    maxOffset - MIN_OFFSET_BETWEEN_GUTTERS,
  )

  const snappedOffset = snapGutterOffset(gutterById, gutterId, clampedOffset)

  return {
    ...grid,
    gutters: grid.gutters.map(other =>
      other.id === gutterId ? { ...other, offset: snappedOffset } : other,
    ),
  }
}
