import { Design } from '@moonpig/web-personalise-editor-types'

export const design: Design = {
  id: '6e744ed7-eab0-4d27-89d2-4822aee28c21',
  token:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkZXNpZ25JZCI6IjZlNzQ0ZWQ3LWVhYjAtNGQyNy04OWQyLTQ4MjJhZWUyOGMyMSIsImlhdCI6MTY5NTgyNjYzM30.PRALvX9KYzgoa_S52QcKGLNDoDfI-PfkjEWcgB7nPUI',
  version: 0,
  modified: false,
  productKey: 'CMK002',
  layout: 'GENERIC',
  sceneIds: ['d8cb0088-dda2-4b0b-91b6-a3e9209ed5de'],
  sceneById: {
    'd8cb0088-dda2-4b0b-91b6-a3e9209ed5de': {
      id: 'd8cb0088-dda2-4b0b-91b6-a3e9209ed5de',
      title: 'Front of Card',
      width: 133,
      height: 187,
      layout: null,
      layoutOriginal: null,
      renderMethod: 'LEGACY_1',
      availableFeatures: ['stickers', 'overlay-text'],
      elementIds: [],
      elementById: {},
      placements: {
        media: {
          x: 6.649999999999999,
          y: 6.65,
          width: 119.7,
        },
      },
    },
  },
  usedFeatures: [
    {
      type: 'EditorFeatureTemplateBaseline',
      required: true,
    },
    {
      type: 'EditorFeatureOverlayText',
      required: false,
    },
    {
      type: 'EditorFeatureTemplateImageCollage',
      required: true,
    },
    {
      type: 'EditorFeatureTemplateBaseline',
      required: true,
    },
    {
      type: 'EditorFeatureLayout',
      required: false,
    },
    {
      type: 'EditorFeatureOverlayText',
      required: false,
    },
    {
      type: 'EditorFeatureTemplateBaseline',
      required: true,
    },
    {
      type: 'EditorFeatureLayout',
      required: false,
    },
    {
      type: 'EditorFeatureOverlayText',
      required: false,
    },
    {
      type: 'EditorFeatureTemplateBaseline',
      required: true,
    },
  ],
  availableStickerSets: [
    {
      id: 'default',
    },
  ],
  defaultTextStyle: {
    fontFaceId: '837',
    colorId: '2769',
    fontSize: 30,
  },
  availableTextStyles: {
    availableFontFaceIds: [
      '836',
      '837',
      '1534',
      '1603',
      '1703',
      '1856',
      '2014',
      '2541',
      '2580',
      'a6dfebf6',
    ],
    availableFontFaceById: {
      '836': {
        id: '836',
        name: 'Futura Bold',
        urls: {
          woff2:
            'https://eu-west-1.template.prod.moonpig.net/font/0054091271aa744e',
        },
        metrics: {
          head: {
            unitsPerEm: 1000,
          },
          hhea: {
            ascender: 924,
            descender: -200,
            lineGap: 84,
          },
          os2: {
            usWinAscent: 924,
            usWinDescent: 250,
          },
        },
      },
      '837': {
        id: '837',
        name: 'Futura2 Book',
        urls: {
          woff2:
            'https://eu-west-1.template.prod.moonpig.net/font/23c47cd9f17dc11d',
        },
        metrics: {
          head: {
            unitsPerEm: 1000,
          },
          hhea: {
            ascender: 829,
            descender: -200,
            lineGap: 77,
          },
          os2: {
            usWinAscent: 768,
            usWinDescent: 234,
          },
        },
      },
      '1534': {
        id: '1534',
        name: 'Jump LT Pro',
        urls: {
          woff2:
            'https://eu-west-1.template.prod.moonpig.net/font/1983de1849a9a450',
        },
        metrics: {
          head: {
            unitsPerEm: 1000,
          },
          hhea: {
            ascender: 640,
            descender: -360,
            lineGap: 329,
          },
          os2: {
            usWinAscent: 965,
            usWinDescent: 364,
          },
        },
      },
      '1603': {
        id: '1603',
        name: 'Baskerville Com',
        urls: {
          woff2:
            'https://eu-west-1.template.prod.moonpig.net/font/b917ad614b7cabcf',
        },
        metrics: {
          head: {
            unitsPerEm: 1000,
          },
          hhea: {
            ascender: 707,
            descender: -293,
            lineGap: 200,
          },
          os2: {
            usWinAscent: 893,
            usWinDescent: 294,
          },
        },
      },
      '1703': {
        id: '1703',
        name: 'Schmutz Pro Cleaned',
        urls: {
          woff2:
            'https://eu-west-1.template.prod.moonpig.net/font/5db41c10b5ec359c',
        },
        metrics: {
          head: {
            unitsPerEm: 1000,
          },
          hhea: {
            ascender: 700,
            descender: -300,
            lineGap: 200,
          },
          os2: {
            usWinAscent: 800,
            usWinDescent: 400,
          },
        },
      },
      '1856': {
        id: '1856',
        name: 'Finerliner LT Micro',
        urls: {
          woff2:
            'https://eu-west-1.template.prod.moonpig.net/font/200fca279e4c7091',
        },
        metrics: {
          head: {
            unitsPerEm: 1000,
          },
          hhea: {
            ascender: 632,
            descender: -208,
            lineGap: 0,
          },
          os2: {
            usWinAscent: 800,
            usWinDescent: 208,
          },
        },
      },
      '2014': {
        id: '2014',
        name: 'BerrangerHandITC TT',
        urls: {
          woff2:
            'https://eu-west-1.template.prod.moonpig.net/font/072923a9039529a6',
        },
        metrics: {
          head: {
            unitsPerEm: 1000,
          },
          hhea: {
            ascender: 1030,
            descender: -500,
            lineGap: 0,
          },
          os2: {
            usWinAscent: 1030,
            usWinDescent: 500,
          },
        },
      },
      '2541': {
        id: '2541',
        name: 'Dancing Script',
        urls: {
          woff2:
            'https://eu-west-1.template.prod.moonpig.net/font/bd092902e2843393',
        },
        metrics: {
          head: {
            unitsPerEm: 1000,
          },
          hhea: {
            ascender: 920,
            descender: -280,
            lineGap: 0,
          },
          os2: {
            usWinAscent: 1096,
            usWinDescent: 306,
          },
        },
      },
      '2580': {
        id: '2580',
        name: 'Shadows Into Light',
        urls: {
          woff2:
            'https://eu-west-1.template.prod.moonpig.net/font/3976d3040d7eb7b1',
        },
        metrics: {
          head: {
            unitsPerEm: 1024,
          },
          hhea: {
            ascender: 1203,
            descender: -442,
            lineGap: 0,
          },
          os2: {
            usWinAscent: 1203,
            usWinDescent: 440,
          },
        },
      },
      a6dfebf6: {
        id: 'a6dfebf6',
        name: 'Praise Regular',
        urls: {
          woff2:
            'https://eu-west-1.template.prod.moonpig.net/font/c60baf0a52f02290',
        },
        metrics: {
          head: {
            unitsPerEm: 1000,
          },
          hhea: {
            ascender: 850,
            descender: -350,
            lineGap: 0,
          },
          os2: {
            usWinAscent: 1023,
            usWinDescent: 544,
          },
        },
      },
    },
    availableColorIds: [
      '2769',
      '2777',
      '3110',
      '3227',
      '3327',
      '3467',
      '3470',
      '4183',
      '4297',
      '4303',
      '4377',
      '4421',
      '4428',
      '4468',
      '4498',
      '4517',
      '4552',
      '4646',
      '4715',
      '4723',
      '4731',
      '4811',
    ],
    availableColorById: {
      '2769': {
        id: '2769',
        name: 'Black',
        value: '#000000',
      },
      '2777': {
        id: '2777',
        name: 'Red',
        value: '#ed2124',
      },
      '3110': {
        id: '3110',
        name: 'Sea Green',
        value: '#44963d',
      },
      '3227': {
        id: '3227',
        name: 'Dark Pink',
        value: '#ee3e8f',
      },
      '3327': {
        id: '3327',
        name: 'Teal',
        value: '#008c98',
      },
      '3467': {
        id: '3467',
        name: 'Lavender',
        value: '#7056a4',
      },
      '3470': {
        id: '3470',
        name: 'Carrot Orange',
        value: '#f2822f',
      },
      '4183': {
        id: '4183',
        name: 'Midnight',
        value: '#21318b',
      },
      '4297': {
        id: '4297',
        name: 'Dark Olive Green',
        value: '#336735',
      },
      '4303': {
        id: '4303',
        name: 'Dark Rose',
        value: '#bf375b',
      },
      '4377': {
        id: '4377',
        name: 'Grey',
        value: '#7a7a7a',
      },
      '4421': {
        id: '4421',
        name: 'Orange Red',
        value: '#dd4f12',
      },
      '4428': {
        id: '4428',
        name: 'Indigo',
        value: '#572a77',
      },
      '4468': {
        id: '4468',
        name: 'Steel Blue',
        value: '#0c87bf',
      },
      '4498': {
        id: '4498',
        name: 'Dusky Rose',
        value: '#c2536b',
      },
      '4517': {
        id: '4517',
        name: 'Turquoise',
        value: '#62b9af',
      },
      '4552': {
        id: '4552',
        name: 'Steel Blue Grey',
        value: '#456289',
      },
      '4646': {
        id: '4646',
        name: 'Dark Red',
        value: '#951429',
      },
      '4715': {
        id: '4715',
        name: 'Darkest Green',
        value: '#033a23',
      },
      '4723': {
        id: '4723',
        name: 'Tangerine Yellow',
        value: '#e8a11e',
      },
      '4731': {
        id: '4731',
        name: 'Dark Slate',
        value: '#4a4746',
      },
      '4811': {
        id: '4811',
        name: 'Fuchsia',
        value: '#df0075',
      },
    },
    availableFontSizes: [
      12, 14, 16, 18, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65,
    ],
  },
  limits: {
    maxInlayCount: 4,
    maxStickerCount: 50,
    maxTextCount: 80,
    maxImageCount: 20,
  },
}
