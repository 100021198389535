import { SceneObject } from '@moonpig/renderer-scene-types'
import { DesignElement } from '@moonpig/web-personalise-editor-types'
import React, {
  FC,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { animated } from 'react-spring'
import { useCamera } from '../../../../../camera'
import { usePixelRatio } from '../../../../../utils/usePixelRatio'
import { Layout, scaleLayout } from '../../../../../layout'
import { LayoutImage } from '../../../../../layout/types'
import { useMainView } from '../../../../../store'
import { DefaultSceneSurface } from '../../../components/DefaultSceneSurface'
import { useLayout, useScene, useSceneIds } from '../../../selectors'
import { useActiveKeyboardElementRef } from '../../selectors'

const SceneRender: FC<{
  sceneId: string
  position: { x: number; y: number }
  scale: number
}> = ({ sceneId, position, scale }) => {
  const scene = useScene(sceneId)
  const activeKeyboardElementRef = useActiveKeyboardElementRef()
  const canvasWidth = Math.floor(scene.width * scale)
  const canvasHeight = Math.floor(scene.height * scale)

  const hideObject = useCallback(
    ({ object }: { object: SceneObject }): boolean => {
      const element = scene.elementById[object.id]
      if (
        element &&
        element.type === 'overlay-text' &&
        element.fragmentsState?.resizingWidth
      ) {
        return true
      }

      return object.id === activeKeyboardElementRef?.id
    },
    [activeKeyboardElementRef, scene.elementById],
  )

  const selectedImageUploadElementId = useMainView(
    'edit',
    ({ activeElementId }, { design, selectedSceneId }): string | null => {
      if (!activeElementId) {
        return null
      }

      const element =
        design.sceneById[selectedSceneId].elementById[activeElementId]

      if (element.type === 'image-upload') {
        return element.id
      }

      return null
    },
  )

  const dimElement = useCallback(
    (element: DesignElement): boolean => {
      if (element.type === 'media') {
        return element.state.type !== 'uploaded'
      }

      if (element.type !== 'image-upload' || !element.editable) {
        return false
      }

      if (
        element.customisations.sourceImage === null &&
        element.customisations.editedImage === null
      ) {
        return true
      }

      return (
        selectedImageUploadElementId !== null &&
        element.id !== selectedImageUploadElementId
      )
    },
    [selectedImageUploadElementId],
  )

  return (
    <div
      data-testid={`mp-ed-layer-render-scene-${sceneId}`}
      style={{
        position: 'absolute',
        width: canvasWidth,
        height: canvasHeight,
        left: position.x,
        top: position.y,
        overflow: 'hidden',
      }}
    >
      <DefaultSceneSurface
        scene={scene}
        size={{ width: canvasWidth, height: canvasHeight }}
        hideObject={hideObject}
        dimElement={dimElement}
      />
    </div>
  )
}

const useScaledLayout = (worldScale: number): [Layout, number] => {
  const pixelRatio = usePixelRatio({ max: 2, min: 1 })

  const layout = useLayout()

  const trueScale = worldScale * pixelRatio

  const [renderScale, setRenderScale] = useState(trueScale)

  useLayoutEffect(
    () => setRenderScale(currentScale => Math.max(trueScale, currentScale)),
    [trueScale],
  )

  const scaledLayout = useMemo(
    () => scaleLayout(layout, renderScale),
    [layout, renderScale],
  )

  return [scaledLayout, renderScale]
}

const Images: FC<{ images: LayoutImage[] }> = ({ images }) => {
  return (
    <>
      {images.map(image => {
        const { id, url, x, y, width, height } = image
        return (
          <img
            key={id}
            src={url}
            alt=""
            style={{ position: 'absolute', left: x, top: y, width, height }}
          />
        )
      })}
    </>
  )
}

export const LayerRender: FC = () => {
  const sceneIds = useSceneIds()

  const { animation, scale } = useCamera()
  const [scaledLayout, renderScale] = useScaledLayout(scale)

  const transform = animation.transform.to(v => {
    return v.replace(/scale\((.+)\)/, (...p) => {
      return `scale(${Number(p[1]) / renderScale})`
    })
  })

  return (
    <animated.div
      data-testid="mp-editor-layer-render"
      style={{
        position: 'absolute',
        zIndex: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transform,
        transformOrigin: '0px 0px',
        pointerEvents: 'none',
      }}
    >
      <Images images={scaledLayout.images.background} />
      {sceneIds.map(sceneId => {
        const position = scaledLayout.sceneById[sceneId]
        return (
          <SceneRender
            key={sceneId}
            sceneId={sceneId}
            position={position}
            scale={renderScale}
          />
        )
      })}
      <Images images={scaledLayout.images.foreground} />
    </animated.div>
  )
}
