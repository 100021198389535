/* eslint-disable array-callback-return */
import { Box, Grid } from '@moonpig/launchpad-components'
import { AllSystemProps } from '@moonpig/launchpad-system'
import React, { FC, ReactNode } from 'react'
import { ContentButton } from './ContentButton'

type ContentItem =
  | {
      type: 'loading'
      id: string
      content: ReactNode
    }
  | {
      type: 'loaded'
      id: string
      selected: boolean
      selectLabel?: string
      deleteLabel?: string
      content: ReactNode
    }

type ContentPickerProps = {
  labelledById?: string
  columnWidth?: AllSystemProps['width']
  addContentControl?: ReactNode
  items: ContentItem[]
  selectLimit?: number
  multiSelect?: boolean
  onDeleteItem?: (id: string) => void
  onSelectItem: (id: string) => void
}

export const ContentPicker: FC<ContentPickerProps> = ({
  labelledById,
  columnWidth = 1 / 3,
  addContentControl,
  items,
  selectLimit = 1,
  multiSelect,
  onDeleteItem,
  onSelectItem,
}) => {
  const selectedCount = items.filter(
    item => item.type === 'loaded' && item.selected,
  ).length

  const disableUnselected = multiSelect && selectLimit === selectedCount
  return (
    <Box
      role={multiSelect ? undefined : 'radiogroup'}
      aria-labelledby={labelledById}
      pb={onDeleteItem ? 6 : 0}
    >
      <Grid colGap={6} rowGap={onDeleteItem ? 10 : 6}>
        {addContentControl && (
          <Box width={columnWidth}>{addContentControl}</Box>
        )}
        {items.map(item => {
          switch (item.type) {
            case 'loading': {
              return (
                <Box key={item.id} width={columnWidth}>
                  {item.content}
                </Box>
              )
            }
            case 'loaded': {
              return (
                <Box key={item.id} width={columnWidth}>
                  <ContentButton
                    selected={item.selected}
                    disabled={disableUnselected && !item.selected}
                    multiSelect={multiSelect}
                    selectLabel={item.selectLabel}
                    deleteLabel={item.deleteLabel}
                    onSelect={() => {
                      if (multiSelect || !item.selected) {
                        onSelectItem(item.id)
                      }
                    }}
                    onDelete={
                      onDeleteItem &&
                      (() => {
                        onDeleteItem(item.id)
                      })
                    }
                  >
                    {item.content}
                  </ContentButton>
                </Box>
              )
            }
          }
        })}
      </Grid>
    </Box>
  )
}
