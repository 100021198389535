import React, { FC } from 'react'
import { Image } from '@moonpig/launchpad-components'
import { useStore } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'

export const HandwritingOnboardingBanner: FC = () => {
  const t = useEditorLocaleText()
  const handwritingOnboardingImageUrl = useStore(
    ({ config }) => config.handwritingOnboardingImageUrl,
  )
  return (
    <Image
      alt={t('toolbar_menu_handwriting_label')}
      src={handwritingOnboardingImageUrl}
      aspectRatio={4 / 3}
    />
  )
}
