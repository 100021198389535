import { Vec2 } from '@moonpig/common-math'
import {
  DesignElementOverlayImage,
  DesignScene,
  Photo,
} from '@moonpig/web-personalise-editor-types'
import uuid from 'uuid/v4'
import { getDefaultOverlayImageSize } from '../../../../../../../../utils/getDefaultOverlayImageSize'

const DIAGONAL_OFFSET_DISTANCE = 10
const DIAGONAL_OFFSET_REPEAT = 3

export const createOverlayImageElement = ({
  scene,
  photo,
  numberOfSelectedPhotos,
}: {
  scene: DesignScene
  photo: Photo
  numberOfSelectedPhotos: number
}): DesignElementOverlayImage => {
  const offsetMultiplier = numberOfSelectedPhotos % DIAGONAL_OFFSET_REPEAT
  const offset: Vec2 = [
    DIAGONAL_OFFSET_DISTANCE * offsetMultiplier,
    DIAGONAL_OFFSET_DISTANCE * offsetMultiplier,
  ]

  const { width, height } = getDefaultOverlayImageSize(
    scene,
    photo.originalImage,
  )

  const x = offset[0] + (scene.width - width) * 0.5
  const y = offset[1] + (scene.height - height) * 0.5

  return {
    type: 'overlay-image',
    sceneId: scene.id,
    id: uuid(),
    x,
    y,
    width,
    height,
    rotation: 0,
    customisations: {
      pendingEditedImage: null,
      editedImage: null,
      imageKind: 'photo',
      fixed: false,
      sourceImage: {
        id: photo.id,
        transform: {
          position: { x: 0, y: 0 },
          rotation: 0,
          scale: 1,
        },
      },
    },
  }
}
