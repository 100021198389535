import { assert } from '../../../utils/assert'
import { ActionCreator } from '../../types'

export const createSetUI: ActionCreator<'setUI'> =
  ({ get, set, services }) =>
  async updater => {
    const store = get()

    assert(store.view.type === 'main' && store.view.view.type === 'edit')

    const ui =
      typeof updater === 'function' ? updater(store.view.view.ui) : updater

    set(({ view }) => {
      assert(view.type === 'main' && view.view.type === 'edit')

      const {
        design: { productKey },
      } = view

      switch (ui?.type) {
        case 'image-collage': {
          switch (ui.selectedMenuItem) {
            case 'layout-picker': {
              services.trackEvent({
                type: 'SELECT_CONTENT',
                kind: 'COLLAGE_LAYOUT',
                productKey,
              })
              break
            }
            case 'photo-library': {
              services.trackEvent({
                type: 'SELECT_CONTENT',
                kind: 'COLLAGE_PHOTOS',
                productKey,
              })
              break
            }
            case 'border': {
              services.trackEvent({
                type: 'SELECT_CONTENT',
                kind: 'COLLAGE_BORDER',
                productKey,
              })
              break
            }
          }
          break
        }
        case 'image-collage-cell': {
          switch (ui.selectedMenuItem) {
            case 'adjust': {
              services.trackEvent({
                type: 'SELECT_CONTENT',
                kind: 'COLLAGE_ADJUST',
                productKey,
              })
              break
            }
            case 'replace': {
              services.trackEvent({
                type: 'SELECT_CONTENT',
                kind: 'COLLAGE_REPLACE',
                productKey,
              })
              break
            }
          }
          break
        }
        case 'image-collage-rearrange': {
          services.trackEvent({
            type: 'SELECT_CONTENT',
            kind: 'COLLAGE_REARRANGE',
            productKey,
          })
          break
        }
      }

      return {
        view: {
          ...view,
          view: { ...view.view, ui },
        },
      }
    })
  }
