import React, { FC } from 'react'

export const IconRedo: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <path
        d="M9.8 19C8.21667 19 6.85417 18.4667 5.7125 17.4C4.57083 16.3333 4 15.0167 4 13.45C4 11.8833 4.57083 10.5667 5.7125 9.5C6.85417 8.43333 8.21667 7.9 9.8 7.9H17.15L14.825 5.575C14.675 5.425 14.6 5.25 14.6 5.05C14.6 4.85 14.675 4.675 14.825 4.525C14.975 4.375 15.15 4.3 15.35 4.3C15.55 4.3 15.725 4.375 15.875 4.525L19.475 8.125C19.5583 8.20833 19.6167 8.29167 19.65 8.375C19.6833 8.45833 19.7 8.55 19.7 8.65C19.7 8.75 19.6833 8.84167 19.65 8.925C19.6167 9.00833 19.5583 9.09167 19.475 9.175L15.875 12.775C15.725 12.925 15.55 13 15.35 13C15.15 13 14.975 12.925 14.825 12.775C14.675 12.625 14.6 12.45 14.6 12.25C14.6 12.05 14.675 11.875 14.825 11.725L17.15 9.4H9.775C8.60833 9.4 7.60417 9.7875 6.7625 10.5625C5.92083 11.3375 5.5 12.3 5.5 13.45C5.5 14.6 5.92083 15.5625 6.7625 16.3375C7.60417 17.1125 8.60833 17.5 9.775 17.5H16.25C16.4667 17.5 16.6458 17.5708 16.7875 17.7125C16.9292 17.8542 17 18.0333 17 18.25C17 18.4667 16.9292 18.6458 16.7875 18.7875C16.6458 18.9292 16.4667 19 16.25 19H9.8Z"
        fill="currentColor"
      />
    </svg>
  )
}
