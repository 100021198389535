export const moveSelectionToEnd = (element: Element) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selection = window.getSelection()!
  const range = document.createRange()
  range.selectNodeContents(element)
  range.collapse(false)
  selection.removeAllRanges()
  selection.addRange(range)
}

export const focusInput = (element: HTMLElement) => {
  element.focus()
  moveSelectionToEnd(element)
}
