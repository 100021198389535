import { ContentPicker } from '@moonpig/web-personalise-components'
import {
  DesignElementSticker,
  DesignSticker,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useEffect, useState } from 'react'
import {
  DESKTOP_BP,
  DIAGONAL_OFFSET_DISTANCE,
  DIAGONAL_OFFSET_REPEAT,
} from '../../../../../../../../constants'
import { useCheckIfMaxElementLimitReached } from '../../../../../../../../utils/useCheckIfMaxElementLimitReached'
import { useAction, useView } from '../../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../../text-localisation'
import { StickerImage } from './StickerImage'
import { createStickerElement } from './createStickerElement'

export const StickerLibrary: FC = () => {
  const t = useEditorLocaleText()
  const loadSticker = useAction('loadSticker')
  const addElement = useAction('addElement')
  const notify = useAction('notify')
  const setUI = useAction('setUI')
  const selectElement = useAction('selectElement')
  const deleteElement = useAction('deleteElement')
  const [appliedStickers, setAppliedStickers] = useState<
    DesignElementSticker[]
  >([])
  const selectedSceneId = useView('main', view => view.selectedSceneId)
  const maxStickerCount = useView(
    'main',
    view => view.design.limits.maxStickerCount,
  )
  const stickers = useView('main', view => view.stickers)
  const selectedSceneWidth = useView(
    'main',
    view => view.design.sceneById[selectedSceneId].width,
  )
  const selectedSceneHeight = useView(
    'main',
    view => view.design.sceneById[selectedSceneId].height,
  )

  const { maxElementAdded, designOverlayElementCount } =
    useCheckIfMaxElementLimitReached('sticker', 'maxStickerCount')

  useEffect(() => {
    if (maxElementAdded) {
      notify({ type: 'maximum_stickers_added' })
    }
  }, [maxElementAdded, notify])

  const addOrRemoveStickers = useCallback(
    (
      selectedStickers: DesignElementSticker[],
      sticker: DesignSticker,
    ): DesignElementSticker[] => {
      const existingSticker = selectedStickers.find(
        ({ sticker: appliedSticker }) => appliedSticker.id === sticker.id,
      )
      if (existingSticker) {
        deleteElement(existingSticker)
        setUI({
          type: 'sticker',
          sceneId: selectedSceneId,
          elementRef: null,
          selectedMenuItem: 'stickers',
        })
        return selectedStickers.filter(
          ({ sticker: appliedSticker }) => appliedSticker.id !== sticker.id,
        )
      }
      const offsetMultiplier = selectedStickers.length % DIAGONAL_OFFSET_REPEAT
      const offset = DIAGONAL_OFFSET_DISTANCE * offsetMultiplier
      const newElement = createStickerElement({
        sceneId: selectedSceneId,
        sceneWidth: selectedSceneWidth,
        sceneHeight: selectedSceneHeight,
        sticker,
        offset,
      })

      addElement(newElement)

      selectElement(selectedSceneId, newElement.id)

      return [...selectedStickers, newElement]
    },
    [
      selectedSceneId,
      selectedSceneWidth,
      selectedSceneHeight,
      addElement,
      selectElement,
      deleteElement,
      setUI,
    ],
  )

  const handleSelectSticker = useCallback(
    (stickerId: string) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const selectedSticker = stickers.find(
        sticker => sticker.id === stickerId,
      )!

      const stickersOnScene = addOrRemoveStickers(
        appliedStickers,
        selectedSticker,
      )
      const selectedStickers: DesignSticker[] = stickersOnScene.map(
        appliedSticker => appliedSticker.sticker,
      )
      selectedStickers.forEach(loadSticker)
      setAppliedStickers(stickersOnScene)
    },
    [stickers, addOrRemoveStickers, appliedStickers, loadSticker],
  )

  const selectLimit =
    maxStickerCount - designOverlayElementCount + appliedStickers.length

  return (
    <ContentPicker
      columnWidth={{ xs: 1 / 3, sm: 1 / 4, md: 1 / 5, [DESKTOP_BP]: 1 / 3 }}
      multiSelect
      selectLimit={selectLimit}
      onSelectItem={handleSelectSticker}
      items={stickers.map((sticker, index) => {
        const name = `${index + 1}`

        const selected = appliedStickers.some(
          ({ sticker: appliedSticker }) => appliedSticker.id === sticker.id,
        )

        const selectLabel = selected
          ? t('toolbar_deselect_sticker_screen_reader_label', name)
          : t('toolbar_select_sticker_screen_reader_label', name)

        return {
          type: 'loaded',
          id: sticker.id,
          selected,
          selectLabel,
          content: <StickerImage imageUrl={sticker.urlThumbnail} />,
        }
      })}
    />
  )
}
