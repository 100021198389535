import { TooltipPlacement } from './types'

interface TooltipPosition {
  top?: number
  bottom?: number
  left?: number
  right?: number
  pointerOffsetX?: number
  pointerOffsetY?: number
}

type BoundingCoordinates = {
  top: number
  right: number
  bottom: number
  left: number
  height: number
  width: number
  x: number
  y: number
}

interface TooltipPositionOptions {
  target: HTMLElement
  tooltip: HTMLElement
  placement: TooltipPlacement
  pointerSize: number
}

const getBoundingCoordinates = (elem: HTMLElement): BoundingCoordinates => {
  const box = elem.getBoundingClientRect()
  return {
    top: box.top + window.pageYOffset,
    right: box.right + window.pageXOffset,
    bottom: box.bottom + window.pageYOffset,
    left: box.left + window.pageXOffset,
    height: box.height,
    width: box.width,
    x: box.x,
    y: box.y,
  }
}

const getBottomPosition = ({
  targetElem,
  targetRect,
  tooltipRect,
  pointerSize,
}: {
  targetElem: HTMLElement
  targetRect: BoundingCoordinates
  tooltipRect: BoundingCoordinates
  pointerSize: number
}): TooltipPosition => {
  let left = targetElem.offsetLeft - pointerSize * 0.5
  let pointerOffsetX = targetRect.width * 0.5
  const parentWidth = targetElem.parentElement?.clientWidth || window.innerWidth
  /* istanbul ignore next - covered by browser tests */
  if (left + tooltipRect.width > parentWidth) {
    left = 0
    pointerOffsetX =
      targetElem.offsetLeft + targetRect.width * 0.5 - pointerSize * 0.5
  }
  return {
    top: targetElem.offsetTop + targetRect.height + pointerSize * 0.5,
    left,
    pointerOffsetX,
  }
}

export const getTooltipAndArrowOffsets = (
  options: TooltipPositionOptions,
): TooltipPosition => {
  const {
    placement,
    pointerSize,
    tooltip: tooltipElem,
    target: targetElem,
  } = options
  const targetRect = getBoundingCoordinates(targetElem)
  const tooltipRect = getBoundingCoordinates(tooltipElem)
  switch (placement) {
    case 'right':
      return {
        top:
          targetElem.offsetTop + targetRect.height / 2 - tooltipRect.height / 2,
        left: targetElem.offsetLeft + targetRect.width + pointerSize * 0.5,
        pointerOffsetX: targetRect.width,
        pointerOffsetY: tooltipRect.height * 0.5 - pointerSize * 0.5,
      }
    case 'bottom':
      return getBottomPosition({
        targetElem,
        targetRect,
        tooltipRect,
        pointerSize,
      })
    case 'left':
      return {
        top:
          targetElem.offsetTop + targetRect.height / 2 - tooltipRect.height / 2,
        left: targetElem.offsetLeft - tooltipRect.width - pointerSize * 0.5,
        pointerOffsetX: tooltipRect.width,
        pointerOffsetY: tooltipRect.height * 0.5 - pointerSize * 0.5,
      }
    case 'top':
      return {
        bottom: targetElem.offsetTop + targetRect.height + pointerSize * 0.5,
        pointerOffsetY: tooltipRect.height,
        pointerOffsetX: targetRect.width * 0.5,
        left: targetElem.offsetLeft - pointerSize * 0.5,
      }
  }
}
