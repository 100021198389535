import {
  Design,
  convertDesignToStoredDesign,
} from '@moonpig/web-personalise-editor-types'
import { GetEditorLocaleText } from '../../text-localisation'
import { createSceneTextById } from '../actions/createLoad/createSceneTextById'
import {
  DesignMetaHistoryEntry,
  SceneTextById,
  Store,
  ViewMain,
} from '../types'
import { storeUpdateView } from './view'

export type UpdateDesignContext =
  | {
      changeReason: 'elements-changed'
      t: GetEditorLocaleText
    }
  | {
      changeReason: 'elements-changed-minor'
      t: GetEditorLocaleText
    }
  | {
      changeReason: 'element-changed'
    }
  | {
      changeReason: 'element-changed-minor'
    }

export const storeUpdateDesign = (
  store: Store,
  context: UpdateDesignContext,
  updater: (design: Design, view: ViewMain) => Design,
): Store => {
  return storeUpdateView(store, 'main', view => {
    const design = updater(view.design, view)
    let sceneTextById: SceneTextById
    let majorChange: boolean

    switch (context.changeReason) {
      case 'elements-changed': {
        sceneTextById = createSceneTextById(design, context.t)
        majorChange = true
        break
      }
      case 'elements-changed-minor': {
        sceneTextById = createSceneTextById(design, context.t)
        majorChange = false
        break
      }
      case 'element-changed': {
        sceneTextById = view.sceneTextById
        majorChange = true
        break
      }
      case 'element-changed-minor': {
        sceneTextById = view.sceneTextById
        majorChange = false
        break
      }
    }

    let history = view.designMeta.history
    let changed = false

    if (majorChange) {
      const currentEntry =
        view.designMeta.history.entries[view.designMeta.history.entryIndex]

      const newEntry: DesignMetaHistoryEntry = {
        selectedSceneId: view.selectedSceneId,
        design: convertDesignToStoredDesign(design),
      }

      if (JSON.stringify(newEntry) !== JSON.stringify(currentEntry)) {
        const newHistoryEntries = [
          ...view.designMeta.history.entries.slice(
            0,
            view.designMeta.history.entryIndex + 1,
          ),
          newEntry,
        ]

        history = {
          entryIndex: newHistoryEntries.length - 1,
          entries: newHistoryEntries,
        }

        changed = true
      }
    }

    return {
      design,
      designMeta: {
        ...view.designMeta,
        changed,
        history,
      },
      sceneTextById,
    }
  })
}
