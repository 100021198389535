import { DesignElementTextPlain } from '@moonpig/web-personalise-editor-types'
import React, { FC } from 'react'
import { useCamera } from '../../../../../../../camera'
import { isElementValid } from '../../../../../../../utils/isElementValid'
import { useMainView } from '../../../../../../../store'
import { useElementRef } from '../../../../../selectors'
import { useActiveKeyboardElementRef } from '../../../../selectors'
import { InteractiveElementProps } from '../../types'
import { InteractiveElementCommon } from '../InteractiveElementCommon'
import { CharacterCount } from './components/CharacterCount'

export const ElementTextPlainInteractive: FC<
  InteractiveElementProps<DesignElementTextPlain>
> = ({ elementRef, title, sceneWidth, sceneHeight }) => {
  const { inverseScale } = useCamera()
  const element = useElementRef(elementRef)
  const highlightInvalidElements = useMainView(
    'edit',
    view => view.highlightInvalidElements,
  )

  const activeKeyboardElementRef = useActiveKeyboardElementRef()
  const isEditing = elementRef.id === activeKeyboardElementRef?.id
  const { maxCharacters } = element.editableText
  const { text } = element.customisations
  const isInvalid = highlightInvalidElements && !isElementValid(element).valid

  return (
    <InteractiveElementCommon
      indicator="TEXT"
      border="ALWAYS"
      title={title}
      sceneId={elementRef.sceneId}
      sceneWidth={sceneWidth}
      sceneHeight={sceneHeight}
      element={element}
      isInvalid={isInvalid}
    >
      {isEditing && maxCharacters !== null && (
        <CharacterCount
          scale={inverseScale}
          width={element.width}
          height={element.height}
          charactersUsed={text.length}
          characterLimit={maxCharacters}
        />
      )}
    </InteractiveElementCommon>
  )
}
