import { DesignLayout } from '@moonpig/web-personalise-editor-types'
import { buildLayoutCardLandscape } from './layoutCardLandscape'
import { buildLayoutCardPortrait } from './layoutCardPortrait'
import { buildLayoutFallback } from './layoutFallback'
import { Layout, LayoutSceneInput } from './types'

export const buildLayout = (
  layout: DesignLayout,
  scenes: LayoutSceneInput[],
): Layout => {
  switch (layout) {
    case 'CARD_PORTRAIT': {
      return buildLayoutCardPortrait(scenes, 'RECTANGULAR')
    }
    case 'CARD_SQUARE': {
      return buildLayoutCardPortrait(scenes, 'SQUARE')
    }
    case 'CARD_LANDSCAPE': {
      return buildLayoutCardLandscape(scenes)
    }
    default: {
      return buildLayoutFallback(scenes)
    }
  }
}
