import {
  Design,
  EditorEventAppliedStickerElement,
  EditorEventEditableImageElement,
  EditorEventEditableTextElement,
  EditorEventState,
  LoadedDesignState,
} from '@moonpig/web-personalise-editor-types'
import { Photos } from '../types'

export const createEditorEventState = ({
  design,
  designState,
  photos,
  selectedSceneId,
}: {
  design: Design
  designState: LoadedDesignState
  photos: Photos
  selectedSceneId: string
}): EditorEventState => {
  const variantKey =
    'variantKey' in designState && designState.variantKey !== undefined
      ? designState.variantKey
      : null

  let videoApplied

  const editableTextElements: EditorEventEditableTextElement[] = []
  const editableImageElements: EditorEventEditableImageElement[] = []
  const stickersApplied: EditorEventAppliedStickerElement[] = []

  design.sceneIds.forEach((sceneId, sceneIndex) => {
    const scene = design.sceneById[sceneId]
    let nextTextElementIndex = 0
    let nextImageElementIndex = 0

    scene.elementIds.forEach(elementId => {
      const element = scene.elementById[elementId]
      switch (element.type) {
        case 'text-styled':
        case 'overlay-text': {
          editableTextElements.push({
            id: element.id,
            sceneIndex,
            elementIndex: nextTextElementIndex,
            textLength: element.customisations.text.length,
            textModified:
              element.customisations.text !==
              (element.type === 'text-styled'
                ? element.editableText.defaultText
                : ''),
            colorId: element.customisations.colorId,
            fontFaceId: element.customisations.fontFaceId,
            fontSize: element.customisations.fontSize,
            horizontalAlignment: element.customisations.horizontalAlignment,
            verticalAlignment: element.customisations.verticalAlignment,
            starterMessageId:
              element.type === 'text-styled' ? element.starterMessageId : null,
            flexibleText: element.type === 'overlay-text',
            x: element.x,
            y: element.y,
            rotation: element.rotation,
            height: element.height,
            width: element.width,
          })

          nextTextElementIndex += 1

          break
        }
        case 'sticker': {
          const { id: stickerId } = element.sticker
          stickersApplied.push({
            stickerId,
          })
          break
        }
        case 'overlay-image': {
          editableImageElements.push({
            id: element.id,
            sceneIndex,
            elementIndex: nextImageElementIndex,
            rotation: element.rotation,
            assignedImage: true,
            flexibleImage: true,
            x: element.x,
            y: element.y,
            height: element.height,
            width: element.width,
            zoom: false,
            repositioned: false,
          })
          nextImageElementIndex += 1
          break
        }
        case 'media': {
          if (element.kind === 'video') videoApplied = true

          break
        }
      }
    })
  })

  return {
    productKey: design.productKey,
    variantKey,
    productUpdated: design.modified,
    sceneIndex: design.sceneIds.indexOf(selectedSceneId),
    sceneTitle: design.sceneById[selectedSceneId].title,
    editableTextElements,
    editableImageElements,
    photoLibraryCount: photos.photoIds.length,
    designId: design.id,
    stickersApplied,
    videoApplied,
  }
}
