import { ActionCreator } from '../../types'

export const createDeselectElement: ActionCreator<'deselectElement'> =
  ({ set }) =>
  async () => {
    set(({ view }) => {
      if (view.type !== 'main' || view.view.type !== 'edit') {
        return { view }
      }

      return {
        view: {
          ...view,
          view: {
            ...view.view,
            activeElementId: null,
            ui: null,
          },
        },
      }
    })
  }
