import { Box } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { colorValue } from '@moonpig/launchpad-theme'
import { breakpoint, styled } from '@moonpig/launchpad-utils'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import React, { FC } from 'react'
import { useIsBreakpoint } from '../../utils'
import { Drawer, DrawerStopPosition } from '../Drawer'
import { MenuHeader } from '../MenuHeader'

type ModalProps = {
  heading: string
  closeLabel: string
  onClose: () => void
}

type ModalContentProps = ModalProps & {
  heading: string
  closeLabel: string
  onClose: () => void
}

const DESKTOP_BP = 'md'
const HEADING_ID = 'mp-ed-modal-heading'

const SyledDialogOverlay = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  ${breakpoint(DESKTOP_BP)} {
    background: rgba(0, 0, 0, 0.6);
    justify-content: center;
  }
`

const StyledDialogContent = styled(DialogContent)`
  position: relative;
  ${s({
    px: 6,
    pb: 6,
    width: '100%',
    minWidth: 'none',
  })}

  ${breakpoint(DESKTOP_BP)} {
    ${s({
      px: 10,
      pb: 10,
      width: 'auto',
      minWidth: '440px',
    })}
  }
`

const StyledFloatingModal = styled.div`
  background: ${colorValue('colorBackground01')};
  box-shadow: rgb(0 32 77 / 15%) 0px -3px 8px 0px;
  border-radius: 8px;
  min-width: 480px;
  ${s({ pt: 8 })}
`

const ModalContent: FC<ModalContentProps> = ({
  heading,
  closeLabel,
  onClose,
  children,
}) => {
  return (
    <StyledDialogContent aria-labelledby={HEADING_ID}>
      <MenuHeader
        heading={heading}
        headingId={HEADING_ID}
        closeLabel={closeLabel}
        onClose={onClose}
      />
      <Box pt={6}>{children}</Box>
    </StyledDialogContent>
  )
}

export const Modal: FC<ModalProps> = props => {
  const isDesktop = useIsBreakpoint(DESKTOP_BP)

  const content = <ModalContent {...props} />

  const stopPositions: DrawerStopPosition[] = ['auto']

  const { onClose } = props
  return (
    <SyledDialogOverlay onDismiss={onClose}>
      {isDesktop ? (
        <StyledFloatingModal>{content}</StyledFloatingModal>
      ) : (
        <Drawer stopPositions={stopPositions} onClose={onClose}>
          {content}
        </Drawer>
      )}
    </SyledDialogOverlay>
  )
}
