import { Vec2, radToDeg } from '@moonpig/common-math'
import { styled, useTheme } from '@moonpig/launchpad-utils'
import { Transform, useHandle } from '@moonpig/use-transform'
import React from 'react'
import { BORDER_WIDTH, MIN_TOUCH_SIZE, RESIZE_HANDLE_SIZE } from '../constants'

const buildControlAnchorCursor = (
  elementWidth: number,
  elementHeight: number,
  corner: Corner,
): [Vec2, Vec2, string] => {
  const hw = elementWidth * 0.5
  const hh = elementHeight * 0.5
  switch (corner) {
    case 'top-left':
      return [[-hw, -hh], [hw, hh], 'nwse-resize']
    case 'top-right':
      return [[hw, -hh], [-hw, hh], 'nesw-resize']
    case 'bottom-left':
      return [[-hw, hh], [hw, -hh], 'nesw-resize']
    case 'bottom-right':
      return [[hw, hh], [-hw, -hh], 'nwse-resize']
  }
}

const StyledLink = styled.a<{ borderColor: string }>`
  -webkit-touch-callout: none;
  -webkit-user-drag: none;
  outline: none;
  pointer-events: all;

  .bg {
    fill: white;
  }

  &:hover {
    .bg {
      fill: ${({ borderColor }) => borderColor};
    }
  }
`

type Corner = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

export const ResizeHandle = ({
  id,
  transform,
  disabled,
  elementWidth,
  elementHeight,
  transformPoint,
  screenScale,
  corner,
}: {
  id: string
  transform: Transform
  disabled: boolean
  elementWidth: number
  elementHeight: number
  transformPoint?: (point: Vec2) => Vec2
  screenScale: number
  corner: Corner
}) => {
  const [control, anchor, cursor] = buildControlAnchorCursor(
    elementWidth,
    elementHeight,
    corner,
  )

  const [, bind] = useHandle({
    options: {
      enableScale: true,
      enableRotation: false,
      transformPoint,
    },
    transform,
    control,
    anchor,
  })

  const touchSize = MIN_TOUCH_SIZE * screenScale
  const halfTouchSize = touchSize * 0.5
  const visibleSize = RESIZE_HANDLE_SIZE * screenScale
  const halfVisibleSize = visibleSize * 0.5
  const borderSize = BORDER_WIDTH * screenScale

  const { palette } = useTheme()
  const { colorFeedbackInformation } = palette
  const borderColor = colorFeedbackInformation

  const svgTransform = [
    `translate(${transform.value.position[0]}, ${transform.value.position[1]})`,
    `rotate(${radToDeg(transform.value.rotation)})`,
    `translate(${control[0] * transform.value.scale[0]} ${
      control[1] * transform.value.scale[1]
    })`,
  ].join(' ')

  return !disabled ? (
    <StyledLink
      {...bind()}
      onClick={
        /* istanbul ignore next */ e => {
          e.stopPropagation()
          e.preventDefault()
        }
      }
      aria-hidden
      tabIndex={-1}
      style={{
        cursor,
      }}
      href="#"
      borderColor={borderColor}
      data-testid={`mp-ed-handle-resize-${corner}-${id}`}
    >
      <g transform={svgTransform}>
        <rect
          x={-halfTouchSize}
          y={-halfTouchSize}
          width={touchSize}
          height={touchSize}
          fill="transparent"
        />
        <rect
          x={-halfVisibleSize}
          y={-halfVisibleSize}
          width={visibleSize}
          height={visibleSize}
          fill={borderColor}
        />
        <rect
          className="bg"
          x={-(halfVisibleSize - borderSize)}
          y={-(halfVisibleSize - borderSize)}
          width={visibleSize - 2 * borderSize}
          height={visibleSize - 2 * borderSize}
        />
      </g>
    </StyledLink>
  ) : null
}
