import {
  Transform2d,
  Vec2,
  rectTranslationToIntersectPoint,
  vec2Add,
} from '@moonpig/common-math'
import {
  TransformContext,
  useContacts,
  useKeyboard,
  usePointer,
  useTransform,
  useWheel,
} from '@moonpig/use-transform'
import {
  DesignElementImageUpload,
  DesignImageTransform,
} from '@moonpig/web-personalise-editor-types'
import React, {
  FC,
  MouseEvent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useCamera } from '../../../../../../../camera'
import { MAX_SCALE_PHOTO } from '../../../../../../../constants'
import { assert } from '../../../../../../../utils/assert'
import { getInitialScale } from '../../../../../../../utils/getInitialScale'
import { isKeyboardTransformDisabled } from '../../../../../../../utils/isKeyboardTransformDisabled'
import { useDisablePageZoom } from '../../../../../../../utils/useDisablePageZoom'
import {
  getGestureChangeType,
  useAction,
  useMainView,
  useView,
} from '../../../../../../../store'
import { useLayout } from '../../../../../selectors'

const ImageManipulationSurface: FC<{
  designTransform: DesignImageTransform
  updateDesignTransform: (
    transform: DesignImageTransform,
    context: TransformContext,
  ) => void
  initialScale: number
  worldX: number
  worldY: number
  imageWidth: number
  imageHeight: number
}> = ({
  designTransform,
  updateDesignTransform,
  initialScale,
  worldX,
  worldY,
  imageWidth,
  imageHeight,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { toWorldPoint, screenBounds } = useCamera()
  const [offset, setOffset] = useState<Vec2>([0, 0])

  useDisablePageZoom(containerRef)

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const bounds = containerRef.current!.getBoundingClientRect()

    setOffset([bounds.left, bounds.top])
  }, [screenBounds])

  const transformPoint = useCallback(
    (screenPoint: Vec2): Vec2 => {
      const screenX = screenPoint[0] - screenBounds.x - offset[0]
      const screenY = screenPoint[1] - screenBounds.y - offset[1]

      return toWorldPoint([screenX, screenY])
    },
    [offset, screenBounds.x, screenBounds.y, toWorldPoint],
  )

  const updateTransform = useCallback(
    (newTransform: Transform2d, context: TransformContext) => {
      updateDesignTransform(
        {
          position: {
            x: newTransform.position[0],
            y: newTransform.position[1],
          },
          rotation: newTransform.rotation,
          scale: newTransform.scale[0],
        },
        context,
      )
    },
    [updateDesignTransform],
  )

  const setTransform = useCallback(
    (newTransform: Transform2d, context: TransformContext) => {
      const localTransform: Transform2d = {
        ...newTransform,
        position: [
          newTransform.position[0] - worldX,
          newTransform.position[1] - worldY,
        ],
      }

      const translate = rectTranslationToIntersectPoint(
        [0, 0],
        localTransform,
        {
          x: -imageWidth * 0.5,
          y: -imageHeight * 0.5,
          width: imageWidth,
          height: imageHeight,
        },
      )
      const position = vec2Add(localTransform.position, translate)

      updateTransform({ ...localTransform, position }, context)
    },
    [imageHeight, imageWidth, updateTransform, worldX, worldY],
  )

  const transform = useTransform({
    value: {
      ...designTransform,
      scale: [designTransform.scale, designTransform.scale],
      position: [
        designTransform.position.x + worldX,
        designTransform.position.y + worldY,
      ],
    },
    setValue: setTransform,
    options: {
      minScale: initialScale,
      maxScale: initialScale * MAX_SCALE_PHOTO,
      uniformScale: true,
    },
  })

  useKeyboard({
    transform,
    disabled: isKeyboardTransformDisabled,
    options: { increment: 1 },
  })

  const bindWheel = useWheel({
    options: {
      sensitivity: 0.001,
      transformPoint,
    },
    transform,
  })

  const onContactsChange = useContacts({
    options: { rotationSnapOut: 12, rotationSnapIn: 2 },
    transform,
  })

  const bindPointer = usePointer(onContactsChange, {
    transformPoint,
  })

  const handleClick = useCallback(
    /* istanbul ignore next */ (event: MouseEvent) => {
      event.stopPropagation()
    },
    [],
  )

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      ref={containerRef}
      data-testid="ed-image-manipulation"
      style={{
        cursor: transform.isLocked ? 'grabbing' : 'grab',
        position: 'absolute',
        touchAction: 'none',
        width: '100%',
        height: '100%',
        pointerEvents: 'all',
      }}
      onClick={handleClick}
      {...bindPointer()}
      {...bindWheel()}
    />
  )
}

const ImageManipulationSourceImage: FC<{
  element: DesignElementImageUpload
}> = ({ element }) => {
  const updateElementRef = useAction('updateElementRef')
  const layout = useLayout()

  const { sourceImage } = element.customisations

  assert(sourceImage !== null)

  const photo = useView('main', ({ photos }) => {
    const photoState = photos.photoById[sourceImage.id]

    assert(photoState.type === 'loaded')

    return photoState.photo
  })

  const initialScale = getInitialScale(
    photo.originalImage.width,
    photo.originalImage.height,
    element.width,
    element.height,
  )

  const sceneLayout = layout.sceneById[element.sceneId]
  const worldX = sceneLayout.x + element.x + element.width * 0.5
  const worldY = sceneLayout.y + element.y + element.height * 0.5

  const imageWidth = photo.originalImage.width
  const imageHeight = photo.originalImage.height

  const updateElementTransform = useCallback(
    (newTransform: DesignImageTransform, context: TransformContext) => {
      updateElementRef<DesignElementImageUpload>(
        element,
        current => {
          return {
            ...current,
            customisations: {
              ...current.customisations,
              pendingEditedImage: null,
              editedImage: null,
              sourceImage: {
                ...sourceImage,
                transform: newTransform,
              },
            },
          }
        },
        { changeType: getGestureChangeType(context) },
      )
    },
    [element, sourceImage, updateElementRef],
  )

  return (
    <ImageManipulationSurface
      designTransform={sourceImage.transform}
      updateDesignTransform={updateElementTransform}
      worldX={worldX}
      worldY={worldY}
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      initialScale={initialScale}
    />
  )
}

const ImageManipulationMissingImage: FC = () => {
  const notify = useAction('notify')

  const updateDesignTransform = useCallback(() => {
    notify({
      type: 'photo-missing',
      elementType: 'image-upload',
    })
  }, [notify])

  const designTransform = useMemo<DesignImageTransform>(
    () => ({
      position: { x: 0, y: 0 },
      rotation: 0,
      scale: 1,
    }),
    [],
  )

  return (
    <ImageManipulationSurface
      designTransform={designTransform}
      updateDesignTransform={updateDesignTransform}
      worldX={0}
      worldY={0}
      imageWidth={0}
      imageHeight={0}
      initialScale={1}
    />
  )
}

export const ImageManipulation: FC = () => {
  const selectedImageElement = useMainView(
    'edit',
    (view, { design, selectedSceneId }) => {
      if (!view.activeElementId) {
        return null
      }
      const element =
        design.sceneById[selectedSceneId].elementById[view.activeElementId]
      if (element.type !== 'image-upload') {
        return null
      }
      return element
    },
  )

  if (!selectedImageElement) {
    return null
  }

  if (!selectedImageElement.customisations.sourceImage) {
    if (selectedImageElement.customisations.editedImage) {
      return <ImageManipulationMissingImage />
    }
    return null
  }

  return <ImageManipulationSourceImage element={selectedImageElement} />
}
