import {
  Flex,
  Heading,
  PrimaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { TextInput } from '@moonpig/launchpad-forms'
import React, { FC, useCallback, useState } from 'react'
import { BrandLogo } from '@moonpig/launchpad-assets'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { useAction } from '../../store'

const LOGO_WIDTH_PX = 96

const StyledBrandLogo = styled.div`
  width: ${LOGO_WIDTH_PX}px;
  ${s({ color: 'colorPrimary01' })}
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 64px;

  svg {
    display: block;
  }
`
export const ShareJoin: FC = () => {
  const [name, setName] = useState('')
  const load = useAction('load')

  const handleJoin = useCallback(() => {
    localStorage.setItem('mp-ed-collab-name', name || 'Anonymous')
    load()
  }, [load, name])

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      position="fixed"
      top="0"
      right="0"
      bottom="0"
      left="0"
      p={6}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxWidth="400px"
        flex="1"
      >
        <Flex flexDirection="column">
          <Heading level="h1">Welcome!</Heading>
          <Text>
            You have been invited to edit a design with others. Enter your name
            to let them see who is editing.
          </Text>
          <Flex mt={4} flexDirection="column" width="100%">
            <TextInput
              label="Your Name (optional)"
              name=""
              value={name}
              placeholder="Anonymous"
              onChange={event => {
                setName(event.currentTarget.value)
              }}
            />
            <PrimaryButton mt={6} onClick={handleJoin}>
              Join
            </PrimaryButton>
          </Flex>
        </Flex>
      </Flex>
      <StyledBrandLogo>
        <BrandLogo />
      </StyledBrandLogo>
    </Flex>
  )
}
