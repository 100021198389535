type Duration =
  | {
      type: 'seconds'
      seconds: number
    }
  | {
      type: 'minutes-seconds'
      minutes: number
      seconds: number
    }

export const formatDuration = (durationInSeconds: number): Duration => {
  const wholeSeconds = Math.round(durationInSeconds)
  const minutes = Math.floor(wholeSeconds / 60)
  const seconds = wholeSeconds - minutes * 60

  if (minutes > 0) {
    return { type: 'minutes-seconds', minutes, seconds }
  }

  return { type: 'seconds', seconds }
}
