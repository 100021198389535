import { colorValue } from '@moonpig/launchpad-theme'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { ContentPicker } from '../ContentPicker'
import { LayoutGridIcon } from './LayoutGridIcon'
import { useLocaleText } from './LayoutPicker.locale'
import { Layout } from './types'

const StyledItemContainer = styled.div`
  ${s({ p: 3 })}

  border: 1px solid ${colorValue('colorBorder01')};
  border-radius: inherit;
`

type LayoutPickerProps = {
  labelledById?: string
  onSelect: (id: string) => void
  layouts: Layout[]
}

export const LayoutPicker: FC<LayoutPickerProps> = ({
  layouts,
  labelledById,
  onSelect,
}) => {
  const t = useLocaleText()

  return (
    <ContentPicker
      labelledById={labelledById}
      onSelectItem={onSelect}
      columnWidth={{ xs: 1 / 3, md: 1 / 5, lg: 1 / 2 }}
      items={layouts.map((layout, index) => {
        return {
          type: 'loaded',
          id: layout.id,
          selected: layout.selected,
          selectLabel: t('apply_layout', String(index + 1)),
          content: (
            <StyledItemContainer>
              <LayoutGridIcon
                elements={layout.elements}
                width={layout.width}
                height={layout.height}
              />
            </StyledItemContainer>
          ),
        }
      })}
    />
  )
}
