import { Flex } from '@moonpig/launchpad-components'
import { Minimap, PageLayout } from '@moonpig/web-personalise-components'
import React, { FC, useCallback, useMemo, useRef, useState } from 'react'
import { animated } from 'react-spring'
import { Camera, useCamera } from '../../../../../camera'
import { SHARED_ANIMATION_CONFIG } from '../../../../../constants'
import { useCameraPad } from '../../../../../utils/useCameraPad'
import { Layout, LayoutImage, buildLayout } from '../../../../../layout'
import { useView } from '../../../../../store'
import { Notifications } from '../../../components/Notifications'
import { usePreviewImages } from '../../selectors'
import { OnLoaded } from '../../types'
import { Header } from '../Header'

type NavProps = {
  layout: Layout
  selectedSceneId: string
  onSelectScene: (sceneId: string) => void
}

const Nav: FC<NavProps> = ({ layout, selectedSceneId, onSelectScene }) => {
  const previewImages = usePreviewImages()

  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Minimap
        scenes={Object.keys(layout.sceneById).map((sceneId, index) => {
          const sceneLayout = layout.sceneById[sceneId]
          const previewImage = previewImages[index]

          return {
            sceneId,
            title: previewImage.title,
            bounds: sceneLayout,
            content: (
              <img
                src={previewImage.url}
                alt=""
                width={previewImage.width}
                height={previewImage.height}
                style={{ width: '100%', height: 'auto' }}
              />
            ),
          }
        })}
        selectedSceneId={selectedSceneId}
        onSelectScene={onSelectScene}
      />
    </Flex>
  )
}

const Images: FC<{ images: LayoutImage[] }> = ({ images }) => {
  return (
    <>
      {images.map(image => {
        const { id, url, x, y, width, height } = image
        return (
          <img
            key={id}
            src={url}
            alt=""
            style={{ position: 'absolute', left: x, top: y, width, height }}
          />
        )
      })}
    </>
  )
}

type PreviewProps = {
  layout: Layout
  onLoaded: OnLoaded
}

const Preview: FC<PreviewProps> = ({ layout, onLoaded }) => {
  const previewImages = usePreviewImages()
  const { animation } = useCamera()
  const sceneIds = Object.keys(layout.sceneById)
  const readyCountRef = useRef(0)

  const handleLoad = useCallback(() => {
    readyCountRef.current += 1
    if (readyCountRef.current === sceneIds.length) {
      onLoaded('success')
    }
  }, [onLoaded, sceneIds.length])

  const handleError = useCallback(() => {
    onLoaded('error')
  }, [onLoaded])

  return (
    <animated.div
      style={{
        position: 'absolute',
        zIndex: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transform: animation.transform,
        transformOrigin: '0px 0px',
        pointerEvents: 'none',
      }}
      data-testid="mp-editor-preview"
    >
      <Images images={layout.images.background} />
      {sceneIds.map((sceneId, index) => {
        const scene = layout.sceneById[sceneId]
        const previewImage = previewImages[index]
        return (
          <div
            key={sceneId}
            style={{
              position: 'absolute',
              left: scene.x,
              top: scene.y,
              width: scene.width,
              height: scene.height,
            }}
          >
            <img
              src={previewImage.url}
              alt={previewImage.title}
              width={previewImage.width}
              height={previewImage.height}
              style={{ width: '100%', height: 'auto' }}
              onLoad={handleLoad}
              onError={handleError}
            />
          </div>
        )
      })}
      <Images images={layout.images.foreground} />
    </animated.div>
  )
}

type MainProps = {
  layout: Layout
  selectedSceneId: string
  onSelectScene: (sceneId: string) => void
  onLoaded: OnLoaded
}

const Main: FC<MainProps> = ({
  layout,
  selectedSceneId,
  onSelectScene,
  onLoaded,
}) => {
  const pad = useCameraPad()

  const selectedSceneLayout = layout.sceneById[selectedSceneId]
  const focus = {
    x: selectedSceneLayout.x,
    y: selectedSceneLayout.y,
    width: selectedSceneLayout.width,
    height: selectedSceneLayout.height,
  }

  return (
    <Camera
      pad={pad}
      animationConfig={SHARED_ANIMATION_CONFIG}
      focus={focus}
      node={selectedSceneLayout}
      onNodeChange={onSelectScene}
    >
      <Preview layout={layout} onLoaded={onLoaded} />
    </Camera>
  )
}

type PreviewDefaultProps = {
  onLoaded: OnLoaded
}

export const PreviewDefault: FC<PreviewDefaultProps> = ({ onLoaded }) => {
  const previewImages = usePreviewImages()
  const designLayout = useView('main', view => view.design.layout)

  const layout = useMemo(
    () =>
      buildLayout(
        designLayout,
        previewImages.map((previewImage, index) => {
          return {
            id: `preview-${index}`,
            width: previewImage.width,
            height: previewImage.height,
          }
        }),
      ),
    [designLayout, previewImages],
  )

  const [selectedSceneId, setSelectedSceneId] = useState(
    Object.keys(layout.sceneById)[0],
  )

  return (
    <PageLayout
      header={<Header />}
      nav={
        <Nav
          layout={layout}
          selectedSceneId={selectedSceneId}
          onSelectScene={setSelectedSceneId}
        />
      }
      main={
        <Main
          layout={layout}
          selectedSceneId={selectedSceneId}
          onSelectScene={setSelectedSceneId}
          onLoaded={onLoaded}
        />
      }
      notifications={<Notifications />}
    />
  )
}
