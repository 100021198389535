import { useMemo } from 'react'
import { CameraUI } from '../../../camera'
import {
  UI_HEIGHT_TOP,
  UI_WIDTH_CLOSED_LAYOUT_LEFT,
  UI_WIDTH_OPEN_LAYOUT_LEFT,
} from '../../../constants'

import { useCameraPad } from '../../../utils/useCameraPad'

import { useIsDesktop } from '../../../utils/useIsDesktop'
import { useUI } from './selectors'

type UseCameraUIResult = [CameraUI | undefined, number]

export const useCameraUI = (): UseCameraUIResult => {
  const ui = useUI()
  const isDesktop = useIsDesktop()
  const pad = useCameraPad()

  const result = useMemo<UseCameraUIResult>(() => {
    if (!ui) return [undefined, pad]

    const direction = isDesktop ? 'left' : 'top'
    const widthIfLeft =
      ui.selectedMenuItem && ui.selectedMenuItem !== 'keyboard'
        ? UI_WIDTH_OPEN_LAYOUT_LEFT
        : UI_WIDTH_CLOSED_LAYOUT_LEFT
    const size = direction === 'left' ? widthIfLeft : UI_HEIGHT_TOP

    return [
      {
        direction,
        size,
      },
      pad,
    ]
  }, [isDesktop, pad, ui])

  return result
}
