import {
  DesignElementSticker,
  DesignSticker,
} from '@moonpig/web-personalise-editor-types'
import { v4 as uuid } from 'uuid'
import { getDefaultStickerSize } from '../../../../../../../../utils/getDefaultStickerSize'

export const createStickerElement = (input: {
  sceneId: string
  sceneWidth: number
  sceneHeight: number
  sticker: DesignSticker
  offset: number
}): DesignElementSticker => {
  const [maxSize] = getDefaultStickerSize(input.sceneWidth, input.sceneHeight)
  const x = input.offset + (input.sceneWidth - maxSize) * 0.5
  const y = input.offset + (input.sceneHeight - maxSize) * 0.5

  return {
    id: uuid(),
    type: 'sticker',
    width: maxSize,
    height: maxSize,
    x,
    y,
    sceneId: input.sceneId,
    rotation: 0,
    sticker: input.sticker,
  }
}
