import React, { FC } from 'react'

export const IconAdjust: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 24 24" width={width} height={height}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m12 0-1.414 1.414L7 5l1.414 1.414L11 3.828V11H3.828l2.586-2.586L5 7l-3.586 3.586L0 12l1.414 1.414L5 17l1.414-1.414L3.828 13H11v7.172l-2.586-2.586L7 19l3.586 3.586L11 23l1 1 1-1 .414-.414L17 19l-1.414-1.414L13 20.172V13h7.172l-2.586 2.586L19 17l3.586-3.586L23 13l1-1-1-1-.414-.414L19 7l-1.414 1.414L20.172 11H13V3.828l2.586 2.586L17 5l-3.586-3.586L12 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
