import { DesignElementImageUpload } from '@moonpig/web-personalise-editor-types'
import React, { FC } from 'react'
import { isElementValid } from '../../../../../../../utils/isElementValid'
import { useMainView } from '../../../../../../../store'
import { useElementRef } from '../../../../../selectors'
import { InteractiveElementProps } from '../../types'
import { InteractiveElementCommon } from '../InteractiveElementCommon'

export const ElementImageUploadInteractive: FC<
  InteractiveElementProps<DesignElementImageUpload>
> = ({ elementRef, title, sceneWidth, sceneHeight }) => {
  const element = useElementRef(elementRef)
  const highlightInvalidElements = useMainView(
    'edit',
    view => view.highlightInvalidElements,
  )

  const isDefault = !element.customisations.sourceImage
  const isInvalid = highlightInvalidElements && !isElementValid(element).valid
  const activeElementId = useMainView('edit', view => view.activeElementId)
  const isSelected = activeElementId === element.id

  if (!element.editable) {
    return null
  }

  return (
    <InteractiveElementCommon
      indicator={isDefault ? 'PHOTO' : 'NONE'}
      interactionNone={isSelected}
      title={title}
      sceneId={elementRef.sceneId}
      sceneWidth={sceneWidth}
      sceneHeight={sceneHeight}
      element={element}
      isInvalid={isInvalid}
    />
  )
}
