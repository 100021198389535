import {
  IconAdjust,
  IconChevronDown,
  IconChevronUp,
  IconDelete,
  IconPhotos,
} from '@moonpig/web-personalise-components'
import React, { useCallback, useMemo } from 'react'
import shallow from 'zustand/shallow'
import { assert } from '../../../../../../../utils/assert'
import {
  UIOverlayImage,
  UIOverlayImageMenuItem,
  useAction,
  useView,
} from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { useDeleteElement } from '../../../../useDeleteElement'
import { ToolbarItem, ToolbarMenu, ToolbarOrientation } from '../Toolbar'
import { ToolbarCommon } from '../ToolbarCommon'
import { getLayerControlsState } from '../utils/elementLayerState'
import { Adjust } from './Adjust'
import { OverlayImagePhotoLibrary } from './OverlayImagePhotoLibrary'

type ToolbarOverlayImageProps = {
  onDone: () => void
  orientation: ToolbarOrientation
  ui: UIOverlayImage
}

export const ToolbarOverlayImage = ({
  onDone,
  orientation,
  ui,
}: ToolbarOverlayImageProps) => {
  const { selectedMenuItem } = ui

  const setUI = useAction('setUI')
  const deleteElement = useDeleteElement()

  const t = useEditorLocaleText()
  const reorderElement = useAction('reorderElement')

  const { forwardButtonDisabled, backwardButtonDisabled } = useView(
    'main',
    ({ design }) => getLayerControlsState(ui.elementRef, design),
    shallow,
  )

  const items = useMemo<ToolbarItem<UIOverlayImageMenuItem>[]>(() => {
    return [
      {
        type: 'menu',
        id: 'photo-library',
        label: t('toolbar_image_photos_label'),
        ariaLabel: t('toolbar_add_photo'),
        icon: <IconPhotos />,
        disabled: false,
      },
      {
        type: 'menu',
        id: 'adjust',
        label: t('toolbar_image_adjust_label'),
        ariaLabel: t('toolbar_adjust'),
        icon: <IconAdjust />,
        disabled: !ui.elementRef,
      },
      {
        type: 'button',
        id: 'forward',
        label: t('toolbar_move_to_front_label'),
        ariaLabel: t('toolbar_photo_move_to_front_aria_label'),
        icon: <IconChevronUp />,
        disabled: forwardButtonDisabled,
      },
      {
        type: 'button',
        id: 'backward',
        label: t('toolbar_move_to_back_label'),
        ariaLabel: t('toolbar_photo_move_to_back_aria_label'),
        icon: <IconChevronDown />,
        disabled: backwardButtonDisabled,
      },
      {
        type: 'menu',
        id: 'delete',
        label: t('delete_element_label'),
        ariaLabel: t('toolbar_photo_delete_aria_label'),
        icon: <IconDelete />,
        disabled: !ui.elementRef,
      },
    ]
  }, [backwardButtonDisabled, forwardButtonDisabled, t, ui.elementRef])

  const photoLibraryMenu = useMemo<ToolbarMenu>(() => {
    const titleId = `mp-menu-heading-overlay-photo-library`

    return {
      titleId,
      title: t('toolbar_menu_choose_photo_label'),
      content: (
        <OverlayImagePhotoLibrary labelledById={titleId} sceneId={ui.sceneId} />
      ),
      closeLabel: t('toolbar_menu_close_photo_library_label'),
    }
  }, [t, ui.sceneId])

  const menu = useMemo<ToolbarMenu | undefined>(() => {
    switch (selectedMenuItem) {
      case 'photo-library': {
        return photoLibraryMenu
      }
      case 'adjust': {
        assert(ui.elementRef !== null)

        return {
          title: t('toolbar_menu_adjust_photo_label'),
          content: <Adjust elementRef={ui.elementRef} />,
          closeLabel: t('toolbar_menu_close_adjust_label'),
          drawerHeight: 'fit',
        }
      }
    }
  }, [photoLibraryMenu, selectedMenuItem, t, ui.elementRef])

  const handleSelectItem = useCallback(
    (itemId: UIOverlayImageMenuItem | null) => {
      setUI(current => {
        assert(current !== null && current.type === 'overlay-image')
        switch (itemId) {
          case 'delete': {
            assert(current.elementRef !== null)
            deleteElement(current.elementRef)
            return null
          }
          case 'forward': {
            const { elementRef } = current

            assert(elementRef !== null)

            reorderElement({ elementRef, direction: 'to-front' })

            return {
              ...current,
              selectedMenuItem: null,
            }
          }
          case 'backward': {
            const { elementRef } = current

            assert(elementRef !== null)

            reorderElement({ elementRef, direction: 'to-back' })

            return {
              ...current,
              selectedMenuItem: null,
            }
          }
          default: {
            return {
              type: 'overlay-image',
              sceneId: current.sceneId,
              elementRef: current.elementRef,
              selectedMenuItem: itemId,
            }
          }
        }
      })
    },
    [setUI, deleteElement, reorderElement],
  )

  return (
    <ToolbarCommon
      label={t('toolbar_image_toolbar_title')}
      orientation={orientation}
      items={items}
      selectedItemId={selectedMenuItem}
      onSelectItem={handleSelectItem}
      menu={menu}
      done={{
        label: t('toolbar_done_button'),
        ariaLabel: t('toolbar_image_toolbar_close_label'),
        onDone,
      }}
    />
  )
}
