import { styled } from '@moonpig/launchpad-utils'
import {
  Toolbar as ToolbarBase,
  useFocusOn,
} from '@moonpig/web-personalise-components'
import React, { useCallback } from 'react'
import { FOCUS_ID_TOOLBAR, TOOLBAR_ID } from '../../../../../../../constants'
import { MenuBottom, MenuLeft } from './AnimatedMenu'
import { ToolbarBottom, ToolbarLeft } from './AnimatedToolbar'
import {
  ToolbarItem,
  ToolbarMenu,
  ToolbarOrientation,
  ToolbarTooltip,
} from './types'

const StyledToolbarContainer = styled.div`
  flex-direction: row-reverse;
  position: relative;
`

export type ToolbarProps<TItemId> = {
  controlId?: string
  label: string
  orientation: ToolbarOrientation
  items: ToolbarItem<TItemId>[]
  selectedItemId: string | null
  onSelectItem: (itemId: TItemId | null) => void
  menu?: ToolbarMenu
  done?: {
    label: string
    ariaLabel: string
    onDone: () => void
  }
  tooltip?: ToolbarTooltip
  carouselViewed: boolean
  handleCarouselViewed: () => void
}

export const Toolbar = <TItemId extends string>({
  controlId,
  label,
  orientation,
  items,
  selectedItemId,
  onSelectItem,
  menu,
  done,
  tooltip,
  carouselViewed,
  handleCarouselViewed,
}: ToolbarProps<TItemId>) => {
  const focusOn = useFocusOn()

  const handleCloseMenu = useCallback(() => {
    focusOn(FOCUS_ID_TOOLBAR)
    onSelectItem(null)
  }, [focusOn, onSelectItem])

  const AnimatedMenu = orientation === 'horizontal' ? MenuBottom : MenuLeft

  const AnimatedToolbar =
    orientation === 'horizontal' ? ToolbarBottom : ToolbarLeft

  return (
    <StyledToolbarContainer>
      <AnimatedToolbar>
        <ToolbarBase
          id={TOOLBAR_ID}
          focusId={FOCUS_ID_TOOLBAR}
          controlId={controlId}
          label={label}
          orientation={orientation}
          items={items}
          selectedItemId={selectedItemId}
          onSelectItem={onSelectItem}
          done={done}
          tooltip={tooltip}
          carouselViewed={carouselViewed}
          handleCarouselViewed={handleCarouselViewed}
        />
      </AnimatedToolbar>
      <AnimatedMenu
        id={selectedItemId ?? undefined}
        menu={menu}
        onClose={handleCloseMenu}
        drawerHeight={menu?.drawerHeight}
      />
    </StyledToolbarContainer>
  )
}
