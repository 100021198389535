import { lerp, modNeg } from '@moonpig/common-math'
import { MAX_OPEN_DEGREES } from './constants'
import { Orientation, PagePose } from './types'

export const getPose = (
  orientation: Orientation,
  size: { width: number; height: number },
  posePoint: number,
): PagePose => {
  const isPortrait = orientation === 'PORTRAIT'
  const axisSize = isPortrait ? size.width : size.height

  const poses = [
    {
      creaseOffset: 0,
      rotationPageA: 0,
      rotationPageB: 0,
      rotationFlip: 0,
    },
    {
      creaseOffset: 0,
      rotationPageA: -MAX_OPEN_DEGREES,
      rotationPageB: 0,
      rotationFlip: 0,
    },
    {
      creaseOffset: axisSize,
      rotationPageA: -180,
      rotationPageB: MAX_OPEN_DEGREES - 180,
      rotationFlip: 0,
    },
    {
      creaseOffset: isPortrait ? axisSize : 0,
      rotationPageA: isPortrait ? -180 : 0,
      rotationPageB: isPortrait ? -180 : 0,
      rotationFlip: isPortrait ? 0 : 180,
    },
  ]

  const poseFromIndex = Math.floor(posePoint)
  const poseFrom = poses[modNeg(poseFromIndex, 4)]
  const poseTo = poses[modNeg(poseFromIndex + 1, 4)]
  const p = modNeg(posePoint, 1)

  return {
    creaseOffset: lerp(poseFrom.creaseOffset, poseTo.creaseOffset, p),
    rotationPageA: lerp(poseFrom.rotationPageA, poseTo.rotationPageA, p),
    rotationPageB: lerp(poseFrom.rotationPageB, poseTo.rotationPageB, p),
    rotationFlip: lerp(poseFrom.rotationFlip, poseTo.rotationFlip, p),
  }
}
