import dynamic from 'next/dynamic'
import React, { FC } from 'react'
import { OnLoaded } from '../../types'

const PreviewDynamic = dynamic(
  async () => {
    return (await import('./PreviewDynamic')).PreviewDynamic
  },
  {
    loading: /* istanbul ignore next */ () => null,
  },
)

type PreviewMugProps = {
  onLoaded: OnLoaded
}

export const PreviewMug: FC<PreviewMugProps> = ({ onLoaded }) => {
  return <PreviewDynamic onLoaded={onLoaded} />
}
