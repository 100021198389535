import { Flex } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { Minimap } from '../../../components/Minimap'

type NavProps = {
  sceneOrder?: number[]
}

export const Nav: FC<NavProps> = ({ sceneOrder }) => {
  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Minimap sceneOrder={sceneOrder} />
    </Flex>
  )
}
