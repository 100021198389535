const WORLD_SHADOW_BLUR_SIZE = 3
const WORLD_SHADOW_OFFSET_Y = 2
const WORLD_SHADOW_SAFE_TOP = WORLD_SHADOW_BLUR_SIZE
const WORLD_SHADOW_SAFE_LEFT = WORLD_SHADOW_BLUR_SIZE
const WORLD_SHADOW_SAFE_RIGHT = WORLD_SHADOW_BLUR_SIZE
const WORLD_SHADOW_SAFE_BOTTOM = WORLD_SHADOW_BLUR_SIZE + WORLD_SHADOW_OFFSET_Y
const WORLD_CREASE_SIZE = 32
const WORLD_SCALE = 2

const SHADOW_COLOR = `0, 32, 77`
const SHADOW_OPACITY = 0.25
const CREASE_INTENSITY = 0.15

const createShadowColor = (opacity: number) => {
  return `rgba(${SHADOW_COLOR}, ${opacity})`
}

export type DrawnImage = {
  x: number
  y: number
  width: number
  height: number
  url: string
}

export const drawShadow = (
  worldWidth: number,
  worldHeight: number,
): DrawnImage => {
  const width = worldWidth * WORLD_SCALE
  const height = worldHeight * WORLD_SCALE

  const worldOuterWidth =
    WORLD_SHADOW_SAFE_LEFT + worldWidth + WORLD_SHADOW_SAFE_RIGHT
  const worldOuterHeight =
    WORLD_SHADOW_SAFE_TOP + worldHeight + WORLD_SHADOW_SAFE_BOTTOM

  const safeTop = WORLD_SHADOW_SAFE_TOP * WORLD_SCALE
  const safeLeft = WORLD_SHADOW_SAFE_LEFT * WORLD_SCALE
  const safeRight = WORLD_SHADOW_SAFE_RIGHT * WORLD_SCALE
  const safeBottom = WORLD_SHADOW_SAFE_BOTTOM * WORLD_SCALE

  const outerWidth = safeLeft + width + safeRight
  const outerHeight = safeTop + height + safeBottom

  const shadowBlur = WORLD_SHADOW_BLUR_SIZE * WORLD_SCALE
  const shadowOffsetY = WORLD_SHADOW_OFFSET_Y * WORLD_SCALE

  const canvas = document.createElement('canvas')
  canvas.width = outerWidth
  canvas.height = outerHeight

  const g = canvas.getContext('2d') as CanvasRenderingContext2D

  // Shadow
  g.shadowColor = createShadowColor(SHADOW_OPACITY)
  g.shadowBlur = shadowBlur
  g.shadowOffsetX = 0
  g.shadowOffsetY = shadowOffsetY

  // Background
  g.fillStyle = createShadowColor(SHADOW_OPACITY)
  g.fillRect(safeLeft, safeTop, width, height)

  const url = canvas.toDataURL()

  return {
    x: -WORLD_SHADOW_SAFE_LEFT,
    y: -WORLD_SHADOW_SAFE_TOP,
    width: worldOuterWidth,
    height: worldOuterHeight,
    url,
  }
}

export const drawCrease = (
  worldLength: number,
  direction: 'left' | 'top',
): DrawnImage => {
  const isLeft = direction === 'left'

  const worldWidth = isLeft ? WORLD_CREASE_SIZE : worldLength
  const worldHeight = isLeft ? worldLength : WORLD_CREASE_SIZE

  const width = worldWidth * WORLD_SCALE
  const height = worldHeight * WORLD_SCALE

  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  const g = canvas.getContext('2d') as CanvasRenderingContext2D

  const gradient = g.createLinearGradient(
    0,
    0,
    isLeft ? width : 0,
    isLeft ? 0 : height,
  )

  gradient.addColorStop(0, createShadowColor(0))
  gradient.addColorStop(0.8, createShadowColor(CREASE_INTENSITY * 0.2))
  gradient.addColorStop(1, createShadowColor(CREASE_INTENSITY * 0.3))

  g.fillStyle = gradient
  g.fillRect(0, 0, width, height)

  const url = canvas.toDataURL()

  return {
    x: isLeft ? -worldWidth : 0,
    y: isLeft ? 0 : -worldHeight,
    width: worldWidth,
    height: worldHeight,
    url,
  }
}
