import React, { FC } from 'react'

export const IconKeyboard: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 24 24" width={width} height={height}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 5.5H2a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h20a.5.5 0 0 0 .5-.5V6a.5.5 0 0 0-.5-.5ZM2 4a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2Zm7.819 4.286c0 .591-.489 1.071-1.091 1.071-.603 0-1.091-.48-1.091-1.071 0-.592.488-1.072 1.09-1.072.603 0 1.092.48 1.092 1.072Zm0 4.285c.602 0 1.09-.48 1.09-1.071 0-.592-.488-1.072-1.09-1.072-.603 0-1.091.48-1.091 1.072 0 .592.488 1.071 1.09 1.071ZM6.544 8.286c0 .591-.488 1.071-1.09 1.071-.603 0-1.092-.48-1.092-1.071 0-.592.489-1.072 1.091-1.072.603 0 1.091.48 1.091 1.072Zm.001 4.285c.602 0 1.09-.48 1.09-1.071 0-.592-.488-1.072-1.09-1.072-.603 0-1.09.48-1.09 1.072 0 .592.487 1.071 1.09 1.071Zm6.545-4.285c0 .591-.488 1.071-1.09 1.071-.603 0-1.092-.48-1.092-1.071 0-.592.489-1.072 1.091-1.072.603 0 1.091.48 1.091 1.072Zm1.09 4.285c.603 0 1.092-.48 1.092-1.071 0-.592-.489-1.072-1.091-1.072-.603 0-1.091.48-1.091 1.072 0 .592.488 1.071 1.09 1.071Zm2.182-4.285c0 .591-.488 1.071-1.09 1.071-.603 0-1.091-.48-1.091-1.071 0-.592.488-1.072 1.09-1.072.603 0 1.091.48 1.091 1.072Zm1.091 4.285c.603 0 1.091-.48 1.091-1.071 0-.592-.488-1.072-1.09-1.072-.603 0-1.092.48-1.092 1.072 0 .592.489 1.071 1.091 1.071Zm2.183-4.285c0 .591-.489 1.071-1.091 1.071s-1.09-.48-1.09-1.071c0-.592.488-1.072 1.09-1.072.602 0 1.09.48 1.09 1.072Zm-.75 7.928a.75.75 0 0 0 0-1.5H5.114a.75.75 0 0 0 0 1.5h13.773Z"
        fill="currentColor"
      />
    </svg>
  )
}
