import { IconDelete } from '@moonpig/web-personalise-components'
import React, { FC, useCallback, useMemo } from 'react'
import { assert } from '../../../../../../../utils/assert'
import {
  UIAudio,
  UIAudioMenuItem,
  useAction,
  useView,
} from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { useDeleteElement } from '../../../../useDeleteElement'
import { ToolbarItem, ToolbarOrientation } from '../Toolbar/types'
import { ToolbarCommon } from '../ToolbarCommon'

type ToolbarAudioProps = {
  onDone: () => void
  orientation: ToolbarOrientation
  ui: UIAudio
}

export const ToolbarAudio: FC<ToolbarAudioProps> = ({
  onDone,
  orientation,
}) => {
  const t = useEditorLocaleText()
  const setUI = useAction('setUI')
  const deleteElement = useDeleteElement()
  const trackEvent = useAction('trackEvent')
  const productKey = useView('main', view => view.design.productKey)

  const items = useMemo<ToolbarItem<UIAudioMenuItem>[]>(() => {
    return [
      {
        type: 'button',
        id: 'delete',
        icon: <IconDelete />,
        label: t('delete_element_label'),
        disabled: false,
      },
    ]
  }, [t])

  const handleSelectItem = useCallback(
    (itemId: UIAudioMenuItem | null) => {
      setUI(current => {
        assert(current !== null && current.type === 'audio')
        switch (itemId) {
          case 'delete': {
            const { elementRef } = current

            deleteElement(elementRef)

            trackEvent({
              type: 'SELECT_CONTENT',
              kind: 'AUDIO_DELETE',
              productKey,
              label: 'toolbar',
            })

            return null
          }
          /* istanbul ignore next: unreachable*/
          default: {
            return current
          }
        }
      })
    },
    [deleteElement, productKey, setUI, trackEvent],
  )

  return (
    <ToolbarCommon
      label={t('toolbar_audio_toolbar_title')}
      orientation={orientation}
      items={items}
      selectedItemId={null}
      onSelectItem={handleSelectItem}
      menu={undefined}
      done={{
        label: t('toolbar_done_button'),
        ariaLabel: t('toolbar_audio_toolbar_close_label'),
        onDone,
      }}
    />
  )
}
