import { ErrorScreen } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { useSession, useView } from '../../store'
import { useEditorLocaleText } from '../../text-localisation'

export const ErrorDesignNotEditable: FC = () => {
  const t = useEditorLocaleText()
  const session = useSession()
  const designId = useView('error-design-not-editable', view => view.designId)

  return (
    <ErrorScreen
      title={t('loading_design_not_editable_title')}
      description={t('loading_design_not_editable_description', designId)}
      session={session}
    />
  )
}
