import React, { FC, ReactNode } from 'react'
import { useChain, useSpringRef } from 'react-spring'
import {
  DURATION,
  TIMING_BACKGROUND,
  TIMING_CARD,
  TIMING_MEDIA_SPINNER,
  TIMING_MEDIA_VIDEO,
  TIMING_PHONE,
} from './constants'
import { LayerBackground } from './LayerBackground'
import { LayerCard } from './LayerCard'
import { LayerMediaSpinner } from './LayerMediaSpinner'
import { LayerMediaVideo } from './LayerMediaVideo'
import { LayerPhone } from './LayerPhone'
import { Layout, Size } from './types'

type LayersProps = {
  layout: Layout
  renderScene: (input: { sceneIndex: number }) => ReactNode
  sceneSize: Size
  demoVideoUrl: string
}

export const Layers: FC<LayersProps> = ({
  layout,
  renderScene,
  sceneSize,
  demoVideoUrl,
}) => {
  const backgroundSpringRef = useSpringRef()
  const cardSpringRef = useSpringRef()
  const mediaSpinnerSpringRef = useSpringRef()
  const mediaVideoSpringRef = useSpringRef()
  const phoneSpringRef = useSpringRef()

  const timingBackground = TIMING_BACKGROUND.start / DURATION
  const timingCard = TIMING_CARD.start / DURATION
  const timingPhone = TIMING_PHONE.start / DURATION
  const timingMediaSpinner = TIMING_MEDIA_SPINNER.start / DURATION
  const timingMediaVideo = TIMING_MEDIA_VIDEO.start / DURATION

  useChain(
    [
      backgroundSpringRef,
      cardSpringRef,
      phoneSpringRef,
      mediaSpinnerSpringRef,
      mediaVideoSpringRef,
    ],
    [
      timingBackground,
      timingCard,
      timingPhone,
      timingMediaSpinner,
      timingMediaVideo,
    ],
    DURATION,
  )

  return (
    <>
      <LayerBackground springRef={backgroundSpringRef} />
      <LayerCard
        layout={layout}
        renderScene={renderScene}
        springRef={cardSpringRef}
        sceneSize={sceneSize}
      />
      <LayerMediaSpinner
        springRef={mediaSpinnerSpringRef}
        sceneSize={sceneSize}
      />
      <LayerMediaVideo
        demoVideoUrl={demoVideoUrl}
        springRef={mediaVideoSpringRef}
        sceneSize={sceneSize}
      />
      <LayerPhone springRef={phoneSpringRef} sceneSize={sceneSize} />
    </>
  )
}
