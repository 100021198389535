import {
  clamp,
  Vec2,
  vec2Div,
  vec2Mul,
  vec2Scale,
  vec2Sub,
} from '@moonpig/common-math'
import { CollageLayout } from './layout'

export type Image = {
  url: string
  size: Vec2
}

export type ImageById = {
  [imageId in string]: Image
}

export type ImageLayout = {
  scale: number
  offset: Vec2
  size: Vec2
}

export type ImageLayoutById = {
  [id in string]: ImageLayout
}

const scaleContentToFitContainer = (
  containerSize: Vec2,
  contentSize: Vec2,
): number => {
  const aspectContainer = containerSize[0] / containerSize[1]
  const aspectContent = contentSize[0] / contentSize[1]
  const fillWidth = aspectContainer > aspectContent

  return fillWidth
    ? containerSize[0] / contentSize[0]
    : containerSize[1] / contentSize[1]
}

const getImageScale = ({
  frameSize,
  imageSize,
  scale,
}: {
  imageSize: Vec2
  frameSize: Vec2
  scale: number
}): number => {
  return scale * scaleContentToFitContainer(frameSize, imageSize)
}

export const convertLayoutOffsetToCellOffset = ({
  offset,
  imageSize,
  frameSize,
  scale,
}: {
  offset: Vec2
  imageSize: Vec2
  frameSize: Vec2
  scale: number
}): Vec2 => {
  const imageScale = getImageScale({
    imageSize,
    frameSize,
    scale,
  })

  const scaledImageSize = vec2Scale(imageSize, imageScale)

  const movementRange = vec2Sub(scaledImageSize, frameSize)

  const offsetRange = vec2Div(movementRange, scaledImageSize)

  const newImageHalfSize = vec2Scale(vec2Scale(imageSize, imageScale), 0.5)

  return [
    clamp(-offset[0] / newImageHalfSize[0], -offsetRange[0], offsetRange[0]),
    clamp(-offset[1] / newImageHalfSize[1], -offsetRange[1], offsetRange[1]),
  ]
}

export const layoutImages = (
  imageById: ImageById,
  layout: CollageLayout,
): ImageLayoutById => {
  const imageLayoutById: ImageLayoutById = {}

  layout.elements.forEach(({ id, content, frame }) => {
    const image = imageById[content.imageId]

    const imageScale = getImageScale({
      imageSize: image.size,
      frameSize: frame.size,
      scale: content.scale,
    })

    const imageSize = vec2Scale(image.size, imageScale)

    const imageOffset = vec2Scale(vec2Mul(imageSize, content.offset), -0.5)

    const movementRange = vec2Scale(vec2Sub(imageSize, frame.size), 0.5)

    const clampedOffet: Vec2 = [
      clamp(imageOffset[0], -movementRange[0], movementRange[0]),
      clamp(imageOffset[1], -movementRange[1], movementRange[1]),
    ]

    imageLayoutById[id] = {
      scale: imageScale,
      offset: clampedOffet,
      size: imageSize,
    }
  })

  return imageLayoutById
}
