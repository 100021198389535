/*
  This does not update if browser resolution changes e.g. change monitor.
  Could use approach similar to https://github.com/rexxars/use-device-pixel-ratio if this becomes a requirement
 */
export const usePixelRatio = ({
  min,
  max,
}: { min?: number; max?: number } = {}) => {
  let value = 1
  /* istanbul ignore else */
  if (
    typeof window !== 'undefined' &&
    typeof window.devicePixelRatio === 'number'
  ) {
    value = window.devicePixelRatio
  }
  return Math.max(Math.min(value, max || Number.MAX_VALUE), min || 1)
}
