import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  alignment_option_horizontal: {
    'en-GB': `Horizontal`,
    'nl-NL': `Horizontaal`,
  },
  alignment_option_vertical: {
    'en-GB': `Vertical`,
    'nl-NL': `Verticaal`,
  },
  horizontal_alignment_center_label: {
    'en-GB': `Align to centre`,
    'nl-NL': `Gecentreerd uitlijnen`,
  },
  horizontal_alignment_justify_label: {
    'en-GB': `Make justified`,
    'nl-NL': `Uitgevuld uitlijnen`,
  },
  horizontal_alignment_left_label: {
    'en-GB': `Align to left`,
    'nl-NL': `Links uitlijnen`,
  },
  horizontal_alignment_right_label: {
    'en-GB': `Align to right`,
    'nl-NL': `Rechts uitlijnen`,
  },
  vertical_alignment_bottom_label: {
    'en-GB': `Align to bottom`,
    'nl-NL': `Naar beneden uitlijnen`,
  },
  vertical_alignment_middle_label: {
    'en-GB': `Align to middle`,
    'nl-NL': `Naar het midden uitlijnen`,
  },
  vertical_alignment_top_label: {
    'en-GB': `Align to top`,
    'nl-NL': `Naar boven uitlijnen`,
  },
}

export const useLocaleText = createLocaleTextHook({ dictionary })
