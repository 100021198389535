import React, { FC } from 'react'
import { Text, Alert, Heading } from '@moonpig/launchpad-components'
import { useLocaleText } from './MediaPlayer.locale'
import { MediaKind } from './types'

type MediaErrorProps = {
  mediaKind: MediaKind
  onRetry?: () => void
}

export const MediaError: FC<MediaErrorProps> = ({ mediaKind, onRetry }) => {
  const t = useLocaleText()

  const title: { [kind in MediaKind]: string } = {
    Audio: t('audio_error_title'),
    Video: t('video_error_title'),
  }

  return (
    <Alert
      variant={'error'}
      actions={
        onRetry
          ? [{ title: t('media_error_retry'), onClick: onRetry }]
          : undefined
      }
    >
      <Heading level="h4" mb={0}>
        {title[mediaKind]}
      </Heading>
      <Text typography={'body'}>{t('media_error_description')}</Text>
    </Alert>
  )
}
