import { CollageGridGutter } from './grid'

export type GutterById = {
  [x: string]: CollageGridGutter
}

type Range = [number, number]

const doRangesOverlap = (r1: Range, r2: Range): boolean => {
  return r2[0] < r1[1] && r2[1] > r1[0]
}

const getGutterRange = (
  gutterById: GutterById,
  gutter: CollageGridGutter,
): Range => {
  const offsetStart =
    gutter.anchors.start === null ? 0 : gutterById[gutter.anchors.start].offset

  const offsetEnd =
    gutter.anchors.end === null ? 1 : gutterById[gutter.anchors.end].offset

  return [offsetStart, offsetEnd]
}

export const getGutterOffsetRange = (
  gutterById: GutterById,
  gutterId: string,
): Range => {
  const gutter = gutterById[gutterId]
  const range = getGutterRange(gutterById, gutter)

  let minOffset = 0
  let maxOffset = 1

  Object.values(gutterById).forEach(other => {
    if (
      other.orientation !== gutter.orientation ||
      !doRangesOverlap(range, getGutterRange(gutterById, other))
    ) {
      return
    }

    if (other.offset > minOffset && other.offset < gutter.offset) {
      minOffset = other.offset
    }

    if (other.offset < maxOffset && other.offset > gutter.offset) {
      maxOffset = other.offset
    }
  })

  return [minOffset, maxOffset]
}
