import { focusIndicatorStyles } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'

export const RadioGroup = styled.fieldset`
  ${focusIndicatorStyles}

  padding: 0;
  margin: 0;
  border-width: 0px;
  min-width: 0;

  ${s({
    borderRadius: 1,
  })}

  &:has(:focus-visible) {
    ${s({
      boxShadow: 5,
    })}
  }

  & *:focus {
    outline: none;
  }
`
