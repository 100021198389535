import { styled } from '@moonpig/launchpad-utils'
import { TextAlignProperty } from 'csstype'
import React, {
  ClipboardEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useLayoutEffect,
  useRef,
} from 'react'
import { useConsumeTouch } from '../../utils'
import { useFocusRef } from '../FocusManager'
import {
  HorizontalAlignment,
  TextEditableFragment,
  VerticalAlignment,
} from './types'
import { autoSizeText } from './utils/autoSizeText'
import { extractFragments } from './utils/extractFragments'
import { focusInput, moveSelectionToEnd } from './utils/focusInput'
import { getTextContent } from './utils/getTextContent'

const textAlignByHorizontalAlignment: {
  [x in HorizontalAlignment]: TextAlignProperty
} = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  JUSTIFY: 'justify',
}

const StyledContentEditable = styled.div`
  position: relative;
  display: block;
  width: 100%;
  outline: none;
  white-space: pre-wrap;
  overflow-wrap: break-word;

  &,
  & * {
    user-select: auto;
  }
`

export type TextEditableGetFragments = () => TextEditableFragment[]

export type TextEditableDoneEvent = {
  getFragments: TextEditableGetFragments
}

export type TextEditableFragmentsChangedEvent = {
  getFragments: TextEditableGetFragments
}

type TextEditableProps = {
  focusId: string
  hidden?: boolean
  text: string
  color?: string
  shadow?: { color: string; offsetX: number; offsetY: number } | null
  horizontalAlignment?: HorizontalAlignment
  verticalAlignment?: VerticalAlignment
  fontFamily?: string
  fontSize: number
  lineSpacingRelative?: number
  maxCharacterLimit?: number | null
  textTransform?: 'UPPERCASE' | null
  transformText?: (text: string) => string
  onChange: (text: string, wordBoundary: boolean) => void
  onDone?: (event: TextEditableDoneEvent) => void
  onTruncated?: () => void
  onResize?: (event: { height: number }) => boolean
  onFragmentsChanged?: (event: TextEditableFragmentsChangedEvent) => void
  fragmentsVersion?: number
  baselinePercentOfHeight?: number
}

const countWords = (text: string): number => {
  return text.split(/\s+/).length
}

export const TextEditable: FC<TextEditableProps> = ({
  focusId,
  hidden,
  text,
  color,
  shadow,
  horizontalAlignment = 'LEFT',
  verticalAlignment = 'TOP',
  fontFamily,
  fontSize,
  lineSpacingRelative = 0,
  maxCharacterLimit = null,
  textTransform,
  transformText,
  onChange,
  onDone,
  onTruncated,
  onResize,
  onFragmentsChanged,
  fragmentsVersion,
  baselinePercentOfHeight = 1,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const editableRef = useFocusRef<HTMLDivElement>(focusId)

  const handleInput = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const textElement = editableRef.current!
    const newText = getTextContent(textElement)

    const transformedText = transformText ? transformText(newText) : newText

    if (transformedText !== newText) {
      textElement.textContent = transformedText
      focusInput(textElement)
    }

    const truncatedText =
      maxCharacterLimit === null
        ? transformedText
        : transformedText.slice(0, maxCharacterLimit)

    if (truncatedText !== transformedText) {
      textElement.textContent = truncatedText
      focusInput(textElement)
      onTruncated?.()
    }

    const isWordBoundary = countWords(truncatedText) !== countWords(text)

    onChange(truncatedText, isWordBoundary)
  }, [
    editableRef,
    transformText,
    maxCharacterLimit,
    onChange,
    onTruncated,
    text,
  ])

  const handleFocus = useCallback((event: FocusEvent) => {
    moveSelectionToEnd(event.currentTarget)
  }, [])

  const getFragments = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const textElement = editableRef.current!

    return extractFragments({ textElement, baselinePercentOfHeight })
  }, [baselinePercentOfHeight, editableRef])

  const handleBlur = useCallback(() => {
    const selection = window.getSelection()
    selection?.removeAllRanges()

    onDone?.({ getFragments })
  }, [getFragments, onDone])

  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (
      event.key === 'Escape' ||
      (event.key === 'Enter' && (event.metaKey || event.ctrlKey))
    ) {
      event.currentTarget.blur()
    }
  }, [])

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      const selection = window.getSelection()

      if (!selection?.rangeCount) {
        return
      }

      const selectionLength = selection.getRangeAt(0).toString().length
      const remainingCharacters =
        maxCharacterLimit === null
          ? Infinity
          : maxCharacterLimit - text.length + selectionLength

      if (remainingCharacters <= 0) {
        event.preventDefault()
      }
    },
    [maxCharacterLimit, text],
  )

  const handlePaste = useCallback(
    (event: ClipboardEvent<HTMLDivElement>) => {
      event.preventDefault()
      const selection = window.getSelection()

      if (!selection?.rangeCount) {
        return
      }

      const selectionLength = selection.getRangeAt(0).toString().length
      const remainingCharacters =
        maxCharacterLimit === null
          ? Infinity
          : maxCharacterLimit - text.length + selectionLength

      const pastedText = event.clipboardData.getData('text')
      const truncatedPastedText = pastedText.substring(0, remainingCharacters)

      selection.deleteFromDocument()
      const range = selection.getRangeAt(0)
      range.insertNode(document.createTextNode(truncatedPastedText))
      range.collapse(false)

      if (truncatedPastedText !== pastedText) {
        onTruncated?.()
      }

      handleInput()
    },
    [handleInput, maxCharacterLimit, onTruncated, text.length],
  )

  const maxFontSize = Math.floor(fontSize)

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const containerElement = containerRef.current!
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const textElement = editableRef.current!

    if (text !== getTextContent(textElement)) {
      textElement.textContent = text
    }

    if (document.activeElement !== textElement && !hidden) {
      focusInput(textElement)
    }
    autoSizeText({
      containerElement,
      textElement,
      maxFontSize,
      verticalAlignment,
      onResize,
    })
  }, [
    text,
    fontSize,
    maxFontSize,
    verticalAlignment,
    containerRef,
    editableRef,
    onResize,
    hidden,
    getFragments,
  ])

  useLayoutEffect(() => {
    if (onFragmentsChanged) {
      onFragmentsChanged({ getFragments })
    }
  }, [
    getFragments,
    fragmentsVersion,
    horizontalAlignment,
    verticalAlignment,
    fontFamily,
    fontSize,
    text,
    onFragmentsChanged,
  ])

  const bindConsumeTouch = useConsumeTouch()

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <StyledContentEditable
        ref={editableRef}
        contentEditable={!hidden}
        aria-hidden={hidden}
        aria-multiline="true"
        role="textbox"
        tabIndex={hidden ? -1 : 0}
        style={{
          fontFamily,
          color,
          textAlign: textAlignByHorizontalAlignment[horizontalAlignment],
          lineHeight: lineSpacingRelative > 0 ? lineSpacingRelative : 'normal',
          textShadow: shadow
            ? `${shadow.offsetX}px ${shadow.offsetY}px 0 ${shadow.color}`
            : undefined,
          textTransform: textTransform === 'UPPERCASE' ? 'uppercase' : 'none',
        }}
        onFocus={handleFocus}
        onBlur={hidden ? undefined : handleBlur}
        onKeyDown={handleKeyDown}
        onKeyPress={handleKeyPress}
        onInput={handleInput}
        onPaste={handlePaste}
        {...bindConsumeTouch()}
      />
    </div>
  )
}
