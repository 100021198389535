import {
  FontMetrics,
  Scene,
  SceneImageObject,
  SceneObject,
  SceneRectangleObject,
} from '@moonpig/renderer-scene-types'
import {
  FRAME_OVERLAP,
  ImageById,
  convertCollageGridToScene,
  getImageIds,
} from '@moonpig/web-personalise-collage'
import {
  DesignAvailableTextStyles,
  DesignElement,
  DesignElementById,
  DesignFontMetrics,
  DesignRenderMethod,
  DesignTextTransform,
} from '@moonpig/web-personalise-editor-types'
import { assert } from '../../../../utils/assert'
import { getDynamicImageId } from '../../../../utils/getDynamicImageId'
import { getInitialScale } from '../../../../utils/getInitialScale'
import { Photos, RemoteImageStore } from '../../../../store'

const DIMMED_IMAGE_OVERLAY_COLOR = 'rgba(255, 255,255, 0.7)'

type SceneConfig = {
  width: number
  height: number
  renderMethod: DesignRenderMethod
  elementIds: string[]
}

const convertFontMetrics = (metrics: DesignFontMetrics): FontMetrics => {
  return {
    unitsPerEm: metrics.head.unitsPerEm,
    usWinAscent: metrics.os2.usWinAscent,
    usWinDescent: metrics.os2.usWinDescent,
  }
}

const transformText = (
  text: string,
  textTransform: DesignTextTransform | null,
): string => {
  if (textTransform === 'UPPERCASE') {
    return text.toUpperCase()
  }

  return text
}

const convertElementToSceneObjects = ({
  scene,
  element,
  photos,
  loadedStickerIds,
  availableTextStyles,
  remoteImageStore,
  enableTextFragments,
}: {
  scene: SceneConfig
  element: DesignElement
  photos: Photos
  loadedStickerIds: Set<string>
  availableTextStyles: DesignAvailableTextStyles
  remoteImageStore: RemoteImageStore
  enableTextFragments: boolean
}): SceneObject[] => {
  switch (element.type) {
    case 'image-static': {
      return [
        {
          type: 'IMAGE',
          id: element.id,
          frame: {
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
          },
          url: element.url,
          maskUrl: element.maskUrl ?? undefined,
        },
      ]
    }
    case 'overlay-image': {
      const defaultSceneObject = {
        type: 'IMAGE' as const,
        id: element.id,
        frame: {
          x: element.x,
          y: element.y,
          width: element.width,
          height: element.height,
          rotation: element.rotation,
        },
      }

      if (!element.customisations.sourceImage) {
        const editedImageUrl =
          element.customisations.editedImage &&
          remoteImageStore.editedImageById[
            element.customisations.editedImage.id
          ]

        if (editedImageUrl) {
          return [
            {
              ...defaultSceneObject,
              url: editedImageUrl,
            },
          ]
        }

        return []
      }

      const photoState = photos.photoById[element.customisations.sourceImage.id]

      assert(photoState.type === 'loaded')

      const { photo } = photoState
      const { transform } = element.customisations.sourceImage

      return [
        {
          ...defaultSceneObject,
          url: photo.mediumImage.url,
          transform: {
            position: transform.position,
            scale: 1,
            rotation: (transform.rotation * 180) / Math.PI,
          },
        },
      ]
    }
    case 'image-upload': {
      const defaultSceneObject: SceneImageObject = {
        type: 'IMAGE',
        id: element.id,
        frame: {
          x: element.x,
          y: element.y,
          width: element.width,
          height: element.height,
          rotation: element.rotation,
        },
        url: element.url,
        maskUrl: element.maskUrl ?? undefined,
      }

      if (!element.customisations.sourceImage) {
        const editedImageUrl =
          element.customisations.editedImage &&
          remoteImageStore.editedImageById[
            element.customisations.editedImage.id
          ]

        if (editedImageUrl) {
          return [
            {
              ...defaultSceneObject,
              url: editedImageUrl,
            },
          ]
        }

        return [defaultSceneObject]
      }

      const photoState = photos.photoById[element.customisations.sourceImage.id]

      assert(photoState.type === 'loaded')

      const { photo } = photoState
      const { transform } = element.customisations.sourceImage

      const initialScale = getInitialScale(
        photo.originalImage.width,
        photo.originalImage.height,
        element.width,
        element.height,
      )

      return [
        {
          ...defaultSceneObject,
          url: photo.mediumImage.url,
          transform: {
            position: transform.position,
            scale: transform.scale / initialScale,
            rotation: (transform.rotation * 180) / Math.PI,
          },
        },
      ]
    }
    case 'image-dynamic': {
      const { dynamicImageId, previousDynamicImageId } =
        getDynamicImageId(element)

      const getDynamicImageUrl = (id: string): string | null => {
        const image = remoteImageStore.dynamicImageById[id]
        return image && image.type === 'loaded' ? image.imageUrl : null
      }

      const dynamicImageUrl =
        getDynamicImageUrl(dynamicImageId) ??
        getDynamicImageUrl(previousDynamicImageId)

      if (!dynamicImageUrl) {
        return []
      }

      return [
        {
          type: 'IMAGE',
          id: element.id,
          frame: {
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
          },
          url: dynamicImageUrl,
        },
      ]
    }
    case 'image-collage': {
      if (element.customisations.grid) {
        const imageIds = getImageIds(element.customisations.grid)

        const imageById = imageIds.reduce<ImageById>((acc, imageId) => {
          const photoState = photos.photoById[imageId]

          assert(photoState.type === 'loaded')

          const image = photoState.photo.mediumImage

          acc[imageId] = {
            size: [image.width, image.height],
            url: image.url,
          }

          return acc
        }, {})

        const collageScene = convertCollageGridToScene({
          grid: element.customisations.grid,
          imageById,
          width: element.width,
          height: element.height,
          frameOverlap: FRAME_OVERLAP,
        })

        return collageScene.objects.map(object => {
          return {
            ...object,
            id: `collage-${object.id}`,
            frame: {
              ...object.frame,
              x: object.frame.x + element.x,
              y: object.frame.y + element.y,
            },
          }
        })
      }

      const editedImageUrl =
        element.customisations.editedImage &&
        remoteImageStore.editedImageById[element.customisations.editedImage.id]

      if (editedImageUrl) {
        return [
          {
            type: 'IMAGE',
            id: element.id,
            frame: {
              x: element.x,
              y: element.y,
              width: element.width,
              height: element.height,
              rotation: element.rotation,
            },
            url: editedImageUrl,
          },
        ]
      }

      return [
        {
          type: 'IMAGE',
          id: element.id,
          frame: {
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
          },
          url: element.placeholderImageUrl,
        },
      ]
    }
    case 'overlay-inlay': {
      return [
        {
          type: 'IMAGE',
          id: element.id,
          frame: {
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
          },
          url: element.imageUrl,
        },
      ]
    }
    case 'text-static': {
      return [
        {
          type: 'TEXT',
          id: element.id,
          frame: {
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
          },
          renderMethod: scene.renderMethod,
          color: element.color.value,
          horizontalAlignment: element.horizontalAlignment,
          verticalAlignment: element.verticalAlignment,
          shadow: element.shadow
            ? {
                offsetX: element.shadow.offsetX,
                offsetY: element.shadow.offsetY,
                color: element.shadow.color,
              }
            : null,
          lineSpacing: element.lineSpacing ?? 0,
          maxFontSize: element.fontSize,
          fontUrl: element.fontFace.urls.woff2,
          text: element.text,
          fontMetrics: convertFontMetrics(element.fontFace.metrics),
        },
      ]
    }
    case 'text-plain': {
      return [
        {
          type: 'TEXT',
          id: element.id,
          frame: {
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
          },
          renderMethod: scene.renderMethod,
          color: element.color.value,
          horizontalAlignment: element.horizontalAlignment,
          verticalAlignment: element.verticalAlignment,
          shadow: element.shadow
            ? {
                offsetX: element.shadow.offsetX,
                offsetY: element.shadow.offsetY,
                color: element.shadow.color,
              }
            : null,
          lineSpacing: element.lineSpacing ?? 0,
          maxFontSize: element.fontSize,
          fontUrl: element.fontFace.urls.woff2,
          text: transformText(
            element.customisations.text,
            element.editableText.textTransform,
          ),
          fontMetrics: convertFontMetrics(element.fontFace.metrics),
        },
      ]
    }
    case 'text-styled': {
      return [
        {
          type: 'TEXT',
          id: element.id,
          frame: {
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
          },
          renderMethod: scene.renderMethod,
          color:
            element.availableColorById[element.customisations.colorId].value,
          horizontalAlignment: element.customisations.horizontalAlignment,
          verticalAlignment: element.customisations.verticalAlignment,
          lineSpacing: element.lineSpacing ?? 0,
          maxFontSize: element.customisations.fontSize,
          fontUrl:
            element.availableFontFaceById[element.customisations.fontFaceId]
              .urls.woff2,
          shadow: element.shadow
            ? {
                offsetX: element.shadow.offsetX,
                offsetY: element.shadow.offsetY,
                color: element.shadow.color,
              }
            : null,
          text: transformText(
            element.customisations.text,
            element.editableText.textTransform,
          ),
          fontMetrics: convertFontMetrics(element.fontFace.metrics),
        },
      ]
    }
    case 'text-placeholder': {
      let text = ''
      let editablePartIndex = 0
      element.textParts.forEach(part => {
        switch (part.type) {
          case 'static': {
            text += part.text
            break
          }
          case 'editable': {
            text += transformText(
              element.customisations.textParts[editablePartIndex],
              part.textTransform,
            )
            editablePartIndex += 1
            break
          }
        }
      })

      return [
        {
          type: 'TEXT',
          id: element.id,
          frame: {
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
          },
          renderMethod: scene.renderMethod,
          color: element.color.value,
          horizontalAlignment: element.horizontalAlignment,
          verticalAlignment: element.verticalAlignment,
          shadow: element.shadow
            ? {
                offsetX: element.shadow.offsetX,
                offsetY: element.shadow.offsetY,
                color: element.shadow.color,
              }
            : null,
          lineSpacing: element.lineSpacing ?? 0,
          maxFontSize: element.fontSize,
          fontUrl: element.fontFace.urls.woff2,
          text,
          fontMetrics: convertFontMetrics(element.fontFace.metrics),
        },
      ]
    }
    case 'shape-rectangle': {
      return [
        {
          type: 'RECTANGLE',
          id: element.id,
          frame: {
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
          },
          fillColor: element.fillColor,
          outline: element.outline,
        },
      ]
    }
    case 'sticker': {
      if (!loadedStickerIds.has(element.sticker.id)) {
        return []
      }

      return [
        {
          type: 'IMAGE',
          id: element.id,
          frame: {
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
          },
          url: element.sticker.url,
        },
      ]
    }
    case 'overlay-text': {
      return [
        {
          type: 'TEXT',
          id: element.id,
          frame: {
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
          },
          renderMethod: 'LEGACY_2',
          color:
            availableTextStyles.availableColorById[
              element.customisations.colorId
            ].value,
          horizontalAlignment: element.customisations.horizontalAlignment,
          verticalAlignment: element.customisations.verticalAlignment,
          shadow: null,
          lineSpacing: 0,
          maxFontSize: element.customisations.fontSize,
          fontUrl:
            availableTextStyles.availableFontFaceById[
              element.customisations.fontFaceId
            ].urls.woff2,
          text: element.customisations.text,
          fragments:
            enableTextFragments && element.fragmentsState
              ? element.fragmentsState.fragments
              : null,
          fontMetrics: convertFontMetrics(
            availableTextStyles.availableFontFaceById[
              element.customisations.fontFaceId
            ].metrics,
          ),
        },
      ]
    }
    case 'media': {
      const mediaId = 'mediaId' in element.state ? element.state.mediaId : ''

      const mediaImageUrl = remoteImageStore.mediaImageById[mediaId]

      if (!mediaImageUrl) {
        return []
      }

      return [
        {
          type: 'IMAGE',
          id: element.id,
          frame: {
            x: element.x,
            y: element.y,
            width: element.width,
            height: element.height,
            rotation: element.rotation,
          },
          url: mediaImageUrl,
        },
      ]
    }
  }
}

export const convertDesignToScene = ({
  elementById,
  scene,
  photos,
  dimElement,
  loadedStickerIds,
  availableTextStyles,
  remoteImageStore,
  enableTextFragments,
}: {
  elementById: DesignElementById
  scene: SceneConfig
  photos: Photos
  dimElement?: (element: DesignElement) => boolean
  loadedStickerIds: Set<string>
  availableTextStyles: DesignAvailableTextStyles
  remoteImageStore: RemoteImageStore
  enableTextFragments: boolean
}): Scene => {
  const objects = scene.elementIds.flatMap<SceneObject>(elementId => {
    const element = elementById[elementId]

    const elementObjects = convertElementToSceneObjects({
      scene,
      element,
      photos,
      loadedStickerIds,
      availableTextStyles,
      remoteImageStore,
      enableTextFragments,
    })

    if (dimElement?.(element)) {
      const overlay: SceneRectangleObject = {
        type: 'RECTANGLE',
        id: `${elementId}-overlay`,
        frame: {
          x: element.x,
          y: element.y,
          width: element.width,
          height: element.height,
          rotation: element.rotation,
        },
        fillColor: DIMMED_IMAGE_OVERLAY_COLOR,
        outline: null,
      }

      return [...elementObjects, overlay]
    }

    return elementObjects
  })

  return {
    title: '',
    width: scene.width,
    height: scene.height,
    objects,
  }
}
