import { Box } from '@moonpig/launchpad-components'
import { system } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'

const BUTTON_SIZE = 48

type RadioButtonProps = {
  id: string
  name: string
  onSelect(): void
  checked: boolean
  fullWidth?: boolean
  disabled?: boolean
}

const StyledRadioButton = styled.input`
  appearance: none;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  border-radius: 8px;
`

const innerBorderWidth = 4
const outerBorderWidth = 1
const outerBorderWidthSelected = 2

const StyledRadioLabel = styled.label<{ fullWidth?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: ${innerBorderWidth}px;
  user-select: none;

  ${system({
    borderColor: 'colorBlack40',
    bgcolor: 'colorBlack00',
    color: 'colorTextBody',
  })}

  input:checked + & {
    padding: ${innerBorderWidth -
    outerBorderWidthSelected +
    outerBorderWidth}px;

    ${system({
      bgcolor: 'colorBackgroundInformation',
      borderColor: 'colorInteractionSelectedState',
    })}
  }

  input:disabled + & {
    cursor: not-allowed;

    ${system({
      bgcolor: 'colorBlack05',
      borderColor: 'colorInteractionDeactivated',
      color: 'colorInteractionDeactivated',
    })}
  }
`

export const RadioButton: FC<RadioButtonProps> = ({
  id,
  name,
  onSelect,
  checked,
  fullWidth,
  disabled,
  children,
}) => {
  return (
    <Box
      position="relative"
      width={fullWidth ? '100%' : BUTTON_SIZE}
      height={BUTTON_SIZE}
    >
      <StyledRadioButton
        id={id}
        type="radio"
        name={name}
        onChange={() => onSelect()}
        checked={checked}
        disabled={disabled}
      />
      <StyledRadioLabel htmlFor={id} fullWidth={fullWidth}>
        {children}
      </StyledRadioLabel>
    </Box>
  )
}
