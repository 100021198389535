import { Box, Flex, focusIndicatorStyles } from '@moonpig/launchpad-components'
import React, {
  FC,
  UIEventHandler,
  useCallback,
  useLayoutEffect,
  useRef,
  TouchEvent,
} from 'react'
import { styled, useTheme } from '@moonpig/launchpad-utils'
import { clamp } from '@moonpig/common-math'
import { useElementSize } from '../../utils'

const TIME_LABEL_ID = 'mp-ed-audio-timeline-time-label'

const convertToMinutesAndSeconds = (fractionalSeconds: number): string => {
  const wholeSeconds = Math.round(fractionalSeconds)
  const minutes = Math.floor(wholeSeconds / 60)
    .toString()
    .padStart(2, '0')
  const seconds = (wholeSeconds % 60).toString().padStart(2, '0')
  return `${minutes}:${seconds}`
}

const BAR_WIDTH = 4
const WAVEFORM_MAX_HEIGHT = 60
const BAR_GAP = BAR_WIDTH / 2
const PADDING = 16

const StyledContainer = styled(Flex)`
  ${focusIndicatorStyles}
`

const WaveformContainer = styled(Box)`
  flex: 1;
  position: relative;
  width: 100%;
  min-width: 0;
`

const Waveform = styled.div`
  overflow-y: hidden;
  width: 100%;
  height: ${WAVEFORM_MAX_HEIGHT + PADDING * 2}px;
  padding: ${PADDING}px 0;

  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  &.locked {
    overflow-x: hidden;
  }
`

const AudioWaveformProgressIndicator: FC = () => {
  const { palette } = useTheme()
  const { colorInteractionButton } = palette

  const circleRadius = 4
  const indicatorWidth = circleRadius * 2
  const indicatorHeight = WAVEFORM_MAX_HEIGHT + PADDING * 2
  const lineWidth = 2
  const lineX = (indicatorWidth - lineWidth) / 2

  return (
    <svg
      viewBox={`0 0 ${indicatorWidth} ${indicatorHeight}`}
      style={{
        top: 0,
        position: 'absolute',
        height: `calc(100%)`,
        width: indicatorWidth,
        left: '50%',
        transform: 'translateX(-50%)',
        pointerEvents: 'none',
        overflow: 'visible',
      }}
    >
      <circle
        cx={circleRadius}
        cy={0}
        r={circleRadius}
        fill={colorInteractionButton}
      />
      <rect
        fill={colorInteractionButton}
        x={lineX}
        y={0}
        height={indicatorHeight}
        width={lineWidth}
      />
      <circle
        cx={circleRadius}
        cy={indicatorHeight}
        r={circleRadius}
        fill={colorInteractionButton}
      />
    </svg>
  )
}

export type AudioTimelineProps = {
  currentTimeSeconds: number
  timePerSampleSeconds: number
  samples: number[]
  onSeek?: (time: number) => void
  durationSeconds?: number
}

export const AudioTimeline: FC<AudioTimelineProps> = ({
  currentTimeSeconds,
  durationSeconds = 0,
  timePerSampleSeconds,
  samples,
  onSeek,
}) => {
  const { palette } = useTheme()
  const { colorBlack30, colorBlack70 } = palette
  const containerRef = useRef<HTMLDivElement>(null)
  const numberOfBars = onSeek
    ? samples.length
    : currentTimeSeconds / timePerSampleSeconds
  const width = numberOfBars * (BAR_WIDTH + BAR_GAP)
  const waveformSize = useElementSize(containerRef)
  /* istanbul ignore next */
  const waveformWidth = waveformSize ? waveformSize.width : 0
  const barOffset = waveformWidth / 2

  const expectedScrollLeftRef = useRef(0)

  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const container = containerRef.current!

    let scrollLeft
    if (onSeek) {
      scrollLeft = Math.round(width * (currentTimeSeconds / durationSeconds))
    } else {
      scrollLeft = Math.round(container.scrollWidth - container.clientWidth)
    }
    container.scrollLeft = scrollLeft
    expectedScrollLeftRef.current = scrollLeft
  }, [onSeek, containerRef, currentTimeSeconds, width, durationSeconds])

  const handleScroll: UIEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (!onSeek) {
        return
      }

      if (
        Math.round(event.currentTarget.scrollLeft) ===
        Math.round(expectedScrollLeftRef.current)
      ) {
        return
      }

      const seekPosition =
        (event.currentTarget.scrollLeft / width) * durationSeconds

      onSeek(seekPosition)
    },
    [onSeek, width, durationSeconds],
  )

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (!onSeek) {
        return
      }

      switch (event.key) {
        case 'ArrowDown':
        case 'ArrowLeft': {
          event.preventDefault()
          event.stopPropagation()
          onSeek(clamp(currentTimeSeconds - 1, 0, durationSeconds))
          break
        }
        case 'ArrowUp':
        case 'ArrowRight': {
          event.preventDefault()
          event.stopPropagation()
          onSeek(clamp(currentTimeSeconds + 1, 0, durationSeconds))
          break
        }
        case 'Home': {
          onSeek(0)
          break
        }
        case 'End': {
          onSeek(durationSeconds)
          break
        }
      }
    },
    [onSeek, currentTimeSeconds, durationSeconds],
  )

  const handleTouchStart = useCallback((event: TouchEvent) => {
    event.stopPropagation()
  }, [])

  return (
    <Box boxShadow={4} borderRadius={2}>
      <StyledContainer
        bgcolor="colorBackgroundSite"
        role="timer"
        aria-labelledby={TIME_LABEL_ID}
        alignItems="center"
        borderRadius={2}
        gap="16px"
        pl={6}
        pr={4}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onTouchStart={handleTouchStart}
      >
        <WaveformContainer>
          <Waveform
            className={onSeek ? 'seekable' : 'locked'}
            ref={containerRef}
            onScroll={handleScroll}
            data-testid="mp-ed-waveform-scroll"
          >
            <svg
              style={{
                width: `${width + waveformWidth}px`,
                height: '100%',
              }}
            >
              <g>
                {samples.map((amplitude, index) => {
                  const height = Math.max(
                    BAR_WIDTH,
                    WAVEFORM_MAX_HEIGHT * amplitude,
                  )
                  const sampleTimeSeconds = index * timePerSampleSeconds
                  const fillColor =
                    sampleTimeSeconds > currentTimeSeconds
                      ? colorBlack30
                      : colorBlack70

                  const x = onSeek
                    ? barOffset + index * (BAR_WIDTH + BAR_GAP)
                    : waveformWidth + index * (BAR_WIDTH + BAR_GAP)

                  return (
                    <rect
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      fill={fillColor}
                      x={x}
                      y={(WAVEFORM_MAX_HEIGHT - height) / 2}
                      width={BAR_WIDTH}
                      height={height}
                      rx={BAR_WIDTH / 2}
                    />
                  )
                })}
              </g>
            </svg>
          </Waveform>
          {onSeek && <AudioWaveformProgressIndicator />}
        </WaveformContainer>
        <Box id={TIME_LABEL_ID} minWidth="48px">
          {convertToMinutesAndSeconds(currentTimeSeconds)}
        </Box>
      </StyledContainer>
    </Box>
  )
}
