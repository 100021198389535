import { useTheme } from '@moonpig/launchpad-utils'
import { IconAdjust } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { animated, SpringValue, to } from 'react-spring'

const REARRANGE_ICON_SIZE = 36
const REARRANGE_ICON_BORDER_RADIUS = REARRANGE_ICON_SIZE * 0.6
const REARRANGE_ICON_BORDER_WIDTH = 3
const REARRANGE_ICON_OFFSET = REARRANGE_ICON_SIZE * -0.5

type RearrangeIndicatorProps = {
  animatedX: SpringValue<number>
  animatedY: SpringValue<number>
  animatedW: SpringValue<number>
  animatedH: SpringValue<number>
  scale: number
}

export const RearrangeIndicator: FC<RearrangeIndicatorProps> = ({
  animatedX,
  animatedY,
  animatedW,
  animatedH,
  scale,
}) => {
  const theme = useTheme()
  const { colorFeedbackInformation, colorBackground01 } = theme.palette

  return (
    <animated.g
      aria-hidden
      style={{ pointerEvents: 'none' }}
      transform={to(
        [animatedX, animatedY, animatedW, animatedH],
        (x, y, w, h) => {
          return `translate(${x + w * 0.5} ${y + h * 0.5}) scale(${scale})`
        },
      )}
    >
      <circle
        r={REARRANGE_ICON_BORDER_RADIUS}
        stroke={colorFeedbackInformation}
        strokeWidth={REARRANGE_ICON_BORDER_WIDTH}
        fill={colorBackground01}
      />
      <g
        transform={`translate(${REARRANGE_ICON_OFFSET} ${REARRANGE_ICON_OFFSET})`}
      >
        <IconAdjust width={REARRANGE_ICON_SIZE} height={REARRANGE_ICON_SIZE} />
      </g>
    </animated.g>
  )
}
