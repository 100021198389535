import { IconSystemLoader } from '@moonpig/launchpad-assets'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { DesignElement } from '@moonpig/web-personalise-editor-types'
import React, { FC } from 'react'
import { useCamera } from '../../../../../../../camera'

const LOADING_ICON_SIZE = 48

const StyledLoadingIndicator = styled.g`
  & > svg > * {
    ${s({ color: 'colorTextBody' })}
    animation: ed-element-loader-rotation infinite 2000ms linear;
    transform-origin: center;

    @keyframes ed-element-loader-rotation {
      0% {
        transform: rotateZ(0deg);
      }
      100% {
        transform: rotateZ(360deg);
      }
    }
  }
`

export const LoadingIndicator: FC<{ id?: string; element: DesignElement }> = ({
  id,
  element,
}) => {
  const { inverseScale } = useCamera()
  const iconSize = LOADING_ICON_SIZE * inverseScale
  const x = element.x + (element.width - iconSize) * 0.5
  const y = element.y + (element.height - iconSize) * 0.5

  return (
    <StyledLoadingIndicator id={id} role="progressbar">
      <IconSystemLoader x={x} y={y} width={iconSize} height={iconSize} />
    </StyledLoadingIndicator>
  )
}
