import { IllustrationBubbleError } from '@moonpig/launchpad-assets'
import { Flex, SecondaryButton, Text } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { PageHeading } from '@moonpig/web-personalise-components'
import React, { FC, useCallback } from 'react'
import { useAction, useMainView } from '../../store'
import { useEditorLocaleText } from '../../text-localisation'

const StyledPageHeading = styled(PageHeading)`
  ${s({
    mb: 2,
    typography: 'bodyBold',
  })}
`

export const ErrorUnsupportedFeatures: FC = () => {
  const t = useEditorLocaleText()
  const resettable = useMainView(
    'error-unsupported-features',
    view => view.resettable,
  )

  const resetDesign = useAction('resetDesign')
  const edit = useAction('edit')

  const handleReset = useCallback(() => {
    resetDesign()
    edit()
  }, [resetDesign, edit])

  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      padding={8}
    >
      <Flex maxWidth="320px" flexDirection="column">
        <Flex mb={10} justifyContent="center">
          <IllustrationBubbleError width={128} />
        </Flex>
        <Flex mb={4}>
          <StyledPageHeading>
            {t('unsupported_features_title')}
          </StyledPageHeading>
        </Flex>
        <Text typography="bodySmall">
          {resettable
            ? t('unsupported_features_description_resettable')
            : t('unsupported_features_description')}
        </Text>
        {resettable && (
          <SecondaryButton mt={6} onClick={handleReset}>
            {t('unsupported_features_reset_button')}
          </SecondaryButton>
        )}
      </Flex>
    </Flex>
  )
}
