import React, {
  FC,
  useState,
  useRef,
  useEffect,
  useMemo,
  useLayoutEffect,
} from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { animated, useTransition } from 'react-spring'
import { useDrag } from 'react-use-gesture'
import { Box } from '@moonpig/launchpad-components'

const CLOSED_OFFSET = -20

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px 8px 0 0;
  box-shadow: rgb(0 32 77 / 15%) 0px -3px 8px 0px;
  pointer-events: all;
  overflow: hidden;

  ${s({ bgcolor: 'colorBackground01' })}
`

const StyledMenuTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24px;
  flex-shrink: 0;
  z-index: 1;
`

const StyledMenuHandle = styled.div`
  width: 40px;
  height: 4px;
  border-radius: 2px;
  ${s({
    bgcolor: 'colorBorder03',
  })}
`

const StyledMenuMain = styled(Box)`
  min-height: 0;
  flex: 1;
`

type DrawerContentProps = {
  setContentHeight: (height: number) => void
  expand?: boolean
  overlapHandle?: boolean
}

const DrawerContent: FC<DrawerContentProps> = ({
  children,
  setContentHeight,
  expand,
  overlapHandle,
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (!expand) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const contentHeight = containerRef!.current!.clientHeight
      setContentHeight(contentHeight)
    }
  }, [setContentHeight, expand])

  return (
    <StyledMenu ref={containerRef} style={{ height: expand ? '100%' : 'auto' }}>
      <StyledMenuTop>
        <StyledMenuHandle />
      </StyledMenuTop>
      <StyledMenuMain mt={overlapHandle ? '-24px' : 0}>
        {children}
      </StyledMenuMain>
    </StyledMenu>
  )
}

export type DrawerStopPosition = number | 'auto'

type DrawerProps = {
  onClose: () => void
  stopPositions: DrawerStopPosition[]
  overlapHandle?: boolean
}

export const Drawer: FC<DrawerProps> = ({
  children,
  onClose,
  stopPositions,
  overlapHandle,
}) => {
  const [dragOffset, setDragOffset] = useState(0)
  const [stopIndex, setStopIndex] = useState(1)
  const isDragging = useRef(false)
  const [contentHeight, setContentHeight] = useState(0)

  useEffect(() => {
    if (!children) setStopIndex(1)
  }, [children])

  const isExpandable = stopPositions[0] === 'auto'

  const drawerContent = useMemo(() => {
    if (children) {
      return (
        <DrawerContent
          setContentHeight={setContentHeight}
          expand={!isExpandable}
          overlapHandle={overlapHandle}
        >
          {children}
        </DrawerContent>
      )
    }
  }, [children, isExpandable, overlapHandle])

  const allStopPositions = [0, ...stopPositions].map(stopPosition => {
    return stopPosition === 'auto' ? contentHeight : stopPosition
  })
  const lastStopPosition = allStopPositions[allStopPositions.length - 1]
  const currentStop = allStopPositions[stopIndex]

  const currentOffset = Math.min(currentStop + dragOffset, lastStopPosition)

  const transition = useTransition(drawerContent, {
    from: { offset: CLOSED_OFFSET },
    enter: { offset: currentOffset },
    update: { offset: currentOffset },
    leave: { offset: CLOSED_OFFSET },
    immediate: isDragging.current,
  })

  const bind = useDrag(({ movement, swipe, last }) => {
    const my = movement[1]
    isDragging.current = !last

    if (last) {
      let newIndex
      /* istanbul ignore next */
      if (swipe[1] !== 0) {
        newIndex = stopIndex - swipe[1]
      } else {
        const sortedDistances = allStopPositions
          .map((position, index) => {
            return {
              index,
              distance: Math.abs(position - (currentStop - my)),
            }
          })
          .sort((a, b) => a.distance - b.distance)
        /* istanbul ignore next */
        newIndex = sortedDistances[0].index
      }
      if (newIndex === 0) {
        setStopIndex(1)
        onClose()
      } else {
        setStopIndex(newIndex)
      }
      setDragOffset(0)
    } else {
      setDragOffset(-my)
    }
  })

  const nextStop =
    allStopPositions[Math.min(stopIndex + 1, allStopPositions.length)]

  return transition(
    (springs, content) =>
      content && (
        <animated.div
          {...bind()}
          data-testid="mp-drawer"
          style={{
            position: 'fixed',
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 2,
            transform: springs.offset.to(
              offset => `translateY(calc(100% - ${offset}px))`,
            ),
            height: springs.offset.to(offset =>
              offset > currentStop ? `${nextStop}px` : `${currentStop}px`,
            ),
          }}
        >
          {content}
        </animated.div>
      ),
  )
}
