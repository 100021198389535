import React, { FC } from 'react'

export const IconText: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 24 24" width={width} height={height}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H20C20.5304 2 21.0391 2.21071 21.4142 2.58579C21.7893 2.96086 22 3.46957 22 4V20C22 20.5304 21.7893 21.0391 21.4142 21.4142C21.0391 21.7893 20.5304 22 20 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2ZM0 4C0 2.93913 0.421427 1.92172 1.17157 1.17157C1.92172 0.421427 2.93913 0 4 0H20C21.0609 0 22.0783 0.421427 22.8284 1.17157C23.5786 1.92172 24 2.93913 24 4V20C24 21.0609 23.5786 22.0783 22.8284 22.8284C22.0783 23.5786 21.0609 24 20 24H4C2.93913 24 1.92172 23.5786 1.17157 22.8284C0.421427 22.0783 0 21.0609 0 20V4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 5H6V7.286C6 7.55122 6.10536 7.80557 6.29289 7.99311C6.48043 8.18064 6.73478 8.286 7 8.286C7.26522 8.286 7.51957 8.18064 7.70711 7.99311C7.89464 7.80557 8 7.55122 8 7.286V7H11V17H10.5C10.2348 17 9.98043 17.1054 9.79289 17.2929C9.60536 17.4804 9.5 17.7348 9.5 18C9.5 18.2652 9.60536 18.5196 9.79289 18.7071C9.98043 18.8946 10.2348 19 10.5 19H13.5C13.7652 19 14.0196 18.8946 14.2071 18.7071C14.3946 18.5196 14.5 18.2652 14.5 18C14.5 17.7348 14.3946 17.4804 14.2071 17.2929C14.0196 17.1054 13.7652 17 13.5 17H13V7H16V7.286C16 7.55122 16.1054 7.80557 16.2929 7.99311C16.4804 8.18064 16.7348 8.286 17 8.286C17.2652 8.286 17.5196 8.18064 17.7071 7.99311C17.8946 7.80557 18 7.55122 18 7.286V5Z"
        fill="currentColor"
      />
    </svg>
  )
}
