import { DesignElementOverlayText } from '@moonpig/web-personalise-editor-types'
import { updateDesignElement } from '../../common/updateDesignElement'
import { ActionCreator } from '../../types'
import { storeUpdateDesign } from '../../updaters/design'

export const createUpdateStyledElementStyles: ActionCreator<
  'updateStyledElementStyles'
> =
  ({ set }) =>
  customisations => {
    set(store => {
      return storeUpdateDesign(
        store,
        { changeReason: 'element-changed' },
        design => {
          const { sceneIds, sceneById } = design

          const styledElements = sceneIds.reduce<DesignElementOverlayText[]>(
            (acc, sceneId) => {
              const scene = sceneById[sceneId]

              scene.elementIds.forEach(elementId => {
                const element = scene.elementById[elementId]

                /* istanbul ignore else */
                if (element.type === 'overlay-text') {
                  acc.push(element)
                }
              })
              return acc
            },
            [],
          )

          let updatedDesign = design

          styledElements.forEach(styledElement => {
            updatedDesign = updateDesignElement(updatedDesign, {
              ...styledElement,
              customisations: {
                ...styledElement.customisations,
                ...customisations,
              },
            })
          })

          return updatedDesign
        },
      )
    })
  }
