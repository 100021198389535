import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { MenuHeader } from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { DESKTOP_BP, FOCUS_ID_MENU } from '../../../../../../../constants'

const StyledMenuHeader = styled.div`
  ${s({ px: 6, mt: { [DESKTOP_BP]: 8 } })}
`

type MenuHeaderDefaultProps = {
  title: string
  titleId?: string
  closeLabel: string
  onClose: () => void
}

export const MenuHeaderDefault: FC<MenuHeaderDefaultProps> = ({
  title,
  titleId,
  closeLabel,
  onClose,
}) => {
  return (
    <StyledMenuHeader>
      <MenuHeader
        heading={title}
        headingId={titleId}
        closeLabel={closeLabel}
        onClose={onClose}
        focusId={FOCUS_ID_MENU}
      />
    </StyledMenuHeader>
  )
}
