import {
  DesignHorizontalAlignment,
  DesignVerticalAlignment,
} from '@moonpig/web-personalise-editor-types'
import { formatDuration } from './format-duration'
import { formatFileSize } from './format-file-size'

const NON_BREAKING_SPACE = '\u00A0'

export const EDITOR_TEXT = {
  add_to_basket_error: {
    'en-GB': `The editor is unable to save your design`,
    'nl-NL': `De editor kan je ontwerp niet opslaan`,
  },
  add_to_basket_loading_title: {
    'en-GB': `All looking good`,
    'nl-NL': `Ziet er goed uit`,
  },
  add_to_basket_loading_description: {
    'en-GB': `Your creation is being added to your basket`,
    'nl-NL': `De kaart is toegevoegd aan je winkelmandje.`,
  },
  update_basket_loading_title: {
    'en-GB': `Looking even better`,
    'nl-NL': `Dat ziet er nog beter uit`,
  },
  update_basket_loading_description: {
    'en-GB': `Your creation is being saved to your basket`,
    'nl-NL': `Je creatie wordt opgeslagen in je winkelmandje`,
  },
  exiting_title: {
    'en-GB': `Exiting`,
    'nl-NL': `Afsluiten`,
  },
  exiting_description: {
    'en-GB': `Your creation is being saved`,
    'nl-NL': `Je creatie wordt opgeslagen`,
  },
  toolbar_image_toolbar_title: {
    'en-GB': `Photo editing`,
    'nl-NL': `Fotobewerking`,
  },
  toolbar_sticker_toolbar_title: {
    'en-GB': `Sticker editing`,
    'nl-NL': `Stickers bewerken`,
  },
  toolbar_layout_toolbar_title: {
    'en-GB': 'Layouts',
    'nl-NL': 'Opmaak',
  },
  toolbar_text_toolbar_title: {
    'en-GB': `Text editing`,
    'nl-NL': `Tekstbewerking`,
  },
  smart_text_onboarding_tooltip_text: {
    'en-GB':
      'Not sure what to write? Smart Text can help you create a personalised message',
    'nl-NL':
      'Geen idee wat je moet schrijven? Teksthulp kan je helpen om iets persoonlijks te schrijven',
  },
  video_onboarding_tooltip_text: {
    'en-GB': 'Send a heartfelt message with a video 🎥',
    'nl-NL': 'Stuur een persoonlijke videoboodschap 🎥',
  },
  tooltip_onboarding_tooltip_done_label: {
    'en-GB': 'Done',
    'nl-NL': 'Klaar',
  },
  tooltip_onboarding_dismiss_aria_label: {
    'en-GB': 'Dismiss tip',
    'nl-NL': 'Tip overslaan',
  },
  smart_text_dismiss_more_info_aria_label: {
    'en-GB': 'Dismiss more information tooltip',
    'nl-NL': 'Tip meer info overslaan',
  },
  toolbar_smart_text: {
    'en-GB': `Smart Text`,
    'nl-NL': `Teksthulp`,
  },
  toolbar_close_smart_text_menu: {
    'en-GB': `Close Smart Text menu`,
    'nl-NL': `Menu Teksthulp sluiten`,
  },
  toolbar_smart_text_aria_label: {
    'en-GB': `Add smart text to the card`,
    'nl-NL': `Voeg een Teksthulp toe aan je kaart`,
  },
  toolbar_image_toolbar_close_label: {
    'en-GB': `Close photo editing toolbar`,
    'nl-NL': `Werkbalk voor fotobewerking sluiten`,
  },
  smart_text_intro_text: {
    'en-GB': `Create personalised messages for your recipient using our new AI tool.`,
    'nl-NL': `Schrijf iets persoonlijks met onze AI-tool.`,
  },
  smart_text_disclaimer_text: {
    'en-GB': ({
      privacyNoticeUrl,
      termsAndConditionsUrl,
      userGeneratedTermsAndConditionsUrl,
    }: {
      privacyNoticeUrl: string
      termsAndConditionsUrl: string
      userGeneratedTermsAndConditionsUrl: string
    }) =>
      `By using this AI text generating tool, you agree to our <a href="${userGeneratedTermsAndConditionsUrl}" target="_blank" rel="noopener">Moonpig terms for user generated content</a> and <a href="${termsAndConditionsUrl}" target="_blank" rel="noopener">Terms and Conditions</a>. Moonpig does not endorse or guarantee the accuracy or quality of the content that is generated. Also see our <a href="${privacyNoticeUrl}" target="_blank" rel="noopener">Privacy Notice</a>.`,
    'nl-NL': ({
      privacyNoticeUrl,
      termsAndConditionsUrl,
      userGeneratedTermsAndConditionsUrl,
    }: {
      privacyNoticeUrl: string
      termsAndConditionsUrl: string
      userGeneratedTermsAndConditionsUrl: string
    }) =>
      `Door deze AI-tekstgenerator te gebruiken, ga je akkoord met onze <a href="${termsAndConditionsUrl}" target="_blank" rel="noopener">algemene voorwaarden</a> en <a href="${userGeneratedTermsAndConditionsUrl}" target="_blank" rel="noopener">aanvullende voorwaarden</a>. Greetz is niet verantwoordelijk voor de juistheid of kwaliteit van de tekst die deze tool genereert. Zie ook het <a href="${privacyNoticeUrl}" target="_blank" rel="noopener">Privacybeleid</a>.`,
  },
  smart_text_label: {
    'en-GB': 'What would you like to say?',
    'nl-NL': 'Wat zou je willen zeggen?',
  },
  smart_text_info_label: {
    'en-GB': 'More information about Smart Text',
    'nl-NL': 'Meer info over Teksthulp',
  },
  smart_text_info_text: {
    'en-GB':
      'In a few words, what would you like your message to include. Some ideas for this are:',
    'nl-NL':
      'Beschrijf in een paar woorden wat er in de tekst moet komen. Een paar ideeën,',
  },
  smart_text_recipient_name: {
    'en-GB': "your recipient's name and age",
    'nl-NL': 'naam en leeftijd van de ontvanger',
  },
  smart_text_recipient_occasion: {
    'en-GB': 'the occasion',
    'nl-NL': 'de gelegenheid',
  },
  smart_text_tone_of_message: {
    'en-GB': 'the tone of the message',
    'nl-NL': 'de toon van de tekst',
  },
  smart_text_fact_on_message: {
    'en-GB': 'a fact you want mentioned',
    'nl-NL': 'een feitje dat je graag wilt noemen',
  },
  smart_text_placeholder: {
    'en-GB':
      "eg. Write me a fun message for my sister's birthday. She's turning 30 and loves plants.",
    'nl-NL':
      'bijv. Schrijf een leuke tekst voor de verjaardag van mijn zus. Ze wordt 30 en ze houdt van planten.',
  },
  smart_text_action_button_label: {
    'en-GB': 'Generate Smart Text',
    'nl-NL': 'Teksthulp ophalen',
  },
  smart_text_limit_reached_title: {
    'en-GB': 'Maximum message limit reached',
    'nl-NL': 'Maximum bereikt',
  },
  smart_text_limit_error: {
    'en-GB': 'We can only support 25 messages per day.',
    'nl-NL': 'Je kunt maximaal 25 teksten per dag ingeven.',
  },
  smart_text_moderation_error: {
    'en-GB':
      "Sorry, we can't use that input. Please edit your message to continue.",
    'nl-NL':
      'Sorry, we kunnen deze opdracht niet uitvoeren. Pas je opdracht aan en probeer het opnieuw.',
  },
  smart_text_generic_error_title: {
    'en-GB': 'Oops! Something went wrong.',
    'nl-NL': 'Oeps, er ging iets mis.',
  },
  smart_text_generic_error: {
    'en-GB': 'Please try again.',
    'nl-NL': 'Probeer het even opnieuw.',
  },
  smart_text_feature_unavailable_title: {
    'en-GB': "This feature isn't available right now.",
    'nl-NL': 'Deze functie is nu niet beschikbaar.',
  },
  smart_text_unavailable_error: {
    'en-GB': 'Please try again later.',
    'nl-NL': 'Probeer het later opnieuw.',
  },
  toolbar_sticker_toolbar_close_label: {
    'en-GB': `Close sticker editing toolbar`,
    'nl-NL': `Stickerwerkbalk sluiten`,
  },
  toolbar_text_toolbar_close_label: {
    'en-GB': `Close text editing toolbar`,
    'nl-NL': `Werkbalk voor tekstbewerking sluiten`,
  },
  toolbar_menu_choose_photo_label: {
    'en-GB': `Photo Library`,
    'nl-NL': `Fotobibliotheek`,
  },
  tooltip_onboarding_tooltip_next_label: {
    'en-GB': `Next`,
    'nl-NL': 'Verder',
  },
  tooltip_onboarding_next_aria_label: {
    'en-GB': 'Show next tip',
    'nl-NL': 'Volgende tip bekijken',
  },
  tooltip_onboarding_tooltip_skip_label: {
    'en-GB': `Skip`,
    'nl-NL': 'Overslaan',
  },
  tooltip_onboarding_skip_aria_label: {
    'en-GB': 'Skip all tips',
    'nl-NL': 'Alle tips overslaan',
  },
  toolbar_menu_close_photo_library_label: {
    'en-GB': `Close photo library`,
    'nl-NL': `Fotobibliotheek sluiten`,
  },
  toolbar_menu_adjust_photo_label: {
    'en-GB': `Adjust`,
    'nl-NL': `Aanpassen`,
  },
  toolbar_menu_adjust_sticker_label: {
    'en-GB': `Adjust`,
    'nl-NL': `Aanpassen`,
  },
  toolbar_menu_close_adjust_label: {
    'en-GB': `Close adjust`,
    'nl-NL': `Aanpassen sluiten`,
  },
  toolbar_styled_text_apply_colour_all: {
    'en-GB': 'Apply colour to all',
    'nl-NL': 'Tekstkleur overal toepassen',
  },
  toolbar_styled_text_apply_font_all: {
    'en-GB': 'Apply font to all',
    'nl-NL': 'Lettertype overal toepassen',
  },
  continue_draft_text: {
    'en-GB': `We have retrieved your personalised design.`,
    'nl-NL': `Je hebt dit product al gepersonaliseerd. Wat wil je doen?`,
  },
  continue_draft_title: {
    'en-GB': `Continue your creation`,
    'nl-NL': `Doorgaan met je creatie`,
  },
  continue_draft_continue_button: {
    'en-GB': `Continue`,
    'nl-NL': `Doorgaan met creatie`,
  },
  continue_draft_discard_button: {
    'en-GB': `Discard Changes`,
    'nl-NL': `Creatie verwijderen`,
  },
  edit_clear_button: {
    'en-GB': `Clear`,
    'nl-NL': `Wissen`,
  },
  toolbar_text_color_menu_heading: {
    'en-GB': `Text Colour`,
    'nl-NL': `Tekstkleur`,
  },
  toolbar_font_menu_heading: {
    'en-GB': `Font`,
    'nl-NL': `Lettertype`,
  },
  toolbar_text_size_menu_heading: {
    'en-GB': `Text Size`,
    'nl-NL': `Tekstgrootte`,
  },
  toolbar_text_menu_heading: {
    'en-GB': `Edit Text`,
    'nl-NL': `Tekst bewerken`,
  },
  toolbar_next_label: {
    'en-GB': `View next options`,
    'nl-NL': 'Bekijk volgende',
  },
  toolbar_previous_label: {
    'en-GB': `View previous options`,
    'nl-NL': 'Bekijk vorige',
  },
  toolbar_text_scale_menu_heading: {
    'en-GB': 'Scale',
    'nl-NL': 'Schaal',
  },
  edit_heading_screen_reader_text: {
    'en-GB': `Edit Your Design`,
    'nl-NL': `Je ontwerp bewerken`,
  },
  edit_exit_cta_text: {
    'en-GB': `Exit`,
    'nl-NL': `Afsluiten`,
  },
  edit_preview_cta_text: {
    'en-GB': `Preview`,
    'nl-NL': `Verder`,
  },
  toolbar_text_alignment_menu_heading: {
    'en-GB': `Text Alignment`,
    'nl-NL': `Tekstuitlijning`,
  },
  edit_total_used_character_label: {
    'en-GB': (usedLabel: string, total: number) =>
      `${usedLabel} (${total} character limit)`,
    'nl-NL': (usedLabel: string, total: number) =>
      `${usedLabel} (max. ${total} tekens)`,
  },
  edit_used_multiple_characters_label: {
    'en-GB': (used: number) => `Used ${used} characters`,
    'nl-NL': (used: number) => `${used} tekens gebruikt`,
  },
  edit_used_one_character_label: {
    'en-GB': `Used 1 character`,
    'nl-NL': `1 teken gebruikt`,
  },
  loading: {
    'en-GB': `Let’s get started`,
    'nl-NL': `Aan de slag`,
  },
  loading_description: {
    'en-GB': 'Personalisation tool is loading',
    'nl-NL': `Personalisatietool is aan het laden`,
  },
  loading_error_title: {
    'en-GB': `Please refresh the page`,
    'nl-NL': `Vernieuw de pagina`,
  },
  loading_error_description: {
    'en-GB': `Something went wrong when loading the personalisation tool, please try again.`,
    'nl-NL': `Er ging iets mis bij het laden van de personalisatietool, probeer het opnieuw.`,
  },
  color_name_not_found_title: {
    'en-GB': (value: string) => `Hex ${value}`,
    'nl-NL': (value: string) => `Hex ${value}`,
  },
  loading_product_not_found_title: {
    'en-GB': `Product not found`,
    'nl-NL': `Product niet gevonden`,
  },
  loading_product_not_found_description: {
    'en-GB': (productKey: string) =>
      `Sorry, we could not find product "${productKey}"`,
    'nl-NL': (productKey: string) =>
      `Sorry, we konden product "${productKey}" niet vinden`,
  },
  loading_design_not_found_title: {
    'en-GB': `Design not found`,
    'nl-NL': `Ontwerp niet gevonden`,
  },
  loading_design_not_found_description: {
    'en-GB': (designId: string) =>
      `Sorry, we could not find design "${designId}"`,
    'nl-NL': (designId: string) =>
      `Sorry, we konden ontwerp "${designId}" niet vinden`,
  },
  loading_design_not_editable_title: {
    'en-GB': `Design not editable`,
    'nl-NL': `Ontwerp kan niet bewerkt worden`,
  },
  loading_design_not_editable_description: {
    'en-GB': (designId: string) =>
      `Sorry, design "${designId}" is no longer editable`,
    'nl-NL': (designId: string) =>
      `Sorry, ontwerp "${designId}" is niet langer bewerkbaar`,
  },
  unsupported_features_title: {
    'en-GB': 'Some features are unavailable 💔',
    'nl-NL': 'Sommige functies zijn niet beschikbaar 💔',
  },
  unsupported_features_description: {
    'en-GB':
      'Unfortunately some of the awesome features you used on this card aren’t on the web yet! Try using the device you started with to continue.',
    'nl-NL':
      'Helaas zijn sommige handige functies die je hebt gebruikt op deze kaart nog niet beschikbaar op het web! Ga verder op het apparaat waarmee je bent begonnen om door te gaan.',
  },
  unsupported_features_description_resettable: {
    'en-GB':
      'Unfortunately some of the awesome features you used on this card aren’t on the web yet!',
    'nl-NL':
      'Helaas zijn sommige handige functies die je hebt gebruikt op deze kaart nog niet beschikbaar op het web!',
  },
  unsupported_features_reset_button: {
    'en-GB': 'Start creating from scratch',
    'nl-NL': 'Maak een nieuwe creatie',
  },
  missing_required_image_title: {
    'en-GB': `Missing photo`,
    'nl-NL': `De foto ontbreekt nog`,
  },
  missing_required_image_text: {
    'en-GB': `Oops! It looks like you’ve not uploaded an image to this field, please add one before continuing.`,
    'nl-NL': `Oeps! Het lijkt erop dat je nog geen foto hebt geüpload. Voeg een foto toe voordat je verdergaat.`,
  },
  missing_required_collage_title: {
    'en-GB': `Missing photo`,
    'nl-NL': `Foto ontbreekt`,
  },
  missing_required_collage_text: {
    'en-GB': `Oops! It looks like you’ve not uploaded an image to this field, please add at least one before continuing.`,
    'nl-NL': `Oeps! Het lijkt erop dat je nog geen foto hebt geüpload. Voeg er minstens eentje toe voordat je verdergaat.`,
  },
  missing_required_text_input_title: {
    'en-GB': `Missing text`,
    'nl-NL': `Tekst ontbreekt`,
  },
  missing_required_text_input_text: {
    'en-GB': `Oops! It looks like you’ve not added any text to this field, please add some before continuing.`,
    'nl-NL': `Oeps! 'Het lijkt erop dat je geen tekst aan dit vak hebt toegevoegd. Geen een tekst in voordat je verdergaat.`,
  },
  preview_mug_front_label: {
    'en-GB': () => 'View front side',
    'nl-NL': () => 'Voorkant bekijken',
  },
  preview_mug_left_label: {
    'en-GB': () => 'View left side',
    'nl-NL': () => 'Linkerkant bekijken',
  },
  preview_mug_right_label: {
    'en-GB': () => 'View right side',
    'nl-NL': () => 'Rechterkant bekijken',
  },
  filtered_notification_warning_emojis_title: {
    'en-GB': `Emojis not available 🙈`,
    'nl-NL': `Emoticons niet beschikbaar 🙈`,
  },
  filtered_notification_warning_emojis_text: {
    'en-GB': `Sorry! We can’t currently print emojis, so we’ve removed them for you.`,
    'nl-NL': `Sorry, we kunnen op dit moment geen emoticons printen. We hebben ze daarom voor je verwijderd.`,
  },
  filtered_notification_warning_characters_text: {
    'en-GB': `Sorry! We can’t currently print some of the characters entered, so we’ve removed them for you.`,
    'nl-NL': `Sorry, we kunnen op dit moment enkele van de tekens die je hebt gebruikt niet printen. We hebben ze daarom voor je verwijderd.`,
  },
  filtered_notification_warning_characters_title: {
    'en-GB': `Characters not available`,
    'nl-NL': `Tekens niet beschikbaar`,
  },
  notification_truncated_text_message: {
    'en-GB': `Your message is too long, so weʼve shortened it to fit. Make sure youʼre happy with whatʼs left.`,
    'nl-NL': `Je tekst was te lang en we we hebben deze passend vor je gemaakt. 'Kijk even of je tevreden bent met het resultaat.`,
  },
  notification_truncated_text_title: {
    'en-GB': `Character limit reached`,
    'nl-NL': `Maximaal aantal tekens bereikt`,
  },
  notification_card_multibuy_text: {
    'en-GB': (quantity: number) =>
      `Please note the same design will appear on all ${quantity} cards.`,
    'nl-NL': (quantity: number) =>
      `Houd er rekening mee dat hetzelfde ontwerp en dezelfde boodschap op alle ${quantity} kaarten verschijnen.`,
  },
  notification_card_multibuy_title: {
    'en-GB': `You've selected multi-buy!`,
    'nl-NL': `Je hebt gekozen voor stapelkorting!`,
  },
  notification_maximum_overlay_text_added_text: {
    'en-GB': `You've already added the maximum number of text boxes. Please delete one if you'd like to add more`,
    'nl-NL': `Je hebt al het maximale aantal tekstvelden toegevoegd. Verwijder er eentje als je meer velden wilt toevoegen`,
  },
  notification_maximum_stickers_added_text: {
    'en-GB': `You've already added the maximum number of stickers. Please delete one if you'd like to add more`,
    'nl-NL': `Je hebt al het maximale aantal stickers toegevoegd. Verwijder er eentje als je meer stickers wilt toevoegen.`,
  },
  notification_maximum_photos_added_text: {
    'en-GB': `You've already added the maximum number of photos. Please delete one if you'd like to add more`,
    'nl-NL': `Je hebt het maximale aantal foto's al toegevoed. Verwijder er eentje als je er meer wilt toevoegen`,
  },
  notification_maximum_overlay_text_added_title: {
    'en-GB': `Maximum textboxes added`,
    'nl-NL': `Maximale aantal tekstvelden toegevoegd`,
  },
  notification_maximum_stickers_added_title: {
    'en-GB': `Maximum stickers added`,
    'nl-NL': `Maximale aantal stickers toegevoegd`,
  },
  notification_maximum_photos_added_title: {
    'en-GB': `Maximum photos added`,
    'nl-NL': `Maximumaantal foto's toegevoegd`,
  },
  notification_voucher_card_text: {
    'en-GB': 'Your exciting voucher will be printed in this FREE card.',
    'nl-NL': 'Je cadeaubon wordt in deze GRATIS kaart afgedrukt.',
  },
  notification_voucher_card_title: {
    'en-GB': 'This Card’s On Us!',
    'nl-NL': 'Deze kaart krijg je van ons!',
  },
  notification_profanity_blocked_title: {
    'en-GB': 'Profanity Detected',
    'nl-NL': 'Ongepast taalgebruik gevonden',
  },
  notification_profanity_blocked_text: {
    'en-GB':
      'Oops! This brand doesn’t allow profanity. Please edit your message to continue.',
    'nl-NL':
      'Oeps! Dit merk staat geen ongepast taalgebruik toe. Pas je tekst aan om verder te kunnen.',
  },
  page_title_editable_elements: {
    'en-GB': (pageTitle: string, editableElementCount: number) => {
      switch (editableElementCount) {
        case 0: {
          return `${pageTitle}. No editable elements on this page`
        }
        case 1: {
          return `${pageTitle}. 1 editable element on this page`
        }
        default: {
          return `${pageTitle}. ${editableElementCount} editable elements on this page`
        }
      }
    },
    'nl-NL': (pageTitle: string, editableElementCount: number) => {
      switch (editableElementCount) {
        case 0: {
          return `${pageTitle}. Geen bewerkbare elementen op deze pagina`
        }
        case 1: {
          return `${pageTitle}. 1 bewerkbaar element op deze pagina`
        }
        default: {
          return `${pageTitle}. ${editableElementCount} bewerkbare elementen op deze pagina`
        }
      }
    },
  },
  edit_image_element_title: {
    'en-GB': (pageTitle: string, current: number, total: number) => {
      return `Edit ${pageTitle} image ${current} of ${total}`
    },
    'nl-NL': (pageTitle: string, current: number, total: number) => {
      return `Afbeelding ${pageTitle} bewerken ${current} van ${total}`
    },
  },
  edit_text_element_title: {
    'en-GB': (pageTitle: string, current: number, total: number) => {
      return `Edit ${pageTitle} text box ${current} of ${total}`
    },
    'nl-NL': (pageTitle: string, current: number, total: number) => {
      return `Tekstvak aan de ${pageTitle} bewerken ${current} van ${total}`
    },
  },
  edit_sticker_element_title: {
    'en-GB': (pageTitle: string, current: number, total: number) => {
      return `Edit ${pageTitle} sticker ${current} of ${total}`
    },
    'nl-NL': (pageTitle: string, current: number, total: number) => {
      return `${pageTitle} sticker ${current} van ${total} bewerken`
    },
  },
  photo_missing_text: {
    'en-GB': `We need you to upload the photo again to edit it. Sorry about that!`,
    'nl-NL': `Sorry, upload de foto opnieuw om deze te kunnen bewerken.’,`,
  },
  photo_missing_title: {
    'en-GB': `Please re-add photo`,
    'nl-NL': `Voeg foto opnieuw toe`,
  },
  collage_photo_missing_text: {
    'en-GB': `We need you to upload the photos again to edit the collage. Sorry about that!`,
    'nl-NL': `Om de collage te kunnen bewerken, moet je de foto's opnieuw toevoegen. Sorry daarvoor!`,
  },
  collage_photo_missing_title: {
    'en-GB': `Please re-add photos`,
    'nl-NL': `Voeg opnieuw foto's toe`,
  },
  placeholder_text_form_editable_alert_text: {
    'en-GB': `Only words highlighted below are editable.`,
    'nl-NL': `Alleen de hieronder gemarkeerde woorden zijn bewerkbaar.`,
  },
  placeholder_text_form_screen_reader_legend: {
    'en-GB': `Edit text parts`,
    'nl-NL': `Tekstonderdelen bewerken`,
  },
  editable_text_part_label: {
    'en-GB': (num: number) => `Editable text part ${num}`,
    'nl-NL': (num: number) => `Bewerkbare tekst deel ${num}`,
  },
  preview_error_alert_title: {
    'en-GB': `Please refresh the page`,
    'nl-NL': `Vernieuw de pagina`,
  },
  preview_error_alert_description: {
    'en-GB': `Something went wrong when creating your preview, please try again.`,
    'nl-NL': `Er ging iets mis bij het aanmaken van je voorbeeld. Probeer het opnieuw.`,
  },
  preview_error_notification_title: {
    'en-GB': `We weren’t able to create your preview`,
    'nl-NL': `We konden je preview niet maken`,
  },
  preview_error_notification_text: {
    'en-GB': `You can still order this and we’ll deliver it.`,
    'nl-NL': `Maar je kunt dit nog steeds bestellen en laten bezorgen.`,
  },
  preview_heading_screen_reader_text: {
    'en-GB': `Preview of Your Design`,
    'nl-NL': `Voorbeeld van je ontwerp`,
  },
  rotation_label: {
    'en-GB': `Rotation`,
    'nl-NL': `Draaien`,
  },
  styled_text_add_text_label: {
    'en-GB': `Add Text`,
    'nl-NL': `Tekst toevoegen`,
  },
  toolbar_add_photos_label: {
    'en-GB': `Add Photos`,
    'nl-NL': `Foto's toevoegen`,
  },
  toolbar_add_photos_text: {
    'en-GB': `Add\nPhotos`,
    'nl-NL': `Foto's`,
  },
  toolbar_apply_image_screen_reader_label: {
    'en-GB': (name: string) => `Apply photo ${name}`,
    'nl-NL': (name: string) => `Foto ${name} toevoegen`,
  },
  toolbar_remove_image_screen_reader_label: {
    'en-GB': (name: string) => `Remove photo ${name}`,
    'nl-NL': (name: string) => `Foto ${name} verwijderen`,
  },
  toolbar_character_limit_reached_error_helper_text: {
    'en-GB': `Maximum character limit reached.`,
    'nl-NL': `Maximaal aantal tekens bereikt.`,
  },
  toolbar_delete_image_screen_reader_label: {
    'en-GB': (name: string) => `Delete photo ${name}`,
    'nl-NL': (name: string) => `Foto ${name} verwijderen`,
  },
  toolbar_done_button: {
    'en-GB': `Done`,
    'nl-NL': `Klaar`,
  },
  toolbar_image_placeholder_screen_reader_label: {
    'en-GB': (name: string) => `Processing photo ${name}`,
    'nl-NL': (name: string) => `Foto ${name} aan het verwerken`,
  },
  toolbar_loading_font_list_text: {
    'en-GB': (fontName: string) => `Loading ${fontName}…`,
    'nl-NL': (fontName: string) => `${fontName} aan het laden…`,
  },
  toolbar_stickers_label: {
    'en-GB': 'Stickers',
    'nl-NL': 'Stickers',
  },
  toolbar_handwriting_label: {
    'en-GB': 'Handwriting',
  },
  toolbar_sticker_delete_label: {
    'en-GB': 'Delete',
    'nl-NL': 'Verwijderen',
  },
  toolbar_sticker_delete_aria_label: {
    'en-GB': 'Delete sticker',
    'nl-NL': 'Sticker verwijderen',
  },
  toolbar_video_toolbar_title: {
    'en-GB': 'Video editing',
    'nl-NL': 'Video bewerken',
  },
  toolbar_audio_toolbar_title: {
    'en-GB': 'Audio editing',
    'nl-NL': 'Audio bewerken',
  },
  toolbar_video_toolbar_close_label: {
    'en-GB': 'Close video editing toolbar',
    'nl-NL': 'Werkbalk voor bewerken video sluiten',
  },
  toolbar_audio_toolbar_close_label: {
    'en-GB': 'Close audio editing toolbar',
    'nl-NL': 'Werkbalk voor audio bewerken sluiten',
  },
  audio_error_title: {
    'en-GB': 'Unable to play audio',
    'nl-NL': 'De audio kan niet worden afgespeeld',
  },
  audio_error_description: {
    'en-GB':
      'There might be a problem with the recording or internet connection.',
    'nl-NL': 'Er kan iets mis zijn met de opname of de internetverbinding.',
  },
  media_already_exists_warning_title: {
    'en-GB': "We can't support both!",
    'nl-NL': 'Allebei kan helaas niet! ',
  },
  audio_already_exists_warning_description: {
    'en-GB': 'Adding a video will replace your audio message.',
    'nl-NL': 'Als je een video toevoegt, vervangt deze je audio.',
  },
  audio_already_exists_warning_continue_button: {
    'en-GB': 'Replace audio',
    'nl-NL': 'Audio vervangen',
  },
  video_already_exists_warning_description: {
    'en-GB': 'Adding an audio message will replace your video.',
    'nl-NL': 'Als je een audio toevoegt, vervangt deze je video.',
  },
  video_already_exists_warning_continue_button: {
    'en-GB': 'Replace video',
    'nl-NL': 'Video vervangen',
  },
  toolbar_text_delete_label: {
    'en-GB': 'Delete',
    'nl-NL': 'Verwijderen',
  },
  toolbar_text_delete_aria_label: {
    'en-GB': 'Delete text',
    'nl-NL': 'Verwijder tekst',
  },
  toolbar_photo_delete_aria_label: {
    'en-GB': 'Delete photo',
    'nl-NL': 'Foto verwijderen',
  },
  toolbar_move_to_front_label: {
    'en-GB': 'To Front',
    'nl-NL': 'Vooraan',
  },
  toolbar_sticker_move_to_front_aria_label: {
    'en-GB': 'Move sticker to front',
    'nl-NL': 'Sticker naar voorkant',
  },
  toolbar_text_move_to_front_aria_label: {
    'en-GB': 'Move text to front',
    'nl-NL': 'Tekst naar voorkant',
  },
  toolbar_photo_move_to_front_aria_label: {
    'en-GB': 'Move photo to front',
    'nl-NL': 'Foto naar voorkant',
  },
  toolbar_move_to_back_label: {
    'en-GB': 'To Back',
    'nl-NL': 'Achteraan',
  },
  toolbar_sticker_move_to_back_aria_label: {
    'en-GB': 'Move sticker to back',
    'nl-NL': 'Sticker naar achterkant',
  },
  toolbar_text_move_to_back_aria_label: {
    'en-GB': 'Move text to back',
    'nl-NL': 'Tekst naar achterkant',
  },
  toolbar_photo_move_to_back_aria_label: {
    'en-GB': 'Move photo to back',
    'nl-NL': 'Foto naar achterkant',
  },
  toolbar_sticker_adjust_label: {
    'en-GB': 'Adjust',
    'nl-NL': 'Aanpassen',
  },
  toolbar_sticker_adjust_aria_label: {
    'en-GB': 'Adjust sticker',
    'nl-NL': 'Sticker aanpassen',
  },
  toolbar_image_photos_label: {
    'en-GB': 'Photos',
    'nl-NL': `Foto's`,
  },
  toolbar_image_replace_label: {
    'en-GB': 'Replace',
    'nl-NL': 'Vervangen',
  },
  toolbar_image_adjust_label: {
    'en-GB': 'Adjust',
    'nl-NL': `Aanpassen`,
  },
  toolbar_keyboard_label: {
    'en-GB': 'Keyboard',
    'nl-NL': `Toetsenbord`,
  },
  toolbar_font_label: {
    'en-GB': 'Font',
    'nl-NL': `Lettertype`,
  },
  toolbar_text_size_label: {
    'en-GB': 'Size',
    'nl-NL': `Formaat`,
  },
  toolbar_text_color_label: {
    'en-GB': 'Colour',
    'nl-NL': `Kleur`,
  },
  toolbar_text_alignment_label: {
    'en-GB': 'Align',
    'nl-NL': `Uitlijning`,
  },
  toolbar_text_label: {
    'en-GB': 'Text',
    'nl-NL': `Tekst`,
  },
  toolbar_scale_label: {
    'en-GB': 'Scale',
    'nl-NL': 'Schaal',
  },
  toolbar_text_scale_aria_label: {
    'en-GB': 'Scale text',
    'nl-NL': 'Schaal tekst',
  },
  toolbar_text_rotate_menu_heading: {
    'en-GB': 'Rotate',
    'nl-NL': 'Draaien',
  },
  toolbar_rotate_label: {
    'en-GB': 'Rotate',
    'nl-NL': 'Draaien',
  },
  toolbar_text_rotate_aria_label: {
    'en-GB': 'Rotate text',
    'nl-NL': 'Draai de tekst',
  },
  toolbar_add_photo: {
    'en-GB': () => `Add photo`,
    'nl-NL': () => `Foto toevoegen`,
  },
  toolbar_replace_photo: {
    'en-GB': () => `Replace photo`,
    'nl-NL': () => `Foto vervangen`,
  },
  toolbar_adjust: {
    'en-GB': () => `Adjust photo`,
    'nl-NL': `Foto aanpassen`,
  },
  toolbar_open_keyboard: {
    'en-GB': 'Open keyboard',
    'nl-NL': `Toetsenbord openen`,
  },
  toolbar_current_font: {
    'en-GB': (name: string) => `Font: ${name}`,
    'nl-NL': (name: string) => `Lettertype: ${name}`,
  },
  toolbar_current_text_size: {
    'en-GB': (size: number) => `Font size: ${size} points`,
    'nl-NL': (size: number) => `Lettertypegrootte: ${size} punten`,
  },
  toolbar_current_text_color: {
    'en-GB': (color: string) => `Font colour: ${color}`,
    'nl-NL': (color: string) => `Kleur lettertype: ${color}`,
  },
  toolbar_current_text_alignment: {
    'en-GB': (vertical: string, horizontal: string) =>
      `Text alignment: ${vertical}, ${horizontal}`,
    'nl-NL': (vertical: string, horizontal: string) =>
      `Tekstuitlijning: ${vertical}, ${horizontal}`,
  },
  toolbar_close_stickers_menu: {
    'en-GB': 'Close stickers menu',
    'nl-NL': 'Stickermenu sluiten',
  },
  toolbar_close_layouts_menu: {
    'en-GB': 'Close layouts menu',
    'nl-NL': 'Opmaakmenu sluiten',
  },
  toolbar_close_font_menu: {
    'en-GB': 'Close font menu',
    'nl-NL': `Lettertypemenu sluiten`,
  },
  toolbar_close_text_size_menu: {
    'en-GB': 'Close text size menu',
    'nl-NL': `Menu tekstgrootte sluiten`,
  },
  toolbar_close_text_color_menu: {
    'en-GB': 'Close text colour menu',
    'nl-NL': `Menu tekstkleur sluiten`,
  },
  toolbar_close_text_alignment_menu: {
    'en-GB': 'Close text alignment menu',
    'nl-NL': 'Menu tekstuitlijning sluiten',
  },
  toolbar_close_text_menu: {
    'en-GB': 'Close text editing menu',
    'nl-NL': 'Menu voor tekstbewerking sluiten',
  },
  toolbar_close_text_scale_menu: {
    'en-GB': 'Close text scale menu',
    'nl-NL': 'Tekstmenu op schaal sluiten',
  },
  toolbar_close_text_rotate_menu: {
    'en-GB': 'Close text rotate menu',
    'nl-NL': 'Sluit het draai menu',
  },
  rotate_anticlockwise_label: {
    'en-GB': 'Rotate anticlockwise',
    'nl-NL': 'Draai tegen te klok in',
  },
  rotate_clockwise_label: {
    'en-GB': 'Rotate clockwise',
    'nl-NL': 'Draai met de klok mee',
  },
  unchanged_default_text_title: {
    'en-GB': `Just double check the text!`,
    'nl-NL': `Controleer de tekst nog een keer!`,
  },
  unchanged_default_text_message_front: {
    'en-GB': `You havenʼt changed some of the text on the front. Is it all correct?`,
    'nl-NL': `Je hebt een deel van de tekst aan de voorkant niet gewijzigd. Klopt het allemaal?`,
  },
  unchanged_default_text_message_back: {
    'en-GB': `You havenʼt changed some of the text on the back. Is it all correct?`,
    'nl-NL': `Je hebt een deel van de tekst aan de achterkant niet gewijzigd. Klopt het allemaal?`,
  },
  unchanged_default_text_message_front_and_back: {
    'en-GB': `You havenʼt changed some of the text on the front and back. Is it all correct?`,
    'nl-NL': `Je hebt een deel van de tekst aan de voor- en achterkant niet gewijzigd. Klopt het allemaal?`,
  },
  unchanged_default_text_message_generic: {
    'en-GB': `You havenʼt changed some of the text. Is it all correct?`,
    'nl-NL': `Je hebt een deel van de tekst niet gewijzigd. Klopt het allemaal?`,
  },
  unchanged_default_text_continue_button: {
    'en-GB': `Continue`,
    'nl-NL': `Verdergaan`,
  },
  unchanged_default_text_show_me_button: {
    'en-GB': `Show Me`,
    'nl-NL': `Laten zien`,
  },
  zoom_label: {
    'en-GB': `Zoom`,
    'nl-NL': `Zoomen`,
  },
  adjust_height_label: {
    'en-GB': `Height`,
    'nl-NL': `Hoogte`,
  },
  adjust_width_label: {
    'en-GB': `Width`,
    'nl-NL': `Breedte`,
  },
  add_to_basket_button: {
    'en-GB': `Add to Basket`,
    'nl-NL': `In winkelmandje`,
  },
  edit_button: {
    'en-GB': `Edit Design`,
    'nl-NL': 'Bewerken',
  },
  finished_editing_button: {
    'en-GB': `Finished Editing`,
    'nl-NL': `Klaar met bewerken`,
  },
  login_redirect_loading: {
    'en-GB': `Redirecting to login`,
    'nl-NL': `Doorverwijzen naar login`,
  },
  login_redirect_loading_description: {
    'en-GB': `You will need to login to your account to access this design`,
    'nl-NL': `Log in op je MyGreetz-account om dit ontwerp te kunnen bewerken.`,
  },
  preview_loading: {
    'en-GB': `Almost there`,
    'nl-NL': `Bijna klaar`,
  },
  preview_loading_description: {
    'en-GB': `Your preview is being created`,
    'nl-NL': `Je voorbeeld wordt aangemaakt`,
  },
  horizontal_alignment: {
    'en-GB': (align: DesignHorizontalAlignment): string => {
      switch (align) {
        case 'LEFT': {
          return 'left'
        }
        case 'CENTER': {
          return 'centre'
        }
        case 'RIGHT': {
          return 'right'
        }
        case 'JUSTIFY': {
          return 'justify'
        }
      }
    },
    'nl-NL': (align: DesignHorizontalAlignment): string => {
      switch (align) {
        case 'LEFT': {
          return 'links'
        }
        case 'CENTER': {
          return 'midden'
        }
        case 'RIGHT': {
          return 'rechts'
        }
        case 'JUSTIFY': {
          return 'centeren'
        }
      }
    },
  },
  vertical_alignment: {
    'en-GB': (align: DesignVerticalAlignment): string => {
      switch (align) {
        case 'TOP': {
          return 'top'
        }
        case 'MIDDLE': {
          return 'middle'
        }
        case 'BOTTOM': {
          return 'bottom'
        }
      }
    },
    'nl-NL': (align: DesignVerticalAlignment): string => {
      switch (align) {
        case 'TOP': {
          return 'boven'
        }
        case 'MIDDLE': {
          return 'midden'
        }
        case 'BOTTOM': {
          return 'beneden'
        }
      }
    },
  },
  unexpected_error_title: {
    'en-GB': `An unexpected error occurred`,
    'nl-NL': `Er is onverwachts een fout opgetreden`,
  },
  update_label: {
    'en-GB': `Update`,
    'nl-NL': `Bijwerken`,
  },
  updating_label: {
    'en-GB': `Updating`,
    'nl-NL': `Bezig met bijwerken`,
  },
  confirm_exit_exit_title: {
    'en-GB': `Save changes?`,
    'nl-NL': `Wijzigingen opslaan?`,
  },
  modal_close_button: {
    'en-GB': `Close`,
    'nl-NL': `Sluiten`,
  },
  confirm_exit_save_and_exit_button: {
    'en-GB': `Save and exit`,
    'nl-NL': `Opslaan en afsluiten`,
  },
  confirm_exit_exit_without_saving_button: {
    'en-GB': `Exit without saving`,
    'nl-NL': `Afsluiten zonder opslaan`,
  },
  onboarding_title: {
    'en-GB': "What's new?",
    'nl-NL': 'Wat is er nieuw?',
  },
  onboarding_body: {
    'en-GB':
      'Get creative with a suite of design features created just for you!',
    'nl-NL':
      'Laat je creativiteit de vrije loop met nieuwe designfuncties speciaal voor jou!',
  },
  onboarding_dismiss_button: {
    'en-GB': 'Got it!',
    'nl-NL': 'Oké',
  },
  keep_draft_title: {
    'en-GB': `Keep Draft?`,
    'nl-NL': `Je ontwerp bewaren?`,
  },
  keep_draft_body: {
    'en-GB': `Your design has been saved automatically.`,
    'nl-NL': `Je ontwerp is automatisch opgeslagen.`,
  },
  keep_draft_button: {
    'en-GB': `Keep Draft`,
    'nl-NL': `Ontwerp bewaren`,
  },
  delete_draft_button: {
    'en-GB': `Delete Draft`,
    'nl-NL': `Ontwerp verwijderen`,
  },
  collage_element_label: {
    'en-GB': `Photo collage element`,
    'nl-NL': `Onderdeel fotocollage`,
  },
  collage_add_photos: {
    'en-GB': (maxPhotos: number) => `Add up to ${maxPhotos}\nphotos to start!`,
    'nl-NL': (maxPhotos: number) =>
      `Voeg tot ${maxPhotos} foto's toe\nen ga aan de slag!`,
  },
  toolbar_layout_label: {
    'en-GB': `Layout`,
    'nl-NL': `Lay-out`,
  },
  toolbar_select_layout: {
    'en-GB': `Select Layout`,
    'nl-NL': `Kies lay-out`,
  },
  toolbar_menu_choose_layout_label: {
    'en-GB': `Layout`,
    'nl-NL': `Lay-out`,
  },
  toolbar_menu_close_layout_label: {
    'en-GB': `Close layout menu`,
    'nl-NL': `Menu lay-out sluiten`,
  },
  collage_image_label: {
    'en-GB': (name: string) => `Adjust photo ${name}`,
    'nl-NL': (name: string) => `Foto ${name} aanpassen`,
  },
  toolbar_collage_toolbar_title: {
    'en-GB': `Collage editing`,
    'nl-NL': `Collage bewerken`,
  },
  toolbar_collage_cell_toolbar_title: {
    'en-GB': `Collage photo editing`,
    'nl-NL': `Collage fotobewerking`,
  },
  toolbar_collage_rearrange_toolbar_title: {
    'en-GB': `Collage rearrange photos`,
    'nl-NL': `Collage indeling foto's wijzigen`,
  },
  toolbar_collage_toolbar_close_label: {
    'en-GB': `Close collage editing toolbar`,
    'nl-NL': `Werkbalk collage bewerken sluiten`,
  },
  toolbar_collage_cell_toolbar_close_label: {
    'en-GB': `Close collage photo editing toolbar`,
    'nl-NL': `Werkbalk fotobewerking sluiten`,
  },
  toolbar_collage_rearrange_toolbar_close_label: {
    'en-GB': `Close collage rearrange toolbar`,
    'nl-NL': `Werkbalk indeling collage sluiten`,
  },
  toolbar_collage_rearrange_label: {
    'en-GB': `Rearrange`,
    'nl-NL': `Ordenen`,
  },
  toolbar_rearrange: {
    'en-GB': `Rearrange photos`,
    'nl-NL': `Indeling foto's wijzigen`,
  },
  toolbar_image_shuffle_label: {
    'en-GB': `Shuffle`,
    'nl-NL': `Shuffle`,
  },
  toolbar_shuffle_photo: {
    'en-GB': `Shuffle photos`,
    'nl-NL': `Shuffle foto's`,
  },
  toolbar_stickers_place_button: {
    'en-GB': (count: number) =>
      count === 1 ? `Place ${count} sticker` : `Place ${count} stickers`,
    'nl-NL': (count: number) =>
      count === 1 ? `Voeg ${count} sticker toe` : `Voeg ${count} stickers toe`,
  },
  toolbar_select_sticker_screen_reader_label: {
    'en-GB': (name: string) => `Select sticker ${name}`,
    'nl-NL': (name: string) => `Sticker ${name} gebruiken`,
  },
  toolbar_deselect_sticker_screen_reader_label: {
    'en-GB': (name: string) => `Deselect sticker ${name}`,
    'nl-NL': (name: string) => `Sticker ${name} niet meer gebruiken`,
  },
  stickers_unavailable_title: {
    'en-GB': `Oops! Stickers unavailable`,
    'nl-NL': `Oeps, er zijn geen stickers`,
  },
  stickers_unavailable_text: {
    'en-GB': `Stickers aren’t available for this item just yet.`,
    'nl-NL': `Er zijn voor deze nog geen stickers.`,
  },
  skip_to_toolbar: {
    'en-GB': `Skip to toolbar`,
    'nl-NL': `Naar werkbalk`,
  },
  tooltip_add_text_in_card: {
    'en-GB': `Add text literally anywhere in your card 🖋️`,
    'nl-NL': `Zet in de kaart waar je maar wilt tekst 🖋️`,
  },
  tooltip_add_photos_inside_card: {
    'en-GB': `Bring your card to life with a photo or a sticker ❤️`,
    'nl-NL': `Maak je kaart nog sprekender met een foto of sticker ❤️`,
  },
  tooltip_choose_layout: {
    'en-GB': `Pick a handy layout to get started 👌`,
    'nl-NL': `Kies een ontwerp en ga aan de slag 👌`,
  },
  tooltip_add_audio: {
    'en-GB': 'Let them hear your voice with an audio message 🎤',
    'nl-NL': 'Laat ze je stem horen met een audiobericht 🎤',
  },
  are_you_sure_about_deletion: {
    'en-GB': `Are you sure you want to delete this?`,
    'nl-NL': `Weet je zeker dat je deze wilt verwijderen?`,
  },
  are_you_sure_about_change_layouts: {
    'en-GB': `You’re about to change layouts`,
    'nl-NL': 'Je opmaak wijzigen?',
  },
  deleted_items_will_be_gone: {
    'en-GB': `Once it’s gone, it’s gone`,
    'nl-NL': `Eenmaal verwijderd is ook echt weg.`,
  },
  existing_customisations_will_be_gone: {
    'en-GB': `Existing text customisations will be lost`,
    'nl-NL': 'Eventuele aanpassingen in je tekst gaan verloren.',
  },
  change_layout: {
    'en-GB': `Change Layout`,
    'nl-NL': 'Opmaak wijzigen',
  },
  cancel: {
    'en-GB': `Cancel`,
    'nl-NL': 'Annuleren',
  },
  cancel_video_upload: {
    'en-GB': 'Cancel video upload',
    'nl-NL': 'Video upload annuleren',
  },
  cancel_audio_upload: {
    'en-GB': 'Cancel audio upload',
    'nl-NL': 'Uploaden audio annuleren',
  },
  keep_element: {
    'en-GB': `Keep`,
    'nl-NL': `Bewaren`,
  },
  delete_element_label: {
    'en-GB': 'Delete',
    'nl-NL': 'Verwijderen',
  },
  textbox_tooltip_message: {
    'en-GB': `You can now add emojis! 🥳`,
    'nl-NL': `Je kunt nu emoji's toevoegen! 🥳`,
  },
  toolbar_video_label: {
    'en-GB': 'Video',
    'nl-NL': 'Video',
  },
  toolbar_audio_label: {
    'en-GB': 'Audio',
    'nl-NL': 'Audio',
  },
  toolbar_menu_add_video_label: {
    'en-GB': 'Add a Free Video Message!',
    'nl-NL': 'Voeg gratis een videoboodschap toe!',
  },
  toolbar_menu_add_handwriting_label: {
    'en-GB': 'Add a Handwritten Message!',
    'nl-NL': 'Voeg een handgeschreven boodschap toe!',
  },
  toolbar_menu_add_audio_label: {
    'en-GB': 'Add a Free Audio Message!',
    'nl-NL': 'Voeg gratis een audiobericht toe!',
  },
  toolbar_menu_preview_video_label: {
    'en-GB': 'Preview Your Video',
    'nl-NL': 'Bekijk de preview',
  },
  toolbar_menu_add_audio_message_label: {
    'en-GB': 'Add Audio Message',
    'nl-NL': 'Voeg een audiobericht toe',
  },
  toolbar_menu_audio_start_recording_label: {
    'en-GB': 'Tap to start recording your message',
    'nl-NL': 'Tik om de opname te starten',
  },
  toolbar_menu_audio_record_label: {
    'en-GB': 'Record',
    'nl-NL': 'Opnemen',
  },
  toolbar_menu_audio_recording_label: {
    'en-GB': 'Tap to stop recording',
    'nl-NL': 'Tik om de opname te stoppen',
  },
  toolbar_menu_stop_recording_label: {
    'en-GB': 'Stop Recording',
    'nl-NL': 'Opname Stoppen',
  },
  toolbar_menu_close_audio_label: {
    'en-GB': 'Close audio menu',
    'nl-NL': 'Audiomenu sluiten',
  },
  button_upload_audio: {
    'en-GB': 'Upload Audio',
    'nl-NL': 'Audio uploaden',
  },
  button_play_audio: {
    'en-GB': 'Playback',
    'nl-NL': 'Afspelen',
  },
  button_pause_audio: {
    'en-GB': 'Pause',
    'nl-NL': 'Pauzeren',
  },
  toolbar_menu_audio_playback: {
    'en-GB':
      'This message will be added as a QR code on the inside of your card.',
    'nl-NL':
      'Dit bericht wordt als een QR-code toegevoegd aan de binnenkant van je kaart.',
  },
  toolbar_menu_close_video_label: {
    'en-GB': 'Close video menu',
    'nl-NL': 'Videomenu sluiten',
  },
  toolbar_menu_close_handwriting_label: {
    'en-GB': 'Close handwriting menu',
    'nl-NL': 'Handschriftmenu sluiten',
  },
  toolbar_menu_video_step_1: {
    'en-GB': 'You <b>upload</b> a video recording',
    'nl-NL': 'Jij <b>uploadt</b> een video',
  },
  toolbar_menu_audio_step_1: {
    'en-GB': 'You <b>upload</b> an audio recording',
    'nl-NL': 'Jij <b>uploadt</b> een audiobericht',
  },
  toolbar_menu_handwriting_step_1: {
    'en-GB': 'Grab some plain paper and a pen',
    'nl-NL': 'Pak pen en papier',
  },
  toolbar_menu_step_2: {
    'en-GB': 'We print a <b>QR code</b> in the card',
    'nl-NL': 'Wij zetten een <b>QR-code</b> in de kaart',
  },
  toolbar_menu_handwriting_step_2: {
    'en-GB': 'Write your message',
    'nl-NL': 'Schrijf je tekst',
  },
  toolbar_menu_step_3: {
    'en-GB': 'They <b>scan it</b> to play the message',
    'nl-NL': 'Zij <b>scannen</b> deze om de video te bekijken',
  },
  toolbar_menu_handwriting_step_3: {
    'en-GB':
      'Take a photo of your paper in a well-lit place, directly from above',
    'nl-NL':
      'Maak een goed verlichte foto van je tekst, direct van bovenaf genomen',
  },
  toolbar_menu_handwriting_label: {
    'en-GB': 'Phone taking photo of handwritten note',
    'nl-NL': 'Telefoon maakt foto van handgeschreven tekst',
  },
  button_maybe_later: {
    'en-GB': 'Maybe Later',
    'nl-NL': 'Misschien later',
  },
  button_add_video: {
    'en-GB': 'Add Video',
    'nl-NL': 'Video toevoegen',
  },
  button_add_handwriting: {
    'en-GB': 'Add Handwriting',
    'nl-NL': 'Handschrift toevoegen',
  },
  button_add_audio: {
    'en-GB': 'Add Audio',
    'nl-NL': 'Audio toevoegen',
  },
  button_upload_video: {
    'en-GB': 'Upload Video',
    'nl-NL': 'Upload video',
  },
  aria_label_video_duration: {
    'en-GB': (durationInSeconds: number) => {
      const formatted = formatDuration(durationInSeconds)
      switch (formatted.type) {
        case 'minutes-seconds': {
          return `${formatted.minutes} minutes ${formatted.seconds} seconds`
        }
        case 'seconds': {
          return `${formatted.seconds} seconds`
        }
      }
    },
    'nl-NL': (durationInSeconds: number) => {
      const formatted = formatDuration(durationInSeconds)
      switch (formatted.type) {
        case 'minutes-seconds': {
          return `${formatted.minutes} minuten ${formatted.seconds} seconden`
        }
        case 'seconds': {
          return `${formatted.seconds} seconden`
        }
      }
    },
  },
  label_video_duration: {
    'en-GB': (durationInSeconds: number) => {
      const formatted = formatDuration(durationInSeconds)
      switch (formatted.type) {
        case 'minutes-seconds': {
          return `${formatted.minutes}m ${formatted.seconds}s`
        }
        case 'seconds': {
          return `${formatted.seconds}s`
        }
      }
    },
    'nl-NL': (durationInSeconds: number) => {
      const formatted = formatDuration(durationInSeconds)
      switch (formatted.type) {
        case 'minutes-seconds': {
          return `${formatted.minutes}m ${formatted.seconds}s`
        }
        case 'seconds': {
          return `${formatted.seconds}s`
        }
      }
    },
  },
  aria_label_video_size: {
    'en-GB': (sizeInBytes: number) => {
      const formatted = formatFileSize(sizeInBytes)
      switch (formatted.type) {
        case 'mb': {
          return `${formatted.value} megabytes`
        }
        case 'kb': {
          return `${formatted.value} kilobytes`
        }
      }
    },
    'nl-NL': (sizeInBytes: number) => {
      const formatted = formatFileSize(sizeInBytes)
      switch (formatted.type) {
        case 'mb': {
          return `${formatted.value} megabytes`
        }
        case 'kb': {
          return `${formatted.value} kilobytes`
        }
      }
    },
  },
  file_size: {
    'en-GB': (sizeInBytes: number) => {
      const formatted = formatFileSize(sizeInBytes)
      switch (formatted.type) {
        case 'mb': {
          return `${formatted.value}${NON_BREAKING_SPACE}MB`
        }
        case 'kb': {
          return `${formatted.value}${NON_BREAKING_SPACE}KB`
        }
      }
    },
    'nl-NL': (sizeInBytes: number) => {
      const formatted = formatFileSize(sizeInBytes)
      switch (formatted.type) {
        case 'mb': {
          return `${formatted.value}${NON_BREAKING_SPACE}MB`
        }
        case 'kb': {
          return `${formatted.value}${NON_BREAKING_SPACE}KB`
        }
      }
    },
  },
  video_size_label: {
    'en-GB': 'Video size',
    'nl-NL': 'Bestandsgrootte video',
  },
  video_duration_label: {
    'en-GB': 'Video duration',
    'nl-NL': 'Duur video',
  },
  video_selected_label: {
    'en-GB': 'Selected video',
    'nl-NL': 'Geselecteerde video',
  },
  video_terms_and_conditions: {
    'en-GB': (termsAndConditionsUrl: string) =>
      `I confirm that this video does not violate Moonpig’s content rules as outlined in <a href="${termsAndConditionsUrl}" target="_blank" rel="noopener">Moonpig Terms and Conditions</a>.`,
    'nl-NL': (termsAndConditionsUrl: string) =>
      `Ik bevestig dat deze video niet in strijd is met de regels voor digitale inhoud die worden omschreven in de <a href="${termsAndConditionsUrl}" target="_blank" rel="noopener">algemene voorwaarden van Greetz</a>.`,
  },
  video_terms_and_conditions_error: {
    'en-GB': 'Tick the confirmation box above to continue.',
    'nl-NL': 'Vink het bevestigingsvakje aan om verder te gaan.',
  },
  video_uploading: {
    'en-GB': 'Video uploading…',
    'nl-NL': 'Video wordt geüpload…',
  },
  audio_uploading: {
    'en-GB': 'Audio uploading…',
    'nl-NL': 'Audio is aan het uploaden…',
  },
  video_element_upload_status: {
    'en-GB': (progressPercent: number) => {
      return `Video upload ${progressPercent}% complete`
    },
    'nl-NL': (progressPercent: number) => {
      return `Uploaden video voor ${progressPercent}% voltooid`
    },
  },
  edit_video_element_title: {
    'en-GB': (pageTitle: string) => {
      return `Edit ${pageTitle} video`
    },
    'nl-NL': (pageTitle: string) => {
      return `Video ${pageTitle} bewerken`
    },
  },
  edit_audio_element_title: {
    'en-GB': (pageTitle: string) => {
      return `Edit ${pageTitle} audio`
    },
    'nl-NL': (pageTitle: string) => {
      return `Audio ${pageTitle} bewerken`
    },
  },
  notification_file_too_large_title: {
    'en-GB': () => 'That file was a bit too big!',
    'nl-NL': () => 'Dat bestand was te groot!',
  },
  notification_video_file_too_large_text: {
    'en-GB': (maximumSize: string) =>
      `Make sure your file is ${maximumSize} or less.`,
    'nl-NL': (maximumSize: string) =>
      `Zorg dat je bestand ${maximumSize} of kleiner is.`,
  },
  notification_audio_file_too_large_text: {
    'en-GB': 'Please try recording a new audio message.',
    'nl-NL': 'Probeer een nieuw audiobericht op te nemen',
  },
  notification_video_upload_error_title: {
    'en-GB': 'Unable to upload video',
    'nl-NL': 'Uploaden video niet gelukt',
  },
  notification_audio_upload_error_title: {
    'en-GB': 'Unable to upload audio',
    'nl-NL': 'De audio kon niet worden geüpload.',
  },
  notification_microphone_permissions_error_title: {
    'en-GB': 'Allow microphone',
    'nl-NL': 'Microfoon toestaan',
  },
  notification_microphone_permissions_error_text: {
    'en-GB':
      'We need permission to use your microphone. Please update your settings to continue.',
    'nl-NL':
      'We hebben je toestemming nodig voor het gebruik van je microfoon. Update je instellingen om verder te gaan.',
  },
  notification_audio_recording_error_title: {
    'en-GB': 'Recording failed',
    'nl-NL': 'Opname is niet gelukt.',
  },
  notification_audio_recording_error_text: {
    'en-GB': 'Something went wrong. Please try recording a new audio message.',
    'nl-NL': 'Er ging iets fout. Probeer een nieuwe audio op te nemen.',
  },
  notification_video_upload_error_text: {
    'en-GB': 'Please try again or choose a different video.',
    'nl-NL': 'Probeer het even opnieuw of kies een andere video.',
  },
  notification_audio_upload_error_text: {
    'en-GB': 'Please try recording a new audio message.',
    'nl-NL': '',
  },
  border: {
    'en-GB': 'Border',
    'nl-NL': 'Randen',
  },
  toolbar_menu_close_border_label: {
    'en-GB': 'Close border menu',
    'nl-NL': 'Sluit menu randen',
  },
  none: {
    'en-GB': 'None',
    'nl-NL': 'Geen',
  },
  color_white: {
    'en-GB': 'White',
    'nl-NL': 'Wit',
  },
  color_black: {
    'en-GB': 'Black',
    'nl-NL': 'Zwart',
  },
  color_pink: {
    'en-GB': 'Pink',
    'nl-NL': 'Roze',
  },
  color_light_blue: {
    'en-GB': 'Light Blue',
    'nl-NL': 'Lichtblauw',
  },
  color_light_green: {
    'en-GB': 'Light Green',
    'nl-NL': 'Lichtgroen',
  },
  color_light_yellow: {
    'en-GB': 'Light Yellow',
    'nl-NL': 'Lichtgeel',
  },
  starter_message_birthday_generic: {
    'en-GB': 'Happy Birthday!\nI hope you have a great day!',
    'nl-NL': 'Gefeliciteerd met je verjaardag!\nEen hele fijne dag gewenst!',
  },
  starter_message_birthday_funny: {
    'en-GB': 'Birthdays are a great excuse to eat more cake!',
    'nl-NL': 'Verjaardagen zijn een lekker excuus voor taart!',
  },
  starter_message_birthday_cute: {
    'en-GB': 'Happy Birthday! Hope you have the best day.',
    'nl-NL':
      'Gefeliciteerd met je verjaardag! Dat het maar een perfecte dag mag worden.',
  },
  starter_message_birthday_trendy: {
    'en-GB': 'Have a fab birthday!',
    'nl-NL': 'Een hele fijne verjaardag gewenst!',
  },
  starter_message_birthday_traditional: {
    'en-GB': 'Happy Birthday!\nHave a wonderful day.',
    'nl-NL': 'Gefeliciteerd met je verjaardag!\nMaak er een mooie dag van.',
  },
  starter_message_occasion_anniversary: {
    'en-GB': "Happy Anniversary! Here's to many more!",
    'nl-NL': 'Gefeliciteerd met het jubileum! Op nog vele jaren!',
  },
  starter_message_occasion_christmas: {
    'en-GB': 'Wishing you a Merry Christmas & a Happy New Year!',
    'nl-NL': 'Prettige kerstdagen en een gelukkig nieuw jaar!',
  },
  starter_message_occasion_congratulations: {
    'en-GB': 'Massive congratulations!',
    'nl-NL': 'Van harte gefeliciteerd!',
  },
  starter_message_occasion_get_well: {
    'en-GB': 'Get well soon!',
    'nl-NL': 'Beterschap!',
  },
  starter_message_occasion_good_luck: {
    'en-GB': 'Good luck!',
    'nl-NL': 'Succes!',
  },
  starter_message_occasion_leaving: {
    'en-GB': "Good luck for the next chapter - you'll be missed!",
    'nl-NL': 'Succes met dit nieuwe hoofdstuk - je zult gemist worden!',
  },
  starter_message_occasion_mothers_day: {
    'en-GB': "Happy Mother's Day!",
    'nl-NL': 'Fijne Moederdag!',
  },
  starter_message_occasion_new_baby: {
    'en-GB': 'Congratulations on your new arrival!',
    'nl-NL': 'Gefeliciteerd met de nieuwe aanwinst!',
  },
  starter_message_occasion_new_home: {
    'en-GB': 'Congratulations on your new home!',
    'nl-NL': 'Gefeliciteerd met de nieuwe woning!',
  },
  starter_message_occasion_new_job: {
    'en-GB': 'Congratulations and good luck for your new job!',
    'nl-NL': 'Gefeliciteerd en veel succes in je nieuw baan!',
  },
  starter_message_occasion_sympathy: {
    'en-GB': 'Thinking of you and sending my love',
    'nl-NL': 'Ik denk aan je',
  },
  starter_message_occasion_thank_you: {
    'en-GB': 'Thank you so much!',
    'nl-NL': 'Heel erg bedankt!',
  },
  starter_message_occasion_thinking_of_you: {
    'en-GB': "Just a note to say I'm thinking of you",
    'nl-NL': 'Zomaar, om te laten weten dat ik aan je denk',
  },
  starter_message_occasion_wedding: {
    'en-GB': 'Congratulations on your wedding day!',
    'nl-NL': 'Gefeliciteerd met jullie trouwdag!',
  },
  undo: {
    'en-GB': 'Undo',
    'nl-NL': 'Ongedaan maken',
  },
  redo: {
    'en-GB': 'Redo',
    'nl-NL': 'Herstellen',
  },
  onboarding_content_photos_title: {
    'en-GB': 'Photos',
    'nl-NL': '',
  },
  onboarding_content_photos_description: {
    'en-GB': 'Spread the love with a photo. You can zoom and rotate it, too!',
    'nl-NL': '',
  },
  onboarding_content_stickers_title: {
    'en-GB': 'Stickers',
    'nl-NL': '',
  },
  onboarding_content_stickers_description: {
    'en-GB': 'Get creative with a range of stickers from animals to numbers.',
    'nl-NL': '',
  },
  onboarding_content_video_title: {
    'en-GB': 'Video',
    'nl-NL': '',
  },
  onboarding_content_video_description: {
    'en-GB': 'Bridge the distance with a video message in the physical card!',
    'nl-NL': '',
  },
  onboarding_content_audio_title: {
    'en-GB': 'Audio',
    'nl-NL': '',
  },
  onboarding_content_audio_description: {
    'en-GB': 'Bridge the distance with a video message in the physical card!',
    'nl-NL': '',
  },
  onboarding_content_smart_text_title: {
    'en-GB': 'Smart Text',
    'nl-NL': '',
  },
  onboarding_content_smart_text_description: {
    'en-GB': 'Not sure what to write? Our new AI tool is here to help!',
    'nl-NL': '',
  },
  onboarding_content_handwriting_title: {
    'en-GB': 'Handwriting',
    'nl-NL': '',
  },
  onboarding_content_handwriting_description: {
    'en-GB': 'Add an extra personal touch by sending your written message',
    'nl-NL': '',
  },
  onboarding_content_layouts_title: {
    'en-GB': 'Layouts',
    'nl-NL': '',
  },
  onboarding_content_layouts_description: {
    'en-GB': 'Keep organised or be your most creative with a blank canvas.',
    'nl-NL': '',
  },
  onboarding_content_text_title: {
    'en-GB': 'Text boxes',
    'nl-NL': '',
  },
  onboarding_content_text_description: {
    'en-GB': 'Place text anywhere, rotate and style it to express yourself.',
    'nl-NL': '',
  },
  onboarding_content_emoji_title: {
    'en-GB': 'Emojis',
    'nl-NL': '',
  },
  onboarding_content_emoji_description: {
    'en-GB': 'Express yourself with an emoji.',
    'nl-NL': '',
  },
}
