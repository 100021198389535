import { mat2dFromTransform, Transform2d } from '@moonpig/common-math'
import { loadImage } from '@moonpig/web-core-utils'
import 'canvas-toBlob'
import { createSequentialCanvasRenderer } from '../helpers/createSequentialCanvasRenderer'

type TransformImageInput = {
  width: number
  height: number
  url: string
  transform: Transform2d
}

export type TransformImage = (input: TransformImageInput) => Promise<Blob>

export const createTransformImage = (): TransformImage => {
  return createSequentialCanvasRenderer<TransformImageInput>(
    async (ctx, input) => {
      const image = await loadImage(input.url)

      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, input.width, input.height)
      ctx.setTransform(...mat2dFromTransform(input.transform))
      ctx.drawImage(
        image,
        -image.naturalWidth / 2,
        -image.naturalHeight / 2,
        image.naturalWidth,
        image.naturalHeight,
      )
    },
  )
}
