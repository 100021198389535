import { Flex, Text } from '@moonpig/launchpad-components'
import React, { FC, useMemo } from 'react'
import { RadioGrid } from '../RadioGrid'

const FontSizeOption: FC<{ fontSize: number }> = ({ fontSize }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
    >
      <Text typography="ctaButton" as="div">
        {fontSize}
      </Text>
    </Flex>
  )
}

export type FontSizeOption = {
  value: number
  disabled?: boolean
}

type FontSizePickerProps = {
  selectedFontSize: number
  sizes: FontSizeOption[]
  onSelect: (fontSize: number) => void
}

export const FontSizePicker: FC<FontSizePickerProps> = ({
  sizes,
  onSelect,
  selectedFontSize,
}) => {
  const items = useMemo(
    () =>
      sizes.map(({ disabled, value }) => ({
        id: `${value}`,
        value,
        disabled,
        element: <FontSizeOption fontSize={value} />,
      })),
    [sizes],
  )

  return (
    <RadioGrid
      name="mp-ed-toolbar-font-size-radio"
      selectedId={`${selectedFontSize}`}
      items={items}
      onSelect={onSelect}
    />
  )
}
