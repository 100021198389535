import { assert } from '../../../utils/assert'
import { addNotification } from '../../common/addNotification'
import { ActionCreator } from '../../types'

export const createNotify: ActionCreator<'notify'> =
  ({ set }) =>
  async notification => {
    set(({ view }) => {
      assert(view.type === 'main')

      return {
        view: {
          ...view,
          notifications: addNotification(view.notifications, notification),
        },
      }
    })
  }
