import { Design, DesignElement, DesignScene } from '../design'
import { StoredDesign, StoredDesignElement, StoredDesignScene } from './types'
import { mapRecord } from './utils'

const convertStoredDesignElementToDesignElement = (
  element: StoredDesignElement,
): DesignElement => {
  switch (element.type) {
    case 'image-dynamic': {
      return {
        ...element,
        customisations: {
          previousTextParts: element.customisations.textParts,
          textParts: element.customisations.textParts,
        },
      }
    }
    case 'image-upload': {
      return {
        ...element,
        customisations: {
          pendingEditedImage: null,
          editedImage: element.customisations.editedImage,
          sourceImage: element.customisations.sourceImage,
        },
      }
    }
    case 'image-collage': {
      return {
        ...element,
        customisations: {
          pendingEditedImage: null,
          editedImage: element.customisations.editedImage,
          grid: element.customisations.grid,
        },
      }
    }
    case 'overlay-image': {
      return {
        ...element,
        customisations: {
          pendingEditedImage: null,
          imageKind: 'photo',
          fixed: false,
          editedImage: element.customisations.editedImage,
          sourceImage: element.customisations.sourceImage,
        },
      }
    }
    case 'overlay-text': {
      const { fragments, ...elementRest } = element

      return {
        ...elementRest,
        fragmentsState: fragments && {
          fragments,
          version: 0,
          resizingWidth: false,
        },
      }
    }
    case 'media': {
      const { mediaToken, mediaId, ...rest } = element

      return {
        ...rest,
        uploadComplete: Promise.resolve({
          type: 'success',
          mediaToken,
        }),
        state: {
          type: 'uploaded',
          mediaId,
          mediaToken,
        },
      }
    }
    default: {
      return element
    }
  }
}
const convertStoredDesignSceneToDesignScene = (
  scene: StoredDesignScene,
): DesignScene => {
  return {
    id: scene.id,
    title: scene.title,
    width: scene.width,
    height: scene.height,
    renderMethod: scene.renderMethod,
    availableFeatures: scene.availableFeatures,
    elementIds: scene.elementIds,
    elementById: mapRecord(
      scene.elementById,
      convertStoredDesignElementToDesignElement,
    ),
    layout: scene.layout,
    layoutOriginal: scene.layoutOriginal,
    placements: scene.placements,
  }
}

export const convertStoredDesignToDesign = (design: StoredDesign): Design => {
  return {
    id: design.id,
    token: design.token,
    version: design.version,
    modified: true,
    productKey: design.productKey,
    layout: design.layout,
    sceneIds: design.sceneIds,
    sceneById: mapRecord(
      design.sceneById,
      convertStoredDesignSceneToDesignScene,
    ),
    usedFeatures: design.usedFeatures,
    availableStickerSets: design.availableStickerSets,
    defaultTextStyle: design.defaultTextStyle,
    availableTextStyles: design.availableTextStyles,
    limits: design.limits,
  }
}
