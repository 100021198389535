import { convertStoredDesignToDesign } from '@moonpig/web-personalise-editor-types'
import { removeMissingSourceImages } from '../../common/removeMissingSourceImages'
import { ActionCreator } from '../../types'
import { storeUpdateMainView } from '../../updaters/main-view'
import { storeUpdateView } from '../../updaters/view'

export const createNavigateHistory: ActionCreator<'navigateHistory'> =
  ({ set }) =>
  direction => {
    set(storeInitial => {
      let store = storeInitial

      store = storeUpdateMainView(store, 'edit', () => {
        return {
          activeElementId: null,
          ui: null,
        }
      })

      store = storeUpdateView(store, 'main', view => {
        const entry =
          view.designMeta.history.entries[view.designMeta.history.entryIndex]
        const nextEntryIndex = view.designMeta.history.entryIndex + direction
        const nextEntry = view.designMeta.history.entries[nextEntryIndex]
        const design = removeMissingSourceImages({
          design: convertStoredDesignToDesign(nextEntry.design),
          collageGrids: view.customisationConfig.availableCollageGrids,
          photos: view.photos,
          partiallyRemove: true,
        })

        const selectedSceneId =
          direction === 1 ? nextEntry.selectedSceneId : entry.selectedSceneId

        return {
          selectedSceneId,
          design,
          designMeta: {
            ...view.designMeta,
            history: {
              entryIndex: nextEntryIndex,
              entries: view.designMeta.history.entries,
            },
          },
        }
      })

      return store
    })
  }
