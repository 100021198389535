/* istanbul ignore file: not testable with js-dom - covered by browser tests */

import { TextEditableFragment } from '../types'

type TextLine = {
  text: string
  x: number
  y: number
  width: number
  height: number
}

type Rect = {
  x: number
  y: number
  width: number
  height: number
}

type GetClientRects = (startIndex: number, endIndex: number) => Rect[]

const extractTextLines = (input: {
  text: string
  getClientRects: GetClientRects
}): TextLine[] => {
  const lines: TextLine[] = []

  for (let i = 0; i < input.text.length; i += 1) {
    const rects = input.getClientRects(i, i + 1)

    if (rects.length > 0) {
      const rect = rects[rects.length - 1]
      lines.push({
        text: input.text.slice(i, i + 1),
        x: rect.x,
        y: rect.y,
        width: rect.width,
        height: rect.height,
      })
    }
  }

  const groupedLines = lines.reduce<TextLine[]>((acc, line) => {
    const prevLine = acc[acc.length - 1]

    if (prevLine && line.y === prevLine.y) {
      prevLine.text += line.text
      prevLine.width += line.width
    } else {
      acc.push(line)
    }

    return acc
  }, [])

  return groupedLines
}

const getElementFontSize = (element: HTMLElement): number => {
  const matches = /^(.+)px$/.exec(element.style.fontSize)

  return matches ? Number(matches[1]) : 0
}

export const extractFragments = (input: {
  baselinePercentOfHeight: number
  textElement: HTMLElement
}): TextEditableFragment[] => {
  input.textElement.normalize()

  const fragments: TextEditableFragment[] = []
  const containerBounds = input.textElement.getBoundingClientRect()
  const fontSize = getElementFontSize(input.textElement)

  const processTextNodesRecursively = (nodes: Node[]) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const node of nodes) {
      if (node.nodeType !== Node.TEXT_NODE || !node.textContent) {
        processTextNodesRecursively(Array.from(node.childNodes))

        // eslint-disable-next-line no-continue
        continue
      }

      const range = document.createRange()

      const lines = extractTextLines({
        text: node.textContent,
        getClientRects: (startIndex, endIndex) => {
          range.setStart(node, startIndex)
          range.setEnd(node, endIndex)

          return Array.from(range.getClientRects())
        },
      })

      lines.forEach(line => {
        const baseline = line.height * input.baselinePercentOfHeight

        fragments.push({
          text: line.text,
          x: line.x - containerBounds.x,
          y: line.y - containerBounds.y + baseline,
          width: line.width,
          height: line.height,
          fontSize,
        })
      })
    }
  }

  processTextNodesRecursively(Array.from(input.textElement.childNodes))

  return fragments
}
