import { updateDesignSceneElements } from '../../../utils/updateDesignSceneElements'
import { ActionCreator } from '../../types'
import { storeUpdateDesign } from '../../updaters/design'
import { storeUpdateMainView } from '../../updaters/main-view'

export const createDeleteElement: ActionCreator<'deleteElement'> =
  ({ set, t }) =>
  elementRef => {
    set(storeInitial => {
      let store = storeInitial

      store = storeUpdateMainView(store, 'edit', view => {
        if (view.activeElementId === elementRef.id) {
          return {
            activeElementId: null,
            ui: null,
          }
        }

        return {}
      })

      store = storeUpdateDesign(
        store,
        { t, changeReason: 'elements-changed' },
        design => {
          return updateDesignSceneElements(
            design,
            elementRef.sceneId,
            currentDesign => {
              const currentScene = currentDesign.sceneById[elementRef.sceneId]
              const elementIds = currentScene.elementIds.filter(
                elementId => elementId !== elementRef.id,
              )

              const elementById = { ...currentScene.elementById }
              delete elementById[elementRef.id]

              return { elementById, elementIds }
            },
          )
        },
      )

      return store
    })
  }
