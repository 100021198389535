import { Bounds } from '@moonpig/common-math'
import { IconSystemGroup } from '@moonpig/launchpad-assets'
import {
  Flex,
  PrimaryButton,
  TertiaryButton,
} from '@moonpig/launchpad-components'
import {
  PageHeading,
  PageLayout,
  ScreenReaderOnly,
  useFocusOn,
} from '@moonpig/web-personalise-components'
import React, { FC, memo, useCallback, useLayoutEffect, useMemo } from 'react'
import { Camera, CameraNode } from '../../../camera'
import {
  FOCUS_ID_MENU,
  FOCUS_ID_TEXT_INPUT,
  FOCUS_ID_TOOLBAR,
  SHARED_ANIMATION_CONFIG,
} from '../../../constants'
import { LayoutScene } from '../../../layout'
import { useAction, useMainView } from '../../../store'
import { useEditorLocaleText } from '../../../text-localisation'
import { useIsDesktop } from '../../../utils/useIsDesktop'
import { Minimap } from '../components/Minimap'
import { Notifications } from '../components/Notifications'
import { useSelectedSceneLayout } from '../selectors'
import { CameraDebug } from './components/CameraDebug'
import { EditModal } from './components/EditModal'
import { HistoryControls } from './components/HistoryControls'
import { LayerInteractive } from './components/LayerInteractive'
import { LayerRender } from './components/LayerRender'
import { LayerText } from './components/LayerText'
import { LayerToolbar } from './components/LayerToolbar'
import { DRAWER_FIRST_STOP_POSITION } from './components/LayerToolbar/components/Toolbar/AnimatedMenu'
import { useMediaProgressBar } from './components/MediaProgressBar'
import { Peers } from './components/Peers'
import { useActiveKeyboardElementRef, useUI } from './selectors'
import { useCameraUI } from './useCameraUI'

const Header: FC = () => {
  const t = useEditorLocaleText()
  const showModal = useAction('showModal')
  const mediaProgressBar = useMediaProgressBar()

  const handleInvite = useCallback(() => {
    showModal({ type: 'invite' })
  }, [showModal])

  return (
    <>
      <ScreenReaderOnly>
        <PageHeading>{t('edit_heading_screen_reader_text')}</PageHeading>
      </ScreenReaderOnly>
      <Flex width="100%" height="100%" justifyContent="space-between">
        <Flex
          display={{ xs: mediaProgressBar ? 'none' : 'flex', md: 'flex' }}
          justifyContent="flex-start"
          flexGrow={1}
          flexShrink={1}
          flexBasis="100%"
        >
          <TertiaryButton onClick={() => showModal({ type: 'confirm-exit' })}>
            {t('edit_exit_cta_text')}
          </TertiaryButton>
        </Flex>
        <Flex
          px={{ xs: mediaProgressBar ? 0 : 6, md: 6 }}
          justifyContent="center"
          flexGrow={1}
          flexShrink={mediaProgressBar ? 0 : 1}
          flexBasis="400px"
          minWidth={0}
          maxWidth="100%"
        >
          {mediaProgressBar || <HistoryControls />}
        </Flex>
        <Flex
          display={{ xs: mediaProgressBar ? 'none' : 'flex', md: 'flex' }}
          justifyContent="flex-end"
          flexGrow={1}
          flexShrink={1}
          flexBasis="100%"
        >
          <Peers />
          <PrimaryButton
            onClick={handleInvite}
            leadingIcon={
              <Flex
                flexDirection="column"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                <IconSystemGroup />
              </Flex>
            }
          >
            Invite
          </PrimaryButton>
        </Flex>
      </Flex>
    </>
  )
}

const Nav: FC = () => {
  return (
    <Flex
      width="100%"
      height="100%"
      justifyContent="center"
      alignItems="center"
    >
      <Minimap />
    </Flex>
  )
}

const useYFocusPosition = (layout: LayoutScene): number => {
  const isDesktop = useIsDesktop()

  return useMainView('edit', (view, { design, selectedSceneId }) => {
    if (!view.activeElementId) {
      return layout.y
    }

    const selectedMenuItem = view.ui?.selectedMenuItem

    const element =
      design.sceneById[selectedSceneId].elementById[view.activeElementId]

    if (
      isDesktop ||
      element === null ||
      element.type === 'image-collage' ||
      !selectedMenuItem ||
      selectedMenuItem === 'keyboard'
    ) {
      return layout.y
    }

    const elementBottomEdge = layout.y + element.y + element.height
    const drawerTopEdge = layout.height * (1 - DRAWER_FIRST_STOP_POSITION)

    return elementBottomEdge > drawerTopEdge
      ? Math.max(elementBottomEdge - drawerTopEdge, layout.y)
      : layout.y
  })
}

type UseCameraFocusAndNodeResult = {
  focus: Bounds
  cameraNode: CameraNode
}

const useCameraFocusAndNode = (): UseCameraFocusAndNodeResult => {
  const layout = useSelectedSceneLayout()
  const y = useYFocusPosition(layout)

  const result = useMemo<UseCameraFocusAndNodeResult>(
    () => ({
      focus: {
        x: layout.x,
        y,
        width: layout.width,
        height: layout.height,
      },
      cameraNode: layout,
    }),
    [layout, y],
  )

  return result
}

const useFocusTarget = () => {
  const ui = useUI()

  return useMainView(
    'edit',
    ({ activeElementId }, { design, selectedSceneId }) => {
      if (activeElementId) {
        const element =
          design.sceneById[selectedSceneId].elementById[activeElementId]
        if (element.type === 'text-plain') {
          return FOCUS_ID_TEXT_INPUT
        }
      }

      if (!ui || ui.type === 'default') {
        return null
      }

      if (ui.selectedMenuItem === null) {
        return FOCUS_ID_TOOLBAR
      }

      if (ui.selectedMenuItem === 'keyboard') {
        return FOCUS_ID_TEXT_INPUT
      }

      return FOCUS_ID_MENU
    },
  )
}

const useDisablePan = (): boolean => {
  const activeKeyboardElementRef = useActiveKeyboardElementRef()

  const imageElementSelected = useMainView(
    'edit',
    (view, { design, selectedSceneId }) => {
      if (!view.activeElementId) {
        return false
      }

      const element =
        design.sceneById[selectedSceneId].elementById[view.activeElementId]

      return element.type === 'image-upload' || element.type === 'image-collage'
    },
  )

  return activeKeyboardElementRef !== null || imageElementSelected
}

const MainContent = memo(() => {
  return (
    <>
      <LayerRender />
      <LayerInteractive />
      <LayerText />
      <LayerToolbar />
      <CameraDebug />
    </>
  )
})

const Main: FC = () => {
  const disablePan = useDisablePan()

  const [ui, pad] = useCameraUI()
  const { focus, cameraNode } = useCameraFocusAndNode()

  const selectScene = useAction('selectScene')

  const handleSceneChange = useCallback(
    (sceneId: string) => {
      selectScene(sceneId, 'SWIPE')
    },
    [selectScene],
  )

  const focusOn = useFocusOn()
  const focusTarget = useFocusTarget()

  useLayoutEffect(() => {
    if (focusTarget) {
      focusOn(focusTarget)
    }
  }, [focusOn, focusTarget])

  return (
    <Camera
      focus={focus}
      pad={pad}
      ui={ui}
      animationConfig={SHARED_ANIMATION_CONFIG}
      node={cameraNode}
      onNodeChange={handleSceneChange}
      disablePan={disablePan}
    >
      <MainContent />
    </Camera>
  )
}

export const Edit: FC = () => {
  const activeKeyboardElementRef = useActiveKeyboardElementRef()

  return (
    <PageLayout
      enableNativePan={activeKeyboardElementRef !== null}
      header={<Header />}
      nav={<Nav />}
      main={<Main />}
      notifications={<Notifications />}
      modal={<EditModal />}
    />
  )
}
