import { createLocaleTextHook } from '@moonpig/web-core-locale-text'

const dictionary = {
  error_reference_session_id: {
    'en-GB': (sessionId: string) => `reference: ${sessionId}`,
    'nl-NL': (sessionId: string) => `referentie: ${sessionId}`,
  },
  error_reference_short_session_id: {
    'en-GB': (shortSessionId: string) => `ref: ${shortSessionId}`,
    'nl-NL': (shortSessionId: string) => `ref: ${shortSessionId}`,
  },
}

export const useLocaleText = createLocaleTextHook({ dictionary })
