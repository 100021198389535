import { Vec2 } from '@moonpig/common-math'
import React, { FC } from 'react'
import { byId } from '../../common/byId'
import { Frame } from '../../common/frame'
import { CollageGrid, updateGridGutterOffset } from '../../model'
import { evenlySpaceGutter } from '../../model/evenlySpaceGutter'
import { GutterHandle } from '../GutterHandle'

type InteractiveCollageGuttersProps = {
  grid: CollageGrid
  gridSize: Vec2
  clipBounds: Frame
  scale: number
  onChangeGrid: (newGrid: CollageGrid, context: { last: boolean }) => void
  onDragging: (isDragging: boolean) => void
}

export const InteractiveCollageGutters: FC<InteractiveCollageGuttersProps> = ({
  grid,
  gridSize,
  clipBounds,
  scale,
  onChangeGrid,
  onDragging,
}) => {
  const gutterById = byId(grid.gutters)

  return (
    <>
      {grid.gutters.map(gutter => {
        return (
          <GutterHandle
            key={gutter.id}
            gridSize={gridSize}
            scale={scale}
            clipBounds={clipBounds}
            orientation={gutter.orientation}
            offset={gutter.offset}
            anchors={{
              start: gutter.anchors.start
                ? gutterById[gutter.anchors.start].offset
                : 0,
              end: gutter.anchors.end
                ? gutterById[gutter.anchors.end].offset
                : 1,
            }}
            onMove={(newOffset, context) => {
              onChangeGrid(
                updateGridGutterOffset(grid, gutter.id, newOffset),
                context,
              )
            }}
            onReset={() => {
              onChangeGrid(evenlySpaceGutter(grid, gutter.id), { last: true })
            }}
            onDragging={onDragging}
          />
        )
      })}
    </>
  )
}
