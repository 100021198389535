import { styled, useTheme } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { useCamera } from '../../../../../../../../camera'

const FONT_SIZE = 14

const StyledSkipLinkButton = styled.a`
  opacity: 0;
  outline: none;

  :focus {
    opacity: 1;
    text-decoration: underline;
  }
`

type SkipLinkProps = {
  label: string
  onFocus(): void
  onClick(): void
}

export const SkipLink: FC<SkipLinkProps> = ({ label, onFocus, onClick }) => {
  const { inverseScale } = useCamera()
  const { colors } = useTheme()
  const { colorInteractionTextLink } = colors

  return (
    <StyledSkipLinkButton
      href="#"
      role="button"
      aria-label={label}
      tabIndex={0}
      onClick={event => {
        event.stopPropagation()
        event.preventDefault()
        onClick()
      }}
      onFocus={onFocus}
    >
      <text
        aria-hidden
        x={0}
        y={FONT_SIZE * inverseScale}
        fontSize={FONT_SIZE * inverseScale}
        fontWeight="bold"
        fill={colorInteractionTextLink}
      >
        {label}
      </text>
    </StyledSkipLinkButton>
  )
}
