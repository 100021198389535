import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer'
import { RefObject, useCallback, useMemo, useState } from 'react'
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect'

type Size = {
  width: number
  height: number
}

const ResizeObserver =
  typeof window !== 'undefined' && 'ResizeObserver' in window
    ? window.ResizeObserver
    : ResizeObserverPolyfill

export const useElementSize = (
  elementInput: Element | null | RefObject<Element>,
): Size | null => {
  const [elementSize, setElementSize] = useState<Size | null>(null)

  const getElement = useCallback((): Element | null => {
    if (elementInput === null) {
      return null
    }
    if (elementInput instanceof Element) {
      return elementInput
    }
    return elementInput.current
  }, [elementInput])

  const mutationObserver = useMemo(
    () =>
      new MutationObserver(() => {
        const element = getElement()
        if (element) {
          setElementSize({
            width: element.clientWidth,
            height: element.clientHeight,
          })
        }
      }),
    [getElement],
  )

  const observer = useMemo(
    () =>
      new ResizeObserver(entries => {
        if (entries[0]) {
          const { width, height } = entries[0].contentRect
          setElementSize({ width, height })
        }
      }),
    [],
  )

  useIsomorphicLayoutEffect(() => {
    const element = getElement()

    if (element) {
      observer.observe(element)
      mutationObserver.observe(element, { childList: true, subtree: true })
    }

    return () => {
      observer.disconnect()
      mutationObserver.disconnect()
    }
  }, [getElement, observer])

  return elementSize
}
