import { Text, focusIndicatorStyles } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { colorValue } from '@moonpig/launchpad-theme'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC, ReactNode } from 'react'

type ActionButtonProps = {
  icon: ReactNode
  text: string
  onClick: () => void
  disabled?: boolean
}

const StyledActionButton = styled.button`
  ${focusIndicatorStyles}
  ${s({ borderRadius: 2 })}

  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  &:not(:disabled) {
    & > span {
      color: ${colorValue('colorInteractionButton')};
    }

    &:hover > div {
      border-color: ${colorValue('colorInteractionButtonHover')};
      background: ${colorValue('colorInteractionButtonHover')};
    }

    &:hover > span {
      color: ${colorValue('colorInteractionButtonHover')};
    }

    &:focus > div {
      background: ${colorValue('colorInteractionButtonFocus')};
      border-color: ${colorValue('colorInteractionButtonFocus')};
    }

    &:focus > span {
      color: ${colorValue('colorInteractionButtonFocus')};
    }

    &:active > div {
      ${s({
        boxShadow: 1,
      })}
      background: ${colorValue('colorInteractionButtonFocus')};
      border-color: ${colorValue('colorInteractionButtonFocus')};
    }

    &:active > span {
      color: ${colorValue('colorInteractionButtonFocus')};
    }
  }
`

const StyledShape = styled.div`
  border-radius: 50%;
  display: flex;
  height: 100px;
  width: 100px;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-color: ${colorValue('colorInteractionButton')};
  background: ${colorValue('colorInteractionButton')};
`

const StyledIcon = styled.div.attrs<{
  disabled: boolean
}>(({ disabled }) => ({
  className: disabled ? 'disabled' : '',
}))<{ disabled: boolean }>`
  color: ${colorValue('colorBlack00')};

  &.disabled {
    color: ${colorValue('colorInteractionDeactivated')};
  }
`

export const ActionButton: FC<ActionButtonProps> = ({
  icon,
  text,
  onClick,
  disabled = false,
}) => {
  return (
    <StyledActionButton disabled={disabled} onClick={onClick}>
      <StyledShape>
        <StyledIcon disabled={disabled}>{icon}</StyledIcon>
      </StyledShape>
      <Text pt={5} typography="ctaButton">
        {text}
      </Text>
    </StyledActionButton>
  )
}
