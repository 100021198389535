import { Card3D, PageLayout } from '@moonpig/web-personalise-components'
import React, { FC, useCallback, useRef } from 'react'
import { Camera, useCamera } from '../../../../../camera'
import { SHARED_ANIMATION_CONFIG } from '../../../../../constants'
import { useCameraPad } from '../../../../../utils/useCameraPad'
import { useAction } from '../../../../../store'
import { Notifications } from '../../../components/Notifications'
import { useSceneIds, useSelectedSceneId } from '../../../selectors'
import { usePreviewImages } from '../../selectors'
import { OnLoaded } from '../../types'
import { Header } from '../Header'
import { Nav } from '../Nav'

type Orientation = 'PORTRAIT' | 'LANDSCAPE'

type PreviewCardContentProps = {
  width: number
  height: number
  orientation: Orientation
  onLoaded: OnLoaded
}

const PreviewCardContent: FC<PreviewCardContentProps> = ({
  width,
  height,
  orientation,
  onLoaded,
}) => {
  const previewImages = usePreviewImages()
  const sceneIds = useSceneIds()
  const selectedSceneId = useSelectedSceneId()
  const selectScene = useAction('selectScene')
  const { translate, scale } = useCamera()
  const readyCountRef = useRef(0)

  const handleLoad = useCallback(() => {
    readyCountRef.current += 1
    if (readyCountRef.current === sceneIds.length) {
      onLoaded('success')
    }
  }, [onLoaded, sceneIds.length])

  const handleError = useCallback(() => {
    onLoaded('error')
  }, [onLoaded])

  return (
    <Card3D
      orientation={orientation}
      size={{ width: width * scale, height: height * scale }}
      position={{ x: translate[0], y: translate[1] }}
      activeSceneIndex={sceneIds.indexOf(selectedSceneId)}
      onChangeActiveSceneIndex={facingPageIndex => {
        selectScene(sceneIds[facingPageIndex], 'SWIPE')
      }}
      renderScene={({ sceneIndex }) => {
        const { title, url } = previewImages[sceneIndex]
        return (
          <img
            alt={title}
            src={url}
            width="100%"
            height="100%"
            onLoad={handleLoad}
            onError={handleError}
          />
        )
      }}
    />
  )
}

type MainProps = {
  orientation: Orientation
  onLoaded: OnLoaded
}

const Main: FC<MainProps> = ({ orientation, onLoaded }) => {
  const previewImages = usePreviewImages()
  const pad = useCameraPad()
  const { width, height } = previewImages[0]

  return (
    <Camera
      pad={pad}
      animationConfig={SHARED_ANIMATION_CONFIG}
      focus={{ x: 0, y: 0, width, height }}
    >
      <PreviewCardContent
        width={width}
        height={height}
        orientation={orientation}
        onLoaded={onLoaded}
      />
    </Camera>
  )
}

type PreviewCardProps = {
  orientation: Orientation
  onLoaded: OnLoaded
}

export const PreviewCard: FC<PreviewCardProps> = ({
  orientation,
  onLoaded,
}) => {
  return (
    <PageLayout
      header={<Header />}
      nav={<Nav sceneOrder={[0, 2, 1, 3]} />}
      main={<Main orientation={orientation} onLoaded={onLoaded} />}
      notifications={<Notifications />}
    />
  )
}
