import React, { FC, ReactNode } from 'react'
import { CreaseShadowLandscape, CreaseShadowPortrait } from './CreaseShadow'
import { Page } from './Page'
import { Side } from './Side'
import { PagePose } from './types'

type Card3DPosedProps = {
  renderScene: (input: { sceneIndex: number }) => ReactNode
  animated: boolean
  pose: PagePose
  transitionDuration?: number
}

export const Card3DPosedPortrait: FC<Card3DPosedProps> = ({
  renderScene,
  animated,
  pose,
  transitionDuration,
}) => {
  return (
    <>
      <Page
        transformOrigin="left center"
        rotateX={0}
        rotateY={pose.rotationPageA}
        translateX={pose.creaseOffset}
        translateY={0}
        animated={animated}
        transitionDuration={transitionDuration}
      >
        <Side raised>{renderScene({ sceneIndex: 0 })}</Side>
        <Side flipped>
          {renderScene({ sceneIndex: 1 })}
          <CreaseShadowPortrait />
        </Side>
      </Page>
      <Page
        transformOrigin="left center"
        rotateX={0}
        rotateY={pose.rotationPageB}
        translateX={pose.creaseOffset}
        translateY={0}
        animated={animated}
        transitionDuration={transitionDuration}
      >
        <Side>{renderScene({ sceneIndex: 2 })}</Side>
        <Side raised flipped>
          {renderScene({ sceneIndex: 3 })}
        </Side>
      </Page>
    </>
  )
}

export const Card3DPosedLandscape: FC<Card3DPosedProps> = ({
  renderScene,
  animated,
  pose,
  transitionDuration,
}) => {
  return (
    <>
      <Page
        transformOrigin="center top"
        rotateX={-pose.rotationPageA}
        rotateY={pose.rotationFlip}
        translateX={0}
        translateY={pose.creaseOffset}
        animated={animated}
        transitionDuration={transitionDuration}
      >
        <Side raised>{renderScene({ sceneIndex: 0 })}</Side>
        <Side flipped rotated>
          {renderScene({ sceneIndex: 1 })}
          <CreaseShadowLandscape />
        </Side>
      </Page>
      <Page
        transformOrigin="center top"
        rotateX={-pose.rotationPageB}
        rotateY={pose.rotationFlip}
        translateX={0}
        translateY={pose.creaseOffset}
        animated={animated}
        transitionDuration={transitionDuration}
      >
        <Side>{renderScene({ sceneIndex: 2 })}</Side>
        <Side raised flipped>
          {renderScene({ sceneIndex: 3 })}
        </Side>
      </Page>
    </>
  )
}
