import React, { FC } from 'react'

export const IconShuffle: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <>
      <svg width={width} height={height} viewBox="0 0 24 20" fill="none">
        <path
          d="M18 4V2.13504C18 1.2872 18.9889 0.824047 19.6402 1.36682L23.0781 4.23178C23.5579 4.63157 23.5579 5.36843 23.0781 5.76822L19.6402 8.63318C18.9889 9.17595 18 8.7128 18 7.86496V6H14.4454C14.1619 6 13.8917 6.12032 13.7021 6.33104L5.29793 15.669C5.10829 15.8797 4.83812 16 4.55464 16H1C0.447715 16 0 15.5523 0 15C0 14.4477 0.447715 14 1 14H3.55464C3.83812 14 4.10829 13.8797 4.29793 13.669L12.7021 4.33104C12.8917 4.12032 13.1619 4 13.4454 4H18Z"
          fill="currentColor"
        />
        <path
          d="M17.9996 16V17.865C17.9996 18.7128 18.9885 19.176 19.6398 18.6332L23.0777 15.7682C23.5575 15.3684 23.5575 14.6316 23.0777 14.2318L19.6398 11.3668C18.9885 10.824 17.9996 11.2872 17.9996 12.135V14H14.445C14.1615 14 13.8913 13.8797 13.7017 13.669L12.0284 11.8098C11.6451 11.3839 10.9831 11.3665 10.578 11.7716L10.5205 11.8291C10.1444 12.2052 10.1285 12.8099 10.4843 13.2052L12.7017 15.669C12.8913 15.8797 13.1615 16 13.445 16H17.9996Z"
          fill="currentColor"
        />
        <path
          d="M1 6H3.55464C3.83812 6 4.10829 6.12032 4.29793 6.33104L5.9949 8.21656C6.37817 8.64241 7.04018 8.65982 7.4453 8.2547L7.50281 8.19719C7.8789 7.8211 7.8948 7.21644 7.539 6.82111L5.29793 4.33104C5.10829 4.12032 4.83812 4 4.55464 4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6Z"
          fill="currentColor"
        />
      </svg>
    </>
  )
}
