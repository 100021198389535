import { IconKeyboard } from '@moonpig/web-personalise-components'
import React, { FC, useCallback, useMemo } from 'react'
import { createElementDomId } from '../../../../../../../utils/createElementId'
import {
  UITextPlaceholder,
  UITextPlaceholderMenuItem,
  useAction,
} from '../../../../../../../store'
import { useEditorLocaleText } from '../../../../../../../text-localisation'
import { useElementRef } from '../../../../../selectors'
import { ToolbarItem, ToolbarMenu, ToolbarOrientation } from '../Toolbar'
import { ToolbarCommon } from '../ToolbarCommon'
import { createMenuTitleId } from '../utils/createMenuTitleId'
import { TextPlaceholderForm } from './TextPlaceholderForm'

type ToolbarTextPlaceholderProps = {
  onDone: () => void
  orientation: ToolbarOrientation
  ui: UITextPlaceholder
}

export const ToolbarTextPlaceholder: FC<ToolbarTextPlaceholderProps> = ({
  onDone,
  orientation,
  ui,
}) => {
  const { elementRef, selectedMenuItem } = ui

  const element = useElementRef(elementRef)
  const setUI = useAction('setUI')
  const t = useEditorLocaleText()

  const controlId = createElementDomId(element.id)

  const items = useMemo<ToolbarItem<UITextPlaceholderMenuItem>[]>(() => {
    return [
      {
        type: 'menu',
        id: 'text',
        label: t('toolbar_text_label'),
        ariaLabel: t('toolbar_text_label'),
        icon: <IconKeyboard />,
        disabled: false,
      },
    ]
  }, [t])

  const menu = useMemo<ToolbarMenu | undefined>(() => {
    if (!selectedMenuItem) return

    const titleId = createMenuTitleId(elementRef.id, selectedMenuItem)

    switch (selectedMenuItem) {
      case 'text': {
        return {
          title: t('toolbar_text_menu_heading'),
          titleId,
          content: <TextPlaceholderForm elementRef={elementRef} />,
          closeLabel: t('toolbar_close_text_menu'),
        }
      }
    }
  }, [elementRef, selectedMenuItem, t])

  const handleSelectItem = useCallback(
    (itemId: UITextPlaceholderMenuItem | null) => {
      setUI({ ...ui, selectedMenuItem: itemId })
    },
    [setUI, ui],
  )

  return (
    <ToolbarCommon
      controlId={controlId}
      label={t('toolbar_text_toolbar_title')}
      orientation={orientation}
      items={items}
      selectedItemId={selectedMenuItem}
      onSelectItem={handleSelectItem}
      menu={menu}
      done={{
        label: t('toolbar_done_button'),
        ariaLabel: t('toolbar_text_toolbar_close_label'),
        onDone,
      }}
    />
  )
}
