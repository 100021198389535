import { Design } from '@moonpig/web-personalise-editor-types'
import { GetEditorLocaleText } from '../../../text-localisation'
import { SceneText, SceneTextById } from '../../types'

const createSceneText = (
  design: Design,
  sceneId: string,
  t: GetEditorLocaleText,
): SceneText => {
  const { sceneById } = design
  const { title: sceneTitle, elementIds, elementById } = sceneById[sceneId]

  const {
    editableImageCount,
    editableTextCount,
    editableStickerCount,
    editableVideoCount,
  } = elementIds.reduce<{
    editableImageCount: number
    editableTextCount: number
    editableStickerCount: number
    editableVideoCount: number
  }>(
    // eslint-disable-next-line array-callback-return
    (acc, elementId) => {
      const element = elementById[elementId]
      switch (element.type) {
        case 'image-static':
        case 'overlay-inlay':
        case 'shape-rectangle':
        case 'text-static': {
          return acc
        }
        case 'image-collage':
        case 'image-upload':
        case 'overlay-image': {
          return {
            ...acc,
            editableImageCount: acc.editableImageCount + 1,
          }
        }
        case 'image-dynamic':
        case 'text-plain':
        case 'text-styled':
        case 'overlay-text':
        case 'text-placeholder': {
          return {
            ...acc,
            editableTextCount: acc.editableTextCount + 1,
          }
        }
        case 'sticker': {
          return {
            ...acc,
            editableStickerCount: acc.editableStickerCount + 1,
          }
        }
        case 'media': {
          return {
            ...acc,
            editableVideoCount: acc.editableVideoCount + 1,
          }
        }
      }
    },
    {
      editableImageCount: 0,
      editableTextCount: 0,
      editableStickerCount: 0,
      editableVideoCount: 0,
    },
  )

  const editableElementsCount =
    editableImageCount +
    editableTextCount +
    editableStickerCount +
    editableVideoCount

  const { editElementLabelById } = elementIds.reduce<{
    editElementLabelById: SceneText['editElementLabelById']
    editableImageCount: number
    editableTextCount: number
    editableStickerCount: number
  }>(
    (acc, elementId) => {
      const element = elementById[elementId]

      switch (element.type) {
        case 'image-collage':
        case 'image-upload':
        case 'overlay-image': {
          return {
            ...acc,
            editElementLabelById: {
              ...acc.editElementLabelById,
              [elementId]: t(
                'edit_image_element_title',
                sceneTitle,
                acc.editableImageCount + 1,
                editableImageCount,
              ),
            },
            editableImageCount: acc.editableImageCount + 1,
          }
        }
        case 'image-dynamic':
        case 'text-plain':
        case 'text-styled':
        case 'overlay-text':
        case 'text-placeholder': {
          return {
            ...acc,
            editElementLabelById: {
              ...acc.editElementLabelById,
              [elementId]: t(
                'edit_text_element_title',
                sceneTitle,
                acc.editableTextCount + 1,
                editableTextCount,
              ),
            },
            editableTextCount: acc.editableTextCount + 1,
          }
        }
        case 'sticker': {
          return {
            ...acc,
            editElementLabelById: {
              ...acc.editElementLabelById,
              [elementId]: t(
                'edit_sticker_element_title',
                sceneTitle,
                acc.editableStickerCount + 1,
                editableStickerCount,
              ),
            },
            editableStickerCount: acc.editableStickerCount + 1,
          }
        }
        case 'media': {
          const mediaElementId = {
            audio: t('edit_audio_element_title', sceneTitle),
            video: t('edit_video_element_title', sceneTitle),
          }

          return {
            ...acc,
            editElementLabelById: {
              ...acc.editElementLabelById,
              [elementId]: mediaElementId[element.kind],
            },
          }
        }
        default:
          return acc
      }
    },
    {
      editElementLabelById: {},
      editableImageCount: 0,
      editableTextCount: 0,
      editableStickerCount: 0,
    },
  )

  return {
    sceneTitle: t(
      'page_title_editable_elements',
      sceneTitle,
      editableElementsCount,
    ),
    editElementLabelById,
  }
}

export const createSceneTextById = (
  design: Design,
  t: GetEditorLocaleText,
): SceneTextById => {
  return Object.fromEntries(
    design.sceneIds.map(sceneId => [
      sceneId,
      createSceneText(design, sceneId, t),
    ]),
  )
}
