import { Design, DesignSticker } from '@moonpig/web-personalise-editor-types'
import { assert } from '../../utils/assert'
import { ActionContext } from '../types'

export const loadSticker = async (
  { registerResources, services, set, get }: ActionContext,
  sticker: DesignSticker,
) => {
  const store = get()

  assert(store.view.type === 'main')

  if (
    store.view.loadedStickerIds.has(sticker.id) ||
    store.view.loadingStickerIds.has(sticker.id)
  ) {
    return
  }

  set(({ view }) => {
    assert(view.type === 'main')

    return {
      view: {
        ...view,
        loadingStickerIds: new Set([...view.loadingStickerIds, sticker.id]),
      },
    }
  })

  const imageUrl = sticker.url
  const imageElement = await services.loadImage(imageUrl)

  registerResources.registerImage({ imageUrl, imageElement })

  set(({ view }) => {
    assert(view.type === 'main')

    return {
      view: {
        ...view,
        loadedStickerIds: new Set([...view.loadedStickerIds, sticker.id]),
      },
    }
  })
}

export const loadAllStickers = async (
  context: ActionContext,
  design: Design,
) => {
  const stickers: DesignSticker[] = []

  design.sceneIds.forEach(sceneId => {
    const scene = design.sceneById[sceneId]
    scene.elementIds.forEach(elementId => {
      const element = scene.elementById[elementId]
      if (element.type === 'sticker') {
        stickers.push(element.sticker)
      }
    })
  })

  await Promise.all(stickers.map(sticker => loadSticker(context, sticker)))
}
