/* eslint-disable @typescript-eslint/no-shadow */
import {
  DesignElementMedia,
  DesignElementMediaUploadCompleteResult,
} from '@moonpig/web-personalise-editor-types'
import { addMediaToDesign } from '../../../utils/addMediaElementToDesign'
import { assert } from '../../../utils/assert'
import { deleteElement } from '../../../utils/deleteElement'
import { updateDesignSceneElements } from '../../../utils/updateDesignSceneElements'
import { addNotification } from '../../common/addNotification'
import { updateDesignElement } from '../../common/updateDesignElement'
import { ActionCreator } from '../../types'
import { storeUpdateDesign } from '../../updaters/design'
import { storeUpdateView } from '../../updaters/view'

export const createUploadMedia: ActionCreator<'uploadMedia'> =
  context =>
  async ({ file, termsAndConditionsAccepted, mediaAspectRatio, kind }) => {
    const { get, set, t, services, registerResources } = context
    const { view } = get()

    assert(view.type === 'main')

    const { design } = view

    let resolveUploadComplete: (
      result: DesignElementMediaUploadCompleteResult,
    ) => void = /* istanbul ignore next: unreachable */ () => {}

    const uploadComplete = new Promise<DesignElementMediaUploadCompleteResult>(
      resolve => {
        resolveUploadComplete = resolve
      },
    )

    const { updatedDesign, mediaElement, targetSceneId } = addMediaToDesign({
      design,
      uploadComplete,
      state: { type: 'loading' },
      mediaAspectRatio,
      kind,
    })

    const updateMediaElementState = (
      stateUpdater: (
        currentState: DesignElementMedia['state'],
      ) => DesignElementMedia['state'],
      {
        changeReason = 'element-changed-minor',
      }: { changeReason?: 'element-changed' | 'element-changed-minor' } = {},
    ) => {
      set(store => {
        return storeUpdateDesign(store, { changeReason }, design => {
          const currentElement =
            design.sceneById[mediaElement.sceneId].elementById[mediaElement.id]

          /* istanbul ignore next: unreachable */
          if (!currentElement || currentElement.type !== 'media') {
            return design
          }

          return updateDesignElement(design, {
            ...currentElement,
            state: stateUpdater(currentElement.state),
          })
        })
      })
    }

    set(initialStore => {
      let store = initialStore

      store = storeUpdateDesign(
        store,
        { changeReason: 'elements-changed', t },
        () => updatedDesign,
      )

      store = storeUpdateView(store, 'main', () => {
        return {
          selectedSceneId: targetSceneId,
        }
      })

      return store
    })

    const result = await services.uploadMediaToDesign({
      designToken: design.token,
      termsAndConditionsAccepted,
      width: mediaElement.width,
      height: mediaElement.height,
      file,
      kind,
      onProgress: progressPercent => {
        const currentStore = get()

        assert(currentStore.view.type === 'main')

        const currentElement =
          currentStore.view.design.sceneById[mediaElement.sceneId].elementById[
            mediaElement.id
          ]

        if (!currentElement) {
          return 'abort'
        }

        updateMediaElementState(currentState => {
          if (currentState.type !== 'uploading') {
            return currentState
          }

          return {
            ...currentState,
            progressPercent: Math.max(
              progressPercent,
              currentState.progressPercent,
            ),
          }
        })
      },
    })

    const handleError = () => {
      services.trackEvent({
        type: `MEDIA_UPLOADED`,
        kind: 'ERROR',
        productKey: design.productKey,
        mediaKind: kind,
      })

      resolveUploadComplete({ type: 'error' })

      set(initialStore => {
        let store = initialStore

        store = storeUpdateDesign(
          store,
          { changeReason: 'elements-changed', t },
          design => {
            return updateDesignSceneElements(design, targetSceneId, design => {
              return deleteElement(mediaElement, design)
            })
          },
        )
        store = storeUpdateView(store, 'main', () => {
          return {
            notifications: addNotification(view.notifications, {
              type: `${kind}-upload-error`,
            }),
          }
        })

        return store
      })
    }

    if (result.type === 'error') {
      handleError()
      return
    }

    const { imageUrl, mediaToken, mediaId, uploadCompleted } = result

    const imageElement = await services.loadImage(imageUrl)

    registerResources.registerImage({ imageUrl, imageElement })

    set(({ remoteImageStore }) => {
      return {
        remoteImageStore: {
          ...remoteImageStore,
          mediaImageById: {
            ...remoteImageStore.mediaImageById,
            [mediaId]: imageUrl,
          },
        },
      }
    })

    services.trackEvent({
      type: 'MEDIA_UPLOADED',
      kind: 'SUCCESS',
      productKey: design.productKey,
      mediaId,
      mediaKind: kind,
    })

    updateMediaElementState(() => {
      return {
        type: 'uploading',
        mediaId,
        mediaToken,
        progressPercent: 0,
      }
    })

    const uploadResult = await uploadCompleted

    if (uploadResult.type === 'error') {
      handleError()
      return
    }

    if (uploadResult.type === 'aborted') {
      return
    }

    resolveUploadComplete({ type: 'success', mediaToken })

    updateMediaElementState(
      () => {
        return {
          type: 'uploaded',
          mediaId,
          mediaToken,
        }
      },
      { changeReason: 'element-changed' },
    )
  }
