import {
  CollageGrid,
  getAvailableGrids,
  getImageIds,
} from '@moonpig/web-personalise-collage'
import {
  DesignElementImageCollage,
  DesignElementRef,
} from '@moonpig/web-personalise-editor-types'
import React, { FC, useCallback, useMemo } from 'react'
import { COLLAGE_PHOTO_LIMIT } from '../../../../../../../../constants'
import { useAction, useView } from '../../../../../../../../store'
import { getDesignElementByRef } from '../../../../../../selectors'
import { PhotoLibrary } from '../../PhotoLibrary'

type ImageCollagePhotoLibraryProps = {
  elementRef: DesignElementRef<DesignElementImageCollage>
  labelledById: string
}

const toggleCollagePhoto = (
  element: DesignElementImageCollage,
  photoId: string,
  collageGrids: CollageGrid[],
): DesignElementImageCollage => {
  const { grid } = element.customisations
  const currentImageIds = grid ? getImageIds(grid) : []

  const newImageIds = currentImageIds.includes(photoId)
    ? currentImageIds.filter(id => id !== photoId)
    : [...currentImageIds, photoId]

  const grids = getAvailableGrids(collageGrids, newImageIds)

  const newGrid = grids.length > 0 ? grids[0] : null

  return {
    ...element,
    customisations: {
      ...element.customisations,
      pendingEditedImage: null,
      editedImage: null,
      grid: newGrid && {
        ...newGrid,
        background: grid?.background,
        border: grid?.border,
      },
    },
  }
}

export const ImageCollagePhotoLibrary: FC<ImageCollagePhotoLibraryProps> = ({
  elementRef,
  labelledById,
}) => {
  const addPhotos = useAction('addPhotos')
  const removePhoto = useAction('removePhoto')
  const updateElementRef = useAction('updateElementRef')
  const photos = useView('main', view => view.photos)
  const availableCollageGrids = useView(
    'main',
    view => view.customisationConfig.availableCollageGrids,
  )

  const handleAddPhotos = useCallback(
    (files: Blob[]) => {
      addPhotos(files, addedPhotos => {
        updateElementRef(elementRef, current => {
          if (current.customisations.grid !== null) {
            return current
          }

          const collagePhotos = addedPhotos.slice(0, COLLAGE_PHOTO_LIMIT)

          const imageIds = collagePhotos.map(photo => photo.id)
          const grid = getAvailableGrids(availableCollageGrids, imageIds)[0]

          return {
            ...current,
            customisations: {
              ...current.customisations,
              pendingEditedImage: null,
              grid,
            },
          }
        })
      })
    },
    [addPhotos, availableCollageGrids, elementRef, updateElementRef],
  )

  const handleSelectPhoto = useCallback(
    (photoId: string) => {
      updateElementRef(elementRef, current => {
        return toggleCollagePhoto(current, photoId, availableCollageGrids)
      })
    },
    [availableCollageGrids, elementRef, updateElementRef],
  )

  const selectedPhotoIds = useView('main', view => {
    const element = getDesignElementByRef(view.design, elementRef)
    const { grid } = element.customisations

    return grid ? getImageIds(grid) : []
  })

  const photosView = useMemo(() => {
    // eslint-disable-next-line array-callback-return
    return photos.photoIds.map(id => {
      const photoState = photos.photoById[id]

      switch (photoState.type) {
        case 'loading': {
          return {
            type: 'loading' as const,
            id,
          }
        }
        case 'loaded': {
          return {
            type: 'loaded' as const,
            id,
            selected: selectedPhotoIds.includes(id),
            imageUrl: photoState.photo.smallImage.url,
          }
        }
      }
    })
  }, [photos, selectedPhotoIds])

  return (
    <PhotoLibrary
      selectLimit={COLLAGE_PHOTO_LIMIT}
      multiSelect
      labelledById={labelledById}
      photos={photosView}
      onAddPhotos={handleAddPhotos}
      onSelectPhoto={handleSelectPhoto}
      onRemovePhoto={removePhoto}
    />
  )
}
