import React, { FC } from 'react'

export const IconBorder: FC<{
  width?: number
  height?: number
}> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 28 28" width={width} height={height} fill="none">
      <mask id="mp-ed-icon-border" fill="#fff">
        <path
          clipRule="evenodd"
          d="M24 4H4v20h20zM4 2a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z"
          fillRule="evenodd"
        />
      </mask>
      <g fill="currentColor">
        <path
          clipRule="evenodd"
          d="M24 4H4v20h20zM4 2a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z"
          fillRule="evenodd"
        />
        <path
          d="M24 4h2V2h-2zM4 4V2H2v2zm0 20H2v2h2zm20 0v2h2v-2zm0-22H4v4h20zM2 4v20h4V4zm2 22h20v-4H4zm22-2V4h-4v20zM4 0a4 4 0 0 0-4 4h4zM0 4v20h4V4zm0 20a4 4 0 0 0 4 4v-4zm4 4h20v-4H4zm20 0a4 4 0 0 0 4-4h-4zm4-4V4h-4v20zm0-20a4 4 0 0 0-4-4v4zm-4-4H4v4h20z"
          mask="url(#mp-ed-icon-border)"
        />
      </g>
    </svg>
  )
}
