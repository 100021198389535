import {
  Design,
  DesignElementRef,
  DesignElements,
} from '@moonpig/web-personalise-editor-types'

export const deleteElement = (
  elementRef: DesignElementRef,
  design: Design,
): DesignElements => {
  const currentScene = design.sceneById[elementRef.sceneId]

  const elementIds = currentScene.elementIds.filter(
    elementId => elementId !== elementRef.id,
  )

  const elementById = { ...currentScene.elementById }
  delete elementById[elementRef.id]

  return {
    elementIds,
    elementById,
  }
}
