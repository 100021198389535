import { IconSystemImageUpload } from '@moonpig/launchpad-assets'
import { AspectRatio } from '@moonpig/launchpad-components'
import { colorValue } from '@moonpig/launchpad-theme'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { ScreenReaderOnly } from '@moonpig/web-personalise-components'
import { useEditorLocaleText } from '../../../../../../../text-localisation'

const StyledPhotoPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${colorValue('colorBorder04')};
  color: ${colorValue('colorBorder02')};
`

type PhotoPlaceholderProps = {
  name: string
}

export const PhotoPlaceholder: FC<PhotoPlaceholderProps> = ({ name }) => {
  const t = useEditorLocaleText()

  return (
    <AspectRatio ratio={1}>
      <StyledPhotoPlaceholder>
        <IconSystemImageUpload width={28} height={28} />
        <ScreenReaderOnly>
          {t('toolbar_image_placeholder_screen_reader_label', name)}
        </ScreenReaderOnly>
      </StyledPhotoPlaceholder>
    </AspectRatio>
  )
}
