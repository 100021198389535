import {
  PrimaryButton,
  Flex,
  SecondaryButton,
} from '@moonpig/launchpad-components'
import {
  ScreenReaderOnly,
  PageHeading,
} from '@moonpig/web-personalise-components'
import React, { FC } from 'react'
import { useAction } from '../../../../../store'
import { useEditorLocaleText } from '../../../../../text-localisation'
import { useDesignStateType } from '../../../selectors'

export const Header: FC = () => {
  const t = useEditorLocaleText()
  const edit = useAction('edit')
  const finish = useAction('finish')
  const designStateType = useDesignStateType()

  let finishButtonText: string
  switch (designStateType) {
    case 'draft': {
      finishButtonText = t('add_to_basket_button')
      break
    }
    case 'in-basket': {
      finishButtonText = t('finished_editing_button')
      break
    }
  }

  return (
    <Flex width="100%" height="100%" justifyContent="flex-end">
      <ScreenReaderOnly>
        <PageHeading>{t('preview_heading_screen_reader_text')}</PageHeading>
      </ScreenReaderOnly>
      <Flex>
        <SecondaryButton onClick={edit}>{t('edit_button')}</SecondaryButton>
        <PrimaryButton ml={4} onClick={finish}>
          {finishButtonText}
        </PrimaryButton>
      </Flex>
    </Flex>
  )
}
