/* eslint-disable @typescript-eslint/ban-ts-comment, import/no-unresolved, import/no-namespace */
import {
  AppContext,
  createCollaborate,
  createDesignId,
  createLoadFont,
  createLoadImage,
  createPhotoStore,
  createRecordAudio,
} from '@moonpig/web-personalise-editor-services'
import { Services } from '@moonpig/web-personalise-editor-types'
import { features } from '../utils/useEditorFeatures'

// Run `yarn generate` to generate this data
// @ts-ignore
import * as designs from '../data/design'
// @ts-ignore
import * as fontUrls from '../data/font'
// @ts-ignore
import * as imageUrls from '../data/image'
// @ts-ignore
import { stickers } from '../data/stickers/default'

export const createDemoServices = (): Services => {
  const productKey = 'cmk002'
  const appContext: AppContext = {
    features,
    mode: {
      value: 'CREATE',
      productKey,
      variantKey: '1',
      quantity: 1,
    },
    addMetadata: () => {},
    client: {} as never,
    cookies: {},
    region: 'uk',
  }

  const baseLoadFont = createLoadFont(appContext)
  const baseLoadImage = createLoadImage()
  const photoStore = createPhotoStore()

  return {
    addPhoto: photoStore.addPhoto,
    deleteDesign: async () => {
      try {
        window.localStorage.removeItem('mp-ed-collab-name')
        window.localStorage.removeItem('mp-ed-collab-sharing')
        window.localStorage.removeItem('mp-ed-collab-design-id')
      } catch {
        // ignore
      }
    },
    exit: async () => {
      window.location.reload()
    },
    finishEditing: async () => {
      return { type: 'error' }
    },
    loadCustomisationConfig: async () => {
      return {
        availableCollageGrids: [],
        availableSceneLayouts: [],
        smartTextConfig: {
          maxPromptLength: 200,
          remainingRequests: 20,
          smartTextFeatureEnabled: true,
        },
        video: {
          maxSizeBytes: 1000000000,
          sceneAspectRatio: 2.256,
          supportedMimeTypes: [
            'video/avi',
            'video/mp4',
            'video/quicktime',
            'video/x-ms-wmv',
            'video/x-msvideo',
          ],
        },
        audio: {
          maxSizeBytes: 200000000,
          sceneAspectRatio: 2.256,
          supportedMimeTypes: [
            'audio/mp3',
            'audio/mp4',
            'audio/webm',
            'audio/wav',
          ],
          maxDurationSeconds: 180,
        },
      }
    },
    loadDesign: async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const stubDesign = (designs as any)[`design${productKey}`]
      if (!stubDesign) {
        return { type: 'error' }
      }

      const designIdKey = 'mp-ed-collab-design-id'
      const currentDesignId = localStorage.getItem(designIdKey)
      const designId = currentDesignId ?? createDesignId()
      localStorage.setItem(designIdKey, designId)

      return {
        type: 'success',
        state: { type: 'draft', sku: '' },
        design: { ...stubDesign, id: designId },
        facets: [],
        editedImageById: {},
        mediaImageById: {},
      }
    },
    loadFont: (fontFamily, fontUrl, fontMetrics) => {
      const id = fontUrl.substring(fontUrl.lastIndexOf('/') + 1)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const localFontUrl = (fontUrls as any)[`font${id}`] ?? fontUrl
      return baseLoadFont(fontFamily, localFontUrl, fontMetrics)
    },
    loadImage: imageUrl => {
      const id = imageUrl.substring(imageUrl.lastIndexOf('/') + 1)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const localImageUrl = (imageUrls as any)[`image${id}`] ?? imageUrl
      return baseLoadImage(localImageUrl)
    },
    loadPhotos: photoStore.loadPhotos,
    loadSmartText: async () => {
      return {
        type: 'success',
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nec sagittis aliquam malesuada bibendum arcu vitae elementum.',
      }
    },
    loadStickers: async () => {
      return {
        nextCursor: null,
        stickers,
      }
    },
    loadUserState: () => {
      return {
        textOnboardingViewed: true,
        photosAndStickersOnboardingViewed: true,
        layoutsOnboardingViewed: true,
        emojiOnboardingViewed: true,
        videoOnboardingViewed: true,
        smartTextOnboardingViewed: true,
        audioOnboardingViewed: true,
      }
    },
    recordAudio: createRecordAudio(),
    removePhoto: photoStore.removePhoto,
    renderDynamicImage: async () => {
      return { type: 'error' }
    },
    renderMediaImage: async () => {
      return { type: 'error' }
    },
    saveDesign: async () => {},
    saveDesignAndPreview: async () => {
      return { type: 'error' }
    },
    saveUserState: () => {},
    trackEvent: () => {},
    uploadCollageToDesign: async () => {
      return { type: 'error' }
    },
    uploadMediaToDesign: async () => {
      return { type: 'error' }
    },
    uploadPhotoToDesign: async () => {
      return { type: 'error' }
    },
    collaborate: createCollaborate(),
  }
}
