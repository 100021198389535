import { DesignElementImageDynamic } from '@moonpig/web-personalise-editor-types'

export const getDynamicImageId = (
  element: DesignElementImageDynamic,
): { dynamicImageId: string; previousDynamicImageId: string } => {
  const dynamicImageId = JSON.stringify({
    dynamic: element.dynamic,
    textParts: element.customisations.textParts,
  })

  const previousDynamicImageId = JSON.stringify({
    dynamic: element.dynamic,
    textParts: element.customisations.previousTextParts,
  })

  return { dynamicImageId, previousDynamicImageId }
}
