import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC, useEffect, useRef } from 'react'
import { useConsumeTouch } from '@moonpig/web-personalise-components'
import {
  UI_SPACER,
  UI_TOOLBAR_PORTRAIT_MENU_WIDTH,
} from '../../../../../../../constants'
import { MenuHeaderBanner } from './MenuHeaderBanner'
import { MenuHeaderDefault } from './MenuHeaderDefault'
import { ToolbarMenu, ToolbarOrientation } from './types'

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: all;
  max-height: 100%;
  height: 100%;
  &.vertical {
    width: ${UI_TOOLBAR_PORTRAIT_MENU_WIDTH}px;
    ${s({ bgcolor: 'colorBackground01', borderRadius: 2, boxShadow: 2 })}
    margin-right: ${UI_SPACER}px;
    height: max-content;
    overflow: hidden;
  }
`

const StyledMenuMain = styled.div`
  ${s({ p: 6 })}

  overflow-y: auto;
  max-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  touch-action: pan-y;
`

type MenuProps = {
  id?: string
  menu: ToolbarMenu
  orientation: ToolbarOrientation
  onClose: () => void
}

export const Menu: FC<MenuProps> = ({
  id,
  orientation,
  menu: { banner, title, titleId, closeLabel, content },
  onClose,
}) => {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault()
        onClose()
      }
    }
    document.addEventListener('keydown', handler)
    return () => {
      document.removeEventListener('keydown', handler)
    }
  }, [onClose])

  const bindConsumeTouch = useConsumeTouch()
  const touchStartY = useRef<number | null>(null)

  return (
    <StyledMenu
      id={id}
      role="menu"
      aria-label={title}
      tabIndex={-1}
      className={orientation}
      onClick={
        /* istanbul ignore next */ event => {
          event.stopPropagation()
        }
      }
    >
      {banner ? (
        <MenuHeaderBanner
          banner={banner}
          title={title}
          titleId={titleId}
          closeLabel={closeLabel}
          onClose={onClose}
        />
      ) : (
        <MenuHeaderDefault
          title={title}
          titleId={titleId}
          closeLabel={closeLabel}
          onClose={onClose}
        />
      )}
      <StyledMenuMain
        {...bindConsumeTouch()}
        onTouchStart={
          /* istanbul ignore next: difficult to test touch/scroll behaviour */ event => {
            const element = event.currentTarget
            const isAtScrollTop = element.scrollTop === 0
            const touchY = event.touches[0].screenY

            touchStartY.current = isAtScrollTop ? touchY : null
          }
        }
        onTouchMove={
          /* istanbul ignore next: difficult to test touch/scroll behaviour */ event => {
            const element = event.currentTarget
            const isScrollable = element.clientHeight !== element.scrollHeight
            const touchY = event.touches[0].screenY

            if (
              isScrollable &&
              (touchStartY.current === null || touchStartY.current > touchY)
            ) {
              event.stopPropagation()
            }
          }
        }
      >
        {content}
      </StyledMenuMain>
    </StyledMenu>
  )
}
