import { IconSystemCross } from '@moonpig/launchpad-assets'
import { focusIndicatorStyles } from '@moonpig/launchpad-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { useFocusRef } from '../FocusManager'

const StyledContainer = styled.div`
  gap: 8px;
  display: flex;
  justify-content: space-between;
`

const StyledHeading = styled.h4`
  ${focusIndicatorStyles}
  ${s({ borderRadius: 2 })}

  outline: none;
  margin-bottom: 0;
  align-self: center;
  ${s({ typography: 'typeDisplay04' })}
`

const StyledClose = styled.button`
  ${focusIndicatorStyles}
  ${s({ borderRadius: 2 })}

  display: flex;
  flex: 0;
  justify-content: center;
  align-items: center;
  position: relative;
  right: -6px;
  width: 32px;
  height: 32px;
  color: inherit;
`

type MenuHeaderProps = {
  heading: string
  headingId?: string
  focusId?: string
  closeLabel: string
  onClose: () => void
}

export const MenuHeader: FC<MenuHeaderProps> = ({
  heading,
  headingId,
  focusId = '',
  closeLabel,
  onClose,
}) => {
  const headingRef = useFocusRef<HTMLHeadingElement>(focusId)

  return (
    <StyledContainer>
      <StyledHeading ref={headingRef} id={headingId} tabIndex={-1}>
        {heading}
      </StyledHeading>
      <StyledClose
        aria-label={closeLabel}
        onClick={event => {
          event.stopPropagation()
          onClose()
        }}
      >
        <IconSystemCross width={28} height={28} />
      </StyledClose>
    </StyledContainer>
  )
}
