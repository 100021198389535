import {
  clamp,
  Transform2d,
  Vec2,
  vec2Add,
  vec2Scale,
  vec2Sub,
} from '@moonpig/common-math'
import { styled } from '@moonpig/launchpad-utils'
import {
  screenToSvgUnits,
  TransformContext,
  useContacts,
  usePointer,
  useTransform,
  useWheel,
} from '@moonpig/use-transform'
import React, { FC, useCallback, useRef } from 'react'
import { Frame } from '../../common/frame'
import { MAX_SCALE_PHOTO } from '../../constants'
import {
  convertLayoutOffsetToCellOffset,
  Image,
  ImageLayout,
} from '../../model'

type InteractiveImageManipulationProps = {
  bounds: Frame
  cell: { offset: Vec2; scale: number }
  frame: Frame
  layout: ImageLayout
  image: Image
  onChangeTransform: (event: {
    offset: Vec2
    scale: number
    last: boolean
  }) => void
}

const StyledRect = styled.rect`
  pointer-events: all;
  touch-action: none;
`

export const InteractiveImageManipulation: FC<
  InteractiveImageManipulationProps
> = ({ bounds, cell, frame, layout, image, onChangeTransform, children }) => {
  const grabRectRef = useRef<SVGRectElement>(null)
  const frameCenter = vec2Add(frame.position, vec2Scale(frame.size, 0.5))
  const imageCenter = vec2Add(frameCenter, layout.offset)

  const transformPoint = useCallback((screenPoint: Vec2): Vec2 => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const svg = grabRectRef.current!.closest('svg')!
    return screenToSvgUnits(screenPoint[0], screenPoint[1], svg)
  }, [])

  const setTransform = useCallback(
    (newTransform: Transform2d, { last }: TransformContext) => {
      const newScale = clamp(newTransform.scale[0], 1, MAX_SCALE_PHOTO)

      const newOffset = convertLayoutOffsetToCellOffset({
        imageSize: image.size,
        frameSize: frame.size,
        scale: newScale,
        offset: vec2Sub(newTransform.position, frameCenter),
      })

      onChangeTransform({ offset: newOffset, scale: newScale, last })
    },
    [frame.size, frameCenter, image.size, onChangeTransform],
  )

  const transform = useTransform({
    value: {
      position: imageCenter,
      scale: [cell.scale, cell.scale],
      rotation: 0,
    },
    setValue: setTransform,
    options: {
      minScale: 1,
      maxScale: MAX_SCALE_PHOTO,
      uniformScale: true,
    },
  })

  const onContactsChange = useContacts({
    options: { disableRotation: true },
    transform,
  })

  const bindPointer = usePointer(onContactsChange, {
    transformPoint,
    capturePointerOnLeave: true,
  })

  const bindWheel = useWheel({
    options: {
      sensitivity: 0.001,
      transformPoint,
    },
    transform,
  })

  return (
    <g
      data-testid="mp-ed-image-manipulation"
      ref={grabRectRef}
      {...bindPointer()}
      {...bindWheel()}
    >
      <StyledRect
        fill="transparent"
        x={bounds.position[0]}
        y={bounds.position[1]}
        width={bounds.size[0]}
        height={bounds.size[1]}
      />
      {children}
    </g>
  )
}
