import React from 'react'

import { useCamera } from '../../../../../camera'

const ENABLE_CAMERA_DEBUG = process.env.NODE_ENV === 'test'

const CameraDebugContent = () => {
  const camera = useCamera()

  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        padding: 4,
        color: '#777',
        fontSize: 8,
        background: 'rgba(255, 255, 255, 0.75)',
      }}
    >
      <div>
        Fc.{' '}
        <span data-testid="mp-editor-camera-focus">
          {JSON.stringify(camera.focus)}
        </span>
        <br />
        UI{' '}
        <span data-testid="mp-editor-camera-ui">
          {JSON.stringify(camera.ui)}
        </span>
      </div>
    </div>
  )
}

export const CameraDebug = () => {
  /* istanbul ignore next: unreachable */
  if (!ENABLE_CAMERA_DEBUG) {
    return null
  }

  return <CameraDebugContent />
}
