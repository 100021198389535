import { styled } from '@moonpig/launchpad-utils'
import React, { FC } from 'react'
import { useAction, useView } from '../../../store'
import { useDesignLayout } from '../selectors'
import { LoadingPreview } from './components/LoadingPreview'
import { PreviewCard } from './components/PreviewCard'
import { PreviewDefault } from './components/PreviewDefault'
import { PreviewMug } from './components/PreviewMug'
import { OnLoaded } from './types'

const FullSizeContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

type PreviewContentProps = {
  onLoaded: OnLoaded
}

const PreviewContent: FC<PreviewContentProps> = ({ onLoaded }) => {
  const layout = useDesignLayout()

  switch (layout) {
    case 'CARD_PORTRAIT':
    case 'CARD_SQUARE': {
      return <PreviewCard orientation="PORTRAIT" onLoaded={onLoaded} />
    }
    case 'CARD_LANDSCAPE': {
      return <PreviewCard orientation="LANDSCAPE" onLoaded={onLoaded} />
    }
    /* istanbul ignore next: uses WebGL */
    case 'MUG': {
      return <PreviewMug onLoaded={onLoaded} />
    }
    default: {
      return <PreviewDefault onLoaded={onLoaded} />
    }
  }
}

type PreviewState = 'loading-data' | 'loading-resources' | 'ready'

const usePreviewState = (): PreviewState => {
  return useView('main', ({ view }): PreviewState => {
    switch (view.type) {
      case 'preview': {
        return view.readyToShow ? 'ready' : 'loading-resources'
      }
      default: {
        return 'loading-data'
      }
    }
  })
}

export const Preview: FC = () => {
  const previewLoaded = useAction('previewLoaded')
  const previewState = usePreviewState()

  return (
    <>
      {previewState === 'loading-data' ? null : (
        <FullSizeContainer
          aria-hidden={previewState === 'loading-resources'}
          style={{ opacity: previewState === 'loading-resources' ? 0 : 1 }}
        >
          <PreviewContent onLoaded={previewLoaded} />
        </FullSizeContainer>
      )}
      {previewState === 'ready' ? null : (
        <FullSizeContainer>
          <LoadingPreview />
        </FullSizeContainer>
      )}
    </>
  )
}
